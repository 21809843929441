import React, { useState, useEffect } from "react";
import { Delete2 } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH, MaintenanceParts } from "../../../constants/paths";
import { Link, useParams, useNavigate } from "react-router-dom";
import { DatePicker, Space, Input, Select, InputNumber } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  adminApiCall,
  userAccessibility,
  getDate,
  capatalizeLetters,
} from "../../../helpers/ajax_request";
import Swal from "sweetalert2";

export default function Index() {
  const params = useParams();
  console.log(params);
  const maintenanceID = params.id;

  const { TextArea } = Input;

  const [dateValue, setDateValue] = useState(moment());
  const [trade, setTrade] = useState([]);
  const [tradeNames, setTradeNames] = useState();
  const [addComments, setAddComments] = useState("");

  const [maintenance, setMaintenance] = useState({
    suite: "",
    price: "",
    description: "",
    comment: "",
  });
  const [maintenanceOrderListing, setMaintenanceOrderListing] = useState({});
  const [maintenaceServiceItems, setMaintenaceServiceItems] = useState([]);
  const [uniqueID, setUniqueID] = useState(0);
  // console.log("uniqueID: ", uniqueID);
  const [commentArray, setCommentArray] = useState([]);
  const [commentLength, setCommentLength] = useState(commentArray.length);
  const [unitNames, setUnitNames] = useState("Other");
  const [unit, setUnit] = useState([]);

  const [description, setDescription] = useState("");
  const [suite, setSuite] = useState("");
  const [price, setPrice] = useState(1);
  const [approveSendEmailCheckBox, setApproveSendEmailCheckBox] =
    useState(false);
  const [orderID, setOrderID] = useState("");
  const [orderStatus, setOrderStatus] = useState("APPROVED");

  const [findBuilding, setFindBuilding] = useState("");

  useEffect(() => {
    setBuildingOnNavbar();
  }, [findBuilding]);

  const maintenanceItemHandler = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    let tempObj = {
      ...maintenance,
      [name]: value,
    };
    // console.table(tempObj);
    setMaintenance(tempObj);
  };

  const navigate = useNavigate();

  useEffect(() => {
    addedComment();
  }, [commentLength]);

  useEffect(() => {
    getTradeList();
    getMaintenanceOrder();
    getUnitList();
  }, []);

  const logs = async (comment) => {
    let data = {
      purchase_order_id: maintenanceID,
      log_detail: {
        log: comment,
      },
    };

    let res = await adminApiCall(
      "/api/admin/purchase_order/log",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // notifySuccess("Log Created (add list)");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const dateFormat = "DD/MM/YYYY";

  const getMaintenanceOrder = async () => {
    let data = {
      purchase_order_id: maintenanceID,
      order_type: "maintenance_service",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setFindBuilding(res?.data?.building_id);
        setMaintenanceOrderListing(res.data);
        setOrderStatus(res.data?.order_state);
        let tempArray = [res.data?.maintenance_service_items];
        // console.log("old array", tempArray[0]);
        let newArray = [];
        tempArray[0].map((v, i) => {
          let obj = {
            complete_by: v.complete_by,
            completed_at: v.completed_at,
            completed_by_user_info: v.completed_by_user_info,
            created_at: v.created_at,
            created_by: v.created_by,
            created_by_user_info: v.created_by_user_info,
            description: v.description,
            is_complete: v.is_complete,
            price: v.price,
            status: v.status,
            suite_no: v.suite_no,
            updated_at: v.updated_at,
            updated_by: v.updated_by,
            updated_by_user_info: v.updated_by_user_info,
            _id: v._id,
            id: i,
          };
          newArray.push(obj);
        });
        // console.log("newArray: ", newArray);
        setMaintenaceServiceItems(newArray);
        let lastElement = newArray[newArray.length - 1];
        // console.log(lastElement.id);
        setUniqueID(lastElement.id + 1);
        setTradeNames(res.data?.trade_id);
        setOrderID(res.data?.order_id);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const setBuildingOnNavbar = async () => {
    let data = {
      building_id: findBuilding,
    };
    let res = await adminApiCall(
      "/api/admin/building/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res SET BUILDING => ", res);
        localStorage.setItem("building_name", res?.data?.street);
        localStorage.setItem("building_ID", res?.data?.building_id);
        localStorage.setItem("building_id", res?.data?._id);
        // console.log("v._id --===> ", v._id);
        localStorage.setItem("available_suites", res?.data?.available_suite);
        localStorage.setItem("no_of_suites", res?.data?.no_of_suite);
        localStorage.setItem("building_namee", res?.data?.building_name);
      } else if (res.status === 400) {
        notifyError(res.message);
      } else if (res.status === 300) {
        // notifyError(res.message);
        navigate("*");
      }
    }
  };

  const addedComment = async () => {
    let data = {
      purchase_order_id: maintenanceID,
      order_type: "maintenance_service",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setCommentArray(res.data.comments);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  function onChange(date, dateString) {
    // console.log(date, dateString);
    setDateValue(dateString);
  }

  const handleChangeCategory = (e) => {
    setTradeNames(e.target.value);
    // console.log(e.target.value);
    logs(`trade asignee has been changed to ${e.target.value}`);
  };

  const getUnitList = async () => {
    let data = {
      building_id: localStorage.getItem("building_id"),
    };
    let res = await adminApiCall(
      "/api/admin/unit/unit_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res unittttt", res);

        setUnit(res.data);
        // setUnitNames(res.data[0]?.unit_no);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getTradeList = async () => {
    let data = {
      property_id: "",
    };
    let res = await adminApiCall(
      "/api/admin/trade/trades_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setTrade(res.data);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const addMaintenanceItem = () => {
    if (!maintenance.description) {
      notifyError("Please add description inorder to add item to list");
    } else {
      let maintenanceServiceObj = {
        description: maintenance.description,
        price: maintenance.price,
        suite_no: unitNames,
        id: uniqueID,
      };
      // console.log("maintenanceServiceObj: ", maintenanceServiceObj);
      let tempArray = [...maintenaceServiceItems];
      tempArray.push(maintenanceServiceObj);
      // console.log(tempArray);
      setUniqueID(uniqueID + 1);
      setMaintenaceServiceItems(tempArray);
      logs(
        `Item with description of ${maintenance.description} has been added to Maintenance Order`
      );
      // console.log(tempArray);
      setMaintenance({
        suite: "",
        price: "",
        description: "",
      });
    }
  };

  const deleteSpecificItem = (item) => {
    let ref = [...maintenaceServiceItems];
    // console.log(ref.indexOf(item));
    ref.splice(ref.indexOf(item), 1);
    logs(
      `Item with description of ${item.description} has been removed from Maintenance Order`
    );
    // console.log("ref", ref);
    setMaintenaceServiceItems(ref);
  };

  const submit = async () => {
    let data = {
      purchase_order_id: maintenanceID,
      trade_id: tradeNames,
      maintenance_service_items: maintenaceServiceItems,
    };
    // console.log("data: ", data);
    if (maintenaceServiceItems.length < 1) {
      notifyError("Please add items for maintenance to proceed");
    } else if (maintenaceServiceItems.find((val) => val.description === "")) {
      notifyError("Description is Mandatory!");
    } else {
      // console.log(data);
      // return;
      let res = await adminApiCall(
        "/api/admin/purchase_order/update",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("Maintenance Service Updated Successfully!");
          logs(
            `Maintenance service submitted against maintenance service ID ${maintenanceID} with trade assignee ${tradeNames}`
          );
          navigate(PATH.VIEW_EXISTING);
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
  };

  const addComment = async () => {
    let data = {
      purchase_order_id: maintenanceID,
      comment: { comment: addComments },
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setAddComments("");
        setCommentLength(commentLength + 1);
        // getMaintenanceOrder();
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const handleMileageRateBlur = (e) => {
    let value = e.target.value < 0 ? 0 : Number(e.target.value).toFixed(2);
    let name = e.target.name;
    let tempObj = {
      ...maintenance,
      [name]: value,
    };

    setMaintenance(tempObj);
  };

  const deleteComment = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            purchase_order_id: maintenanceID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            purchase_order_id: maintenanceID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            // getMaintenanceOrder();
            setCommentLength(commentLength - 1);
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  const handleChangeUnit = (e) => {
    setUnitNames(e.target.value);
    // console.log(e.target.value);
  };

  const [size, setSize] = React.useState("small");

  const { Option } = Select;

  const children = [<Option key="Other">Other</Option>];
  unit.map((v, i) => {
    children.push(<Option key={v.unit_no}>{v.unit_no}</Option>);
  });

  function onChangeDescription(value, id) {
    setDescription(value);
    // console.log(value.target.value);
    let tempArray = [...maintenaceServiceItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val._id);
        logs(
          `Description changed to "${value.target.value}" of item having description "${val.description}"`
        );
        return {
          id: val.id,
          description: value.target.value,
          suite_no: val.suite_no,
          price: val.price,
        };
      } else {
        return val;
      }
    });
    setMaintenaceServiceItems(arr);
    // console.log(arr);
  }

  function handleChange(value, id) {
    setSuite(value);
    // console.log(value);
    let tempArray = [...maintenaceServiceItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val._id);
        logs(
          `Suite Number changed to "${value}" of item having description "${val.description}"`
        );
        return {
          id: val.id,
          description: val.description,
          suite_no: value,
          price: val.price,
        };
      } else {
        return val;
      }
    });
    setMaintenaceServiceItems(arr);
    // console.log(arr);
  }

  function onChangeNumber(value, id) {
    // console.log("value", value);
    // console.log("id: ", id);
    setPrice(value);
    let tempArray = [...maintenaceServiceItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        logs(
          `Price changed to "$${value}" of item having description "${val.description}"`
        );
        // console.log(id + " " + val._id);
        // console.log(val);
        return {
          id: val.id,
          description: val.description,
          suite_no: val.suite_no,
          price: value,
        };
      } else {
        return val;
      }
    });
    setMaintenaceServiceItems(arr);
    // console.log("val:", arr);
  }

  const approveListing = async () => {
    let data = {
      purchase_order_id: maintenanceID,
      send_to_trader: approveSendEmailCheckBox,
    };

    // console.log(data);
    // return;

    let res = await adminApiCall(
      "/api/admin/purchase_order/approve_order",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Order has been Approved!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="contentCard small">
              <div className="titleWrapper">
                <span>Edit Maintenance Service</span>
              </div>

              <div className="formDesign">
                <div className="row">
                  <div className={`col-12 col-lg-6`}>
                    <div className="form-group">
                      <label>Assign Trade</label>
                      <select
                        className="form-control"
                        value={tradeNames}
                        onChange={(e) => handleChangeCategory(e)}
                      >
                        {trade &&
                          trade.map((v, i) => {
                            return (
                              <option key={i} value={v._id}>
                                {v.company.charAt(0).toUpperCase() +
                                  v.company.slice(1)}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formDesign">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>
                        Apt. No. <small>(optional)</small>
                      </label>
                      <select
                        className="form-control"
                        defaultValue={unitNames}
                        onChange={(e) => handleChangeUnit(e)}
                      >
                        <option value="Other">Other</option>
                        {unit &&
                          unit.map((v, i) => {
                            return (
                              <option key={i} value={v.unit_no}>
                                {v.unit_no}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>
                        Price <small>(optional)</small>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="price"
                        value={maintenance.price}
                        onChange={(e) => maintenanceItemHandler(e)}
                        onBlur={(e) => handleMileageRateBlur(e)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Description</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        name="description"
                        value={maintenance.description}
                        onChange={(e) => maintenanceItemHandler(e)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cardFooter">
                <button className="myBtn" onClick={addMaintenanceItem}>
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="contentCard small">
              <h3 className="sectionTitle">
                Maintenance Service # {maintenanceOrderListing?.order_id}
              </h3>
              <hr className="splitCard" />

              <div className="mainTable ax newTable table-responsive scrollable">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ minWidth: "350px" }}>Description</th>
                      <th>Apt. No.</th>
                      <th>
                        Price <small>($)</small>
                      </th>
                      <th className="text-right">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {maintenaceServiceItems.length < 1 ? (
                      <tr></tr>
                    ) : (
                      maintenaceServiceItems &&
                      maintenaceServiceItems.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td style={{ minWidth: "350px" }}>
                              <TextArea
                                size={size}
                                rows={1}
                                placeholder="Please add Description"
                                allowClear
                                value={v.description}
                                onChange={(e) => onChangeDescription(e, v.id)}
                              />
                            </td>
                            <td>
                              <Select
                                size={size}
                                defaultValue={v.suite_no}
                                onChange={(e) => handleChange(e, v.id)}
                                style={{ width: 90 }}
                              >
                                {children}
                              </Select>
                            </td>
                            <td>
                              <Space>
                                <InputNumber
                                  size="small"
                                  min={0.1}
                                  max={1000000000}
                                  precision={2}
                                  defaultValue={(
                                    (v.price * 100) /
                                    100
                                  ).toLocaleString()}
                                  onChange={(e) => onChangeNumber(e, v.id)}
                                />
                              </Space>
                            </td>
                            <td className="text-right">
                              <a
                                className="circleBtn danger deleteThis"
                                href="#"
                                onClick={() => deleteSpecificItem(v)}
                              >
                                <Delete2 />
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>

                {maintenaceServiceItems.length < 1 ? (
                  <div
                    className="mb-3"
                    style={{
                      border: "1px dashed #606071",
                      textAlign: "center",
                      fontSize: "16px",
                      marginRight: "10%",
                      marginLeft: "10%",
                      padding: "25px",
                    }}
                  >
                    Maintenance Order is Empty. <br /> Please Add atleast one
                    item from the Maintenance Service.
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="contentCard commentWrapper mt-3">
                <div className="formDesign">
                  <div className="form-group">
                    <label>
                      Comments <small>(optional)</small>
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={addComments}
                      onChange={(e) => setAddComments(e.target.value)}
                    ></textarea>
                  </div>
                  <button
                    className="myBtn success mb-2"
                    onClick={() => addComment()}
                    disabled={!addComments ? true : false}
                  >
                    Add Comment
                  </button>
                </div>
                <div className="commentLogs">
                  {commentArray &&
                    commentArray.map((v, i) => {
                      return (
                        <div key={i} className="comment">
                          <h2>
                            {capatalizeLetters(
                              v?.created_by_user_info?.first_name +
                              "_" +
                              v?.created_by_user_info?.last_name
                            )}{" "}
                            <span>
                              {getDate(v.created_at)}{" "}
                              {localStorage.getItem("role") === "viewer" ? (
                                userAccessibility(
                                  "purchase_order_management",
                                  "delete"
                                ) === false ? (
                                  ""
                                ) : (
                                  <a
                                    href="#"
                                    className="circleBtn danger deleteThis ml-2"
                                    onClick={() => {
                                      deleteComment([v._id]);
                                    }}
                                  >
                                    <Delete2 />
                                  </a>
                                )
                              ) : localStorage.getItem("role") ===
                                "building_manager" ? (
                                userAccessibility(
                                  "purchase_order_management",
                                  "delete"
                                ) === false ? (
                                  ""
                                ) : (
                                  <a
                                    href="#"
                                    className="circleBtn danger deleteThis ml-2"
                                    onClick={() => {
                                      deleteComment([v._id]);
                                    }}
                                  >
                                    <Delete2 />
                                  </a>
                                )
                              ) : localStorage.getItem("role") ===
                                "property_manager" ? (
                                userAccessibility(
                                  "purchase_order_management",
                                  "delete"
                                ) === false ? (
                                  ""
                                ) : (
                                  <a
                                    href="#"
                                    className="circleBtn danger deleteThis ml-2"
                                    onClick={() => {
                                      deleteComment([v._id]);
                                    }}
                                  >
                                    <Delete2 />
                                  </a>
                                )
                              ) : (
                                <a
                                  href="#"
                                  className="circleBtn danger deleteThis ml-2"
                                  onClick={() => {
                                    deleteComment([v._id]);
                                  }}
                                >
                                  <Delete2 />
                                </a>
                              )}
                            </span>
                          </h2>
                          <p>{v.comment}</p>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="cardFooter">
                {orderStatus === "PENDING" && localStorage.getItem("role") !=
                  "building_manager" ? (
                  <button
                    className="myBtn mr-1"
                    // type="submit"
                    data-toggle="modal"
                    data-target="#approveOrder"
                  // onClick={() => alert("Under construction")}
                  >
                    Approve
                  </button>
                ) : (
                  ""
                )}
                <button
                  className="myBtn mr-1"
                  type="submit"
                  onClick={() => submit()}
                >
                  Update
                </button>
                <Link className="myBtn bdr" to={PATH.VIEW_EXISTING}>
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* approve popup */}
      <div className="modal fade" id="approveOrder">
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content customModal">
            <div className="modal-body">
              <h2 className="modalTitle"> Approve!</h2>
              <div className="formDesign">
                <div className="form-group">
                  <label>
                    You Are About To Approve Purchase Order [{orderID}]
                  </label>
                  <input
                    type="checkbox"
                    className="customCheck mx-3"
                    // id={"purchaseOrder" + i}
                    //               value={v._id}
                    //               checked={
                    //                 deleteItem && deleteItem.includes(v._id)
                    //                   ? true
                    //                   : false
                    //               }

                    value={approveSendEmailCheckBox}
                    onChange={(e) =>
                      setApproveSendEmailCheckBox(e.target.checked)
                    }
                  />
                  <label>Send Email to Contractor</label>
                </div>
                <div className="text-center">
                  <button
                    className="myBtn mr-1"
                    data-dismiss="modal"
                    onClick={() => approveListing()}
                  >
                    Approve
                  </button>
                  <button className="myBtn simple " data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
