import React, { useState, useEffect } from "react";
import MainLayout from "../../../layouts";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminApiCall } from "../../../helpers/ajax_request";
import { PATH } from "../../../constants/paths";
import CustomPhone from "../../../components/CustomPhone/Index";

export default function Index() {
  const params = useParams();
  const buildingID = params.id;
  // console.log(buildingID);

  const navigate = useNavigate();

  const [buildingData, setbuildingData] = useState({});

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const [stateOptions, setStateOptions] = useState([]);
  // console.log(stateOptions);

  const [selectedPM, setSelectedPM] = useState("");

  let initialValues = {
    fullName: "",
    contact: "",
    email: "",
  };

  useEffect(() => {
    getBuildingData();
    getPMList();
  }, []);

  const selectPMHandler = (e) => {
    // console.log(e.target.value);
    setSelectedPM(e.target.value);
  };

  // let validationSchema = yup.object({
  //   address: yup
  //     .string()
  //     .trim()
  //     // .min(2, "Please enter an address.")
  //     // .max(100, "Please enter an address.")
  //     .required("Address is mandatory."),
  //   country: yup
  //     .string()
  //     .trim()
  //     // .min(2, "Please enter valid country name.")
  //     // .max(20, "Please enter valid country name.")
  //     .required("Country name is mandatory."),
  //   province: yup
  //     .string()
  //     .trim()
  //     // .min(2, "Please enter province name.")
  //     // .max(25, "Please enter province name.")
  //     .required("Province name is mandatory."),
  //   city: yup
  //     .string()
  //     .trim()
  //     // .min(2, "Please enter city name.")
  //     // .max(25, "Please enter city name.")
  //     .required("City name is mandatory."),
  //   postalCode: yup
  //     .string()
  //     .trim()
  //     // .min(2, "Please enter postal code.")
  //     // .max(25, "Please enter postal code.")
  //     .required("Postal code is mandatory."),
  //   legacyID: yup
  //     .string()
  //     .trim()
  //     // .min(2, "Please enter legacy ID.")
  //     // .max(25, "Please enter legacy ID.")
  //     .required("Legacy ID is mandatory."),
  //   areaSize: yup
  //     .string()
  //     .trim()
  //     // .min(2, "Please enter area size.")
  //     // .max(20, "Please enter area size.")
  //     .required("Area size is mandatory."),
  //   region: yup
  //     .string()
  //     .trim()
  //     // .min(2, "Please enter region.")
  //     // .max(20, "Please enter region.")
  //     .required("Region is mandatory."),
  //   phone: yup
  //     .string()
  //     .matches(
  //       /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/,
  //       "Enter Phone No."
  //     )
  //     .trim()
  //     .min(2, "Please enter valid amount of digits.")
  //     .max(50, "Please enter valid amount of digits.")
  //     .required("Please specify Phone No."),
  //   intersection: yup
  //     .string()
  //     .trim()
  //     // .min(2, "Please enter region.")
  //     // .max(20, "Please enter region.")
  //     .required("Intersection is mandatory."),
  //   noOfSuites: yup
  //     .string()
  //     .trim()
  //     // .min(2, "Please enter region.")
  //     // .max(20, "Please enter region.")
  //     .required("Please enter number of suites."),
  // });

  const getBuildingData = async () => {
    let data = {
      building_id: buildingID,
    };

    let res = await adminApiCall(
      "/api/admin/building/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setbuildingData(res.data);
        setSelectedPM(
          res.data?.property_manager_id === null
            ? ""
            : res.data?.property_manager_id
        );
        formik.setValues({
          // address: res.data.street,
          // country: res.data.country,
          // province: res.data.province,
          // city: res.data.city,
          // postalCode: res.data.postal_code,
          // legacyID: res.data.legacy_id,
          // areaSize: res.data.area_size,
          // region: res.data.region,
          // intersection: res.data.intersection,
          // phone: res.data.phone,
          // noOfSuites: res.data.no_of_suite,
          // description: res.data.description,
          fullName: res.data.building_contact_full_name,
          contact: res.data.building_contact_phone,
          email: res.data.building_contact_email,
        });
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getPMList = async () => {
    let data = {
      // building_id: buildingID,
    };

    let res = await adminApiCall(
      "/api/admin/user/property_manager_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log(res);
        let tempArray = [{ label: "Choose an Option", value: "" }];
        res?.data?.map((v, i) => {
          tempArray.push({
            label: `${v?.first_name + " " + v?.last_name}`,
            value: `${v?._id}`,
          });
        });
        // console.log(tempArray);
        setStateOptions(tempArray);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const savePropertyManager = async () => {
    // console.log(selectedPM);
    let data = {
      building_id: buildingID,
      property_manager_id: selectedPM,
    };
    // console.log(data);
    // return;
    let res = await adminApiCall(
      "/api/admin/building/assign_building",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Property Manager Assigned to Emails!");
        navigate(PATH.BUILDING_LIST);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const onSubmit = async (values) => {
    // console.log("all okay", values);
    let data = {
      building_id: buildingID,
      building_name: buildingData?.building_name,
      building_contact_full_name: formik.values.fullName,
      building_contact_phone: formik.values.contact,
      building_contact_email: formik.values.email,
    };
    // console.log(data);
    // return;
    let res = await adminApiCall(
      "/api/admin/building/update",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Building Updated Successfully!");
        navigate(PATH.BUILDING_LIST);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: validationSchema,
    onSubmit,
  });

  const handleContactNumberChange = (phone) => {
    let output = "";
    if (phone.length === 11) {
      let cc = phone.substr(0, 1);
      let area = phone.substr(1, 3);
      let pre = phone.substr(4, 3);
      let tel = phone.substr(7, 4);

      output = `+${cc} (${area}) ${pre} ${tel}`;
      // console.log("if", phone);
      formik.setFieldValue("contact", phone);
    } else {
      if (phone.length < 11) {
        // console.log("length zero");
      } else {
        // console.log("not zero");
      }
      // console.log("else", phone);
      formik.setFieldValue("contact", phone);
    }
  };
  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="contentCard">
          <div className="titleWrapper">
            <span>Contact</span>
          </div>
          <h3 className="sectionTitle" style={{ fontSize: "30px" }}>
            {buildingData?.building_name}
          </h3>

          <hr className="splitCard" />

          <h3 className="sectionTitle">Building Contact (Optional)</h3>
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group">
                <label>Full Name</label>
                <input
                  className="form-control fnm"
                  name="fullName"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  // disabled={true}
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group">
                <label>Contact</label>
                {/* <input
                  className="form-control fnm"
                  name="contact"
                  value={formik.values.contact}
                  onChange={formik.handleChange}
                  // disabled={true}
                  type="text"
                /> */}
                <CustomPhone
                  className="form-control noDrpDown fnm"
                  value={formik.values.contact}
                  onChange={(contact) => handleContactNumberChange(contact)}
                  onlyCountries={["ca"]}
                  name="contact"
                />
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group">
                <label>Email</label>
                <input
                  className="form-control fnm"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  // disabled={true}
                  type="text"
                />
              </div>
            </div>
          </div>

          <hr className="splitCard" />

          <h3 className="sectionTitle">Property Manager</h3>
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <div className="form-group">
                <label>Select from list</label>
                <select
                  className="form-control select_custom"
                  value={selectedPM}
                  onChange={(e) => selectPMHandler(e)}
                >
                  {stateOptions &&
                    stateOptions.map((v, i) => {
                      return (
                        <option key={i} value={v.value}>
                          {v.label}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          <Link className="myBtn bdr" to={PATH.BUILDING_LIST}>
            Go Back
          </Link>
          <button
            className="myBtn ml-1"
            onClick={() => {
              savePropertyManager();
              formik.handleSubmit();
            }}
          >
            Save
          </button>
        </div>
      </section>
    </MainLayout>
  );
}
