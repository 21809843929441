import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PATH } from "../../constants/paths";
import { Modal, Button } from "react-bootstrap";
// import { DatePicker, Space } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import moment from "moment";

export default function CreateOrder(props) {
  const { purchaseOrderModal, setPurchaseOrderModal } = props;
  const [modalShow, setModalShow] = useState(false);
  const [dateValue, setDateValue] = useState(moment());

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="modalTitle text-left"> Choose a Category</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text text-center">
            To create the purchase order, Please select the category first
          </p>
          <div className="formDesign text-center">
            <Link
              className="myBtn shadow "
              style={{ minWidth: "176px" }}
              to={PATH.MAINTENANCE_SERVICES}
            >
              Maintenance Service
            </Link>
            <br />
            <br />
            <Link
              className="myBtn shadow "
              style={{ minWidth: "176px" }}
              to={PATH.PURCHASE_PARTS}
            >
              Purchase Parts
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function onChange(date, dateString) {
    console.log(date, dateString);
  }

  const dateFormat = "DD/MM/YYYY";
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
