import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Delete2, Print } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import SignatureCanvas from "react-signature-canvas";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminApiCall } from "../../../helpers/ajax_request";
import Logosm from "../../../assets/images/logosm.png";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import $ from "jquery";
import CustomPhone from "../../../components/CustomPhone/Index";
import Select from "react-select";
export default function Index() {
  const navigate = useNavigate();
  const [vacantCheckBox, setVacantCheckBox] = useState(false);
  const [commonAreaCheckBox, setCommonAreaCheckBox] = useState(false);
  const [addItem, setAddItem] = useState("");
  const [specificItems, setSpecificItems] = useState([]);
  // console.log(specificItems);
  const [date, setDate] = useState(moment.utc());
  // console.log(date);
  const [signPad, setSignPad] = useState("");
  const [signaturingPad, setSignaturingPad] = useState("");
  const [divWidth, setDivWidth] = useState("");
  const [divHeight, setDivHeight] = useState("");
  const [itemLock, setItemLock] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [showLogo, setShowLogo] = useState(false);
  useEffect(() => {
    getUnitList();
  }, []);

  const componentRef = useRef();
  let sigCanvas = useRef();

  const initialValues = {
    suiteNo: "",
    building: "",
    tenantName: "",
    phone: "",
    // selecteditems: "",
  };

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const getUnitList = async () => {
    let data = {
      building_id: localStorage.getItem("building_id"),
    };
    let res = await adminApiCall(
      "/api/admin/unit/unit_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res unittttt", res);
        let tempArray = [{ value: "Other", label: "Other" }];

        res.data.map((v, i) => {
          let obj = { value: v.unit_no, label: v.unit_no };
          tempArray.push(obj);
        });
        setSelectOptions(tempArray);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  let validationSchema = yup.object({
    tenantName: yup
      .string()
      .trim()
      // .min(2, "Please enter an address.")
      // .max(100, "Please enter an address.")
      .required("Tenant Name is mandatory."),
  });

  const onSubmit = async (values) => {
    setSignaturingPad(signPad.getTrimmedCanvas().toDataURL("image/png"));
    setItemLock(true);

    if (
      signPad.getTrimmedCanvas().toDataURL("image/png") ===
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC" ||
      signPad.getTrimmedCanvas().toDataURL("image/png") === "" ||
      typeof signPad.getTrimmedCanvas().toDataURL("image/png") === "object" ||
      specificItems.length < 1
    ) {
      if (
        signPad.getTrimmedCanvas().toDataURL("image/png") ===
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC" ||
        signPad.getTrimmedCanvas().toDataURL("image/png") === "" ||
        typeof signPad.getTrimmedCanvas().toDataURL("image/png") === "object"
      ) {
        notifyWarning("Signatures are mandatory!");
      }
      if (specificItems.length < 1) {
        notifyWarning("Please Add Items");
      }
    } else if (date === "") {
      notifyWarning("Please Enter Date");
    } else {
      // console.log("all okay", values);
      let data = {
        suite_no: selectedOption === null ? "" : selectedOption.value,
        building_id: localStorage.getItem("building_id"),
        tenant_name: formik.values.tenantName,
        vacant_area: vacantCheckBox,
        common_area: commonAreaCheckBox,
        phone_no: formik.values.phone,
        items: specificItems,
        tenant_signature: signPad.getTrimmedCanvas().toDataURL("image/png"),
        work_order_creation_date:
          typeof date === "string" ? date : date?.format("MM/DD/YYYY"),
      };
      console.log("datasent: ", data);
      // return;
      let res = await adminApiCall(
        "/api/admin/work_order/create",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("Work Order Created Successfully!");
          navigate(PATH.WORK_ORDER);
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const vacantCheckBoxHandler = (e) => {
    setVacantCheckBox(e.target.checked);
    // console.log(e.target.checked);
  };

  const commonAreaCheckBoxHandler = (e) => {
    setCommonAreaCheckBox(e.target.checked);
    // console.log(e.target.checked);
  };

  const addSpecificItem = () => {
    let tempArray = [...specificItems];
    // console.log(tempArray.length);
    let tempObj = {
      item: addItem,
    };
    // if (formik.values.selecteditems === "") {
    //   notifyWarning("Please Specify Item");
    // } else
    if (tempArray.length > 9) {
      notifyError("Items selected cannot be more than 10.");
      // setItemLock(true);
    } else if (addItem === "") {
      // console.log("empty addItem");
      notifyWarning("Please Enter Item!");
    } else {
      tempArray.push(tempObj);
      setSpecificItems(tempArray);
      // console.log(specificItems);
      setAddItem("");
      // formik.setValues({
      //   selecteditems: "",
      // });
    }
  };

  const deleteSpecificItem = (item) => {
    let ref = [...specificItems];
    // console.log(ref.indexOf(item));
    ref.splice(ref.indexOf(item), 1);
    // console.log("ref", ref);
    setSpecificItems(ref);
  };

  function onChange(date, dateString) {
    // console.log(date, dateString);
    // setDate(dateString);
    date = moment.utc(date);
    setDate(dateString === "" ? "" : moment.utc(date));
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setShowLogo(false);
    },
    // content: () => sigRef.current,
  });

  const signatureHandler = (ref) => {
    sigCanvas = ref;
    setSignPad(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  const getDimension = () => {
    let divWidths = $("#canvas_Wraper").width();
    let divHeights = $("#canvas_Wraper").height();
    setDivWidth(divWidths);
    setDivHeight(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const handlePhoneNumberChange = (phone) => {
    /**
     * Total 11 Numbers
     * cc  = country code
     * area = 3 digit area code in (234)
     * pre = 3 digit half phone number
     * tel = last 4 digit phone number
     *
     * ******************
     * e.g,
     * 12345645879
     *
     * Final Result:
     * +1 (234) 564 5879
     *
     */
    let output = "";
    if (phone.length === 11) {
      let cc = phone.substr(0, 1);
      let area = phone.substr(1, 3);
      let pre = phone.substr(4, 3);
      let tel = phone.substr(7, 4);

      output = `+${cc} (${area}) ${pre} ${tel}`;
      // console.log("if", phone);
      formik.setFieldValue("phone", phone);
    } else {
      if (phone.length < 11) {
        // console.log("length zero");
      } else {
        // console.log("not zero");
      }
      // console.log("else", phone);
      formik.setFieldValue("phone", phone);
    }
  };

  return (
    <MainLayout>
      <section className="pageWrapper">
        <form
          ref={componentRef}
          className="contentCard"
          onSubmit={formik.handleSubmit}
        >
          {showLogo ? (
            <div
              className="logoWrapper mb-5"
              style={{ backgroundColor: "#38393c" }}
            >
              <Link className="logo" to={PATH.BUILDING_LIST}>
                <img className="logo" src={Logosm} alt="" />
              </Link>
            </div>
          ) : (
            ""
          )}

          <div className="titleWrapper">
            <span>Create Work Order</span>
          </div>
          <div className="formDesign">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label>
                    Apt. No. <small>(Optional)</small>
                  </label>
                  <Select
                    defaultValue={selectedOption}
                    onChange={(value) => {
                      // console.log(value);
                      setSelectedOption(value);
                    }}
                    isSearchable
                    isClearable
                    options={selectOptions}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label>Building Name</label>
                  <input
                    // className={
                    //   "form-control " +
                    //   (formik.errors.building && formik.touched.building
                    //     ? " is-invalid"
                    //     : "")
                    // }
                    className="form-control"
                    name="building"
                    value={localStorage.getItem("building_namee")}
                    onChange={formik.handleChange}
                    type="text"
                    disabled={true}
                  />
                  {/* <div className="invalid-feedback">
                      {formik.errors.building}
                    </div> */}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label>Tenant Full Name</label>
                  <input
                    className={
                      "form-control " +
                      (formik.errors.tenantName && formik.touched.tenantName
                        ? " is-invalid"
                        : "")
                    }
                    name="tenantName"
                    value={formik.values.tenantName}
                    onChange={formik.handleChange}
                    type="text"
                  />
                  <div className="invalid-feedback">
                    {formik.errors.tenantName}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label>
                    Phone Number <small>(Optional)</small>
                  </label>
                  <CustomPhone
                    className={
                      "form-control noDrpDown " +
                      (formik.errors.phone && formik.touched.phone
                        ? "is-invalid"
                        : "")
                    }
                    value={formik.values.phone}
                    onChange={(phone) => {
                      formik.handleChange(phone);
                      handlePhoneNumberChange(phone);
                    }}
                    onlyCountries={["ca"]}
                    name="phone"
                  />
                  {/* <div className="invalid-feedback">
                      {formik.errors.phone}
                    </div> */}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      className="customCheck"
                      value={vacantCheckBox}
                      onChange={(e) => vacantCheckBoxHandler(e)}
                    />{" "}
                    Vacant
                  </label>
                  <br />
                  <label>
                    <input
                      type="checkbox"
                      className="customCheck"
                      value={commonAreaCheckBox}
                      onChange={(e) => commonAreaCheckBoxHandler(e)}
                    />{" "}
                    Common Area
                  </label>
                </div>
              </div>
            </div>
          </div>
          <p className="text">
            I, the above named tenant request that the management repair the
            following items in my unit:
          </p>

          <div className="mainTable table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Item Number</th>
                  <th>Work Requests</th>
                  <th className="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                {specificItems.length < 1 ? (
                  <tr></tr>
                ) : (
                  specificItems &&
                  specificItems.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <b>{v.item}</b>
                        </td>
                        <td className="text-right">
                          <a
                            className={`circleBtn deleteThis `}
                            href="#"
                            onClick={() => deleteSpecificItem(v)}
                          >
                            <Delete2 />
                          </a>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            {specificItems.length < 1 ? (
              <div
                className="mb-3"
                style={{
                  border: "1px dashed #606071",
                  textAlign: "center",
                  fontSize: "16px",
                  marginRight: "20%",
                  marginLeft: "20%",
                  // width: "100%",
                  padding: "25px",
                }}
              >
                Work Order is Empty. <br /> Please Add atleast one item.
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="formDesign">
            <div className="row align-items-end">
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label>Specific Items</label>
                  <input
                    className={"form-control"}
                    // value={formik.values.selecteditems}
                    value={addItem}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      setAddItem(e.target.value);
                    }}
                    type="text"
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <button
                  className="myBtn success mb-3"
                  type="button"
                  // disabled={specificItems.length > 9 ? true : false}
                  onClick={() => addSpecificItem()}
                >
                  Add Item
                </button>
              </div>
            </div>
          </div>
          <p className="text">
            After the signing of this work request, i hereby give permission to
            enter my suite within the next 24 hours (or with a 24-hour written
            entry notice thereafter), in order that the above requested repairs
            are evaluated and/or completed. In the event the Landlord is unable
            to access my unit to complete the above repairs. i understand and
            agree to any costs associated to re-schedule an entry.
          </p>
          <div className="formDesign">
            <div className="row align-items-end">
              <div className="col-lg-2 col-sm-4">
                <div className="form-group">
                  <label>Date</label> <br />
                  <Space direction="vertical">
                    <DatePicker
                      className="form-control"
                      name="date"
                      value={date}
                      onChange={onChange}
                    />
                  </Space>
                </div>
              </div>
              <div className="col-12 col-lg-8 col-xl-4 col-sm-12">
                <div className="form-group">
                  <label>Tenant Signature</label>
                  <div
                    id="canvas_Wraper"
                    onLoad={() => getDimension()}
                    style={{ border: "1px solid black", width: "100%" }}
                  >
                    <SignatureCanvas
                      ref={(ref) => signatureHandler(ref)}
                      penColor="grey"
                      canvasProps={{
                        className: "canvasSignature",
                        width: `${divWidth ? divWidth : ""}`,
                        height: 220,
                      }}
                    />
                    <div className="d-flex justify-content-around pb-2">
                      <div className="col-lg-3">
                        <button
                          className="myBtn bdr"
                          style={{ width: "100%" }}
                          type="reset"
                          onClick={() => {
                            signPad.clear();
                            setSignaturingPad("");
                            setItemLock(false);
                            // console.log("signatureValue: ", signatureValue);
                          }}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="col-lg-5">
                        <button
                          // className={`${
                          //   itemLock === true
                          //     ? "myBtn info w-100"
                          //     : "myBtn bdr w-100"
                          // }`}
                          className="myBtn"
                          style={{ width: "100%" }}
                          type="submit"
                        >
                          Submit Work Order
                        </button>
                      </div>
                      <div className="col-lg-3">
                        <button
                          className="myBtn bdr"
                          style={{ width: "100%" }}
                          type="button"
                          onClick={() => {
                            setShowLogo(true);
                            setTimeout(() => {
                              handlePrint();
                            }, 500);
                          }}
                        >
                          <Print />
                          Print
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showLogo ? (
            ""
          ) : (
            <div className="cardFooter">
              <Link className="myBtn bdr" to={PATH.WORK_ORDER}>
                Cancel
              </Link>
            </div>
          )}
        </form>
      </section>
    </MainLayout>
  );
}
