import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import EditQuantityReceived from "../../../components/EditQuantityReceived/EditQuantityReceived";
import Logosm from "../../../assets/images/logo.png";
import MainLayout from "../../../layouts";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import WebcamCapture from "../../../components/Webcam/WebcamCapture";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ToastContainer, toast } from "react-toastify";
import { useFileUpload } from "use-file-upload";
import { Delete2, Edit } from "../../../Icons/Icons";
import { baseDomain } from "../../../config";
import { PATH, PurchaseParts } from "../../../constants/paths";

import {
  adminApiCall,
  adminApiCallForFormData,
  getDateOnly,
  capatalizeLetters,
  userAccessibility,
  getDate,
} from "../../../helpers/ajax_request";

export default function Index() {
  const params = useParams();
  const purchasePartID = params.id;

  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [snapPhotoModal, setSnapPhotoModal] = useState(false);
  let [file, selectFile] = useFileUpload();
  const [purchaseOrderListing, setPurchaseOrderListing] = useState({
    order_id: "",
    created_at: "",
    order_type: "",
    trader_info: {
      company: "",
    },
    order_requested_by_user_info: "",
    order_state: "",
  });
  const [purchaseItemsList, setPurchaseItemsList] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState("");
  const [quantityReceived, setQuantityReceived] = useState("");
  const [itemDetails, setItemDetails] = useState({
    itemDescription: "",
    itemID: "",
  });
  const [enableWOrkConfirmation, setEnableWOrkConfirmation] = useState(false);
  const [enableBtnArray, setEnableBtnArray] = useState([]);
  const [addComments, setAddComments] = useState("");
  const [enableCamera, setEnableCamera] = useState(false);
  const [dateVal, setDateVal] = useState(moment.utc());
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [printPO, setPrintPO] = useState(false);
  const [printMS, setPrintMS] = useState(false);
  // purchase
  const printTablePurchase = useRef(null);
  const printWCPurchase = useRef(null);
  const pOPrintPurchase = useReactToPrint({
    content: () => printTablePurchase.current,
  });
  const wCPrintPurchase = useReactToPrint({
    content: () => printWCPurchase.current,
  });
  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  const getPurchaseOrder = async () => {
    let data = {
      purchase_order_id: purchasePartID,
      order_type: "purchase_parts",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setPurchaseOrderListing(res.data);
        setPurchaseItemsList(res.data?.purchase_items);
        console.log("PURCHASE ORDER -> ", res.data)
        let tempArray = [];
        res.data?.purchase_items.map((v, i) => {
          if (v.is_complete === true) {
            tempArray.push(v);
          } else {
          }
          // console.log(tempArray);
          setEnableBtnArray(tempArray);
          if (res.data.purchase_items.length === tempArray.length) {
            setEnableWOrkConfirmation(true);
          } else {
            setEnableWOrkConfirmation(false);
          }
        });
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const updatePurchasePartWorkConfirmation = async () => {
    if (quantityReceived > totalQuantity) {
      notifyError(
        "Quantity Received cannot be greater than total quantity ordered!"
      );
    } else if (dateVal === "" || quantityReceived === "") {
      notifyError("Please fill all Fields!");
    } else if (
      purchaseOrderListing?.order_state === "PENDING" ||
      purchaseOrderListing?.order_state === "VOID"
    ) {
      notifyError(
        `Order State should be "Approved" inorder to edit quantity received`
      );
    } else {
      let data = {
        purchase_order_id: purchasePartID,
        order_type: PurchaseParts,
        item_id: itemDetails.itemID,
        received_quantity: Number(quantityReceived),
        received_date: dateVal === "" ? dateVal : dateVal.format("MM/DD/YYYY"),
        total_quantity: totalQuantity,
      };
      // console.log("data: ", data);
      // return;
      let res = await adminApiCall(
        "/api/admin/purchase_order/update_order_purchase_parts_item",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("quantity changed!");
          setEditModalShow(false);
          getPurchaseOrder();
          if (quantityReceived === totalQuantity) {
            notifySuccess("work confirmation button enabled!");
            // setEnableWOrkConfirmation(true);
          }
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
  };

  const receivedAllWorkConfirmation = async () => {
    let data = {
      purchase_order_id: purchasePartID,
      order_type: PurchaseParts,
    };
    // console.log("data: ", data);

    let res = await adminApiCall(
      "/api/admin/purchase_order/update_order_purchase_parts_all_items",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("All items received!");
        getPurchaseOrder();
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const addComment = async () => {
    let data = {
      purchase_order_id: purchasePartID,
      comment: { comment: addComments },
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setAddComments("");
        getPurchaseOrder();
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };
  const confirmPurchaseOrder = async () => {
    let data = {
      purchase_order_id: purchasePartID,
    };
    // console.log(data);
    // return;
    let res = await adminApiCall(
      "/api/admin/purchase_order/confirm_order",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Purchase Part has been Completed");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }

    if (invoiceNumber) {
      let dataa = {
        purchase_order_id: purchasePartID,
        ...(invoiceNumber && {
          invoice_number: invoiceNumber,
        }),
      };
      // console.log(dataa);
      // return;
      let resp = await adminApiCall(
        "/api/admin/purchase_order/received_purchase_order_invoice",
        "POST",
        JSON.stringify(dataa)
      );
      if (!resp) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (resp.hasOwnProperty("status")) {
        if (resp.status === 200) {
        } else if (resp.status === 400) {
          notifyError(resp.message);
        }
      }
    }
  };
  const uploadDocument = async () => {
    let formData = new FormData();
    formData.append("purchase_order_id", purchasePartID);
    formData.append("document", file?.file);

    if (file === null) {
      // console.log("inside if");
      notifyError("Please Select a File to Upload");
    } else if (typeof file === "object") {
      // console.log("inside else if");
      let res = await adminApiCallForFormData(
        "/api/admin/purchase_order/upload_file",
        formData
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("File Uploading Successful!");
          getPurchaseOrder();
          setModalShow(false);
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
  };
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="modalTitle">Upload Document</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mt-3 mb-5"></div>

          <div className="formDesign text-center">
            {file ? (
              <div className="mb-3">
                <div> File Name: {file.name} </div>
                <div> File Size: {file.size} </div>
              </div>
            ) : (
              <div className="mb-3">No file selected</div>
            )}
            <button
              className="myBtn shadow mr-1"
              onClick={() => {
                // Single File Upload
                selectFile(
                  { accept: "image/*" },
                  ({ source, name, size, file }) => {
                    // file - is the raw File Object
                    // console.log("testing image", { source, name, size, file });
                  }
                );
              }}
            >
              Browse File
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="formDesign">
            <button className="myBtn shadow mr-1" onClick={uploadDocument}>
              Upload
            </button>
            <button
              className="myBtn simple"
              onClick={() => {
                setModalShow(false);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
  function MyVerticallyCenteredModalSnapPhoto(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="modalTitle">Upload Snap</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {enableCamera ? <WebcamCapture /> : "abc"} */}
          <WebcamCapture />

          <div className="formDesign text-center">
            <button
              className="myBtn shadow mr-1"
              onClick={() => {
                setEnableCamera(!enableCamera);
              }}
            >
              {!enableCamera ? "Open Camera" : "Close Camera"}
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="formDesign">
            <button
              className="myBtn shadow mr-1"
            //  onClick={uploadDocument}
            >
              Upload Picture
            </button>
            <button
              className="myBtn simple"
              onClick={() => {
                setSnapPhotoModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
  const deleteComment = (deleteArray) => {
    // console.log("deleteArray", deleteArray);
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            purchase_order_id: purchasePartID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            purchase_order_id: purchasePartID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getPurchaseOrder();
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };
  const deleteFile = async (fileID) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete this File?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            purchase_order_id: purchasePartID,
            id: fileID,
          };
        } else {
          data = {
            purchase_order_id: purchasePartID,
            id: fileID,
          };
        }
        // console.log(data);
        // return;
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete_file",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getPurchaseOrder();
            notifySuccess("File Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };
  const printMaintainenceOrder = (type) => {
    if (type === "mtable") {
      wCPrintPurchase();
    } else if (type === "mwc") {
      pOPrintPurchase();
    }
  }
  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Purchase Order Details</span>
              </div>
              <div className="details">
                <div className="detailContent">
                  <h4>Purchase Order ID</h4>
                  <h2>{purchaseOrderListing?.order_id}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created Date</h4>
                  <h2>{getDateOnly(purchaseOrderListing?.created_at)}</h2>
                </div>
                <div className="detailContent">
                  <h4>Type</h4>
                  <h2>
                    {capatalizeLetters(
                      purchaseOrderListing?.order_type +
                      " " +
                      purchaseOrderListing?.order_type
                    )}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>Assigned Trade</h4>
                  <h2>{purchaseOrderListing?.trade_info?.company}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created By</h4>
                  <h2>
                    {capatalizeLetters(
                      purchaseOrderListing?.order_requested_by_user_info
                        ?.first_name +
                      "_" +
                      purchaseOrderListing?.order_requested_by_user_info
                        ?.last_name
                    )}
                  </h2>
                </div>
                {/* {purchaseOrderListing?.invoice_number ? (
                  <div className="detailContent">
                    <h4>Invoice Number</h4>
                    <h2>{purchaseOrderListing?.invoice_number}</h2>
                  </div>
                ) : (
                  ""
                )} */}

                <div className="detailContent">
                  <h4>State</h4>
                  <h2
                    className={`badge ${purchaseOrderListing.order_state === "PENDING"
                      ? "grey"
                      : purchaseOrderListing.order_state === "APPROVED"
                        ? "pending"
                        : purchaseOrderListing.order_state === "CONFIRM"
                          ? "success"
                          : "danger"
                      }`}
                  >
                    {purchaseOrderListing?.order_state}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-9">
            <div className="contentCard mb-0">
              <div className="titleWrapper">
                <span>Verify Order</span>
              </div>
              <div className="mainTable table-responsive scrollable">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>List of Added Items</th>
                      <th>Qty Ordered</th>
                      <th>Qty Received</th>
                      <th>Price</th>
                      <th className="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseItemsList &&
                      purchaseItemsList.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <b>{v?.description}</b>
                            </td>
                            <td>
                              <span className="text">{v?.quantity}</span>
                            </td>
                            <td>
                              <span
                                className={`text ${v?.quantity_received === v?.quantity
                                  ? "success"
                                  : "danger"
                                  }`}
                              >
                                <span></span> {v?.quantity_received}
                                {v?.received_date ? (
                                  <small className="">
                                    {" "}
                                    ({getDateOnly(v?.received_date)}){" "}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </span>
                            </td>
                            <td>
                              <b>
                                $
                                {((v.price * 100) / 100).toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 2 }
                                )}
                              </b>
                            </td>
                            <td>
                              <button
                                style={{ height: "35px", width: "35px" }}
                                className={`circleBtn success`}
                                onClick={() => {
                                  setEditModalShow(true);
                                  setQuantityReceived(v?.quantity_received);
                                  // setItemID(v._id);
                                  let ref = { ...itemDetails };
                                  ref.itemDescription = v?.description;
                                  ref.itemID = v._id;
                                  setItemDetails(ref);
                                  setTotalQuantity(v?.quantity);
                                }}
                              // disabled={
                              //   v?.quantity_received === v?.quantity
                              //     ? true
                              //     : false
                              // }
                              >
                                <Edit />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <EditQuantityReceived
                show={editModalShow}
                onHide={() => {
                  setEditModalShow(false);
                  setItemDetails({
                    itemDescription: "",
                    itemID: "",
                  });
                }}
                dateVal={dateVal}
                setDateVal={setDateVal}
                quantityReceived={quantityReceived}
                setQuantityReceived={setQuantityReceived}
                updatePurchasePartWorkConfirmation={
                  updatePurchasePartWorkConfirmation
                }
                itemDetails={itemDetails}
              />
              <hr className="splitCard" />
              <div className="formDesign">
                <div className="row align-items-center">
                  <div className="col-12 col-md-6 col-lg-4">
                    {/* <a
                      className="myBtn simple mb-3"
                      // href="#"
                      onClick={() => updatePurchasePartWorkConfirmation()}
                    >
                      Update Qty
                    </a> */}
                    <a
                      className="myBtn simple"
                      // href="#"
                      onClick={() => receivedAllWorkConfirmation()}
                    >
                      Received All
                    </a>
                  </div>
                </div>
              </div>

              <div className="cardFooter">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="form-group">
                      <label>
                        <b>
                          Invoice No. <small>(Optional)</small>
                        </b>
                      </label>
                      <input
                        className={"form-control"}
                        name="address"
                        value={invoiceNumber}
                        onChange={(e) => {
                          setInvoiceNumber(e.target.value);
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-9 col-md-6 col-sm-12"
                    style={{ marginTop: "12px" }}
                  >
                    <button
                      className={`${purchaseOrderListing.order_state === "CONFIRM" ||
                        !enableWOrkConfirmation ||
                        enableBtnArray.length !== purchaseItemsList.length
                        ? // "myBtn info mr-1"
                        "myBtn success mr-1"
                        : "myBtn success mr-1"
                        }`}
                      style={
                        purchaseOrderListing.order_state === "CONFIRM" ||
                          !enableWOrkConfirmation ||
                          enableBtnArray.length !== purchaseItemsList.length
                          ? { cursor: "default" }
                          : { cursor: "pointer" }
                      }
                      // disabled={
                      //   purchaseOrderListing.order_state === "CONFIRM" ||
                      //   !enableWOrkConfirmation ||
                      //   enableBtnArray.length !== purchaseItemsList.length
                      //     ? true
                      //     : false
                      // }
                      onClick={() => {
                        confirmPurchaseOrder();
                        navigate(PATH.VIEW_EXISTING);
                      }}
                    >
                      Invoice Received
                    </button>
                    <span className="dropUp">
                      <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                      <MyVerticallyCenteredModalSnapPhoto
                        show={snapPhotoModal}
                        onHide={() => setSnapPhotoModal(false)}
                      />
                      <button
                        type="button"
                        className="myBtn simple mr-1"
                        onClick={() => {
                          setModalShow(true);
                        }}
                      >
                        Upload Photo/Doc
                      </button>
                    </span>
                    <Link className="myBtn bdr" to={PATH.VIEW_EXISTING}>
                      Back to Listing
                    </Link>
                    <button className="myBtn ml-1 h-100 " onClick={() => printMaintainenceOrder("mtable")}>
                      Print
                    </button>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
            {purchaseOrderListing?.documents?.length === 0 ? (
              ""
            ) : (
              <div className="contentCard commentWrapper mt-3">
                <div className="titleWrapper">
                  <span>Documents</span>
                </div>
                <div className="row documentDisplayWrapper">
                  {purchaseOrderListing?.documents?.map((v, i) => {
                    return (
                      <div
                        key={i}
                        className={`col-lg-3 col-md-12 col-sm-12 mt-2 mb-2 ${v.mime_type === "image" ? "imageView" : "fileView"
                          }`}
                      >
                        {v.mime_type === "image" ? (
                          <>
                            <a
                              className="imageDisplay"
                              href={`${baseDomain + v.url}`}
                              download={v.original_name}
                              target="_blank"
                              key={i}
                            >
                              {v.original_name}{" "}
                            </a>
                            <span
                              className="deleteFile"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                deleteFile(v._id);
                              }}
                            >
                              X
                            </span>
                          </>
                        ) : (
                          <>
                            <a
                              className="documentDisplay"
                              href={`${baseDomain + v.url}`}
                              download={v.original_name}
                              target="_blank"
                              key={i}
                            >
                              {v.original_name}{" "}
                            </a>
                            <span
                              className="deleteFile"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                deleteFile(v._id);
                              }}
                            >
                              X
                            </span>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="contentCard commentWrapper mt-3">
              <div className="formDesign">
                <div className="form-group">
                  <label>
                    Comments <small>(optional)</small>
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={addComments}
                    onChange={(e) => setAddComments(e.target.value)}
                  ></textarea>
                </div>
                <button
                  className="myBtn success mb-2"
                  onClick={() => addComment()}
                  disabled={!addComments ? true : false}
                >
                  Add Comment
                </button>
              </div>
              <div className="commentLogs">
                {purchaseOrderListing?.comments?.map((v, i) => {
                  return (
                    <div key={i} className="comment">
                      <h2>
                        {capatalizeLetters(
                          v?.created_by_user_info?.first_name +
                          "_" +
                          v?.created_by_user_info?.last_name
                        )}{" "}
                        <span>
                          {getDate(v.created_at)}{" "}
                          {localStorage.getItem("role") === "viewer" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : localStorage.getItem("role") ===
                            "building_manager" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : localStorage.getItem("role") ===
                            "property_manager" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : (
                            <a
                              href="#"
                              className="circleBtn danger deleteThis ml-2"
                              onClick={() => {
                                deleteComment([v._id]);
                              }}
                            >
                              <Delete2 />
                            </a>
                          )}
                        </span>
                      </h2>
                      <p>{v.comment}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Purchase View */}
      <section style={{ display: "none" }} className="pageWrapper">
        <div ref={printWCPurchase} className="row ">
          <div className="col-12 col-md-4 col-lg-12">
            <div
              className="row justify-content-between align-items-center mb-0"
              style={{ backgroundColor: "white" }}
            >
              <div className="col-4 col-md-4 col-lg-3">
                <div
                  className="logoWrapper"
                  style={{ backgroundColor: "white" }}
                >
                  <Link to={PATH.BUILDING_LIST}>
                    <img className="logo" src={Logosm} alt="" />
                  </Link>
                </div>
              </div>
              <div
                className="col-4 col-md-4 col-lg-3 text-center"
                style={{ margin: "10px 0px" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      <th className="tableBorderPrint">PO ID</th>
                      <td
                        className="tableBorderPrint"
                        style={{ minWidth: "155px" }}
                      >
                        {purchaseOrderListing.order_id}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint" style={{ width: "max-content"}}>Building Name</th>
                      <td className="tableBorderPrint">{purchaseOrderListing?.building_info?.building_name}</td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Status</th>
                      <td
                        className={`tableBorderPrint text ${purchaseOrderListing?.order_state === "PENDING"
                          ? "pending"
                          : purchaseOrderListing?.order_state ===
                            "APPROVED"
                            ? "success"
                            : purchaseOrderListing?.order_state === "CONFIRM"
                              ? "success"
                              : "danger"
                          }`}
                        style={{ fontWeight: "900", fontSize: "16px" }}
                      >
                        {purchaseOrderListing?.order_state === "PENDING"
                          ? "PENDING"
                          : purchaseOrderListing?.order_state === "APPROVED"
                            ? "APPROVED"
                            : purchaseOrderListing?.order_state === "CONFIRM"
                              ? "INVOICED"
                              : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <hr className="splitCard" /> */}
          </div>
          <div className="contentCard row justify-content-between w-100 my-0 pb-1">
            <div className="col-6 col-md-4 col-lg-4">
              <table className="mx-auto">
                <tbody>
                  <tr
                    style={{
                      color: "black",
                      fontSize: "16px",
                    }}
                  >
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#d97228",
                        border: "1px solid black",
                        boxSizing: "border-box",
                        color: "white",
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: "16px",
                        lineHeight: 2,

                      }}
                    >
                      Purchase Order Details
                    </th>
                  </tr>
                  <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Building Address</th>
                    <td className="tableBorderPrint">
                      {purchaseOrderListing?.building_info?.street +
                        ", " +
                        purchaseOrderListing?.building_info?.city +
                        ", " +
                        purchaseOrderListing?.building_info?.country}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Postal Code</th>
                    <td className="tableBorderPrint">
                      {purchaseOrderListing?.building_info?.postal_code}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Phone</th>
                    <td className="tableBorderPrint">
                      {" ("}
                      {purchaseOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[0] !== undefined
                        ? purchaseOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[0]
                        : ""}
                      {purchaseOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[1] !== undefined
                        ? purchaseOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[1]
                        : ""}
                      {purchaseOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[2] !== undefined
                        ? purchaseOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[2]
                        : ""}
                      {") "}
                      {purchaseOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[3] !== undefined
                        ? purchaseOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[3]
                        : ""}
                      {purchaseOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[4] !== undefined
                        ? purchaseOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[4]
                        : ""}
                      {purchaseOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[5] !== undefined
                        ? purchaseOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[5]
                        : ""}
                      {" - "}
                      {purchaseOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[6] !== undefined
                        ? purchaseOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[6]
                        : ""}
                      {purchaseOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[7] !== undefined
                        ? purchaseOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[7]
                        : ""}
                      {purchaseOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[8] !== undefined
                        ? purchaseOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[8]
                        : ""}
                      {purchaseOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[9] !== undefined
                        ? purchaseOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[9]
                        : ""}
                    </td>
                  </tr>
                  {/* <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Created Date</th>
                    <td className="tableBorderPrint">
                      {getDateOnly(purchaseOrderListing?.created_at)}
                    </td>
                  </tr> */}
                  <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Type</th>
                    <td className="tableBorderPrint">
                      {capatalizeLetters(
                        purchaseOrderListing?.order_type +
                        " " +
                        purchaseOrderListing?.order_type
                      )}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Requested By</th>
                    <td className="tableBorderPrint">
                      {capatalizeLetters(
                        purchaseOrderListing?.order_requested_by_user_info
                          ?.first_name +
                        "_" +
                        purchaseOrderListing?.order_requested_by_user_info
                          ?.last_name
                      )}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Requested On</th>
                    <td className="tableBorderPrint">
                      {getDateOnly(purchaseOrderListing?.order_requested_at)}
                    </td>
                  </tr>
                  {purchaseOrderListing?.order_approved_by === null ? (
                    ""
                  ) : (
                    <>
                      <tr style={{ color: "black", fontSize: "16px" }}>
                        <th className="tableBorderPrint">Approved By</th>
                        <td className="tableBorderPrint">
                          {capatalizeLetters(
                            purchaseOrderListing?.order_approved_by_user_info
                              ?.first_name +
                            "_" +
                            purchaseOrderListing
                              ?.order_approved_by_user_info?.last_name
                          )}
                        </td>
                      </tr>
                      <tr style={{ color: "black", fontSize: "16px" }}>
                        <th className="tableBorderPrint">Approved On</th>
                        <td className="tableBorderPrint">
                          {getDateOnly(
                            purchaseOrderListing?.order_approved_at
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                  {purchaseOrderListing?.email_to_contractor === null ? (
                    ""
                  ) : (
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">
                        Emailed To Contractor On
                      </th>
                      <td className="tableBorderPrint">
                        {/* {purchaseOrderListing?.email_to_contractor} */}
                        {moment(
                          new Date(
                            purchaseOrderListing?.email_to_contractor
                          ).toUTCString()
                        ).format("DD-MMMM-YYYY hh:mm a")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-6 col-md-4 col-lg-4">
              <table className="w-100">
                <tbody>
                  <tr
                    style={{
                      color: "black",
                      fontSize: "16px",
                    }}
                  >
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#d97228",
                        border: "1px solid black",
                        boxSizing: "border-box",
                        color: "white",
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: "16px"
                        ,lineHeight:2
                      }}
                    >
                      Trade Details
                    </th>
                  </tr>
                  <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Name</th>
                    <td className="tableBorderPrint">
                      {purchaseOrderListing?.trade_info_updated?.company}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Phone</th>
                    <td className="tableBorderPrint">
                      {" ("}
                      {purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[0] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[0]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[1] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[1]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[2] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[2]
                        : ""}
                      {") "}
                      {purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[3] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[3]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[4] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[4]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[5] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[5]
                        : ""}
                      {" - "}
                      {purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[6] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[6]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[7] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[7]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[8] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[8]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[9] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[9]
                        : ""}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Fax</th>
                    <td className="tableBorderPrint">
                      {" ("}
                      {purchaseOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[0] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[0]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[1] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[1]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[2] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[2]
                        : ""}
                      {") "}
                      {purchaseOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[3] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[3]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[4] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[4]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[5] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[5]
                        : ""}
                      {" - "}
                      {purchaseOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[6] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[6]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[7] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[7]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[8] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[8]
                        : ""}
                      {purchaseOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[9] !== undefined
                        ? purchaseOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[9]
                        : ""}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "16px" }}>
                    <th className="tableBorderPrint">Email</th>
                    <td className="tableBorderPrint">
                      {purchaseOrderListing?.trade_info_updated?.email}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <hr className="splitCard" /> */}
          </div>

          <div ref={printTablePurchase} className="col-12 col-md-8 col-lg-9">
            {printPO === true ? (
              <div className="col-12 col-md-4 col-lg-12">
                <div
                  className="row justify-content-between align-items-center"
                  style={{ backgroundColor: "#38393c" }}
                >
                  <div className="col-4 col-md-4 col-lg-3">
                    <div
                      className="logoWrapper"
                      style={{ backgroundColor: "#38393c" }}
                    >
                      <Link to={PATH.BUILDING_LIST}>
                        <img className="logo" src={Logosm} alt="" />
                      </Link>
                    </div>
                  </div>
                  {/* <div className="col-4 col-md-4 col-lg-3">abc</div> */}
                  <div className="col-4 col-md-4 col-lg-3 text-center">
                    <div className="details mt-2 mb-2">
                      <div className="detailContent">
                        <h4 style={{ color: "white", fontSize: "16px" }}>
                          Purchase Order ID
                        </h4>
                        <h2 style={{ color: "white", fontSize: "16px" }}>
                          {purchaseOrderListing?.order_id}
                        </h2>
                      </div>
                      <div className="detailContent">
                        <h4 style={{ color: "white", fontSize: "16px" }}>
                          Building Name
                        </h4>
                        <h2 style={{ color: "white", fontSize: "16px" }}>
                          {purchaseOrderListing?.buildingName}
                        </h2>
                      </div>
                      <div className="detailContent">
                        <h4 style={{ color: "white", fontSize: "16px" }}>
                          Status
                        </h4>
                        <h2
                          className={`badge ${purchaseOrderListing?.order_state === "PENDING"
                            ? "pending"
                            : purchaseOrderListing?.order_state ===
                              "APPROVED"
                              ? "success"
                              : purchaseOrderListing?.order_state ===
                                "CONFIRM"
                                ? "success"
                                : "danger"
                            }`}
                        >
                          {purchaseOrderListing?.order_state}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="contentCard mb-0 py-1">
              <div className="titleWrapperPrint my-1">
                <span style={{ fontSize: "16px" }}>
                  Purchase Parts Work Confirmation
                </span>
              </div>
              <div className=" table-responsive">
                <table className="p-orderTable table table-striped">
                  <thead>
                  <tr>
                        <th  style={{ fontSize: "16px", width: "75.590551181px", wordBreak: "break-all" }}>Category</th>
                        <th  style={{ fontSize: "16px", width: "94.488188976px", wordBreak: "break-all" }}>Model Number</th>
                        <th  style={{ fontSize: "16px", width: "226.77165354px", wordBreak: "break-all" }}>Description</th>
                        <th  style={{ fontSize: "16px", width: "56.692913386px", wordBreak: "break-all" }}>Apt</th>
                        <th  style={{ fontSize: "16px", width: "56.692913386px", wordBreak: "break-all" }}>Price</th>
                        <th  style={{ fontSize: "16px", width: "56.692913386px", wordBreak: "break-all" }}>
                          Qty
                        </th>
                        <th  style={{ fontSize: "16px", width: "113.38582677px" ,wordBreak:"break-all"}}>
                          Qty Rcvd.
                        </th>
                      </tr>
                  </thead>
                  <tbody>
                    {purchaseItemsList &&
                      purchaseItemsList.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td
                              style={{
                                fontSize: "16px",
                                border: "1px solid black",
                                borderLeft: "none",
                              }}
                            >
                              <b>{v.inventory_category}</b>
                            </td>
                            <td
                              style={{
                                fontSize: "16px",
                                border: "1px solid black",
                              }}
                            >
                              {v.model_number}
                            </td>
                            <td
                              style={{
                                fontSize: "16px",
                                // minWidth: "330px",
                                border: "1px solid black",
                              }}
                            >
                              {v.description}
                            </td>
                            <td
                              style={{
                                fontSize: "16px",
                                border: "1px solid black",
                              }}
                            >
                              {v.suite_no}
                            </td>
                            <td
                              style={{
                                fontSize: "16px",
                                border: "1px solid black",
                              }}
                            >
                              <b>${v.price}</b>
                            </td>
                            <td
                              style={{
                                fontSize: "16px",
                                border: "1px solid black",
                              }}
                            >
                              {v.quantity}
                            </td>
                            <td
                              style={{
                                fontSize: "16px",
                                // minWidth: "175px",
                                border: "1px solid black",
                                borderRight: "none",
                              }}
                            >
                              {v.quantity_received}
                              <span style={{ marginLeft: "5px" }}>
                                {v.received_date === null ? (
                                  ""
                                ) : (
                                  <>
                                    {"("}
                                    {getDateOnly(v.received_date)}
                                    {")"}
                                  </>
                                )}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              {/* <hr className="splitCard" /> */}
            </div>
            {purchaseOrderListing?.comments?.length < 1 ? (
              ""
            ) : (
              <div className="contentCard commentWrapper my-0 py-1">
                <div className="formDesign">
                  <div className="form-group">
                    <label style={{ fontSize: "20px" }}>
                      Comments
                      {/* <small>(optional)</small> */}
                    </label>
                    {/* <textarea
                      className="form-control"
                      rows="3"
                      value={addComments}
                      onChange={(e) => setAddComments(e.target.value)}
                    ></textarea> */}
                    <div className="commentLogs">
                      {purchaseOrderListing?.comments?.map((v, i) => {
                        return (
                          <div key={i} className="comment">
                            <h2 style={{ fontSize: "20px" }}>
                              {capatalizeLetters(
                                v?.created_by_user_info?.first_name +
                                "_" +
                                v?.created_by_user_info?.last_name
                              )}{" "}
                              <span style={{ fontSize: "16px" }}>
                                {getDate(v.created_at)}{" "}
                                {localStorage.getItem("role") === "viewer" ? (
                                  userAccessibility(
                                    "purchase_order_management",
                                    "delete"
                                  ) === false ? (
                                    ""
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "building_manager" ? (
                                  userAccessibility(
                                    "purchase_order_management",
                                    "delete"
                                  ) === false ? (
                                    ""
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "property_manager" ? (
                                  userAccessibility(
                                    "purchase_order_management",
                                    "delete"
                                  ) === false ? (
                                    ""
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )
                                ) : (
                                  <a
                                    href="#"
                                    className="circleBtn danger deleteThis ml-2"
                                    onClick={() => {
                                      deleteComment([v._id]);
                                    }}
                                  >
                                    <Delete2 />
                                  </a>
                                )}
                              </span>
                            </h2>
                            <p style={{ fontSize: "16px" }}>{v.comment} </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
