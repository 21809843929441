import { adminLogin } from "../../../helpers/ajax_request";

import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const notifySuccess = (msg) =>
  toast.success(msg, {
    autoClose: 3000,
  });

const notifyWarning = (msg) =>
  toast.warning(msg, {
    autoClose: 3000,
  });
const notifyError = (msg) =>
  toast.error(msg, {
    autoClose: 3000,
  });

export const loginApi = ({ username, password }) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      let data = {
        email: username,
        password: password,
      };
      adminLogin("/auth/admin/login", JSON.stringify(data))
        .then((res) => {
          if (!res) {
            notifyError("Something went wrong..Please Try Again!");
            return false;
          }
          if (res.hasOwnProperty("status")) {
            if (res.status === 200) {
              notifySuccess("Logged In Successfully!");
              // console.log(res);
              resolve({
                data: {
                  access_token: res.data.token,
                  user_detail: res.data,
                },
                message: "Login successfull",
              });
            } else if (res.status === 300) {
              notifyError(`Login Failed! ${res.message}`);
            } else {
              reject(new Error("Login failed"));
            }
          }
        })
        .catch((err) => {
          reject(new Error("Login failed"));
        });
    }, 100);
  });
