const baseDomain = "https://backendmopo.candoapartments.com";
const sessionExpireStatus = 440;

// import img from "../assets/images/"
const env = "prod";
const base =
  env === "dev" ? "http://localhost:3000/" : "https://backendmopo.candoapartments.com/";
const baseURL = `${base}`;

export { baseURL, baseDomain, sessionExpireStatus };
