import "rc-time-picker/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import Logosm from "../../../assets/images/logo.png";
import MainLayout from "../../../layouts";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import TimePickers from "rc-time-picker";
import moment from "moment";
import { TimePicker } from "antd";
import { DatePicker, Space } from "antd";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import { useReactToPrint } from "react-to-print";
import { ToastContainer, toast } from "react-toastify";
import { useFileUpload } from "use-file-upload";
import { Delete, Delete2, Edit } from "../../../Icons/Icons";
import { baseDomain } from "../../../config";
import { MaintenanceParts, PATH } from "../../../constants/paths";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

import {
  adminApiCall,
  adminApiCallForFormData,
  getDateOnly,
  capatalizeLetters,
  userAccessibility,
  getDate,
} from "../../../helpers/ajax_request";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function Index() {
  const params = useParams();
  const MaintenanceID = params.id;

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [tags, setTags] = useState([]);
  const [itemIDComplete, setItemIDComplete] = useState([]);
  const [maintenanceOrderListing, setMaintenanceOrderListing] = useState({});
  const [maintenanceServiceItemsList, setMaintenanceServiceItemsList] =
    useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [addComments, setAddComments] = useState("");
  const [enableWOrkConfirmation, setEnableWOrkConfirmation] = useState(false);
  const [enableBtnArray, setEnableBtnArray] = useState([]);
  let [file, selectFile] = useFileUpload();
  const [completeArray, setCompleteArray] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [printMS, setPrintMS] = useState(false);

  // ///////////////////////////////////////
  const [dateVal, setDateVal] = useState(moment.utc());
  const [timeList, setTimeList] = useState([{ time_in: "", time_out: "" }]);
  // console.log(timeList);
  const [itemIDs, setItemIDs] = useState([]);

  // maintenance
  const printWCMaintenance = useRef(null);
  const printTableMaintenance = useRef(null);

  const pOPrintMaintenance = useReactToPrint({
    content: () => printTableMaintenance.current,
  });
  const wCPrintMaintenance = useReactToPrint({
    content: () => printWCMaintenance.current,
  });
  const printMaintainenceOrder = (type) => {
    if (type === "mtable") {
      wCPrintMaintenance();
    } else if (type === "mwc") {
      pOPrintMaintenance();
    }
  }
  useEffect(() => {
    getMaintenanceService();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const logs = async (comment) => {
    // let data = {
    //   purchase_order_id: MaintenanceID,
    //   log_detail: {
    //     log: comment,
    //     time_in: timeIn.format("HH:mm:ss"),
    //     time_out: timeOut.format("HH:mm:ss"),
    //     item_used: [{ name: "hammer" }, { name: "nut" }],
    //   },
    // };
    // console.log("logdata: ", data);
    // let res = await adminApiCall(
    //   // "/api/admin/purchase_order/log",
    //   "POST",
    //   JSON.stringify(data)
    // );
    // if (!res) {
    //   notifyError("Something went wrong.. Please Try Again");
    //   return false;
    // }
    // if (res.hasOwnProperty("status")) {
    //   if (res.status === 200) {
    //     // notifySuccess("Log Created (add list)");
    //   } else if (res.status === 400) {
    //     notifyError(res.message);
    //   }
    // }
  };

  const getMaintenanceService = async () => {
    let data = {
      purchase_order_id: MaintenanceID,
      order_type: "maintenance_service",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        console.log("---> res", res)
        setMaintenanceOrderListing(res.data);
        setMaintenanceServiceItemsList(res.data?.maintenance_service_items);
        setIsComplete(res.data?.maintenance_service_items[0]?.is_complete);
        let completeValue = [...completeArray];
        res.data?.maintenance_service_items.map((v, i) => {
          completeValue.push(v.is_complete);
          // console.log("completeValue: ", completeValue);
        });
        setCompleteArray(completeValue);
        let tempArray = [];
        res.data?.maintenance_service_items.map((v, i) => {
          if (v.is_complete === true) {
            tempArray.push(v);
          } else {
          }
          // console.log(tempArray);
          setEnableBtnArray(tempArray);
          if (res.data.maintenance_service_items.length === tempArray.length) {
            setEnableWOrkConfirmation(true);
          } else {
            setEnableWOrkConfirmation(false);
          }
        });
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const maintenanceItemsMarkedCompleted = (e, itemId, position) => {
    const updatedCheckedState = completeArray.map((item, index) =>
      index === position ? !item : item
    );

    setCompleteArray(updatedCheckedState);
    // let tempArray = [...checkedArray];
    // console.log(e.target.checked);
    let IDArray = [...itemIDComplete];
    let ref = [...itemIDs];
    // console.log(itemId, suiteNo, desc);
    if (e.target.checked === true) {
      // tempArray.push("true");
      IDArray.push(itemId);

      ref.push(itemId);
      // console.log("add", ref);
      setItemIDs(ref);
      setShowDetails(true);
    } else {
      // tempArray.pop("true");
      IDArray.splice(IDArray.indexOf(itemId), 1);
      ref.splice(ref.indexOf(itemId), 1);

      // console.log("removed", ref);
      setItemIDs(ref);
      if (IDArray.length === 0) {
        setShowDetails(false);
      } else {
        setShowDetails(true);
      }
    }

    // setCheckedArray(tempArray);
    setItemIDComplete(IDArray);
    if (
      maintenanceOrderListing?.order_state === "PENDING" ||
      maintenanceOrderListing?.order_state === "VOID"
    ) {
      notifyError(
        `Order State should be "Approved" inorder to mark complete the item`
      );
    } else if (maintenanceOrderListing?.order_state === "APPROVED") {
      setIsComplete(e.target.checked);
    }
  };

  const submitMarkedCheckedItems = async () => {
    let ref = [...timeList];

    let newFormat = ref.map((v, i) => {
      let timeIn = moment(v.time_in, "hh:mm a");
      let timeOut = moment(v.time_out, "hh:mm a");
      return {
        item_id: itemIDs,

        time_in: timeIn.format("hh:mm a"),
        time_out: timeOut.format("hh:mm a"),
      };
    });

    // console.log("newFormat: ", newFormat);

    if (!dateVal) {
      notifyError("Please enter Work Confirmation Date");
    } else {
      let data = {
        purchase_order_id: MaintenanceID,
        order_type: MaintenanceParts,
        item_ids: itemIDComplete,
        time_log: newFormat,
        received_date: dateVal.format("MM/DD/YYYY"),
        // received_date: dateVal === "" ? "" : dateVal.format("MM/DD/YYYY"),
        is_complete: true,
      };

      console.log("data: ", data);
      // return;
      let res = await adminApiCall(
        "/api/admin/purchase_order/update_order_maintenance_service_item",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          setDateVal(moment.utc(new Date()));
          if (isComplete) {
            notifySuccess("Item(s) marked Completed!");
            setTimeList([]);
            setTimeout(() => {
              getMaintenanceService()
            }, 900)
          }

          // navigate(PATH.VIEW_EXISTING);
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }

    let dataa = {
      purchase_order_id: MaintenanceID,
      log_detail: {
        log: `Item marked Complete with Time in and out of ${newFormat.map(
          (v, i) => v.time_in + " - " + v.time_out
        )} and Item belongings of building used are ${tags.map(
          (v, i) => v.text
        )}`,
        time_log: newFormat,
        item_used: tags,
      },
    };

    // console.log("logdata: ", dataa);
    // return;

    let resp = await adminApiCall(
      "/api/admin/purchase_order/log",
      "POST",
      JSON.stringify(dataa)
    );
    if (!resp) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (resp.hasOwnProperty("status")) {
      if (resp.status === 200) {
        // notifySuccess("Log Created (add list)");
      } else if (resp.status === 400) {
        notifyError(resp.message);
      }
    }
  };

  const addComment = async () => {
    let data = {
      purchase_order_id: MaintenanceID,
      comment: { comment: addComments },
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setAddComments("");
        getMaintenanceService();
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const confirmPurchaseOrder = async () => {
    let data = {
      purchase_order_id: MaintenanceID,
    };
    // console.log(data);
    // return;
    let res = await adminApiCall(
      "/api/admin/purchase_order/confirm_order",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setTimeout(() => {
          getMaintenanceService()
        }, 300)
        notifySuccess("Maintenance Service has been Completed");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }

    if (invoiceNumber) {
      let dataa = {
        purchase_order_id: MaintenanceID,
        ...(invoiceNumber && {
          invoice_number: invoiceNumber,
        }),
      };
      // console.log(dataa);
      // return;
      let resp = await adminApiCall(
        "/api/admin/purchase_order/received_purchase_order_invoice",
        "POST",
        JSON.stringify(dataa)
      );
      if (!resp) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (resp.hasOwnProperty("status")) {
        if (resp.status === 200) {
          // navigate(PATH.VIEW_EXISTING);
        } else if (resp.status === 400) {
          notifyError(resp.message);
        }
      }
    }
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="modalTitle">Upload Document</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mt-3 mb-5"></div>

          <div className="formDesign text-center">
            {file ? (
              <div className="mb-3 text-left">
                <div>
                  {" "}
                  <b>File Name:</b> {file.name}{" "}
                </div>
                <div>
                  {" "}
                  <b>File Size:</b> {file.size / 1000} Kbs{" "}
                </div>
              </div>
            ) : (
              <div className="mb-3">No file selected</div>
            )}
            <button
              className="myBtn shadow mr-1"
              onClick={() => {
                // Single File Upload
                selectFile();
              }}
            >
              Browse File
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="formDesign">
            <button className="myBtn shadow mr-1" onClick={uploadDocument}>
              Upload
            </button>
            <button
              className="myBtn simple"
              onClick={() => {
                setModalShow(false);
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  const uploadDocument = async () => {
    // console.log("fileeee: ", file);
    let formData = new FormData();
    formData.append("purchase_order_id", MaintenanceID);
    formData.append("document", file?.file);

    if (file === null) {
      // console.log("inside if");
      notifyError("Please Select a File to Upload");
    } else if (file.file.type === "video/mp4") {
      notifyError("Video Format cannot be uploaded");
    } else if (typeof file === "object") {
      // console.log("file: ", file.file.type);
      // return;
      let res = await adminApiCallForFormData(
        "/api/admin/purchase_order/upload_file",
        formData
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("File Uploading Successful!");
          getMaintenanceService();
          setModalShow(false);
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
  };

  const deleteComment = (deleteArray) => {
    // console.log("deleteArray", deleteArray);
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          // let dataArray = [];
          // dataArray.push(deleteArray);
          data = {
            purchase_order_id: MaintenanceID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            purchase_order_id: MaintenanceID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getMaintenanceService();
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  /////////////////////////////////////////////////////

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...tags].slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  const handleTagClick = (index) => {
    // console.log("The tag at index " + index + " was clicked");
  };

  const onClearAll = () => {
    setTags([]);
  };

  const onTagUpdate = (i, newTag) => {
    const updatedTags = tags.slice();
    updatedTags.splice(i, 1, newTag);
    setTags(updatedTags);
  };

  const deleteFile = async (fileID) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete this File?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            purchase_order_id: MaintenanceID,
            id: fileID,
          };
        } else {
          data = {
            purchase_order_id: MaintenanceID,
            id: fileID,
          };
        }
        // console.log(data);
        // return;
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete_file",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getMaintenanceService();
            notifySuccess("File Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  /////////////////////////////////////////////////////////

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...timeList];
    list[index][name] = value;
    // console.log("list: ", list);
    setTimeList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...timeList];
    list.splice(index, 1);
    setTimeList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setTimeList([...timeList, { time_in: "", time_out: "" }]);
  };

  function onChange(date, dateString) {
    // console.log(date, dateString);
    setDateVal(dateString === "" ? "" : moment.utc(date));
    // formik.setValues({
    //   dateVal: date === null ? "" : date,
    // });
  }

  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Maintenance Service Details</span>
              </div>
              <div className="details">
                <div className="detailContent">
                  <h4>Maintenance Service ID</h4>
                  <h2>{maintenanceOrderListing?.order_id}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created Date</h4>
                  <h2>{getDateOnly(maintenanceOrderListing.created_at)}</h2>
                </div>
                <div className="detailContent">
                  <h4>Type</h4>
                  <h2>
                    {capatalizeLetters(
                      maintenanceOrderListing?.order_type +
                      " " +
                      maintenanceOrderListing?.order_type
                    )}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>Assigned Trade</h4>
                  <h2>{maintenanceOrderListing?.trade_info?.company}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created By</h4>
                  <h2>
                    {capatalizeLetters(
                      maintenanceOrderListing?.order_requested_by_user_info
                        ?.first_name +
                      "_" +
                      maintenanceOrderListing?.order_requested_by_user_info
                        ?.last_name
                    )}
                  </h2>
                </div>
                {/* {maintenanceOrderListing?.invoice_number ? (
                  <div className="detailContent">
                    <h4>Invoice Number</h4>
                    <h2>{maintenanceOrderListing?.invoice_number}</h2>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="detailContent">
                  <h4>State</h4>
                  <h2
                    className={`badge ${maintenanceOrderListing.order_state === "PENDING"
                      ? "grey"
                      : maintenanceOrderListing.order_state === "APPROVED"
                        ? "pending"
                        : maintenanceOrderListing.order_state === "CONFIRM"
                          ? "success"
                          : "danger"
                      }`}
                  >
                    {maintenanceOrderListing?.order_state}
                  </h2>
                </div>
              </div>
              {/* <hr className="splitCard" /> */}
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-9">
            <div className="contentCard mb-0">
              <div className="titleWrapper">
                <span>Verify Service</span>
              </div>
              <div className="mainTable table-responsive scrollable">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Log Hrs.</th>
                      <th>Apt. No.</th>
                      <th>Price</th>
                      <th className="text-center">Completed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {maintenanceServiceItemsList &&
                      maintenanceServiceItemsList.map((v, i) => {
                        return (
                          <tr key={v._id}>
                            <td>
                              <b>{v.description}</b>
                            </td>
                            {v?.is_complete === false ? (
                              <td>
                                <span
                                  className={` ${v?.is_complete ? "success" : "danger"
                                    }`}
                                >
                                  <small>-</small>
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span
                                  className={` ${v?.is_complete ? "success" : "danger"
                                    }`}
                                >
                                  {!v.received_date ? (
                                    ""
                                  ) : (
                                    <small>
                                      <b>{getDateOnly(v.received_date)}</b>
                                    </small>
                                  )}
                                  {/* <small>
                                    <b>{getDateOnly(v.received_date)}</b>
                                  </small> */}
                                  {console.log("v,v", v)}
                                  {v?.time_logs?.map((val, ind) => {
                                    return (
                                      <small key={val.time_in + "" + ind}>
                                        {" "}
                                        (
                                        {val.time_in === "Invalid date"
                                          ? "No Time In Added"
                                          : val.time_in}{" "}
                                        -{" "}
                                        {val.time_out === "Invalid date"
                                          ? "No Time Out Added"
                                          : val.time_out}
                                        )

                                      </small>
                                    );
                                  })}
                                </span>
                              </td>
                            )}
                            <td>{v.suite_no}</td>
                            <td>
                              {v.price === null ? (
                                <b></b>
                              ) : (
                                <b>
                                  $
                                  {((v.price * 100) / 100).toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 2 }
                                  )}
                                </b>
                              )}
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                className="customRadio"
                                value={v.is_complete}
                                onChange={(e) =>
                                  maintenanceItemsMarkedCompleted(e, v._id, i)
                                }
                                checked={completeArray[i]}
                                disabled={v.is_complete === true ? true : false}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="cardFooter">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="form-group">
                      <label>
                        <b>
                          Invoice No. <small>(Optional)</small>
                        </b>
                      </label>
                      <input
                        className={"form-control"}
                        name="address"
                        value={invoiceNumber}
                        onChange={(e) => {
                          setInvoiceNumber(e.target.value);
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-9 col-md-6 col-sm-12"
                    style={{ marginTop: "12px" }}
                  >
                    <button
                      className={`${maintenanceOrderListing.order_state === "CONFIRM" ||
                        !enableWOrkConfirmation ||
                        enableBtnArray.length !==
                        maintenanceServiceItemsList.length
                        ? // "myBtn info mr-1"
                        "myBtn success mr-1"
                        : "myBtn success mr-1"
                        }`}
                      style={
                        maintenanceOrderListing.order_state === "CONFIRM" ||
                          !enableWOrkConfirmation ||
                          enableBtnArray.length !==
                          maintenanceServiceItemsList.length
                          ? { cursor: "default" }
                          : { cursor: "pointer" }
                      }
                      // disabled={
                      //   maintenanceOrderListing.order_state === "CONFIRM" ||
                      //   !enableWOrkConfirmation ||
                      //   enableBtnArray.length !==
                      //     maintenanceServiceItemsList.length
                      //     ? true
                      //     : false
                      // }
                      onClick={() => {
                        confirmPurchaseOrder();
                        // navigate(PATH.VIEW_EXISTING);
                      }}
                    >
                      Invoice Received
                    </button>

                    <span className="dropUp">
                      <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                      <button
                        type="button"
                        className="myBtn simple mr-1"
                        onClick={() => {
                          setModalShow(true);
                        }}
                      >
                        Upload Photo/Doc
                      </button>
                    </span>
                    <Link className="myBtn bdr" to={PATH.VIEW_EXISTING}>
                      Back to Listing
                    </Link>
                    <button className="myBtn ml-1 h-100 " onClick={() => printMaintainenceOrder("mtable")}>
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="contentCard mt-3"
              style={
                showDetails === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <div className="formDesign">
                <h6 className="mb-4">
                  For the above Completed Items, Please Enter the Below Details:
                </h6>
                <div className="row mb-2">
                  <div className="col-lg-6">
                    <label>Date: </label>
                    <Space direction="vertical">
                      <DatePicker
                        className={"form-control"}
                        style={{ width: "50%" }}
                        defaultValue={dateVal}
                        format={"MM/DD/YYYY"}
                        onChange={onChange}
                        name="dateVal"
                      />
                    </Space>
                  </div>
                </div>
                {timeList &&
                  timeList?.map((v, i) => {
                    return (
                      <div key={i} className="row align-items-center">
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              <label>Time In: </label>
                              <div className="form-group">
                                <input
                                  type="time"
                                  className="form-control timeLog"
                                  name="time_in"
                                  value={v.time_in}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label>Time Out: </label>
                              <div className="form-group">
                                <input
                                  type="time"
                                  className="form-control timeLog"
                                  name="time_out"
                                  value={v.time_out}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="btn-box">
                          {timeList.length !== 1 && (
                            <span>
                              <MinusCircleOutlined
                                className="increaseDecreaseBtn"
                                onClick={() => handleRemoveClick(i)}
                              />
                            </span>
                          )}
                          {timeList.length - 1 === i && (
                            <span>
                              <PlusCircleOutlined
                                className="increaseDecreaseBtn"
                                onClick={handleAddClick}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                <div className="row align-items-center justify-content-between">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>
                        List any Items Belongings to Building Used by
                        Tradesperson
                      </label>
                      <ReactTags
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleDrag={handleDrag}
                        delimiters={delimiters}
                        handleTagClick={handleTagClick}
                        onClearAll={onClearAll}
                        onTagUpdate={onTagUpdate}
                        suggestions={[
                          { id: "1", text: "Hammer" },
                          { id: "2", text: "Nails" },
                          { id: "3", text: "Pliers" },
                          { id: "4", text: "Nut" },
                          { id: "5", text: "Bolt" },
                          { id: "6", text: "Tester" },
                          { id: "7", text: "Drill Machine" },
                          { id: "8", text: "Drill Bit" },
                          { id: "9", text: "Grease" },
                          { id: "10", text: "tape" },
                          { id: "11", text: "measuring tape" },
                        ]}
                        placeholder="e.g hammer"
                        minQueryLength={2}
                        maxLength={Infinity}
                        autofocus={false}
                        allowDeleteFromEmptyInput={true}
                        autocomplete={true}
                        readOnly={false}
                        allowUnique={true}
                        allowDragDrop={true}
                        inline={true}
                        allowAdditionFromPaste={true}
                        editable={true}
                        clearAll={true}
                        tags={tags}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      className={
                        maintenanceOrderListing?.order_state === "PENDING" ||
                          maintenanceOrderListing?.order_state === "VOID"
                          ? "myBtn info mt-4"
                          : "myBtn mt-4"
                      }
                      disabled={
                        maintenanceOrderListing?.order_state === "PENDING" ||
                          maintenanceOrderListing?.order_state === "VOID"
                          ? true
                          : false
                      }
                      onClick={() => {
                        submitMarkedCheckedItems();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {maintenanceOrderListing?.documents?.length === 0 ? (
              ""
            ) : (
              <div className="contentCard commentWrapper mt-3">
                <div className="titleWrapper">
                  <span>Documents</span>
                </div>
                <div className="row documentDisplayWrapper">
                  {maintenanceOrderListing?.documents?.map((v, i) => {
                    return (
                      <div
                        key={i}
                        className={`col-lg-3 col-md-12 col-sm-12 mt-2 mb-2 ${v.mime_type === "image" ? "imageView" : "fileView"
                          }`}
                      >
                        {v.mime_type === "image" ? (
                          <>
                            <a
                              className="imageDisplay"
                              href={`${baseDomain + v.url}`}
                              download={v.original_name}
                              target="_blank"
                              key={i}
                            >
                              {v.original_name}{" "}
                            </a>
                            <span
                              className="deleteFile"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                deleteFile(v._id);
                              }}
                            >
                              X
                            </span>
                          </>
                        ) : (
                          <>
                            <a
                              className="documentDisplay"
                              href={`${baseDomain + v.url}`}
                              download={v.original_name}
                              target="_blank"
                              key={i}
                            >
                              {v.original_name}{" "}
                            </a>
                            <span
                              className="deleteFile"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                deleteFile(v._id);
                              }}
                            >
                              X
                            </span>
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="contentCard commentWrapper mt-3">
              <div className="formDesign">
                <div className="form-group">
                  <label>
                    Comments <small>(optional)</small>
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={addComments}
                    onChange={(e) => setAddComments(e.target.value)}
                  ></textarea>
                </div>
                <button
                  className="myBtn success mb-2"
                  onClick={() => addComment()}
                  disabled={!addComments ? true : false}
                >
                  Add Comment
                </button>
              </div>
              <div className="commentLogs">
                {maintenanceOrderListing?.comments?.map((v, i) => {
                  return (
                    <div key={i} className="comment">
                      <h2>
                        {capatalizeLetters(
                          v?.created_by_user_info?.first_name +
                          "_" +
                          v?.created_by_user_info?.last_name
                        )}{" "}
                        <span>
                          {getDate(v.created_at)}
                          {localStorage.getItem("role") === "viewer" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : localStorage.getItem("role") ===
                            "building_manager" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : localStorage.getItem("role") ===
                            "property_manager" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : (
                            <a
                              href="#"
                              className="circleBtn danger deleteThis ml-2"
                              onClick={() => {
                                deleteComment([v._id]);
                              }}
                            >
                              <Delete2 />
                            </a>
                          )}
                        </span>
                      </h2>
                      <p>{v.comment}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ display: "none" }} className="pageWrapper">
        <div ref={printWCMaintenance} className="row">
          <div className="col-12 col-md-4 col-lg-12">
            <div
              className="row justify-content-between align-items-center"
              style={{ backgroundColor: "white" }}
            >
              <div className="col-4 col-md-4 col-lg-3">
                <div
                  className="logoWrapper"
                  style={{ backgroundColor: "white" }}
                >
                  <Link to={PATH.BUILDING_LIST}>
                    <img className="logo" src={Logosm} alt="" />
                  </Link>
                </div>
              </div>
              <div
                className="col-4 col-md-4 col-lg-3 text-center"
                style={{ margin: "10px 0px" }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      <th className="tableBorderPrint">PO ID</th>
                      <td
                        className="tableBorderPrint"
                        style={{ minWidth: "140px" }}
                      >
                        {maintenanceOrderListing?.order_id}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Building Name</th>
                      {/* change this building name to api building data -> pending  */}
                      <td className="tableBorderPrint">{maintenanceOrderListing?.building_info?.building_name}</td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "16px" }}>
                      <th className="tableBorderPrint">Status</th>
                      <td
                        className={`tableBorderPrint text ${maintenanceOrderListing?.order_state === "PENDING"
                          ? "pending"
                          : maintenanceOrderListing?.order_state ===
                            "APPROVED"
                            ? "success"
                            : maintenanceOrderListing?.order_state ===
                              "CONFIRM"
                              ? "success"
                              : "danger"
                          }`}
                        style={{ fontWeight: "900", fontSize: "22px" }}
                      >
                        {maintenanceOrderListing?.order_state === "PENDING"
                          ? "PENDING"
                          : maintenanceOrderListing?.order_state ===
                            "APPROVED"
                            ? "APPROVED"
                            : maintenanceOrderListing?.order_state === "CONFIRM"
                              ? "INVOICED"
                              : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr className="splitCard" />
          </div>
          <div className="contentCard row justify-content-between w-100">
            <div className="col-6 col-md-4 col-lg-4">
              <table className="mx-auto">
                <tbody>
                  <tr
                    style={{
                      color: "black",
                      fontSize: "16px",
                    }}
                  >
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#d97228",
                        border: "1px solid black",
                        boxSizing: "border-box",
                        color: "white",
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: "22px",
                        lineHeight: 2
                      }}
                    >
                      Maintenance Service Details
                    </th>
                  </tr>
                  {/* <tr
                      style={{
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      <th className="tableBorderPrint">Purchase Order ID</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.order_id}
                      </td>
                    </tr> */}
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Building Address</th>
                    <td className="tableBorderPrint">
                      {maintenanceOrderListing?.building_info?.street +
                        ", " +
                        maintenanceOrderListing?.building_info?.city +
                        ", " +
                        maintenanceOrderListing?.building_info?.country}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Postal Code</th>
                    <td className="tableBorderPrint">
                      {maintenanceOrderListing?.building_info?.postal_code}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Phone</th>
                    <td className="tableBorderPrint">
                      {" ("}
                      {maintenanceOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[0] !== undefined
                        ? maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[0]
                        : ""}
                      {maintenanceOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[1] !== undefined
                        ? maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[1]
                        : ""}
                      {maintenanceOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[2] !== undefined
                        ? maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[2]
                        : ""}
                      {") "}
                      {maintenanceOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[3] !== undefined
                        ? maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[3]
                        : ""}
                      {maintenanceOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[4] !== undefined
                        ? maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[4]
                        : ""}
                      {maintenanceOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[5] !== undefined
                        ? maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[5]
                        : ""}
                      {" - "}
                      {maintenanceOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[6] !== undefined
                        ? maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[6]
                        : ""}
                      {maintenanceOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[7] !== undefined
                        ? maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[7]
                        : ""}
                      {maintenanceOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[8] !== undefined
                        ? maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[8]
                        : ""}
                      {maintenanceOrderListing?.building_info?.phone_no?.split(
                        ""
                      )[9] !== undefined
                        ? maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[9]
                        : ""}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Created Date</th>
                    <td className="tableBorderPrint">
                      {getDateOnly(maintenanceOrderListing?.created_at)}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Type</th>
                    <td className="tableBorderPrint">
                      {capatalizeLetters(
                        maintenanceOrderListing?.order_type +
                        " " +
                        maintenanceOrderListing?.order_type
                      )}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Requested By</th>
                    <td className="tableBorderPrint">
                      {capatalizeLetters(
                        maintenanceOrderListing?.order_requested_by_user_info
                          ?.first_name +
                        "_" +
                        maintenanceOrderListing
                          ?.order_requested_by_user_info?.last_name
                      )}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Requested On</th>
                    <td className="tableBorderPrint">
                      {getDateOnly(
                        maintenanceOrderListing?.order_requested_at
                      )}
                    </td>
                  </tr>
                  {maintenanceOrderListing?.order_approved_by === null ? (
                    ""
                  ) : (
                    <>
                      <tr style={{ color: "black", fontSize: "18px" }}>
                        <th className="tableBorderPrint">Approved By</th>
                        <td className="tableBorderPrint">
                          {capatalizeLetters(
                            maintenanceOrderListing
                              ?.order_approved_by_user_info?.first_name +
                            "_" +
                            maintenanceOrderListing
                              ?.order_approved_by_user_info?.last_name
                          )}
                        </td>
                      </tr>
                      <tr style={{ color: "black", fontSize: "18px" }}>
                        <th className="tableBorderPrint">Approved On</th>
                        <td className="tableBorderPrint">
                          {getDateOnly(
                            maintenanceOrderListing?.order_approved_at
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                  {maintenanceOrderListing?.email_to_contractor === null ? (
                    ""
                  ) : (
                    <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">
                        Emailed To Contractor On
                      </th>
                      <td className="tableBorderPrint">
                        {/* {maintenanceOrderListing?.email_to_contractor} */}
                        {moment(
                          new Date(
                            maintenanceOrderListing?.email_to_contractor
                          ).toUTCString()
                        ).format("DD-MMMM-YYYY hh:mm a")}
                      </td>
                    </tr>
                  )}
                  {/* <tr style={{ color: "black", fontSize: "18px" }}>
                      <th className="tableBorderPrint">
                        Emailed To Contractor On
                      </th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.trade_info?.email}
                      </td>
                    </tr> */}
                </tbody>
              </table>
            </div>
            <div className="col-6 col-md-4 col-lg-4">
              <table className="w-100">
                <tbody>
                  <tr
                    style={{
                      color: "black",
                      fontSize: "16px",
                    }}
                  >
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#d97228",
                        border: "1px solid black",
                        boxSizing: "border-box",
                        color: "white",
                        fontWeight: "600",
                        textAlign: "center",
                        fontSize: "22px",
                        lineHeight: 2
                      }}
                    >
                      Trade Details
                    </th>
                  </tr>
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Name</th>
                    <td className="tableBorderPrint">
                      {maintenanceOrderListing?.trade_info_updated?.company}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Phone</th>
                    <td className="tableBorderPrint">
                      {" ("}
                      {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[0] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[0]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[1] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[1]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[2] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[2]
                        : ""}
                      {") "}
                      {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[3] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[3]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[4] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[4]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[5] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[5]
                        : ""}
                      {" - "}
                      {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[6] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[6]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[7] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[7]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[8] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[8]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                        ""
                      )[9] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[9]
                        : ""}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Fax</th>
                    <td className="tableBorderPrint">
                      {" ("}
                      {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[0] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[0]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[1] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[1]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[2] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[2]
                        : ""}
                      {") "}
                      {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[3] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[3]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[4] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[4]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[5] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[5]
                        : ""}
                      {" - "}
                      {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[6] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[6]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[7] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[7]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[8] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[8]
                        : ""}
                      {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                        ""
                      )[9] !== undefined
                        ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[9]
                        : ""}
                    </td>
                  </tr>
                  <tr style={{ color: "black", fontSize: "18px" }}>
                    <th className="tableBorderPrint">Email</th>
                    <td className="tableBorderPrint">
                      {maintenanceOrderListing?.trade_info_updated?.email}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            ref={printTableMaintenance}
            className="col-12 col-md-8 col-lg-9"
          >
            {printMS === true ? (
              <div className="col-12 col-md-4 col-lg-12">
                <div
                  className="row justify-content-between align-items-center"
                  style={{ backgroundColor: "#38393c" }}
                >
                  <div className="col-4 col-md-4 col-lg-3">
                    <div
                      className="logoWrapper"
                      style={{ backgroundColor: "#38393c" }}
                    >
                      <Link to={PATH.BUILDING_LIST}>
                        <img className="logo" src={Logosm} alt="" />
                      </Link>
                    </div>
                  </div>
                  {/* <div className="col-4 col-md-4 col-lg-3">abc</div> */}
                  <div className="col-4 col-md-4 col-lg-3 text-center">
                    <div className="details mt-2 mb-2">
                      <div className="detailContent">
                        <h4 style={{ color: "white", fontSize: "18px" }}>
                          Maintenance Service ID
                        </h4>
                        <h2 style={{ color: "white", fontSize: "18px" }}>
                          {maintenanceOrderListing?.order_id}
                        </h2>
                      </div>
                      <div className="detailContent">
                        <h4 style={{ color: "white", fontSize: "18px" }}>
                          Building Name
                        </h4>
                        <h2 style={{ color: "white", fontSize: "18px" }}>
                          {"asasasasas"}
                        </h2>
                      </div>
                      <div className="detailContent">
                        <h4 style={{ color: "white", fontSize: "18px" }}>
                          Status
                        </h4>
                        <h2
                          className={`badge ${maintenanceOrderListing?.order_state === "PENDING"
                            ? "pending"
                            : maintenanceOrderListing?.order_state ===
                              "APPROVED"
                              ? "success"
                              : maintenanceOrderListing?.order_state ===
                                "CONFIRM"
                                ? "success"
                                : "danger"
                            }`}
                        >
                          {maintenanceOrderListing?.order_state}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="contentCard mb-0">
              <div className="titleWrapperPrint">
                <span style={{ fontSize: "22px" }}>
                  Maintenance Service Work Confirmation
                </span>
              </div>
              <div className=" table-responsive">
                <table className="p-orderTable table table-striped">
                  <thead>
                    <tr>
                      <th style={{ fontSize: "18px", width: "283px", wordBreak: "break-all" }} >Description</th>
                      <th style={{ fontSize: "18px", width: "170.07874016px", wordBreak: "break-all" }} >Log Hrs.</th>
                      <th style={{ fontSize: "18px", width: "75.590551181px", wordBreak: "break-all" }} >Apt</th>
                      <th style={{ fontSize: "18px", width: "75.590551181px", wordBreak: "break-all" }} >Price</th>
                      <th
                        className="text-center"
                        style={{ fontSize: "18px", width: "75.590551181px", wordBreak: "break-all" }}

                      >
                        Completed
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {maintenanceServiceItemsList &&
                      maintenanceServiceItemsList.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td
                              style={{
                                fontSize: "18px",
                                border: "1px solid black",
                                borderLeft: "none",
                              }}
                            >
                              {v.description}
                            </td>



                            <td
                              style={{
                                fontSize: "18px",
                                border: "1px solid black",
                              }}
                            >
                              {v.received_date ?

                                <> {
                                  !v.time_logs.length ? <span>
                                    <b>{getDateOnly(v.received_date)}</b>
                                  </span>
                                    :
                                    v?.time_logs?.map((val, ind) => {
                                      return (
                                        <span key={ind}>

                                          {
                                            !!v.received_date &&
                                            <b>
                                              {ind === 0
                                                ? getDateOnly(v.received_date) +
                                                ""
                                                : ""}
                                            </b>
                                          }
                                          <br />
                                          (
                                          {val.time_in === "Invalid date"
                                            ? "No Time In Added"
                                            : val.time_in}{" "}
                                          -{" "}
                                          {val.time_out === "Invalid date"
                                            ? "No Time Out Added"
                                            : val.time_out}
                                          )
                                        </span>
                                      );
                                    })}
                                </>
                                : "-"
                              }
                            </td>



                            <td
                              style={{
                                fontSize: "18px",
                                border: "1px solid black",
                              }}
                            >
                              {v.suite_no}
                            </td>
                            <td
                              style={{
                                fontSize: "18px",
                                border: "1px solid black",
                              }}
                            >
                              <b>{v.price ? "$" + v.price : ""}</b>
                            </td>
                            <td
                              className="text-center"
                              style={{
                                fontSize: "18px",
                                border: "1px solid black",
                                borderRight: "none",
                              }}
                            >
                              <input
                                type="checkbox"
                                className="customRadio"
                                checked={v.is_complete ? true : false}
                                disabled
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <hr className="splitCard" />
            </div>
            {maintenanceOrderListing?.comments?.length < 1 ? (
              ""
            ) : (
              <div className="contentCard commentWrapper mt-3">
                {/* {bug here } */}
                <div className="formDesign">
                  <div className="form-group">
                    <label style={{ fontSize: "20px" }}>Comments </label>
                    <div className="commentLogs">
                      {maintenanceOrderListing?.comments?.map((v, i, arr) => {
                        return (
                          <div key={v._id} className="comment">
                            <h2 style={{ fontSize: "20px" }}>
                              {capatalizeLetters(
                                v?.created_by_user_info?.first_name +
                                "_" +
                                v?.created_by_user_info?.last_name
                              )}{" "}
                              <span style={{ fontSize: "16px" }}>
                                {getDate(v.created_at)}{" "}
                                {localStorage.getItem("role") === "viewer" ? (
                                  userAccessibility(
                                    "purchase_order_management",
                                    "delete"
                                  ) === false ? (
                                    ""
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "building_manager" ? (
                                  userAccessibility(
                                    "purchase_order_management",
                                    "delete"
                                  ) === false ? (
                                    ""
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "property_manager" ? (
                                  userAccessibility(
                                    "purchase_order_management",
                                    "delete"
                                  ) === false ? (
                                    ""
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )
                                ) : (
                                  <a
                                    href="#"
                                    className="circleBtn danger deleteThis ml-2"
                                    onClick={() => {
                                      deleteComment([v._id]);
                                    }}
                                  >
                                    <Delete2 />
                                  </a>
                                )}
                              </span>
                            </h2>
                            <p style={{ fontSize: "20px" }}>{v.comment}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
