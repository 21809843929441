import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileImg from "../../../assets/images/profileImg.png";
import DefaultImg from "../../../assets/images/default.png";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { addUserAction } from "./addUser-action";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Upload, message, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { adminApiCall, capatalizeLetters } from "../../../helpers/ajax_request";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomPhone from "../../../components/CustomPhone/Index";

export default function Index() {
  const [modalShow, setModalShow] = useState(false);
  const [roleType, setRoleType] = useState("Property Manager");

  const navigate = useNavigate();
  const initialValues = {
    fName: "",
    lName: "",
    email: "",
    phone: "",
    // role: "",
    password: "",
    cPassword: "",
    description: "",
  };

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  let validationSchema = yup.object({
    fName: yup
      .string("Enter first name.")
      .trim()
      .min(2, "Please enter a valid name.")
      .max(50, "Please enter a valid name.")
      .required("Please specify first name."),
    lName: yup
      .string("Enter last name.")
      .trim()
      .min(2, "Please enter a valid name.")
      .max(50, "Please enter a valid name.")
      .required("Please specify last name."),
    email: yup
      .string("Enter email.")
      .trim()
      .email("Please enter a valid email address.")
      .required("Email is required."),
    // phone: yup
    //   .string()
    //   .trim()
    //   .min(10, "Please enter 10 digit number.")
    //   .max(10, "16 digit number exceeded.")
    //   .required("Please specify Phone No."),
    password: yup
      .string()
      .required("Please specify your password.")
      .min(8, "The password should have at minimum length of 8."),
    cPassword: yup
      .string()
      .required("Please re-enter password to confirm")
      .oneOf([yup.ref("password"), null], "Password does not match"),
  });

  const onSubmit = async (values) => {
    let newRoleType = capatalizeLetters(roleType);
    let data = {
      first_name: values.fName,
      last_name: values.lName,
      phone_no: values.phone,
      email: values.email,
      password: values.password,
      role: roleType,
      description: values.description,
    };
    // console.log("data sent: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/user/create",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("User Added Successfully!");
        navigate(PATH.USER_LIST);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const handleChangeRole = (e) => {
    setRoleType(e.target.value);
    // console.log(e.target.value);
  };

  function MyVerticallyCenteredModal(props) {
    const profilePicAPI = {
      name: "file",
      listType: "picture",
      maxCount: 1,
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      headers: {
        authorization: "authorization-text",
      },
      onChange(info) {
        if (info.file.status !== "uploading") {
          // console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="modalTitle">Upload Profile Picture</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mt-3 mb-5">
            <Upload {...profilePicAPI}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </div>

          <div className="formDesign text-center">
            <button
              className="myBtn shadow mr-1"
              onClick={() => handleCloseModal()}
            >
              Confirm
            </button>
            <button
              className="myBtn shadow "
              style={{ width: "100px" }}
              onClick={() => handleCloseModal()}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <div className="formDesign text-center">
            <Link
              className="myBtn shadow "
              style={{ minWidth: "176px" }}
              to={PATH.MAINTENANCE_SERVICES}
            >
              Maintenance Service
            </Link>
            <br />
            <br />
            <Link
              className="myBtn shadow "
              style={{ minWidth: "176px" }}
              to={PATH.PURCHASE_PARTS}
            >
              Purchase Parts
            </Link>
          </div>
        </Modal.Footer> */}
      </Modal>
    );
  }

  const handlePhoneNumberChange = (phone) => {
    let output = "";
    if (phone.length === 11) {
      let cc = phone.substr(0, 1);
      let area = phone.substr(1, 3);
      let pre = phone.substr(4, 3);
      let tel = phone.substr(7, 4);

      output = `+${cc} (${area}) ${pre} ${tel}`;
      // console.log("if", phone);
      formik.setFieldValue("phone", phone);
    } else {
      if (phone.length < 11) {
        // console.log("length zero");
      } else {
        // console.log("not zero");
      }
      // console.log("else", phone);
      formik.setFieldValue("phone", phone);
    }
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="contentCard">
                <div className="titleWrapper">
                  <span>Create a User</span>
                </div>
                <div className="formDesign">
                  <form className="row" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.fName && formik.touched.fName
                              ? " is-invalid"
                              : "")
                          }
                          type="text"
                          name="fName"
                          value={formik.values.fName}
                          onChange={formik.handleChange}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.fName}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.lName && formik.touched.lName
                              ? " is-invalid"
                              : "")
                          }
                          type="text"
                          name="lName"
                          value={formik.values.lName}
                          onChange={formik.handleChange}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.lName}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.email && formik.touched.email
                              ? " is-invalid"
                              : "")
                          }
                          type="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.email}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <CustomPhone
                          className={
                            "form-control noDrpDown " +
                            (formik.errors.phone && formik.touched.phone
                              ? "is-invalid"
                              : "")
                          }
                          value={formik.values.phone}
                          onChange={(phone) => {
                            formik.handleChange(phone);
                            handlePhoneNumberChange(phone);
                          }}
                          onlyCountries={["ca"]}
                          name="phone"
                        />
                        <div className="invalid-feedback">
                          {formik.errors.phone}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Role</label>
                        <select
                          className={
                            "form-control " +
                            (formik.errors.role && formik.touched.role
                              ? " is-invalid"
                              : "")
                          }
                          name="role"
                          value={roleType}
                          onChange={(e) => handleChangeRole(e)}
                        >
                          <option value="property_manager">
                            Property Manager
                          </option>
                          <option value="building_manager">
                            Building Manager
                          </option>
                          <option value="viewer">Viewer</option>
                        </select>
                        <div className="invalid-feedback">
                          {formik.errors.role}
                        </div>
                      </div>
                      {formik.values.role}
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.password && formik.touched.password
                              ? " is-invalid"
                              : "")
                          }
                          type="password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.password}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.cPassword && formik.touched.cPassword
                              ? " is-invalid"
                              : "")
                          }
                          type="password"
                          name="cPassword"
                          value={formik.values.cPassword}
                          onChange={formik.handleChange}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.cPassword}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <div className="form-group">
                        <label>Details</label>
                        <textarea
                          className="form-control"
                          rows="5"
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="cardFooter ml-0">
                      <button className="myBtn mr-1" type="submit">
                        Add
                      </button>

                      <Link className="myBtn bdr" to={PATH.USER_LIST}>
                        Cancel
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- modal starts here --> */}
        <div className="modal fade" id="profileModal">
          <div className="modal-dialog modal-xl">
            <div className="modal-content customModal">
              <div className="modal-header">
                <div className="titleWrapper">
                  <span>Account Settings</span>
                </div>
              </div>
              <div className="modal-body">
                <div className="formDesign">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Role</label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea className="form-control" rows="5"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <Link className="myBtn shadow ml-auto" to="#">
                  Update Profile
                </Link>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </MainLayout>
  );
}
