import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Delete2, Edit, Search, File } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import ReactPaginate from "react-paginate";
import { adminApiCall } from "../../../helpers/ajax_request";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Index() {
  const [categoryList, setCategoryList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [perPage, setPerPage] = useState(50);
  const [deleteItem, setDeleteItem] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [categorySort, setCategorySort] = useState(false);

  useEffect(() => {
    categoryListAPI();
  }, [searchBar, categorySort, currentPage]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const searchHandler = (e) => {
    setSearchBar(e.target.value);
    // console.log(e.target.value);
  };

  const categoryListAPI = async () => {
    let data = {
      current_page: currentPage,
      itemsPerPage: perPage,
      search_value: searchBar,
      sort_by_category_name: categorySort,
      // sort_by_description: false,
    };
    let res = await adminApiCall(
      "/api/admin/inventory_category/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setCategoryList(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const handleChange = (e) => {
    const itemPerPage = parseInt(e.target.value);
    setPerPage(itemPerPage);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const handleChangeCheckBox = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    // console.log(e);
    // console.log(checked + value);
    // if (deleteItem) {
    let tempArray = [...deleteItem];
    if (checked) {
      tempArray.push(value);
      // console.log("deleteitem: ", deleteItem);
    } else {
      tempArray.splice(tempArray.indexOf(value), 1);
      // console.log("deleteitem: ", deleteItem);
    }
    setDeleteItem(tempArray);
    // }
  };

  const deleteCategory = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          // let dataArray = [];
          // dataArray.push(deleteArray);
          data = {
            inventory_category_id: deleteArray,
          };
        } else {
          data = {
            inventory_category_id: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/inventory_category/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            categoryListAPI();
            setDeleteItem([]);
            notifySuccess("Category Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  const categorySorting = () => {
    setCategorySort(!categorySort);
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="tabsSection">
            <ul className="nav customTabs"></ul>
          </div>

          <div className="tab-content">
            <div className="tab-pane fade show active" id="view">
              <div className="contentCard">
                <div className="titleWrapper">
                  <span>Manage Categories</span>

                  <div>
                    <Link className="myBtn shadow" to={PATH.ADD_CATEGORY}>
                      <i className="fa fa-plus-circle"></i> Add Category
                    </Link>
                  </div>
                </div>

                <div className="col-2 col-md-4 col-lg-3 col-xl-2 mb-3">
                  <div className="search">
                    <div className="searchField">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search"
                        value={searchBar}
                        onChange={searchHandler}
                      />
                      <div className="icon">
                        <Search />
                      </div>
                    </div>
                  </div>
                </div>

                {loader ? (
                  <div className="text-center">
                    <img src={Loader} alt="" />
                  </div>
                ) : noRecord ? (
                  <div className="text-center">
                    <img src={NoRecordFound} alt="" />
                  </div>
                ) : (
                  <div className="mainTable table-responsive dataTable scrollable">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th style={{ width: "100px" }}></th>
                          <th>
                            <span
                              className="sort"
                              onClick={() => categorySorting()}
                            >
                              Category Title
                            </span>{" "}
                          </th>
                          <th className="text-right" style={{ width: "210px" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryList &&
                          categoryList.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck mx-3"
                                    id={"categoryCheck" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>
                                <td>
                                  <b>{v.category_name}</b>
                                </td>
                                <td className="text-right">
                                  <Link
                                    className="circleBtn"
                                    to={`${PATH.EDIT_CATEGORY}/${v._id}`}
                                  >
                                    <Edit />
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    Showing {currentPage * perPage - perPage + 1} to{" "}
                    {totalRecord > currentPage * perPage
                      ? currentPage * perPage
                      : totalRecord}{" "}
                    of {totalRecord} entries
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers text-right"
                      id="datatable_paginate"
                    >
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        onPageChange={(e) => handlePageClick(e)}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        pageClassName={"paginate_button page-item"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>

                <div className="cardFooter">
                  <button
                    className="myBtn danger squre deleteThis"
                    onClick={() => deleteCategory(deleteItem)}
                  >
                    <Delete2 />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
