import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import Loader from "../../../assets/images/LoaderNew.gif";
import MainLayout from "../../../layouts";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Delete, Edit } from "../../../Icons/Icons";
import { PATH } from "../../../constants/paths";
import { adminApiCall, getDateOnly } from "../../../helpers/ajax_request";

export default function Index() {
  const buildingID = localStorage.getItem("building_id");

  const [unitItemListing, setUnitItemListing] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(10);
  const [perPage, setPerPage] = React.useState(10);
  const [deleteItem, setDeleteItem] = useState([]);
  const [typeSelected, setTypeSelected] = useState("BACHELOR");
  const [bathroomSelected, setBathroomSelected] = useState("1 BATHROOM");

  const [type, setType] = useState([
    { label: "Bachelor", value: "BACHELOR" },
    { label: "1 Bedroom", value: "1" },
    { label: "2 Bedroom", value: "2" },
    { label: "3 Bedroom", value: "3" },
    { label: "4 Bedroom", value: "4" },
  ]);

  const [bathroom, setBathroom] = useState([
    { label: "1 Bathroom", value: "1" },
    { label: "1.5 Bathroom", value: "1.5" },
    { label: "2 Bathroom", value: "2" },
  ]);

  const handleType = (e) => {
    // console.log(e.target.value);
    setTypeSelected(e.target.value);
  };

  const handleBathroom = (e) => {
    // console.log(e.target.value);
    setBathroomSelected(e.target.value);
  };

  const initialValues = {
    unitName: "",
    height: "",
    description: "",
  };

  // console.log(initialValues);

  useEffect(() => {
    getUnitList();
  }, [currentPage]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  let validationSchema = yup.object({
    unitName: yup.string().trim().required("Unit name is mandatory."),
    // height: yup.string().trim().required("Please provide with Square Footage."),
  });

  const getUnitList = async () => {
    let data = {
      current_page: currentPage,
      // itemsPerPage: 10,
      building_id: buildingID,
    };

    let res = await adminApiCall(
      "/api/admin/unit/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setUnitItemListing(res.data);
        console.log("geting api data",res.data)
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    console.log("all okay", values);
    let data = {
      building_id: buildingID,
      unit_no: formik.values.unitName,
      type: typeSelected,
      bathroom: bathroomSelected,
      ...(formik.values.height && { square_footage: formik.values.height }),
      ...(values.description && { note: values.description }),
      // description: values.description,
      // square_footage: formik.values.height,
    };

    console.log("data: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/unit/create",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Unit Created Successfully!");
        getUnitList();
        localStorage.setItem(
          "available_suites",
          Number(localStorage.getItem("available_suites")) - 1
        );
        resetForm();
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleChangeCheckBox = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    // console.log(e);
    // console.log(checked + value);
    // if (deleteItem) {
    let tempArray = [...deleteItem];
    if (checked) {
      tempArray.push(value);
      // console.log("deleteitem: ", deleteItem);
    } else {
      tempArray.splice(tempArray.indexOf(value), 1);
      // console.log("deleteitem: ", deleteItem);
    }
    setDeleteItem(tempArray);
    // }
  };

  const deleteInventory = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          // let dataArray = [];
          // dataArray.push(deleteArray);
          data = {
            building_id: buildingID,
            unit_id: deleteArray,
          };
        } else {
          data = {
            building_id: buildingID,
            unit_id: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/unit/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getUnitList();
            // console.log("deleteItem.length: ", deleteItem.length);
            localStorage.setItem(
              "available_suites",
              Number(localStorage.getItem("available_suites")) +
                deleteItem.length
            );
            setDeleteItem([]);
            notifySuccess("Unit Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };
console.log("unitItemListing",unitItemListing)
  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <form className="contentCard" onSubmit={formik.handleSubmit}>
            <div className="titleWrapper">
              <span>Add Apt.</span>
            </div>
            <div className="formDesign">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Building Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={localStorage.getItem("building_namee")}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Number Of Apts.</label>
                    <input
                      className="form-control"
                      type="number"
                      value={localStorage.getItem("no_of_suites")}
                      disabled
                    />
                    <span className="text">
                      Available No. Of Apts. (
                      {localStorage.getItem("available_suites")})
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Apt. Name</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.unitName && formik.touched.unitName
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      placeholder="A-12"
                      name="unitName"
                      value={formik.values.unitName}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.unitName}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <hr className="splitCard" />

                  <h3 className="sectionTitle">Apt. Types</h3>
                  <span className="text">Please Select Type of Apt.</span>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Bedroom</label>
                    <select
                      className="form-control"
                      onChange={(e) => handleType(e)}
                    >
                      {type &&
                        type.map((v, i) => {
                          return (
                            <option key={i} value={v.value}>
                              {v.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Bathroom</label>
                    <select
                      className="form-control"
                      onChange={(e) => handleBathroom(e)}
                    >
                      {bathroom &&
                        bathroom.map((v, i) => {
                          return (
                            <option key={i} value={v.value}>
                              {v.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Square Footage</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.height && formik.touched.height
                          ? " is-invalid"
                          : "")
                      }
                      type="number"
                      name="height"
                      value={formik.values.height}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.height}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8 col-lg-9">
                  <div className="form-group">
                    <label>Unit Specific Notes</label>
                    <textarea
                      className={
                        "form-control "
                        // +
                        // (formik.errors.description && formik.touched.description
                        //   ? " is-invalid"
                        //   : "")
                      }
                      name="description"
                      id=""
                      cols="30"
                      rows="3"
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    ></textarea>
                    {/* <div className="invalid-feedback">
                      {formik.errors.height}
                    </div> */}
                  </div>
                </div>
              </div>
              <button className="myBtn mr-1" type="submit">
                Add
              </button>
              <Link className="myBtn bdr" to={PATH.VIEW_TRAFFIC_SHEET}>
                Cancel
              </Link>
            </div>
          </form>
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Apt. List</span>
            </div>

            {loader ? (
              <div className="text-center">
                <img src={Loader} alt="" />
              </div>
            ) : noRecord ? (
              <div className="text-center">
                <img src={NoRecordFound} alt="" />
              </div>
            ) : (
              <div className="mainTable table-responsive dataTable overflowTable">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Apt. No.</th>
                      <th>Created At</th>
                      <th>Bedroom</th>
                      <th>Bathroom</th>
                      <th>Square Footage</th>
                      <th>Description</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unitItemListing &&
                      unitItemListing.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <input
                                type="checkbox"
                                className="customCheck"
                                id={"unitCheck" + i}
                                value={v._id}
                                checked={
                                  deleteItem && deleteItem.includes(v._id)
                                    ? true
                                    : false
                                }
                                onChange={(e) => handleChangeCheckBox(e)}
                              />
                            </td>
                            <td>
                              <b>{v.unit_no}</b>
                            </td>
                            <td>{getDateOnly(v.created_at)}</td>
                            <td>{v.type}</td>
                            <td>{v.bathroom}</td>
                            <td>{v.square_footage}</td>
                            <td>{v.note_or_description}</td>
                            <td className="text-right">
                              <div className="dropleft">
                                <button
                                  type="button"
                                  className="actionEclips dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </button>
                                <div className="dropdown-menu dropDesign">
                                  <Link to={`${PATH.EDIT_UNIT}/${v._id}`}>
                                    Edit
                                    <span>
                                      <Edit />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {unitItemListing.length > 0 ? (
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-5">
                  Showing {currentPage * perPage - perPage + 1} to{" "}
                  {totalRecord > currentPage * perPage
                    ? currentPage * perPage
                    : totalRecord}{" "}
                  of {totalRecord} entries
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers text-right"
                    id="datatable_paginate"
                  >
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      // marginPagesDisplayed={2}
                      // pageRangeDisplayed={5}
                      onPageChange={(e) => handlePageClick(e)}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      pageClassName={"paginate_button page-item"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="cardFooter">
              <button
                className="myBtn danger squre"
                onClick={() => deleteInventory(deleteItem)}
              >
                <Delete />
              </button>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
