import React from "react";
import { Link } from "react-router-dom";
import {
  Buildings,
  Inventory,
  Reports,
  Settings,
  Trade,
  Users,
} from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { adminApiCall } from "../../../helpers/ajax_request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomPhone from "../../../components/CustomPhone/Index";

export default function Index(props) {
  const { show, onHide, getTradeList, modalChange } = props;
  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const initialValues = {
    company: "",
    fName: "",
    lName: "",
    phone: "",
    fax: "",
    email: "",
    note: "",
  };

  let validationSchema = yup.object({
    company: yup
      .string("Enter Description.")
      .trim()
      .required("Please provide company name."),
    email: yup
      .string("Enter email.")
      .trim()
      .email("Please enter a valid email address."),
    // .required("Email is required."),
  });

  const onSubmit = async (values, { resetForm }) => {
    // console.log("all okay", values);
    let data = {
      company: values.company,
      ...(values.fName && {
        first_name: values.fName === "" ? "" : values.fName,
      }),
      ...(values.lName && {
        last_name: values.lName === "" ? "" : values.lName,
      }),
      ...(values.phone && {
        phone_no: values.phone === "" ? "" : values.phone,
      }),
      ...(values.fax && {
        fax: values.fax === "" ? "" : values.fax,
      }),
      ...(values.email && {
        email: values.email === "" ? "" : values.email,
      }),
      ...(values.note && {
        note: values.note === "" ? "" : values.note,
      }),
      // last_name: values.lName,
      // phone_no: values.phone,
      // fax: values.fax,
      // email: values.email,
      // note: values.note,
    };

    let res = await adminApiCall(
      "/api/admin/trade/create",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again.");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Trade Created Successfully!");
        getTradeList();
        resetForm();
        modalClose();
      } else if (res.status === 400) {
        // notifyError("Duplication in Company Name not Allowed!");
        notifyError(res.exception);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const modalClose = () => {
    modalChange(false);
  };

  const handlePhoneNumberChange = (phone) => {
    let output = "";
    if (phone.length === 11) {
      let cc = phone.substr(0, 1);
      let area = phone.substr(1, 3);
      let pre = phone.substr(4, 3);
      let tel = phone.substr(7, 4);

      output = `+${cc} (${area}) ${pre} ${tel}`;
      // console.log("if", phone);
      formik.setFieldValue("phone", phone);
    } else {
      if (phone.length < 11) {
        // console.log("length zero");
      } else {
        // console.log("not zero");
      }
      // console.log("else", phone);
      formik.setFieldValue("phone", phone);
    }
  };

  const handleContactNumberChange = (phone) => {
    let output = "";
    if (phone.length === 11) {
      let cc = phone.substr(0, 1);
      let area = phone.substr(1, 3);
      let pre = phone.substr(4, 3);
      let tel = phone.substr(7, 4);

      output = `+${cc} (${area}) ${pre} ${tel}`;
      // console.log("if", phone);
      formik.setFieldValue("fax", phone);
    } else {
      if (phone.length < 11) {
        // console.log("length zero");
      } else {
        // console.log("not zero");
      }
      // console.log("else", phone);
      formik.setFieldValue("fax", phone);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Trade
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contentCard ">
            <div className="formDesign">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Company</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.company && formik.touched.company
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="company"
                      value={formik.values.company}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.company}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.fName && formik.touched.fName
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="fName"
                      value={formik.values.fName}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.fName}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.lName && formik.touched.lName
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="lName"
                      value={formik.values.lName}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.lName}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Phone</label>
                    <CustomPhone
                      className={
                        "form-control noDrpDown " +
                        (formik.errors.phone && formik.touched.phone
                          ? "is-invalid"
                          : "")
                      }
                      value={formik.values.phone}
                      onChange={(phone) => {
                        // formik.handleChange(phone);
                        handlePhoneNumberChange(phone);
                      }}
                      onlyCountries={["ca"]}
                      name="phone"
                    />
                    <div className="invalid-feedback">
                      {formik.errors.phone}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Fax</label>
                    <CustomPhone
                      className={
                        "form-control noDrpDown" +
                        (formik.errors.fax && formik.touched.fax
                          ? " is-invalid"
                          : "")
                      }
                      value={formik.values.fax}
                      onChange={(contact) => handleContactNumberChange(contact)}
                      onlyCountries={["ca"]}
                      name="fax"
                    />
                    <div className="invalid-feedback">{formik.errors.fax}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.email && formik.touched.email
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="form-group">
                    <label>Note</label>
                    <textarea
                      className={
                        "form-control " +
                        (formik.errors.note && formik.touched.note
                          ? " is-invalid"
                          : "")
                      }
                      rows="5"
                      name="note"
                      value={formik.values.note}
                      onChange={formik.handleChange}
                    ></textarea>
                    <div className="invalid-feedback">{formik.errors.note}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="myBtn mr-1" type="submit">
            Add
          </button>
          <Link
            className="myBtn bdr"
            onClick={() => modalChange(false)}
            to={PATH.VIEW_TRADES}
          >
            Cancel
          </Link>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
