import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { adminApiCall } from "../../../helpers/ajax_request";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomPhone from "../../../components/CustomPhone/Index";

export default function Index() {
  const params = useParams();
  const buildingID = params.id;
  // console.log(buildingID);

  const navigate = useNavigate();

  const [buildingData, setbuildingData] = useState({});

  let initialValues = {
    buildingName: "",
    address: "",
    country: "",
    province: "",
    city: "",
    postalCode: "",
    legacyID: "",
    areaSize: "",
    region: "",
    // confirm with QA about below fields

    // propertyID: "23746WKkjk",
    // street: "unknown",
    intersection: "",
    phone: "",
    noOfSuites: 15,
    // pm_email: "pM_buildinggg@gmail.com",

    // below are optional fields
    description: "",
    fullName: "",
    contact: "",
    email: "",
    // buildingContactPmEmail: "",
  };

  const [phoneFlag, setPhoneFlag] = useState(false);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  useEffect(() => {
    getBuildingData();
  }, []);

  const getBuildingData = async () => {
    let data = {
      building_id: buildingID,
    };

    let res = await adminApiCall(
      "/api/admin/building/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setbuildingData(res.data);
        formik.setValues({
          buildingName: res.data?.building_name,
          address: res.data?.street,
          country: res.data?.country,
          province: res.data?.province,
          city: res.data?.city,
          postalCode: res.data?.postal_code,
          legacyID: res.data?.legacy_id,
          areaSize: res.data?.area_size,
          region: res.data?.region,
          intersection: res.data?.intersection,
          phone: res.data?.phone,
          noOfSuites: res.data?.no_of_suite,
          description: res.data?.description,
          fullName: res.data?.building_contact_full_name,
          contact: res.data?.building_contact_phone,
          email: res.data?.building_contact_email,
        });
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  let validationSchema = yup.object({
    buildingName: yup.string().trim().required("Building Name is mandatory."),
    address: yup.string().trim().required("Address is mandatory."),
    country: yup.string().trim().required("Country name is mandatory."),
    province: yup.string().trim().required("Province name is mandatory."),
    city: yup.string().trim().required("City name is mandatory."),
    postalCode: yup
      .string()
      .matches(
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
        "Enter correct postal code"
      )
      .trim()
      .required("Postal code is mandatory."),
    region: yup.string().trim().required("Region is mandatory."),
    phone: yup
      .string()
      .trim()
      .min(10, "Please enter 10 digit number.")
      .max(10, "16 digit number exceeded.")
      .required("Please specify Phone No."),
    intersection: yup.string().trim().required("Intersection is mandatory."),
    noOfSuites: yup
      .number()
      .min(1, "Suites cant be less than one.")
      .required("Please enter number of suites."),
  });

  const onSubmit = async (values) => {
    // console.log("all okay", values);
    let data = {
      building_id: buildingID,
      property_id: "aaa",
      building_name: formik.values.buildingName,
      // legacy_id: formik.values.legacyID,
      street: formik.values.address,
      city: formik.values.city,
      postal_code: formik.values.postalCode,
      province: formik.values.province,
      country: formik.values.country,
      region: formik.values.region,
      intersection: formik.values.intersection,
      // area_size: formik.values.areaSize,
      phone: formik.values.phone,
      description: formik.values.description,
      // email: formik.values.email,
      // pm_email: "bbbuilding@gmail.com",
      no_of_suite: formik.values.noOfSuites,
      building_contact_full_name: formik.values.fullName,
      building_contact_phone: formik.values.contact,
      building_contact_email: formik.values.email,
      // building_contact_pm_email: false,
    };
    let res = await adminApiCall(
      "/api/admin/building/update",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Building Updated Successfully!");
        navigate(PATH.BUILDING_LIST);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handlePhoneNumberChange = (phone) => {
    let output = "";
    if (phone.length === 11) {
      let cc = phone.substr(0, 1);
      let area = phone.substr(1, 3);
      let pre = phone.substr(4, 3);
      let tel = phone.substr(7, 4);

      output = `+${cc} (${area}) ${pre} ${tel}`;
      // console.log("if", phone);
      formik.setFieldValue("phone", phone);
    } else {
      if (phone.length < 11) {
        // console.log("length zero");
        setPhoneFlag(true);
      } else {
        // console.log("not zero");
        setPhoneFlag(false);
      }
      // console.log("else", phone);
      formik.setFieldValue("phone", phone);
    }
  };

  const handleContactNumberChange = (phone) => {
    let output = "";
    if (phone.length === 11) {
      let cc = phone.substr(0, 1);
      let area = phone.substr(1, 3);
      let pre = phone.substr(4, 3);
      let tel = phone.substr(7, 4);

      output = `+${cc} (${area}) ${pre} ${tel}`;
      // console.log("if", phone);
      formik.setFieldValue("contact", phone);
    } else {
      if (phone.length < 11) {
        // console.log("length zero");
      } else {
        // console.log("not zero");
      }
      // console.log("else", phone);
      formik.setFieldValue("contact", phone);
    }
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Update Building</span>
            </div>
            <form className="formDesign" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                  <label htmlFor="">Building Name</label>
                  <input
                    type="text"
                    className={
                      "form-control " +
                      (formik.errors.buildingName && formik.touched.buildingName
                        ? " is-invalid"
                        : "")
                    }
                    name="buildingName"
                    value={formik.values.buildingName}
                    onChange={formik.handleChange}
                  />
                  <div className="invalid-feedback">
                    {formik.errors.buildingName}
                  </div>
                </div>
              </div>
              <h3 className="sectionTitle mt-4">Address</h3>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Street Address</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.address && formik.touched.address
                          ? " is-invalid"
                          : "")
                      }
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      type="text"
                    />
                    <div className="invalid-feedback">
                      {formik.errors.address}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.country && formik.touched.country
                          ? " is-invalid"
                          : "")
                      }
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      type="text"
                    />
                    <div className="invalid-feedback">
                      {formik.errors.country}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Province</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.province && formik.touched.province
                          ? " is-invalid"
                          : "")
                      }
                      name="province"
                      value={formik.values.province}
                      onChange={formik.handleChange}
                      type="text"
                    />
                    <div className="invalid-feedback">
                      {formik.errors.province}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.city && formik.touched.city
                          ? " is-invalid"
                          : "")
                      }
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      type="text"
                    />
                    <div className="invalid-feedback">{formik.errors.city}</div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Postal Code</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.postalCode && formik.touched.postalCode
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="postalCode"
                      value={formik.values.postalCode}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.postalCode}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Region</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.region && formik.touched.region
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="region"
                      value={formik.values.region}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.region}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Phone</label>
                    <CustomPhone
                      className={
                        "form-control noDrpDown " +
                        (formik.errors.phone && formik.touched.phone
                          ? "is-invalid"
                          : "")
                      }
                      value={formik.values.phone}
                      onChange={(phone) => {
                        formik.handleChange(phone);
                        handlePhoneNumberChange(phone);
                      }}
                      onlyCountries={["ca"]}
                      name="phone"
                    />
                    <div className="invalid-feedback">
                      {formik.errors.phone}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Intersection</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.intersection &&
                        formik.touched.intersection
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="intersection"
                      value={formik.values.intersection}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.intersection}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Number Of Apts.</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.noOfSuites && formik.touched.noOfSuites
                          ? " is-invalid"
                          : "")
                      }
                      type="number"
                      name="noOfSuites"
                      value={formik.values.noOfSuites}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.noOfSuites}
                    </div>
                  </div>
                </div>
                <div className="col-12  ">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>

              <hr className="splitCard" />

              <h3 className="sectionTitle">Building Contact (Optional)</h3>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Full Name</label>
                    <input
                      className="form-control"
                      name="fullName"
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Contact Number</label>
                    <CustomPhone
                      className="form-control noDrpDown "
                      value={formik.values.contact}
                      onChange={(contact) => handleContactNumberChange(contact)}
                      onlyCountries={["ca"]}
                      name="contact"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className="form-control"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <button className="myBtn mr-1" type="submit">
                Update Building
              </button>
              <Link className="myBtn bdr" to={PATH.BUILDING_LIST}>
                Cancel
              </Link>
            </form>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
