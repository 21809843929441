import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Delete2, Edit, Delete } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import {
  adminApiCall,
  userAccessibility,
  getDate,
  capatalizeLetters,
} from "../../../helpers/ajax_request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import moment from "moment";
import Swal from "sweetalert2";

export default function Index() {
  const params = useParams();
  // console.log(params);
  const TSID = params.id;
  const TS_ID = params._id;
  const navigate = useNavigate();
  const [sheetDetail, setSheetDetail] = useState({});
  const [vacancyReportListing, setVacancyReportListing] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = React.useState(10);
  const [totalRecord, setTotalRecord] = React.useState(10);
  const [perPage, setPerPage] = React.useState(10);
  const [addComments, setAddComments] = useState([]);

  useEffect(() => {
    viewDetails();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const viewDetails = async () => {
    let data = {
      unit_id: TSID,
      traffic_sheet_id: TS_ID,
    };

    let res = await adminApiCall(
      "/api/admin/traffic_sheet/get_unit_history",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setSheetDetail(res.data);
        setVacancyReportListing(res.data?.traffic_sheet);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        if (res.data?.traffic_sheet.length === 0) {
          setNoRecord(true);
        } else {
          setNoRecord(false);
        }
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const deleteUser = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            traffic_sheet_id: [deleteArray],
          };
        } else {
          data = {
            traffic_sheet_id: [deleteArray],
          };
        }
        let res = await adminApiCall(
          "/api/admin/traffic_sheet/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            notifySuccess("Traffic Sheet Item Deleted Successfully!");
            navigate(PATH.VIEW_TRAFFIC_SHEET);
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  const deleteComment = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            traffic_sheet_id: TS_ID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            traffic_sheet_id: TS_ID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/traffic_sheet/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            viewDetails();
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="row">
          <div className="col-12 col-md-4 ">
            <div className="contentCard dark">
              <div className="titleWrapper">
                <span>View Traffic Sheet</span>
              </div>
              <div className="details">
                <div className="detailContent">
                  <h4 className="textColor">Apt. No.</h4>
                  <h2 className="textColor">{sheetDetail?.unit_no}</h2>
                </div>
                <div className="detailContent">
                  <h4 className="textColor">Standard Rent</h4>
                  <h2 className="textColor">${sheetDetail?.standard_rent}</h2>
                </div>
                <div className="detailContent">
                  <h4 className="textColor">Rent With 2% Discount</h4>
                  <h2 className="textColor">
                    ${sheetDetail?.rent_with_discount}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4 className="textColor">Status</h4>
                  <h2 className="textColor">
                    {sheetDetail?.unit_status === "OCCUPIED"
                      ? "RENTED"
                      : sheetDetail?.unit_status}
                  </h2>
                </div>
                <div className="detailContent">
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="textColor">Action</h2>
                    <div className="actionBtns">
                      <Link
                        className="circleBtn"
                        to={`${PATH.EDIT_TRAFFIC_SHEET}/${sheetDetail?._id}`}
                      >
                        <Edit />
                      </Link>
                      <Link
                        className="circleBtn deleteThis"
                        onClick={() => deleteUser(TS_ID)}
                        to={"#"}
                      >
                        <Delete2 />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 ">
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Vacancy Report</span>
              </div>
              {loader ? (
                <div className="text-center">
                  <img src={Loader} alt="" />
                </div>
              ) : noRecord ? (
                <div className="text-center">
                  <img src={NoRecordFound} alt="" />
                </div>
              ) : (
                <div className="mainTable table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Apt. No.</th>
                        <th>Size</th>
                        <th>Rent With 2% Discount</th>
                        <th>Vacate Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vacancyReportListing &&
                        vacancyReportListing.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <b>{sheetDetail?.unit_no}</b>
                              </td>
                              <td>1</td>
                              <td>${v?.rent_with_discount}</td>
                              <td>
                                {moment(v?.vacate_date).format("DD MMMM YYYY")}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="cardFooter">
                <button
                  className="myBtn bdr"
                  onClick={() => navigate(PATH.VIEW_TRAFFIC_SHEET)}
                >
                  Back To Listing
                </button>
              </div>
            </div>
            {sheetDetail?.comments?.length === 0 ? (
              ""
            ) : (
              // <div className="contentCard commentWrapper mt-3">
              //   <div className="commentLogs">
              //     {sheetDetail?.comments?.map((v, i) => {
              //       return (
              //         <div key={i} className="comment">
              //           <h2>
              //             {capatalizeLetters(
              //               v?.created_by_user_info?.first_name +
              //                 "_" +
              //                 v?.created_by_user_info?.last_name
              //             )}{" "}
              //             <span>
              //               {getDate(v.created_at)}{" "}
              //               {localStorage.getItem("role") === "viewer" ? (
              //                 userAccessibility(
              //                   "purchase_order_management",
              //                   "delete"
              //                 ) === false ? (
              //                   ""
              //                 ) : (
              //                   <a
              //                     href="#"
              //                     className="circleBtn danger deleteThis ml-2"
              //                     onClick={() => {
              //                       deleteComment([v._id]);
              //                     }}
              //                   >
              //                     <Delete2 />
              //                   </a>
              //                 )
              //               ) : localStorage.getItem("role") ===
              //                 "building_manager" ? (
              //                 userAccessibility(
              //                   "purchase_order_management",
              //                   "delete"
              //                 ) === false ? (
              //                   ""
              //                 ) : (
              //                   <a
              //                     href="#"
              //                     className="circleBtn danger deleteThis ml-2"
              //                     onClick={() => {
              //                       deleteComment([v._id]);
              //                     }}
              //                   >
              //                     <Delete2 />
              //                   </a>
              //                 )
              //               ) : localStorage.getItem("role") ===
              //                 "property_manager" ? (
              //                 userAccessibility(
              //                   "purchase_order_management",
              //                   "delete"
              //                 ) === false ? (
              //                   ""
              //                 ) : (
              //                   <a
              //                     href="#"
              //                     className="circleBtn danger deleteThis ml-2"
              //                     onClick={() => {
              //                       deleteComment([v._id]);
              //                     }}
              //                   >
              //                     <Delete2 />
              //                   </a>
              //                 )
              //               ) : (
              //                 <a
              //                   href="#"
              //                   className="circleBtn danger deleteThis ml-2"
              //                   onClick={() => {
              //                     deleteComment([v._id]);
              //                   }}
              //                 >
              //                   <Delete2 />
              //                 </a>
              //               )}
              //             </span>
              //           </h2>
              //           <p>{v.comment}</p>
              //         </div>
              //       );
              //     })}
              //   </div>
              // </div>
              <div> </div>
            )}
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
