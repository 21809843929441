import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { PATH } from "../../../constants/paths";
import MainLayout from "../../../layouts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  adminApiCall,
  capatalizeLetters,
  getDate,
  getDateOnly,
} from "../../../helpers/ajax_request";

export default function Index() {
  const params = useParams();
  const purchaseOrderID = params.id;
  // console.log(purchaseOrderID);

  const [purchaseOrderListing, setPurchaseOrderListing] = useState({});
  const [purchaseItemsList, setpurchaseItemsList] = useState([]);
  const [addComments, setAddComments] = useState("");

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const getPurchaseOrder = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      order_type: "purchase_parts",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setPurchaseOrderListing(res.data);
        setpurchaseItemsList(res.data?.purchase_items);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const addComment = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      comment: { comment: addComments },
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setAddComments("");
        getPurchaseOrder();
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };
  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Purchase Order Details</span>
              </div>
              <div className="details">
                <div className="detailContent">
                  <h4>Purchase Order ID</h4>
                  <h2>{purchaseOrderListing?.order_id}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created Date</h4>
                  <h2>{getDateOnly(purchaseOrderListing?.created_at)}</h2>
                </div>
                <div className="detailContent">
                  <h4>Type</h4>
                  <h2>
                    {capatalizeLetters(
                      purchaseOrderListing?.order_type +
                        " " +
                        purchaseOrderListing?.order_type
                    )}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>Assigned Trade</h4>
                  <h2>{purchaseOrderListing?.trade_info?.company}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created By</h4>
                  <h2>
                    {capatalizeLetters(
                      purchaseOrderListing?.order_requested_by_user_info
                        ?.first_name +
                        "_" +
                        purchaseOrderListing?.order_requested_by_user_info
                          ?.last_name
                    )}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>State</h4>
                  <h2
                    className={`badge ${
                      purchaseOrderListing?.order_state === "PENDING"
                        ? "pending"
                        : purchaseOrderListing?.order_state === "APPROVED"
                        ? "success"
                        : purchaseOrderListing?.order_state === "CONFIRM"
                        ? "success"
                        : "danger"
                    }`}
                  >
                    {purchaseOrderListing?.order_state}
                  </h2>
                </div>
              </div>
            </div>
            {purchaseOrderListing?.logs?.length < 1 ? (
              ""
            ) : (
              <div className="contentCard">
                <div className="titleWrapper">
                  <span>Logs</span>
                </div>
                <div className="commentLogs">
                  {purchaseOrderListing?.logs?.map((v, i) => {
                    return (
                      <div key={i} className="comment">
                        <h2>
                          {capatalizeLetters(
                            v?.created_by_user_info?.first_name +
                              "_" +
                              v?.created_by_user_info?.last_name
                          )}{" "}
                          <span>{getDate(v.created_at)}</span>
                        </h2>
                        <p>{v.log_detail?.log}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="col-12 col-md-8 col-lg-9">
            <div className="contentCard mb-0">
              <div className="titleWrapper">
                <span>Verify Order</span>
              </div>
              <div className="mainTable table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Model Number</th>
                      <th>Description</th>
                      <th>Apt. No.</th>
                      <th>Price</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseItemsList &&
                      purchaseItemsList.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <b>{v.inventory_category}</b>
                            </td>
                            <td>{v.model_number}</td>
                            <td>{v.description}</td>
                            <td>{v.suite_no}</td>
                            <td>
                              <b>${((v.price * 100) / 100).toLocaleString()}</b>
                            </td>
                            <td>{v.quantity}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <hr className="splitCard" />
              <div className="formDesign">
                <Link className="myBtn bdr" to={PATH.PO_REPORT}>
                  Back To Listing
                </Link>
              </div>
            </div>

            {purchaseOrderListing?.comments?.length === 0 ? (
              ""
            ) : (
              <div className="contentCard commentWrapper mt-3">
                <h6 htmlFor="">Comments</h6>
                <div className="commentLogs">
                  {purchaseOrderListing?.comments?.map((v, i) => {
                    return (
                      <div key={i} className="comment">
                        <h2>
                          {capatalizeLetters(
                            v?.created_by_user_info?.first_name +
                              "_" +
                              v?.created_by_user_info?.last_name
                          )}{" "}
                          <span>{getDate(v.created_at)}</span>
                        </h2>
                        <p>{v.comment}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
