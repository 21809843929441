import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "./login-thunks";
import { Link, useNavigate } from "react-router-dom";
import { PATH } from "../../../constants/paths";
import { baseURL } from "../../../config";
import { userAccessibility } from "../../../helpers/ajax_request";
import { PasswordIcon, UserIcon } from "../../../Icons/Icons";
const Login = () => {
  const rememberedUser = localStorage.getItem("rememberUser");
  const rememberedPassword = localStorage.getItem("rememberPass");
  const rememberedCheckBox = localStorage.getItem("rememberMe");
  useEffect(() => {
    if (rememberedUser && rememberedPassword !== "") {
      setUsername(rememberedUser);
      setPassword(rememberedPassword);
      setRememberMe(rememberedCheckBox === "true" ? true : false);
    }
  }, []);
  localStorage.removeItem("building_name");
  localStorage.removeItem("building_id");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleUsername = (event) => {
    setUsername(event.target.value);
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleCheckBox = (event) => {
    setRememberMe(event.target.checked);
  };
  const submit = async (event) => {
    if (username !== "" && rememberMe === true) {
      localStorage.setItem("rememberUser", username);
      localStorage.setItem("rememberPass", password);
      localStorage.setItem("rememberMe", "true");
    } else {
      localStorage.removeItem("rememberUser");
      localStorage.removeItem("rememberPass");
      localStorage.setItem("rememberMe", "false");
    }
    const payload = { username, password };
    dispatch(login(payload))
      .then((res) => {
        // console.log("res: ", res);

        /// ROLES and PERMISSIONS login Page
        if (localStorage.getItem("role") === "viewer") {
          // console.log("viewer");
          if (userAccessibility("building_management", "create") === false) {
            navigate(PATH.HOME); //// this if is for viewer
          } else {
            navigate(PATH.HOME);
          }
        } else if (localStorage.getItem("role") === "building_manager") {
          // console.log("building manager");
          if (userAccessibility("building_management", "create") === false) {
            // console.log("building if");
            navigate(PATH.HOME); //// this is for building manager
          } else {
            // console.log("building else");
            navigate(PATH.HOME);
          }
        } else if (localStorage.getItem("role") === "property_manager") {
          // console.log("property manager");
          if (userAccessibility("building_management", "create") === false) {
            navigate(PATH.HOME); //// this is for property manager
          } else {
            navigate(PATH.HOME);
          }
        } else {
          navigate(PATH.HOME); /// super admin
        }
      })
      .catch((err) => {
        setError(err.payload.message);
      });
  };

  const handlePress = (e) => {
    // console.log(e.keyCode);
    if (e.keyCode === 13) {
      e.preventDefault();
      // console.log("enter press here! ");
      submit();
    } else {
      // console.log("button not clicked!");
    }
  };
  return (
    <>
      <section className="authSection">
        <img className="design" src={"assets/images/loginBg.png"} />;
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="loginForm">
              <div className="innerWrapper">
                <form onSubmit={submit}>
                  <div className="logoLine">
                    <img src={"assets/images/logo.png"} alt="" />
                  </div>
                  <div className="form-group formIcon">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="User Name"
                      value={username}
                      onChange={handleUsername}
                      onKeyUp={(e) => handlePress(e)}
                    />
                    <div className="icon">
                      <UserIcon />
                    </div>
                  </div>
                  <div className="form-group formIcon">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={handlePassword}
                      onKeyUp={(e) => handlePress(e)}
                    />
                    <div className="icon">
                      <PasswordIcon />
                    </div>
                  </div>

                  <a
                    className="myBtn gradient"
                    type="submit"
                    onClick={(e) => {
                      submit(e);
                    }}
                    onKeyUp={(e) => handlePress(e)}
                    href="#"
                  >
                    SIGN IN
                  </a>

                  <div className="acceptTerm">
                    <div className="acceptCheck">
                      <input
                        className="customCheck"
                        type="checkbox"
                        id="accept"
                        value={rememberMe}
                        onChange={handleCheckBox}
                        checked={rememberMe === true ? "checked" : false}
                      />
                      <label className="ml-1" htmlFor="accept">
                        Remember me
                      </label>
                    </div>
                    <Link to={PATH.FORGOT_PASSWORD}>Forgot Password?</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 loginBg">
            <div className="authSideWrapper">
              <div className="innerWrapper">
                <div className="logoWhite">
                  <img src={baseURL + "assets/images/logo.png"} alt="" />
                </div>
                {/* <p>
                  Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed
                  Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna
                  Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Etc
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
