import React, { useState } from "react";
import style from "../../../assets/css/auth.module.css";
import { Form, Input, Button, Checkbox } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faShield,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";

import { Link, useNavigate } from "react-router-dom";
import { PATH } from "../../../constants/paths";

import Validator from "validator";

import { baseURL } from "../../../config";
import { forgotPassword, resetPassword } from "../../../helpers/ajax_request";
import { PasswordIcon, UserIcon, WorkConfirmation } from "../../../Icons/Icons";

export default function Index() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [passwords, setPasswords] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [code, setCode] = useState("");
  const [showReset, setshowReset] = useState(false);
  // console.log(passwords);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  function inputHandleChangeCnfirmPass(e) {
    const value = e.target.value;
    setCpassword(value);
  }

  function inputHandleChangeCode(e) {
    const value = e.target.value;
    setCode(value);
  }

  async function handleSubmit(e) {
    if (email === "") {
      notifyError("Please Fill Email Field");
    } else {
      let data = {
        email: email,
      };

      let res = await forgotPassword(
        "/auth/admin/forgot_password",
        JSON.stringify(data)
      );

      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess(res.message);
          setshowReset(true);
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
  }
  async function handleSubmitRestPass(e) {
    e.preventDefault();
    console.log(code);
    if (passwords === "") {
      notifyError("Please Fill Password Field");
    } else if (code === "") {
      notifyError("Please enter Verification Code");
    } else if (passwords.split("").length < 8) {
      notifyError("Password (Minimum 8 characters and Alphanumeric");
    } else if (passwords !== cpassword) {
      notifyError("Password doesnt match");
    } else {
      let data = {
        email: email,
        verification_code: code,
        new_password: passwords,
      };

      let res = await resetPassword(
        "/auth/admin/reset_password",
        JSON.stringify(data)
      );

      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess(res.message);
          navigate("/");
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
  }
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleUsername = (event) => {
    setEmail(event.target.value);
    // console.log(event.target.value);
  };
  const handlePassword = (event) => {
    setPasswords(event.target.value);
  };
  return !showReset ? (
    <section className="authSection">
      <img className="design" src={baseURL + "assets/images/loginBg.png"} />;
      <div className="row">
        <div className="col-12 col-sm-6">
          <div className="loginForm">
            <div className="innerWrapper">
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <div className="logoLine">
                  <img src={baseURL + "assets/images/logo.png"} alt="" />
                </div>
                <div className={style.formHeader}>
                  <Link to="/" className={style.backLink}>
                    <FontAwesomeIcon
                      style={{
                        backgroundColor: "#d94728",
                        borderRadius: "50%",
                        padding: "7px",
                        fontSize: "18px",
                      }}
                      icon={faChevronLeft}
                    ></FontAwesomeIcon>
                  </Link>
                </div>

                <div className={`${style.pageForm} ${style.loginForm}`}>
                  <h1 className={style.formHeading}>
                    Please provide us your email to generate OTP.
                  </h1>
                  <div className={style.orArea}></div>
                  {/* <label className={`${style.commonLabel} mt-0`}>
                    Email <span>*</span>
                  </label> */}
                  <Form.Item
                    label=""
                    // name="email"
                    placeholder="Email address"
                    className="form-group formIcon"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please input your Email!",
                      },
                    ]}
                  >
                    <input
                      type="email"
                      className={`form-control mt-0`}
                      placeholder="Email address"
                      name="email"
                      value={email}
                      onChange={handleUsername}
                    />
                    <div className="icon">
                      <UserIcon />
                    </div>
                  </Form.Item>
                  <button
                    className={"myBtn gradient"}
                    htmltype="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
                {/* </div> */}
              </Form>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 loginBg">
          <div className="authSideWrapper">
            <div className="innerWrapper">
              <div className="logoWhite">
                <img src={baseURL + "assets/images/logo.png"} alt="" />
              </div>
              <p>
                Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed
                Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna
                Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section className="authSection">
      <img className="design" src={baseURL + "assets/images/loginBg.png"} />;
      <div className="row">
        <div className="col-12 col-sm-6">
          <div className="loginForm">
            <div className="innerWrapper">
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <div className="logoLine">
                  <img src={baseURL + "assets/images/logo.png"} alt="" />
                </div>
                <div className={style.formHeader}>
                  <Link
                    to="#"
                    className={style.backLink}
                    onClick={() => {
                      setPasswords("");
                      setCpassword("");
                      setCode("");
                      setshowReset(false);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        backgroundColor: "#d94728",
                        borderRadius: "50%",
                        padding: "7px",
                        fontSize: "18px",
                      }}
                      icon={faChevronLeft}
                    ></FontAwesomeIcon>
                  </Link>
                </div>

                <div className={`${style.pageForm} ${style.loginForm}`}>
                  <h1 className={style.formHeading}>Reset Password</h1>
                  <div className={style.orArea}></div>
                  {/* <label className={`${style.commonLabel} mt-0`}>
                    Email <span>*</span>
                  </label> */}
                  <Form.Item
                    label=""
                    // name="email"
                    placeholder="Verification code"
                    className="form-group formIcon"
                    rules={[
                      {
                        type: "number",
                        required: true,
                        message: "Please enter verification code",
                      },
                    ]}
                  >
                    <input
                      type="number"
                      className={`form-control mt-0`}
                      placeholder="Enter verification code"
                      name="verificationCode"
                      value={code}
                      onChange={inputHandleChangeCode}
                    />
                    <div className="icon">
                      <FontAwesomeIcon
                        style={{ fontSize: "30px" }}
                        icon={faShieldHalved}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    label=""
                    // name="email"
                    placeholder="Password"
                    className="form-group formIcon"
                    rules={[
                      {
                        type: "password",
                        required: true,
                        message: "Please enter new password",
                      },
                    ]}
                  >
                    <input
                      type="password"
                      className={`form-control mt-0`}
                      placeholder="Enter password"
                      name="passwords"
                      value={passwords}
                      onChange={handlePassword}
                    />
                    <div className="icon">
                      <PasswordIcon />
                    </div>
                  </Form.Item>
                  <Form.Item
                    label=""
                    // name="email"
                    placeholder="Confirm Password"
                    className="form-group formIcon"
                    rules={[
                      {
                        type: "password",
                        required: true,
                        message: "Please enter new Password!",
                      },
                    ]}
                  >
                    <input
                      type="password"
                      className={`form-control mt-0`}
                      placeholder="Confirm password"
                      name="cpassword"
                      value={cpassword}
                      onChange={inputHandleChangeCnfirmPass}
                    />
                    <div className="icon">
                      <PasswordIcon />
                    </div>
                  </Form.Item>
                  <button
                    className={"myBtn gradient"}
                    htmltype="submit"
                    onClick={handleSubmitRestPass}
                  >
                    Reset
                  </button>
                </div>
                {/* </div> */}
              </Form>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 loginBg">
          <div className="authSideWrapper">
            <div className="innerWrapper">
              <div className="logoWhite">
                <img src={baseURL + "assets/images/logo.png"} alt="" />
              </div>
              <p>
                Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed
                Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna
                Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
