import React from "react";
import { useState, useRef, useEffect } from "react";
import MainLayout from "../../layouts";
import { DatePicker, Space } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
// import Logo from "../../assets/images/logo.png";
import Logo from "../../assets/images/logo.png";

import {
  createApplcation,
  updateApplication,
} from "../../helpers/ajax_request";
import SignatureCanvas from "react-signature-canvas";
import $ from "jquery";
import { Modal, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Delete2, Print } from "../../../Icons/Icons";
import { Print } from "../../Icons/Icons";
import { userApiCallApplication } from "../../helpers/ajax_request";
import { baseDomain } from "../../config";
import * as Yup from "yup";

import { getApplication } from "../../helpers/ajax_request";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import momentTz from "moment-timezone";
import { Tabs } from "antd";
import ReactInputMask from "react-input-mask";
import statics from "../../assets/images/statics.bmp";
const { TabPane } = Tabs;

const Application = () => {
  const [isValid, setIsValid] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [checkResponse, setCheckResponse] = useState(false);
  // const [signatureSaved, setSignatureSaved] = useState(false);
  // const [signatureSavedCreditTenant, setSignatureSavedCreditTenant] = useState(false);
  // const [signatureSavedSmokeTenant, setSignatureSavedSmokeTenant] = useState(false);
  // const [signatureSavedLimmiterTenant, setSignatureSavedLimmiterTenant] = useState(false);

  const [fields, setFields] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      sin: "",
      email: "",
    },
  ]);
  const [occupantFields, setOccupantFields] = useState([
    {
      firstNameOccupant: "",
      middleNameOccupant: "",
      lastNameOccupant: "",
      sinOccupant: "",
      emailOccupant: "",
    },
  ]);
  const [visitedTabs, setVisitedTabs] = useState([]);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadLoadingImg, setUploadLoadingImg] = useState(false);

  const [requestDate, setRequestDate] = useState(moment.utc());
  const [creditDate, setcreditDate] = useState(moment.utc());
  const [statuse, setStatuse] = useState("");

  const [limmiterDate, setLimmitertDate] = useState(moment.utc());
  const [limmiterDate2, setLimmitertDate2] = useState(moment.utc());
  const [limmiterDate3, setLimmitertDate3] = useState(moment.utc());

  const [smokeDate, setSmokeDate] = useState(moment.utc());
  const [visible, setVisible] = useState(false);
  const [agreementDate, setAgreementDate] = useState(moment.utc());
  const [acceptanceDate, setAcceptanceDate] = useState(moment.utc());
  // const [dobApplicantOnes, setDobApplicantOnes] = useState(moment.utc());
  const [dobApplicantOnes, setDobApplicantOnes] = useState("");

  const [dobApplicantTwos, setDobApplicantTows] = useState("");
  const [dobApplicantThrees, setDobApplicantThrees] = useState("");
  const [tenentStartDate, setTenentStartDate] = useState("");
  const [tenentEndDate, setTenentEndDate] = useState("");
  const [proStartDate, setProStartDate] = useState("");
  const [proEndDate, setProEndDate] = useState("");
  const [divWidth, setDivWidth] = useState("");
  const [divWidthCreditBm, setDivWidthCreditBm] = useState("");

  const [divWidthSmoke, setDivWidthSmoke] = useState("");
  const [divWidthLimmiter, setDivWidthLimmiter] = useState("");

  const [divWidhCreditTenent1, setDivWidthCreditTenent1] = useState("");
  const [divWidhCreditTenent2, setDivWidthCreditTenent2] = useState("");
  const [divWidhCreditTenent3, setDivWidthCreditTenent3] = useState("");

  const [divWidth1, setDivWidth1] = useState("");
  const [divHeight1, setDivHeight1] = useState("");
  const [divWidth2, setDivWidth2] = useState("");
  const [divHeight2, setDivHeight2] = useState("");
  const [divHeightSmoke, setDivHeightSmoke] = useState("");
  const [divHeightLimmiter, setDivHeightLimmiter] = useState("");
  const [divHeightCreditBm, setDivHeightCreditBm] = useState("");

  const [divHeightCreditTenent1, setDivHeightCreditTenent1] = useState("");
  const [divHeightCreditTenent2, setDivHeightCreditTenent2] = useState("");
  const [divHeightCreditTenent3, setDivHeightCreditTenent3] = useState("");

  const [signPadTenent, setSignPadTenent] = useState("");
  const [signPadTenent2, setSignPadTenent2] = useState("");
  const [signPadTenent3, setSignPadTenent3] = useState("");

  const [signPadTenentSmoke, setSignPadTenentSmoke] = useState("");

  const [signPadTenentSmoke2, setSignPadTenentSmoke2] = useState("");
  const [signPadTenentSmoke3, setSignPadTenentSmoke3] = useState("");

  const [signPadBuildingManager, setSignPadBuldingManager] = useState("");
  const [signPadPropertyManager, setSignPadPropertyManager] = useState("");
  const [signPadCreditBm, setSignPadCreditBm] = useState("");
  const [signPadCreditTenent1, setSignPadCreditTenent1] = useState("");
  const [signPadCreditTenent2, setSignPadCreditTenent2] = useState("");
  const [signPadCreditTenent3, setSignPadCreditTenent3] = useState("");

  const [signPadTenentLimmiter, setSignPadTenentLimmiter] = useState("");
  const [signPadTenentLimmiter2, setSignPadTenentLimmiter2] = useState("");

  const [signPadTenentLimmiter3, setSignPadTenentLimmiter3] = useState("");

  // const [signaturingPad, setSignaturingPad] = useState("");
  const [signaturingPadTenent, setSignaturingPadTenent] = useState("");
  const [signaturingPadBuldingManager, setSignaturingPadBuldingManager] =
    useState("");
  const [signaturingPadPropertyManager, setSignaturingPadPropertyManager] =
    useState("");
  const [divHeight, setDivHeight] = useState("300");
  // images
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  // file
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [uploadProgressFile, setUploadProgressFile] = useState(0);
  const [modalVisible, setModalVisible] = useState(false); // State to control the visibility of the modal
  const [showLogo, setShowLogo] = useState(false);
  // check boxes
  const [newApplicants, setNewApplicants] = useState(false);
  const [assignments, setAssignments] = useState(false);
  const [sublets, setSublets] = useState(false);
  const [transfers, setTransfers] = useState(false);
  const [nameChanges, setNameChanges] = useState(false);
  const [activeTab, setActiveTab] = useState("1: Application");

  const [tenantView, setTenantView] = useState("");
  const [creditTenantOneView, setCreditTenantOneView] = useState("");
  const [creditTenantTwoView, setCreditTenantTwoView] = useState("");
  const [limmterTenantView, setLimmterTenantView] = useState("");
  const [cosmeticTenantView, setCosmeticTenantView] = useState("");

  const [buildingManagerView, setBuildingManagerView] = useState("");
  const [propertyManagerView, setPropertyManagerView] = useState("");
  const [responseFileUrl, setResponseFileUrl] = useState(null);
  const [responseImageUrl, setResponseImageUrl] = useState("");
  const [isSignatureSigned, setIsSignatureSigned] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [fieldValidation, setFieldValidation] = useState([]);
  const [occupantFieldValidation, setOccupantFieldValidation] = useState([]);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isCurrentSectionComplete, setIsCurrentSectionComplete] =
    useState(false);
  const [type, setType] = useState([
    { label: "Bachelor", value: "BACHELOR" },
    { label: "1 Bedroom", value: "1 Bedroom" },
    { label: "2 Bedroom", value: "2 Bedroom" },
    { label: "3 Bedroom", value: "3 Bedroom" },
    { label: "4 Bedroom", value: "4 Bedroom" },
  ]);
  const [typeSelected, setTypeSelected] = useState("BACHELOR");
  const navigate = useNavigate();

  // All Response
  const [responseData, setResponseData] = useState([]);
  const listingRef1 = useRef();
  const listingRef2 = useRef();
  const listingRef3 = useRef();
  const listingRef4 = useRef();
  const listingRef5 = useRef();

  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);
  const thirdInputRef = useRef(null);
  const fourthInputRef = useRef(null);
  const fifthInputRef = useRef(null);
  const sixthInputRef = useRef(null);
  const seventhInputRef = useRef(null);
  const eigththInputRef = useRef(null);
  const ninthInputRef = useRef(null);
  const tenthInputRef = useRef(null);
  const eleventhInputRef = useRef(null);
  const twelvethInputRef = useRef(null);
  const thirteenthInputRef = useRef(null);
  const fourteenthInputRef = useRef(null);
  const fifteenthInputRef = useRef(null);
  const sixteenthInputRef = useRef(null);
  const seventeenthInputRef = useRef(null);
  const eighteenthInputRef = useRef(null);
  const nineteenthInputRef = useRef(null);
  const twenteenthInputRef = useRef(null);
  const twentyoneInputRef = useRef(null);
  const twentytwoInputRef = useRef(null);
  const twentythreeInputRef = useRef(null);
  const twentyfourInputRef = useRef(null);
  const twentyfiveInputRef = useRef(null);
  const twentysixInputRef = useRef(null);
  const twentysevenInputRef = useRef(null);
  const twentyeightInputRef = useRef(null);
  const twentynineInputRef = useRef(null);
  const thirtyInputRef = useRef(null);
  const fourtyoneInputRef = useRef(null);
  const thirtyoneInputRef = useRef(null);
  const thirtytwoInputRef = useRef(null);
  const thirtythreeInputRef = useRef(null);
  const thirtyfourInputRef = useRef(null);
  const thirtyfiveInputRef = useRef(null);
  const thirtysixInputRef = useRef(null);
  const thirtysevenInputRef = useRef(null);
  const thirtyeightInputRef = useRef(null);
  const thirtynineInputRef = useRef(null);
  const fourtyInputRef = useRef(null);
  const fourtytwoInputRef = useRef(null);
  const fourtythreeInputRef = useRef(null);

  const fourtyfourInputRef = useRef(null);
  const fourtyfiveInputRef = useRef(null);
  const fourtysixInputRef = useRef(null);

  const handleModalApp = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    setVisible(!modalVisible);
  };
  const handleType = (e) => {
    // console.log(e.target.value);
    setTypeSelected(e.target.value);
  };
  const params = useParams();
  const applicationID = params.id;
  // checkboxes
  const newApplicant = () => {
    setNewApplicants(!newApplicants);
  };
  const assignment = () => {
    setAssignments(!assignments);
  };
  const sublet = () => {
    setSublets(!sublets);
  };
  const transfer = () => {
    setTransfers(!transfers);
  };
  const nameChange = () => {
    setNameChanges(!nameChanges);
  };
  // const handleNext = () => {
  //   const currentIndex = tabs?.indexOf(activeTab);
  //   if (currentIndex < tabs?.length - 1) {
  //     setActiveTab(tabs[currentIndex + 1]);
  //   }
  // };
  const handleBack = () => {
    const currentIndex = tabs?.indexOf(activeTab);
    if (currentIndex > 0) {
      setActiveTab(tabs[currentIndex - 1]);
    }
  };

  // checkboxes
  console.log("newApplicants", newApplicants);

  function getExtension(filename) {
    // Split the filename at the last period
    const parts = filename?.split(".");

    // Check if there's at least one extension part (including underscore)
    if (parts?.length > 1) {
      // Get the last part (extension)
      const extension = parts?.pop();

      // Split the extension at the underscore
      const extensionParts = extension.split("_");

      // Return the part before the underscore (if it exists)
      return extensionParts.length > 0 ? extensionParts[0] : "";
    }
    return "";
  }
  // file
  const renderPreview = () => {
    if (filePreview) {
      if (selectedFile.type.startsWith("image/")) {
        return (
          <img src={filePreview} alt="Preview" style={{ maxWidth: "200px" }} />
        );
      } else if (selectedFile.type === "application/pdf") {
        return (
          <embed
            src={filePreview}
            type="application/pdf"
            maxWidth="200px"
            maxHeight="16rem"
          />
        );
      } else {
        return <p>{selectedFile.name}</p>;
      }
    } else {
      return <p></p>;
    }
  };

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  //------------------------------- Old one -----------------
  // const handleFileChange = async (event) => {
  //   const file = event.target.files[0];

  //   setSelectedFile(file);
  //   setFilePreview(URL.createObjectURL(file));
  //   setUploadProgress(0);

  //   // Create a FormData object to send the file in the request
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   try {
  //     // Make a POST request to upload the image with the authorization token in the headers
  //     const response = await fetch(`${baseDomain}/api/user/upload_document`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: Application_token, // Replace YOUR_AUTH_TOKEN with the actual token
  //       },
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       // Image uploaded successfully, handle the response data if needed
  //       const responseData = await response.json();
  //       console.log("Upload success:", responseData);
  //       setResponseFileUrl(responseData?.keys);
  //       setSelectedFile(responseData?.keys);

  //       toast.success("Proof of Employment Uploaded Successfully");
  //     } else {
  //       console.log("response==> s3", response);
  //       // Handle error responses
  //       console.error("Upload failed:", response.message);
  //       toast.error("Upload failed", response.message);
  //     }
  //   } catch (error) {
  //     console.error("Error occurred during upload:", error);
  //   }
  // };

  // -------------------------------new one -------------------
  // ---------------------- Multi images Upload ---------------------------------- >>>>>>>>>>
  const handleFileChange = async (event) => {
    const files = event.target.files;
    const fileArray = Array.from(files);
    // Upload all files together
    uploadFilesProof(fileArray);
  };

  const uploadFilesProof = async (files) => {
    // Create a FormData object to send all files in the request
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`files`, file);
    });
    try {
      // Make a POST request to upload the files with the authorization token in the headers
      setUploadLoading(true);
      const response = await fetch(
        `${baseDomain}/api/user/upload_document?isImage=employment`,
        {
          method: "POST",
          headers: {
            Authorization: Application_token, // Replace YOUR_AUTH_TOKEN with the actual token
          },
          body: formData,
        }
      );

      if (response.ok) {
        // Files uploaded successfully, handle the response data if needed
        const responseData = await response.json();
        console.log("Upload success:", responseData);
        setResponseFileUrl(responseData?.keys);
        setSelectedFile(responseData?.keys); // Handle the response accordingly
        toast.success("Files Uploaded Successfully");
        setUploadLoading(false);
      } else if (response.status === 400) {
        const responseData = await response.json();
        console.error("Upload failed:", responseData);
        toast.error("This image is Already Uploaded");
        setUploadLoading(false);
      }
    } catch (error) {
      console.error("Error occurred during upload:", error);
    }
  };

  const handleRemoveFile = () => {
    URL.revokeObjectURL(filePreview);
    setSelectedFile(null);
    setFilePreview(null);
    setUploadProgress(0);
  };

  // get buffer from blob

  // file
  // images

  // const handleImageChange = async (event) => {
  //   const file = event.target.files[0];
  //   setSelectedImage(URL.createObjectURL(file));
  //   setUploadProgress(0); // Reset upload progress when a new image is selected

  // };

  // -----------------------  Single Item Upload  -------------------------------- >>>>>>>>>>
  // const handleImageChange = async (event) => {
  //   const file = event.target.files[0];
  //   setSelectedImage(URL.createObjectURL(file));
  //   setUploadProgress(0);

  //   // Create a FormData object to send the file in the request
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   try {
  //     // Make a POST request to upload the image with the authorization token in the headers
  //     const response = await fetch(`${baseDomain}/api/user/upload_document`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: Application_token, // Replace YOUR_AUTH_TOKEN with the actual token
  //       },
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       // Image uploaded successfully, handle the response data if needed
  //       const responseData = await response.json();
  //       console.log("Upload success:", responseData);
  //       setResponseImageUrl(responseData?.key);

  //       toast.success("Your Identification Uploaded Successfully");
  //     } else {
  //       console.log("response==> s3", response);
  //       // Handle error responses
  //       console.error("Upload failed:", response.message);
  //       toast.error("Upload failed", response.message);
  //     }
  //   } catch (error) {
  //     console.error("Error occurred during upload:", error);
  //   }
  // };

  // ---------------------- Multi images Upload ---------------------------------- >>>>>>>>>>
  const handleImageChange = async (event) => {
    const files = event.target.files;
    const fileArray = Array.from(files);

    // Upload all files together
    uploadFiles(fileArray);
  };

  const uploadFiles = async (files) => {
    // Create a FormData object to send all files in the request
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`files`, file);
    });
    try {
      setUploadLoadingImg(true);
      // Make a POST request to upload the files with the authorization token in the headers
      const response = await fetch(
        `${baseDomain}/api/user/upload_document?isImage=government`,
        {
          method: "POST",
          headers: {
            Authorization: Application_token, // Replace YOUR_AUTH_TOKEN with the actual token
          },
          body: formData,
        }
      );
      console.log("responseData ==>", responseData);

      if (response.ok) {
        // Files uploaded successfully, handle the response data if needed
        const responseData = await response.json();
        console.log("Upload success:", responseData);
        setSelectedImage(responseData?.keys);
        // Handle the response accordingly
        toast.success("Files Uploaded Successfully");
        setUploadLoadingImg(false);
      } else if (response.status === 400) {
        const responseData = await response.json();
        console.error("Upload failed:", responseData);
        toast.error("This image is Already Uploaded");
        setUploadLoadingImg(false);
      }

      // else {
      //   console.error("Upload failed:", response);
      //   toast.error(response?.data?.message);
      // }
    } catch (error) {
      console.error("Error occurred during upload:", error);
    }
  };

  // const handleRemoveImage = () => {
  //   URL.revokeObjectURL(selectedImage);
  //   setSelectedImage(null);
  //   setUploadProgress(0);
  // };

  // images
  const listingRef = useRef();
  let sigCanvas = useRef();
  // Dates in Application
  function dobApplicantOne(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setDobApplicantOnes(dateString === "" ? "" : moment.utc(date));
  }
  function dobApplicantTwo(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setDobApplicantTows(dateString === "" ? "" : moment.utc(date));
  }
  function dobApplicantThree(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setDobApplicantThrees(dateString === "" ? "" : moment.utc(date));
  }
  // const handleRequestDate = (selectedDate) => {
  //   const dateString = selectedDate; // Convert date to string

  //   setRequestDate(dateString);
  // };

  function handleRequestDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setRequestDate(dateString === "" ? "" : moment.utc(date));
  }
  function handleCreditDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setcreditDate(dateString === "" ? "" : moment.utc(date));
  }

  function handleLimmiterDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setLimmitertDate(dateString === "" ? "" : moment.utc(date));
  }

  function handleLimmiterDate2(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setLimmitertDate2(dateString === "" ? "" : moment.utc(date));
  }

  function handleLimmiterDate3(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setLimmitertDate3(dateString === "" ? "" : moment.utc(date));
  }

  function handleSmokeDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setSmokeDate(dateString === "" ? "" : moment.utc(date));
  }
  function handleTenencyStartDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setTenentStartDate(dateString === "" ? "" : moment.utc(date));
  }

  function handleTenencyEndDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setTenentEndDate(dateString === "" ? "" : moment.utc(date));
  }

  function ProRatesStart(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setProStartDate(dateString === "" ? "" : moment.utc(date));
  }

  function ProRatesEnd(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setProEndDate(dateString === "" ? "" : moment.utc(date));
  }

  function handleAgreementtDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setAgreementDate(dateString === "" ? "" : moment.utc(date));
  }

  function handleAcceptancetDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log("pla", date, dateString);
    date = moment.utc(date);
    setAcceptanceDate(dateString === "" ? "" : moment.utc(date));
  }

  // Dates in ApplicationdobApplicantOne

  const handleChangeTenent = (index, event) => {
    let { name, value } = event.target;
    let updatedFields = [...fields];
    updatedFields[index] = { ...updatedFields[index], [name]: value };
    setFields(updatedFields);

    const validationStatus = updatedFields.map((field) => ({
      firstName: field.firstName.trim() !== "",
      // middleName: field.middleName.trim() !== "",
      lastName: field.lastName.trim() !== "",
      // sin: field.sin.trim() !== "",
      // email: isValidEmail(field.email),
    }));

    setFieldValidation(validationStatus);
  };
  const handleChangeOccupant = (index, event) => {
    let { name, value } = event.target;
    let updatedFields = [...occupantFields];
    updatedFields[index] = { ...updatedFields[index], [name]: value };
    setOccupantFields(updatedFields);

    const validationStatus = updatedFields.map((field) => ({
      // firstNameOccupant: field.firstNameOccupant.trim() !== "",
      // middleNameOccupant: field.middleNameOccupant.trim() !== "",
      // lastNameOccupant: field.lastNameOccupant.trim() !== "",
      // sinOccupant: field.sinOccupant.trim() !== "",
      // emailOccupant: isValidEmail(field.emailOccupant),
    }));

    setOccupantFieldValidation(validationStatus);
  };
  const handleAddField = () => {
    setFields([
      ...fields,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        sin: "",
        email: "",
      },
    ]);
  };
  const handleAddFieldOccupant = () => {
    setOccupantFields([
      ...occupantFields,
      {
        firstNameOccupant: "",
        middleNameOccupant: "",
        lastNameOccupant: "",
        sinOccupant: "",
        emailOccupant: "",
      },
    ]);
  };
  const handleRemoveFieldOcuupant = (index) => {
    let updatedFields = [...occupantFields];
    updatedFields.splice(index, 1);
    setOccupantFields(updatedFields);
  };
  const handleRemoveField = (index) => {
    let updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  // onSubmit
  const onSubmit = async (values) => {
    console.log(formik.errors);
    console.log(Object.values(formik.errors).length);
    // setSignaturingPad(signPad.getTrimmedCanvas().toDataURL("image/png"));
    setSignaturingPadTenent(
      signPadTenent.getTrimmedCanvas().toDataURL("image/png")
    );

    // Check if any of the fields are empty

    let data = {
      suite_no: formik?.values?.suite,
      application_type: formik?.values?.type,
      building: localStorage.getItem("building_namee")
        ? localStorage.getItem("building_namee")
        : "Select Building",
      inside: formik?.values?.inside,
      outside: formik?.values?.outside,
      lastMonthRent: formik?.values?.lastMonthRent,
      belowLast: formik?.values?.belowLast,
      discountRent: formik?.values?.discountRent,
      discountLast: formik?.values?.discountLast,
      discountPayable: formik?.values?.discountPayable,
      netLast: formik?.values?.netLast,
      netPayable: formik?.values?.netPayable,
      lastMonthDeposit: formik?.values?.lastMonthDeposit,
      noDays: formik?.values?.noDays,
      perDiem: formik?.values?.perDiem,
      proAmountRent: formik?.values?.proAmountRent,
      proAmount: formik?.values?.proAmount,
      Prior: formik?.values?.Prior,

      tenant_name: formik?.values?.tenentContactName,
      tenant_email: formik?.values?.tenentEmailAddress,
      tenant_phone_no: formik?.values?.tenentPhoneNumber,

      bona_fide_tenants: fields,
      occupants: occupantFields,

      tenancy_start_date: tenentStartDate,
      tenancy_end_date: tenentEndDate,
      initial_one: formik?.values?.initial_one,
      initial_two: formik?.values?.initial_two,
      initial_three: formik?.values?.initial_three,

      parking_below_ground: {
        law_rent: formik?.values?.belowRent,
        rent_payable: formik?.values?.belowPayAble,
      },
      rent: {
        law_rent: formik?.values?.lawRent,
        rent_payable: formik?.values?.payAbleRent,
      },
      parking_above_ground: {
        law_rent: formik?.values?.parkingLawRent,
        rent_payable: formik?.values?.parkingPayAbleRent,
      },
      total_legal_rent: {
        law_rent: formik?.values?.legalRent,
        rent_payable: formik?.values?.legalPayAble,
        last_month_rent: formik?.values?.legalLast,
      },
      prompt_payment_discount: formik?.values?.discountPayable,
      net_rent_payable: formik?.values?.netPayable,

      pro_rate: {
        pro_start_date: proStartDate,
        pro_End_date: proEndDate,
        no_of_days: formik?.values?.noDays,
        per_diem_rate: formik?.values?.perDiem,
        pro_rent: formik?.values?.proAmountRent,
        pro_rate_amount: formik?.values?.proAmount,
        lmr: formik?.values?.lmr,
        total_amount_prior_move_in: formik?.values?.Prior,
      },

      application_type: {
        new_application: newApplicants,
        assignment: assignments,
        sublet: sublets,
        transfer: transfers,
        name_change: nameChanges,
      },

      // government_identification: responseImageUrl,
      government_identification: selectedImage,
      proof_of_employment: selectedFile,
      aggrement_date: agreementDate,

      // building_manager_signature:
      //   signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
      //   null,
      building_manager_signature: statics,
      property_manager_signature: statics,

      action: "send",
      // acceptance_date :acceptanceDate,
      today_date: requestDate,

      // new data

      // new form

      applicant_name: {
        name_applicant_one: formik?.values?.name_applicant_one,
        name_applicant_two: formik?.values?.name_applicant_two,
        name_applicant_three: formik?.values?.name_applicant_three,
      },
      date_of_birth: {
        // date_of_birth_one: formik?.values?.date_of_birth_one,
        // date_of_birth_two: formik?.values?.date_of_birth_two,
        // date_of_birth_three: formik?.values?.date_of_birth_three,
        date_of_birth_one: dobApplicantOnes,
        date_of_birth_two: dobApplicantTwos,
        date_of_birth_three: dobApplicantThrees,
      },
      current_address: {
        current_address_one: formik?.values?.current_address_one,
        current_address_two: formik?.values?.current_address_two,
        current_address_three: formik?.values?.current_address_three,
      },
      apartment_no: {
        appartment_no_one: formik?.values?.appartment_no_one,
        appartment_no_two: formik?.values?.appartment_no_two,
        appartment_no_three: formik?.values?.appartment_no_three,
      },
      present_monthly_rent: {
        present_monthly_rent_one: formik?.values?.present_monthly_rent_one,
        present_monthly_rent_two: formik?.values?.present_monthly_rent_two,
        present_monthly_rent_three: formik?.values?.present_monthly_rent_three,
      },
      postal_code: {
        postal_code_one: formik?.values?.postal_code_one,
        postal_code_two: formik?.values?.postal_code_two,
        postal_code_three: formik?.values?.postal_code_three,
      },
      length_of_residence: {
        length_of_residence_one: formik?.values?.length_of_residence_one,
        length_of_residence_two: formik?.values?.length_of_residence_two,
        length_of_residence_three: formik?.values?.length_of_residence_three,
      },
      home_phone_no: {
        home_phone_no_one: formik?.values?.home_phone_no_one,
        home_phone_no_two: formik?.values?.home_phone_no_two,
        home_phone_no_three: formik?.values?.home_phone_no_three,
      },
      cell_phone_no: {
        cell_phone_no_one: formik?.values?.cell_phone_no_one,
        cell_phone_no_two: formik?.values?.cell_phone_no_two,
        cell_phone_no_three: formik?.values?.cell_phone_no_three,
      },

      landlords_name: {
        landlords_name_one: formik?.values?.landlords_name_one,
        landlords_name_two: formik?.values?.landlords_name_two,
        landlords_name_three: formik?.values?.landlords_name_three,
      },
      landlords_phone_no: {
        landlords_phone_one: formik?.values?.landlords_phone_one,
        landlords_phone_two: formik?.values?.landlords_phone_two,
        landlords_phone_three: formik?.values?.landlords_name_three,
      },

      previous_address: {
        previous_address_one: formik?.values?.previous_address_one,
        previous_address_two: formik?.values?.previous_address_two,
        previous_address_three: formik?.values?.previous_address_three,
      },

      previous_apartment_no: {
        previous_appartment_no_one: formik?.values?.previous_appartment_no_one,
        previous_appartment_no_two: formik?.values?.previous_appartment_no_two,
        previous_appartment_no_three:
          formik?.values?.previous_appartment_no_three,
      },
      previous_monthly_rent: {
        previous_monthly_rent_one: formik?.values?.previous_monthly_rent_one,
        previous_monthly_rent_two: formik?.values?.previous_monthly_rent_two,
        previous_monthly_rent_three:
          formik?.values?.previous_monthly_rent_three,
      },
      previous_postal_code: {
        previous_postal_code_one: formik?.values?.previous_postal_code_one,
        previous_postal_code_two: formik?.values?.previous_postal_code_two,
        previous_postal_code_three: formik?.values?.previous_position_three,
      },
      previous_length_of_residence: {
        previous_length_of_residence_one:
          formik?.values?.previous_length_of_residence_one,
        previous_length_of_residence_two:
          formik?.values?.previous_length_of_residence_two,
        previous_length_of_residence_three:
          formik?.values?.previous_length_of_residence_three,
      },
      previous_landlords_name: {
        previous_landlords_name_one:
          formik?.values?.previous_landlords_name_one,
        previous_landlords_name_two:
          formik?.values?.previous_landlords_name_two,
        previous_landlords_name_three:
          formik?.values?.previous_landlords_name_three,
      },
      previous_landlords_phone_no: {
        previous_landlords_phone_one:
          formik?.values?.previous_landlords_phone_one,
        previous_landlords_phone_two:
          formik?.values?.previous_landlords_name_two,
        previous_landlords_phone_three:
          formik?.values?.previous_landlords_name_three,
      },
      current_employer: {
        current_employer_one: formik?.values?.current_employer_one,
        current_employer_two: formik?.values?.current_employer_two,
        current_employer_three: formik?.values?.current_employer_three,
      },
      work_phone_no: {
        work_phone_no_one: formik?.values?.work_phone_no_one,
        work_phone_no_two: formik?.values?.work_phone_no_two,
        work_phone_no_three: formik?.values?.work_phone_no_three,
      },
      position: {
        position_one: formik?.values?.position_one,
        position_two: formik?.values?.position_two,
        position_three: formik?.values?.position_three,
      },
      length_of_employment: {
        length_of_employment_one: formik?.values?.length_of_employment_one,
        length_of_employment_two: formik?.values?.length_of_employment_two,
        length_of_employment_three: formik?.values?.length_of_employment_three,
      },
      contact_name_and_no: {
        contact_name_no_one: formik?.values?.contact_name_no_one,
        contact_name_no_two: formik?.values?.contact_name_no_two,
        contact_name_no_three: formik?.values?.contact_name_no_three,
      },
      phone_no: {
        phone_no_one: formik?.values?.phone_no_one,
        phone_no_two: formik?.values?.phone_no_two,
        phone_no_three: formik?.values?.phone_no_three,
      },
      annual_income: {
        annual_income_one: formik?.values?.annual_income_one,
        annual_income_two: formik?.values?.annual_income_two,
        annual_income_three: formik?.values?.annual_income_three,
      },
      previous_employer: {
        previous_employer_one: formik?.values?.previous_employer_one,
        previous_employer_two: formik?.values?.previous_employer_two,
        previous_employer_three: formik?.values?.previous_employer_three,
      },
      previous_position: {
        previous_position_one: formik?.values?.previous_position_one,
        previous_position_two: formik?.values?.previous_position_two,
        previous_position_three: formik?.values?.previous_position_three,
      },
      previous_length_of_employment: {
        Previous_length_of_employment_one:
          formik?.values?.Previous_length_of_employment_one,
        Previous_length_of_employment_two:
          formik?.values?.Previous_length_of_employment_two,
        Previous_length_of_employment_three:
          formik?.values?.previous_length_of_residence_three,
      },
      previous_contact_name_and_no: {
        previous_contact_name_no_one:
          formik?.values?.previous_contact_name_no_one,
        previous_contact_name_no_two:
          formik?.values?.previous_contact_name_no_two,
        previous_contact_name_no_three:
          formik?.values?.previous_annual_income_three,
      },
      previous_annual_income: {
        previous_annual_income_one: formik?.values?.previous_annual_income_one,
        previous_annual_income_two: formik?.values?.previous_annual_income_two,
        previous_annual_income_three:
          formik?.values?.previous_annual_income_three,
      },
      bank: {
        bank_one: formik?.values?.bank_one,
        bank_two: formik?.values?.bank_two,
        bank_three: formik?.values?.bank_three,
      },
      branch: {
        branch_one: formik?.values?.branch_one,
        branch_two: formik?.values?.branch_two,
        branch_three: formik?.values?.branch_three,
      },
      account_no: {
        account_no_one: formik?.values?.account_no_one,
        account_no_two: formik?.values?.account_no_two,
        account_no_three: formik?.values?.account_no_three,
      },
      make_of_vehicle: {
        make_of_vehicle_one: formik?.values?.make_of_vehicle_one,
        make_of_vehicle_two: formik?.values?.make_of_vehicle_two,
        make_of_vehicle_three: formik?.values?.make_of_vehicle_three,
      },
      year_model_of_vehicle: {
        year_model_of_vehicle_one: formik?.values?.year_model_of_vehicle_one,
        year_model_of_vehicle_two: formik?.values?.year_model_of_vehicle_two,
        year_model_of_vehicle_three:
          formik?.values?.year_model_of_vehicle_threer,
      },
      colour_of_vehicle: {
        colour_of_vehicle_one: formik?.values?.colour_of_vehicle_one,
        colour_of_vehicle_two: formik?.values?.colour_of_vehicle_two,
        colour_of_vehicle_three: formik?.values?.colour_of_vehicle_three,
      },
      licence_plate_no: {
        licence_plate_no_one: formik?.values?.licence_plate_no_one,
        licence_plate_no_two: formik?.values?.licence_plate_no_two,
        licence_plate_no_three: formik?.values?.licence_plate_no_three,
      },
      drivers_license_no: {
        drivers_license_no_one: formik?.values?.drivers_license_no_one,
        drivers_license_no_two: formik?.values?.drivers_license_no_two,
        drivers_license_no_three: formik?.values?.drivers_license_no_three,
      },
      reference_name: {
        reference_name_one: formik?.values?.reference_name_one,
        reference_name_two: formik?.values?.reference_name_two,
        reference_name_three: formik?.values?.reference_name_three,
      },
      reference_address: {
        reference_address_one: formik?.values?.reference_address_one,
        reference_address_two: formik?.values?.reference_address_two,
        reference_address_three: formik?.values?.reference_address_three,
      },
      reference_phone_no: {
        reference_phone_no_one: formik?.values?.reference_phone_no_one,
        reference_phone_no_two: formik?.values?.reference_phone_no_two,
        reference_phone_no_three: formik?.values?.reference_phone_no_three,
      },
      reference_relationship: {
        reference_relationship_one: formik?.values?.reference_relationship_one,
        reference_relationship_two: formik?.values?.reference_relationship_two,
        reference_relationship_three:
          formik?.values?.reference_relationship_three,
      },
      next_of_kin_name: {
        next_name_one: formik?.values?.next_name_one,
        next_name_two: formik?.values?.next_name_two,
        next_name_three: formik?.values?.next_name_three,
      },
      next_of_kin_address: {
        next_address_one: formik?.values?.next_address_one,
        next_address_two: formik?.values?.next_address_two,
        next_address_three: formik?.values?.next_address_three,
      },
      next_of_kin_phone_no: {
        next_phone_no_one: formik?.values?.next_phone_no_one,
        next_phone_no_two: formik?.values?.next_phone_no_two,
        next_phone_no_three:
          formik?.values?.formik?.values?.next_phone_no_three,
      },
      next_of_kin_relationship: {
        next_relationship_one: formik?.values?.next_relationship_one,
        next_relationship_two: formik?.values?.next_relationship_two,
        next_relationship_three: formik?.values?.next_relationship_three,
      },

      credit_unit: formik?.values?.suite,
      credit_building: formik?.values?.building,
      credit_date: creditDate,

      cosmetic_smoke_date: smokeDate,
      cosmetic_limmiter_date: limmiterDate,
      cosmetic_limmiter_date_2: limmiterDate2,
      cosmetic_limmiter_date_3: limmiterDate3,

      tenant_signature:
        signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

      tenant_signature_2:
        signPadTenent2?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

      tenant_signature_3:
        signPadTenent3?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

      credit_tenant1_signature:
        signPadCreditTenent1?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,

      credit_tenant2_signature:
        signPadCreditTenent2?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,

      credit_tenant3_signature:
        signPadCreditTenent3?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,

      cosmetic_smoke_tenant_signature:
        signPadTenentSmoke?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

      cosmetic_smoke_tenant_signature_2:
        signPadTenentSmoke2?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,

      cosmetic_smoke_tenant_signature_3:
        signPadTenentSmoke3?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,

      cosmetic_limmiter_tenant_signature:
        signPadTenentLimmiter?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,

      cosmetic_limmiter_tenant_signature_2:
        signPadTenentLimmiter2?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,

      cosmetic_limmiter_tenant_signature_3:
        signPadTenentLimmiter3?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,
    };

    try {
      const Application_token = localStorage.getItem("Application_token");

      let res = await userApiCallApplication(
        `/api/user/update_application`,
        "POST",
        JSON.stringify(data),
        Application_token
      );
      console.log("Response", res);
      toast.success(res?.message); // Display the success toaster notification
      navigate("/thankyou");
    } catch (error) {
      console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  // draft
  // onSubmit
  const DraftHandling = async (values) => {
    let data = {
      initial_one: formik?.values?.initial_one,
      initial_two: formik?.values?.initial_two,
      initial_three: formik?.values?.initial_three,

      suite_no: formik?.values?.suite,
      application_type: formik?.values?.type,
      building: localStorage.getItem("building_namee")
        ? localStorage.getItem("building_namee")
        : "Select Building",
      inside: formik?.values?.inside,
      outside: formik?.values?.outside,
      lastMonthRent: formik?.values?.lastMonthRent,
      belowLast: formik?.values?.belowLast,
      discountRent: formik?.values?.discountRent,
      discountLast: formik?.values?.discountLast,
      discountPayable: formik?.values?.discountPayable,
      netLast: formik?.values?.netLast,
      netPayable: formik?.values?.netPayable,
      lastMonthDeposit: formik?.values?.lastMonthDeposit,
      noDays: formik?.values?.noDays,
      perDiem: formik?.values?.perDiem,
      // proAmountRent: formik?.values?.proAmountRent,
      proAmount: formik?.values?.proAmount,
      Prior: formik?.values?.Prior,

      parking_below_ground: {
        law_rent: formik?.values?.belowRent,
        rent_payable: formik?.values?.belowPayAble,
      },
      tenant_name: formik?.values?.tenentContactName,
      tenant_email: formik?.values?.tenentEmailAddress,
      tenant_phone_no: formik?.values?.tenentPhoneNumber,

      bona_fide_tenants: fields,
      occupants: occupantFields,

      tenancy_start_date: tenentStartDate,
      tenancy_end_date: tenentEndDate,
      rent: {
        law_rent: formik?.values?.lawRent,
        rent_payable: formik?.values?.payAbleRent,
      },
      parking_above_ground: {
        law_rent: formik?.values?.parkingLawRent,
        rent_payable: formik?.values?.parkingPayAbleRent,
      },
      total_legal_rent: {
        law_rent: formik?.values?.legalRent,
        rent_payable: formik?.values?.legalPayAble,
        last_month_rent: formik?.values?.legalLast,
      },
      prompt_payment_discount: formik?.values?.discountPayable,
      net_rent_payable: formik?.values?.netPayable,

      pro_rate: {
        pro_start_date: proStartDate,
        pro_End_date: proEndDate,
        no_of_days: formik?.values?.noDays,
        per_diem_rate: formik?.values?.perDiem,
        pro_rent: formik?.values?.proAmountRent,
        pro_rate_amount: formik?.values?.proAmount,
        lmr: formik?.values?.lmr,
        total_amount_prior_move_in: formik?.values?.Prior,
      },

      application_type: {
        new_application: newApplicants,
        assignment: assignments,
        sublet: sublets,
        transfer: transfers,
        name_change: nameChanges,
      },

      government_identification: selectedImage,
      proof_of_employment: selectedFile,
      aggrement_date: agreementDate,
      // tenant_signature:
      //   signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
      // tenant_signature1:
      // signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
      // building_manager_signature:
      //   signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
      //   null,
      building_manager_signature: statics,
      property_manager_signature: statics,

      // property_manager_signature:
      //   signPadPropertyManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
      //   null,
      action: "draft",
      // acceptance_date :acceptanceDate,
      today_date: requestDate,
      // new form

      applicant_name: {
        name_applicant_one: formik?.values?.name_applicant_one,
        name_applicant_two: formik?.values?.name_applicant_two,
        name_applicant_three: formik?.values?.name_applicant_three,
      },
      date_of_birth: {
        // date_of_birth_one: formik?.values?.date_of_birth_one,
        // date_of_birth_two: formik?.values?.date_of_birth_two,
        // date_of_birth_three: formik?.values?.date_of_birth_three,
        date_of_birth_one: dobApplicantOnes,
        date_of_birth_two: dobApplicantTwos,
        date_of_birth_three: dobApplicantThrees,
      },
      current_address: {
        current_address_one: formik?.values?.current_address_one,
        current_address_two: formik?.values?.current_address_two,
        current_address_three: formik?.values?.current_address_three,
      },
      apartment_no: {
        appartment_no_one: formik?.values?.appartment_no_one,
        appartment_no_two: formik?.values?.appartment_no_two,
        appartment_no_three: formik?.values?.appartment_no_three,
      },
      present_monthly_rent: {
        present_monthly_rent_one: formik?.values?.present_monthly_rent_one,
        present_monthly_rent_two: formik?.values?.present_monthly_rent_two,
        present_monthly_rent_three: formik?.values?.present_monthly_rent_three,
      },
      postal_code: {
        postal_code_one: formik?.values?.postal_code_one,
        postal_code_two: formik?.values?.postal_code_two,
        postal_code_three: formik?.values?.postal_code_three,
      },
      length_of_residence: {
        length_of_residence_one: formik?.values?.length_of_residence_one,
        length_of_residence_two: formik?.values?.length_of_residence_two,
        length_of_residence_three: formik?.values?.length_of_residence_three,
      },
      home_phone_no: {
        home_phone_no_one: formik?.values?.home_phone_no_one,
        home_phone_no_two: formik?.values?.home_phone_no_two,
        home_phone_no_three: formik?.values?.home_phone_no_three,
      },
      cell_phone_no: {
        cell_phone_no_one: formik?.values?.cell_phone_no_one,
        cell_phone_no_two: formik?.values?.cell_phone_no_two,
        cell_phone_no_three: formik?.values?.cell_phone_no_three,
      },

      landlords_name: {
        landlords_name_one: formik?.values?.landlords_name_one,
        landlords_name_two: formik?.values?.landlords_name_two,
        landlords_name_three: formik?.values?.landlords_name_three,
      },
      landlords_phone_no: {
        landlords_phone_one: formik?.values?.landlords_phone_one,
        landlords_phone_two: formik?.values?.landlords_phone_two,
        landlords_phone_three: formik?.values?.landlords_name_three,
      },
      previous_address: {
        previous_address_one: formik?.values?.previous_address_one,
        previous_address_two: formik?.values?.previous_address_two,
        previous_address_three: formik?.values?.previous_address_three,
      },

      previous_apartment_no: {
        previous_appartment_no_one: formik?.values?.previous_appartment_no_one,
        previous_appartment_no_two: formik?.values?.previous_appartment_no_two,
        previous_appartment_no_three:
          formik?.values?.previous_appartment_no_three,
      },
      previous_monthly_rent: {
        previous_monthly_rent_one: formik?.values?.previous_monthly_rent_one,
        previous_monthly_rent_two: formik?.values?.previous_monthly_rent_two,
        previous_monthly_rent_three:
          formik?.values?.previous_monthly_rent_three,
      },
      previous_postal_code: {
        previous_postal_code_one: formik?.values?.previous_postal_code_one,
        previous_postal_code_two: formik?.values?.previous_postal_code_two,
        previous_postal_code_three: formik?.values?.previous_position_three,
      },
      previous_length_of_residence: {
        previous_length_of_residence_one:
          formik?.values?.previous_length_of_residence_one,
        previous_length_of_residence_two:
          formik?.values?.previous_length_of_residence_two,
        previous_length_of_residence_three:
          formik?.values?.previous_length_of_residence_three,
      },
      previous_landlords_name: {
        previous_landlords_name_one:
          formik?.values?.previous_landlords_name_one,
        previous_landlords_name_two:
          formik?.values?.previous_landlords_name_two,
        previous_landlords_name_three:
          formik?.values?.previous_landlords_name_three,
      },
      previous_landlords_phone_no: {
        previous_landlords_phone_one:
          formik?.values?.previous_landlords_phone_one,
        previous_landlords_phone_two:
          formik?.values?.previous_landlords_name_two,
        previous_landlords_phone_three:
          formik?.values?.previous_landlords_name_three,
      },
      current_employer: {
        current_employer_one: formik?.values?.current_employer_one,
        current_employer_two: formik?.values?.current_employer_two,
        current_employer_three: formik?.values?.current_employer_three,
      },
      work_phone_no: {
        work_phone_no_one: formik?.values?.work_phone_no_one,
        work_phone_no_two: formik?.values?.work_phone_no_two,
        work_phone_no_three: formik?.values?.work_phone_no_three,
      },
      position: {
        position_one: formik?.values?.position_one,
        position_two: formik?.values?.position_two,
        position_three: formik?.values?.position_three,
      },
      length_of_employment: {
        length_of_employment_one: formik?.values?.length_of_employment_one,
        length_of_employment_two: formik?.values?.length_of_employment_two,
        length_of_employment_three: formik?.values?.length_of_employment_three,
      },
      contact_name_and_no: {
        contact_name_no_one: formik?.values?.contact_name_no_one,
        contact_name_no_two: formik?.values?.contact_name_no_two,
        contact_name_no_three: formik?.values?.contact_name_no_three,
      },
      phone_no: {
        phone_no_one: formik?.values?.phone_no_one,
        phone_no_two: formik?.values?.phone_no_two,
        phone_no_three: formik?.values?.phone_no_three,
      },
      annual_income: {
        annual_income_one: formik?.values?.annual_income_one,
        annual_income_two: formik?.values?.annual_income_two,
        annual_income_three: formik?.values?.annual_income_three,
      },
      previous_employer: {
        previous_employer_one: formik?.values?.previous_employer_one,
        previous_employer_two: formik?.values?.previous_employer_two,
        previous_employer_three: formik?.values?.previous_employer_three,
      },
      previous_position: {
        previous_position_one: formik?.values?.previous_position_one,
        previous_position_two: formik?.values?.previous_position_two,
        previous_position_three: formik?.values?.previous_position_three,
      },
      previous_length_of_employment: {
        Previous_length_of_employment_one:
          formik?.values?.Previous_length_of_employment_one,
        Previous_length_of_employment_two:
          formik?.values?.Previous_length_of_employment_two,
        Previous_length_of_employment_three:
          formik?.values?.previous_length_of_residence_three,
      },
      previous_contact_name_and_no: {
        previous_contact_name_no_one:
          formik?.values?.previous_contact_name_no_one,
        previous_contact_name_no_two:
          formik?.values?.previous_contact_name_no_two,
        previous_contact_name_no_three:
          formik?.values?.previous_annual_income_three,
      },
      previous_annual_income: {
        previous_annual_income_one: formik?.values?.previous_annual_income_one,
        previous_annual_income_two: formik?.values?.previous_annual_income_two,
        previous_annual_income_three:
          formik?.values?.previous_annual_income_three,
      },
      bank: {
        bank_one: formik?.values?.bank_one,
        bank_two: formik?.values?.bank_two,
        bank_three: formik?.values?.bank_three,
      },
      branch: {
        branch_one: formik?.values?.branch_one,
        branch_two: formik?.values?.branch_two,
        branch_three: formik?.values?.branch_three,
      },
      account_no: {
        account_no_one: formik?.values?.account_no_one,
        account_no_two: formik?.values?.account_no_two,
        account_no_three: formik?.values?.account_no_three,
      },
      make_of_vehicle: {
        make_of_vehicle_one: formik?.values?.make_of_vehicle_one,
        make_of_vehicle_two: formik?.values?.make_of_vehicle_two,
        make_of_vehicle_three: formik?.values?.make_of_vehicle_three,
      },
      year_model_of_vehicle: {
        year_model_of_vehicle_one: formik?.values?.year_model_of_vehicle_one,
        year_model_of_vehicle_two: formik?.values?.year_model_of_vehicle_two,
        year_model_of_vehicle_three:
          formik?.values?.year_model_of_vehicle_threer,
      },
      colour_of_vehicle: {
        colour_of_vehicle_one: formik?.values?.colour_of_vehicle_one,
        colour_of_vehicle_two: formik?.values?.colour_of_vehicle_two,
        colour_of_vehicle_three: formik?.values?.colour_of_vehicle_three,
      },
      licence_plate_no: {
        licence_plate_no_one: formik?.values?.licence_plate_no_one,
        licence_plate_no_two: formik?.values?.licence_plate_no_two,
        licence_plate_no_three: formik?.values?.licence_plate_no_three,
      },
      drivers_license_no: {
        drivers_license_no_one: formik?.values?.drivers_license_no_one,
        drivers_license_no_two: formik?.values?.drivers_license_no_two,
        drivers_license_no_three: formik?.values?.drivers_license_no_three,
      },
      reference_name: {
        reference_name_one: formik?.values?.reference_name_one,
        reference_name_two: formik?.values?.reference_name_two,
        reference_name_three: formik?.values?.reference_name_three,
      },
      reference_address: {
        reference_address_one: formik?.values?.reference_address_one,
        reference_address_two: formik?.values?.reference_address_two,
        reference_address_three: formik?.values?.reference_address_three,
      },
      reference_phone_no: {
        reference_phone_no_one: formik?.values?.reference_phone_no_one,
        reference_phone_no_two: formik?.values?.reference_phone_no_two,
        reference_phone_no_three: formik?.values?.reference_phone_no_three,
      },
      reference_relationship: {
        reference_relationship_one: formik?.values?.reference_relationship_one,
        reference_relationship_two: formik?.values?.reference_relationship_two,
        reference_relationship_three:
          formik?.values?.reference_relationship_three,
      },
      next_of_kin_name: {
        next_name_one: formik?.values?.next_name_one,
        next_name_two: formik?.values?.next_name_two,
        next_name_three: formik?.values?.next_name_three,
      },
      next_of_kin_address: {
        next_address_one: formik?.values?.next_address_one,
        next_address_two: formik?.values?.next_address_two,
        next_address_three: formik?.values?.next_address_three,
      },
      next_of_kin_phone_no: {
        next_phone_no_one: formik?.values?.next_phone_no_one,
        next_phone_no_two: formik?.values?.next_phone_no_two,
        next_phone_no_three:
          formik?.values?.formik?.values?.next_phone_no_three,
      },
      next_of_kin_relationship: {
        next_relationship_one: formik?.values?.next_relationship_one,
        next_relationship_two: formik?.values?.next_relationship_two,
        next_relationship_three: formik?.values?.next_relationship_three,
      },
      credit_unit: formik?.values?.suite,
      credit_building: formik?.values?.building,
      credit_date: creditDate,

      cosmetic_smoke_date: smokeDate,
      cosmetic_limmiter_date: limmiterDate,
      cosmetic_limmiter_date_2: limmiterDate2,
      cosmetic_limmiter_date_3: limmiterDate3,

      tenant_signature:
        signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

      tenant_signature_2:
        signPadTenent2?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,
      tenant_signature_3:
        signPadTenent3?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

      credit_tenant1_signature:
        signPadCreditTenent1?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,

      credit_tenant2_signature:
        signPadCreditTenent2?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,

      credit_tenant3_signature:
        signPadCreditTenent3?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,

      cosmetic_smoke_tenant_signature:
        signPadTenentSmoke?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

      cosmetic_smoke_tenant_signature_2:
        signPadTenentSmoke2?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,

      cosmetic_smoke_tenant_signature_3:
        signPadTenentSmoke3?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,

      cosmetic_limmiter_tenant_signature:
        signPadTenentLimmiter?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,

      cosmetic_limmiter_tenant_signature_2:
        signPadTenentLimmiter2?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,

      cosmetic_limmiter_tenant_signature_3:
        signPadTenentLimmiter3?.getTrimmedCanvas()?.toDataURL("image/png") ??
        null, // building_manager_signature: agreementDate,
    };
    try {
      const Application_token = localStorage.getItem("Application_token");

      let res = await userApiCallApplication(
        `/api/user/update_application`,
        "POST",
        JSON.stringify(data),
        Application_token
      );
      console.log("Response user Application", res);
      if (res?.success == true) {
        setCheckResponse(true);
      } else {
        setCheckResponse(false);
      }
      // toast.success(res?.message); // Display the success toaster notification
      // navigate("/thankyou");
    } catch (error) {
      console.log("err", error);
      toast.error("Server Side Error"); // Display the error toaster notification
    }
  };

  console.log("Selected Image  image", selectedImage);
  console.log("Selected Image  file", selectedFile);

  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({});

  // checking initial values
  const initialValues = {
    suite: "",
    type: "",
    building: localStorage.getItem("building_namee")
      ? localStorage.getItem("building_namee")
      : "",
    inside: "",
    outside: "",
    lawRent: "",
    payAbleRent: "",
    lastMonthRent: "",
    parkingLawRent: "",
    initial_one: "",
    initial_two: "",
    initial_three: "",

    parkingLastRent: "",
    belowRent: "",
    belowPayAble: "",

    belowLast: "",
    legalRent: "",
    legalPayAble: "",
    legalLast: "",

    discountRent: "",
    discountLast: "",
    discountPayable: "",
    netLast: "",

    netPayable: "",
    lastMonthDeposit: "",
    noDays: "",
    perDiem: "",
    proAmountRent: "",
    proAmount: "",
    Prior: "",

    tenentContactName: "",
    tenentEmailAddress: "",
    tenentPhoneNumber: "",
    lmr: "",

    // new Application

    name_applicant_one: fields?.[0]?.firstName ?? "",
    name_applicant_two: fields?.[2]?.firstName ?? "",
    name_applicant_three: fields?.[3]?.firstName ?? "",

    date_of_birth_one: dobApplicantOnes,
    date_of_birth_two: dobApplicantTwos,
    date_of_birth_three: dobApplicantThrees,

    current_address_one: "",
    current_address_two: "",
    current_address_three: "",

    appartment_no_one: "",
    appartment_no_two: "",
    appartment_no_three: "",

    present_monthly_rent_one: "",
    present_monthly_rent_two: "",
    present_monthly_rent_three: "",

    postal_code_one: "",
    postal_code_two: "",
    postal_code_three: "",

    length_of_residence_one: "",
    length_of_residence_two: "",
    length_of_residence_three: "",

    home_phone_no_one: "",
    home_phone_no_two: "",
    home_phone_no_three: "",

    cell_phone_no_one: "",
    cell_phone_no_two: "",
    cell_phone_no_three: "",

    landlords_name_one: "",
    landlords_name_two: "",
    landlords_name_three: "",

    landlords_phone_one: "",
    landlords_phone_two: "",
    landlords_phone_three: "",

    previous_address_one: "",
    previous_address_two: "",
    previous_address_three: "",

    previous_appartment_no_one: "",
    previous_appartment_no_two: "",
    previous_appartment_no_three: "",

    previous_monthly_rent_one: "",
    previous_monthly_rent_two: "",
    previous_monthly_rent_three: "",

    previous_postal_code_one: "",
    previous_postal_code_two: "",
    previous_postal_code_three: "",

    previous_length_of_residence_one: "",
    previous_length_of_residence_two: "",
    previous_length_of_residence_three: "",

    previous_landlords_name_one: "",
    previous_landlords_name_two: "",
    previous_landlords_name_three: "",

    previous_landlords_phone_one: "",
    previous_landlords_phone_two: "",
    previous_landlords_phone_three: "",

    current_employer_one: "",
    current_employer_two: "",
    current_employer_three: "",

    work_phone_no_one: "",
    work_phone_no_two: "",
    work_phone_no_three: "",

    position_one: "",
    position_two: "",
    position_three: "",

    length_of_employment_one: "",
    length_of_employment_two: "",
    length_of_employment_three: "",

    contact_name_no_one: "",
    contact_name_no_two: "",
    contact_name_no_three: "",

    phone_no_one: "",
    phone_no_two: "",
    phone_no_three: "",

    annual_income_one: "",
    annual_income_two: "",
    annual_income_three: "",

    previous_employer_one: "",
    previous_employer_two: "",
    previous_employer_three: "",

    previous_position_one: "",
    previous_position_two: "",
    previous_position_three: "",

    Previous_length_of_employment_one: "",
    Previous_length_of_employment_two: "",
    Previous_length_of_employment_three: "",

    previous_contact_name_no_one: "",
    previous_contact_name_no_two: "",
    previous_contact_name_no_three: "",

    previous_annual_income_one: "",
    previous_annual_income_two: "",
    previous_annual_income_three: "",

    bank_one: "",
    bank_two: "",
    bank_three: "",

    branch_one: "",
    branch_two: "",
    branch_three: "",

    account_no_one: "",
    account_no_two: "",
    account_no_three: "",

    make_of_vehicle_one: "",
    make_of_vehicle_two: "",
    make_of_vehicle_three: "",

    year_model_of_vehicle_one: "",
    year_model_of_vehicle_two: "",
    year_model_of_vehicle_three: "",

    colour_of_vehicle_one: "",
    colour_of_vehicle_two: "",
    colour_of_vehicle_three: "",

    licence_plate_no_one: "",
    licence_plate_no_two: "",
    licence_plate_no_three: "",

    drivers_license_no_one: "",
    drivers_license_no_two: "",
    drivers_license_no_three: "",

    reference_name_one: "",
    reference_name_two: "",
    reference_name_three: "",

    reference_address_one: "",
    reference_address_two: "",
    reference_address_three: "",

    reference_phone_no_one: "",
    reference_phone_no_two: "",
    reference_phone_no_three: "",

    reference_relationship_one: "",
    reference_relationship_two: "",
    reference_relationship_three: "",

    next_name_one: "",
    next_name_two: "",
    next_name_three: "",

    next_address_one: "",
    next_address_two: "",
    next_address_three: "",

    next_phone_no_one: "",
    next_phone_no_two: "",
    next_phone_no_three: "",

    next_relationship_one: "",
    next_relationship_two: "",
    next_relationship_three: "",
    credit_unit: "",
    credit_building: "",
  };
  // checking in formiks
  const formik = useFormik({
    initialValues,

    // initialValues: responseData,
    validationSchema,
    onSubmit,
  });

  const clearSignature = () => {
    if (signPadTenent) {
      signPadTenent.clear();
    }
  };
  const clearSignature2 = () => {
    if (signPadTenent2) {
      signPadTenent2.clear();
    }
  };
  const clearSignature3 = () => {
    if (signPadTenent3) {
      signPadTenent3.clear();
    }
  };
  const clearSignaturecreditTenant1 = () => {
    if (signPadCreditTenent1) {
      signPadCreditTenent1.clear();
    }
  };

  const clearSignaturecreditTenant2 = () => {
    if (signPadCreditTenent2) {
      signPadCreditTenent2.clear();
    }
  };

  const clearSignaturecreditTenant3 = () => {
    if (signPadCreditTenent3) {
      signPadCreditTenent3.clear();
    }
  };
  const clearSignatureTenentLimmiter = () => {
    if (signPadTenentLimmiter) {
      signPadTenentLimmiter.clear();
    }
  };
  const clearSignatureTenentLimmiter2 = () => {
    if (signPadTenentLimmiter2) {
      signPadTenentLimmiter2.clear();
    }
  };

  const clearSignatureTenentLimmiter3 = () => {
    if (signPadTenentLimmiter3) {
      signPadTenentLimmiter3.clear();
    }
  };

  const clearSignatureTenentSmoke = () => {
    if (signPadTenentSmoke) {
      signPadTenentSmoke.clear();
    }
  };
  const clearSignatureTenentSmoke2 = () => {
    if (signPadTenentSmoke2) {
      signPadTenentSmoke2.clear();
    }
  };
  const clearSignatureTenentSmoke3 = () => {
    if (signPadTenentSmoke3) {
      signPadTenentSmoke3.clear();
    }
  };

  // signature
  const signatureHandlerTenent = (ref) => {
    sigCanvas = ref;
    setSignPadTenent(sigCanvas); //elem ~ dataurl
  };
  // signature
  const signatureHandlerTenent2 = (ref) => {
    if (ref) {
      sigCanvas = ref;
      setSignPadTenent2(sigCanvas); //elem ~ dataurl
    }
  };

  const signatureHandlerTenent3 = (ref) => {
    if (ref) {
      sigCanvas = ref;
      setSignPadTenent3(sigCanvas); //elem ~ dataurl
    }
  };

  const handleClear = () => {
    if (signPadTenent?.current) {
      signPadTenent?.current.clear();
      // Do not clear the tenantView here if you want to retain the image after clearing the canvas
    }
  };

  const signatureHandlerTenentSmoke = (ref) => {
    sigCanvas = ref;
    setSignPadTenentSmoke(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  const signatureHandlerTenentSmoke2 = (ref) => {
    sigCanvas = ref;
    setSignPadTenentSmoke2(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  const signatureHandlerTenentSmoke3 = (ref) => {
    sigCanvas = ref;
    setSignPadTenentSmoke3(sigCanvas);
  };
  // signature
  const signatureHandlerBuldingManager = (ref) => {
    sigCanvas = ref;
    setSignPadBuldingManager(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  // signature
  const signatureHandlerPropertyManager = (ref) => {
    sigCanvas = ref;
    setSignPadPropertyManager(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };
  const signatureHandlerCreditBm = (ref) => {
    sigCanvas = ref;
    setSignPadCreditBm(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  const signatureHandlerCreditTenent1 = (ref) => {
    sigCanvas = ref;
    setSignPadCreditTenent1(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };
  const signatureHandlerCreditTenent2 = (ref) => {
    sigCanvas = ref;
    setSignPadCreditTenent2(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };
  const signatureHandlerCreditTenent3 = (ref) => {
    sigCanvas = ref;
    setSignPadCreditTenent3(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };
  const signatureHandlerTenentLimmiter = (ref) => {
    sigCanvas = ref;
    setSignPadTenentLimmiter(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };
  const signatureHandlerTenentLimmiter2 = (ref) => {
    sigCanvas = ref;
    setSignPadTenentLimmiter2(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  const signatureHandlerTenentLimmiter3 = (ref) => {
    sigCanvas = ref;
    setSignPadTenentLimmiter3(sigCanvas);
    // console.log(
    //   sigCanvas.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  const getDimension = () => {
    let divWidths = $("#canvas_Wraper").width();
    let divHeights = $("#canvas_Wraper").height();
    setDivWidth(divWidths);
    setDivHeight(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimensionCreditTenent1 = () => {
    let divWidths = $("#canvas_Wraper_creditTenent1").width();
    let divHeights = $("#canvas_Wraper_creditTenent1").height();
    setDivWidthCreditTenent1(divWidths);
    setDivHeightCreditTenent1(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };
  const getDimensionCreditTenent2 = () => {
    let divWidths = $("#canvas_Wraper_creditTenent2").width();
    let divHeights = $("#canvas_Wraper_creditTenent2").height();
    setDivWidthCreditTenent2(divWidths);
    setDivHeightCreditTenent2(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimensionCreditTenent3 = () => {
    let divWidths = $("#canvas_Wraper_creditTenent2").width();
    let divHeights = $("#canvas_Wraper_creditTenent2").height();
    setDivWidthCreditTenent3(divWidths);
    setDivHeightCreditTenent3(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimensionCreditBm = () => {
    let divWidths = $("#canvas_Wraper_credit_bm").width();
    let divHeights = $("#canvas_Wraper_credit_bm").height();
    setDivWidthCreditBm(divWidths);
    setDivHeightCreditBm(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimensionSmoke = () => {
    let divWidths = $("#canvas_Wraper_smoke").width();
    let divHeights = $("#canvas_Wraper_smoke").height();
    setDivWidthSmoke(divWidths);
    setDivHeightSmoke(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimensionLimmiter = () => {
    let divWidths = $("#canvas_Wraper_limmiter").width();
    let divHeights = $("#canvas_Wraper_limmiter").height();
    setDivWidthLimmiter(divWidths);
    setDivHeightLimmiter(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };

  const getDimension1 = () => {
    let divWidths = $("#canvas_Wraper1").width();
    let divHeights = $("#canvas_Wraper1").height();
    setDivWidth1(divWidths);
    setDivHeight1(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };
  const getDimension2 = () => {
    let divWidths = $("#canvas_Wraper2").width();
    let divHeights = $("#canvas_Wraper2").height();
    setDivWidth2(divWidths);
    setDivHeight2(divHeights);
    // console.log("Width: " + divWidth + ", " + "Height: " + divHeight);
  };
  console.log("formik in application", formik);
  console.log("selectedFile", selectedFile);
  console.log("selectedFile img", selectedImage);
  // const handleListingPage = useReactToPrint({
  //   content: () => listingRef.current,
  //   onAfterPrint: () => {
  //     setShowLogo(false);
  //   },
  // });
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]+$/;

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };
  console.log("responseData", responseData);

  // get Api call

  const Application_token = localStorage.getItem("Application_token");

  useEffect(() => {
    console.log("token validate", Application_token);
    if (Application_token) {
      fetch(`${baseDomain}/api/user/get_application`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${Application_token}`, // Add the token to the Authorization header
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("user Get", data);
          if (
            data?.message == "Something went wrong while authenticating session"
          ) {
            toast.error("Something went wrong while authenticating session");
          } else {
            setIsValid(data.isValid);
            formik.setValues({
              tenentContactName: data?.data?.tenant_name,
              tenentEmailAddress: data?.data?.tenant_email,
              tenentPhoneNumber: data?.data?.tenant_phone_no,
              suite: data?.data?.suite_no,
              // tenentContactName:data?.data?.building,
              legalRent: data?.data?.total_legal_rent?.law_rent,
              legalLast: data?.data?.total_legal_rent?.last_month_rent,
              // legalPayAble : data?.data?.rent?.rent_payable,
              legalPayAble: data?.data?.rent?.rent_payable_legal,
              lawRent: data?.data?.rent?.law_rent,
              payAbleRent: data?.data?.rent?.rent_payable,

              discountPayable: data?.data?.prompt_payment_discount,
              lawRent: data?.data?.rent?.law_rent,
              payAbleRent: data?.data?.rent?.rent_payable,
              parkingLawRent: data?.data?.parking_above_ground?.law_rent,
              parkingPayAbleRent:
                data?.data?.parking_above_ground?.rent_payable,
              belowRent: data?.data?.parking_below_ground?.law_rent,
              belowPayAble: data?.data?.parking_below_ground?.rent_payable,
              legalRent: data?.data?.total_legal_rent?.law_rent,
              legalPayAble: data?.data?.total_legal_rent?.rent_payable_legal,
              legalLast: data?.data?.total_legal_rent?.last_month_rent,
              netPayable: data?.data?.net_rent_payable,
              initial_one: data?.data?.initial_one,
              initial_two: data?.data?.initial_two,
              initial_three: data?.data?.initial_three,
              proStartDate: data?.data?.pro_rate?.pro_start_date,
              proEndDate: data?.data?.pro_rate?.pro_End_date,
              noDays: data?.data?.pro_rate?.no_of_days,
              perDiem: data?.data?.pro_rate?.per_diem_rate,
              proAmountRent: data?.data?.pro_rate?.pro_rent,
              proAmount: data?.data?.pro_rate?.pro_rate_amount,
              lmr: data?.data?.pro_rate?.lmr,
              Prior: data?.data?.pro_rate?.total_amount_prior_move_in,
              tenentStartDate: data?.data?.tenancy_start_date,
              tenentEndDate: data?.data?.tenancy_end_date,
              newApplicants: setNewApplicants(
                data?.data?.application_type?.new_application
              ),
              assignments: setAssignments(
                data?.data?.application_type?.assignment
              ),
              nameChanges: setNameChanges(
                data?.data?.application_type?.name_change
              ),
              sublets: setSublets(data?.data?.application_type?.sublet),
              transfers: setTransfers(data?.data?.application_type?.transfer),
              // date = moment.utc(date);
              // setRequestDate(dateString === "" ? "" : moment.utc(date));
              // Use formattedDate as the value for the DatePicker component

              // new form
              name_applicant_one:
                data?.data?.applicant_name?.name_applicant_one,
              name_applicant_two:
                data?.data?.applicant_name?.name_applicant_two,
              name_applicant_three:
                data?.data?.applicant_name?.name_applicant_three,

              current_address_one:
                data?.data?.current_address?.current_address_one,
              current_address_two:
                data?.data?.current_address?.current_address_two,
              current_address_three:
                data?.data?.current_address?.current_address_three,

              appartment_no_one: data?.data?.apartment_no?.appartment_no_one,
              appartment_no_two: data?.data?.apartment_no?.appartment_no_two,
              appartment_no_three:
                data?.data?.apartment_no?.appartment_no_three,

              present_monthly_rent_one:
                data?.data?.present_monthly_rent?.present_monthly_rent_one,
              present_monthly_rent_two:
                data?.data?.present_monthly_rent?.present_monthly_rent_two,
              present_monthly_rent_three:
                data?.data?.present_monthly_rent?.present_monthly_rent_three,

              postal_code_one: data?.data?.postal_code?.postal_code_one,
              postal_code_two: data?.data?.postal_code?.postal_code_two,
              postal_code_three: data?.data?.postal_code?.postal_code_three,

              length_of_residence_one:
                data?.data?.length_of_residence?.length_of_residence_one,
              length_of_residence_two:
                data?.data?.length_of_residence?.length_of_residence_two,
              length_of_residence_three:
                data?.data?.length_of_residence?.length_of_residence_three,

              home_phone_no_one: data?.data?.home_phone_no?.home_phone_no_one,
              home_phone_no_two: data?.data?.home_phone_no?.home_phone_no_two,
              home_phone_no_three:
                data?.data?.home_phone_no?.home_phone_no_three,

              cell_phone_no_one: data?.data?.cell_phone_no?.cell_phone_no_one,
              cell_phone_no_two: data?.data?.cell_phone_no?.cell_phone_no_two,
              cell_phone_no_three:
                data?.data?.cell_phone_no?.cell_phone_no_three,

              landlords_name_one:
                data?.data?.landlords_name?.landlords_name_one,
              landlords_name_two:
                data?.data?.landlords_name?.landlords_name_two,
              landlords_name_three:
                data?.data?.landlords_name?.landlords_name_three,

              landlords_phone_one:
                data?.data?.landlords_phone_no?.landlords_phone_one,
              landlords_phone_two:
                data?.data?.landlords_phone_no?.landlords_phone_two,
              landlords_phone_three:
                data?.data?.landlords_phone_no?.landlords_phone_three,

              previous_appartment_no_one:
                data?.data?.previous_apartment_no?.previous_appartment_no_one,

              previous_address_one:
                data?.data?.previous_address?.previous_address_one,
              previous_address_two:
                data?.data?.previous_address?.previous_address_two,

              previous_address_three:
                data?.data?.previous_address?.previous_address_three,

              previous_appartment_no_two:
                data?.data?.previous_apartment_no?.previous_appartment_no_two,
              previous_appartment_no_three:
                data?.data?.previous_apartment_no?.previous_appartment_no_three,

              previous_monthly_rent_one:
                data?.data?.previous_monthly_rent?.previous_monthly_rent_one,
              previous_monthly_rent_two:
                data?.data?.previous_monthly_rent?.previous_monthly_rent_two,
              previous_monthly_rent_three:
                data?.data?.previous_monthly_rent?.previous_monthly_rent_three,

              previous_postal_code_one:
                data?.data?.previous_postal_code?.previous_postal_code_one,
              previous_postal_code_two:
                data?.data?.previous_postal_code?.previous_postal_code_two,
              previous_postal_code_three:
                data?.data?.previous_postal_code?.previous_postal_code_three,

              previous_length_of_residence_one:
                data?.data?.previous_length_of_residence
                  ?.previous_length_of_residence_one,
              previous_length_of_residence_two:
                data?.data?.previous_length_of_residence
                  ?.previous_length_of_residence_two,
              previous_length_of_residence_three:
                data?.data?.previous_length_of_residence
                  ?.previous_length_of_residence_three,

              previous_landlords_name_one:
                data?.data?.previous_landlords_name
                  ?.previous_landlords_name_one,
              previous_landlords_name_two:
                data?.data?.previous_landlords_name
                  ?.previous_landlords_name_two,
              previous_landlords_name_three:
                data?.data?.previous_landlords_name
                  ?.previous_landlords_name_three,

              previous_landlords_phone_one:
                data?.data?.previous_landlords_phone_no
                  ?.previous_landlords_phone_one,
              previous_landlords_phone_two:
                data?.data?.previous_landlords_phone_no
                  ?.previous_landlords_phone_two,
              previous_landlords_phone_three:
                data?.data?.previous_landlords_phone_no
                  ?.previous_landlords_phone_three,

              current_employer_one:
                data?.data?.current_employer?.current_employer_one,
              current_employer_two:
                data?.data?.current_employer?.current_employer_two,
              current_employer_three:
                data?.data?.current_employer?.current_employer_three,

              work_phone_no_one: data?.data?.work_phone_no?.work_phone_no_one,
              work_phone_no_two: data?.data?.work_phone_no?.work_phone_no_two,
              work_phone_no_three:
                data?.data?.work_phone_no?.work_phone_no_three,

              position_one: data?.data?.position?.position_one,
              position_two: data?.data?.position?.position_two,
              position_three: data?.data?.position?.position_three,

              length_of_employment_one:
                data?.data?.length_of_employment?.length_of_employment_one,
              length_of_employment_two:
                data?.data?.length_of_employment?.length_of_employment_two,
              length_of_employment_three:
                data?.data?.length_of_employment?.length_of_employment_three,

              contact_name_no_one:
                data?.data?.contact_name_and_no?.contact_name_no_one,
              contact_name_no_two:
                data?.data?.contact_name_and_no?.contact_name_no_two,
              contact_name_no_three:
                data?.data?.contact_name_and_no?.contact_name_no_three,

              phone_no_one: data?.data?.phone_no?.phone_no_one,
              phone_no_two: data?.data?.phone_no?.phone_no_two,
              phone_no_three: data?.data?.phone_no?.phone_no_three,

              annual_income_one: data?.data?.annual_income?.annual_income_one,
              annual_income_two: data?.data?.annual_income?.annual_income_two,
              annual_income_three:
                data?.data?.annual_income?.annual_income_three,

              previous_employer_one:
                data?.data?.previous_employer?.previous_employer_one,
              previous_employer_two:
                data?.data?.previous_employer?.previous_employer_one,
              previous_employer_two:
                data?.data?.previous_employer?.previous_employer_two,

              previous_position_one:
                data?.data?.previous_position?.previous_position_one,
              previous_position_two:
                data?.data?.previous_position?.previous_position_two,
              previous_position_three:
                data?.data?.previous_position?.previous_position_three,

              Previous_length_of_employment_one:
                data?.data?.previous_length_of_employment
                  ?.Previous_length_of_employment_one,
              Previous_length_of_employment_two:
                data?.data?.previous_length_of_employment
                  ?.Previous_length_of_employment_two,
              Previous_length_of_employment_three:
                data?.data?.previous_length_of_employment
                  ?.Previous_length_of_employment_three,

              previous_contact_name_no_one:
                data?.data?.previous_contact_name_and_no
                  ?.previous_contact_name_no_one,
              previous_contact_name_no_two:
                data?.data?.previous_contact_name_and_no
                  ?.previous_contact_name_no_two,
              previous_contact_name_no_three:
                data?.data?.previous_contact_name_and_no
                  ?.previous_contact_name_no_three,

              previous_annual_income_one:
                data?.data?.previous_annual_income?.previous_annual_income_one,
              previous_annual_income_two:
                data?.data?.previous_annual_income?.previous_annual_income_two,
              previous_annual_income_three:
                data?.data?.previous_annual_income
                  ?.previous_annual_income_three,

              bank_one: data?.data?.bank?.bank_one,
              bank_two: data?.data?.bank?.bank_two,
              bank_three: data?.data?.bank?.bank_three,

              branch_one: data?.data?.branch?.branch_one,
              branch_two: data?.data?.branch?.branch_two,
              bank_three: data?.data?.branch?.bank_three,

              account_no_one: data?.data?.account_no?.account_no_one,
              account_no_two: data?.data?.account_no?.account_no_two,
              account_no_three: data?.data?.account_no?.account_no_three,

              make_of_vehicle_one:
                data?.data?.make_of_vehicle?.make_of_vehicle_one,
              make_of_vehicle_two:
                data?.data?.make_of_vehicle?.make_of_vehicle_two,
              make_of_vehicle_three:
                data?.data?.make_of_vehicle?.make_of_vehicle_three,

              year_model_of_vehicle_one:
                data?.data?.year_model_of_vehicle?.year_model_of_vehicle_one,
              year_model_of_vehicle_two:
                data?.data?.year_model_of_vehicle?.year_model_of_vehicle_two,
              year_model_of_vehicle_three:
                data?.data?.year_model_of_vehicle?.year_model_of_vehicle_three,

              colour_of_vehicle_one:
                data?.data?.colour_of_vehicle?.colour_of_vehicle_one,
              colour_of_vehicle_two:
                data?.data?.colour_of_vehicle?.colour_of_vehicle_two,
              colour_of_vehicle_three:
                data?.data?.colour_of_vehicle?.colour_of_vehicle_three,

              licence_plate_no_one:
                data?.data?.licence_plate_no?.licence_plate_no_one,
              licence_plate_no_two:
                data?.data?.licence_plate_no?.licence_plate_no_two,
              licence_plate_no_three:
                data?.data?.licence_plate_no?.licence_plate_no_three,

              drivers_license_no_one:
                data?.data?.drivers_license_no?.drivers_license_no_one,
              drivers_license_no_two:
                data?.data?.drivers_license_no?.drivers_license_no_two,
              drivers_license_no_three:
                data?.data?.drivers_license_no?.drivers_license_no_three,

              reference_name_one:
                data?.data?.reference_name?.reference_name_one,
              reference_name_two:
                data?.data?.reference_name?.reference_name_two,
              reference_name_three:
                data?.data?.reference_name?.reference_name_three,

              reference_address_one:
                data?.data?.reference_address?.reference_address_one,
              reference_address_two:
                data?.data?.reference_address?.reference_address_two,
              reference_address_three:
                data?.data?.reference_address?.reference_address_three,

              reference_phone_no_one:
                data?.data?.reference_phone_no?.reference_phone_no_one,
              reference_phone_no_two:
                data?.data?.reference_phone_no?.reference_phone_no_two,
              reference_phone_no_three:
                data?.data?.reference_phone_no?.reference_phone_no_three,

              reference_relationship_one:
                data?.data?.reference_relationship?.reference_relationship_one,
              reference_relationship_two:
                data?.data?.reference_relationship?.reference_relationship_two,
              reference_relationship_three:
                data?.data?.reference_relationship
                  ?.reference_relationship_three,

              next_name_one: data?.data?.next_of_kin_name?.next_name_one,
              next_name_two: data?.data?.next_of_kin_name?.next_name_two,
              next_name_three: data?.data?.next_of_kin_name?.next_name_three,

              next_address_one:
                data?.data?.next_of_kin_address?.next_address_one,
              next_address_two:
                data?.data?.next_of_kin_address?.next_address_two,
              next_address_three:
                data?.data?.next_of_kin_address?.next_address_three,

              next_phone_no_one:
                data?.data?.next_of_kin_phone_no?.next_phone_no_one,
              next_phone_no_two:
                data?.data?.next_of_kin_phone_no?.next_phone_no_two,
              next_phone_no_three:
                data?.data?.next_of_kin_phone_no?.next_phone_no_three,

              next_relationship_one:
                data?.data?.next_of_kin_relationship?.next_relationship_one,
              next_relationship_two:
                data?.data?.next_of_kin_relationship?.next_relationship_two,
              next_relationship_three:
                data?.data?.next_of_kin_relationship?.next_relationship_three,
              credit_unit: data?.data?.credit_unit,
              credit_building: data?.data?.credit_building,
              credit_date: data?.data?.credit_date,
            });
            setTypeSelected(data?.data?.unit_type);
            // setFields(data?.data?.bona_fide_tenants)
            if (data?.data?.bona_fide_tenants?.length > 0) {
              setFields(data.data.bona_fide_tenants);
            }

            // setOccupantFields(data?.data?.occupants)
            if (data?.data?.occupants?.length > 0) {
              setOccupantFields(data.data.occupants);
            }

            setSelectedImage(data?.data?.government_identification);
            setSelectedFile(data?.data?.proof_of_employment);
            setStatuse(data?.data?.status);
            setRequestDate(momentTz(data?.data?.today_date).tz("utc"));
            setTenentStartDate(
              data?.data?.tenancy_start_date === "" ||
                data?.data?.tenancy_start_date === null
                ? ""
                : momentTz(data?.data?.tenancy_start_date).tz("utc")
            );
            setTenentEndDate(
              data?.data?.tenancy_end_date === "" ||
                data?.data?.tenancy_end_date === null
                ? ""
                : momentTz(data?.data?.tenancy_end_date).tz("utc")
            );
            setProStartDate(
              data?.data?.pro_rate?.pro_start_date === "" ||
                data?.data?.pro_rate?.pro_start_date === null
                ? ""
                : momentTz(data?.data?.pro_rate?.pro_start_date).tz("utc")
            );
            // setProStartDate(
            //   momentTz(data?.data?.pro_rate?.pro_start_date).tz("utc")
            // );
            setProEndDate(
              data?.data?.pro_rate?.pro_End_date === "" ||
                data?.data?.pro_rate?.pro_End_date === null
                ? ""
                : momentTz(data?.data?.pro_rate?.pro_End_date).tz("utc")
            );
            // setProEndDate(
            //   momentTz(data?.data?.pro_rate?.pro_End_date).tz("utc")
            // );
            setDobApplicantOnes(
              data?.data?.date_of_birth?.date_of_birth_one === "" ||
                data?.data?.date_of_birth?.date_of_birth_one === null
                ? ""
                : momentTz(data?.data?.date_of_birth?.date_of_birth_one).tz(
                    "utc"
                  )
            );
            setDobApplicantTows(
              data?.data?.date_of_birth?.date_of_birth_two === "" ||
                data?.data?.date_of_birth?.date_of_birth_two === null
                ? ""
                : momentTz(data?.data?.date_of_birth?.date_of_birth_two).tz(
                    "utc"
                  )
            );
            setDobApplicantThrees(
              data?.data?.date_of_birth?.date_of_birth_three === "" ||
                data?.data?.date_of_birth?.date_of_birth_three === null
                ? ""
                : momentTz(data?.data?.date_of_birth?.date_of_birth_three).tz(
                    "utc"
                  )
            );

            // setSmokeDate(momentTz(data?.data?.cosmetic_smoke_date).tz("utc"));
            // setLimmitertDate(
            //   momentTz(data?.data?.cosmetic_limmiter_date).tz("utc")
            // );

            setTenantView(data?.data?.tenant_signature);
            setCreditTenantOneView(data?.data?.credit_tenant1_signature);
            setCreditTenantTwoView(data?.data?.credit_tenant2_signature);
            setCosmeticTenantView(data?.data?.cosmetic_smoke_tenant_signature);
            setLimmterTenantView(
              data?.data?.cosmetic_limmiter_tenant_signature
            );

            setBuildingManagerView(data?.data?.building_manager_signature);
            setPropertyManagerView(data?.data?.property_manager_signature);

            // setAgreementDate(momentTz(data?.data?.aggrement_date).tz("utc"));

            toast.success(data?.message); // Display the success toaster notification

            signPadTenent?.fromDataURL(data?.data?.tenant_signature);
            signPadTenent2?.fromDataURL(data?.data?.tenant_signature_2);
            signPadTenent3?.fromDataURL(data?.data?.tenant_signature_3);

            signPadCreditTenent1?.fromDataURL(
              data?.data?.credit_tenant1_signature
            );

            signPadTenentSmoke?.fromDataURL(
              data?.data?.cosmetic_smoke_tenant_signature
            );
            signPadTenentSmoke2?.fromDataURL(
              data?.data?.cosmetic_smoke_tenant_signature_2
            );
            signPadTenentSmoke3?.fromDataURL(
              data?.data?.cosmetic_smoke_tenant_signature_3
            );

            signPadTenentLimmiter?.fromDataURL(
              data?.data?.cosmetic_limmiter_tenant_signature
            );
            signPadTenentLimmiter2?.fromDataURL(
              data?.data?.cosmetic_limmiter_tenant_signature_2
            );
            signPadTenentLimmiter3?.fromDataURL(
              data?.data?.cosmetic_limmiter_tenant_signature_3
            );
            signPadCreditTenent2?.fromDataURL(
              data?.data?.credit_tenant2_signature
            );

            signPadCreditTenent3?.fromDataURL(
              data?.data?.credit_tenant3_signature
            );

            signPadCreditTenent1?.fromDataURL(
              data?.data?.credit_tenant1_signature
            );
            // setSignPadCreditTenent1()

            // tenant_signature:
            // signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

            // credit_tenant1_signature:
            // signPadCreditTenent1?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

            // credit_tenant2_signature:
            // signPadCreditTenent2?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,
            // cosmetic_smoke_tenant_signature:
            // signPadTenentSmoke?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,
            // cosmetic_limmiter_tenant_signature:
            // signPadTenentLimmiter?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,
          }
        })
        .catch((error) => {
          console.error("Token validation error:", error);
        });
    }
  }, [
    signPadTenent3,
    signPadTenent2,
    signPadTenent,
    signPadTenentSmoke,
    signPadTenentSmoke2,
    signPadTenentSmoke3,
    signPadTenentLimmiter,
    signPadTenentLimmiter2,
    signPadTenentLimmiter3,
    signPadCreditTenent1,
  ]);

  console.log("setTenantView", tenantView);
  console.log("Fields", fields);

  // useEffect(() => {
  //   DraftHandling();
  // }, [formik.values]);

  console.log("applicationID", applicationID);
  console.log("response Data", responseData);
  // class visibility hidden
  // update Api call on Draft
  console.log("signPadTenent3", signPadTenent3);
  const Update = async () => {
    try {
      let data = {
        suite_no: formik?.values?.suite,
        application_type: formik?.values?.type,
        building: localStorage.getItem("building_namee")
          ? localStorage.getItem("building_namee")
          : "Select Building",
        inside: formik?.values?.inside,
        outside: formik?.values?.outside,
        lastMonthRent: formik?.values?.lastMonthRent,
        belowLast: formik?.values?.belowLast,
        discountRent: formik?.values?.discountRent,
        discountLast: formik?.values?.discountLast,
        discountPayable: formik?.values?.discountPayable,
        netLast: formik?.values?.netLast,
        netPayable: formik?.values?.netPayable,
        lastMonthDeposit: formik?.values?.lastMonthDeposit,
        noDays: formik?.values?.noDays,
        perDiem: formik?.values?.perDiem,
        proAmountRent: formik?.values?.proAmountRent,
        proAmount: formik?.values?.proAmount,
        Prior: formik?.values?.Prior,
        initial_one: formik?.values?.initial_one,
        initial_two: formik?.values?.initial_two,
        initial_three: formik?.values?.initial_three,

        tenant_name: formik?.values?.tenentContactName,
        tenant_email: formik?.values?.tenentEmailAddress,
        tenant_phone_no: formik?.values?.tenentPhoneNumber,
        parking_below_ground: {
          law_rent: formik?.values?.belowRent,
          rent_payable: formik?.values?.belowPayAble,
        },
        bona_fide_tenants: fields,
        occupants: occupantFields,

        tenancy_start_date: tenentStartDate,
        tenancy_end_date: tenentEndDate,
        rent: {
          law_rent: formik?.values?.lawRent,
          rent_payable: formik?.values?.payAbleRent,
        },
        parking_above_ground: {
          law_rent: formik?.values?.parkingLawRent,
          rent_payable: formik?.values?.parkingPayAbleRent,
        },
        total_legal_rent: {
          law_rent: formik?.values?.legalRent,
          rent_payable_legal: formik?.values?.legalPayAble,
          last_month_rent: formik?.values?.legalLast,
        },
        prompt_payment_discount: formik?.values?.discountPayable,
        net_rent_payable: formik?.values?.netPayable,

        pro_rate: {
          pro_start_date: proStartDate,
          pro_End_date: proEndDate,
          no_of_days: formik?.values?.noDays,
          per_diem_rate: formik?.values?.perDiem,
          pro_rent: formik?.values?.proAmountRent,
          pro_rate_amount: formik?.values?.proAmount,
          lmr: formik?.values?.lmr,
          total_amount_prior_move_in: formik?.values?.Prior,
        },

        application_type: {
          new_application: newApplicants,
          assignment: assignments,
          sublet: sublets,
          transfer: transfers,
          name_change: nameChanges,
        },
        action: "draft",
        application_id: applicationID,

        government_identification: selectedImage,
        // proof_of_employment: selectedFile,
        proof_of_employment: selectedFile,

        aggrement_date: agreementDate,
        // credit_unit: formik?.values?.credit_unit,
        // credit_building: formik?.values?.credit_building,
        // credit_date: creditDate,
        // tenant_signature:
        //   signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
        // building_manager_signature: signPadBuildingManager
        //   ?.getTrimmedCanvas()
        //   ?.toDataURL("image/png"),
        // building_manager_signature:
        //   signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
        //   null,
        building_manager_signature: statics,
        property_manager_signature: statics,

        // property_manager_signature: signPadPropertyManager
        //   .getTrimmedCanvas()
        //   .toDataURL("image/png"),
        // property_manager_signature:
        //   signPadPropertyManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
        //   null,

        // acceptance_date :acceptanceDate,
        today_date: requestDate,
        // action: "draft",

        // new form

        applicant_name: {
          name_applicant_one: formik?.values?.name_applicant_one,
          name_applicant_two: formik?.values?.name_applicant_two,
          name_applicant_three: formik?.values?.name_applicant_three,
        },
        date_of_birth: {
          // date_of_birth_one: formik?.values?.date_of_birth_one,
          // date_of_birth_two: formik?.values?.date_of_birth_two,
          // date_of_birth_three: formik?.values?.date_of_birth_three,
          date_of_birth_one: dobApplicantOnes,
          date_of_birth_two: dobApplicantTwos,
          date_of_birth_three: dobApplicantThrees,
        },
        current_address: {
          current_address_one: formik?.values?.current_address_one,
          current_address_two: formik?.values?.current_address_two,
          current_address_three: formik?.values?.current_address_three,
        },
        apartment_no: {
          appartment_no_one: formik?.values?.appartment_no_one,
          appartment_no_two: formik?.values?.appartment_no_two,
          appartment_no_three: formik?.values?.appartment_no_three,
        },
        present_monthly_rent: {
          present_monthly_rent_one: formik?.values?.present_monthly_rent_one,
          present_monthly_rent_two: formik?.values?.present_monthly_rent_two,
          present_monthly_rent_three:
            formik?.values?.present_monthly_rent_three,
        },
        postal_code: {
          postal_code_one: formik?.values?.postal_code_one,
          postal_code_two: formik?.values?.postal_code_two,
          postal_code_three: formik?.values?.postal_code_three,
        },
        length_of_residence: {
          length_of_residence_one: formik?.values?.length_of_residence_one,
          length_of_residence_two: formik?.values?.length_of_residence_two,
          length_of_residence_three: formik?.values?.length_of_residence_three,
        },
        home_phone_no: {
          home_phone_no_one: formik?.values?.home_phone_no_one,
          home_phone_no_two: formik?.values?.home_phone_no_two,
          home_phone_no_three: formik?.values?.home_phone_no_three,
        },
        cell_phone_no: {
          cell_phone_no_one: formik?.values?.cell_phone_no_one,
          cell_phone_no_two: formik?.values?.cell_phone_no_two,
          cell_phone_no_three: formik?.values?.cell_phone_no_three,
        },

        landlords_name: {
          landlords_name_one: formik?.values?.landlords_name_one,
          landlords_name_two: formik?.values?.landlords_name_two,
          landlords_name_three: formik?.values?.landlords_name_three,
        },
        landlords_phone_no: {
          landlords_phone_one: formik?.values?.landlords_phone_one,
          landlords_phone_two: formik?.values?.landlords_phone_two,
          landlords_phone_three: formik?.values?.landlords_name_three,
        },

        previous_address: {
          previous_address_one: formik?.values?.previous_address_one,
          previous_address_two: formik?.values?.previous_address_two,
          previous_address_three: formik?.values?.previous_address_three,
        },
        previous_apartment_no: {
          previous_appartment_no_one:
            formik?.values?.previous_appartment_no_one,
          previous_appartment_no_two:
            formik?.values?.previous_appartment_no_two,
          previous_appartment_no_three:
            formik?.values?.previous_appartment_no_three,
        },
        previous_monthly_rent: {
          previous_monthly_rent_one: formik?.values?.previous_monthly_rent_one,
          previous_monthly_rent_two: formik?.values?.previous_monthly_rent_two,
          previous_monthly_rent_three:
            formik?.values?.previous_monthly_rent_three,
        },
        previous_postal_code: {
          previous_postal_code_one: formik?.values?.previous_postal_code_one,
          previous_postal_code_two: formik?.values?.previous_postal_code_two,
          previous_postal_code_three: formik?.values?.previous_position_three,
        },
        previous_length_of_residence: {
          previous_length_of_residence_one:
            formik?.values?.previous_length_of_residence_one,
          previous_length_of_residence_two:
            formik?.values?.previous_length_of_residence_two,
          previous_length_of_residence_three:
            formik?.values?.previous_length_of_residence_three,
        },
        previous_landlords_name: {
          previous_landlords_name_one:
            formik?.values?.previous_landlords_name_one,
          previous_landlords_name_two:
            formik?.values?.previous_landlords_name_two,
          previous_landlords_name_three:
            formik?.values?.previous_landlords_name_three,
        },
        previous_landlords_phone_no: {
          previous_landlords_phone_one:
            formik?.values?.previous_landlords_phone_one,
          previous_landlords_phone_two:
            formik?.values?.previous_landlords_name_two,
          previous_landlords_phone_three:
            formik?.values?.previous_landlords_name_three,
        },
        current_employer: {
          current_employer_one: formik?.values?.current_employer_one,
          current_employer_two: formik?.values?.current_employer_two,
          current_employer_three: formik?.values?.current_employer_three,
        },
        work_phone_no: {
          work_phone_no_one: formik?.values?.work_phone_no_one,
          work_phone_no_two: formik?.values?.work_phone_no_two,
          work_phone_no_three: formik?.values?.work_phone_no_three,
        },
        position: {
          position_one: formik?.values?.position_one,
          position_two: formik?.values?.position_two,
          position_three: formik?.values?.position_three,
        },
        length_of_employment: {
          length_of_employment_one: formik?.values?.length_of_employment_one,
          length_of_employment_two: formik?.values?.length_of_employment_two,
          length_of_employment_three:
            formik?.values?.length_of_employment_three,
        },
        contact_name_and_no: {
          contact_name_no_one: formik?.values?.contact_name_no_one,
          contact_name_no_two: formik?.values?.contact_name_no_two,
          contact_name_no_three: formik?.values?.contact_name_no_three,
        },
        phone_no: {
          phone_no_one: formik?.values?.phone_no_one,
          phone_no_two: formik?.values?.phone_no_two,
          phone_no_three: formik?.values?.phone_no_three,
        },
        annual_income: {
          annual_income_one: formik?.values?.annual_income_one,
          annual_income_two: formik?.values?.annual_income_two,
          annual_income_three: formik?.values?.annual_income_three,
        },
        previous_employer: {
          previous_employer_one: formik?.values?.previous_employer_one,
          previous_employer_two: formik?.values?.previous_employer_two,
          previous_employer_three: formik?.values?.previous_employer_three,
        },
        previous_position: {
          previous_position_one: formik?.values?.previous_position_one,
          previous_position_two: formik?.values?.previous_position_two,
          previous_position_three: formik?.values?.previous_position_three,
        },
        previous_length_of_employment: {
          Previous_length_of_employment_one:
            formik?.values?.Previous_length_of_employment_one,
          Previous_length_of_employment_two:
            formik?.values?.Previous_length_of_employment_two,
          Previous_length_of_employment_three:
            formik?.values?.previous_length_of_residence_three,
        },
        previous_contact_name_and_no: {
          previous_contact_name_no_one:
            formik?.values?.previous_contact_name_no_one,
          previous_contact_name_no_two:
            formik?.values?.previous_contact_name_no_two,
          previous_contact_name_no_three:
            formik?.values?.previous_annual_income_three,
        },
        previous_annual_income: {
          previous_annual_income_one:
            formik?.values?.previous_annual_income_one,
          previous_annual_income_two:
            formik?.values?.previous_annual_income_two,
          previous_annual_income_three:
            formik?.values?.previous_annual_income_three,
        },
        bank: {
          bank_one: formik?.values?.bank_one,
          bank_two: formik?.values?.bank_two,
          bank_three: formik?.values?.bank_three,
        },
        branch: {
          branch_one: formik?.values?.branch_one,
          branch_two: formik?.values?.branch_two,
          branch_three: formik?.values?.branch_three,
        },
        account_no: {
          account_no_one: formik?.values?.account_no_one,
          account_no_two: formik?.values?.account_no_two,
          account_no_three: formik?.values?.account_no_three,
        },
        make_of_vehicle: {
          make_of_vehicle_one: formik?.values?.make_of_vehicle_one,
          make_of_vehicle_two: formik?.values?.make_of_vehicle_two,
          make_of_vehicle_three: formik?.values?.make_of_vehicle_three,
        },
        year_model_of_vehicle: {
          year_model_of_vehicle_one: formik?.values?.year_model_of_vehicle_one,
          year_model_of_vehicle_two: formik?.values?.year_model_of_vehicle_two,
          year_model_of_vehicle_three:
            formik?.values?.year_model_of_vehicle_threer,
        },
        colour_of_vehicle: {
          colour_of_vehicle_one: formik?.values?.colour_of_vehicle_one,
          colour_of_vehicle_two: formik?.values?.colour_of_vehicle_two,
          colour_of_vehicle_three: formik?.values?.colour_of_vehicle_three,
        },
        licence_plate_no: {
          licence_plate_no_one: formik?.values?.licence_plate_no_one,
          licence_plate_no_two: formik?.values?.licence_plate_no_two,
          licence_plate_no_three: formik?.values?.licence_plate_no_three,
        },
        drivers_license_no: {
          drivers_license_no_one: formik?.values?.drivers_license_no_one,
          drivers_license_no_two: formik?.values?.drivers_license_no_two,
          drivers_license_no_three: formik?.values?.drivers_license_no_three,
        },
        reference_name: {
          reference_name_one: formik?.values?.reference_name_one,
          reference_name_two: formik?.values?.reference_name_two,
          reference_name_three: formik?.values?.reference_name_three,
        },
        reference_address: {
          reference_address_one: formik?.values?.reference_address_one,
          reference_address_two: formik?.values?.reference_address_two,
          reference_address_three: formik?.values?.reference_address_three,
        },
        reference_phone_no: {
          reference_phone_no_one: formik?.values?.reference_phone_no_one,
          reference_phone_no_two: formik?.values?.reference_phone_no_two,
          reference_phone_no_three: formik?.values?.reference_phone_no_three,
        },
        reference_relationship: {
          reference_relationship_one:
            formik?.values?.reference_relationship_one,
          reference_relationship_two:
            formik?.values?.reference_relationship_two,
          reference_relationship_three:
            formik?.values?.reference_relationship_three,
        },
        next_of_kin_name: {
          next_name_one: formik?.values?.next_name_one,
          next_name_two: formik?.values?.next_name_two,
          next_name_three: formik?.values?.next_name_three,
        },
        next_of_kin_address: {
          next_address_one: formik?.values?.next_address_one,
          next_address_two: formik?.values?.next_address_two,
          next_address_three: formik?.values?.next_address_three,
        },
        next_of_kin_phone_no: {
          next_phone_no_one: formik?.values?.next_phone_no_one,
          next_phone_no_two: formik?.values?.next_phone_no_two,
          next_phone_no_three:
            formik?.values?.formik?.values?.next_phone_no_three,
        },
        next_of_kin_relationship: {
          next_relationship_one: formik?.values?.next_relationship_one,
          next_relationship_two: formik?.values?.next_relationship_two,
          next_relationship_three: formik?.values?.next_relationship_three,
        },
        credit_unit: formik?.values?.suite,
        credit_building: formik?.values?.building,
        credit_date: creditDate,

        cosmetic_smoke_date: smokeDate,
        cosmetic_limmiter_date: limmiterDate,
        cosmetic_limmiter_date_2: limmiterDate2,
        cosmetic_limmiter_date_3: limmiterDate3,

        tenant_signature:
          signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,
        tenant_signature_2:
          signPadTenent2?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,
        tenant_signature_3:
          signPadTenent3?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

        credit_tenant1_signature:
          signPadCreditTenent1?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        credit_tenant2_signature:
          signPadCreditTenent2?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        credit_tenant3_signature:
          signPadCreditTenent3?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        cosmetic_smoke_tenant_signature:
          signPadTenentSmoke?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        cosmetic_smoke_tenant_signature_2:
          signPadTenentSmoke2?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        cosmetic_smoke_tenant_signature_3:
          signPadTenentSmoke3?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        cosmetic_limmiter_tenant_signature:
          signPadTenentLimmiter?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,
        cosmetic_limmiter_tenant_signature_2:
          signPadTenentLimmiter2?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        cosmetic_limmiter_tenant_signature_3:
          signPadTenentLimmiter3?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,
      };
      console.log(data);
      const Application_token = localStorage.getItem("Application_token");

      let res = await userApiCallApplication(
        `/api/user/update_application`,
        "POST",
        JSON.stringify(data),
        Application_token
      );
      console.log("Response user Application", res);
      // toast.success(res?.message); // Display the success toaster notification
      // navigate("/thankyou");
    } catch (error) {
      console.log("error update", error);
      // notifyError("Something went wrong.. Please Try Again");
      notifyError(error?.responseJSON?.message);

      return false;
    }
  };

  // update Api call on submit
  const UpdateSubmit = async () => {
    try {
      let data = {
        suite_no: formik?.values?.suite,
        application_type: formik?.values?.type,
        building: localStorage.getItem("building_namee")
          ? localStorage.getItem("building_namee")
          : "Select Building",
        inside: formik?.values?.inside,
        outside: formik?.values?.outside,
        lastMonthRent: formik?.values?.lastMonthRent,
        belowLast: formik?.values?.belowLast,
        discountRent: formik?.values?.discountRent,
        discountLast: formik?.values?.discountLast,
        discountPayable: formik?.values?.discountPayable,
        netLast: formik?.values?.netLast,
        netPayable: formik?.values?.netPayable,
        lastMonthDeposit: formik?.values?.lastMonthDeposit,
        noDays: formik?.values?.noDays,
        perDiem: formik?.values?.perDiem,
        proAmountRent: formik?.values?.proAmountRent,
        proAmount: formik?.values?.proAmount,
        Prior: formik?.values?.Prior,
        initial_one: formik?.values?.initial_one,
        initial_two: formik?.values?.initial_two,
        initial_three: formik?.values?.initial_three,

        tenant_name: formik?.values?.tenentContactName,
        tenant_email: formik?.values?.tenentEmailAddress,
        tenant_phone_no: formik?.values?.tenentPhoneNumber,
        parking_below_ground: {
          law_rent: formik?.values?.belowRent,
          rent_payable: formik?.values?.belowPayAble,
        },
        bona_fide_tenants: fields,
        occupants: occupantFields,

        tenancy_start_date: tenentStartDate,
        tenancy_end_date: tenentEndDate,
        rent: {
          law_rent: formik?.values?.lawRent,
          rent_payable: formik?.values?.payAbleRent,
        },
        parking_above_ground: {
          law_rent: formik?.values?.parkingLawRent,
          rent_payable: formik?.values?.parkingPayAbleRent,
        },
        total_legal_rent: {
          law_rent: formik?.values?.legalRent,
          rent_payable_legal: formik?.values?.legalPayAble,
          last_month_rent: formik?.values?.legalLast,
        },
        prompt_payment_discount: formik?.values?.discountPayable,
        net_rent_payable: formik?.values?.netPayable,

        pro_rate: {
          pro_start_date: proStartDate,
          pro_End_date: proEndDate,
          no_of_days: formik?.values?.noDays,
          per_diem_rate: formik?.values?.perDiem,
          pro_rent: formik?.values?.proAmountRent,
          pro_rate_amount: formik?.values?.proAmount,
          lmr: formik?.values?.lmr,
          total_amount_prior_move_in: formik?.values?.Prior,
        },

        application_type: {
          new_application: newApplicants,
          assignment: assignments,
          sublet: sublets,
          transfer: transfers,
          name_change: nameChanges,
        },
        action: "send",
        application_id: applicationID,

        government_identification: selectedImage,
        proof_of_employment: selectedFile,
        aggrement_date: agreementDate,
        // tenant_signature:
        //   signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null,
        // building_manager_signature:
        //   signPadBuildingManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
        //   null,
        building_manager_signature: statics,
        property_manager_signature: statics,

        // property_manager_signature:
        //   signPadPropertyManager?.getTrimmedCanvas()?.toDataURL("image/png") ??
        //   null,
        // acceptance_date :acceptanceDate,
        today_date: requestDate,

        applicant_name: {
          name_applicant_one: formik?.values?.name_applicant_one,
          name_applicant_two: formik?.values?.name_applicant_two,
          name_applicant_three: formik?.values?.name_applicant_three,
        },
        date_of_birth: {
          // date_of_birth_one: formik?.values?.date_of_birth_one,
          // date_of_birth_two: formik?.values?.date_of_birth_two,
          // date_of_birth_three: formik?.values?.date_of_birth_three,
          date_of_birth_one: dobApplicantOnes,
          date_of_birth_two: dobApplicantTwos,
          date_of_birth_three: dobApplicantThrees,
        },
        current_address: {
          current_address_one: formik?.values?.current_address_one,
          current_address_two: formik?.values?.current_address_two,
          current_address_three: formik?.values?.current_address_three,
        },
        apartment_no: {
          appartment_no_one: formik?.values?.appartment_no_one,
          appartment_no_two: formik?.values?.appartment_no_two,
          appartment_no_three: formik?.values?.appartment_no_three,
        },
        present_monthly_rent: {
          present_monthly_rent_one: formik?.values?.present_monthly_rent_one,
          present_monthly_rent_two: formik?.values?.present_monthly_rent_two,
          present_monthly_rent_three:
            formik?.values?.present_monthly_rent_three,
        },
        postal_code: {
          postal_code_one: formik?.values?.postal_code_one,
          postal_code_two: formik?.values?.postal_code_two,
          postal_code_three: formik?.values?.postal_code_three,
        },
        length_of_residence: {
          length_of_residence_one: formik?.values?.length_of_residence_one,
          length_of_residence_two: formik?.values?.length_of_residence_two,
          length_of_residence_three: formik?.values?.length_of_residence_three,
        },
        home_phone_no: {
          home_phone_no_one: formik?.values?.home_phone_no_one,
          home_phone_no_two: formik?.values?.home_phone_no_two,
          home_phone_no_three: formik?.values?.home_phone_no_three,
        },
        cell_phone_no: {
          cell_phone_no_one: formik?.values?.cell_phone_no_one,
          cell_phone_no_two: formik?.values?.cell_phone_no_two,
          cell_phone_no_three: formik?.values?.cell_phone_no_three,
        },

        landlords_name: {
          landlords_name_one: formik?.values?.landlords_name_one,
          landlords_name_two: formik?.values?.landlords_name_two,
          landlords_name_three: formik?.values?.landlords_name_three,
        },
        landlords_phone_no: {
          landlords_phone_one: formik?.values?.landlords_phone_one,
          landlords_phone_two: formik?.values?.landlords_phone_two,
          landlords_phone_three: formik?.values?.landlords_name_three,
        },

        previous_address: {
          previous_address_one: formik?.values?.previous_address_one,
          previous_address_two: formik?.values?.previous_address_two,
          previous_address_three: formik?.values?.previous_address_three,
        },
        previous_apartment_no: {
          previous_appartment_no_one:
            formik?.values?.previous_appartment_no_one,
          previous_appartment_no_two:
            formik?.values?.previous_appartment_no_two,
          previous_appartment_no_three:
            formik?.values?.previous_appartment_no_three,
        },
        previous_monthly_rent: {
          previous_monthly_rent_one: formik?.values?.previous_monthly_rent_one,
          previous_monthly_rent_two: formik?.values?.previous_monthly_rent_two,
          previous_monthly_rent_three:
            formik?.values?.previous_monthly_rent_three,
        },
        previous_postal_code: {
          previous_postal_code_one: formik?.values?.previous_postal_code_one,
          previous_postal_code_two: formik?.values?.previous_postal_code_two,
          previous_postal_code_three: formik?.values?.previous_position_three,
        },
        previous_length_of_residence: {
          previous_length_of_residence_one:
            formik?.values?.previous_length_of_residence_one,
          previous_length_of_residence_two:
            formik?.values?.previous_length_of_residence_two,
          previous_length_of_residence_three:
            formik?.values?.previous_length_of_residence_three,
        },
        previous_landlords_name: {
          previous_landlords_name_one:
            formik?.values?.previous_landlords_name_one,
          previous_landlords_name_two:
            formik?.values?.previous_landlords_name_two,
          previous_landlords_name_three:
            formik?.values?.previous_landlords_name_three,
        },
        previous_landlords_phone_no: {
          previous_landlords_phone_one:
            formik?.values?.previous_landlords_phone_one,
          previous_landlords_phone_two:
            formik?.values?.previous_landlords_name_two,
          previous_landlords_phone_three:
            formik?.values?.previous_landlords_name_three,
        },
        current_employer: {
          current_employer_one: formik?.values?.current_employer_one,
          current_employer_two: formik?.values?.current_employer_two,
          current_employer_three: formik?.values?.current_employer_three,
        },
        work_phone_no: {
          work_phone_no_one: formik?.values?.work_phone_no_one,
          work_phone_no_two: formik?.values?.work_phone_no_two,
          work_phone_no_three: formik?.values?.work_phone_no_three,
        },
        position: {
          position_one: formik?.values?.position_one,
          position_two: formik?.values?.position_two,
          position_three: formik?.values?.position_three,
        },
        length_of_employment: {
          length_of_employment_one: formik?.values?.length_of_employment_one,
          length_of_employment_two: formik?.values?.length_of_employment_two,
          length_of_employment_three:
            formik?.values?.length_of_employment_three,
        },
        contact_name_and_no: {
          contact_name_no_one: formik?.values?.contact_name_no_one,
          contact_name_no_two: formik?.values?.contact_name_no_two,
          contact_name_no_three: formik?.values?.contact_name_no_three,
        },
        phone_no: {
          phone_no_one: formik?.values?.phone_no_one,
          phone_no_two: formik?.values?.phone_no_two,
          phone_no_three: formik?.values?.phone_no_three,
        },
        annual_income: {
          annual_income_one: formik?.values?.annual_income_one,
          annual_income_two: formik?.values?.annual_income_two,
          annual_income_three: formik?.values?.annual_income_three,
        },
        previous_employer: {
          previous_employer_one: formik?.values?.previous_employer_one,
          previous_employer_two: formik?.values?.previous_employer_two,
          previous_employer_three: formik?.values?.previous_employer_three,
        },
        previous_position: {
          previous_position_one: formik?.values?.previous_position_one,
          previous_position_two: formik?.values?.previous_position_two,
          previous_position_three: formik?.values?.previous_position_three,
        },
        previous_length_of_employment: {
          Previous_length_of_employment_one:
            formik?.values?.Previous_length_of_employment_one,
          Previous_length_of_employment_two:
            formik?.values?.Previous_length_of_employment_two,
          Previous_length_of_employment_three:
            formik?.values?.previous_length_of_residence_three,
        },
        previous_contact_name_and_no: {
          previous_contact_name_no_one:
            formik?.values?.previous_contact_name_no_one,
          previous_contact_name_no_two:
            formik?.values?.previous_contact_name_no_two,
          previous_contact_name_no_three:
            formik?.values?.previous_annual_income_three,
        },
        previous_annual_income: {
          previous_annual_income_one:
            formik?.values?.previous_annual_income_one,
          previous_annual_income_two:
            formik?.values?.previous_annual_income_two,
          previous_annual_income_three:
            formik?.values?.previous_annual_income_three,
        },
        bank: {
          bank_one: formik?.values?.bank_one,
          bank_two: formik?.values?.bank_two,
          bank_three: formik?.values?.bank_three,
        },
        branch: {
          branch_one: formik?.values?.branch_one,
          branch_two: formik?.values?.branch_two,
          branch_three: formik?.values?.branch_three,
        },
        account_no: {
          account_no_one: formik?.values?.account_no_one,
          account_no_two: formik?.values?.account_no_two,
          account_no_three: formik?.values?.account_no_three,
        },
        make_of_vehicle: {
          make_of_vehicle_one: formik?.values?.make_of_vehicle_one,
          make_of_vehicle_two: formik?.values?.make_of_vehicle_two,
          make_of_vehicle_three: formik?.values?.make_of_vehicle_three,
        },
        year_model_of_vehicle: {
          year_model_of_vehicle_one: formik?.values?.year_model_of_vehicle_one,
          year_model_of_vehicle_two: formik?.values?.year_model_of_vehicle_two,
          year_model_of_vehicle_three:
            formik?.values?.year_model_of_vehicle_threer,
        },
        colour_of_vehicle: {
          colour_of_vehicle_one: formik?.values?.colour_of_vehicle_one,
          colour_of_vehicle_two: formik?.values?.colour_of_vehicle_two,
          colour_of_vehicle_three: formik?.values?.colour_of_vehicle_three,
        },
        licence_plate_no: {
          licence_plate_no_one: formik?.values?.licence_plate_no_one,
          licence_plate_no_two: formik?.values?.licence_plate_no_two,
          licence_plate_no_three: formik?.values?.licence_plate_no_three,
        },
        drivers_license_no: {
          drivers_license_no_one: formik?.values?.drivers_license_no_one,
          drivers_license_no_two: formik?.values?.drivers_license_no_two,
          drivers_license_no_three: formik?.values?.drivers_license_no_three,
        },
        reference_name: {
          reference_name_one: formik?.values?.reference_name_one,
          reference_name_two: formik?.values?.reference_name_two,
          reference_name_three: formik?.values?.reference_name_three,
        },
        reference_address: {
          reference_address_one: formik?.values?.reference_address_one,
          reference_address_two: formik?.values?.reference_address_two,
          reference_address_three: formik?.values?.reference_address_three,
        },
        reference_phone_no: {
          reference_phone_no_one: formik?.values?.reference_phone_no_one,
          reference_phone_no_two: formik?.values?.reference_phone_no_two,
          reference_phone_no_three: formik?.values?.reference_phone_no_three,
        },
        reference_relationship: {
          reference_relationship_one:
            formik?.values?.reference_relationship_one,
          reference_relationship_two:
            formik?.values?.reference_relationship_two,
          reference_relationship_three:
            formik?.values?.reference_relationship_three,
        },
        next_of_kin_name: {
          next_name_one: formik?.values?.next_name_one,
          next_name_two: formik?.values?.next_name_two,
          next_name_three: formik?.values?.next_name_three,
        },
        next_of_kin_address: {
          next_address_one: formik?.values?.next_address_one,
          next_address_two: formik?.values?.next_address_two,
          next_address_three: formik?.values?.next_address_three,
        },
        next_of_kin_phone_no: {
          next_phone_no_one: formik?.values?.next_phone_no_one,
          next_phone_no_two: formik?.values?.next_phone_no_two,
          next_phone_no_three:
            formik?.values?.formik?.values?.next_phone_no_three,
        },
        next_of_kin_relationship: {
          next_relationship_one: formik?.values?.next_relationship_one,
          next_relationship_two: formik?.values?.next_relationship_two,
          next_relationship_three: formik?.values?.next_relationship_three,
        },
        credit_unit: formik?.values?.suite,
        credit_building: formik?.values?.building,
        credit_date: creditDate,

        cosmetic_smoke_date: smokeDate,
        cosmetic_limmiter_date: limmiterDate,
        cosmetic_limmiter_date_2: limmiterDate2,
        cosmetic_limmiter_date_3: limmiterDate3,

        tenant_signature:
          signPadTenent?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,
        tenant_signature_2:
          signPadTenent2?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,
        tenant_signature_3:
          signPadTenent3?.getTrimmedCanvas()?.toDataURL("image/png") ?? null, // building_manager_signature: agreementDate,

        credit_tenant1_signature:
          signPadCreditTenent1?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        credit_tenant2_signature:
          signPadCreditTenent2?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        credit_tenant3_signature:
          signPadCreditTenent3?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        cosmetic_smoke_tenant_signature:
          signPadTenentSmoke?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        cosmetic_smoke_tenant_signature_2:
          signPadTenentSmoke2?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        cosmetic_smoke_tenant_signature_3:
          signPadTenentSmoke3?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        cosmetic_limmiter_tenant_signature:
          signPadTenentLimmiter?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,
        cosmetic_limmiter_tenant_signature_2:
          signPadTenentLimmiter2?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,

        cosmetic_limmiter_tenant_signature_3:
          signPadTenentLimmiter3?.getTrimmedCanvas()?.toDataURL("image/png") ??
          null, // building_manager_signature: agreementDate,
      };
      const Application_token = localStorage.getItem("Application_token");

      let res = await userApiCallApplication(
        `/api/user/update_application`,
        "POST",
        JSON.stringify(data),
        Application_token
      );
      console.log("Response user Application", res);
      toast.success(res?.message); // Display the success toaster notification
      navigate("/thankyou");
      // navigate("/thankyou");
    } catch (error) {
      notifyError(error?.responseJSON?.message);

      return false;
    }
  };

  console.log("formik==>", formik);
  console.log("Vals", newApplicants);
  console.log("request Date", requestDate);
  // tabs Configuration
  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
  };
  // console.log("setSignPad Building==>", signPadBuildingManager);
  // protected

  // console.log("selectedFile==>", selectedFile);
  // useEffect(() => {
  //   const applicationToken = localStorage.getItem("Application_token");
  //   if (applicationToken) {
  //     setIsOTPVerified(true);
  //   } else if (applicationToken == undefined) {
  //     navigate("/user_application/verify_otp");
  //   }
  //   return () => {
  //     // Remove the token from localStorage when the component is unmounted
  //     localStorage.removeItem("Application_token");
  //   };
  // }, []);
  // window.onbeforeunload = () => {
  //   // Clear the token from local storage when the user navigates away
  //   localStorage.removeItem("Application_token");
  // };

  const refsss = useReactToPrint({
    copyStyles: true,
    pageStyles:
      "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin: 0; padding: 0; } div { page-break-after: auto; } }",
    content: () => {
      // console.log("pdf", { elem: listingRef2.current });
      const listingRefpdf1 = listingRef1.current?.cloneNode(true);
      const listingRefpdf2 = listingRef2.current?.cloneNode(true);
      const listingRefpdf3 = listingRef3.current?.cloneNode(true);
      const listingRefpdf4 = listingRef4.current?.cloneNode(true);
      const listingRefpdf5 = listingRef5.current?.cloneNode(true);
      // const listingRefpdf6 = listingRef6.current?.cloneNode(true);

      const PrintElem = document.createElement("div");
      // PrintElem.appendChild(data_toPrint);
      PrintElem.appendChild(listingRefpdf1);
      PrintElem.appendChild(listingRefpdf2);
      PrintElem.appendChild(listingRefpdf3);
      PrintElem.appendChild(listingRefpdf4);
      // PrintElem.appendChild(listingRefpdf5);

      return PrintElem;
    },
    onAfterPrint: () => {
      setShowLogo(false);
    },
  });
  const tabs = [
    "1: Application",
    "2: Applicant Particulars",
    "3: Credit Information Agreement",
    "4: No Cosmetic Repair Agreement",
    "5: Upload Files",
  ];
  function isValidEmail(email) {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailPattern.test(email);
  }

  console.log("isSignatureSigned", isSignatureSigned);

  console.log("activeTab out", activeTab);
  // -----------------------------------------------------hadleNext----------------------
  const handleNext = async () => {
    let emptyURL =
      "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/2wBDAQMDAwQDBAgEBAgQCwkLEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBD/wAARCAABAAEDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAn/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AJVAA//Z"; // formik.setTouched{}
    let errors = await formik.validateForm();
    console.log({ errors });
    const canvasIsEmpty = signPadTenent
      .getTrimmedCanvas()
      .toDataURL("image/jpeg");

    const canvasIsEmpty2 = signPadTenent2
      ?.getTrimmedCanvas()
      ?.toDataURL("image/jpeg");
    const canvasIsEmpty3 = signPadTenent3
      ?.getTrimmedCanvas()
      ?.toDataURL("image/jpeg");
    // const canvasIsEmpty = signPadTenent?.isEmpty();
    const creditsignss = signPadCreditTenent1
      ?.getTrimmedCanvas()
      .toDataURL("image/jpeg");
    const creditsignss2 = signPadCreditTenent2
      ?.getTrimmedCanvas()
      .toDataURL("image/jpeg");

    const creditsignss3 = signPadCreditTenent3
      ?.getTrimmedCanvas()
      .toDataURL("image/jpeg");

    const cosmeticAgreementsn = signPadTenentSmoke
      ?.getTrimmedCanvas()
      .toDataURL("image/jpeg");

    const limmietsign = signPadTenentLimmiter
      ?.getTrimmedCanvas()
      .toDataURL("image/jpeg");
    const cosmeticAgreementsn2 = signPadTenentSmoke2
      ?.getTrimmedCanvas()
      .toDataURL("image/jpeg");

    const cosmeticAgreementsn3 = signPadTenentSmoke3
      ?.getTrimmedCanvas()
      .toDataURL("image/jpeg");

    const limmietsign2 = signPadTenentLimmiter2
      ?.getTrimmedCanvas()
      .toDataURL("image/jpeg");

    const limmietsign3 = signPadTenentLimmiter3
      ?.getTrimmedCanvas()
      .toDataURL("image/jpeg");

    const hasMissingValues = fields?.some((field) => {
      return !field.firstName.trim() || !field.lastName.trim();
    });

    console.log("hasMissingValues ==>", hasMissingValues);
    if (hasMissingValues) {
      toast.error("Please fill in all fields before proceeding", {
        position: "top-right",
      });

      const validationStatus = fields?.map((field) => ({
        firstName: field?.firstName?.trim() !== "",
        // middleName: field?.middleName?.trim() !== "",
        lastName: field?.lastName?.trim() !== "",
        // sin: field?.sin?.trim() !== "",
        // email: isValidEmail(field.email),
      }));
      setFieldValidation(validationStatus);

      const validationStatusoc = occupantFields?.map((field) => ({
        // firstNameOccupant: field?.firstNameOccupant?.trim() !== "",
        // middleNameOccupant: field?.middleNameOccupant?.trim() !== "",
        // lastNameOccupant: field?.lastNameOccupant?.trim() !== "",
        // sinOccupant: field?.sinOccupant?.trim() !== "",
        // emailOccupant: isValidEmail(field?.emailOccupant),
      }));
      setOccupantFieldValidation(validationStatusoc);
    } else if (
      activeTab == "1: Application" &&
      ((fields.length === 1 && canvasIsEmpty === emptyURL) ||
        (fields.length >= 2 && canvasIsEmpty2 === emptyURL) ||
        (fields.length === 3 && canvasIsEmpty3 === emptyURL))
    ) {
      toast.error("Please make sure all tenants sign before proceeding", {
        position: "top-right",
      });
    } else if (
      activeTab == "1: Application" &&
      ((fields.length === 1 && formik.values.initial_one === null) ||
        (fields.length === 1 && formik.values.initial_one === ""))
    ) {
      toast.error("Please fill initials first for further proceeding", {
        position: "top-right",
      });
    } else if (
      activeTab == "1: Application" &&
      ((fields.length >= 2 && formik.values.initial_two === null) ||
        (fields.length >= 2 && formik.values.initial_two === ""))
    ) {
      toast.error("Please fill initials first for further proceeding", {
        position: "top-right",
      });
    } else if (
      activeTab == "1: Application" &&
      ((fields.length === 3 && formik.values.initial_three === null) ||
        (fields.length === 3 && formik.values.initial_three === ""))
    ) {
      toast.error("Please fill initials first for further proceeding", {
        position: "top-right",
      });
    } else if (
      activeTab == "3: Credit Information Agreement" &&
      ((fields.length === 1 && creditsignss === emptyURL) ||
        (fields.length >= 2 && creditsignss2 === emptyURL) ||
        (fields.length === 3 && creditsignss3 === emptyURL))
    ) {
      toast.error("Please make sure all tenants sign before proceeding", {
        position: "top-right",
      });
    }
    // else if (
    //   activeTab == "2: Applicant Particular" &&
    //   Object.keys(errors).length > 0
    // ) {
    //   toast.error("Please Fill in all Required fields before proceeding", {
    //     position: "top-right",
    //   });
    //   formik.setTouched({ ...formik.touched, ...formik.errors });
    // }
    else if (
      activeTab == "4: No Cosmetic Repair Agreement" &&
      ((fields.length === 1 && cosmeticAgreementsn === emptyURL) ||
        (fields.length >= 2 && cosmeticAgreementsn2 === emptyURL) ||
        (fields.length === 3 && cosmeticAgreementsn3 === emptyURL) ||
        (fields.length === 1 && limmietsign === emptyURL) ||
        (fields.length >= 2 && limmietsign2 === emptyURL) ||
        (fields.length === 3 && limmietsign3 === emptyURL))
    ) {
      toast.error("Please make sure all tenants sign before proceeding", {
        position: "top-right",
      });
    } else {
      const currentIndex = tabs?.indexOf(activeTab);
      if (currentIndex < tabs?.length - 1) {
        setActiveTab(tabs[currentIndex + 1]);
      }
      console.log("currentIndex", currentIndex);
    }
  };
  // --------------------------------------Updated Handle Next -------------------------------

  //   const handleNext = async () => {
  //     console.log("signPadTenent", signPadTenent, "asasa", signPadTenent2);
  //     let errors = await formik.validateForm();
  //     console.log({ errors });

  //     const isEmptyCanvas = (canvas) => canvas?.getTrimmedCanvas()?.toDataURL("image/jpeg");

  //     const canvasIsEmpty = isEmptyCanvas(signPadTenent);
  //     const canvasIsEmpty2 = isEmptyCanvas(signPadTenent2);
  //     const canvasIsEmpty3 = isEmptyCanvas(signPadTenent3);
  //     const creditsignss = isEmptyCanvas(signPadCreditTenent1);
  //     const creditsignss2 = isEmptyCanvas(signPadCreditTenent2);
  //     const creditsignss3 = isEmptyCanvas(signPadCreditTenent3);
  //     const cosmeticAgreementsn = isEmptyCanvas(signPadTenentSmoke);
  //     const cosmeticAgreementsn2 = isEmptyCanvas(signPadTenentSmoke2);
  //     const cosmeticAgreementsn3 = isEmptyCanvas(signPadTenentSmoke3);
  //     const limmietsign = isEmptyCanvas(signPadTenentLimmiter);
  //     const limmietsign2 = isEmptyCanvas(signPadTenentLimmiter2);
  //     const limmietsign3 = isEmptyCanvas(signPadTenentLimmiter3);

  //     const hasMissingValues = fields?.some((field) => !field.firstName.trim() || !field.lastName.trim());

  //     console.log("hasMissingValues ==>", hasMissingValues);
  //     if (hasMissingValues) {
  //       toast.error("Please fill in all fields before proceeding", {
  //         position: "top-right",
  //       });

  //       const validationStatus = fields?.map((field, index) => ({
  //         firstName: field?.firstName?.trim() !== "",
  //         lastName: field?.lastName?.trim() !== "" && (fields.length === 1 || (fields.length >= 2 && index === 1)),
  //       }));
  //       setFieldValidation(validationStatus);

  //       const validationStatusoc = occupantFields?.map((field) => ({
  //         // Similar validation logic for occupant fields
  //       }));
  //       setOccupantFieldValidation(validationStatusoc);
  //     } else if (
  //       activeTab == "1: Application" &&
  //       (canvasIsEmpty === null || canvasIsEmpty === ""||(fields.length >= 2 &&  canvasIsEmpty2 === null) || (fields.length >= 2 && canvasIsEmpty2 === "")|| (fields.length ===3 && canvasIsEmpty3 === null) || (fields.length ===3 && canvasIsEmpty3 === ""))
  //     ) {
  //       toast.error("Please sign the Document before proceeding", {
  //         position: "top-right",
  //       });
  //     } else if (
  //       activeTab == "1: Application" &&
  //       (formik.values.initial_one === null || formik.values.initial_one === "")
  //     ) {
  //       toast.error("Please fill initials first for further proceeding", {
  //         position: "top-right",
  //       });
  //     } else if (
  //       activeTab == "3: Credit Information Agreement" &&
  //       ((fields.length === 1 &&creditsignss) || (fields.length >= 2 && creditsignss2) || (fields.length === 3 && creditsignss3))
  //     ) {
  //       toast.error("Please sign the Document before proceeding", {
  //         position: "top-right",
  //       });
  //     } else if (
  //       activeTab == "4: No Cosmetic Repair Agreement" &&
  //       (cosmeticAgreementsn || (fields.length >= 2 && cosmeticAgreementsn2)|| (fields.length === 3 && cosmeticAgreementsn3) || limmietsign || (fields.length >= 2 && limmietsign2) || (fields.length === 3 && limmietsign3))
  //     ) {
  //       toast.error("Please sign the Document before proceeding", {
  //         position: "top-right",
  //       });
  //     } else {
  //       const currentIndex = tabs?.indexOf(activeTab);
  //       if (currentIndex < tabs?.length - 1) {
  //         setActiveTab(tabs[currentIndex + 1]);
  //       }
  //     }
  // };

  const handleInputChangeAnnual1 = (event) => {
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "annual_income_one",
        value: value,
      },
    });
  };

  const handleInputChangeAnnual2 = (event) => {
    // const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseInt(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "annual_income_two",
    //     value: formattedValue,
    //   },
    // });

    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "annual_income_two",
        value: value,
      },
    });
  };

  const handleInputChangeAnnual3 = (event) => {
    // const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseInt(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "annual_income_three",
    //     value: formattedValue,
    //   },
    // });
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "annual_income_three",
        value: value,
      },
    });
  };

  const handleInputChangePreviousAnnual1 = (event) => {
    // const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseInt(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "previous_annual_income_one",
    //     value: formattedValue,
    //   },
    // });
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "previous_annual_income_one",
        value: value,
      },
    });
  };

  const handleInputChangePreviousAnnual2 = (event) => {
    // const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseInt(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "previous_annual_income_two",
    //     value: formattedValue,
    //   },
    // });

    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "previous_annual_income_two",
        value: value,
      },
    });
  };
  const handleInputChangePreviousAnnual3 = (event) => {
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "previous_annual_income_three",
        value: value,
      },
    });
  };

  const handleInputChangePreviousMonthly1 = (event) => {
    // const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseInt(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "previous_monthly_rent_one",
    //     value: formattedValue,
    //   },
    // });
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "previous_monthly_rent_one",
        value: value,
      },
    });
  };

  const handleInputChangePreviousMonthly2 = (event) => {
    // const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseInt(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "previous_monthly_rent_two",
    //     value: formattedValue,
    //   },
    // });
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "previous_monthly_rent_two",
        value: value,
      },
    });
  };

  const handleInputChangePreviousMonthly3 = (event) => {
    // const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseInt(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "previous_monthly_rent_three",
    //     value: formattedValue,
    //   },
    // });
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "previous_monthly_rent_three",
        value: value,
      },
    });
  };

  const handleInputChangePresentMonthly1 = (event) => {
    // const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseInt(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "present_monthly_rent_one",
    //     value: formattedValue,
    //   },
    // });

    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "present_monthly_rent_one",
        value: value,
      },
    });
  };

  const handleInputChangePresentMonthly2 = (event) => {
    // const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseInt(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "present_monthly_rent_two",
    //     value: formattedValue,
    //   },
    // });
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "present_monthly_rent_two",
        value: value,
      },
    });
  };

  const handleInputChangePresentMonthly3 = (event) => {
    // const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    // const formattedValue =
    //   value !== "" ? `$${parseInt(value).toLocaleString()}` : ""; // Format with dollar sign and commas
    // formik.handleChange({
    //   target: {
    //     name: "present_monthly_rent_three",
    //     value: formattedValue,
    //   },
    // });
    let value = event.target.value;
    let numericValue = value?.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-decimal characters
    let parts = numericValue?.split("."); // Split into integer and decimal parts

    if (parts?.length > 1) {
      // If there is a decimal point
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Ensure only two decimal places are allowed
      if (decimalPart.length > 2) {
        decimalPart = decimalPart?.slice(0, 2);
      }

      // Reconstruct the value with formatting
      value = `$${integerPart?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}.${decimalPart}`;
    } else {
      // No decimal point, format as an integer
      value = `$${numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    formik.handleChange({
      target: {
        name: "present_monthly_rent_three",
        value: value,
      },
    });
  };

  // const handleTabPress = (event) => {
  //   if (event.key === 'Tab') {
  //     event.preventDefault(); // Prevent the default tab behavior

  //     // Get all input fields on the page
  //     const inputFields = Array?.from(document.querySelectorAll('input'));

  //     // Find the index of the current input field
  //     const currentIndex = inputFields?.findIndex(input => input === event.target);

  //     // Calculate the index of the input field below the current one
  //     const nextIndex = currentIndex + 1;

  //     // Set the focus on the input field below, if it exists
  //     if (nextIndex < inputFields?.length) {
  //       inputFields[nextIndex].focus();
  //     }
  //   }
  // };
  const handleTabPress = (event, inputRef) => {
    if (event.key === "Tab") {
      event.preventDefault(); // Prevent the default tab behavior
      if (inputRef.current) {
        inputRef.current.focus(); // Set focus on the next input field
      }
    }
  };

  const handleCrossClick = () => {
    // Reset the image and any other relevant state here
    setTenantView("");
    DraftHandling();
    // You might also want to reset the signature canvas
  };

  const handleCrossClickLimmiter = () => {
    // Reset the image and any other relevant state here
    setLimmterTenantView("");
    DraftHandling();

    // You might also want to reset the signature canvas
  };

  const handleCrossClickSmoke = () => {
    // Reset the image and any other relevant state here
    setCosmeticTenantView("");
    DraftHandling();

    // You might also want to reset the signature canvas
  };

  const handleCrossClickOne = () => {
    // Reset the image and any other relevant state here
    setCreditTenantOneView("");
    DraftHandling();

    // You might also want to reset the signature canvas
  };

  const handleRemoveImagess = async (index) => {
    console.log("Index ==> image", index);
    const imageData = selectedImage[index];
    try {
      const Application_token = localStorage.getItem("Application_token");

      let res = await userApiCallApplication(
        `/api/user/delete_image`,
        "POST",
        JSON.stringify({ fileName: imageData, isImage: "government" }),
        Application_token
      );

      setSelectedImage((prevState) =>
        prevState?.filter((item, i) => i !== index)
      );
      // setSelectedImage(res?.data);

      console.log("Response", res);
      toast.success(res?.message);
    } catch (error) {
      console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
    //  await fetch(`${baseDomain}/api/user/delete_image`, {
    //     method: 'POST',
    //     headers: {
    //       Authorization: Application_token,
    //     },
    //     body: JSON.stringify({ fileName:imageData }),
    //   })
    //   .then(response => {
    //     if (!response.ok) {
    //       throw new Error('Failed to delete image');
    //     }
    //     console.log("Response delete ==>",response)
    //     console.log('Image deleted successfully');
    //     // You might want to update your state to reflect the deletion, for example:
    //     // setSelectedImage(selectedImage.filter((item, idx) => idx !== index));
    //   })
    //   .catch(error => {
    //     console.error('Error deleting image:', error);
    //     // Handle errors here
    //   });
  };

  const handleRemoveFilesss = async (index) => {
    const imageData = selectedFile[index];
    try {
      const Application_token = localStorage.getItem("Application_token");

      let res = await userApiCallApplication(
        `/api/user/delete_image`,
        "POST",
        JSON.stringify({ fileName: imageData, isImage: "employment" }),
        Application_token
      );

      setSelectedFile((prevState) =>
        prevState?.filter((item, i) => i !== index)
      );
      // setSelectedFile(res?.data)

      console.log("Response", res);
      toast.success(res?.message);
    } catch (error) {
      console.log("err", error);
      if (error.status == 400) {
        toast.error("Something Went Wrong"); // Display the error toaster notification
      } else {
        toast.error("Server Side Error"); // Display the error toaster notification
      }
    }
  };

  console.log("initial ==>", formik.values.initial_one);

  useEffect(() => {
    formik?.setFieldValue("name_applicant_one", fields?.[0]?.firstName);
    formik?.setFieldValue("name_applicant_two", fields?.[1]?.firstName);
    formik?.setFieldValue("name_applicant_three", fields?.[2]?.firstName);
  }, [fields]);

  const imageUrls =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";
  return (
    <>
      <>
        <section className="pageWrapper cardControl">
          <div
            className="contentCard"
            style={{
              minHeight: "850px",
            }}
            // ref={listingRef}
          >
            <h5 style={{ textAlign: "center", color: "#d97228" }}>
              Note: Currently Its Under Development Mode
            </h5>
            <div className="titleWrapper">
              <span>Application</span>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log({ ERR: formik.errors });

                if (Object.values(formik.errors).length) {
                  toast.error("Please Fill Required Fields");
                  formik.setTouched({ ...formik.touched, ...formik.errors });
                }
                // else if (selectedImage == null) {
                //   toast.error("Please Upload your Government Identification ");
                // } else if (selectedFile == null) {
                //   toast.error("Please Upload your Proof of Employment");
                // }
                // if (
                //   signPadTenent?.isEmpty() ||
                //   signPadCreditTenent1?.isEmpty() ||
                //   signPadTenentSmoke?.isEmpty() ||
                //   signPadTenentLimmiter?.isEmpty()
                // ) {
                //   toast.error("Please sign required documents first");
                // }
                else {
                  formik.handleSubmit(e);
                }
              }}
            >
              {/* top 3 fields of tenent contact information */}
              <div className="custom-tabs-handling">
                <div
                  className="tab-container"
                  style={{ justifyContent: "center" }}
                >
                  {
                    // [
                    //   "1: Application",
                    //   "2: Applicant Particular",
                    //   "3: Credit Information Agreement",
                    //   "4: No Cosmetic Repair Agreement",
                    //   "5: Upload Files",
                    // ]

                    tabs?.map((t, index) => (
                      <button
                        type="button"
                        key={t}
                        onClick={() => setActiveTab(t)}
                        // class={`tab-button ${activeTab === t ? "active" : "disable-color"}`}
                        className={`tab-button ${
                          activeTab === t ? "active" : ""
                        }`}
                        // disabled={visitedTabs.includes(t) && index > tabs?.indexOf(activeTab)}
                        disabled={index >= tabs?.indexOf(activeTab)}
                      >
                        {t}
                      </button>
                    ))
                  }
                </div>

                <div
                  className="tab-content"
                  tab="1: Application"
                  key="1: Application"
                  style={{
                    display: activeTab === "1: Application" ? "block" : "none",
                  }}
                >
                  <section className="Application-tab" ref={listingRef1}>
                    {showLogo ? (
                      <div
                        className="logoWrapper mb-5"
                        style={{ backgroundColor: "white" }}
                      >
                        <img
                          className="logo"
                          src={Logo}
                          alt=""
                          style={{ width: "15rem", height: "auto" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row outside-top">
                      <div className="col-xl-2 col-lg-4 print-only">
                        <label style={{ height: "1.5rem" }}>
                          Tenant Contact Name
                        </label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.tenentContactName &&
                            formik.touched.tenentContactName
                              ? " is-invalid"
                              : "")
                          }
                          name="tenentContactName"
                          placeholder="Tenant Contact Name"
                          value={formik?.values?.tenentContactName}
                          onChange={formik?.handleChange}
                          type="text"
                          disabled
                        />
                      </div>

                      <div className="col-xl-2 col-lg-4 print-only">
                        <label style={{ height: "1.5rem" }}>
                          Tenant Email Address
                        </label>
                        <input
                          className={
                            "form-control " +
                            (formik?.errors?.tenentEmailAddress &&
                            formik?.touched?.tenentEmailAddress
                              ? " is-invalid"
                              : "")
                          }
                          name="tenentEmailAddress"
                          placeholder="Tenant Email Address"
                          value={formik?.values?.tenentEmailAddress}
                          onChange={formik?.handleChange}
                          type="text"
                          disabled
                          autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        />
                      </div>
                      <div className="col-xl-2 col-lg-4 print-only">
                        <label style={{ height: "1.5rem" }}>
                          Tenant Phone Number
                        </label>
                        {/* <input
                          className={
                            "form-control " +
                            (formik?.errors.tenentPhoneNumber &&
                            formik?.touched.tenentPhoneNumber
                              ? " is-invalid"
                              : "")
                          }
                          name="tenentPhoneNumber"
                          placeholder="Tenant Phone Number"
                          value={formik?.values?.tenentPhoneNumber}
                          onChange={formik?.handleChange}
                          type="text"
                          disabled
                          onKeyPress={handleKeyPress}
                        /> */}
                        <ReactInputMask
                          mask="(999) 999-9999"
                          // value={formik?.values?.tenentPhoneNu mber}
                          value={formik.values.tenentPhoneNumber} // Use '001' if the value is empty
                          name={"tenentPhoneNumber"}
                          className={
                            "form-control " +
                            (formik?.errors.tenentPhoneNumber &&
                            formik?.touched.tenentPhoneNumber
                              ? " is-invalid"
                              : "")
                          }
                          onChange={formik?.handleChange}
                          disabled
                        />
                      </div>
                      <div className="col-xl-2 col-lg-6 print-only">
                        <label style={{ height: "1.5rem" }}>Suite No</label>
                        <input
                          className={
                            "form-control " +
                            (formik?.errors.suite && formik?.touched.suite
                              ? " is-invalid"
                              : "")
                          }
                          name="suite"
                          placeholder="Suite No"
                          value={formik?.values?.suite}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                      </div>
                      <div className="col-xl-2 col-lg-4 print-five-fields">
                        <div className="form-group">
                          <label>Unit Type</label>
                          <select
                            className="form-control"
                            onChange={(e) => handleType(e)}
                            value={typeSelected}
                            disabled
                          >
                            {type &&
                              type.map((v, i) => {
                                return (
                                  <option key={i} value={v.value}>
                                    {v.label}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-6 print-only">
                        <label style={{ height: "1.5rem" }}>Building</label>
                        <input
                          className={
                            "form-control " +
                            (formik?.errors.building && formik?.touched.building
                              ? " is-invalid"
                              : "")
                          }
                          name="building"
                          placeholder="building"
                          value={formik?.values?.building}
                          onChange={formik?.handleChange}
                          type="text"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row outside-top">
                      {/* calnder */}
                      <div className="col-lg-6 mt-3 print-only-half">
                        <div className="form-group">
                          <label>Today Date</label>
                          <Space direction="vertical">
                            <DatePicker
                              className="form-control"
                              name="requested-date"
                              value={requestDate}
                              placeholder="Today Date"
                              onChange={handleRequestDate}
                              disabled
                            />
                          </Space>
                        </div>
                      </div>
                    </div>
                    {/* dyncamic of Tenent */}
                    <p style={{ fontWeight: "600" }}>
                      It is clearly understood and agreed that no other persons
                      shall occupy the premises other than those identified
                      below
                    </p>
                    <div className="cardTitleHome card-title h5 mt-3">
                      {/* First, Middle & Last Names of "Bona Fide Tenant(s)" */}
                      First, Middle & Last Names of Responsible Lease Holder(s)
                    </div>

                    <div>
                      {fields?.map((field, index) => (
                        <div className="row mt-3" key={index}>
                          <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-five ">
                            <label className="my-custom-height">
                              First name of Tenant
                            </label>
                            <input
                              type="text"
                              // className="form-control"
                              className={`form-control ${
                                fieldValidation[index]?.firstName === false
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="First name of Tenant"
                              aria-label="First name"
                              name="firstName"
                              value={field?.firstName}
                              onChange={(event) =>
                                handleChangeTenent(index, event)
                              }
                              autoComplete="new-password" // Disable autofill and autocomplete suggestions
                              onInput={(event) => {
                                // Prevent entering numbers
                                event.target.value = event.target.value.replace(
                                  /[0-9]/g,
                                  ""
                                );
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-five">
                            <label className="my-custom-height">
                              Middle name of Tenant
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              // className={`form-control ${
                              //   fieldValidation[index]?.middleName === false
                              //     ? "is-invalid"
                              //     : ""
                              // }`}
                              placeholder="Middle name of Tenant"
                              aria-label="Middle name"
                              name="middleName"
                              value={field?.middleName}
                              onChange={(event) =>
                                handleChangeTenent(index, event)
                              }
                              autoComplete="new-password" // Disable autofill and autocomplete suggestions
                              onInput={(event) => {
                                // Prevent entering numbers
                                event.target.value = event.target.value.replace(
                                  /[0-9]/g,
                                  ""
                                );
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-five">
                            <label className="my-custom-height">
                              Last name of Tenant
                            </label>
                            <input
                              type="text"
                              // className="form-control"
                              className={`form-control ${
                                fieldValidation[index]?.lastName === false
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Last name of Tenant"
                              aria-label="Last name"
                              name="lastName"
                              value={field?.lastName}
                              onChange={(event) =>
                                handleChangeTenent(index, event)
                              }
                              autoComplete="new-password" // Disable autofill and autocomplete suggestions
                              onInput={(event) => {
                                // Prevent entering numbers
                                event.target.value = event.target.value.replace(
                                  /[0-9]/g,
                                  ""
                                );
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-five">
                            <label className="my-custom-height">
                              Social Insurance Number of Tenant
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              // className={`form-control ${
                              //   fieldValidation[index]?.sin === false
                              //     ? "is-invalid"
                              //     : ""
                              // }`}
                              placeholder="Social Insurance Number of Tenant"
                              name="sin"
                              value={field?.sin}
                              onChange={(event) =>
                                handleChangeTenent(index, event)
                              }
                              onKeyPress={handleKeyPress}
                              autoComplete="new-password" // Disable autofill and autocomplete suggestions
                            />
                          </div>
                          <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-five">
                            <label className="my-custom-height">
                              Email Address of Tenant
                            </label>
                            <input
                              type="email"
                              // className="form-control"
                              className={`form-control ${
                                fieldValidation[index]?.email === false
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="inputEmail4"
                              placeholder="Email Address of Tenant"
                              name="email"
                              value={field?.email}
                              onChange={(event) =>
                                handleChangeTenent(index, event)
                              }
                              autoComplete="new-password" // Disable autofill and autocomplete suggestions
                            />
                            {field.email && !isValidEmail(field.email) && (
                              <div
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                Invalid email address
                              </div>
                            )}
                          </div>
                          {index > 0 && (
                            <div
                              className="col-lg-1 mt-2 mt-lg-0 "
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => handleRemoveField(index)}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>
                      ))}

                      <button
                        type="button"
                        className="btn btn-outline-danger add-btn mt-2"
                        onClick={handleAddField}
                        style={{ borderColor: "gray", color: "gray" }}
                        disabled={fields.length === 3}
                      >
                        + Add
                      </button>
                    </div>
                    {/* Dyncamic of occupant */}
                    <div className="cardTitleHome card-title h5 mt-3">
                      {/* First, Middle & Last Names of other Occupants */}
                      First, Middle & Last Names of Occupant(s)
                    </div>
                    <div>
                      {occupantFields?.map((field, index) => (
                        <div className="row mt-3" key={index}>
                          <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-five">
                            <label className="my-custom-height">
                              First name of Occupant
                            </label>
                            <input
                              type="text"
                              // className="form-control"
                              className={`form-control ${
                                occupantFieldValidation[index]
                                  ?.firstNameOccupant === false
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="First name of Occupant"
                              aria-label="First name"
                              name="firstNameOccupant"
                              value={field?.firstNameOccupant}
                              onChange={(event) =>
                                handleChangeOccupant(index, event)
                              }
                              autoComplete="new-password" // Disable autofill and autocomplete suggestions
                              onInput={(event) => {
                                event.target.value = event.target.value.replace(
                                  /[0-9]/g,
                                  ""
                                );
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-five">
                            <label className="my-custom-height">
                              Middle name of Occupant
                            </label>
                            <input
                              type="text"
                              // className="form-control"
                              className={`form-control ${
                                occupantFieldValidation[index]
                                  ?.middleNameOccupant === false
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Middle name of Occupant"
                              aria-label="Middle name"
                              name="middleNameOccupant"
                              value={field?.middleNameOccupant}
                              onChange={(event) =>
                                handleChangeOccupant(index, event)
                              }
                              autoComplete="new-password" // Disable autofill and autocomplete suggestions
                              onInput={(event) => {
                                event.target.value = event.target.value.replace(
                                  /[0-9]/g,
                                  ""
                                );
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-five">
                            <label className="my-custom-height">
                              Last name of Occupant
                            </label>
                            <input
                              type="text"
                              // className="form-control"
                              className={`form-control ${
                                occupantFieldValidation[index]
                                  ?.lastNameOccupant === false
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Last name of Occupant"
                              aria-label="Last name"
                              name="lastNameOccupant"
                              value={field?.lastNameOccupant}
                              onChange={(event) =>
                                handleChangeOccupant(index, event)
                              }
                              autoComplete="new-password" // Disable autofill and autocomplete suggestions
                              onInput={(event) => {
                                event.target.value = event.target.value.replace(
                                  /[0-9]/g,
                                  ""
                                );
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-five">
                            <label className="my-custom-height">
                              Age of Occupant
                            </label>
                            <input
                              type="text"
                              // className="form-control"
                              className={`form-control ${
                                occupantFieldValidation[index]?.sinOccupant ===
                                false
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Age of Occupant"
                              aria-label="Age"
                              name="sinOccupant"
                              value={field?.sinOccupant}
                              onChange={(event) =>
                                handleChangeOccupant(index, event)
                              }
                              onKeyPress={handleKeyPress}
                              autoComplete="new-password" // Disable autofill and autocomplete suggestions
                            />
                          </div>
                          <div className="col-xl-2 col-lg-4 mt-3 mt-lg-0 print-only-five">
                            <label className="my-custom-height">
                              Email Address of Occupant
                            </label>
                            <input
                              type="email"
                              // className="form-control"
                              className={`form-control ${
                                occupantFieldValidation[index]
                                  ?.emailOccupant === false
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="inputEmail4"
                              placeholder="Email Address of Occupant"
                              name="emailOccupant"
                              value={field?.emailOccupant}
                              onChange={(event) =>
                                handleChangeOccupant(index, event)
                              }
                              autoComplete="new-password" // Disable autofill and autocomplete suggestions
                            />
                            {field.emailOccupant &&
                              !isValidEmail(field.emailOccupant) && (
                                <div
                                  className="error-message"
                                  style={{ color: "red" }}
                                >
                                  Invalid email address
                                </div>
                              )}
                          </div>
                          {index > 0 && (
                            <div className="col-lg-1 mt-2 mt-lg-0 ">
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => handleRemoveFieldOcuupant(index)}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>
                      ))}

                      <button
                        type="button"
                        className="btn btn-outline-danger add-btn mt-2"
                        onClick={handleAddFieldOccupant}
                        style={{ borderColor: "gray", color: "gray" }}
                        disabled={occupantFields.length === 3}
                      >
                        + Add
                      </button>
                    </div>
                    {/* calnder */}
                    <p style={{ marginTop: "25px", fontWeight: "600" }}>
                      Note: Included utilities are heat and water. Hydro is
                      sub-metered and at the sole expense of the Tenant
                    </p>
                    <div className="row" style={{ marginTop: "1.5rem" }}>
                      <div className="col-lg-6 print-only-half">
                        <div className="form-group">
                          <label>
                            Tenancy Start Date From (must be 1st of the month)
                          </label>

                          <Space direction="vertical">
                            <DatePicker
                              className="form-control"
                              value={tenentStartDate}
                              format={"MMMM DD YYYY"}
                              // format={"yyyy dd mm"}
                              name="Tenancy-start"
                              onChange={handleTenencyStartDate}
                              disabled
                            />
                          </Space>
                        </div>
                      </div>
                      <div className="col-lg-6 print-only-half">
                        <div className="form-group">
                          <label>
                            Tenancy End Date (must be last day of the month)
                          </label>

                          <Space direction="vertical">
                            <DatePicker
                              className="form-control"
                              name="Tenancy-end"
                              format={"MMMM DD YYYY"}
                              value={tenentEndDate}
                              onChange={handleTenencyEndDate}
                              disabled
                            />
                          </Space>
                        </div>
                      </div>
                    </div>
                    {/* Billing details */}
                    {/* <div className="cardTitleHome card-title h5 mt-2">
                        Billing details
                      </div> */}
                    {/* extra */}
                    <div className="input-group mb-3 d-lg-flex d-none print-only-block">
                      <span
                        className="input-group-text col-lg-6 print-only"
                        id="basic-addon3"
                      >
                        Rental Amount
                      </span>
                      <span
                        className="input-group-text col-lg-3 print-only"
                        id="basic-addon3"
                      >
                        Lawfull Rent
                      </span>
                      {/* <span className="input-group-text col-lg-3" id="basic-addon3">
                  Rent Payable
                </span> */}
                      <span
                        className="input-group-text col-lg-3 print-only"
                        id="basic-addon3"
                      >
                        Last Month Rent Deposit “D”
                      </span>
                    </div>
                    {/* extra */}
                    {/* groups */}
                    <div className="groups-field">
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text col-lg-6 print-only"
                          id="basic-addon3"
                        >
                          Rent
                        </span>
                        <input
                          className={
                            "form-control  col-lg-3 print-only " +
                            (formik?.errors.lawRent && formik?.touched.lawRent
                              ? " is-invalid"
                              : "")
                          }
                          name="lawRent"
                          placeholder={`"A"`}
                          value={formik?.values?.lawRent}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          readOnly
                        />
                        {/* <input
                    className={
                      "form-control col-lg-3" +
                      (formik?.errors.payAbleRent && formik?.touched.payAbleRent
                        ? " is-invalid"
                        : "")
                    }
                    name="payAbleRent"
                    placeholder={`"E"`}
                    value={formik?.values?.payAbleRent}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  /> */}
                      </div>
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text col-lg-6 print-only"
                          id="basic-addon3"
                          style={{ height: "39px" }}
                        >
                          Parking Above Ground
                        </span>
                        <input
                          className={
                            "form-control col-lg-3 print-only" +
                            (formik?.errors.parkingLawRent &&
                            formik?.touched.parkingLawRent
                              ? " is-invalid"
                              : "")
                          }
                          name="parkingLawRent"
                          placeholder={`"B"`}
                          value={formik?.values?.parkingLawRent}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                        {/* <input
                    className={
                      "form-control col-lg-3 " +
                      (formik?.errors.parkingPayAbleRent &&
                      formik?.touched.parkingPayAbleRent
                        ? " is-invalid"
                        : "")
                    }
                    name="parkingPayAbleRent"
                    placeholder={`"F"`}
                    value={formik?.values?.parkingPayAbleRent}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  /> */}
                        <p className="col-lg-3 mb-0">
                          {" "}
                          To be applied to the last month of the tenancy. Must
                          be equal to the amount stated in Box “D”.{" "}
                        </p>
                      </div>
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text  col-lg-6 print-only"
                          id="basic-addon3"
                        >
                          Parking Below Ground
                        </span>
                        <input
                          className={
                            "form-control col-lg-3 print-only" +
                            (formik?.errors.belowRent &&
                            formik?.touched.belowRent
                              ? " is-invalid"
                              : "")
                          }
                          name="belowRent"
                          placeholder={`"C"`}
                          value={formik?.values?.belowRent}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                        {/* <input
                    className={
                      "form-control col-lg-3" +
                      (formik?.errors.belowPayAble &&
                      formik?.touched.belowPayAble
                        ? " is-invalid"
                        : "")
                    }
                    name="belowPayAble"
                    placeholder={`"G"`}
                    value={formik?.values?.belowPayAble}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  /> */}
                      </div>{" "}
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text  col-lg-6 print-only"
                          id="basic-addon3"
                        >
                          Total Legal Rent
                        </span>
                        <input
                          className={
                            "form-control col-lg-3 print-only" +
                            (formik?.errors.legalRent &&
                            formik?.touched.legalRent
                              ? " is-invalid"
                              : "")
                          }
                          name="legalRent"
                          placeholder={`"D"`}
                          value={formik?.values?.legalRent}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                        {/* <input
                    className={
                      "form-control col-lg-3" +
                      (formik?.errors.legalPayAble &&
                      formik?.touched.legalPayAble
                        ? " is-invalid"
                        : "")
                    }
                    name="legalPayAble"
                    placeholder={`"H"`}
                    value={formik?.values?.legalPayAble}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  /> */}
                        <input
                          className={
                            "form-control print-only" +
                            (formik?.errors.legalLast &&
                            formik?.touched.legalLast
                              ? " is-invalid"
                              : "")
                          }
                          name="legalLast"
                          placeholder="$"
                          // value={formik?.values?.legalLast}
                          value={formik?.values?.legalRent}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                      </div>
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text  col-lg-6 print-only"
                          id="basic-addon3"
                        >
                          Prompt Payment Discount (2% of Monthly Rent)
                        </span>

                        <input
                          className={
                            "form-control col-lg-3 print-only" +
                            (formik?.errors.discountPayable &&
                            formik?.touched.discountPayable
                              ? " is-invalid"
                              : "")
                          }
                          name="discountPayable"
                          placeholder={`"E"`}
                          value={formik?.values?.discountPayable}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                      </div>{" "}
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text  col-lg-6 print-only"
                          id="basic-addon3"
                        >
                          Net Rent payable on or Before the 1st day of each
                          Month
                        </span>
                        <input
                          className={
                            "form-control col-lg-3 print-only" +
                            (formik?.errors.netPayable &&
                            formik?.touched.netPayable
                              ? " is-invalid"
                              : "")
                          }
                          name="netPayable"
                          placeholder={`"F"`}
                          value={formik?.values?.netPayable}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                      </div>
                    </div>
                    {/* <div className="cardTitleHome card-title h5 mt-2">
                        Pro Rates
                      </div> */}
                    <div className="row">
                      <div className="input-group mb-3 col-12">
                        <span
                          className="input-group-text col-lg-1 font-control  print-only-four"
                          style={{ fontSize: "10px !important" }}
                        >
                          Pro Rate Start Date
                        </span>
                        <DatePicker
                          className="form-control col-lg-2  print-only-four"
                          value={proStartDate}
                          name="pro-start"
                          onChange={ProRatesStart}
                          disabled
                        />
                        <span
                          className="input-group-text col-lg-1 font-control  print-only-four"
                          id="basic-addon3"
                        >
                          Pro Rate End Date
                        </span>
                        <DatePicker
                          className="form-control col-lg-2  print-only-four"
                          value={proEndDate}
                          name="pro-end"
                          onChange={ProRatesEnd}
                          disabled
                        />
                        {/* </div> */}
                        {/* <div className="input-group mb-3 col-12"> */}
                        <span
                          className="input-group-text col-lg-1 font-control  print-only-four"
                          font-control
                        >
                          No. of Days
                        </span>
                        <input
                          className={
                            "form-control col-lg-2  print-only-four" +
                            (formik?.errors.noDays && formik?.touched.noDays
                              ? " is-invalid"
                              : "")
                          }
                          name="noDays"
                          placeholder="Days"
                          value={formik?.values?.noDays}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                        <span className="input-group-text  col-lg-2 font-control  print-only-four">
                          Per Diem Rate
                        </span>
                        <input
                          className={
                            "form-control col-lg-1  print-only-four" +
                            (formik?.errors.perDiem && formik?.touched.perDiem
                              ? " is-invalid"
                              : "")
                          }
                          name="perDiem"
                          placeholder="$"
                          value={formik?.values?.perDiem}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                      </div>
                    </div>
                    {/* No of Days */}
                    {/* <div className="row">
                <div className="input-group mb-3 col-12">
                  <span className="input-group-text col-lg-3">No. of Days</span>
                  <input
                    className={
                      "form-control " +
                      (formik?.errors.noDays && formik?.touched.noDays
                        ? " is-invalid"
                        : "")
                    }
                    name="noDays"
                    placeholder="Days"
                    value={formik?.values?.noDays}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  />
                  <span className="input-group-text  col-lg-3">
                    Per Diem Rate
                  </span>
                  <input
                    className={
                      "form-control " +
                      (formik?.errors.perDiem && formik?.touched.perDiem
                        ? " is-invalid"
                        : "")
                    }
                    name="perDiem"
                    placeholder="$"
                    value={formik?.values?.perDiem}
                    onChange={formik?.handleChange}
                    type="text"
                    onKeyPress={handleKeyPress}
                  />
                </div>
              </div> */}
                    {/* pro rate Amount */}
                    <div className="row">
                      <div className="input-group mb-3 col-12">
                        <span
                          className="input-group-text col-lg-1 font-control  print-only-four"
                          id="basic-addon3"
                        >
                          Pro-Rate Amount
                        </span>
                        <input
                          className={
                            "form-control col-lg-2  print-only-four" +
                            (formik?.errors.proAmount &&
                            formik?.touched.proAmount
                              ? " is-invalid"
                              : "")
                          }
                          name="proAmount"
                          placeholder="$"
                          value={formik?.values?.proAmount}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                        <span
                          className="input-group-text col-lg-1 font-control  print-only-four"
                          id="basic-addon3"
                        >
                          Rent
                        </span>
                        <input
                          className={
                            "form-control col-lg-2 print-only-four " +
                            (formik?.errors.proAmountRent &&
                            formik?.touched.proAmountRent
                              ? " is-invalid"
                              : "")
                          }
                          name="proAmountRent"
                          placeholder="$"
                          // value={formik?.values?.proAmountRent}
                          value={formik?.values?.netPayable}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                        <span
                          className="input-group-text col-lg-1 font-control  print-only-four"
                          id="basic-addon3"
                        >
                          LMR
                        </span>
                        <input
                          className={
                            "form-control col-lg-2  print-only-four" +
                            (formik?.errors.lmr && formik?.touched.lmr
                              ? " is-invalid"
                              : "")
                          }
                          name="lmr"
                          placeholder="$"
                          value={formik?.values?.lmr}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                        <span
                          className="input-group-text col-lg-2 font-control print-only-four"
                          id="basic-addon3"
                        >
                          Total Amount Prior to Move In
                        </span>
                        <input
                          className={
                            "form-control col-lg-1 print-only-four" +
                            (formik?.errors.Prior && formik?.touched.Prior
                              ? " is-invalid"
                              : "")
                          }
                          name="Prior"
                          placeholder="$"
                          value={formik?.values?.Prior}
                          onChange={formik?.handleChange}
                          type="text"
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                      </div>
                    </div>

                    {/* signature */}
                    {/* <label>Tenant Signature</label> */}
                    <div className="row">
                      <div
                        id="canvas_Wraper"
                        // className="row canvasSignature"
                        className="col-lg-4 print-only"
                        onLoad={() => getDimension()}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="building-manager-resolution mb-3"
                          style={{ position: "relative", textAlign: "end" }}
                        >
                          {" "}
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenent(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature",
                              // className: ` ${signatureSaved == true ? 'canvasSignature-tenants' : 'canvasSignature'}`,

                              width: 400,
                              height: 150,
                            }}
                          />
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <div
                            className="button-style-app"
                            onClick={clearSignature}
                          >
                            Clear Signature
                          </div>
                        </div>
                        {/* {tenantView !== imageUrls && (
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            // height: "100%",
                            height: "auto",

                            bottom: "4rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={tenantView}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              width: "auto",
                              height: "auto",
                            }}
                          />
            
                        </div>
                      )} */}
                      </div>

                      <div
                        id="canvas_Wraper"
                        // className="row canvasSignature"
                        className={`col-lg-4 print-only ${
                          fields && fields.length >= 2 ? "visible" : "invisible"
                        }`}
                        onLoad={() => getDimension()}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="building-manager-resolution mb-3"
                          style={{ position: "relative", textAlign: "end" }}
                        >
                          {" "}
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenent2(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature",
                              // className: ` ${signatureSaved == true ? 'canvasSignature-tenants' : 'canvasSignature'}`,

                              width: 400,
                              height: 150,
                            }}
                          />
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <div
                            className="button-style-app"
                            onClick={clearSignature2}
                          >
                            Clear Signature
                          </div>
                        </div>
                        {/* {tenantView !== imageUrls && (
  <div
    style={{
      position: "absolute",
      width: "100%",
      // height: "100%",
      height: "auto",

      bottom: "4rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img
      src={tenantView}
      style={{
        maxWidth: "100%",
        maxHeight: "100%",
        width: "auto",
        height: "auto",
      }}
    />

  </div>
)} */}
                      </div>

                      <div
                        id="canvas_Wraper"
                        // className="row canvasSignature"
                        // className="col-lg-4 print-only"
                        className={`col-lg-4 print-only ${
                          fields && fields.length === 3
                            ? "visible"
                            : "invisible"
                        }`}
                        onLoad={() => getDimension()}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="building-manager-resolution mb-3"
                          style={{ position: "relative", textAlign: "end" }}
                        >
                          {" "}
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenent3(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature",
                              // className: ` ${signatureSaved == true ? 'canvasSignature-tenants' : 'canvasSignature'}`,

                              width: 400,
                              height: 150,
                            }}
                          />
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <div
                            className="button-style-app"
                            onClick={clearSignature3}
                          >
                            Clear Signature
                          </div>
                        </div>
                      </div>

                      {/* manager signature */}
                      <div
                        id="canvas_Wraper1"
                        className="col-lg-4 print-only invisible"
                        onLoad={() => getDimension1()}
                        style={{ position: "absolute" }}
                      >
                        <div
                          className="building-manager-resolution mb-3"
                          style={{ position: "relative", textAlign: "end" }}
                        >
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerBuldingManager(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature-tenants",

                              width: 400,
                              height: 150,
                            }}
                          />

                          <p
                            className=" text-center mt-3 ml-auto signs"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            Building Manager Signature
                          </p>
                          <div
                            style={{
                              position: "absolute",
                              bottom: "2rem",
                              width: "100%",
                              height: "7rem",
                              display: "flex",
                              alignItems: "center" /* Center vertically */,
                              justifyContent:
                                "center" /* Center horizontally */,
                            }}
                          >
                            <img className="" src={statics} />
                          </div>

                          {/* <div className="clear-button" onClick={clearSignatureBM} style={{cursor:"pointer"}} >Clear Signature</div> */}

                          {/* {
              signatureExists  &&
              <div
              className="cross-button"
              onClick={clearSignatureBM}
              style={{
                position: "absolute",
                top: "33px",
                right: 0,
                background: "none",
                border: "none",
              }}
            >
              &#10005;
            </div>
            } */}
                        </div>
                      </div>
                      {/* prperty Manager Acceptance */}

                      <div
                        id="canvas_Wraper2"
                        className="col-lg-4  mb-3 print-only invisible "
                        onLoad={() => getDimension2()}
                        style={{ position: "absolute" }}
                      >
                        <div
                          className="property-manager-resolution"
                          // style={{ alignItems: "end" }}
                        >
                          <div>
                            <SignatureCanvas
                              ref={(ref) =>
                                signatureHandlerPropertyManager(ref)
                              }
                              penColor="grey"
                              canvasProps={{
                                className: "canvasSignature-tenants",
                                // width: `${divWidth2 ? divWidth2 : ""}`,
                                width: 400,

                                height: 150,
                              }}
                            />

                            <p
                              className="text-center mt-3"
                              style={{
                                borderTop: "1px solid black",
                                fontWeight: "500",
                              }}
                            >
                              Property Manager Signature
                            </p>

                            <div
                              style={{
                                position: "absolute",
                                bottom: "2rem",
                                width: "100%",
                                height: "7rem",
                                display: "flex",
                                alignItems: "center" /* Center vertically */,
                                justifyContent:
                                  "center" /* Center horizontally */,
                              }}
                            >
                              <img className="" src={statics} />
                            </div>

                            {/* <img
                              className="d-none print-only-block"
                              src={propertyManagerView}
                              style={{
                                position: "absolute",
                                bottom: "2.8rem",
                                width: "100%",
                                height: "7rem",
                              }}
                            /> */}

                            {/* <div className="clear-button" onClick={clearSignaturePM} style={{cursor:"pointer"}} >Clear Signature</div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div
                      className="col-lg-6 building-manager-resolution mb-3 d-flex"
                      style={{ alignItems: "end" }}
                    >
                      <DatePicker
                    className="form-control canvasSignature "
                    name="agreementDate"
                    value={agreementDate}
                    placeholder="Agreement Date"
                    onChange={handleAgreementtDate}
                  />
                    </div>
                    prperty Manager Acceptance
                    <div
                      className="row mb-3"
                    >
                      <div className="col-lg-6">
                        <div
                          className="d-flex h-100 accptence"
                          style={{ alignItems: "end" }}
                        >
                          <div>
                            <DatePicker
                              className="form-control "
                              name="agreementDate"
                              value={acceptanceDate}
                              placeholder="Acceptance Date"
                              onChange={handleAcceptancetDate}
                              disabled
                            />
                            <p className="canvasSignature text-center">
                              Acceptance Date
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* terms and condition */}
                    <section>
                      <p className="text-center">
                        <p style={{ fontWeight: "800", fontSize: "14px" }}>
                          Terms & Conditions
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          The undersigned acknowledges and agrees that the
                          Landlord may accept or refuse this rental application
                          in the Landlord's sole discretion, after all of the
                          usual forms have been completed in full and approved.
                          Then and only then is a binding tenancy agreement
                          created between the Landlord and the tenant at which
                          time the undersigned shall forthwith enter into a
                          tenancy agreement in the Landlord's usual form of
                          tenancy agreement prior to the undersigned taking
                          possession of the premises in accordance with the
                          terms and conditions outlined above and, in the
                          Landlord’s, usual form of tenancy agreement. The
                          tenant acknowledges that the Landlord provided an
                          opportunity to the tenant to receive or review a blank
                          copy of the Landlord's usual form of tenancy agreement
                          prior to the tenant completing and signing this rental
                          application. In the event that the tenant refuses to
                          sign the tenancy agreement after this rental
                          application has been accepted by the Landlord, then
                          the tenant hereby accepts all the terms and conditions
                          set out in the tenancy agreement and it is hereby
                          deemed that the tenant has signed the Landlord's usual
                          form of tenancy agreement and is bound by such tenancy
                          agreement as if the tenant had signed it in any event.
                          The tenant hereby further acknowledges to having read
                          and understood and agreed to all the terms and
                          conditions set out in this rental application as well
                          as the terms and conditions set out in the Landlord's
                          usual form of tenancy agreement. The undersigned
                          consents and authorizes the Landlord or its authorized
                          agents to obtain such information about the
                          undersigned as the Landlord may deem necessary at any
                          time in conjunction with the premises for which rental
                          application is hereby made, or any renewal or
                          extension thereof. The undersigned also consents to
                          the disclosure of any information concerning the
                          undersigned to any credit reporting agency or to any
                          person with whom the undersigned has or proposes to
                          have financial relations. I/We hereby declare that the
                          information provided in this application and in the
                          tenancy, agreement is true and correct and any false
                          misrepresentation may lead to charges for fraud or any
                          other remedies available.{" "}
                        </p>
                        <p style={{ fontWeight: "800", fontSize: "14px" }}>
                          Terms and Conditions of the 2% Prompt Payment Discount
                        </p>
                        <p style={{ textAlign: "initial", marginTop: "2rem" }}>
                          The Tenant hereby acknowledges and understands that
                          the 2% Prompt Payment discount will only be credited
                          to the Tenant based on fulfilling all of the below
                          requirements:
                        </p>

                        <ul style={{ marginBottom: "3rem", marginTop: "1rem" }}>
                          <li style={{ textAlign: "initial" }}>
                            The Tenant must have an account balance of $0.00 on
                            or before the first day of each month and;
                          </li>
                          <li style={{ textAlign: "initial" }}>
                            The Tenant must maintain suitable apartment
                            insurance at all times during the Tenancy.
                          </li>
                          <li style={{ textAlign: "initial" }}>
                            The Landlord has the right to remove the Tenants
                            eligibility of the 2% Prompt Payment Discount if:
                          </li>
                          <li style={{ textAlign: "initial" }}>
                            Any cheque is returned for whatever reason and/or;
                          </li>
                          <li style={{ textAlign: "initial" }}>
                            Proof of adequate Tenant Insurance is not maintained
                            during the life of the Tenancy or provided to the
                            Landlord upon request at the Landlords discretion.
                          </li>
                        </ul>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          position: "relative",
                          top: "-1rem",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "800",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          Tenant Insurance
                        </p>
                        The undersigned understands and agrees that Tenant
                        apartment insurance is a mandatory requirement by the
                        Landlord. Proof of Tenant insurance must be provided
                        prior to the move in date. Possession of the unit can be
                        withheld until such time as Tenant Insurance is in place
                        and acceptable at the Landlord’s sole discretion.
                        {/* <span style={{ display: "flex", width: "6rem" ,justifyContent:"center",position:"absolute",top:"4rem"}}> */}
                        <span
                          style={{
                            display: "flex",
                            width: "13rem",
                            gap: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <input
                              className={`form-control print-only-four-section`}
                              name="initial_one"
                              value={formik.values.initial_one}
                              onChange={formik.handleChange}
                              type="text"
                              maxLength={3} // Limit to one character
                              onBlur={formik.handleBlur}
                              autoComplete="new-password"
                              onInput={(event) => {
                                event.target.value =
                                  event.target.value.toUpperCase();
                                // Allow only letters (uppercase or lowercase)
                                event.target.value = event.target.value.replace(
                                  /[^a-zA-Z]/g,
                                  ""
                                );
                              }}
                              style={{
                                // borderLeft: "none",
                                // borderRight: "none",
                                // borderTop: "none",
                                textAlign: "center",

                                // marginRight: "1rem",
                                width: "6rem",
                              }}
                            />
                            <span style={{ fontSize: "12px" }}>
                              Tenant(s) Initial
                            </span>
                          </div>

                          <div>
                            <input
                              className={`form-control print-only-four-section ${
                                fields && fields.length >= 2
                                  ? "visible"
                                  : "invisible"
                              }`}
                              name="initial_two"
                              value={formik.values.initial_two}
                              onChange={formik.handleChange}
                              type="text"
                              maxLength={3} // Limit to one character
                              onBlur={formik.handleBlur}
                              autoComplete="new-password"
                              onInput={(event) => {
                                event.target.value =
                                  event.target.value.toUpperCase();
                                // Allow only letters (uppercase or lowercase)
                                event.target.value = event.target.value.replace(
                                  /[^a-zA-Z]/g,
                                  ""
                                );
                              }}
                              style={{
                                // borderLeft: "none",
                                // borderRight: "none",
                                // borderTop: "none",
                                textAlign: "center",

                                // marginRight: "1rem",
                                width: "6rem",
                              }}
                            />
                            <span
                              className={`print-only-four-section ${
                                fields && fields.length >= 2
                                  ? "visible"
                                  : "invisible"
                              }`}
                              style={{ fontSize: "12px" }}
                            >
                              Tenant(s) Initial
                            </span>
                          </div>

                          {/* <input
                            className={`form-control print-only-four-section ${fields && fields.length >= 2 ? "visible" : "invisible"}`}
                            name="initial_two"
                            value={formik?.values?.initial_two}
                            onChange={formik?.handleChange}
                            type="text"
                            maxLength={1} // Limit to one character
                            onBlur={formik.handleBlur}
                            autoComplete="new-password"
                            onInput={(event) => {
                              // Allow only letters (uppercase or lowercase)
                              event.target.value = event.target.value.replace(
                                /[^a-zA-Z]/g,
                                ""
                              );
                            }}
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                            }}
                          /> */}
                          {fields && fields.length === 3 && (
                            <div>
                              <input
                                className={`form-control print-only-four-section  ${
                                  fields && fields.length === 3
                                    ? "visible"
                                    : "invisible"
                                }`}
                                name="initial_three"
                                value={formik.values.initial_three}
                                onChange={formik.handleChange}
                                type="text"
                                maxLength={3} // Limit to one character
                                onBlur={formik.handleBlur}
                                autoComplete="new-password"
                                onInput={(event) => {
                                  event.target.value =
                                    event.target.value.toUpperCase();
                                  // Allow only letters (uppercase or lowercase)
                                  event.target.value =
                                    event.target.value.replace(
                                      /[^a-zA-Z]/g,
                                      ""
                                    );
                                }}
                                style={{
                                  // borderLeft: "none",
                                  // borderRight: "none",
                                  // borderTop: "none",
                                  // marginRight: "1rem",
                                  textAlign: "center",
                                  width: "6rem",
                                }}
                              />
                              <span style={{ fontSize: "12px" }}>
                                Tenant(s) Initial
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </section>
                  </section>
                </div>
                <div
                  className="tab-content"
                  tab="2: Applicant Particulars"
                  key="2: Applicant Particulars"
                  style={{
                    display:
                      activeTab === "2: Applicant Particulars"
                        ? "block"
                        : "none",
                  }}
                >
                  <section className="Applicant-particular" ref={listingRef2}>
                    <div className="cardTitleHome card-title h5 mt-2">
                      Additional details
                    </div>
                    <div className="input-group mb-3 d-lg-flex d-none print-only-block">
                      <span
                        className="input-group-text print-only-four-section col-lg-3"
                        id="basic-addon3"
                      >
                        Detail
                      </span>
                      <span
                        className="input-group-text print-only-four-section col-lg-3"
                        id="basic-addon3"
                      >
                        Applicant “One”
                      </span>
                      <span
                        className="input-group-text print-only-four-section col-lg-3"
                        id="basic-addon3"
                      >
                        Applicant “Two”
                      </span>
                      <span
                        className="input-group-text print-only-four-section col-lg-3"
                        id="basic-addon3"
                      >
                        Applicant “Three”{" "}
                      </span>
                    </div>

                    {/* name */}
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Name
                      </span>
                      <input
                        ref={firstInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.name_applicant_one &&
                          formik?.touched.name_applicant_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"name_applicant_one"}
                        value={formik?.values?.name_applicant_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onBlur={formik.handleBlur}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onKeyDown={(e) => handleTabPress(e, secondInputRef)} // Pass the ref of the next input field
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.name_applicant_two &&
                          formik?.touched.name_applicant_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"name_applicant_two"}
                        value={formik?.values?.name_applicant_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.name_applicant_three &&
                          formik?.touched.name_applicant_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"name_applicant_three"}
                        value={formik?.values?.name_applicant_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                    </div>

                    {/* dob */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Date of Birth
                      </span>
                      {/* <input
                  className={
                    "form-control print-only-four-section col-lg-3" +
                    (formik?.errors.date_of_birth_one &&
                    formik?.touched.date_of_birth_one
                      ? " is-invalid"
                      : "")
                  }
                  name={"date_of_birth_one"}
                  value={formik?.values?.date_of_birth_one}
                  onChange={formik?.handleChange}
                  type="text"
                />
                <input
                  className={
                    "form-control print-only-four-section col-lg-3" +
                    (formik?.errors.date_of_birth_two &&
                    formik?.touched.date_of_birth_two
                      ? " is-invalid"
                      : "")
                  }
                  name={"date_of_birth_two"}
                  value={formik?.values?.date_of_birth_two}
                  onChange={formik?.handleChange}
                  type="text"
                />
                <input
                  className={
                    "form-control print-only-four-section col-lg-3" +
                    (formik?.errors.date_of_birth_three &&
                    formik?.touched.date_of_birth_three
                      ? " is-invalid"
                      : "")
                  }
                  name={"date_of_birth_three"}
                  value={formik?.values?.date_of_birth_three}
                  onChange={formik?.handleChange}
                  type="text"
                /> */}
                      <DatePicker
                        ref={secondInputRef}
                        className="form-control print-only-four-section"
                        value={dobApplicantOnes}
                        name="dobApplicantOne"
                        onChange={dobApplicantOne}
                        // onKeyDown={handleTabPress}
                        onKeyDown={(e) => handleTabPress(e, thirdInputRef)} // No next input field to focus on
                      />
                      <DatePicker
                        className="form-control print-only-four-section"
                        value={dobApplicantTwos}
                        name="dobApplicantTwo"
                        onChange={dobApplicantTwo}
                      />{" "}
                      <DatePicker
                        className="form-control print-only-four-section"
                        value={dobApplicantThrees}
                        name="dobApplicantThree"
                        onChange={dobApplicantThree}
                      />
                    </div>

                    {/* Current Address */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Current Address
                      </span>
                      <textarea
                        ref={thirdInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.current_address_one &&
                          formik?.touched.current_address_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_address_one"}
                        value={formik?.values?.current_address_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, fourthInputRef)} // No next input field to focus on
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.current_address_two &&
                          formik?.touched.current_address_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_address_two"}
                        value={formik?.values?.current_address_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // onKeyDown={handleTabPress}
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.current_address_three &&
                          formik?.touched.current_address_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_address_three"}
                        value={formik?.values?.current_address_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                    </div>

                    {/* Appartment No */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Apartment No.
                      </span>
                      <input
                        ref={fourthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.appartment_no_one &&
                          formik?.touched.appartment_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"appartment_no_one"}
                        value={formik?.values?.appartment_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, fifthInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.appartment_no_two &&
                          formik?.touched.appartment_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"appartment_no_two"}
                        value={formik?.values?.appartment_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.appartment_no_three &&
                          formik?.touched.appartment_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"appartment_no_three"}
                        value={formik?.values?.appartment_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    {/* Present Monthly Rent */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Present Monthly Rent
                      </span>
                      <input
                        ref={fifthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.present_monthly_rent_one &&
                          formik?.touched.present_monthly_rent_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"present_monthly_rent_one"}
                        value={formik?.values?.present_monthly_rent_one}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangePresentMonthly1}
                        type="text"
                        placeholder="$"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, sixthInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.present_monthly_rent_two &&
                          formik?.touched.present_monthly_rent_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"present_monthly_rent_two"}
                        value={formik?.values?.present_monthly_rent_two}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangePresentMonthly2}
                        type="text"
                        placeholder="$"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.present_monthly_rent_three &&
                          formik?.touched.present_monthly_rent_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"present_monthly_rent_three"}
                        value={formik?.values?.present_monthly_rent_three}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangePresentMonthly3}
                        type="text"
                        placeholder="$"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    {/* Postal Code  */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Postal Code
                      </span>
                      <input
                        ref={sixthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.postal_code_one &&
                          formik?.touched.postal_code_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"postal_code_one"}
                        value={formik?.values?.postal_code_one}
                        onChange={formik?.handleChange}
                        type="text"
                        // onKeyPress={handleKeyPress}
                        onBlur={formik.handleBlur}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onKeyDown={(e) => handleTabPress(e, seventhInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.postal_code_two &&
                          formik?.touched.postal_code_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"postal_code_two"}
                        value={formik?.values?.postal_code_two}
                        onChange={formik?.handleChange}
                        type="text"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.postal_code_three &&
                          formik?.touched.postal_code_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"postal_code_three"}
                        value={formik?.values?.postal_code_three}
                        onChange={formik?.handleChange}
                        type="text"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    {/* Length of Residence  */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Length of Residence
                      </span>
                      <input
                        ref={seventhInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.length_of_residence_one &&
                          formik?.touched.length_of_residence_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_residence_one"}
                        value={formik?.values?.length_of_residence_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, eigththInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.length_of_residence_two &&
                          formik?.touched.length_of_residence_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_residence_two"}
                        value={formik?.values?.length_of_residence_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.length_of_residence_three &&
                          formik?.touched.length_of_residence_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_residence_three"}
                        value={formik?.values?.length_of_residence_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    {/* Home Phone No.  */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Home Phone No.
                      </span>
                      {/* <input
                        ref={eigththInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.home_phone_no_one &&
                          formik?.touched.home_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"home_phone_no_one"}
                        value={formik?.values?.home_phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, ninthInputRef)} // No next input field to focus on
                      /> */}
                      <ReactInputMask
                        ref={eigththInputRef}
                        mask="(999) 999-9999"
                        value={formik?.values?.home_phone_no_one}
                        name={"home_phone_no_one"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.home_phone_no_one &&
                          formik?.touched.home_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                        onKeyDown={(e) => handleTabPress(e, ninthInputRef)} // No next input field to focus on
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.home_phone_no_two &&
                          formik?.touched.home_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"home_phone_no_two"}
                        value={formik?.values?.home_phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.home_phone_no_two}
                        name={"home_phone_no_two"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.home_phone_no_two &&
                          formik?.touched.home_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.home_phone_no_three &&
                          formik?.touched.home_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"home_phone_no_three"}
                        value={formik?.values?.home_phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.home_phone_no_three}
                        name={"home_phone_no_three"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.home_phone_no_three &&
                          formik?.touched.home_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                    </div>

                    {/* Cell Phone No.  */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Cell Phone No.
                      </span>
                      {/* <input
                        ref={ninthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.cell_phone_no_one &&
                          formik?.touched.cell_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"cell_phone_no_one"}
                        value={formik?.values?.cell_phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, tenthInputRef)} // No next input field to focus on
                      /> */}
                      <ReactInputMask
                        ref={ninthInputRef}
                        mask="(999) 999-9999"
                        value={formik?.values?.cell_phone_no_one}
                        name={"cell_phone_no_one"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.cell_phone_no_one &&
                          formik?.touched.cell_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        onKeyDown={(e) => handleTabPress(e, tenthInputRef)} // No next input field to focus on
                        onBlur={formik.handleBlur}
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.cell_phone_no_two &&
                          formik?.touched.cell_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"cell_phone_no_two"}
                        value={formik?.values?.cell_phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.cell_phone_no_two}
                        name={"cell_phone_no_two"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.cell_phone_no_two &&
                          formik?.touched.cell_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.cell_phone_no_three &&
                          formik?.touched.cell_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"cell_phone_no_three"}
                        value={formik?.values?.cell_phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.cell_phone_no_three}
                        name={"cell_phone_no_three"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.cell_phone_no_three &&
                          formik?.touched.cell_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                    </div>

                    {/* If Renting, Landlords Name  */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        If Renting, Landlords Name
                      </span>
                      <input
                        ref={tenthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.landlords_name_one &&
                          formik?.touched.landlords_name_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_name_one"}
                        value={formik?.values?.landlords_name_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, eleventhInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.landlords_name_two &&
                          formik?.touched.landlords_name_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_name_two"}
                        value={formik?.values?.landlords_name_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.landlords_name_three &&
                          formik?.touched.landlords_name_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_name_three"}
                        value={formik?.values?.landlords_name_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                    </div>

                    {/* If Renting, Landlords phone  */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        If Renting, Landlords Phone No
                      </span>
                      {/* <input
                        ref={eleventhInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.landlords_phone_one &&
                          formik?.touched.landlords_phone_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_phone_one"}
                        value={formik?.values?.landlords_phone_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onKeyPress={handleKeyPress}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, twelvethInputRef)} // No next input field to focus on
                      /> */}

                      <ReactInputMask
                        ref={eleventhInputRef}
                        mask="(999) 999-9999"
                        value={formik?.values?.landlords_phone_one}
                        name={"landlords_phone_one"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.landlords_phone_one &&
                          formik?.touched.landlords_phone_one
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        onKeyDown={(e) => handleTabPress(e, twelvethInputRef)} // No next input field to focus on
                        onBlur={formik.handleBlur}
                      />

                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.landlords_phone_two &&
                          formik?.touched.landlords_phone_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_phone_two"}
                        value={formik?.values?.landlords_phone_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.landlords_phone_two}
                        name={"landlords_phone_two"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.landlords_phone_two &&
                          formik?.touched.landlords_phone_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.landlords_phone_three &&
                          formik?.touched.landlords_phone_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"landlords_phone_three"}
                        value={formik?.values?.landlords_phone_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.landlords_phone_three}
                        name={"landlords_phone_three"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.landlords_phone_three &&
                          formik?.touched.landlords_phone_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                    </div>

                    {/* Previous Address  */}
                    <div className="cardTitleHome card-title h5 mt-2">
                      Previous Address
                    </div>

                    {/* sasa */}

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Address
                      </span>
                      <textarea
                        ref={twentyfiveInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_address_one &&
                          formik?.touched.previous_address_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_address_one"}
                        value={formik?.values?.previous_address_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, twentysixInputRef)} // No next input field to focus on
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_address_two &&
                          formik?.touched.previous_address_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_address_two"}
                        value={formik?.values?.previous_address_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_address_three &&
                          formik?.touched.previous_address_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_address_three"}
                        value={formik?.values?.previous_address_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                    </div>

                    {/* asa */}
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Apartment No.
                      </span>
                      <textarea
                        ref={twelvethInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_appartment_no_one &&
                          formik?.touched.previous_appartment_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_appartment_no_one"}
                        value={formik?.values?.previous_appartment_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // onKeyPress={handleKeyPress}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, thirteenthInputRef)} // No next input field to focus on
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_appartment_no_two &&
                          formik?.touched.previous_appartment_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_appartment_no_two"}
                        value={formik?.values?.previous_appartment_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // onKeyPress={handleKeyPress}
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_appartment_no_three &&
                          formik?.touched.previous_appartment_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_appartment_no_three"}
                        value={formik?.values?.previous_appartment_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // onKeyPress={handleKeyPress}
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                    </div>

                    {/* monthly rent previous */}
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Monthly Rent
                      </span>
                      <input
                        ref={thirteenthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_monthly_rent_one &&
                          formik?.touched.previous_monthly_rent_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_monthly_rent_one"}
                        value={formik?.values?.previous_monthly_rent_one}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangePreviousMonthly1}
                        type="text"
                        placeholder="$"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, fourteenthInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_monthly_rent_two &&
                          formik?.touched.previous_monthly_rent_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_monthly_rent_two"}
                        value={formik?.values?.previous_monthly_rent_two}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangePreviousMonthly2}
                        type="text"
                        placeholder="$"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_monthly_rent_three &&
                          formik?.touched.previous_monthly_rent_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_monthly_rent_three"}
                        value={formik?.values?.previous_monthly_rent_three}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangePreviousMonthly3}
                        type="text"
                        placeholder="$"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    {/* monthly rent previous */}
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Postal Code/City
                      </span>
                      <input
                        ref={fourteenthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_postal_code_one &&
                          formik?.touched.previous_postal_code_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_postal_code_one"}
                        value={formik?.values?.previous_postal_code_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, fifteenthInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_postal_code_two &&
                          formik?.touched.previous_postal_code_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_postal_code_two"}
                        value={formik?.values?.previous_postal_code_two}
                        onChange={formik?.handleChange}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        type="text"
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_postal_code_three &&
                          formik?.touched.previous_postal_code_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_postal_code_three"}
                        value={formik?.values?.previous_postal_code_three}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onChange={formik?.handleChange}
                        type="text"
                      />
                    </div>

                    {/* Previous Length of Residence */}
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Length of Residence
                      </span>
                      <input
                        ref={fifteenthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_length_of_residence_one &&
                          formik?.touched.previous_length_of_residence_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_length_of_residence_one"}
                        value={formik?.values?.previous_length_of_residence_one}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onChange={formik?.handleChange}
                        type="text"
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, sixteenthInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_length_of_residence_two &&
                          formik?.touched.previous_length_of_residence_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_length_of_residence_two"}
                        value={formik?.values?.previous_length_of_residence_two}
                        onChange={formik?.handleChange}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        type="text"
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_length_of_residence_three &&
                          formik?.touched.previous_length_of_residence_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_length_of_residence_three"}
                        value={
                          formik?.values?.previous_length_of_residence_three
                        }
                        onChange={formik?.handleChange}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        type="text"
                      />
                    </div>

                    {/* Previous Landlords Name */}
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Landlords Name
                      </span>
                      <input
                        ref={sixteenthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_landlords_name_one &&
                          formik?.touched.previous_landlords_name_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_name_one"}
                        value={formik?.values?.previous_landlords_name_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) =>
                          handleTabPress(e, seventeenthInputRef)
                        } // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_landlords_name_two &&
                          formik?.touched.previous_landlords_name_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_name_two"}
                        value={formik?.values?.previous_landlords_name_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_landlords_name_three &&
                          formik?.touched.previous_landlords_name_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_name_three"}
                        value={formik?.values?.previous_landlords_name_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                    </div>

                    {/* Previous Landlords Phone No. */}
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Landlords Phone No.
                      </span>
                      {/* <input
                        ref={seventeenthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_landlords_phone_one &&
                          formik?.touched.previous_landlords_phone_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_phone_one"}
                        value={formik?.values?.previous_landlords_phone_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, eighteenthInputRef)} // No next input field to focus on
                      /> */}
                      <ReactInputMask
                        ref={seventeenthInputRef}
                        mask="(999) 999-9999"
                        value={formik?.values?.previous_landlords_phone_one}
                        name={"previous_landlords_phone_one"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_landlords_phone_one &&
                          formik?.touched.previous_landlords_phone_one
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                        onKeyDown={(e) => handleTabPress(e, eighteenthInputRef)} // No next input field to focus on
                        onBlur={formik.handleBlur}
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_landlords_phone_two &&
                          formik?.touched.previous_landlords_phone_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_phone_two"}
                        value={formik?.values?.previous_landlords_phone_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.previous_landlords_phone_two}
                        name={"previous_landlords_phone_two"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_landlords_phone_two &&
                          formik?.touched.previous_landlords_phone_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_landlords_phone_three &&
                          formik?.touched.previous_landlords_phone_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_landlords_phone_three"}
                        value={formik?.values?.previous_landlords_phone_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.previous_landlords_phone_three}
                        name={"previous_landlords_phone_three"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_landlords_phone_three &&
                          formik?.touched.previous_landlords_phone_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                    </div>

                    {/* Employment Information */}

                    <div className="cardTitleHome card-title h5 mt-2">
                      Employment Information
                    </div>
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Current Employer
                      </span>
                      <textarea
                        ref={eighteenthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.current_employer_one &&
                          formik?.touched.current_employer_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_employer_one"}
                        value={formik?.values?.current_employer_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, nineteenthInputRef)} // No next input field to focus on
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                          e.target.value = e.target.value.replace(/[0-9]/g, "");
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.current_employer_two &&
                          formik?.touched.current_employer_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_employer_two"}
                        value={formik?.values?.current_employer_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                          e.target.value = e.target.value.replace(/[0-9]/g, "");
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.current_employer_three &&
                          formik?.touched.current_employer_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"current_employer_three"}
                        value={formik?.values?.current_employer_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                          e.target.value = e.target.value.replace(/[0-9]/g, "");
                        }}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Work Phone No.
                      </span>
                      {/* <input
                        ref={nineteenthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.work_phone_no_one &&
                          formik?.touched.work_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"work_phone_no_one"}
                        value={formik?.values?.work_phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        onBlur={formik.handleBlur}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onKeyDown={(e) => handleTabPress(e, twenteenthInputRef)} // No next input field to focus on
                      /> */}
                      <ReactInputMask
                        ref={nineteenthInputRef}
                        mask="(999) 999-9999"
                        value={formik?.values?.work_phone_no_one}
                        name={"work_phone_no_one"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.work_phone_no_one &&
                          formik?.touched.work_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                        onKeyDown={(e) => handleTabPress(e, twenteenthInputRef)} // No next input field to focus on
                      />

                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.work_phone_no_two &&
                          formik?.touched.work_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"work_phone_no_two"}
                        value={formik?.values?.work_phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.work_phone_no_two}
                        name={"work_phone_no_two"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.work_phone_no_two &&
                          formik?.touched.work_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.work_phone_no_three &&
                          formik?.touched.work_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"work_phone_no_three"}
                        value={formik?.values?.work_phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.work_phone_no_three}
                        name={"work_phone_no_three"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.work_phone_no_three &&
                          formik?.touched.work_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Position
                      </span>
                      <textarea
                        ref={twenteenthInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.position_one &&
                          formik?.touched.position_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"position_one"}
                        value={formik?.values?.position_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, twentyoneInputRef)} // No next input field to focus on
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                          e.target.value = e.target.value.replace(/[0-9]/g, "");
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.position_two &&
                          formik?.touched.position_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"position_two"}
                        value={formik?.values?.position_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                          e.target.value = e.target.value.replace(/[0-9]/g, "");
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.position_three &&
                          formik?.touched.position_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"position_three"}
                        value={formik?.values?.position_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                          e.target.value = e.target.value.replace(/[0-9]/g, "");
                        }}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Length of Employment
                      </span>
                      <input
                        ref={twentyoneInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.length_of_employment_one &&
                          formik?.touched.length_of_employment_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_employment_one"}
                        value={formik?.values?.length_of_employment_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, twentytwoInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.length_of_employment_two &&
                          formik?.touched.length_of_employment_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_employment_two"}
                        value={formik?.values?.length_of_employment_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.length_of_employment_three &&
                          formik?.touched.length_of_employment_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"length_of_employment_three"}
                        value={formik?.values?.length_of_employment_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Contact Name
                      </span>
                      <input
                        ref={twentytwoInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.contact_name_no_one &&
                          formik?.touched.contact_name_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"contact_name_no_one"}
                        value={formik?.values?.contact_name_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) =>
                          handleTabPress(e, twentythreeInputRef)
                        } // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.contact_name_no_two &&
                          formik?.touched.contact_name_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"contact_name_no_two"}
                        value={formik?.values?.contact_name_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.contact_name_no_three &&
                          formik?.touched.contact_name_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"contact_name_no_three"}
                        value={formik?.values?.contact_name_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Phone No.
                      </span>
                      {/* <input
                        ref={twentythreeInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.phone_no_one &&
                          formik?.touched.phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"phone_no_one"}
                        value={formik?.values?.phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, twentyfourInputRef)} // No next input field to focus on
                      /> */}
                      <ReactInputMask
                        ref={twentythreeInputRef}
                        mask="(999) 999-9999"
                        value={formik?.values?.phone_no_one}
                        name={"phone_no_one"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.phone_no_one &&
                          formik?.touched.phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                        onKeyDown={(e) => handleTabPress(e, twentyfourInputRef)} // No next input field to focus on
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.phone_no_two &&
                          formik?.touched.phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"phone_no_two"}
                        value={formik?.values?.phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.phone_no_two}
                        name={"phone_no_two"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.phone_no_two &&
                          formik?.touched.phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.phone_no_three &&
                          formik?.touched.phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"phone_no_three"}
                        value={formik?.values?.phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.phone_no_three}
                        name={"phone_no_three"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.phone_no_three &&
                          formik?.touched.phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Annual Income
                      </span>
                      <input
                        ref={twentyfourInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.annual_income_one &&
                          formik?.touched.annual_income_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"annual_income_one"}
                        value={formik?.values?.annual_income_one}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangeAnnual1}
                        placeholder="$"
                        type="text"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, twentyfiveInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.annual_income_two &&
                          formik?.touched.annual_income_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"annual_income_two"}
                        value={formik?.values?.annual_income_two}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangeAnnual2}
                        type="text"
                        placeholder="$"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.annual_income_three &&
                          formik?.touched.annual_income_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"annual_income_three"}
                        value={formik?.values?.annual_income_three}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangeAnnual3}
                        type="text"
                        // onKeyPress={handleKeyPress}
                        placeholder="$"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="cardTitleHome card-title h5 mt-2">
                      Previous Employment
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Employer
                      </span>
                      <input
                        ref={twentyfiveInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_employer_one &&
                          formik?.touched.previous_employer_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_employer_one"}
                        value={formik?.values?.previous_employer_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, twentysixInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_employer_two &&
                          formik?.touched.previous_employer_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_employer_two"}
                        value={formik?.values?.previous_employer_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_employer_three &&
                          formik?.touched.previous_employer_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_employer_three"}
                        value={formik?.values?.previous_employer_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Position
                      </span>
                      <input
                        ref={twentysixInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_position_one &&
                          formik?.touched.previous_position_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_position_one"}
                        value={formik?.values?.previous_position_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) =>
                          handleTabPress(e, twentysevenInputRef)
                        } // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_position_two &&
                          formik?.touched.previous_position_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_position_two"}
                        value={formik?.values?.previous_position_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_position_three &&
                          formik?.touched.previous_position_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_position_three"}
                        value={formik?.values?.previous_position_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Length of Employment
                      </span>
                      <input
                        ref={twentysevenInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.Previous_length_of_employment_one &&
                          formik?.touched.Previous_length_of_employment_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"Previous_length_of_employment_one"}
                        value={
                          formik?.values?.Previous_length_of_employment_one
                        }
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) =>
                          handleTabPress(e, twentyeightInputRef)
                        } // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.Previous_length_of_employment_two &&
                          formik?.touched.Previous_length_of_employment_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"Previous_length_of_employment_two"}
                        value={
                          formik?.values?.Previous_length_of_employment_two
                        }
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.Previous_length_of_employment_three &&
                          formik?.touched.Previous_length_of_employment_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"Previous_length_of_employment_three"}
                        value={
                          formik?.values?.Previous_length_of_employment_three
                        }
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Contact Name & No.
                      </span>
                      <input
                        ref={twentyeightInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_contact_name_no_one &&
                          formik?.touched.previous_contact_name_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_contact_name_no_one"}
                        value={formik?.values?.previous_contact_name_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onBlur={formik.handleBlur}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onKeyDown={(e) => handleTabPress(e, twentynineInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_contact_name_no_two &&
                          formik?.touched.previous_contact_name_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_contact_name_no_two"}
                        value={formik?.values?.previous_contact_name_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_contact_name_no_three &&
                          formik?.touched.previous_contact_name_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_contact_name_no_three"}
                        value={formik?.values?.previous_contact_name_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Previous Annual Income
                      </span>
                      <input
                        ref={twentynineInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_annual_income_one &&
                          formik?.touched.previous_annual_income_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_annual_income_one"}
                        value={formik?.values?.previous_annual_income_one}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangePreviousAnnual1}
                        type="text"
                        // onKeyPress={handleKeyPress}
                        placeholder="$"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, thirtyInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_annual_income_two &&
                          formik?.touched.previous_annual_income_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_annual_income_two"}
                        value={formik?.values?.previous_annual_income_two}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangePreviousAnnual2}
                        type="text"
                        // onKeyPress={handleKeyPress}
                        placeholder="$"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.previous_annual_income_three &&
                          formik?.touched.previous_annual_income_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"previous_annual_income_three"}
                        value={formik?.values?.previous_annual_income_three}
                        // onChange={formik?.handleChange}
                        onChange={handleInputChangePreviousAnnual3}
                        type="text"
                        // onKeyPress={handleKeyPress}
                        placeholder="$"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="cardTitleHome card-title h5 mt-2">
                      Banking Information
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Bank
                      </span>
                      <input
                        ref={thirtyInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.bank_one && formik?.touched.bank_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"bank_one"}
                        value={formik?.values?.bank_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, thirtyoneInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.bank_two && formik?.touched.bank_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"bank_two"}
                        value={formik?.values?.bank_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.bank_three &&
                          formik?.touched.bank_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"bank_three"}
                        value={formik?.values?.bank_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Branch
                      </span>
                      <input
                        ref={thirtyoneInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.branch_one &&
                          formik?.touched.branch_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"branch_one"}
                        value={formik?.values?.branch_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, thirtytwoInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.branch_two &&
                          formik?.touched.branch_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"branch_two"}
                        value={formik?.values?.branch_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.branch_three &&
                          formik?.touched.branch_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"branch_three"}
                        value={formik?.values?.branch_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Account No.
                      </span>
                      <input
                        ref={thirtytwoInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.account_no_one &&
                          formik?.touched.account_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"account_no_one"}
                        value={formik?.values?.account_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) =>
                          handleTabPress(e, thirtythreeInputRef)
                        } // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.account_no_two &&
                          formik?.touched.account_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"account_no_two"}
                        value={formik?.values?.account_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        // onKeyPress={handleKeyPress}
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.account_no_three &&
                          formik?.touched.account_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"account_no_three"}
                        value={formik?.values?.account_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        // onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="cardTitleHome card-title h5 mt-2">
                      Vehicle Information
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Make of Vehicle
                      </span>
                      <input
                        ref={thirtythreeInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.make_of_vehicle_one &&
                          formik?.touched.make_of_vehicle_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"make_of_vehicle_one"}
                        value={formik?.values?.make_of_vehicle_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, thirtyfourInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.make_of_vehicle_two &&
                          formik?.touched.make_of_vehicle_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"make_of_vehicle_two"}
                        value={formik?.values?.make_of_vehicle_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.make_of_vehicle_three &&
                          formik?.touched.make_of_vehicle_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"make_of_vehicle_three"}
                        value={formik?.values?.make_of_vehicle_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Year & Model of Vehicle
                      </span>
                      <input
                        ref={thirtyfourInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.year_model_of_vehicle_one &&
                          formik?.touched.year_model_of_vehicle_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"year_model_of_vehicle_one"}
                        value={formik?.values?.year_model_of_vehicle_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onBlur={formik.handleBlur}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onKeyDown={(e) => handleTabPress(e, thirtyfiveInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.year_model_of_vehicle_two &&
                          formik?.touched.year_model_of_vehicle_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"year_model_of_vehicle_two"}
                        value={formik?.values?.year_model_of_vehicle_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.year_model_of_vehicle_three &&
                          formik?.touched.year_model_of_vehicle_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"year_model_of_vehicle_three"}
                        value={formik?.values?.year_model_of_vehicle_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Colour of Vehicle
                      </span>
                      <input
                        ref={thirtyfiveInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.colour_of_vehicle_one &&
                          formik?.touched.colour_of_vehicle_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"colour_of_vehicle_one"}
                        value={formik?.values?.colour_of_vehicle_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, thirtysixInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.colour_of_vehicle_two &&
                          formik?.touched.colour_of_vehicle_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"colour_of_vehicle_two"}
                        value={formik?.values?.colour_of_vehicle_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.colour_of_vehicle_three &&
                          formik?.touched.colour_of_vehicle_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"colour_of_vehicle_three"}
                        value={formik?.values?.colour_of_vehicle_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Licence Plate No.
                      </span>
                      <input
                        ref={thirtysixInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.licence_plate_no_one &&
                          formik?.touched.licence_plate_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"licence_plate_no_one"}
                        value={formik?.values?.licence_plate_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) =>
                          handleTabPress(e, thirtysevenInputRef)
                        } // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.licence_plate_no_two &&
                          formik?.touched.licence_plate_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"licence_plate_no_two"}
                        value={formik?.values?.licence_plate_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.licence_plate_no_three &&
                          formik?.touched.licence_plate_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"licence_plate_no_three"}
                        value={formik?.values?.licence_plate_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Drivers License No.
                      </span>
                      <input
                        ref={thirtysevenInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.drivers_license_no_one &&
                          formik?.touched.drivers_license_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"drivers_license_no_one"}
                        value={formik?.values?.drivers_license_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) =>
                          handleTabPress(e, thirtyeightInputRef)
                        } // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.drivers_license_no_two &&
                          formik?.touched.drivers_license_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"drivers_license_no_two"}
                        value={formik?.values?.drivers_license_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.drivers_license_no_three &&
                          formik?.touched.drivers_license_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"drivers_license_no_three"}
                        value={formik?.values?.drivers_license_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="cardTitleHome card-title h5 mt-2">
                      Reference Information
                    </div>
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Name
                      </span>
                      <input
                        ref={thirtyeightInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_name_one &&
                          formik?.touched.reference_name_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_name_one"}
                        value={formik?.values?.reference_name_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, thirtynineInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_name_two &&
                          formik?.touched.reference_name_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_name_two"}
                        value={formik?.values?.reference_name_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_name_three &&
                          formik?.touched.reference_name_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_name_three"}
                        value={formik?.values?.reference_name_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Address
                      </span>
                      <textarea
                        ref={thirtynineInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_address_one &&
                          formik?.touched.reference_address_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_address_one"}
                        value={formik?.values?.reference_address_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, fourtyInputRef)} // No next input field to focus on
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_address_two &&
                          formik?.touched.reference_address_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_address_two"}
                        value={formik?.values?.reference_address_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_address_three &&
                          formik?.touched.reference_address_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_address_three"}
                        value={formik?.values?.reference_address_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Phone No.
                      </span>
                      {/* <input
                        ref={fourtyInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_phone_no_one &&
                          formik?.touched.reference_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_phone_no_one"}
                        value={formik?.values?.reference_phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, fourtyoneInputRef)} // No next input field to focus on
                      /> */}

                      <ReactInputMask
                        ref={fourtyInputRef}
                        mask="(999) 999-9999"
                        value={formik?.values?.reference_phone_no_one}
                        name={"reference_phone_no_one"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_phone_no_one &&
                          formik?.touched.reference_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                        onKeyDown={(e) => handleTabPress(e, fourtyoneInputRef)} // No next input field to focus on
                      />

                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_phone_no_two &&
                          formik?.touched.reference_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_phone_no_two"}
                        value={formik?.values?.reference_phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onKeyPress={handleKeyPress}
                        onBlur={formik.handleBlur}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.reference_phone_no_two}
                        name={"reference_phone_no_two"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_phone_no_two &&
                          formik?.touched.reference_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_phone_no_three &&
                          formik?.touched.reference_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_phone_no_three"}
                        value={formik?.values?.reference_phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onKeyPress={handleKeyPress}
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.reference_phone_no_three}
                        name={"reference_phone_no_three"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_phone_no_three &&
                          formik?.touched.reference_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Relationship
                      </span>
                      <input
                        ref={fourtyoneInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_relationship_one &&
                          formik?.touched.reference_relationship_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_relationship_one"}
                        value={formik?.values?.reference_relationship_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, fourtytwoInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_relationship_two &&
                          formik?.touched.reference_relationship_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_relationship_two"}
                        value={formik?.values?.reference_relationship_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.reference_relationship_three &&
                          formik?.touched.reference_relationship_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"reference_relationship_three"}
                        value={formik?.values?.reference_relationship_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="cardTitleHome card-title h5 mt-2">
                      Next of Kin Contact Info
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Name
                      </span>
                      <input
                        ref={fourtytwoInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_name_one &&
                          formik?.touched.next_name_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_name_one"}
                        value={formik?.values?.next_name_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onKeyDown={(e) =>
                          handleTabPress(e, fourtythreeInputRef)
                        } // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_name_two &&
                          formik?.touched.next_name_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_name_two"}
                        value={formik?.values?.next_name_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_name_three &&
                          formik?.touched.next_name_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_name_three"}
                        value={formik?.values?.next_name_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Address
                      </span>
                      <textarea
                        ref={fourtythreeInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_address_one &&
                          formik?.touched.next_address_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_address_one"}
                        value={formik?.values?.next_address_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, fourtyfourInputRef)} // No next input field to focus on
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_address_two &&
                          formik?.touched.next_address_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_address_two"}
                        value={formik?.values?.next_address_two}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                      <textarea
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_address_three &&
                          formik?.touched.next_address_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_address_three"}
                        value={formik?.values?.next_address_three}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        style={{
                          minHeight: "100px",
                          resize: "none",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Phone No.
                      </span>
                      {/* <input
                        ref={fourtyfourInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_phone_no_one &&
                          formik?.touched.next_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_phone_no_one"}
                        value={formik?.values?.next_phone_no_one}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onBlur={formik.handleBlur}
                        onKeyDown={(e) => handleTabPress(e, fourtyfiveInputRef)} // No next input field to focus on
                      /> */}
                      <ReactInputMask
                        ref={fourtyfourInputRef}
                        mask="(999) 999-9999"
                        value={formik?.values?.next_phone_no_one}
                        name={"next_phone_no_one"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_phone_no_one &&
                          formik?.touched.next_phone_no_one
                            ? " is-invalid"
                            : "")
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                        onKeyDown={(e) => handleTabPress(e, fourtyfiveInputRef)} // No next input field to focus on
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_phone_no_two &&
                          formik?.touched.next_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_phone_no_two"}
                        value={formik?.values?.next_phone_no_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.next_phone_no_two}
                        name={"next_phone_no_two"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_phone_no_two &&
                          formik?.touched.next_phone_no_two
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                      {/* <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_phone_no_three &&
                          formik?.touched.next_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_phone_no_three"}
                        value={formik?.values?.next_phone_no_three}
                        onChange={formik?.handleChange}
                        type="text"
                        onKeyPress={handleKeyPress}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      /> */}
                      <ReactInputMask
                        mask="(999) 999-9999"
                        value={formik?.values?.next_phone_no_three}
                        name={"next_phone_no_three"}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_phone_no_three &&
                          formik?.touched.next_phone_no_three
                            ? " is-invalid"
                            : "")
                        }
                        onChange={formik?.handleChange}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <span
                        className="input-group-text print-only-four-section  col-lg-3"
                        id="basic-addon3"
                      >
                        Relationship
                      </span>
                      <input
                        ref={fourtyfiveInputRef}
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_relationship_one &&
                          formik?.touched.next_relationship_one
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_relationship_one"}
                        value={formik?.values?.next_relationship_one}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        // onKeyDown={(e) => handleTabPress(e, fourtysixInputRef)} // No next input field to focus on
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_relationship_two &&
                          formik?.touched.next_relationship_two
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_relationship_two"}
                        value={formik?.values?.next_relationship_two}
                        onChange={formik?.handleChange}
                        type="text"
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                      <input
                        className={
                          "form-control print-only-four-section col-lg-3" +
                          (formik?.errors.next_relationship_three &&
                          formik?.touched.next_relationship_three
                            ? " is-invalid"
                            : "")
                        }
                        name={"next_relationship_three"}
                        value={formik?.values?.next_relationship_three}
                        onInput={(event) => {
                          // Prevent entering numbers
                          event.target.value = event.target.value.replace(
                            /[0-9]/g,
                            ""
                          );
                        }}
                        onChange={formik?.handleChange}
                        type="text"
                        autoComplete="new-password" // Disable autofill and autocomplete suggestions
                      />
                    </div>
                  </section>
                </div>
                <div
                  ref={listingRef3}
                  style={{
                    display:
                      activeTab === "3: Credit Information Agreement"
                        ? "flex"
                        : "none",
                    justifyContent: "center",
                  }}
                >
                  <section style={{ maxWidth: "88%", textAlign: "justify" }}>
                    <div>
                      <div className="d-flex justify-content-center">
                        <p className="credit-top">
                          Credit Information Agreement to Share & Investigate
                          for the Purpose of this Tenancy Application
                        </p>
                      </div>

                      <p>
                        The word “Information” means credit information,
                        personal information, information about the services you
                        use that are provided by the Landlord listed in Part (C)
                        and/or (D) below and information relating to your
                        tenancy at the Premises applied for in this application
                        including information regarding the duration of your
                        tenancy, monthly rent, emergency contacts and any
                        matters relating to your lease/tenancy agreement,
                        including misrepresentations relating to, defaults under
                        and/or breaches of your lease/tenancy agreement.
                      </p>
                      <p>
                        <span style={{ fontWeight: "600" }}>
                          “Credit Information”
                        </span>{" "}
                        means information about you, including your name, age,
                        date of birth, occupation, place of residence, previous
                        places of residence, occupancy length, marital status,
                        co-occupant’s/spouse’s/same-sex partner’s name and age,
                        number of dependents, particulars of education or
                        professional qualifications, places of employment,
                        previous places of employment, employment duration,
                        estimated income, paying habits, outstanding debt
                        obligations, cost of living obligations, involvement in
                        bankruptcy proceedings or landlord and tenant disputes,
                        assets, and banking information (including account and
                        credit card information).
                      </p>

                      <p>
                        <span style={{ fontWeight: "600" }}>
                          “Personal Information”{" "}
                        </span>{" "}
                        means information about you other than credit
                        information that is relevant to your suitability as a
                        tenant, including your social insurance number
                        (optional), driver’s license number, vehicle license
                        plate number, vehicle make and year, and information
                        from references which you provide about your character,
                        reputation, physical or personal characteristics or mode
                        of living or about any other matter concerning you that
                        is relevant to your suitability as a tenant.
                      </p>
                      <p>Collection, Use and Disclosure of Information:</p>
                      <p>
                        In consideration for the Landlord accepting you as a
                        tenant and entering into a lease/tenancy agreement with
                        you, you expressly consent to and authorize the
                        following:
                      </p>
                      <ol>
                        <li className="mb-3">
                          The Landlord may obtain Information about you through
                          a tenant check and/or credit or consumer report
                          conducted by Rent Check Credit Bureau or GateMaster
                          Inc./Tenchek or Equifax or Trans Union or any other
                          subsequent credit collection authority hereinafter
                          called the information facilitator and as permitted or
                          required by law. You expressly authorize the
                          information facilitator to provide Information
                          regarding you to the Landlord
                        </li>
                        <li className="mb-3">
                          The Landlord may use Information about you to
                          determine your suitability as a tenant and as
                          permitted or required by law.
                        </li>
                        <li className="mb-2">
                          The Landlord may disclose Information about you as
                          permitted or required by law and to any information
                          facilitator in order to be included within a database
                          of tenant information, and/or within a file on you,
                          for purposes of:
                        </li>
                        <ul className="mb-3">
                          <li>
                            tenant reporting and credit reporting in accordance
                            with the Consumer Reporting Act (Ontario);
                          </li>
                          <li>
                            establishing a credit history and a rental history
                          </li>
                          <li>
                            comparing with aggregate statistical data for
                            purposes of tenancy and credit scoring; and
                          </li>
                          <li>supporting the credit approval process.</li>
                          <li>
                            providing contact information to Bell, Rogers and
                            Metergy Toronto Hydro
                          </li>
                        </ul>
                        <li className="mb-3">
                          You expressly authorize the information facilitator to
                          retain Information regarding you indefinitely for the
                          purposes outlined in section 3 above, subject to any
                          applicable legal restrictions.
                        </li>
                        <li className="mb-3">
                          You expressly authorize the information facilitator to
                          disclose Information regarding you to its members and
                          subscribers as required or permitted by law and for
                          the purposes outlined in section 3 above
                        </li>
                        <li className="mb-3">
                          You agree that you will not withdraw your
                          authorization and consent to the collection, use and
                          disclosure of Information about you by the information
                          facilitator as outlined in sections 1 to 5 above.
                        </li>
                        <li className="mb-3">
                          You agree that all statements on this Residential
                          Rental Application are true and you expressly
                          authorize all references given to release information
                          about you to the Landlord for verification subject to
                          sections 1 to 5.
                        </li>
                      </ol>
                      <p className="credit-bottom">
                        I/We the undersigned applicant(s) hereby confirm the
                        “Information” section set out above in this Application
                        and by my/our signature, I/we have read and understood
                        all the conditions and agree to its terms and contents.
                      </p>
                    </div>
                    <div className="input-group mb-3 mt-3">
                      <span
                        className="input-group-text  col-lg-1"
                        id="basic-addon3"
                      >
                        Building
                      </span>
                      <input
                        className={"form-control col-lg-3"}
                        name={"credit_building"}
                        value={formik?.values?.building}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />

                      <span
                        className="input-group-text  col-lg-1"
                        id="basic-addon3"
                      >
                        Unit
                      </span>
                      <input
                        className={"form-control col-lg-3"}
                        name={"credit_unit"}
                        value={formik?.values?.suite}
                        onChange={formik?.handleChange}
                        type="text"
                        disabled
                      />
                      <span
                        className="input-group-text  col-lg-1"
                        id="basic-addon3"
                      >
                        Date
                      </span>
                      <DatePicker
                        className="form-control col-lg-2"
                        name="credit_date"
                        value={creditDate}
                        placeholder="credit_date"
                        onChange={handleCreditDate}
                      />
                    </div>

                    {/* signs */}
                    <div className="row">
                      <div className="col-lg-4">
                        <div
                          id="canvas_Wraper_creditTenent1"
                          className=" "
                          onLoad={() => getDimensionCreditTenent1()}
                        >
                          <div
                            className="mb-3 tenent-resolution"
                            style={{ position: "relative" }}
                          >
                            <SignatureCanvas
                              ref={(ref) => signatureHandlerCreditTenent1(ref)}
                              penColor="grey"
                              canvasProps={{
                                className: "canvasSignature",
                                width: 400,

                                // width: `${
                                //   divWidhCreditTenent1
                                //     ? divWidhCreditTenent1
                                //     : ""
                                // }`,
                                height: 150,
                              }}
                            />
                            <p
                              // canvasSignature
                              className=" text-center mt-3"
                              style={{
                                borderTop: "1px solid black",
                                fontWeight: "500",
                              }}
                            >
                              Tenant Signature{" "}
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </p>
                            <div
                              className="button-style-app"
                              style={{ marginLeft: "opx" }}
                              onClick={clearSignaturecreditTenant1}
                            >
                              Clear Signature
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        // className="col-lg-4 "
                        className={`col-lg-4 ${
                          fields && fields.length >= 2 ? "visible" : "invisible"
                        }`}
                      >
                        <div
                          id="canvas_Wraper_creditTenent2"
                          className=""
                          onLoad={() => getDimensionCreditTenent2()}
                        >
                          <div className="mb-3 tenent-resolution">
                            <SignatureCanvas
                              ref={(ref) => signatureHandlerCreditTenent2(ref)}
                              penColor="grey"
                              canvasProps={{
                                className: "canvasSignature",
                                // width: `${
                                //   divWidhCreditTenent2
                                //     ? divWidhCreditTenent2
                                //     : ""
                                // }`,
                                width: 400,

                                height: 150,
                              }}
                            />
                            {/* canvasSignature */}
                            <p
                              className=" text-center mt-3"
                              style={{
                                borderTop: "1px solid black",
                                fontWeight: "500",
                              }}
                            >
                              Tenant Signature
                            </p>
                            <div
                              className="button-style-app"
                              onClick={clearSignaturecreditTenant2}
                            >
                              Clear Signature
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`col-lg-4 ${
                          fields && fields.length === 3
                            ? "visible"
                            : "invisible"
                        }`}

                        // className="col-lg-4 "
                      >
                        <div
                          id="canvas_Wraper_creditTenent2"
                          className=""
                          onLoad={() => getDimensionCreditTenent3()}
                        >
                          <div className="mb-3 tenent-resolution">
                            <SignatureCanvas
                              ref={(ref) => signatureHandlerCreditTenent3(ref)}
                              penColor="grey"
                              canvasProps={{
                                className: "canvasSignature",
                                // width: `${
                                //   divWidhCreditTenent2
                                //     ? divWidhCreditTenent2
                                //     : ""
                                // }`,
                                width: 400,

                                height: 150,
                              }}
                            />
                            {/* canvasSignature */}
                            <p
                              className=" text-center mt-3"
                              style={{
                                borderTop: "1px solid black",
                                fontWeight: "500",
                              }}
                            >
                              Tenant Signature
                            </p>
                            <div
                              className="button-style-app"
                              onClick={clearSignaturecreditTenant3}
                            >
                              Clear Signature
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        background: "black",
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      Important Information: Do not sign unless you have read
                      and understood the conditions herein contained
                    </p>
                  </section>
                </div>
                <div
                  tab="4: No Cosmetic Repair Agreement"
                  key={"4: No Cosmetic Repair Agreement"}
                  style={{
                    display:
                      activeTab === "4: No Cosmetic Repair Agreement"
                        ? "flex"
                        : "none",
                    justifyContent: "center",
                  }}
                >
                  <div
                    ref={listingRef4}
                    style={{ maxWidth: "88%", textAlign: "justify" }}
                  >
                    <div className="d-flex justify-content-center">
                      <p className="credit-top">No Cosmetic Repair Agreement</p>
                    </div>

                    <p className="mb-4">
                      I/we, the undersigned prospective tenants acknowledge that
                      no promise of cosmetic repairs by the Landlord or its
                      superintendent were made to me verbally or given to me as
                      a condition of rental to carry out decorating repairs in
                      the apartment including but not limited to painting, floor
                      sanding, retiling of bathroom, counter top replacement,
                      cleaning of appliances, cleaning of apartment, removal of
                      wallpaper and removal of existing carpets. Any outstanding
                      repairs required under the Tenant protection Act or
                      successor legislation will be carried out after the tenant
                      has notified the Landlord in writing of the items
                      requiring repair on the forms supplied by the Landlord and
                      reasonable time will be allowed and agreed upon by both
                      parties to carry out these repairs after the tenant has
                      moved in, as trades become available.
                    </p>
                    <p className="mb-4">
                      No other contractual agreements have been made verbally or
                      otherwise as to form part of this tenancy agreement other
                      than those expressed in this form and in the application
                      for rental and the lease agreement. I unconditionally
                      accept possession of my suite on the first day of the
                      tenancy agreement or if the Landlord is unable to give
                      possession, then on the day the keys are made available to
                      me. This acceptance is made regardless of the condition of
                      the suite. I will not hold the Landlord responsible for
                      delays howsoever caused. I agree that no alterations or
                      decorating will be made during my tenancy without prior
                      written approval from the Landlord. Should I during the
                      term of my tenancy re-decorate or make alterations, I will
                      not hold the Landlord responsible to make good any
                      decorations to plaster repairs, howsoever caused, to match
                      tenant's existing alterations.
                    </p>
                    {/* <p>
                      Smoke and or CO Detector and Fire Equipment
                      Acknowledgement
                    </p> */}
                    <div className="d-flex justify-content-center">
                      <p className="credit-top">
                        Smoke and or CO Detector and Fire Equipment
                        Acknowledgement
                      </p>
                    </div>
                    <p className="mb-4">
                      We, the undersigned tenant(s) hereby verify after having
                      been shown that a smoke detector and CO detector if
                      applicable has been installed or is supplied in the suite
                      and is in proper working order as of today. I further
                      agree to notify the Landlord in the event that the smoke
                      detector stops functioning in any way that I am aware of.
                      I further agree that I shall not tamper or thwart its
                      functionality nor will I in any way hamper its proper use
                      by either removing it, cutting the wires supplying the
                      power, removing the battery. Further, the tenant hereby
                      verifies that a front entrance suite door closer has been
                      installed to the front suite door stated on the face of
                      this application. The Tenant hereby agrees not to remove
                      or disable the door closer. In the event that the door
                      closer has been removed or disabled by the tenant for
                      whatever reason, the tenant hereby assumes full
                      responsibility for such removal and will be held
                      responsible for the cost of replacement of the same and
                      any other damages the Landlord has incurred due to its
                      removal for fines and any other damages howsoever caused.
                    </p>
                    <p className="mb-4">
                      The Tenant hereby agrees not to remove or disable the horn
                      attached to the Fire Alarm System in the suite. In the
                      event that a speaker or horn attached to the Fire Alarm
                      System has been removed or disabled by the tenant for
                      whatever reason, the tenant hereby assumes full
                      responsibility for such removal or disabling in
                      contravention by such authorities who have jurisdiction
                      and will be held responsible for the cost of replacement
                      of the same and any other damages the Landlord has
                      incurred due to its removal for fines and any other
                      damages howsoever caused.
                    </p>

                    <p>
                      The Tenant hereby agrees not to remove, install or alter
                      the locking devices on his door without written consent
                      first. In the event that the tenant changes, removes,
                      alters the locking device on his suite front entrance
                      door, the tenant will be held responsible for the
                      replacement of the building suite lock and if a lock has
                      been added, then the tenant agrees to pay for the cost of
                      the replacement of the suite door including all material
                      and labor.
                    </p>

                    <div className="row mb-3">
                      <div
                        id="canvas_Wraper_smoke print-align"
                        className="d-flex canvasSignature col-lg-4"
                        onLoad={() => getDimensionSmoke()}
                        style={{
                          flexDirection: "column",
                          // alignItems: "end",
                          position: "relative",
                        }}
                      >
                        <div className="tenent-resolution print-only-half">
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentSmoke(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature",
                              // width: `${divWidthSmoke ? divWidthSmoke : ""}`,
                              width: 400,
                              height: 150,
                            }}
                          />
                          {/* canvasSignature */}
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <div
                            className="button-style-app"
                            onClick={clearSignatureTenentSmoke}
                            // style={{ marginLeft: "0px" }}
                          >
                            Clear Signature
                          </div>
                        </div>

                        {/* {cosmeticTenantView !== imageUrls && (
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            // height: "100%",
                            height: "auto",

                            bottom: "3rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={cosmeticTenantView}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              width: "auto",
                              height: "auto",
                            }}
                          />

                        </div>
                      )} */}

                        {/* <div className="canvasSignature mt-3 print-only-half">
                        <div className="form-group">
                          <Space direction="vertical">
                            <DatePicker
                              className="form-control"
                              name="smoke-date"
                              value={smokeDate}
                              placeholder="Date"
                              onChange={handleSmokeDate}
                            />
                          </Space>
                        </div>
                      </div> */}
                      </div>

                      {/* signature2 */}

                      <div
                        id="canvas_Wraper_smoke print-align"
                        // className="d-flex canvasSignature col-lg-4"
                        className={`"d-flex canvasSignature col-lg-4 ${
                          fields && fields.length >= 2 ? "visible" : "invisible"
                        }`}
                        onLoad={() => getDimensionSmoke()}
                        style={{
                          flexDirection: "column",
                          alignItems: "end",
                          position: "relative",
                        }}
                      >
                        <div className="tenent-resolution print-only-half">
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentSmoke2(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature",
                              width: 400,

                              height: 150,
                            }}
                          />
                          {/* canvasSignature */}
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <div
                            className="button-style-app"
                            onClick={clearSignatureTenentSmoke2}
                            // style={{ marginLeft: "0px" }}
                          >
                            Clear Signature
                          </div>
                        </div>
                      </div>

                      {/* signature ===> 3 */}

                      <div
                        id="canvas_Wraper_smoke print-align"
                        // className="d-flex canvasSignature col-lg-4"
                        className={`"d-flex canvasSignature col-lg-4 ${
                          fields && fields.length === 3
                            ? "visible"
                            : "invisible"
                        }`}
                        onLoad={() => getDimensionSmoke()}
                        style={{
                          flexDirection: "column",
                          alignItems: "end",
                          position: "relative",
                        }}
                      >
                        <div className="tenent-resolution print-only-half">
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentSmoke3(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature",
                              width: 400,

                              height: 150,
                            }}
                          />
                          {/* canvasSignature */}
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <div
                            className="button-style-app"
                            onClick={clearSignatureTenentSmoke3}
                            // style={{ marginLeft: "0px" }}
                          >
                            Clear Signature
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <p className="credit-top" style={{ marginTop: "1.5rem" }}>
                        Window Opening Limiter Agreement
                      </p>
                    </div>
                    <p className="mb-3">
                      I, the tenant, hereby request that the window limiter
                      device be installed in my suite. Should I remove it, I do
                      hereby accept responsibility for any problems or mishaps
                      or accidents that may arise due to my decision and save
                      the Landlord from harm, as a result of injury to myself,
                      members of my family, visitors wanted or unwanted as a
                      result of my not having the window opening limiters
                      installed. The screening is not designed nor meant to keep
                      children from falling out of the windows. I undertake not
                      to leave children unattended or leave anything that
                      children can climb onto and thereby increase the risk of
                      accidentally falling out of the window or off the balcony.
                    </p>

                    <div className="row">
                      <div
                        id="canvas_Wraper_limmiter"
                        className="d-flex canvasSignature col-lg-4"
                        onLoad={() => getDimensionLimmiter()}
                        style={{
                          flexDirection: "column",
                          // alignItems: "end",
                          position: "relative",
                        }}
                      >
                        <div className="tenent-resolution">
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentLimmiter(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature",
                              // width: `${
                              //   divWidthLimmiter ? divWidthLimmiter : ""
                              // }`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          {/* canvasSignature */}
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <div
                            className="button-style-app"
                            onClick={clearSignatureTenentLimmiter}
                            // style={{ marginLeft: "0px" }}
                          >
                            Clear Signature
                          </div>
                        </div>

                        {/* {limmterTenantView !== imageUrls && (
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            // height: "100%",
                            height: "auto",
                            top: "4rem",
                            // bottom: "3rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={limmterTenantView}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              width: "auto",
                              height: "auto",
                            }}
                          />
          
                        </div>
                      )} */}
                        {/* <div
                            className="cross-button"
                            onClick={handleCrossClickLimmiter}
                            style={{
                              position: "absolute",
                              top: "33px",
                              right: 0,
                              background: "none",
                              border: "none",
                            }}
                          >
                            &#10005;
                          </div> */}

                        <div className=" mt-3">
                          <div className="form-group">
                            <Space direction="vertical">
                              <DatePicker
                                className="form-control"
                                name="limmiter-date"
                                value={limmiterDate}
                                placeholder="Date"
                                onChange={handleLimmiterDate}
                              />
                            </Space>
                          </div>
                        </div>
                      </div>
                      {/* sigbnature 2 */}

                      <div
                        id="canvas_Wraper_limmiter"
                        // className="d-flex canvasSignature col-lg-4"
                        className={`d-flex canvasSignature col-lg-4 print-only ${
                          fields && fields.length >= 2 ? "visible" : "invisible"
                        }`}
                        onLoad={() => getDimensionLimmiter()}
                        style={{
                          flexDirection: "column",
                          // alignItems: "end",
                          position: "relative",
                        }}
                      >
                        <div className="tenent-resolution">
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentLimmiter2(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature",
                              // width: `${
                              //   divWidthLimmiter ? divWidthLimmiter : ""
                              // }`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          <p
                            className="canvasSignature text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>

                          <div
                            className="button-style-app"
                            onClick={clearSignatureTenentLimmiter2}
                            // style={{ marginLeft: "0px" }}
                          >
                            Clear Signature
                          </div>
                        </div>

                        <div className=" mt-3">
                          <div className="form-group">
                            <Space direction="vertical">
                              <DatePicker
                                className="form-control"
                                name="limmiter-date"
                                value={limmiterDate2}
                                placeholder="Date"
                                onChange={handleLimmiterDate2}
                              />
                            </Space>
                          </div>
                        </div>
                      </div>
                      {/* === SIGNATURE 3 == */}

                      <div
                        id="canvas_Wraper_limmiter"
                        // className="d-flex canvasSignature col-lg-4"
                        className={`"d-flex canvasSignature col-lg-4 ${
                          fields && fields.length === 3
                            ? "visible"
                            : "invisible"
                        }`}
                        onLoad={() => getDimensionLimmiter()}
                        style={{
                          flexDirection: "column",
                          // alignItems: "end",
                          position: "relative",
                        }}
                      >
                        <div className="tenent-resolution">
                          <SignatureCanvas
                            ref={(ref) => signatureHandlerTenentLimmiter3(ref)}
                            penColor="grey"
                            canvasProps={{
                              className: "canvasSignature",
                              // width: `${
                              //   divWidthLimmiter ? divWidthLimmiter : ""
                              // }`,
                              width: 400,

                              height: 150,
                            }}
                          />
                          {/* canvasSignature */}
                          <p
                            className=" text-center mt-3"
                            style={{
                              borderTop: "1px solid black",
                              fontWeight: "500",
                            }}
                          >
                            Tenant Signature
                          </p>
                          <div
                            className="button-style-app"
                            onClick={clearSignatureTenentLimmiter3}
                          >
                            Clear Signature
                          </div>
                        </div>
                        {/* canvasSignature */}
                        <div className=" mt-3">
                          <div className="form-group">
                            <Space direction="vertical">
                              <DatePicker
                                className="form-control"
                                name="limmiter-date"
                                value={limmiterDate3}
                                placeholder="Date"
                                onChange={handleLimmiterDate3}
                              />
                            </Space>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  tab="5: Upload Files"
                  key={"5: Upload Files"}
                  style={{
                    display: activeTab === "5: Upload Files" ? "block" : "none",
                  }}
                >
                  <section>
                    <p
                      style={{
                        textAlign: "center",
                        marginBottom: "2rem ",
                        fontWeight: "700",
                      }}
                    >
                      Your data is secured using latest Amazon security
                    </p>

                    <div className="row" style={{ alignItems: "start" }}>
                      <div
                        className="align-items-center col-lg-6 d-flex flex-column justify-content-start"
                        style={{ paddingTop: "0.4rem" }}
                      >
                        <div style={{ minHeight: "9.3rem" }}>
                          <p style={{ fontWeight: "700", marginTop: "10px" }}>
                            1: Upload 1 Piece of your Government Identification
                          </p>
                          <p style={{ fontWeight: "700", marginBottom: "0px" }}>
                            {" "}
                            Acceptable Government identification:
                          </p>
                          <ul
                            style={{ fontWeight: "500", paddingLeft: "18px" }}
                          >
                            <li>Drivers License</li>
                            <li>Passport</li>
                            <li>
                              Citizenship or Immigration Status Certificate with
                              photo
                            </li>
                            <p style={{ marginTop: "5px", fontWeight: "600" }}>
                              Upload Limit 20 items
                            </p>
                          </ul>
                        </div>
                        <div
                          className=" col-lg-6 d-flex flex-column justify-content-center image-control"
                          style={{
                            // paddingBottom:"2rem",

                            paddingTop: "1.5rem",
                            paddingLeft: "23px  ",
                          }}
                        >
                          <label
                            style={{
                              padding: "7px 10px 7px 10px",
                              border: "1px solid lightgray",
                              cursor: "pointer",
                              width: "9rem",
                            }}
                            for="fileUpload"
                          >
                            Upload a Files {selectedImage?.length}
                          </label>

                          <input
                            type="file"
                            onChange={handleImageChange}
                            accept="image/*, application/pdf, video/*"
                            multiple
                            id="fileUpload"
                            style={{ display: "none" }}
                          />
                          <p style={{}}>
                            {uploadLoadingImg ? (
                              <p>Uploading...</p>
                            ) : (
                              selectedImage?.map((item, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "relative",
                                  }}
                                >
                                  <p
                                    title={item}
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width: "11rem",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {" "}
                                    {item?.length > 10
                                        ? `${item.substring(0, 10)}.` +
                                          getExtension(item)
                                        : item}
                                    {/* {item.length > 50
                                      ? `${item.substring(0, 20)}...`
                                      : item} */}
                                  </p>
                                  <div
                                    onClick={() => handleRemoveImagess(index)} // Assuming you have a function handleDelete to remove an item by index
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      // right: 0,
                                      left: "15rem",
                                      background: "none",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                    >
                                      <path d="M19 5h-4V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1H5a1 1 0 0 0 0 2h1v13a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7h1a1 1 0 0 0 0-2zM9 5h6v1H9zM17 20H7V7h10z" />
                                      <path d="M10 9a1 1 0 0 0 1 1h1a1 1 0 0 0 0-2h-1a1 1 0 0 0-1 1zM13 9a1 1 0 0 0 1 1h1a1 1 0 0 0 0-2h-1a1 1 0 0 0-1 1z" />
                                    </svg>
                                  </div>
                                </div>
                              ))
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-6 d-flex flex-column justify-content-center image-control">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // paddingRight:"7rem",
                            paddingTop: "7px",
                            paddingLeft: "5rem",
                            // alignItems: "flex-start",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "700",
                              textAlign: "center",
                              paddingRight: "8rem",
                              paddingTop: "10px",
                            }}
                          >
                            2: Upload Proof of Employment (Job Letter)
                          </p>
                          <div>
                            <p
                              style={{
                                fontWeight: "700",
                                paddingRight: "12rem",
                                marginBottom: "0px",
                                textAlign: "center",
                              }}
                            >
                              Acceptable Proof of Employment:
                            </p>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  // marginBottom: "4rem ",
                                  fontWeight: "500",
                                }}
                              >
                                <ul style={{ marginBottom: "0px" }}>
                                  <li>
                                    Two most recent paystubs and recent job
                                    income letter
                                  </li>
                                  <li>
                                    A recent T4, not older than last year’s
                                  </li>{" "}
                                  <li>
                                    The most recent tax return if self-employed
                                  </li>{" "}
                                  <li>
                                    A letter from your bank with banking
                                    information and summary
                                  </li>
                                  <p
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "0px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Upload Limit 20 items
                                  </p>
                                </ul>
                              </div>
                              <div
                                className="mt-3  "
                                style={{
                                  paddingLeft: "11rem",
                                  // alignItems: "center",
                                  // display:"flow",
                                  // marginLeft: "5rem",

                                  // , paddingRight: selectedFile ? "0px" : "7rem"
                                }}
                              >
                                <div>
                                  <label
                                    style={{
                                      padding: "7px 10px 7px 10px",
                                      border: "1px solid lightgray",
                                      cursor: "pointer",
                                      width: "9rem",
                                    }}
                                    for="fileUploadss"
                                  >
                                    Upload a Files {selectedFile?.length}
                                  </label>

                                  <input
                                    type="file"
                                    onChange={handleFileChange}
                                    accept="image/*, application/pdf, video/*"
                                    multiple
                                    id="fileUploadss"
                                    style={{ display: "none" }}
                                  />

                                  <p style={{ marginTop: "1rem" }}>
                                    {uploadLoading ? (
                                      <p>Uploading...</p>
                                    ) : (
                                      selectedFile?.map((item, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            position: "relative",
                                          }}
                                        >
                                          {/* <p style={{ width: "30rem" }}>{item}</p> */}
                                          <p
                                            title={item}
                                            style={{
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              width: "11rem",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {" "}
                                            {item?.length > 10
                                        ? `${item.substring(0, 10)}.` +
                                          getExtension(item)
                                        : item}
                                            {/* {item.length > 50
                                              ? `${item.substring(0, 20)}...`
                                              : item} */}
                                          </p>
                                          <div
                                            onClick={() =>
                                              handleRemoveFilesss(index)
                                            } // Assuming you have a function handleDelete to remove an item by index
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              // right: 0,
                                              left: "15rem",
                                              background: "none",
                                              border: "none",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              width="24"
                                              height="24"
                                            >
                                              <path d="M19 5h-4V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1H5a1 1 0 0 0 0 2h1v13a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7h1a1 1 0 0 0 0-2zM9 5h6v1H9zM17 20H7V7h10z" />
                                              <path d="M10 9a1 1 0 0 0 1 1h1a1 1 0 0 0 0-2h-1a1 1 0 0 0-1 1zM13 9a1 1 0 0 0 1 1h1a1 1 0 0 0 0-2h-1a1 1 0 0 0-1 1z" />
                                            </svg>
                                          </div>
                                        </div>
                                      ))
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row" style={{ marginBottom: "3rem" }}></div> */}
                  </section>
                </div>
              </div>

              {applicationID ? (
                <div
                  className="d-flex justify-content-center mt-3"
                  style={{ gap: "2rem", flexWrap: "wrap" }}
                >
                  <div
                    className="button-effects "
                    style={{
                      padding: "1rem 2rem 1rem 2rem",
                      border: "1px solid #d97228",
                      cursor: "pointer",
                      borderRadius: "10PX",
                    }}
                    onClick={Update}
                  >
                    Update/Draft
                  </div>

                  <div
                    className="button-effects "
                    style={{
                      padding: "1rem 2rem 1rem 2rem",
                      border: "1px solid #d97228",
                      cursor: "pointer",
                      borderRadius: "10PX",
                      background: "none",
                    }}
                    onClick={UpdateSubmit}
                  >
                    Submit
                  </div>

                  <div style={{ width: "9rem", display: "none" }}>
                    <button
                      className="myBtn bdr"
                      style={{ width: "100%" }}
                      type="button"
                      onClick={() => {
                        // handlePrint();

                        setShowLogo(true);
                        setTimeout(() => {
                          refsss();
                        }, 500);
                      }}
                    >
                      <div style={{ marginRight: "2px" }}>
                        <Print />
                      </div>
                      Print
                    </button>
                  </div>
                  {/* <div
                  className="button-effects "
                  style={{
                    padding: "1rem 2rem 1rem 2rem",
                    border: "1px solid #d97228",
                    cursor: "pointer",
                    borderRadius: "10PX",
                  }}
                  onClick={() => {
                    window.print();
                  }}
                >
                  Print
                </div> */}
                </div>
              ) : (
                statuse != "SUBMITTED" && (
                  <div
                    className="d-flex justify-content-center mt-3"
                    style={{ gap: "2rem", flexWrap: "wrap" }}
                  >
                    <div
                      className="button-effects "
                      style={{
                        padding: "1rem 2rem 1rem 2rem",
                        border: "1px solid #d97228",
                        cursor: "pointer",
                        borderRadius: "10PX",
                      }}
                      onClick={() => {
                        DraftHandling();
                        setTimeout(() => {
                          toast.success("Application Saved Succefully");
                          // setSignatureSaved(true)
                        }, 300);
                      }}
                    >
                      Save/Draft
                    </div>

                    {activeTab == "5: Upload Files" && (
                      <button
                        className="button-effects "
                        type="submit"
                        style={{
                          padding: "1rem 2rem 1rem 2rem",
                          border: "1px solid #d97228",
                          cursor: "pointer",
                          borderRadius: "10PX",
                          background: "none",
                        }}
                        // onClick={formik?.handleSubmit}
                      >
                        Submit
                      </button>
                    )}
                    {activeTab == "1: Application" ? (
                      ""
                    ) : (
                      <div
                        className="button-effects "
                        style={{
                          padding: "1rem 2rem 1rem 2rem",
                          border: "1px solid #d97228",
                          cursor: "pointer",
                          borderRadius: "10PX",
                        }}
                        onClick={handleBack}
                      >
                        Back
                      </div>
                    )}

                    {activeTab == "5: Upload Files" ? (
                      ""
                    ) : (
                      <div
                        // type="submit"
                        className="div-effects "
                        style={{
                          padding: "1rem 2rem 1rem 2rem",
                          border: "1px solid #d97228",
                          cursor: "pointer",
                          borderRadius: "10PX",
                        }}
                        onClick={handleNext}
                      >
                        Next
                      </div>
                      // <div className="button-effects" style={{
                      //   padding: "1rem 2rem 1rem 2rem",
                      //   border: "1px solid #d97228",
                      //   cursor: "pointer",
                      //   borderRadius: "10px",
                      //   opacity: isCurrentSectionComplete && !formik?.errors.home_phone_no_one &&
                      //            !formik?.errors.home_phone_no_two && !formik?.errors.home_phone_no_three
                      //     ? 1 : 0.5,
                      // }} onClick={isCurrentSectionComplete && !formik?.errors.home_phone_no_one &&
                      //            !formik?.errors.home_phone_no_two && !formik?.errors.home_phone_no_three
                      //   ? handleNext : undefined}>
                      //   Next
                      // </div>
                    )}

                    <div style={{ width: "9rem", display: "none" }}>
                      <button
                        className="myBtn bdr"
                        style={{ width: "100%" }}
                        type="button"
                        onClick={() => {
                          // handlePrint();

                          setShowLogo(true);
                          setTimeout(() => {
                            refsss();
                          }, 500);
                        }}
                      >
                        <div style={{ marginRight: "2px" }}>
                          <Print />
                        </div>
                        Print
                      </button>
                    </div>
                  </div>
                )
              )}
            </form>
          </div>
        </section>
      </>
    </>
  );
};
export default Application;
