import React, { useState, useRef } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 100,
  height: 100,
  facingMode: "user",
  // facingMode: { exact: "environment" },
};

export default function WebcamCapture() {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    console.log(imageSrc);
  }, [webcamRef, setImgSrc]);
  return (
    <div className="webcamCapture">
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <button className="btnnnn" onClick={capture}>
        Capture photo00 {imgSrc !== null ? "has value" : "doesnt have value"}
      </button>
      {imgSrc && <img className="imgggg" src={imgSrc} />}
    </div>
  );
}
