import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Index({
  className,
  value,
  onChange,
  name,
  onlyCountries,
  ...otherProps
}) {
  return (
    <PhoneInput
      className={className}
      disableCountryCode={true}
      country="ca"
      onlyCountries={onlyCountries}
      placeholder=""
      value={value}
      onChange={(value) => {
        onChange(value);
      }}
      name={name}
    />
  );
}
