import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { Modal, Button } from "react-bootstrap";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  adminApiCall,
  capatalizeLetters,
  getDate,
} from "../../../helpers/ajax_request";
import WorkOrderCompletion from "../../../components/WOCompletion/WorkOrderCompletion";

export default function Index() {
  const params = useParams();
  const workOrderID = params.id;
  // console.log(workOrderID);

  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [markedCompletedModal, setmMarkedCompletedModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [workOrderDetails, setWorkOrderDetails] = useState([]);
  const [marketComplete, setMarketComplete] = useState(false);
  const [requireTrade, setRequireTrade] = useState(false);
  const [markedCompleteArray, setmarkedCompleteArray] = useState([]);
  const [requireTradeArray, setrequireTradeArray] = useState([]);
  const [comment, setComment] = useState("");
  const [showCommentArray, setShowCommentArray] = useState([]);
  const [itemID, setItemID] = useState("");
  const [markResolvedEnableBtn, setMarkResolvedEnableBtn] = useState([]);
  const [enableDisableBtn, setEnableDisableBtn] = useState(false);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  useEffect(() => {
    getWorkOrder();
  }, []);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          {/* <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          <h4>Work Order Status changed to Resolved!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="myBtn bdr"
            onClick={() => {
              setmMarkedCompletedModal(false);
              navigate(PATH.WORK_ORDER);
            }}
          >
            Ok
          </Button>
          <Button
            className="myBtn "
            onClick={() => {
              setmMarkedCompletedModal(false);
              // navigate(PATH.WORK_ORDER);
            }}
          >
            View
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const getWorkOrder = async () => {
    let data = {
      work_order_id: workOrderID,
    };
    let res = await adminApiCall(
      "/api/admin/work_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      setLoader(false);
      setNoRecord(false);
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setWorkOrderDetails(res.data);
        setRequireTrade(res.data?.items[0]?.require_trade);
        setMarketComplete(res.data?.items[0]?.is_complete);
        setShowCommentArray(res.data?.comments);
        setLoader(false);
        setNoRecord(false);

        let tempArray = [];
        res.data?.items.map((v, i) => {
          if (v.is_complete === true) {
            tempArray.push(v);
          } else {
          }

          setMarkResolvedEnableBtn(tempArray);
          // console.log("markResolvedEnableBtnnnnnnnn: ", tempArray);
          // console.log(res.data?.items);
          if (tempArray.length === res.data?.items?.length) {
            // console.log("length if working enable = true");
            setEnableDisableBtn(true);
            // setmMarkedCompletedModal(true);
          } else {
            // console.log("length else working enable = false");
            setEnableDisableBtn(false);
          }
        });
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const markCompletedOnChange = (e, itemID) => {
    setMarketComplete(e.target.checked);
    let tempArray = [...markedCompleteArray];
    if (e.target.checked) {
      setModalShow(true);
      tempArray.push(itemID);
      // console.log(tempArray);
    } else {
      tempArray.splice(tempArray.indexOf(itemID), 1);
      // console.log(tempArray);
    }
    setmarkedCompleteArray(tempArray);
  };

  const requireTradeOnChange = async (e, itemID) => {
    setRequireTrade(e.target.checked);
    let tempRequireArray = [...requireTradeArray];
    if (e.target.checked) {
      // console.log("if working");

      tempRequireArray.push(itemID);
      // console.log(tempRequireArray);
      let data = {
        work_order_id: workOrderID,
        item_id: itemID,
        is_complete: false,
        require_trade: true,
      };
      let res = await adminApiCall(
        "/api/admin/work_order/update_items",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("Item Has Been Sent To Require Trades!");
          getWorkOrder();
          // navigate(PATH.WORK_ORDER);
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    } else {
      // console.log("else working");
      tempRequireArray.splice(tempRequireArray.indexOf(itemID), 1);
      // console.log(tempRequireArray);
      let data = {
        work_order_id: workOrderID,
        item_id: itemID,
        is_complete: false,
        require_trade: false,
      };
      let res = await adminApiCall(
        "/api/admin/work_order/update_items",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("Item Has Been Removed From Require Trades!");
          getWorkOrder();
          // navigate(PATH.WORK_ORDER);
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
    setrequireTradeArray(tempRequireArray);
  };

  const commentOnChangeHandler = (e) => {
    setComment(e.target.value);
    // console.log(e.target.value);
  };

  const addComment = async () => {
    let data = {
      work_order_id: workOrderID,
      comment: { comment: comment },
    };
    let res = await adminApiCall(
      "/api/admin/work_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setComment("");
        getWorkOrder();
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  // 621713f0c597411c237f7e08

  const onHideModal = () => {
    let tempArray = [...markedCompleteArray];
    // console.log(tempArray);
    // console.log(itemID);
    // console.log(hasValue);
    if (itemID) {
      // console.log("if working");
      tempArray.splice(tempArray.indexOf(itemID), 1);
      // console.log(tempArray);
    } else {
      // console.log("else working");
    }

    setmarkedCompleteArray(tempArray);
    setModalShow(false);
  };

  const markedResolvedHandler = async () => {
    let data = {
      work_order_id: workOrderID,
      status: true,
    };
    let res = await adminApiCall(
      "/api/admin/work_order/change_order_status",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Work Order Has Been Marked Resolved!");
        setEnableDisableBtn(true);
        navigate(PATH.WORK_ORDER);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };
  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="contentCard ">
          <div className="titleWrapper">
            <span>Work Order Completion</span>
          </div>
          {loader ? (
            <div className="text-center">
              <img src={Loader} alt="" />
            </div>
          ) : noRecord ? (
            <div className="text-center">
              <img src={NoRecordFound} alt="" />
            </div>
          ) : (
            <div className="mainTable table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ maxWidth: "50px" }}>Item #</th>
                    <th style={{ maxWidth: "300px" }}>Specific Items</th>
                    <th style={{ minWidth: "150px" }}>Completed</th>
                    <th style={{ minWidth: "150px", textAlign: "center" }}>
                      Require Trades
                    </th>
                    <th style={{ minWidth: "200px" }}>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {workOrderDetails &&
                    workOrderDetails?.items?.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <b>{v.item}</b>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              className="customRadio"
                              id={v._id}
                              value={marketComplete}
                              checked={v.is_complete ? "checked" : false}
                              onClick={() => {
                                setItemID(v._id);
                              }}
                              onChange={(e) => markCompletedOnChange(e, v._id)}
                              // disabled={v.is_complete ? true : false}
                            />
                            {v?.item_repair_description[0]?.completed_at ? (
                              <small className="">
                                {" "}
                                (
                                {getDate(
                                  v?.item_repair_description[0]?.completed_at
                                )}
                                ){" "}
                              </small>
                            ) : (
                              ""
                            )}
                          </td>
                          <td style={{ textAlign: "center", paddingLeft: "" }}>
                            <input
                              type="checkbox"
                              className="customRadio"
                              value={requireTrade}
                              id={v._id}
                              checked={v.require_trade ? "checked" : false}
                              onChange={(e) => requireTradeOnChange(e, v._id)}
                              // disabled={v.is_complete ? true : false}
                            />
                            <WorkOrderCompletion
                              show={modalShow}
                              onHide={() => onHideModal()}
                              setmodalshow={setModalShow}
                              workorderid={workOrderID}
                              marketcomplete={marketComplete?.toString()}
                              requiretrade={requireTrade?.toString()}
                              setrequiretrade={setRequireTrade}
                              markedcompletearray={markedCompleteArray}
                              setmarkedcompletearray={setmarkedCompleteArray}
                              getWorkOrder={getWorkOrder}
                              itemID={itemID}
                              setItemID={setItemID}
                            />
                            <MyVerticallyCenteredModal
                              show={markedCompletedModal}
                              onHide={() => setmMarkedCompletedModal(false)}
                            />
                          </td>
                          <td>
                            {" "}
                            <p>
                              {" "}
                              {v?.item_repair_description[0]?.description
                                ? v?.item_repair_description[0]?.description
                                : ""}
                            </p>{" "}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
          <div className="formDesign">
            <div className="row align-items-end">
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="form-group">
                  <label>Comments</label>
                  <textarea
                    className="form-control"
                    rows="5"
                    onChange={(e) => commentOnChangeHandler(e)}
                    value={comment}
                  ></textarea>
                </div>
              </div>
              <div className="col-12 ">
                <button
                  className="myBtn success mb-3"
                  onClick={() => {
                    addComment();
                    // console.log("abc");
                  }}
                  disabled={comment ? false : true}
                >
                  Add Comment
                </button>
              </div>
            </div>
          </div>
          {showCommentArray.length > 0 ? (
            <div className=" commentWrapper mt-3">
              <div className="commentLogs">
                {showCommentArray.map((v, i) => {
                  return (
                    <div key={i} className="comment">
                      <h2>
                        {capatalizeLetters(v.user_name)}{" "}
                        <span>{getDate(v.created_at)}</span>
                      </h2>
                      <p>{v.comment}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="cardFooter">
            <button
              // className="myBtn info mr-1"
              className={`${
                !enableDisableBtn ||
                workOrderDetails.order_status === "COMPLETED"
                  ? "myBtn info mr-1"
                  : "myBtn success mr-1"
              }`}
              style={
                !enableDisableBtn ||
                workOrderDetails.order_status === "COMPLETED"
                  ? { cursor: "default" }
                  : { cursor: "pointer" }
              }
              to="#"
              onClick={() => {
                // console.log("marked resolved!");
                markedResolvedHandler();
              }}
              disabled={
                !enableDisableBtn ||
                workOrderDetails.order_status === "COMPLETED"
                  ? // ? (true, console.log("true runs"))
                    // : (false, console.log("false runs"))
                    true //!enabledisablebtn value = false then disable becomes true
                  : false //!enabledisablebtn value = true then disable becomes false
              }
            >
              Marked Resolved
            </button>
            <Link className="myBtn bdr" to="/workOrder">
              Go Back
            </Link>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
