import React, { useEffect, useState } from "react";
import { Route, Navigate, Routes, Outlet } from "react-router-dom";
const AuthenticatedGuard = () => {
  const [auth, setAuth] = useState(true);
  const [isTokenValidated, setIsTokenValidated] = useState(true);
  // console.log('inside private route')
  // console.log(rest)
  // console.log(props)
  // useEffect(() => {
  //     let token = localStorage.getItem('token')
  //     if (token) {
  //         fetch(process.env.REACT_APP_ADMIN_LOGIN_API+'auth' , {
  //             method: "POST",
  //             headers: {
  //                 'Accept': 'application/json',
  //                 'Content-Type': 'application/json'
  //             },
  //             body: JSON.stringify({ token })
  //         })
  //             .then((res) => {
  //                 return res.json()
  //             })
  //             .then((json) => {
  //
  //                 if (json.status === 200) {
  //                     setAuth(true);
  //                 }else{
  //                     setAuth(false);
  //                 }
  //             })
  //             .catch((err) => {
  //                 setAuth(false);
  //                 localStorage.removeItem("token");
  //             })
  //             .then(() => setIsTokenValidated(true));
  //
  //     }
  //     else {
  //         setIsTokenValidated(true); // in case there is no token
  //     }
  //
  // }, [auth]);
  if (!isTokenValidated) return <Navigate to="/" />; // or some kind of loading animation
  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default AuthenticatedGuard;
