import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { DatePicker, Space } from "antd";
import { Modal, Button } from "react-bootstrap";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "react-datepicker/dist/react-datepicker.css";
import { File, Search, Delete2, Print } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import Logosm from "../../../assets/images/logo.png";
import { PATH } from "../../../constants/paths";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  adminApiCall,
  capatalizeLetters,
  userAccessibility,
  getDate,
  getDateOnly,
} from "../../../helpers/ajax_request";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import ReactPaginate from "react-paginate";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import SpecificSearch from "../../../components/SpecificSearch/Index";
import Swal from "sweetalert2";

export default function Index() {
  const buildingName = localStorage.getItem("building_namee");
  const { RangePicker } = DatePicker;
  const [purchaseOrderID, setpurchaseOrderID] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [reports, setReports] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [perPage, setPerPage] = useState(50);
  const [showLogo, setShowLogo] = useState(false);

  const [printPO, setPrintPO] = useState(false);
  const [printMS, setPrintMS] = useState(false);

  const printTablePurchase = useRef(null);
  const printWCPurchase = useRef(null);
  const printTableMaintenance = useRef(null);
  const printWCMaintenance = useRef(null);
  // purchase
  const pOPrintPurchase = useReactToPrint({
    content: () => printTablePurchase.current,
  });
  const wCPrintPurchase = useReactToPrint({
    content: () => printWCPurchase.current,
  });
  // maintenance
  const pOPrintMaintenance = useReactToPrint({
    content: () => printTableMaintenance.current,
  });
  const wCPrintMaintenance = useReactToPrint({
    content: () => printWCMaintenance.current,
  });

  const [POReport, setPOReport] = useState({
    searchValue: "",
    dateRange: ["", ""],
    typeOrder: "purchase_parts",
    buildingID: [],
  });

  const format = "MM/DD/YYYY";
  const [options, setOptions] = useState([]);
  const [orderType, setOrderType] = useState([
    { label: "Purchase Parts", value: "purchase_parts" },
    { label: "Maintenance Services", value: "maintenance_service" },
  ]);
  const [searchScope, setSearchScope] = useState("global_search");

  const [purchaseOrderIDSort, setPurchaseOrderIDSort] = useState(false);
  const [descriptionSort, setDescriptionSort] = useState(false);
  const [orderTypeSort, setOrderTypeSort] = useState(false);
  const [orderRequestedAtSort, setOrderRequestedAtSort] = useState(false);
  const [orderStateSort, setOrderStateSort] = useState(false);
  const [orderStatusSort, setOrderStatusSort] = useState(false);
  const [createdAtSort, setCreatedAtSort] = useState(false);
  const [buildingIDSort, setBuildingIDSort] = useState(false);
  const [addressSort, setAddressSort] = useState(false);
  const [categorySort, setCategorySort] = useState(false);
  const [modelSort, setModelSort] = useState(false);
  const [submitterSort, setSubmitterSort] = useState(false);
  const [sortValue, setSortValue] = useState("0");

  const componentRef = useRef();

  useEffect(() => {
    getBuildingNames();
  }, []);

  useEffect(() => {
    listPOReport();
  }, [
    currentPage,
    POReport.searchValue,
    POReport.dateRange,
    POReport.typeOrder,
    POReport.buildingID,
    purchaseOrderIDSort,
    descriptionSort,
    orderTypeSort,
    orderRequestedAtSort,
    orderStateSort,
    orderStatusSort,
    createdAtSort,
    buildingIDSort,
    addressSort,
    categorySort,
    modelSort,
    submitterSort,
  ]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setShowLogo(false);
    },
  });

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const pOIDSorting = () => {
    setPurchaseOrderIDSort(!purchaseOrderIDSort);
    setSortValue("1");
  };

  const descriptionSorting = () => {
    setDescriptionSort(!descriptionSort);
    setSortValue("2");
  };

  const orderTypeSorting = () => {
    setOrderTypeSort(!orderTypeSort);
    setSortValue("3");
  };

  const orderRequestedSorting = () => {
    setOrderRequestedAtSort(!orderRequestedAtSort);
    setSortValue("4");
  };

  const orderStateSorting = () => {
    setOrderStateSort(!orderStateSort);
    setSortValue("5");
  };

  const orderStatusSorting = () => {
    setOrderStatusSort(!orderStatusSort);
    setSortValue("6");
  };

  const createdAtSorting = () => {
    setCreatedAtSort(!createdAtSort);
    setSortValue("7");
  };

  const buildingSorting = () => {
    setBuildingIDSort(!buildingIDSort);
    setSortValue("8");
  };

  const addressSorting = () => {
    setAddressSort(!addressSort);
    setSortValue("9");
  };

  const categorySorting = () => {
    setCategorySort(!categorySort);
    setSortValue("10");
  };

  const modelSorting = () => {
    setModelSort(!modelSort);
    setSortValue("11");
  };

  const submitterSorting = () => {
    setSubmitterSort(!submitterSort);
    setSortValue("12");
  };

  const listPOReport = async () => {
    let data = {
      current_page: POReport.buildingID === [] ? currentPage : currentPage,
      itemsPerPage: perPage,
      date_start_of:
        POReport.dateRange[0] === ""
          ? POReport.dateRange[0]
          : POReport.dateRange[0].format("MM/DD/YYYY"),
      date_end_of:
        POReport.dateRange[1] === ""
          ? POReport.dateRange[1]
          : POReport.dateRange[1].format("MM/DD/YYYY"),
      search_string: POReport.searchValue === "" ? "" : POReport.searchValue,
      order_type: POReport.typeOrder,
      search_report_scope: searchScope,
      building_id: POReport.buildingID,

      ...(sortValue === "8"
        ? {
            sort_by_building_id: buildingIDSort,
          }
        : sortValue === "9"
        ? {
            sort_by_address: addressSort,
          }
        : sortValue === "10"
        ? {
            sort_by_category: categorySort,
          }
        : sortValue === "11"
        ? {
            sort_by_model: modelSort,
          }
        : sortValue === "12"
        ? {
            sort_by_submitter: submitterSort,
          }
        : sortValue === "1"
        ? {
            sort_by_purchase_order_id: purchaseOrderIDSort,
          }
        : sortValue === "2"
        ? {
            sort_by_description: descriptionSort,
          }
        : sortValue === "3"
        ? {
            sort_by_order_type: orderTypeSort,
          }
        : sortValue === "7"
        ? {
            sort_by_created_at: createdAtSort,
          }
        : ""),

      // sort_by_order_requested_at: false,
      // sort_by_order_state: false,
      // sort_by_order_status: false,
    };

    // console.log("data: ", data);

    let res = await adminApiCall(
      "/api/admin/reports/po_report/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (localStorage.getItem("building_id") === null) {
        notifyError("Please Select a Building First");

        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 200) {
        // console.log("resssss: ", res);
        setReports(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        // notifyError(res.message);
        // console.log(res.message);

        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const onchange = async (date, dateString) => {
    // console.log(date);
    let tempObj = {
      ...POReport,
      dateRange: date === null ? ["", ""] : date,
    };
    // console.log(tempObj);
    setPOReport(tempObj);
  };

  const getBuildingNames = async () => {
    let res = await adminApiCall("/api/admin/user/assign_buildings", "GET");
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log(res);
        setOptions(res.data);
        // console.log("res select building", res);
        let tempArray = [];
        for (let i = 0; i < res.data.length; i++) {
          tempArray.push({
            name: res.data[i].property_id,
            _id: res.data[i]._id,
            building_id: res.data[i].building_id,
            address: res.data[i].street,
            noOfSuite: res.data[i].no_of_suite,
            availableSuite: res.data[i].available_suite,
            nameOfBuilding: res.data[i].building_name,
          });
        }
        // console.log(tempArray);
        // setOptions(tempArray);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const handleSearch = async (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let tempObj = {
      ...POReport,
      [name]: value,
    };
    // console.table(tempObj);
    setPOReport(tempObj);
  };

  const orderTypeHandler = async (e) => {
    // setSelectedOrderType(e.target.value);
    let name = e.target.name;
    let value = e.target.value;
    // console.log(e.target.value);
    let tempObj = {
      ...POReport,
      [name]: value,
    };
    // console.table(tempObj);
    setPOReport(tempObj);
  };

  const radioBtnHandler = async (e) => {
    let opt = e.target.id;
    setSearchScope(opt);
    if (opt === "global_search") {
      POReport.buildingID = [];

      let data = {
        current_page: currentPage,
        // itemsPerPage: 10,
        date_start_of:
          POReport.dateRange[0] === ""
            ? POReport.dateRange[0]
            : POReport.dateRange[0].format("MM/DD/YYYY"),
        date_end_of:
          POReport.dateRange[1] === ""
            ? POReport.dateRange[1]
            : POReport.dateRange[1].format("MM/DD/YYYY"),
        search_string: POReport.searchValue,
        order_type: POReport.typeOrder,
        search_report_scope: opt,
        building_id: searchScope === "global_search" ? [] : [],
      };

      // console.log("data: ", data);

      let res = await adminApiCall(
        "/api/admin/reports/po_report/list",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (localStorage.getItem("building_id") === null) {
          notifyError("Please Select a Building First");
          setLoader(false);
          setNoRecord(true);
        } else if (res.status === 200) {
          // console.log("resssssbuilding: ", res);
          setReports(res.data);
          setPageCount(res.total_pages);
          setTotalRecord(res.total_records);
          setLoader(false);
          setNoRecord(false);
        } else if (res.status === 400) {
          notifyError(res.message);
          setLoader(false);
          setNoRecord(true);
        } else if (res.status === 300) {
          setLoader(false);
          setNoRecord(true);
        }
      }
    } else if (opt === "specific_search") {
      // getBuildingNames();
      let data = {
        current_page: currentPage,
        // itemsPerPage: 10,
        date_start_of:
          POReport.dateRange[0] === ""
            ? POReport.dateRange[0]
            : POReport.dateRange[0].format("MM/DD/YYYY"),
        date_end_of:
          POReport.dateRange[1] === ""
            ? POReport.dateRange[1]
            : POReport.dateRange[1].format("MM/DD/YYYY"),
        search_string: POReport.searchValue,
        order_type: POReport.typeOrder,
        search_report_scope: opt,
        building_id:
          searchScope === "specific_search" ? "" : POReport.buildingID,
      };

      // console.log("data: ", data);

      let res = await adminApiCall(
        "/api/admin/reports/po_report/list",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (localStorage.getItem("building_id") === null) {
          notifyError("Please Select a Building First");
          setLoader(false);
          setNoRecord(true);
        } else if (res.status === 200) {
          // console.log("resssssbuilding: ", res);
          setReports(res.data);
          setPageCount(res.total_pages);
          setTotalRecord(res.total_records);
          setLoader(false);
          setNoRecord(false);
        } else if (res.status === 400) {
          // notifyError("abc");
          setLoader(false);
          setNoRecord(true);
        } else if (res.status === 300) {
          setLoader(false);
          setNoRecord(true);
        }
      }
    }
  };

  const selectBuildingHandler = async (e) => {
    let ID = e.target.id;
    let checked = e.target.checked;
    let tempObj = { ...POReport };
    let tempArray = [...POReport.buildingID];
    if (checked === true) {
      tempArray.push(ID);
      // console.log("TempArrayPush", tempArray);
      tempObj.buildingID = tempArray;
    } else if (checked === false) {
      tempArray.splice(tempArray.indexOf(ID), 1);
      // console.log("TempArrayRemove", tempArray);
      tempObj.buildingID = tempArray;
      // notifyWarning("Please Select a Building to view records");
    }

    setPOReport(tempObj);
  };

  const handlePageClick = (e) => {
    // console.log("currentPage: ", currentPage);
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const modalChange = (val) => {
    setModalShow(val);
  };

  // ///////////////////////////////

  ///////////////////////////////////////Purchase View ///////////////////////////////////////

  const [purchaseOrderListingg, setPurchaseOrderListingg] = useState({});
  const [purchaseItemsList, setpurchaseItemsList] = useState([]);

  const getPurchaseOrder = async (ID, type) => {
    let data = {
      purchase_order_id: ID,
      order_type: "purchase_parts",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setPurchaseOrderListingg(res.data);
        setpurchaseItemsList(res.data?.purchase_items);
        // console.log("purchase type: ", type);

        if (type === "ptable") {
          wCPrintPurchase();
        } else if (type === "pwc") {
          pOPrintPurchase();
        }
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  ///////////////////////////////////////Maintenance View ///////////////////////////////////////

  const [maintenanceOrderListing, setMaintenanceOrderListing] = useState({});
  const [maintenanceServiceItems, setMaintenanceServiceItems] = useState([]);

  const getMaintenanceService = async (ID, type) => {
    let data = {
      purchase_order_id: ID,
      order_type: "maintenance_service",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setMaintenanceOrderListing(res.data);
        setMaintenanceServiceItems(res.data?.maintenance_service_items);
        // console.log("maintenance type: ", type);

        if (type === "mtable") {
          wCPrintMaintenance();
        } else if (type === "mwc") {
          pOPrintMaintenance();
        }
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const deleteComment = (deleteArray) => {
    // console.log("deleteArray", deleteArray);
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            purchase_order_id: purchaseOrderID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            purchase_order_id: purchaseOrderID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getPurchaseOrder();
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div ref={componentRef} className="contentCard">
            {showLogo ? (
              <div
                className="logoWrapper mb-5"
                style={{ backgroundColor: "white" }}
              >
                <Link className="logo" to={PATH.BUILDING_LIST}>
                  <img className="logo" src={Logosm} alt="" />
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="titleWrapper">
              <span>Purchase Order Reports</span>
            </div>

            <div className=" mb-2">
              <div className="col-lg-10 text-center">
                <div className="row justify-content-lg-between">
                  <div className="col-3 col-md-3 col-lg-3 col-xl-3 col-xl-3 mb-3">
                    <div className="search">
                      <div className="searchField">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search"
                          name="searchValue"
                          value={POReport.searchValue}
                          onChange={handleSearch}
                        />
                        <div className="icon">
                          <Search />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 col-md-3 col-lg-3 col-xl-3 col-xl-3 mb-3">
                    <div className="formDesign">
                      <div className="form-group" onChange={radioBtnHandler}>
                        <label style={{ position: "relative", right: "2px" }}>
                          <input
                            type="radio"
                            name="category"
                            value="global_search"
                            className="customRadio mr-2"
                            id="global_search"
                            defaultChecked
                            // onChange={radioBtnHandler}
                            // checked={globalSearch === true ? "checked" : false}
                          />
                          Global Search
                        </label>
                        <label style={{ position: "relative", left: "12px" }}>
                          <input
                            type="radio"
                            name="category"
                            value="specific_search"
                            className="customRadio mr-2"
                            id="specific_search"
                            onClick={() => setModalShow(true)}

                            // onChange={radioBtnHandler}
                          />
                          Specific Building
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 col-md-3 col-lg-4 col-xl-4 mb-3 text-center">
                    <div className="form-group">
                      <Space direction="vertical" size={12}>
                        <RangePicker
                          name="dateRange"
                          onChange={onchange}
                          format={format}
                        />
                      </Space>
                    </div>
                  </div>

                  <SpecificSearch
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    modalChange={modalChange}
                    searchScope={searchScope}
                    options={options}
                    POReport={POReport}
                    selectBuildingHandler={selectBuildingHandler}
                  />
                  <div className="col-3 col-md-6 col-lg-4 col-xl-3 mb-3 ">
                    <div className="form-group text-left">
                      <label>Order Type</label>
                      <select
                        className="form-control"
                        name="typeOrder"
                        value={POReport.typeOrder}
                        onChange={orderTypeHandler}
                      >
                        {orderType &&
                          orderType.map((v, i) => {
                            return (
                              <option key={i} value={v.value}>
                                {v.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loader ? (
              <div className="text-center">
                <img src={Loader} alt="" />
              </div>
            ) : noRecord ? (
              <div className="text-center">
                <img src={NoRecordFound} alt="" />
              </div>
            ) : POReport.typeOrder === "purchase_parts" ? (
              <div className="mainTable table-responsive dataTable scrollable">
                <table id="table-to-xls" className="table table-striped">
                  <thead>
                    <tr>
                      <th className="sort" onClick={() => pOIDSorting()}>
                        Order ID
                      </th>
                      <th className="sort" onClick={() => buildingSorting()}>
                        Building Id
                      </th>
                      <th className="sort" onClick={() => addressSorting()}>
                        Address
                      </th>
                      <th className="sort" onClick={() => orderTypeSorting()}>
                        Type{" "}
                      </th>
                      <th className="sort" onClick={() => buildingSorting()}>
                        Apt. No.
                      </th>
                      <th className="sort" onClick={() => categorySorting()}>
                        Category
                      </th>
                      <th className="sort" onClick={() => modelSorting()}>
                        Model
                      </th>
                      <th className="sort" onClick={() => descriptionSorting()}>
                        Description
                      </th>
                      <th className="sort" onClick={() => orderTypeSorting()}>
                        Trade{" "}
                      </th>
                      <th className="sort" onClick={() => createdAtSorting()}>
                        Date Created
                      </th>

                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports &&
                      reports.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>{v.order_id}</td>
                            <td>
                              <b>{v?.building_info?.building_id}</b>
                            </td>
                            <td>
                              {v.building_info?.street +
                                ", " +
                                v.building_info?.city +
                                ", " +
                                v.building_info?.country}
                            </td>
                            <td>
                              {capatalizeLetters(v.order_type.split("_")[0]) +
                                " " +
                                capatalizeLetters(v.order_type.split("_")[1])}
                            </td>
                            <td>{v?.purchase_part_item?.suite_no}</td>
                            <td>{v.purchase_part_item?.category}</td>
                            <td>{v.purchase_part_item?.model_no}</td>
                            <td>{v.purchase_part_item?.description}</td>
                            <td>{v.trade_info?.company}</td>
                            <td>
                              {moment(v.created_at).format("DD MMMM YYYY")}
                            </td>
                            <td className="text-right">
                              <div className="dropleft">
                                <button
                                  type="button"
                                  className="actionEclips dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </button>
                                <div className="dropdown-menu dropDesign">
                                  <Link
                                    to={`${
                                      v.order_type === "purchase_parts"
                                        ? PATH.VIEW_PO_REPORT
                                        : v.order_type === "maintenance_service"
                                        ? PATH.VIEW_MAINTENANCE_PO_REPORT
                                        : "abc"
                                    }/${v._id}`}
                                  >
                                    View
                                    <span>
                                      <File />
                                    </span>
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setPrintPO(false);
                                      setPrintMS(false);
                                      v.order_type === "purchase_parts"
                                        ? getPurchaseOrder(v._id, "ptable")
                                        : getMaintenanceService(
                                            v._id,
                                            "mtable"
                                          );
                                    }}
                                  >
                                    Print Purchase Order
                                    <span>
                                      <Print />
                                    </span>
                                  </Link>
                                  {/* <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setPrintPO(true);
                                      setPrintMS(true);
                                      v.order_type === "purchase_parts"
                                        ? getPurchaseOrder(v._id, "pwc")
                                        : getMaintenanceService(v._id, "mwc");
                                    }}
                                  >
                                    Print Work Confirmation
                                    <span>
                                      <Print />
                                    </span>
                                  </Link> */}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="mainTable table-responsive dataTable scrollable">
                <table id="table-to-xls" className="table table-striped">
                  <thead>
                    <tr>
                      <th className="sort" onClick={() => pOIDSorting()}>
                        Order ID
                      </th>
                      <th className="sort" onClick={() => buildingSorting()}>
                        Building Id
                      </th>
                      <th className="sort" onClick={() => addressSorting()}>
                        Address
                      </th>
                      <th className="sort" onClick={() => orderTypeSorting()}>
                        Type{" "}
                      </th>
                      <th className="sort" onClick={() => buildingSorting()}>
                        Apt. No.
                      </th>
                      <th className="sort" onClick={() => descriptionSorting()}>
                        Description
                      </th>
                      <th className="sort" onClick={() => buildingSorting()}>
                        Trade
                      </th>
                      <th className="sort" onClick={() => createdAtSorting()}>
                        Date Created
                      </th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports &&
                      reports.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>{v.order_id}</td>
                            <td>
                              <b>{v?.building_info?.building_id}</b>
                            </td>
                            <td>
                              {v.building_info?.street +
                                ", " +
                                v.building_info?.city +
                                ", " +
                                v.building_info?.country}
                            </td>
                            <td>
                              {capatalizeLetters(v.order_type.split("_")[0]) +
                                " " +
                                capatalizeLetters(v.order_type.split("_")[1])}
                            </td>
                            <td>{v?.maintenance_service_item?.suite_no}</td>
                            <td>{v.maintenance_service_item?.description}</td>
                            <td>{v.trade_info?.company}</td>
                            <td>
                              {moment(v.created_at).format("DD MMMM YYYY")}
                            </td>
                            <td className="text-right">
                              <div className="dropleft">
                                <button
                                  type="button"
                                  className="actionEclips dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </button>
                                <div className="dropdown-menu dropDesign">
                                  <Link
                                    to={`${
                                      v.order_type === "purchase_parts"
                                        ? PATH.VIEW_PO_REPORT
                                        : v.order_type === "maintenance_service"
                                        ? PATH.VIEW_MAINTENANCE_PO_REPORT
                                        : "abc"
                                    }/${v._id}`}
                                  >
                                    View
                                    <span>
                                      <File />
                                    </span>
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setPrintPO(false);
                                      setPrintMS(false);
                                      v.order_type === "purchase_parts"
                                        ? getPurchaseOrder(v._id, "ptable")
                                        : getMaintenanceService(
                                            v._id,
                                            "mtable"
                                          );
                                    }}
                                  >
                                    Print Purchase Order
                                    <span>
                                      <Print />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {reports.length < 0 || noRecord === true ? (
              ""
            ) : (
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-5">
                  Showing {currentPage * perPage - perPage + 1} to{" "}
                  {totalRecord > currentPage * perPage
                    ? currentPage * perPage
                    : totalRecord}{" "}
                  of {totalRecord} entries
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers text-right"
                    id="datatable_paginate"
                  >
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      onPageChange={(e) => handlePageClick(e)}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      pageClassName={"paginate_button page-item"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            )}

            {showLogo ? (
              ""
            ) : (
              <div className="cardFooter">
                <button
                  className="myBtn bdr mr-1"
                  href="#"
                  type="button"
                  onClick={() => {
                    setShowLogo(true);
                    setTimeout(() => {
                      handlePrint();
                    }, 500);
                  }}
                >
                  Print
                </button>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button myBtn bdr"
                  table="table-to-xls"
                  filename="poReport"
                  sheet="tablexls"
                  buttonText="Export"
                />
              </div>
            )}
          </div>
        </section>

        {/*  */}
        {/* Purchase View */}
        <section style={{ display: "none" }} className="pageWrapper">
          <div ref={printWCPurchase} className="row">
            <div className="col-12 col-md-4 col-lg-12">
              <div
                className="row justify-content-between align-items-center mb-4"
                style={{ backgroundColor: "white" }}
              >
                <div className="col-4 col-md-4 col-lg-3">
                  <div
                    className="logoWrapper"
                    style={{ backgroundColor: "white" }}
                  >
                    <Link to={PATH.BUILDING_LIST}>
                      <img className="logo" src={Logosm} alt="" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-4 col-md-4 col-lg-3 text-center"
                  style={{ margin: "10px 0px" }}
                >
                  <table>
                    <tbody>
                      <tr
                        style={{
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        <th className="tableBorderPrint">PO ID</th>
                        <td
                          className="tableBorderPrint"
                          style={{ minWidth: "155px" }}
                        >
                          {purchaseOrderListingg?.order_id}
                        </td>
                      </tr>
                      <tr style={{ color: "black", fontSize: "16px" }}>
                        <th className="tableBorderPrint">Building Name</th>
                        <td className="tableBorderPrint">
                          {purchaseOrderListingg?.building_info?.building_name}
                        </td>
                      </tr>
                      <tr style={{ color: "black", fontSize: "16px" }}>
                        <th className="tableBorderPrint">Status</th>
                        <td
                          className={`tableBorderPrint text ${
                            purchaseOrderListingg?.order_state === "PENDING"
                              ? "pending"
                              : purchaseOrderListingg?.order_state ===
                                "APPROVED"
                              ? "success"
                              : purchaseOrderListingg?.order_state === "CONFIRM"
                              ? "success"
                              : "danger"
                          }`}
                          style={{ fontWeight: "900", fontSize: "22px" }}
                        >
                          {purchaseOrderListingg?.order_state === "PENDING"
                            ? "PENDING"
                            : purchaseOrderListingg?.order_state === "APPROVED"
                            ? "APPROVED"
                            : purchaseOrderListingg?.order_state === "CONFIRM"
                            ? "INVOICED"
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr className="splitCard" />
            </div>
            <div className="contentCard row justify-content-between w-100">
              <div className="col-6 col-md-4 col-lg-4">
                <table className="mx-auto">
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      <th
                        colSpan={2}
                        style={{
                          backgroundColor: "#d97228",
                          border: "1px solid black",
                          boxSizing: "border-box",
                          color: "white",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Purchase Order Details
                      </th>
                    </tr>
                    {/* <tr
                      style={{
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      <th className="tableBorderPrint">Purchase Order ID</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.order_id}
                      </td>
                    </tr> */}
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Building Address</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.building_info?.street +
                          ", " +
                          purchaseOrderListingg?.building_info?.city +
                          ", " +
                          purchaseOrderListingg?.building_info?.country}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Postal Code</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.building_info?.postal_code}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Phone</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[0] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[0]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[1] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[1]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[2] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[3] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[3]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[4] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[4]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[5] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[6] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[6]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[7] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[7]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[8] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[8]
                          : ""}
                        {purchaseOrderListingg?.building_info?.phone_no?.split(
                          ""
                        )[9] !== undefined
                          ? purchaseOrderListingg?.building_info?.phone_no?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Created Date</th>
                      <td className="tableBorderPrint">
                        {getDateOnly(purchaseOrderListingg?.created_at)}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Type</th>
                      <td className="tableBorderPrint">
                        {capatalizeLetters(
                          purchaseOrderListingg?.order_type +
                            " " +
                            purchaseOrderListingg?.order_type
                        )}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Requested By</th>
                      <td className="tableBorderPrint">
                        {capatalizeLetters(
                          purchaseOrderListingg?.order_requested_by_user_info
                            ?.first_name +
                            "_" +
                            purchaseOrderListingg?.order_requested_by_user_info
                              ?.last_name
                        )}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Requested On</th>
                      <td className="tableBorderPrint">
                        {getDateOnly(purchaseOrderListingg?.order_requested_at)}
                      </td>
                    </tr>
                    {purchaseOrderListingg?.order_approved_by === null ? (
                      ""
                    ) : (
                      <>
                        <tr style={{ color: "black", fontSize: "14px" }}>
                          <th className="tableBorderPrint">Approved By</th>
                          <td className="tableBorderPrint">
                            {capatalizeLetters(
                              purchaseOrderListingg?.order_approved_by_user_info
                                ?.first_name +
                                "_" +
                                purchaseOrderListingg
                                  ?.order_approved_by_user_info?.last_name
                            )}
                          </td>
                        </tr>
                        <tr style={{ color: "black", fontSize: "14px" }}>
                          <th className="tableBorderPrint">Approved On</th>
                          <td className="tableBorderPrint">
                            {getDateOnly(
                              purchaseOrderListingg?.order_approved_at
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                    {/* <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">
                        Emailed To Contractor On
                      </th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.trade_info?.email}
                      </td>
                    </tr> */}
                    {purchaseOrderListingg?.email_to_contractor === null ? (
                      ""
                    ) : (
                      <tr style={{ color: "black", fontSize: "14px" }}>
                        <th className="tableBorderPrint">
                          Emailed To Contractor On
                        </th>
                        <td className="tableBorderPrint">
                          {/* {purchaseOrderListingg?.email_to_contractor} */}
                          {moment(
                            new Date(
                              purchaseOrderListingg?.email_to_contractor
                            ).toUTCString()
                          ).format("DD-MMMM-YYYY hh:mm a")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-6 col-md-4 col-lg-4">
                <table className="w-100">
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      <th
                        colSpan={2}
                        style={{
                          backgroundColor: "#d97228",
                          border: "1px solid black",
                          boxSizing: "border-box",
                          color: "white",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Trade Details
                      </th>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Name</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.trade_info_updated?.company}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Phone</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[0] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[0]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[1] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[1]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[2] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[3] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[3]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[4] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[4]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[5] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[6] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[6]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[7] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[7]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[8] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[8]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                          ""
                        )[9] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.phone_no?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Fax</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[0] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[0]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[1] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[1]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[2] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[3] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[3]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[4] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[4]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[5] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[6] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[6]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[7] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[7]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[8] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[8]
                          : ""}
                        {purchaseOrderListingg?.trade_info_updated?.fax?.split(
                          ""
                        )[9] !== undefined
                          ? purchaseOrderListingg?.trade_info_updated?.fax?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Email</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.trade_info_updated?.email}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr className="splitCard" />
            </div>

            <div ref={printTablePurchase} className="col-12 col-md-8 col-lg-9">
              {printPO === true ? (
                <div className="col-12 col-md-4 col-lg-12">
                  <div
                    className="row justify-content-between align-items-center"
                    style={{ backgroundColor: "#38393c" }}
                  >
                    <div className="col-4 col-md-4 col-lg-3">
                      <div
                        className="logoWrapper"
                        style={{ backgroundColor: "#38393c" }}
                      >
                        <Link to={PATH.BUILDING_LIST}>
                          <img className="logo" src={Logosm} alt="" />
                        </Link>
                      </div>
                    </div>
                    {/* <div className="col-4 col-md-4 col-lg-3">abc</div> */}
                    <div className="col-4 col-md-4 col-lg-3 text-center">
                      <div className="details mt-2 mb-2">
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "14px" }}>
                            Purchase Order ID
                          </h4>
                          <h2 style={{ color: "white", fontSize: "14px" }}>
                            {purchaseOrderListingg?.order_id}
                          </h2>
                        </div>
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "14px" }}>
                            Building Name
                          </h4>
                          <h2 style={{ color: "white", fontSize: "14px" }}>
                            {buildingName}
                          </h2>
                        </div>
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "14px" }}>
                            Status
                          </h4>
                          <h2
                            className={`badge ${
                              purchaseOrderListingg?.order_state === "PENDING"
                                ? "pending"
                                : purchaseOrderListingg?.order_state ===
                                  "APPROVED"
                                ? "success"
                                : purchaseOrderListingg?.order_state ===
                                  "CONFIRM"
                                ? "success"
                                : "danger"
                            }`}
                          >
                            {purchaseOrderListingg?.order_state}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="contentCard mb-0">
                <div className="titleWrapperPrint">
                  <span style={{ fontSize: "16px" }}>
                    Purchase Parts Work Confirmation
                  </span>
                </div>
                <div className="mainTable table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ fontSize: "14px" }}>Category</th>
                        <th style={{ fontSize: "14px" }}>Model Number</th>
                        <th style={{ fontSize: "14px" }}>Description</th>
                        <th style={{ fontSize: "14px" }}>Apt</th>
                        <th style={{ fontSize: "14px" }}>Price</th>
                        <th style={{ fontSize: "14px" }}>Qty</th>
                        <th style={{ fontSize: "14px" }}>Qty Rcvd.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseItemsList &&
                        purchaseItemsList.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  fontSize: "14px",
                                  border: "1px solid black",
                                  borderLeft: "none",
                                }}
                              >
                                <b>{v.inventory_category}</b>
                              </td>
                              <td
                                style={{
                                  fontSize: "14px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.model_number}
                              </td>
                              <td
                                style={{
                                  fontSize: "14px",
                                  minWidth: "300px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.description}
                              </td>
                              <td
                                style={{
                                  fontSize: "14px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.suite_no}
                              </td>
                              <td
                                style={{
                                  fontSize: "14px",
                                  border: "1px solid black",
                                }}
                              >
                                <b>${v.price}</b>
                              </td>
                              <td
                                style={{
                                  fontSize: "14px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.quantity}
                              </td>
                              <td
                                style={{
                                  fontSize: "14px",
                                  border: "1px solid black",
                                  borderRight: "none",
                                }}
                              >
                                {v.quantity_received}
                              </td>
                              {/* <td
                                style={{ fontSize: "20px", minWidth: "175px" }}
                              >
                                {v.quantity_received}
                                <span style={{ marginLeft: "5px" }}>
                                  {v.received_date === null ? (
                                    ""
                                  ) : (
                                    <>
                                      {"("}
                                      {getDateOnly(v.received_date)}
                                      {")"}
                                    </>
                                  )}
                                </span>
                              </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <hr className="splitCard" />
              </div>
              {purchaseOrderListingg?.comments?.length < 1 ? (
                ""
              ) : (
                <div className="contentCard commentWrapper mt-3">
                  <div className="formDesign">
                    <div className="form-group">
                      <label style={{ fontSize: "20px" }}>
                        Comments
                        {/* <small>(optional)</small> */}
                      </label>
                      {/* <textarea
                      className="form-control"
                      rows="3"
                      value={addComments}
                      onChange={(e) => setAddComments(e.target.value)}
                    ></textarea> */}
                      <div className="commentLogs">
                        {purchaseOrderListingg?.comments?.map((v, i) => {
                          return (
                            <div key={i} className="comment">
                              <h2 style={{ fontSize: "20px" }}>
                                {capatalizeLetters(
                                  v?.created_by_user_info?.first_name +
                                    "_" +
                                    v?.created_by_user_info?.last_name
                                )}{" "}
                                <span style={{ fontSize: "16px" }}>
                                  {getDate(v.created_at)}{" "}
                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )}
                                </span>
                              </h2>
                              <p style={{ fontSize: "14px" }}>{v.comment}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Maintenance View */}

        <section style={{ display: "none" }} className="pageWrapper">
          <div ref={printWCMaintenance} className="row">
            <div className="col-12 col-md-4 col-lg-12">
              <div
                className="row justify-content-between align-items-center"
                style={{ backgroundColor: "white" }}
              >
                <div className="col-4 col-md-4 col-lg-3">
                  <div
                    className="logoWrapper"
                    style={{ backgroundColor: "white" }}
                  >
                    <Link to={PATH.BUILDING_LIST}>
                      <img className="logo" src={Logosm} alt="" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-4 col-md-4 col-lg-3 text-center"
                  style={{ margin: "10px 0px" }}
                >
                  <table>
                    <tbody>
                      <tr
                        style={{
                          color: "black",
                          fontSize: "16px",
                        }}
                      >
                        <th className="tableBorderPrint">PO ID</th>
                        <td
                          className="tableBorderPrint"
                          style={{ minWidth: "140px" }}
                        >
                          {maintenanceOrderListing?.order_id}
                        </td>
                      </tr>
                      <tr style={{ color: "black", fontSize: "16px" }}>
                        <th className="tableBorderPrint">Building Name</th>
                        <td className="tableBorderPrint">
                          {
                            maintenanceOrderListing?.building_info
                              ?.building_name
                          }
                        </td>
                      </tr>
                      <tr style={{ color: "black", fontSize: "16px" }}>
                        <th className="tableBorderPrint">Status</th>
                        <td
                          className={`tableBorderPrint text ${
                            maintenanceOrderListing?.order_state === "PENDING"
                              ? "pending"
                              : maintenanceOrderListing?.order_state ===
                                "APPROVED"
                              ? "success"
                              : maintenanceOrderListing?.order_state ===
                                "CONFIRM"
                              ? "success"
                              : "danger"
                          }`}
                          style={{ fontWeight: "900", fontSize: "22px" }}
                        >
                          {maintenanceOrderListing?.order_state === "PENDING"
                            ? "PENDING"
                            : maintenanceOrderListing?.order_state ===
                              "APPROVED"
                            ? "APPROVED"
                            : maintenanceOrderListing?.order_state === "CONFIRM"
                            ? "INVOICED"
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr className="splitCard" />
            </div>
            <div className="contentCard row justify-content-between w-100">
              <div className="col-6 col-md-4 col-lg-4">
                <table className="mx-auto">
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      <th
                        colSpan={2}
                        style={{
                          backgroundColor: "#d97228",
                          border: "1px solid black",
                          boxSizing: "border-box",
                          color: "white",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Maintenance Service Details
                      </th>
                    </tr>
                    {/* <tr
                      style={{
                        color: "black",
                        fontSize: "20px",
                      }}
                    >
                      <th className="tableBorderPrint">Purchase Order ID</th>
                      <td className="tableBorderPrint">
                        {purchaseOrderListingg?.order_id}
                      </td>
                    </tr> */}
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Building Address</th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.building_info?.street +
                          ", " +
                          maintenanceOrderListing?.building_info?.city +
                          ", " +
                          maintenanceOrderListing?.building_info?.country}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Postal Code</th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.building_info?.postal_code}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Phone</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[0] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[0]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[1] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[1]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[2] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[3] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[3]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[4] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[4]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[5] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[6] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[6]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[7] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[7]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[8] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[8]
                          : ""}
                        {maintenanceOrderListing?.building_info?.phone_no?.split(
                          ""
                        )[9] !== undefined
                          ? maintenanceOrderListing?.building_info?.phone_no?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Created Date</th>
                      <td className="tableBorderPrint">
                        {getDateOnly(maintenanceOrderListing?.created_at)}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Type</th>
                      <td className="tableBorderPrint">
                        {capatalizeLetters(
                          maintenanceOrderListing?.order_type +
                            " " +
                            maintenanceOrderListing?.order_type
                        )}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Requested By</th>
                      <td className="tableBorderPrint">
                        {capatalizeLetters(
                          maintenanceOrderListing?.order_requested_by_user_info
                            ?.first_name +
                            "_" +
                            maintenanceOrderListing
                              ?.order_requested_by_user_info?.last_name
                        )}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Requested On</th>
                      <td className="tableBorderPrint">
                        {getDateOnly(
                          maintenanceOrderListing?.order_requested_at
                        )}
                      </td>
                    </tr>
                    {maintenanceOrderListing?.order_approved_by === null ? (
                      ""
                    ) : (
                      <>
                        <tr style={{ color: "black", fontSize: "14px" }}>
                          <th className="tableBorderPrint">Approved By</th>
                          <td className="tableBorderPrint">
                            {capatalizeLetters(
                              maintenanceOrderListing
                                ?.order_approved_by_user_info?.first_name +
                                "_" +
                                maintenanceOrderListing
                                  ?.order_approved_by_user_info?.last_name
                            )}
                          </td>
                        </tr>
                        <tr style={{ color: "black", fontSize: "14px" }}>
                          <th className="tableBorderPrint">Approved On</th>
                          <td className="tableBorderPrint">
                            {getDateOnly(
                              maintenanceOrderListing?.order_approved_at
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                    {/* <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">
                        Emailed To Contractor On
                      </th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.trade_info?.email}
                      </td>
                    </tr> */}
                    {maintenanceOrderListing?.email_to_contractor === null ? (
                      ""
                    ) : (
                      <tr style={{ color: "black", fontSize: "14px" }}>
                        <th className="tableBorderPrint">
                          Emailed To Contractor On
                        </th>
                        <td className="tableBorderPrint">
                          {/* {maintenanceOrderListing?.email_to_contractor} */}
                          {moment(
                            new Date(
                              maintenanceOrderListing?.email_to_contractor
                            ).toUTCString()
                          ).format("DD-MMMM-YYYY hh:mm a")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-6 col-md-4 col-lg-4">
                <table className="w-100">
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        fontSize: "16px",
                      }}
                    >
                      <th
                        colSpan={2}
                        style={{
                          backgroundColor: "#d97228",
                          border: "1px solid black",
                          boxSizing: "border-box",
                          color: "white",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        Trade Details
                      </th>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Name</th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.trade_info_updated?.company}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Phone</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[0] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[0]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[1] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[1]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[2] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[3] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[3]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[4] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[4]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[5] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[6] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[6]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[7] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[7]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[8] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[8]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                          ""
                        )[9] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.phone_no?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Fax</th>
                      <td className="tableBorderPrint">
                        {" ("}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[0] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[0]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[1] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[1]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[2] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[2]
                          : ""}
                        {") "}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[3] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[3]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[4] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[4]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[5] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[5]
                          : ""}
                        {" - "}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[6] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[6]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[7] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[7]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[8] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[8]
                          : ""}
                        {maintenanceOrderListing?.trade_info_updated?.fax?.split(
                          ""
                        )[9] !== undefined
                          ? maintenanceOrderListing?.trade_info_updated?.fax?.split(
                              ""
                            )[9]
                          : ""}
                      </td>
                    </tr>
                    <tr style={{ color: "black", fontSize: "14px" }}>
                      <th className="tableBorderPrint">Email</th>
                      <td className="tableBorderPrint">
                        {maintenanceOrderListing?.trade_info_updated?.email}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              ref={printTableMaintenance}
              className="col-12 col-md-8 col-lg-9"
            >
              {printMS === true ? (
                <div className="col-12 col-md-4 col-lg-12">
                  <div
                    className="row justify-content-between align-items-center"
                    style={{ backgroundColor: "#38393c" }}
                  >
                    <div className="col-4 col-md-4 col-lg-3">
                      <div
                        className="logoWrapper"
                        style={{ backgroundColor: "#38393c" }}
                      >
                        <Link to={PATH.BUILDING_LIST}>
                          <img className="logo" src={Logosm} alt="" />
                        </Link>
                      </div>
                    </div>
                    {/* <div className="col-4 col-md-4 col-lg-3">abc</div> */}
                    <div className="col-4 col-md-4 col-lg-3 text-center">
                      <div className="details mt-2 mb-2">
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "14px" }}>
                            Maintenance Service ID
                          </h4>
                          <h2 style={{ color: "white", fontSize: "14px" }}>
                            {maintenanceOrderListing?.order_id}
                          </h2>
                        </div>
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "14px" }}>
                            Building Name
                          </h4>
                          <h2 style={{ color: "white", fontSize: "14px" }}>
                            {buildingName}
                          </h2>
                        </div>
                        <div className="detailContent">
                          <h4 style={{ color: "white", fontSize: "14px" }}>
                            Status
                          </h4>
                          <h2
                            className={`badge ${
                              maintenanceOrderListing?.order_state === "PENDING"
                                ? "pending"
                                : maintenanceOrderListing?.order_state ===
                                  "APPROVED"
                                ? "success"
                                : maintenanceOrderListing?.order_state ===
                                  "CONFIRM"
                                ? "success"
                                : "danger"
                            }`}
                          >
                            {maintenanceOrderListing?.order_state}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="contentCard mb-0">
                <div className="titleWrapperPrint">
                  <span style={{ fontSize: "16px" }}>
                    Maintenance Service Work Confirmation
                  </span>
                </div>
                <div className="mainTable table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ fontSize: "14px" }}>Description</th>
                        <th style={{ fontSize: "14px" }}>Log Hrs.</th>
                        <th style={{ fontSize: "14px" }}>Apt</th>
                        <th style={{ fontSize: "14px" }}>Price</th>
                        <th
                          className="text-center"
                          style={{ fontSize: "14px" }}
                        >
                          Completed
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {maintenanceServiceItems &&
                        maintenanceServiceItems.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  fontSize: "14px",
                                  border: "1px solid black",
                                  borderLeft: "none",
                                }}
                              >
                                <b>{v.description}</b>
                              </td>
                              {v?.time_logs?.length === 0 ? (
                                <td
                                  style={{
                                    fontSize: "14px",
                                    border: "1px solid black",
                                  }}
                                >
                                  <small>-</small>
                                </td>
                              ) : (
                                <td
                                  style={{
                                    fontSize: "14px",
                                    border: "1px solid black",
                                  }}
                                >
                                  {v?.time_logs?.map((val, ind) => {
                                    return (
                                      <small key={ind}>
                                        <b>
                                          {ind === 0
                                            ? getDateOnly(v.received_date) +
                                              " - "
                                            : ""}
                                        </b>{" "}
                                        (
                                        {val.time_in === "Invalid date"
                                          ? "No Time In Added"
                                          : val.time_in}{" "}
                                        -{" "}
                                        {val.time_out === "Invalid date"
                                          ? "No Time Out Added"
                                          : val.time_out}
                                        )
                                      </small>
                                    );
                                  })}
                                </td>
                              )}
                              <td
                                style={{
                                  fontSize: "14px",
                                  border: "1px solid black",
                                }}
                              >
                                {v.suite_no}
                              </td>
                              <td
                                style={{
                                  fontSize: "14px",
                                  border: "1px solid black",
                                }}
                              >
                                <b>{v.price ? "$" + v.price : ""}</b>
                              </td>
                              <td
                                className="text-center"
                                style={{
                                  fontSize: "14px",
                                  border: "1px solid black",
                                  borderRight: "none",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="customRadio"
                                  checked={v.is_complete ? true : false}
                                  disabled
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <hr className="splitCard" />
              </div>
              {maintenanceOrderListing?.comments?.length < 1 ? (
                ""
              ) : (
                <div className="contentCard commentWrapper mt-3">
                  <div className="formDesign">
                    <div className="form-group">
                      <label style={{ fontSize: "20px" }}>Comments</label>
                      <div className="commentLogs">
                        {maintenanceOrderListing?.comments?.map((v, i) => {
                          return (
                            <div key={i} className="comment">
                              <h2 style={{ fontSize: "20px" }}>
                                {capatalizeLetters(
                                  v?.created_by_user_info?.first_name +
                                    "_" +
                                    v?.created_by_user_info?.last_name
                                )}{" "}
                                <span style={{ fontSize: "16px" }}>
                                  {getDate(v.created_at)}{" "}
                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )}
                                </span>
                              </h2>
                              <p style={{ fontSize: "14px" }}>{v.comment}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
