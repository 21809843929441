import React from "react";
import { Modal, Button } from "react-bootstrap";
import { DatePicker, Space } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

export default function EditQuantityReceived({
  show,
  onHide,
  dateVal,
  setDateVal,
  quantityReceived,
  setQuantityReceived,
  updatePurchasePartWorkConfirmation,
  itemDetails,
}) {
  function onChange(date, dateString) {
    // console.log(date, dateString);
    date = moment.utc(date);
    setDateVal(dateString === "" ? "" : moment.utc(date));
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center modalHeader">
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="modalTitle text-left text-light">
            {" "}
            {itemDetails.itemDescription}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="formDesign">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-6">
              <h5>Update Total Quantity Received:</h5>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group">
                <input
                  type="number"
                  className="form-control"
                  value={quantityReceived}
                  onChange={(e) => {
                    setQuantityReceived(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <h5>Date Received:</h5>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group">
                <Space direction="vertical">
                  <DatePicker
                    className="form-control"
                    style={{ width: "100%" }}
                    defaultValue={dateVal}
                    format={"MM/DD/YYYY"}
                    onChange={onChange}
                    name="dateVal"
                  />
                </Space>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link to={"#"} className="myBtn bdr" onClick={() => onHide()}>
          Cancel
        </Link>
        <Link
          to={"#"}
          className="myBtn"
          onClick={() => updatePurchasePartWorkConfirmation()}
        >
          Update Qty
        </Link>
      </Modal.Footer>
    </Modal>
  );
}
