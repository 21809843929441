import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminApiCall } from "../../../helpers/ajax_request";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";

export default function Index() {
  const navigate = useNavigate();
  const buildingID = localStorage.getItem("building_id");

  useEffect(() => {
    unitList();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const initialValues = {
    // standardRent: "",
    rentWithDiscount: "",
    comments: "",
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [unitNameList, setUnitNameList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedAction, setSelectedAction] = useState("Monthly");
  const [selectedStatus, setSelectedStatus] = useState("Vacant");
  const [vacateDate, setVacateDate] = useState(moment.utc());
  const [rentedAsOf, setRentedAsOf] = useState("");
  const [pickedUpDate, setPickedUpDate] = useState("");
  const [standardRent, setStandardRent] = useState("");

  const [checkboxes, setCheckboxes] = useState({
    damageReport: false,
    outgoingInspection: false,
    incomingInspection: false,
    leaseSigned: false,
  });

  const [action, setAction] = useState([
    { label: "Monthly", value: "Monthly" },
    { label: "Short", value: "Short" },
    { label: "Eviction", value: "Eviction" },
  ]);

  const [status, setStatus] = useState([
    { label: "Vacant", value: "Vacant" },
    { label: "Occupied", value: "Occupied" },
  ]);

  const selectActionHandler = (e) => {
    // console.log(e.target.value);
    setSelectedAction(e.target.value);
  };

  const selectUnitName = (e) => {
    // console.log(e.target.value);
    setSelectedUnit(e.target.value);
  };

  const unitList = async () => {
    let data = {
      building_id: buildingID,
    };
    let res = await adminApiCall(
      "/api/admin/unit/unit_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);

        setUnitNameList(res.data);
        setSelectedUnit(res.data[0]._id);
      } else if (res.status === 400) {
        notifyError(res.message);
      } else if (res.status === 300) {
        // notifyError(res.message);
        setModalShow(true);
      }
    }
  };

  const handleStatus = (e) => {
    // console.log(e.target.value);
    setSelectedStatus(e.target.value);
  };

  const handleCheckBoxes = (e) => {
    let checked = e.target.checked;
    let name = e.target.name;
    // console.log(e);
    let newCheckbox = {
      ...checkboxes,
      [name]: checked,
    };
    // console.table(newCheckbox);
    setCheckboxes(newCheckbox);
  };

  let validationSchema = yup.object({
    // standardRent: yup.string().required("Please enter Standard Rent."),
    rentWithDiscount: yup.string().required("Please enter Rent with Discount."),
  });

  const onSubmit = async (values) => {
    // console.log("all okay", values);
    // if (vacateDate === "") {
    //   notifyError("Please Enter Vacate Date");
    // } else {
    let data = {
      unit_id: selectedUnit,
      action: selectedAction,
      standard_rent: Number(standardRent.toFixed(2)),
      rent_with_discount: Number(formik.values.rentWithDiscount),
      ...(vacateDate && {
        vacate_date:
          vacateDate === "" ? vacateDate : vacateDate.format("MM/DD/YYYY"),
      }),
      ...(rentedAsOf && {
        rented_as_of:
          rentedAsOf === "" ? rentedAsOf : rentedAsOf.format("MM/DD/YYYY"),
      }),
      // pm_picked_up_date:
      //   pickedUpDate === "" ? pickedUpDate : pickedUpDate.format("MM/DD/YYYY"),
      // unit_status: selectedStatus,
      damage_report: checkboxes.damageReport,
      outgoing_inspection: checkboxes.outgoingInspection,
      incoming_inspection: checkboxes.incomingInspection,
      lease_signed: checkboxes.leaseSigned,
      comments: {
        comment: formik.values.comments,
      },
    };
    // console.log("data sent: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/traffic_sheet/create",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Traffic Sheet Created Successfully!");
        navigate(PATH.VIEW_TRAFFIC_SHEET);
        formik.setValues({});
        formik.setErrors({});
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
    // }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  function onChangeVacateDate(date, dateString) {
    // setVacateDate(date === null ? "" : date);
    console.log('asasas',date, dateString);
    date = moment.utc(date);
    setVacateDate(dateString === "" ? "" : moment.utc(date));
  }

  function onChangeRentedAsOf(date, dateString) {
    // setRentedAsOf(date === null ? "" : date);
    // console.log(date, dateString);
    date = moment.utc(date);
    setRentedAsOf(dateString === "" ? "" : moment.utc(date));
  }

  function onChangePickedUpDate(date, dateString) {
    // setPickedUpDate(date);
    // console.log(date, dateString);
    date = moment.utc(date);
    setPickedUpDate(dateString === "" ? "" : moment.utc(date));
  }

  const customFormik = (e) => {
    // console.log(e.currentTarget.value);
    // console.log(e.currentTarget.name);
    let value = e.currentTarget.value;
    let name = e.currentTarget.name;
    let withoutDiscount = e.target.value / 0.98;
    setStandardRent(withoutDiscount);
    // formik.setValues(obj);
    formik.setFieldValue("rentWithDiscount", value);
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Before creating Traffic Sheet please add Unit<small>(s)</small>{" "}
            against building
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="myBtn"
            onClick={() => {
              navigate(PATH.ADD_UNIT);
            }}
          >
            Add Unit
          </Button>
          <Button
            className="myBtn bdr"
            onClick={() => {
              navigate(PATH.VIEW_TRAFFIC_SHEET);
            }}
          >
            Back To Listing
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <section className="pageWrapper">
          <div className="contentCard ">
            <div className="titleWrapper">
              <span>New Traffic Sheet Item</span>
            </div>
            <div className="formDesign">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Apt.</label>
                    <select className="form-control" onChange={selectUnitName}>
                      {unitNameList &&
                        unitNameList.map((v, i) => {
                          return (
                            <option key={i} value={v._id}>
                              {v.unit_no}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <MyVerticallyCenteredModal show={modalShow} />
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Notice</label>
                    <select
                      className="form-control"
                      // value={action}
                      onChange={(e) => selectActionHandler(e)}
                    >
                      {action &&
                        action.map((v, i) => {
                          return (
                            <option key={i} value={v.value}>
                              {v.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>
                      Rent With 2% Discount <small>($)</small>
                    </label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.rentWithDiscount &&
                        formik.touched.rentWithDiscount
                          ? " is-invalid"
                          : "")
                      }
                      type="number"
                      name="rentWithDiscount"
                      value={formik.values.rentWithDiscount}
                      onChange={customFormik}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.rentWithDiscount}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>
                      Legal Rent <small>($)</small>
                    </label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.standardRent &&
                        formik.touched.standardRent
                          ? " is-invalid"
                          : "")
                      }
                      type="number"
                      name="standardRent"
                      value={(formik.values.rentWithDiscount / 0.98).toFixed(2)}
                      disabled
                    />
                    <div className="invalid-feedback">
                      {formik.errors.standardRent}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Vacate Date</label>
                    <Space direction="vertical">
                      <DatePicker
                        className="form-control"
                        name="vacateDate"
                        value={vacateDate}
                        onChange={onChangeVacateDate}
                      />
                    </Space>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Rented As Of</label>
                    <Space direction="vertical">
                      <DatePicker
                        className="form-control"
                        value={rentedAsOf}
                        onChange={onChangeRentedAsOf}
                      />
                    </Space>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Damage Report</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="damageReport"
                          name="damageReport"
                          value={checkboxes.damageReport}
                          onChange={(e) => handleCheckBoxes(e)}
                        />
                        <label htmlFor="damageReport">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Outgoing Inspection</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="outgoing"
                          name="outgoingInspection"
                          value={checkboxes.outgoingInspection}
                          onChange={(e) => handleCheckBoxes(e)}
                        />
                        <label htmlFor="outgoing">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Incoming Inspection</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="incoming"
                          name="incomingInspection"
                          value={checkboxes.incomingInspection}
                          onChange={(e) => handleCheckBoxes(e)}
                        />
                        <label htmlFor="incoming">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Lease Signed</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="lease"
                          name="leaseSigned"
                          value={checkboxes.leaseSigned}
                          onChange={(e) => handleCheckBoxes(e)}
                        />
                        <label htmlFor="lease">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-8 col-xl-9">
                  <div className="form-group">
                    <label>Comments</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="comments"
                      value={formik.values.comments}
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardFooter">
              <button className="myBtn mr-1" type="submit">
                Add
              </button>
              <Link className="myBtn bdr" to={PATH.VIEW_TRAFFIC_SHEET}>
                Cancel
              </Link>
            </div>
          </div>
        </section>
      </form>
    </MainLayout>
  );
}
