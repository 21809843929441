import React from "react";
import { Link } from "react-router-dom";

export default function Index() {
  return (
    <>
      <div className="not-found">
        <h2 className="pageNotFoundText">Error: 404 Page Not Found!</h2>
        {/* <Link className="btn btn-rounded btn-primary mt-3" to={"/buildingList"}>
          Go To HomePage
        </Link> */}
      </div>
    </>
  );
}
