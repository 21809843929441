import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { PATH } from "../../../constants/paths";
import { Delete2 } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { baseDomain } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  adminApiCall,
  capatalizeLetters,
  userAccessibility,
  getDate,
  getDateOnly,
} from "../../../helpers/ajax_request";
import Swal from "sweetalert2";

export default function Index() {
  const navigate = useNavigate();
  const params = useParams();
  const maintenanceServiceID = params.id;

  const [maintenanceOrderListing, setMaintenanceOrderListing] = useState({});
  const [maintenanceServiceItems, setMaintenanceServiceItems] = useState([]);
  const [addComments, setAddComments] = useState("");
  const [findBuilding, setFindBuilding] = useState("");

  useEffect(() => {
    setBuildingOnNavbar();
  }, [findBuilding]);

  useEffect(() => {
    getMaintenanceService();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const getMaintenanceService = async () => {
    let data = {
      purchase_order_id: maintenanceServiceID,
      order_type: "maintenance_service",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setMaintenanceOrderListing(res.data);
        setMaintenanceServiceItems(res.data?.maintenance_service_items);
        setFindBuilding(res?.data?.building_id);
      } else if (res.status === 400) {
        notifyError(res.message);
      } else if (res.status === 300) {
        // notifyError(res.message);
        navigate("*");
      }
    }
  };

  const setBuildingOnNavbar = async () => {
    let data = {
      building_id: findBuilding,
    };
    let res = await adminApiCall(
      "/api/admin/building/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res SET BUILDING => ", res);
        localStorage.setItem("building_name", res?.data?.street);
        localStorage.setItem("building_ID", res?.data?.building_id);
        localStorage.setItem("building_id", res?.data?._id);
        // console.log("v._id --===> ", v._id);
        localStorage.setItem("available_suites", res?.data?.available_suite);
        localStorage.setItem("no_of_suites", res?.data?.no_of_suite);
        localStorage.setItem("building_namee", res?.data?.building_name);
      } else if (res.status === 400) {
        notifyError(res.message);
      } else if (res.status === 300) {
        // notifyError(res.message);
        navigate("*");
      }
    }
  };

  const addComment = async () => {
    let data = {
      purchase_order_id: maintenanceServiceID,
      comment: { comment: addComments },
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setAddComments("");
        getMaintenanceService();
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const deleteComment = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            purchase_order_id: maintenanceServiceID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            purchase_order_id: maintenanceServiceID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getMaintenanceService();
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Maintenance Service Details</span>
              </div>
              <div className="details">
                <div className="detailContent">
                  <h4>Maintenance Service ID</h4>
                  <h2 className="heading-controls-com">
                    {maintenanceOrderListing?.order_id}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>Created Date</h4>
                  <h2 className="heading-controls-com">
                    {getDateOnly(maintenanceOrderListing.created_at)}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>Type</h4>
                  <h2 className="heading-controls-com">
                    {capatalizeLetters(
                      maintenanceOrderListing?.order_type +
                        " " +
                        maintenanceOrderListing?.order_type
                    )}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>Assigned Trade</h4>
                  <h2 className="heading-controls-com">
                    {maintenanceOrderListing?.trade_info?.company}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>Created By</h4>
                  <h2 className="heading-controls-com">
                    {capatalizeLetters(
                      maintenanceOrderListing?.order_requested_by_user_info
                        ?.first_name +
                        "_" +
                        maintenanceOrderListing?.order_requested_by_user_info
                          ?.last_name
                    )}
                  </h2>
                </div>
                {/* {maintenanceOrderListing?.invoice_number ? (
                  <div className="detailContent">
                    <h4>Invoice Number</h4>
                    <h2>{maintenanceOrderListing?.invoice_number}</h2>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="detailContent">
                  <h4>State</h4>
                  <h2
                    className={`badge heading-controls-com ${
                      maintenanceOrderListing?.order_state === "PENDING"
                        ? "grey"
                        : maintenanceOrderListing?.order_state === "APPROVED"
                        ? "success"
                        : maintenanceOrderListing?.order_state === "CONFIRM"
                        ? "success"
                        : "danger"
                    }`}
                  >
                    {maintenanceOrderListing?.order_state}
                  </h2>
                </div>
              </div>
            </div>
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Logs</span>
              </div>
              <div className="commentLogs">
                {maintenanceOrderListing?.logs?.map((v, i) => {
                  return (
                    <div key={i} className="comment">
                      <h2>
                        {capatalizeLetters(
                          v?.created_by_user_info?.first_name +
                            "_" +
                            v?.created_by_user_info?.last_name
                        )}{" "}
                        <span>{getDate(v.created_at)}</span>
                      </h2>
                      <p>{v.log_detail?.log}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-9">
            <div className="contentCard mb-0">
              <div className="titleWrapper">
                <span>Verify Service</span>
              </div>
              <div className="mainTable table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Log Hrs.</th>
                      <th>Apt. No.</th>
                      <th>Price</th>
                      <th className="text-center">Completed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {maintenanceServiceItems &&
                      maintenanceServiceItems.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <b>{v.description}</b>
                            </td>
                            {v?.is_complete === false ? (
                              <td>
                                <span
                                  className={` ${
                                    v?.is_complete ? "success" : "danger"
                                  }`}
                                >
                                  <small>-</small>
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span
                                  className={` ${
                                    v?.is_complete ? "success" : "danger"
                                  }`}
                                >
                                  <small>
                                    <b>{getDateOnly(v.received_date)}</b>
                                  </small>
                                  {v?.time_logs?.map((val, ind) => {
                                    return (
                                      <small key={ind}>
                                        {" "}
                                        (
                                        {val.time_in === "Invalid date"
                                          ? "No Time In Added"
                                          : val.time_in}{" "}
                                        -{" "}
                                        {val.time_out === "Invalid date"
                                          ? "No Time Out Added"
                                          : val.time_out}
                                        )
                                      </small>
                                    );
                                  })}
                                </span>
                              </td>
                            )}
                            <td>{v.suite_no}</td>
                            <td>
                              <b>
                                {v.price
                                  ? "$" +
                                    ((v.price * 100) / 100).toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 2 }
                                    )
                                  : ""}
                              </b>
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                className="cursoreRadio"
                                checked={v.is_complete ? true : false}
                                disabled
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="cardFooter">
                <Link className="myBtn bdr" to={PATH.VIEW_EXISTING}>
                  Back to Listing
                </Link>
              </div>
            </div>
            {maintenanceOrderListing?.documents?.length === 0 ? (
              ""
            ) : (
              <div className="contentCard commentWrapper mt-3">
                <div className="titleWrapper">
                  <span>Documents</span>
                </div>
                <div className="row documentDisplayWrapper">
                  {maintenanceOrderListing?.documents?.map((v, i) => {
                    return (
                      <>
                        {v.mime_type === "image" ? (
                          <>
                            <a
                              className="imageDisplay col-lg-3 col-md-3 col-sm-12 mt-2 mb-2"
                              href={`${baseDomain + v.url}`}
                              download={v.original_name}
                              target="_blank"
                              key={i}
                            >
                              <div className="imageView">{v.original_name}</div>
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              className="documentDisplay col-lg-3 col-md-3 col-sm-12 mt-2 mb-2"
                              href={`${baseDomain + v.url}`}
                              download={v.original_name}
                              target="_blank"
                              key={i}
                            >
                              <div className="fileView">{v.original_name}</div>
                            </a>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="contentCard commentWrapper mt-3">
              <div className="formDesign">
                <div className="form-group">
                  <label>
                    Comments <small>(optional)</small>
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={addComments}
                    onChange={(e) => setAddComments(e.target.value)}
                  ></textarea>
                </div>
                <button
                  className="myBtn success mb-2"
                  onClick={() => addComment()}
                  disabled={!addComments ? true : false}
                >
                  Add Comment
                </button>
              </div>
              <div className="commentLogs">
                {maintenanceOrderListing?.comments?.map((v, i) => {
                  return (
                    <div key={i} className="comment">
                      <h2>
                        {capatalizeLetters(
                          v?.created_by_user_info?.first_name +
                            "_" +
                            v?.created_by_user_info?.last_name
                        )}{" "}
                        <span>
                          {getDate(v.created_at)}{" "}
                          {localStorage.getItem("role") === "viewer" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : localStorage.getItem("role") ===
                            "building_manager" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : localStorage.getItem("role") ===
                            "property_manager" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : (
                            <a
                              href="#"
                              className="circleBtn danger deleteThis ml-2"
                              onClick={() => {
                                deleteComment([v._id]);
                              }}
                            >
                              <Delete2 />
                            </a>
                          )}
                        </span>
                      </h2>
                      <p>{v.comment}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
