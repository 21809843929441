import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { adminApiCall } from "../../../helpers/ajax_request";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Index() {
  const navigate = useNavigate();
  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const initialValues = {
    categoryTitle: "",
  };

  let validationSchema = yup.object({
    categoryTitle: yup
      .string()
      .trim()
      .required("Please specify category title."),
  });

  const onSubmit = async (values) => {
    let data = {
      category_name: formik.values.categoryTitle,
      // description: "",
    };

    let res = await adminApiCall(
      "/api/admin/inventory_category/create",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Category Added Successfully!");
        navigate(PATH.MANAGE_CATEGORY);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <form className="contentCard" onSubmit={formik.handleSubmit}>
            <div className="titleWrapper">
              <span>Add Category</span>
            </div>
            <div className="formDesign">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Category Title</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.categoryTitle &&
                        formik.touched.categoryTitle
                          ? " is-invalid"
                          : "")
                      }
                      name="categoryTitle"
                      value={formik.values.categoryTitle}
                      onChange={formik.handleChange}
                      type="text"
                    />
                    <div className="invalid-feedback">
                      {formik.errors.categoryTitle}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cardFooter">
              <button className="myBtn mr-1" type="submit">
                Add
              </button>
              <Link className="myBtn bdr" to={PATH.MANAGE_CATEGORY}>
                Cancel
              </Link>
            </div>
          </form>
        </section>
      </>
    </MainLayout>
  );
}
