import React from "react";

export function PurchaseOrder() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 28 28"
    >
      <g transform="translate(-35.362 -511.523)">
        <path
          d="M8.579,5.566V27.613a.961.961,0,0,1-.959.959h0a.961.961,0,0,1-.959-.959V7.757h-1.1A2.189,2.189,0,0,0,3.375,9.948V29.667a2.189,2.189,0,0,0,2.191,2.191H29.743a2.117,2.117,0,0,0,2.116-2.116V5.566a2.189,2.189,0,0,0-2.191-2.191l-19.035.137A1.963,1.963,0,0,0,8.579,5.566ZM13.1,7.757h5.751a.961.961,0,0,1,.959.959h0a.961.961,0,0,1-.959.959H13.1a.961.961,0,0,1-.959-.959h0A.961.961,0,0,1,13.1,7.757Zm0,10.955H23.231a.961.961,0,0,1,.959.959h0a.961.961,0,0,1-.959.959H13.1a.961.961,0,0,1-.959-.959h0A.961.961,0,0,1,13.1,18.712Zm13.42,7.395H13.1a.961.961,0,0,1-.959-.959h0a.961.961,0,0,1,.959-.959h13.42a.961.961,0,0,1,.959.959h0A.961.961,0,0,1,26.518,26.107Zm0-10.955H13.1a.961.961,0,0,1-.959-.959h0a.961.961,0,0,1,.959-.959h13.42a.961.961,0,0,1,.959.959h0A.961.961,0,0,1,26.518,15.152Z"
          transform="translate(31.987 508.148)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export function WorkConfirmation() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 29.25 29.25"
    >
      <path
        d="M12,15.221,9.956,17.269l6.581,6.581L31.163,9.225,29.115,7.178l-12.578,12.5ZM29.7,18A11.65,11.65,0,1,1,21.218,6.739l2.267-2.267A13.6,13.6,0,0,0,18,3.375,14.625,14.625,0,1,0,32.625,18Z"
        transform="translate(-3.375 -3.375)"
        fill="currentColor"
      />
    </svg>
  );
}
export function rejectOrder() {
  return (
    <svg
      class="svg-icon"
      style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M622.276923 508.061538l257.969231-257.96923c11.815385-11.815385 11.815385-29.538462 0-41.353846l-41.353846-41.353847c-11.815385-11.815385-29.538462-11.815385-41.353846 0L539.569231 425.353846c-7.876923 7.876923-19.692308 7.876923-27.569231 0L254.030769 165.415385c-11.815385-11.815385-29.538462-11.815385-41.353846 0l-41.353846 41.353846c-11.815385 11.815385-11.815385 29.538462 0 41.353846l257.969231 257.969231c7.876923 7.876923 7.876923 19.692308 0 27.56923L169.353846 793.6c-11.815385 11.815385-11.815385 29.538462 0 41.353846l41.353846 41.353846c11.815385 11.815385 29.538462 11.815385 41.353846 0L512 618.338462c7.876923-7.876923 19.692308-7.876923 27.569231 0l257.969231 257.96923c11.815385 11.815385 29.538462 11.815385 41.353846 0l41.353846-41.353846c11.815385-11.815385 11.815385-29.538462 0-41.353846L622.276923 535.630769c-5.907692-7.876923-5.907692-19.692308 0-27.569231z"         fill="currentColor"
/>
    </svg>
  );
}
export function WorkOrder() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 23 31"
    >
      <g transform="translate(-0.474 0.402)">
        <g
          transform="translate(0.474 3.598)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <rect width="23" height="27" rx="4" stroke="none" />
          <rect x="1" y="1" width="21" height="25" rx="3" fill="none" />
        </g>
        <line
          x2="8.477"
          transform="translate(3.498 20.201)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
        <line
          x2="8.477"
          transform="translate(3.498 14.955)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
        <line
          x2="13.32"
          transform="translate(5.516 6.525)"
          fill="none"
          stroke="currentColor"
          strokeWidth="3"
        />
        <path
          d="M2.023,8.932.087,7a.3.3,0,0,1,0-.421l.421-.421a.3.3,0,0,1,.421,0l1.3,1.3L5.027,4.664a.3.3,0,0,1,.421,0l.421.421a.3.3,0,0,1,0,.421L2.444,8.932A.3.3,0,0,1,2.023,8.932Z"
          transform="translate(14.247 7.55)"
          fill="currentColor"
        />
        <path
          d="M2.023,8.932.087,7a.3.3,0,0,1,0-.421l.421-.421a.3.3,0,0,1,.421,0l1.3,1.3L5.027,4.664a.3.3,0,0,1,.421,0l.421.421a.3.3,0,0,1,0,.421L2.444,8.932A.3.3,0,0,1,2.023,8.932Z"
          transform="translate(14.247 12.598)"
          fill="currentColor"
        />
        <g
          transform="translate(8.474 -0.402)"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="6"
        >
          <ellipse cx="3.5" cy="4" rx="3.5" ry="4" stroke="none" />
          <ellipse cx="3.5" cy="4" rx="0.5" ry="1" fill="none" />
        </g>
      </g>
    </svg>
  );
}

export function TrafficSheet() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 26.899 28.068"
    >
      <path
        d="M16.152,277.36a.585.585,0,0,0-.637.126l-1.925,1.926-1.925-1.926a.585.585,0,0,0-1,.414v11.7a.585.585,0,0,0,.585.585h4.678a.585.585,0,0,0,.585-.585V277.9A.585.585,0,0,0,16.152,277.36Z"
        transform="translate(-10.666 -262.112)"
        fill="currentColor"
      />
      <path
        d="M142,192.163a.585.585,0,0,0-.827,0l-2.339,2.339a.585.585,0,0,0-.172.414v14.034a.585.585,0,0,0,.585.585h4.678a.585.585,0,0,0,.585-.585V194.916a.585.585,0,0,0-.171-.414Z"
        transform="translate(-131.649 -181.467)"
        fill="currentColor"
      />
      <path
        d="M270,149.5a.585.585,0,0,0-.827,0l-2.339,2.339a.585.585,0,0,0-.172.414v16.373a.585.585,0,0,0,.585.585h4.678a.585.585,0,0,0,.585-.585V152.249a.584.584,0,0,0-.171-.414Z"
        transform="translate(-252.632 -141.139)"
        fill="currentColor"
      />
      <path
        d="M400.343,108.007l-1.17-1.17a.585.585,0,0,0-.414-.171h-1.17a.585.585,0,0,0-.414.171l-2.339,2.339a.585.585,0,0,0-.171.414V128.3a.585.585,0,0,0,.585.585h4.678a.585.585,0,0,0,.585-.585V108.421A.585.585,0,0,0,400.343,108.007Z"
        transform="translate(-373.615 -100.819)"
        fill="currentColor"
      />
      <path
        d="M37.114,0H33.606a.585.585,0,0,0-.414,1l1.341,1.34L30.1,6.775,28.172,4.849a.585.585,0,0,0-.827,0L23.08,9.114,21.155,7.188a.585.585,0,0,0-.827,0l-6.6,6.6L11.8,11.866a.585.585,0,0,0-.827.827l2.339,2.339a.585.585,0,0,0,.827,0l6.6-6.6,1.925,1.925a.585.585,0,0,0,.827,0L27.758,6.09l1.925,1.925a.585.585,0,0,0,.827,0l4.85-4.849,1.34,1.34a.585.585,0,0,0,.414.172.578.578,0,0,0,.223-.044.585.585,0,0,0,.361-.54V.585A.585.585,0,0,0,37.114,0Z"
        transform="translate(-10.8 0)"
        fill="currentColor"
      />
    </svg>
  );
}

export function Logout() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 22.549 19.73"
    >
      <path
        d="M8.456,0V2.819H19.73V16.912H8.456V19.73H22.549V0ZM5.637,5.637,0,9.865l5.637,4.228V11.274H16.912V8.456H5.637Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Home() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 19.246 19.245"
    >
      <rect
        width="6.415"
        height="7.217"
        rx="1"
        transform="translate(6.339 12.029)"
        fill="currentColor"
      />
      <path
        d="M15.99,9.384,6.75,18.55v7.685c0,1.368.481,2.228,1.56,2.228h2.72a.846.846,0,0,0,.8-.883V20.727a.845.845,0,0,1,.8-.883h7.484a.845.845,0,0,1,.8.883v6.849a.931.931,0,0,0,.234.626.767.767,0,0,0,.568.26h2.751c1.056,0,1.526-.948,1.526-2.228V18.544l-9.238-9.16A.568.568,0,0,0,15.99,9.384Z"
        transform="translate(-6.75 -9.235)"
        fill="currentColor"
      />
    </svg>
  );
}

export function Buildings() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 26.837 30.671"
    >
      <g>
        <path
          d="M26.118,28.754h-1.2V1.438A1.438,1.438,0,0,0,23.483,0H3.355A1.438,1.438,0,0,0,1.917,1.438V28.754H.719A.719.719,0,0,0,0,29.473v1.2H26.837v-1.2A.719.719,0,0,0,26.118,28.754ZM7.668,4.553a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719v2.4a.719.719,0,0,1-.719.719h-2.4a.719.719,0,0,1-.719-.719Zm0,5.751a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719v2.4a.719.719,0,0,1-.719.719h-2.4a.719.719,0,0,1-.719-.719Zm3.115,8.866h-2.4a.719.719,0,0,1-.719-.719v-2.4a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719v2.4A.719.719,0,0,1,10.783,19.17Zm4.553,9.585H11.5V23.722A.719.719,0,0,1,12.221,23h2.4a.719.719,0,0,1,.719.719Zm3.834-10.3a.719.719,0,0,1-.719.719h-2.4a.719.719,0,0,1-.719-.719v-2.4a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719Zm0-5.751a.719.719,0,0,1-.719.719h-2.4a.719.719,0,0,1-.719-.719V10.3a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719Zm0-5.751a.719.719,0,0,1-.719.719h-2.4a.719.719,0,0,1-.719-.719v-2.4a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export function Inventory() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 23.095 30.794"
    >
      <g transform="translate(-38 -288.214)">
        <path
          d="M20.209,3.849H15.4a3.849,3.849,0,1,0-7.7,0H2.887A2.888,2.888,0,0,0,0,6.736V27.907a2.888,2.888,0,0,0,2.887,2.887H20.209A2.888,2.888,0,0,0,23.1,27.907V6.736A2.888,2.888,0,0,0,20.209,3.849ZM5.774,25.5a1.443,1.443,0,1,1,1.443-1.443A1.44,1.44,0,0,1,5.774,25.5Zm0-5.774a1.443,1.443,0,1,1,1.443-1.443A1.44,1.44,0,0,1,5.774,19.727Zm0-5.774A1.443,1.443,0,1,1,7.217,12.51,1.44,1.44,0,0,1,5.774,13.954ZM11.548,2.406A1.443,1.443,0,1,1,10.1,3.849,1.44,1.44,0,0,1,11.548,2.406Zm7.7,22.133a.483.483,0,0,1-.481.481H10.1a.483.483,0,0,1-.481-.481v-.962A.483.483,0,0,1,10.1,23.1h8.661a.483.483,0,0,1,.481.481Zm0-5.774a.483.483,0,0,1-.481.481H10.1a.483.483,0,0,1-.481-.481V17.8a.483.483,0,0,1,.481-.481h8.661a.483.483,0,0,1,.481.481Zm0-5.774a.483.483,0,0,1-.481.481H10.1a.483.483,0,0,1-.481-.481v-.962a.483.483,0,0,1,.481-.481h8.661a.483.483,0,0,1,.481.481Z"
          transform="translate(38 288.214)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export function Trade() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 32.478 28.941"
    >
      <g transform="translate(-36.001 -366.162)">
        <g transform="translate(36.001 366.162)">
          <path
            d="M7.219,14.47a1.551,1.551,0,0,1-1.1-.465L.459,8.351a1.584,1.584,0,0,1,0-2.231L6.116.465A1.574,1.574,0,0,1,8.807,1.58V3.087h16.8a.79.79,0,0,1,.789.79v6.718a.79.79,0,0,1-.789.789H22.961a7.515,7.515,0,0,0-13.185,0H8.807v1.507A1.569,1.569,0,0,1,7.219,14.47Z"
            transform="translate(0)"
            fill="currentColor"
          />
          <path
            d="M19.179,14.471a1.57,1.57,0,0,1-1.59-1.581V11.383H.787A.788.788,0,0,1,0,10.6V3.876a.788.788,0,0,1,.787-.787H3.228a7.516,7.516,0,0,0,14.127,0h.234V2.314A7.567,7.567,0,0,0,17.8.783,1.6,1.6,0,0,1,19.179,0a1.552,1.552,0,0,1,1.1.465L25.937,6.12a1.577,1.577,0,0,1,0,2.231l-5.655,5.657A1.558,1.558,0,0,1,19.179,14.471Z"
            transform="translate(6.078 14.47)"
            fill="currentColor"
          />
          <path
            d="M16.092,9.994a2.5,2.5,0,0,1-.654,1.732,2.823,2.823,0,0,1-1.7.9v1.151a.2.2,0,0,1-.21.21H12.64a.213.213,0,0,1-.21-.21V12.624a4.144,4.144,0,0,1-.838-.2,4.037,4.037,0,0,1-1.154-.608,3.748,3.748,0,0,1-.306-.247q-.082-.079-.115-.118a.2.2,0,0,1-.013-.27l.677-.888a.2.2,0,0,1,.151-.079.163.163,0,0,1,.158.059l.013.013a3.433,3.433,0,0,0,1.6.822,2.3,2.3,0,0,0,.487.053,1.594,1.594,0,0,0,.937-.283.921.921,0,0,0,.4-.8.667.667,0,0,0-.1-.348,1.232,1.232,0,0,0-.22-.276,1.8,1.8,0,0,0-.385-.247q-.263-.135-.434-.21t-.526-.214l-.4-.164q-.148-.059-.4-.174t-.411-.2q-.154-.089-.371-.233a2.315,2.315,0,0,1-.352-.279,4.1,4.1,0,0,1-.286-.322,1.608,1.608,0,0,1-.233-.381,2.637,2.637,0,0,1-.138-.437,2.282,2.282,0,0,1-.056-.513,2.248,2.248,0,0,1,.644-1.591A3.008,3.008,0,0,1,12.43,3.6V2.414a.213.213,0,0,1,.21-.21h.888a.2.2,0,0,1,.21.21V3.571a3.657,3.657,0,0,1,.726.151,4.212,4.212,0,0,1,.572.22,3.168,3.168,0,0,1,.417.247q.2.138.256.191t.1.092a.19.19,0,0,1,.033.25l-.533.96a.179.179,0,0,1-.151.105.21.21,0,0,1-.178-.046q-.02-.02-.1-.079t-.256-.174a3.392,3.392,0,0,0-.385-.21,2.936,2.936,0,0,0-.49-.171,2.193,2.193,0,0,0-.562-.076,1.709,1.709,0,0,0-1.019.283.884.884,0,0,0-.339,1.045.74.74,0,0,0,.194.273,2.9,2.9,0,0,0,.26.217,2.316,2.316,0,0,0,.368.2q.247.115.4.178t.46.181q.348.131.533.207t.5.23a3.971,3.971,0,0,1,.5.279,4.2,4.2,0,0,1,.408.329,1.7,1.7,0,0,1,.348.417,2.383,2.383,0,0,1,.207.5,2.169,2.169,0,0,1,.085.618Z"
            transform="translate(3.4 6.013)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export function Reports() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 22.33 29.315"
    >
      <g transform="translate(0)">
        <path
          d="M2.577,29.315H19.753a2.579,2.579,0,0,0,2.577-2.577V8.588H16.318a2.579,2.579,0,0,1-2.577-2.577V0H2.577A2.579,2.579,0,0,0,0,2.577V26.739A2.579,2.579,0,0,0,2.577,29.315ZM6.012,12.081H16.318a.859.859,0,0,1,0,1.718H6.012a.859.859,0,1,1,0-1.718Zm0,3.435H16.318a.859.859,0,1,1,0,1.718H6.012a.859.859,0,1,1,0-1.718Zm0,3.435H16.318a.859.859,0,0,1,0,1.718H6.012a.859.859,0,1,1,0-1.718Zm0,3.435h6.871a.859.859,0,1,1,0,1.718H6.012a.859.859,0,1,1,0-1.718Z"
          transform="translate(0)"
          fill="currentColor"
        />
        <path
          d="M.859,6.368H6.368L0,0V5.509A.859.859,0,0,0,.859,6.368Z"
          transform="translate(15.459 0.503)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export function Users() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 28.538 29.339"
    >
      <g transform="translate(-34.766 -843.472)">
        <g transform="translate(34.766 843.472)">
          <path
            d="M13.531,15.223A7.611,7.611,0,1,0,5.92,7.611,7.613,7.613,0,0,0,13.531,15.223ZM20.3,16.914H17.384a9.2,9.2,0,0,1-7.706,0H6.766A6.765,6.765,0,0,0,0,23.68v.846a2.538,2.538,0,0,0,2.537,2.537H24.525a2.538,2.538,0,0,0,2.537-2.537V23.68A6.765,6.765,0,0,0,20.3,16.914Z"
            fill="currentColor"
          />
          <path
            d="M15.15,10.515a4.719,4.719,0,0,0,0-1.341l1.444-1.129a.345.345,0,0,0,.082-.438L15.307,5.238a.344.344,0,0,0-.417-.151l-1.7.684A5,5,0,0,0,12.029,5.1l-.26-1.814A.334.334,0,0,0,11.434,3H8.7a.334.334,0,0,0-.335.287L8.1,5.1a5.258,5.258,0,0,0-1.157.671l-1.7-.684a.334.334,0,0,0-.417.151L3.453,7.606a.337.337,0,0,0,.082.438L4.98,9.173a5.428,5.428,0,0,0-.048.671,5.428,5.428,0,0,0,.048.671L3.536,11.644a.345.345,0,0,0-.082.438L4.822,14.45a.344.344,0,0,0,.417.151l1.7-.684a5,5,0,0,0,1.157.671l.26,1.814a.334.334,0,0,0,.335.287h2.738a.334.334,0,0,0,.335-.287l.26-1.814a5.258,5.258,0,0,0,1.157-.671l1.7.684a.334.334,0,0,0,.417-.151l1.369-2.368a.345.345,0,0,0-.082-.438L15.15,10.515Zm-5.085,1.725a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,10.065,12.239Z"
            transform="translate(3.218 17.218)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export function Settings() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 28.538 29.339"
    >
      <g transform="translate(16.088 -955.896)">
        <path
          d="M28.578,19.107a11.431,11.431,0,0,0,.1-1.438,11.431,11.431,0,0,0-.1-1.438l3.1-2.42a.74.74,0,0,0,.176-.939L28.915,7.8a.737.737,0,0,0-.895-.323L24.368,8.941A10.718,10.718,0,0,0,21.889,7.5l-.557-3.887A.715.715,0,0,0,20.612,3H14.744a.715.715,0,0,0-.719.616L13.468,7.5a11.27,11.27,0,0,0-2.479,1.438L7.336,7.474a.716.716,0,0,0-.895.323L3.508,12.873a.723.723,0,0,0,.176.939l3.1,2.42a11.634,11.634,0,0,0-.1,1.438,11.634,11.634,0,0,0,.1,1.438l-3.1,2.42a.74.74,0,0,0-.176.939l2.934,5.076a.737.737,0,0,0,.895.323L10.989,26.4a10.717,10.717,0,0,0,2.479,1.438l.557,3.887a.715.715,0,0,0,.719.616h5.868a.715.715,0,0,0,.719-.616l.557-3.887A11.27,11.27,0,0,0,24.368,26.4l3.653,1.467a.716.716,0,0,0,.895-.323l2.934-5.076a.74.74,0,0,0-.176-.939l-3.1-2.42Zm-10.9,3.7a5.134,5.134,0,1,1,5.134-5.134A5.14,5.14,0,0,1,17.678,22.8Z"
          transform="translate(-19.494 952.896)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export function Notification() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 20.148 25.191"
    >
      <g transform="translate(-1672.228 -15.404)">
        <path
          d="M17.639,31.673c1.958,0,3.028-1.385,3.028-3.337H14.6C14.6,30.288,15.674,31.673,17.639,31.673Z"
          transform="translate(1664.647 8.923)"
          fill="currentColor"
        />
        <path
          d="M26.647,22.586c-.97-1.278-2.877-2.027-2.877-7.751,0-5.874-2.594-8.235-5.012-8.8-.227-.057-.39-.132-.39-.371V5.479a1.536,1.536,0,1,0-3.073,0v.183c0,.233-.164.315-.39.371-2.424.573-5.012,2.928-5.012,8.8,0,5.723-1.908,6.466-2.877,7.751a1.25,1.25,0,0,0,1,2H25.652A1.25,1.25,0,0,0,26.647,22.586Z"
          transform="translate(1665.467 11.474)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export function Search({ children, ...props }) {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  24.46 24.46"
      {...props}
    >
      <g transform="translate(1 1)">
        <path
          d="M24.1,14.3a9.8,9.8,0,1,1-9.8-9.8A9.8,9.8,0,0,1,24.1,14.3Z"
          transform="translate(-4.5 -4.5)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M30.3,30.3l-5.328-5.328"
          transform="translate(-8.257 -8.257)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export function Delete() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  24.728 19.783"
    >
      <path
        d="M19.073,20.837h4.946V23.31H19.073Zm0-9.891h8.655v2.473H19.073Zm0,4.946h7.419v2.473H19.073ZM4.236,23.31a2.48,2.48,0,0,0,2.473,2.473h7.419A2.48,2.48,0,0,0,16.6,23.31V10.946H4.236Zm13.6-16.073H14.128L12.891,6H7.946L6.709,7.236H3V9.709H17.837Z"
        transform="translate(-3 -6)"
        fill="currentColor"
      />
    </svg>
  );
}

export function Delete2() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  13.646 17.544"
    >
      <path
        d="M8.475,20.095a1.955,1.955,0,0,0,1.949,1.949h7.8a1.955,1.955,0,0,0,1.949-1.949V8.4H8.475ZM21.146,5.475H17.734L16.76,4.5H11.886l-.975.975H7.5V7.424H21.146Z"
        transform="translate(-7.5 -4.5)"
        fill="currentColor"
      />
    </svg>
  );
}

export function Like() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  18.881 17.54"
    >
      <path
        d="M13.832,22.209l-1.26-1.131C8.1,17.057,5.141,14.4,5.141,11.157a4.7,4.7,0,0,1,4.78-4.73A5.238,5.238,0,0,1,13.832,8.22a5.238,5.238,0,0,1,3.911-1.793,4.7,4.7,0,0,1,4.78,4.73c0,3.247-2.955,5.9-7.43,9.921l-1.26,1.131Z"
        transform="translate(-4.391 -5.677)"
        fill="currentColor"
        stroke="inherit"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export function Edit() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  13.635 14.678"
    >
      <g transform="translate(0.5 0.625)">
        <path
          d="M18,30h7.077"
          transform="translate(-12.653 -16.448)"
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M14.462,4.777A1.565,1.565,0,0,1,16.676,6.99L7.452,16.214,4.5,16.952,5.238,14Z"
          transform="translate(-4.5 -4.318)"
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export function File() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  11.563 14.453"
    >
      <path
        d="M7.445,3A1.443,1.443,0,0,0,6.007,4.445L6,16.008a1.443,1.443,0,0,0,1.438,1.445h8.679a1.45,1.45,0,0,0,1.445-1.445V7.336L13.227,3ZM12.5,8.059V4.084l3.975,3.975Z"
        transform="translate(-6 -3)"
        fill="currentColor"
      />
    </svg>
  );
}

export function Filter() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  24 24"
    >
      <polygon
        points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></polygon>
    </svg>
  );
}

export function Email() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  20.496 13.835"
    >
      <path
        d="M.072,16.678V5.293q0-.02.059-.376l6.7,5.732L.151,17.073a1.675,1.675,0,0,1-.079-.4ZM.961,4.127A.852.852,0,0,1,1.3,4.068H19.343a1.119,1.119,0,0,1,.356.059l-6.72,5.752-.889.712L10.33,12.033,8.571,10.59l-.889-.712Zm.02,13.717,6.74-6.463L10.33,13.5l2.609-2.115,6.74,6.463a.949.949,0,0,1-.336.059H1.3a.9.9,0,0,1-.316-.059ZM13.828,10.65l6.68-5.732a1.18,1.18,0,0,1,.059.376V16.678a1.515,1.515,0,0,1-.059.4Z"
        transform="translate(-0.072 -4.068)"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export function Approve() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  30.583 24.513"
    >
      <path
        d="M20.71,29.513a2.134,2.134,0,0,1-1.513-.627l-6.07-6.07a2.14,2.14,0,1,1,3.027-3.027l4.556,4.556L39.43,5.627a2.14,2.14,0,0,1,3.027,3.027L22.223,28.886A2.133,2.133,0,0,1,20.71,29.513Z"
        transform="translate(-12.5 -5)"
        fill="currentColor"
      />
    </svg>
  );
}

export function Print() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  23.613 20.662"
    >
      <path
        d="M8.474,3.856H20.281V6.808H8.474V3.856ZM24.708,8.284H4.047A1.48,1.48,0,0,0,2.571,9.759v7.379a1.48,1.48,0,0,0,1.476,1.476H8.474v5.9H20.281v-5.9h4.427a1.48,1.48,0,0,0,1.476-1.476V9.759a1.48,1.48,0,0,0-1.476-1.476ZM5.522,12.711A1.476,1.476,0,1,1,7,11.235a1.476,1.476,0,0,1-1.476,1.476ZM18.8,23.042H9.95V15.663H18.8Z"
        transform="translate(-2.571 -3.856)"
        fill="currentColor"
      />
    </svg>
  );
}

export function Void() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  22.893 20.103"
    >
      <g transform="translate(-0.554 -1.897)" fill="currentColor">
        <path d="M12,1.9a3.017,3.017,0,0,1,2.565,1.444l0,0,8.47,14.14.008.014A3,3,0,0,1,20.481,22H3.519A3,3,0,0,1,.954,17.5l.008-.014,8.47-14.14,0,0A3.017,3.017,0,0,1,12,1.9Zm.854,2.479a1,1,0,0,0-1.708,0L2.683,18.506A1,1,0,0,0,3.536,20H20.464a1,1,0,0,0,.854-1.494Z" />
        <path
          d="M0,5A1,1,0,0,1-1,4V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V4A1,1,0,0,1,0,5Z"
          transform="translate(12 9)"
        />
        <path
          d="M.01,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H.01a1,1,0,0,1,1,1A1,1,0,0,1,.01,1Z"
          transform="translate(12 17)"
        />
      </g>
    </svg>
  );
}

export function Export() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 26.565 23.615"
    >
      <path
        d="M17.711,5.622a1.1,1.1,0,0,0-.323-.779L12.873.323A1.106,1.106,0,0,0,12.089,0h-.281V5.9h5.9Zm8.625,8.584L21.923,9.76a.74.74,0,0,0-1.264.521v3H17.707v2.952h2.952v3.007a.74.74,0,0,0,1.264.521l4.414-4.451A.785.785,0,0,0,26.337,14.206ZM8.856,15.5V14.022a.74.74,0,0,1,.738-.738h8.118V7.38H11.439a1.11,1.11,0,0,1-1.107-1.107V0H1.107A1.1,1.1,0,0,0,0,1.107v21.4a1.1,1.1,0,0,0,1.107,1.107H16.6a1.1,1.1,0,0,0,1.107-1.107V16.235H9.594A.74.74,0,0,1,8.856,15.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Hide() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0  24 24"
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      >
        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
        <line x1="1" y1="1" x2="23" y2="23"></line>
      </g>
    </svg>
  );
}

export function UserIcon() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 18.697 16.36"
    >
      <g transform=" translate(0 -32)" fill="currentColor">
        <g transform="translate(9.138 32)">
          <g>
            <path
              d="M252.778,32a3.5,3.5,0,0,0-2.357.911.584.584,0,0,0,.786.865,2.335,2.335,0,1,1,2.022,4.019.584.584,0,0,0,.11,1.158.6.6,0,0,0,.111-.011A3.5,3.5,0,0,0,252.778,32Z"
              transform="translate(-250.23 -32)"
            />
          </g>
        </g>
        <g transform="translate(10.762 40.184)">
          <g>
            <path
              d="M302.64,259.008a.6.6,0,0,0-.025-.12.579.579,0,0,0-.029-.1.516.516,0,0,0-.057-.085.627.627,0,0,0-.077-.1.243.243,0,0,0-.016-.02,10.5,10.5,0,0,0-7.165-2.462.585.585,0,0,0-.561.606.577.577,0,0,0,.606.561,9.331,9.331,0,0,1,6.16,2.031v1.464h-1.753a.584.584,0,0,0,0,1.169h1.753a1.171,1.171,0,0,0,1.169-1.169v-1.753C302.644,259.023,302.64,259.016,302.64,259.008Z"
              transform="translate(-294.709 -256.115)"
            />
          </g>
        </g>
        <g transform="translate(3.506 34.337)">
          <g>
            <path
              d="M99.506,96a3.506,3.506,0,1,0,3.506,3.506A3.51,3.51,0,0,0,99.506,96Zm0,5.843a2.337,2.337,0,1,1,2.337-2.337A2.34,2.34,0,0,1,99.506,101.843Z"
              transform="translate(-96 -96)"
            />
          </g>
        </g>
        <g transform="translate(0 42.513)">
          <g>
            <path
              d="M14.018,322.8a.594.594,0,0,0-.025-.12.584.584,0,0,0-.029-.1.512.512,0,0,0-.058-.086.554.554,0,0,0-.077-.1c-.007-.006-.009-.014-.016-.02a10.578,10.578,0,0,0-13.606,0c-.007.006-.009.014-.016.02a.574.574,0,0,0-.077.1.639.639,0,0,0-.057.085.584.584,0,0,0-.029.1A.594.594,0,0,0,0,322.8c0,.008,0,.015,0,.025v1.753a1.17,1.17,0,0,0,1.169,1.169H12.854a1.171,1.171,0,0,0,1.169-1.169v-1.753C14.023,322.812,14.018,322.8,14.018,322.8Zm-1.164,1.777H1.169V323.11a9.4,9.4,0,0,1,11.686,0Z"
              transform="translate(0 -319.896)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function PasswordIcon() {
  return (
    <svg
      className="icon-definitions"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 12.19 16"
    >
      <path
        d="M12.1,13.69a1.524,1.524,0,1,0-1.524-1.524A1.528,1.528,0,0,0,12.1,13.69Zm4.571-6.857H15.9V5.31a3.81,3.81,0,1,0-7.619,0H9.733a2.362,2.362,0,1,1,4.724,0V6.833H7.524A1.528,1.528,0,0,0,6,8.357v7.619A1.528,1.528,0,0,0,7.524,17.5h9.143a1.528,1.528,0,0,0,1.524-1.524V8.357A1.528,1.528,0,0,0,16.667,6.833Zm0,9.143H7.524V8.357h9.143Z"
        transform="translate(-6 -1.5)"
        fill="currentColor"
      />
    </svg>
  );
}
