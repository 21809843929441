import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { useFormik } from "formik";
import * as yup from "yup";
import { adminApiCall } from "../../../helpers/ajax_request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomPhone from "../../../components/CustomPhone/Index";

export default function Index() {
  const params = useParams();
  const tradeID = params.id;
  // console.log(tradeID);
  toast.configure();
  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const navigate = useNavigate();
  const [tradeData, setTradeData] = useState([]);

  const initialValues = {
    company: "",
    fName: "",
    lName: "",
    phone: "",
    fax: "",
    email: "",
    note: "",
  };

  useEffect(() => {
    getTradeData();
  }, []);

  const getTradeData = async () => {
    let data = {
      trade_id: tradeID,
    };
    let res = await adminApiCall(
      "/api/admin/trade/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setTradeData(res.data);
        formik.setValues({
          company: res.data?.company,
          fName: res.data?.first_name,
          lName: res.data?.last_name,
          phone: res.data?.phone_no,
          fax: res.data?.fax,
          email: res.data?.email,
          note: res.data?.note_or_description,
        });
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  let validationSchema = yup.object({
    company: yup
      .string("Enter Description.")
      .trim()
      .required("Please provide comapny name."),
    // fName: yup
    //   .string("Enter first name.")
    //   .trim()
    //   .min(2, "Please enter a valid name.")
    //   .max(50, "Please enter a valid name.")
    //   .required("Please specify first name."),
    // lName: yup
    //   .string("Enter last name.")
    //   .trim()
    //   .min(2, "Please enter a valid name.")
    //   .max(10, "Please enter a valid name.")
    //   .required("Please specify last name."),
    email: yup
      .string("Enter email.")
      .trim()
      .email("Please enter a valid email address.")
      .required("Email is required."),
    // fax: yup
    //   .string()
    //   // .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, "Enter Phone No000.")
    //   .trim()
    //   .min(10, "Please enter 10 digit number.")
    //   .max(10, "16 digit number exceeded.")
    //   .required("Please specify fax No."),
    // phone: yup
    //   .string()
    //   // .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, "Enter Phone No000.")
    //   .trim()
    //   .min(10, "Please enter 10 digit number.")
    //   .max(10, "16 digit number exceeded.")
    //   .required("Please specify Phone No."),
  });

  const onSubmit = async (values) => {
    // console.log("all okay", values);
    let data = {
      trade_id: tradeID,
      company: values.company,
      ...(values.fName && {
        first_name: values.fName === "" ? "" : values.fName,
      }),
      ...(values.lName && {
        last_name: values.lName === "" ? "" : values.lName,
      }),
      ...(values.phone && {
        phone_no: values.phone === "" ? "" : values.phone,
      }),
      ...(values.fax && {
        fax: values.fax === "" ? "" : values.fax,
      }),
      ...(values.email && {
        email: values.email === "" ? "" : values.email,
      }),
      ...(values.note && {
        note: values.note === "" ? "" : values.note,
      }),
    };
    // console.log("data sent: ", categoryNames);

    let res = await adminApiCall(
      "/api/admin/trade/update",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again.");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Trade Updated Successfully!");
        navigate(PATH.VIEW_TRADES);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handlePhoneNumberChange = (phone) => {
    let output = "";
    if (phone.length === 11) {
      let cc = phone.substr(0, 1);
      let area = phone.substr(1, 3);
      let pre = phone.substr(4, 3);
      let tel = phone.substr(7, 4);

      output = `+${cc} (${area}) ${pre} ${tel}`;
      // console.log("if", phone);
      formik.setFieldValue("phone", phone);
    } else {
      if (phone.length < 11) {
        // console.log("length zero");
      } else {
        // console.log("not zero");
      }
      // console.log("else", phone);
      formik.setFieldValue("phone", phone);
    }
  };

  const handleContactNumberChange = (phone) => {
    let output = "";
    if (phone.length === 11) {
      let cc = phone.substr(0, 1);
      let area = phone.substr(1, 3);
      let pre = phone.substr(4, 3);
      let tel = phone.substr(7, 4);

      output = `+${cc} (${area}) ${pre} ${tel}`;
      // console.log("if", phone);
      formik.setFieldValue("fax", phone);
    } else {
      if (phone.length < 11) {
        // console.log("length zero");
      } else {
        // console.log("not zero");
      }
      // console.log("else", phone);
      formik.setFieldValue("fax", phone);
    }
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <form className="contentCard" onSubmit={formik.handleSubmit}>
            <div className="titleWrapper">
              <span>Edit Trade</span>
            </div>
            <div className="formDesign">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Company</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.company && formik.touched.company
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="company"
                      value={formik.values.company}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.company}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.fName && formik.touched.fName
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="fName"
                      value={formik.values.fName}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.fName}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.lName && formik.touched.lName
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="lName"
                      value={formik.values.lName}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.lName}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Phone</label>
                    <CustomPhone
                      className={
                        "form-control noDrpDown " +
                        (formik.errors.phone && formik.touched.phone
                          ? "is-invalid"
                          : "")
                      }
                      value={formik.values.phone}
                      onChange={(phone) => {
                        // formik.handleChange(phone);
                        handlePhoneNumberChange(phone);
                      }}
                      onlyCountries={["ca"]}
                      name="phone"
                    />
                    <div className="invalid-feedback">
                      {formik.errors.phone}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Fax</label>
                    <CustomPhone
                      className={
                        "form-control noDrpDown" +
                        (formik.errors.fax && formik.touched.fax
                          ? " is-invalid"
                          : "")
                      }
                      value={formik.values.fax}
                      onChange={(contact) => handleContactNumberChange(contact)}
                      onlyCountries={["ca"]}
                      name="fax"
                    />
                    <div className="invalid-feedback">{formik.errors.fax}</div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.email && formik.touched.email
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="form-group">
                    <label>Note</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="note"
                      value={formik.values.note}
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="cardFooter">
              <button className="myBtn mr-1" type="submit">
                Update
              </button>
              <Link className="myBtn bdr" to={PATH.VIEW_TRADES}>
                Cancel
              </Link>
            </div>
          </form>
        </section>
      </>
    </MainLayout>
  );
}
