import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { PATH } from "../../../constants/paths";
import { adminApiCall, userAccessibility } from "../../../helpers/ajax_request";
import {
  Delete,
  Edit,
  Email,
  File,
  PurchaseOrder,
  Search,
} from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Index() {
  const [buildingList, setBuildingList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(10);
  const [perPage, setPerPage] = React.useState(50);
  const [deleteItem, setDeleteItem] = useState([]);
  const [buildingID, setBuildingID] = useState(false);
  const [address, setAddress] = useState(false);
  const [address2, setAddress2] = useState(false);
  const [city, setCity] = useState(false);
  const [postal, setPostal] = useState(false);
  const [province, setProvince] = useState(false);
  const [region, setRegion] = useState(false);
  const [intersection, setIntersection] = useState(false);
  const [phone, setPhone] = useState(false);
  const [noOfSuites, setNoOfSuites] = useState(false);
  const [searchBar, setSearchBar] = useState("");
  const [sortValue, setSortValue] = useState("0");

  const buildingIDSorting = () => {
    setBuildingID(!buildingID);
    setSortValue("1");
  };

  const addressSorting = () => {
    setAddress(!address);
    setSortValue("2");
  };

  const address2Sorting = () => {
    setAddress2(!address2);
    setSortValue("3");
  };

  const citySorting = () => {
    setCity(!city);
    setSortValue("4");
  };

  const postalSorting = () => {
    setPostal(!postal);
    setSortValue("5");
  };

  const provinceSorting = () => {
    setProvince(!province);
    setSortValue("6");
  };

  const regionSorting = () => {
    setRegion(!region);
    setSortValue("7");
  };

  const intersectionSorting = () => {
    setIntersection(!intersection);
    setSortValue("8");
  };

  const phoneSorting = () => {
    setPhone(!phone);
    setSortValue("9");
  };

  const noOfSuitesSorting = () => {
    setNoOfSuites(!noOfSuites);
    setSortValue("10");
  };

  useEffect(() => {
    getBuildingList();
  }, [
    buildingID,
    address,
    address2,
    city,
    postal,
    province,
    region,
    intersection,
    phone,
    noOfSuites,
    searchBar,
    currentPage,
  ]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const searchHandler = (e) => {
    setSearchBar(e.target.value);
  };

  const getBuildingList = async () => {
    let data = {
      current_page: currentPage,
      itemsPerPage: perPage,
      search_value: searchBar,
      ...(sortValue === "1"
        ? {
            sort_by_building_id: buildingID,
          }
        : sortValue === "2"
        ? {
            sort_by_street: address,
          }
        : sortValue === "3"
        ? {
            sort_by_street: address2,
          }
        : sortValue === "4"
        ? {
            sort_by_city: city,
          }
        : sortValue === "5"
        ? {
            sort_by_postal_code: postal,
          }
        : sortValue === "6"
        ? {
            sort_by_province: province,
          }
        : sortValue === "7"
        ? {
            sort_by_region: region,
          }
        : sortValue === "8"
        ? {
            sort_by_intersection: intersection,
          }
        : sortValue === "9"
        ? {
            sort_by_phone: phone,
          }
        : sortValue === "10"
        ? {
            sort_by_no_of_suite: noOfSuites,
          }
        : ""),
    };

    let res = await adminApiCall(
      "/api/admin/building/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setBuildingList(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
        userAccessibility("inventory_category_management", "read");
        if (
          userAccessibility("inventory_category_management", "read") === false
        ) {
          // console.log("Function works in if part");
        } else {
          // console.log("Function works in else part");
        }
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const handleChangeCheckBox = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    let tempArray = [...deleteItem];
    if (checked) {
      tempArray.push(value);
    } else {
      tempArray.splice(tempArray.indexOf(value), 1);
    }
    setDeleteItem(tempArray);
    // }
  };

  const deleteUser = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            building_id: deleteArray,
          };
        } else {
          data = {
            building_id: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/building/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getBuildingList();
            setDeleteItem([]);
            notifySuccess("Building Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  const handleChange = (e) => {
    const itemPerPage = parseInt(e.target.value);
    setPerPage(itemPerPage);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };
  return (
    <MainLayout>
      <section className="pageWrapper cardControl">
        <div className="contentCard">
          <div className="titleWrapper">
            <span>Manage Buildings</span>

            <div className="d-flex justify-content-center align-items-center">
              <div className="search mr-2">
                <div className="searchField">
                  <input
                    className="form-control searchBar"
                    type="text"
                    placeholder="Search"
                    value={searchBar}
                    onChange={searchHandler}
                  />
                  <div className="icon">
                    <Search />
                  </div>
                </div>
              </div>
              <Link className="myBtn shadow ml-auto" to={PATH.ADD_BUILDING}>
                <i className="fa fa-plus-circle"></i> Add Building
              </Link>
            </div>
          </div>

          {loader ? (
            <div className="text-center">
              <img src={Loader} alt="" />
            </div>
          ) : noRecord ? (
            <div className="text-center">
              <img src={NoRecordFound} alt="" />
            </div>
          ) : (
            <div className="mainTable table-responsive dataTable scrollable">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th
                      className="sort"
                      style={{ minWidth: "125px" }}
                      onClick={() => buildingIDSorting()}
                    >
                      Building ID
                    </th>
                    <th className="sort" onClick={() => addressSorting()}>
                      Building Name
                    </th>
                    <th className="sort" onClick={() => address2Sorting()}>
                      Address
                    </th>
                    <th className="sort" onClick={() => citySorting()}>
                      City
                    </th>
                    <th className="sort" onClick={() => postalSorting()}>
                      Postal
                    </th>
                    <th className="sort" onClick={() => provinceSorting()}>
                      Province
                    </th>
                    <th className="sort" onClick={() => regionSorting()}>
                      Region
                    </th>
                    <th className="sort" onClick={() => intersectionSorting()}>
                      Intersection
                    </th>
                    <th
                      className="sort"
                      style={{ minWidth: "180px" }}
                      onClick={() => phoneSorting()}
                    >
                      Phone
                    </th>
                    <th
                      className="sort"
                      style={{ minWidth: "140px" }}
                      onClick={() => noOfSuitesSorting()}
                    >
                      No. Of Apts.
                    </th>
                    <th className="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {buildingList &&
                    buildingList.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <input
                              type="checkbox"
                              className="customCheck"
                              id={"buildingCheck" + i}
                              value={v._id}
                              checked={
                                deleteItem && deleteItem.includes(v._id)
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleChangeCheckBox(e)}
                            />
                          </td>
                          <td>{v.building_id}</td>
                          <td>
                            <b>{v?.building_name}</b>
                          </td>
                          <td>{v.street}</td>
                          <td>{v.city}</td>
                          <td>{v.postal_code}</td>
                          <td>{v.province}</td>
                          <td>{v.region}</td>
                          <td>{v.intersection}</td>
                          <td>
                            {v.phone?.split("")[0] !== undefined ? " (" : ""}
                            {v.phone?.split("")[0] !== undefined
                              ? v.phone?.split("")[0]
                              : ""}
                            {v.phone?.split("")[1] !== undefined
                              ? v.phone?.split("")[1]
                              : ""}
                            {v.phone?.split("")[2] !== undefined
                              ? v.phone?.split("")[2]
                              : ""}
                            {v.phone?.split("")[0] !== undefined ? ") " : ""}
                            {v.phone?.split("")[3] !== undefined
                              ? v.phone?.split("")[3]
                              : ""}
                            {v.phone?.split("")[4] !== undefined
                              ? v.phone?.split("")[4]
                              : ""}
                            {v.phone?.split("")[5] !== undefined
                              ? v.phone?.split("")[5]
                              : ""}
                            {v.phone?.split("")[0] !== undefined ? " - " : ""}
                            {v.phone?.split("")[6] !== undefined
                              ? v.phone?.split("")[6]
                              : ""}
                            {v.phone?.split("")[7] !== undefined
                              ? v.phone?.split("")[7]
                              : ""}
                            {v.phone?.split("")[8] !== undefined
                              ? v.phone?.split("")[8]
                              : ""}
                            {v.phone?.split("")[9] !== undefined
                              ? v.phone?.split("")[9]
                              : ""}
                          </td>
                          <td>{v.no_of_suite}</td>
                          <td className="text-right">
                            <div className="dropleft">
                              <button
                                type="button"
                                className="actionEclips dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                <span></span>
                                <span></span>
                                <span></span>
                              </button>
                              <div className="dropdown-menu dropDesign">
                                <Link to={`${PATH.EDIT_BUILDING}/${v._id}`}>
                                  Edit{" "}
                                  <span>
                                    <Edit />
                                  </span>
                                </Link>
                                {/* <Link to={`${PATH.BUILDING_DETAIL}/${v._id}`}>
                                  Details
                                  <span>
                                    <File />
                                  </span>
                                </Link> */}
                                <Link to={`${PATH.CONTACT}/${v._id}`}>
                                  Contact
                                  <span>
                                    <Email />
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}

          {buildingList && buildingList.length > 0 ? (
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-5">
                Showing {currentPage * perPage - perPage + 1} to{" "}
                {totalRecord > currentPage * perPage
                  ? currentPage * perPage
                  : totalRecord}{" "}
                of {totalRecord} entries
              </div>
              <div className="col-sm-12 col-md-7">
                <div
                  className="dataTables_paginate paging_simple_numbers text-right"
                  id="datatable_paginate"
                >
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={5}
                    onPageChange={(e) => handlePageClick(e)}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    pageClassName={"paginate_button page-item"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="cardFooter">
            <button
              className="myBtn danger squre deleteThis"
              onClick={() => deleteUser(deleteItem)}
              disabled={deleteItem.length === 0 ? true : false}
            >
              <Delete />
            </button>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
