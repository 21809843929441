import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import {
  adminApiCall,
  userAccessibility,
  capatalizeLetters,
  getDate,
} from "../../../helpers/ajax_request";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Delete, Edit, Email, File, Settings } from "../../../Icons/Icons";
import moment from "moment";

export default function Index() {
  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  useEffect(() => {
    listEmailTemplates();
  }, []);

  const [emailTemplates, setEmailTemplates] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);

  const listEmailTemplates = async () => {
    let data = {};

    let res = await adminApiCall(
      "/api/admin/application_setting/email_template/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setLoader(false);
        setNoRecord(false);
        setEmailTemplates(res.data);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Email Templates</span>
            </div>

            {loader ? (
              <div className="text-center">
                <img src={Loader} alt="" />
              </div>
            ) : noRecord ? (
              <div className="text-center">
                <img src={NoRecordFound} alt="" />
              </div>
            ) : (
              <div className="mainTable table-responsive dataTable scrollable">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Templates Name</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emailTemplates &&
                      emailTemplates.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{v?.template_name}</td>
                            <td className="text-right">
                              <div className="dropleft">
                                <button
                                  type="button"
                                  className="actionEclips dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </button>
                                <div className="dropdown-menu dropDesign">
                                  <Link
                                    to={`${PATH.EDIT_EMAIL_TEMPLATE}/${v._id}`}
                                  >
                                    Edit{" "}
                                    <span>
                                      <Edit />
                                    </span>
                                  </Link>
                                  <Link
                                    to={`${PATH.UPDATE_EMAIL_TEMPLATE}/${v._id}`}
                                  >
                                    Settings
                                    <span>
                                      <Settings />
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {localStorage.getItem("role") === "viewer" ? (
              userAccessibility("traffic_sheet_management", "delete") ===
              false ? (
                ""
              ) : (
                <div className="cardFooter">
                  <button
                    className="myBtn danger squre"
                    // onClick={() => deleteUser(deleteItem)}
                    // disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                </div>
              )
            ) : localStorage.getItem("role") === "building_manager" ? (
              userAccessibility("traffic_sheet_management", "delete") ===
              false ? (
                ""
              ) : (
                <div className="cardFooter">
                  <button
                    className="myBtn danger squre"
                    // onClick={() => deleteUser(deleteItem)}
                    // disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                </div>
              )
            ) : localStorage.getItem("role") === "property_manager" ? (
              userAccessibility("traffic_sheet_management", "delete") ===
              false ? (
                ""
              ) : (
                <div className="cardFooter">
                  <button
                    className="myBtn danger squre"
                    // onClick={() => deleteUser(deleteItem)}
                    // disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                </div>
              )
            ) : (
              <div className="cardFooter">
                <button
                  className="myBtn danger squre"
                  // onClick={() => deleteUser(deleteItem)}
                  // disabled={deleteItem.length === 0 ? true : false}
                >
                  <Delete />
                </button>
              </div>
            )}
          </div>
        </section>
      </>
    </MainLayout>
  );
}
