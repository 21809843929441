import React from "react";
import MainLayout from "../../../layouts";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import CreateOrder from "../../../components/CreateOrder/CreateOrder";

export default function Index() {
  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="contentCard ">
            <div className="titleWrapper">
              <span>Create Order</span>
            </div>
            <CreateOrder />
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label>
                    Comments <small>(Optional)</small>
                  </label>
                  <textarea className="form-control" rows="5"></textarea>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
