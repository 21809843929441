import React, { useState, useEffect } from "react";
import { Delete2, Search } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH, PurchaseParts } from "../../../constants/paths";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import AddInventory from "../../Inventory/AddInventory/Index";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  adminApiCall,
  userAccessibility,
  getDate,
  capatalizeLetters,
} from "../../../helpers/ajax_request";
import { Space, Input, InputNumber, Select, Radio } from "antd";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import "antd/dist/antd.css";
import Swal from "sweetalert2";

export default function Index() {
  const params = useParams();
  const purchaseOrderID = params.id;

  const navigate = useNavigate();

  const { TextArea } = Input;

  const [modalShow, setModalShow] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [perPage, setPerPage] = useState(50);
  const [addComments, setAddComments] = useState("");
  const [commentArray, setCommentArray] = useState([]);
  const [commentLength, setCommentLength] = useState(commentArray.length);

  const [category, setCategory] = useState([]);
  const [categoryNames, setCategoryNames] = useState(category[0]?._id);
  const [addToPurchaseList, setAddToPurchaseList] = useState(true);
  const [dateValue, setDateValue] = useState();
  const [trade, setTrade] = useState([]);
  const [tradeNames, setTradeNames] = useState("");

  ////////////////////////////FavouriteList/////////////////////////////
  const [favouriteList, setFavouriteList] = useState([]);
  const [loaderFavourite, setLoaderFavourite] = useState(true);
  const [noRecordFavourite, setNoRecordFavourite] = useState(false);
  const [pageCountFavourite, setPageCountFavourite] = useState(10);
  const [perPageFavourite, setPerPageFavourite] = useState(50);
  const [currentPageFavourite, setCurrentPageFavourite] = useState(1);
  const [totalRecordFavourite, setTotalRecordFavourite] = useState(10);

  const [purchaseOrderListing, setPurchaseOrderListing] = useState({});
  const [addedPurchaseItems, setAddedPurchaseItems] = useState([]);
  // console.log("addedPurchaseItems: ", addedPurchaseItems);
  const [unitNames, setUnitNames] = useState("");
  const [unit, setUnit] = useState([]);

  const [modelNumberEdit, setModelNumberEdit] = useState("");
  const [description, setDescription] = useState("");
  const [suite, setSuite] = useState("");
  const [price, setPrice] = useState(1);
  // console.log(price);
  const [quantity, setQuantity] = useState(1);
  const [searchBar, setSearchBar] = useState("");

  const [categorySort, setCategorySort] = useState(false);
  const [modelNumber, setModelNumber] = useState(false);
  const [expenseCode, setExpenseCode] = useState(false);
  const [priceSorting, setPriceSorting] = useState(false);
  const [descriptionSorting, setDescriptionSorting] = useState(false);
  const [categorySortFavourite, setCategorySortFavourite] = useState(false);
  const [modelNumberFavourite, setModelNumberFavourite] = useState(false);
  const [expenseCodeFavourite, setExpenseCodeFavourite] = useState(false);
  const [priceFavourite, setPriceFavourite] = useState(false);
  const [descriptionFavourite, setDescriptionFavourite] = useState(false);
  const [sortValue, setSortValue] = useState("0");
  const [sortValueFavourite, setSortValueFavourite] = useState("0");
  const [orderStatus, setOrderStatus] = useState("APPROVED");

  const [approveSendEmailCheckBox, setApproveSendEmailCheckBox] =
    useState(false);
  const [orderID, setOrderID] = useState("");

  const [virtualID, setVirtualID] = useState(0);
  // console.log("virtualID: ", virtualID);

  const dateFormat = "DD/MM/YYYY";
  function onChangeDate(date, dateString) {
    setDateValue(dateString);
  }

  const [findBuilding, setFindBuilding] = useState("");
  // console.log("findBuilding ==> ", findBuilding);

  useEffect(() => {
    setBuildingOnNavbar();
  }, [findBuilding]);

  useEffect(() => {
    getInventoryList();
  }, [
    searchBar,
    currentPage,
    perPage,
    categorySort,
    modelNumber,
    expenseCode,
    descriptionSorting,
    price,
  ]);

  useEffect(() => {
    addedComment();
  }, [commentLength]);

  useEffect(() => {
    getPurchaseOrder();
    getCategoryList();
    getTradeList();
    getUnitList();

    if (localStorage.getItem("role") === "viewer") {
      if (userAccessibility("inventory_management", "delete") === false) {
        // console.log("abc")
      } else {
        getFavouriteList();
      }
    } else if (localStorage.getItem("role") === "building_manager") {
      if (userAccessibility("inventory_management", "delete") === false) {
        // console.log("abc")
      } else {
        getFavouriteList();
      }
    } else if (localStorage.getItem("role") === "property_manager") {
      if (userAccessibility("inventory_management", "delete") === false) {
        // console.log("abc")
      } else {
        getFavouriteList();
      }
    } else {
      getFavouriteList();
    }
  }, [
    perPageFavourite,
    currentPageFavourite,
    categorySortFavourite,
    modelNumberFavourite,
    expenseCodeFavourite,
    descriptionFavourite,
    priceFavourite,
  ]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const getUnitList = async () => {
    let data = {
      building_id: localStorage.getItem("building_id"),
    };
    let res = await adminApiCall(
      "/api/admin/unit/unit_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res unittttt", res);
        setUnitNames(res.data[0]?.unit_no);
        setUnit(res.data);
        // setUnitNames(res.data[0]?.unit_no);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getPurchaseOrder = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      order_type: "purchase_parts",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setFindBuilding(res?.data?.building_id);
        // console.log("res unittttt", res.data?.purchase_items);

        setPurchaseOrderListing(res.data);
        setOrderStatus(res.data?.order_state);
        // setAddedPurchaseItems(res.data?.purchase_items);
        // ////////////////
        let tempArray = [res.data?.purchase_items];
        // console.log("old array", tempArray[0]);
        let newArray = [];
        tempArray[0].map((v, i) => {
          let obj = {
            complete_by: v.complete_by,
            completed_at: v.completed_at,
            completed_by_user_info: v.completed_by_user_info,
            created_at: v.created_at,
            created_by: v.created_by,
            created_by_user_info: v.created_by_user_info,
            description: v.description,
            is_complete: v.is_complete,
            price: v.price,
            inventory_category: v.inventory_category,
            model_number: v.model_number,
            quantity: v.quantity,
            status: v.status,
            suite_no: v.suite_no,
            updated_at: v.updated_at,
            updated_by: v.updated_by,
            updated_by_user_info: v.updated_by_user_info,
            _id: v._id,
            id: i,
          };
          newArray.push(obj);
        });
        // console.log("newArray: ", newArray);
        setAddedPurchaseItems(newArray);
        let lastElement = newArray[newArray.length - 1];
        // console.log(lastElement.id);
        setVirtualID(lastElement.id + 1);
        // ////////////////
        setTradeNames(res.data?.trade_id);
        setOrderID(res.data?.order_id);
        // console.log();
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const setBuildingOnNavbar = async () => {
    let data = {
      building_id: findBuilding,
    };
    let res = await adminApiCall(
      "/api/admin/building/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res SET BUILDING => ", res);
        localStorage.setItem("building_name", res?.data?.street);
        localStorage.setItem("building_ID", res?.data?.building_id);
        localStorage.setItem("building_id", res?.data?._id);
        // console.log("v._id --===> ", v._id);
        localStorage.setItem("available_suites", res?.data?.available_suite);
        localStorage.setItem("no_of_suites", res?.data?.no_of_suite);
        localStorage.setItem("building_namee", res?.data?.building_name);
      } else if (res.status === 400) {
        notifyError(res.message);
      } else if (res.status === 300) {
        // notifyError(res.message);
        navigate("*");
      }
    }
  };

  const addedComment = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      order_type: "purchase_parts",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setCommentArray(res.data.comments);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getCategoryList = async () => {
    let data = {
      property_id: "",
    };
    let res = await adminApiCall(
      "/api/admin/inventory_category/inventory_categories_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);

        setCategory(res.data);
        setCategoryNames(res.data[0]?._id);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getTradeList = async () => {
    let data = {
      property_id: "",
    };
    let res = await adminApiCall(
      "/api/admin/trade/trades_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);

        setTrade(res.data);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const searchHandler = (e) => {
    setSearchBar(e.target.value);
    // console.log(e.target.value);
  };

  const handleChangeTrade = (e) => {
    setTradeNames(e.target.value);
    // console.log(e.target.value);
    logs(`trade asignee has been changed to ${e.target.value}`);
  };

  const getInventoryList = async () => {
    let data = {
      current_page: currentPage,
      itemsPerPage: perPage,
      search_value: searchBar,
      ...(sortValue === "1"
        ? {
            sort_by_category: categorySort,
          }
        : sortValue === "2"
        ? {
            sort_by_model_no: modelNumber,
          }
        : sortValue === "3"
        ? {
            sort_by_expense_code: expenseCode,
          }
        : sortValue === "4"
        ? {
            sort_by_price: price,
          }
        : sortValue === "5"
        ? {
            sort_by_description: description,
          }
        : ""),
      is_temp: true,
    };

    let res = await adminApiCall(
      "/api/admin/inventory/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setInventoryList(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const getFavouriteList = async () => {
    let data = {
      current_page: currentPageFavourite,
      itemsPerPage: perPageFavourite,
      ...(sortValueFavourite === "1"
        ? {
            sort_by_category: categorySortFavourite,
          }
        : sortValueFavourite === "2"
        ? {
            sort_by_model_no: modelNumberFavourite,
          }
        : sortValueFavourite === "3"
        ? {
            sort_by_expense_code: expenseCodeFavourite,
          }
        : sortValueFavourite === "4"
        ? {
            sort_by_price: priceFavourite,
          }
        : sortValueFavourite === "5"
        ? {
            sort_by_description: descriptionFavourite,
          }
        : ""),
    };

    let res = await adminApiCall(
      "/api/admin/inventory/favourite_inventories_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setFavouriteList(res.data);
        setPageCountFavourite(res.total_pages);
        setTotalRecordFavourite(res.total_records);
        setLoaderFavourite(false);
        setNoRecordFavourite(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoaderFavourite(false);
        setNoRecordFavourite(true);
      } else if (res.status === 300) {
        setLoaderFavourite(false);
        setNoRecordFavourite(true);
      }
    }
  };

  const logs = async (comment) => {
    let data = {
      purchase_order_id: purchaseOrderID,
      log_detail: {
        log: comment,
      },
    };

    let res = await adminApiCall(
      "/api/admin/purchase_order/log",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // notifySuccess("Log Created (add list)");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const categorySortt = () => {
    setCategorySort(!categorySort);
    setSortValue("1");
  };

  const modelNumberSort = () => {
    setModelNumber(!modelNumber);
    setSortValue("2");
  };

  const expenseSort = () => {
    setExpenseCode(!expenseCode);
    setSortValue("3");
  };

  const priceSort = () => {
    setPrice(!price);
    setSortValue("4");
  };

  const descriptionSort = () => {
    setDescriptionSorting(!descriptionSorting);
    setSortValue("5");
  };

  const categorySorttFav = () => {
    setCategorySortFavourite(!categorySortFavourite);
    setSortValueFavourite("1");
  };

  const modelNumberSortFav = () => {
    setModelNumberFavourite(!modelNumberFavourite);
    setSortValueFavourite("2");
  };

  const expenseSortFav = () => {
    setExpenseCodeFavourite(!expenseCodeFavourite);
    setSortValueFavourite("3");
  };

  const priceSortFav = () => {
    setPriceFavourite(!priceFavourite);
    setSortValueFavourite("4");
  };

  const descriptionSortFav = () => {
    setDescriptionFavourite(!descriptionFavourite);
    setSortValueFavourite("5");
  };

  const addPurchaseItem = async (
    inventoryID,
    category,
    modelNum,
    desc,
    price,
    quantity,
    suiteNum
  ) => {
    let obj = {
      id: inventoryID,
      inventory_category: category,
      model_number: modelNum,
      description: desc,
      price: price,
      quantity: Number(children[0].key),
      suite_no: unitNames,
    };
    // console.log(obj);

    let tempArray = [...addedPurchaseItems];
    // console.log("==================>tempArry1", tempArray);

    let flag = addedPurchaseItems.find((val) =>
      val.id === inventoryID ? true : false
    );
    if (!flag) {
      tempArray.push(obj);
      logs(
        `Inventory Item with model Number ${modelNum} added to purchase order list with price ${price}`
      );
    }
    setAddedPurchaseItems(tempArray);
    setPrice(price);
    // console.log("==================>tempArry2", tempArray);
  };

  const deleteSpecificItem = (item) => {
    let ref = [...addedPurchaseItems];
    // console.log(ref.indexOf(item));
    ref.splice(ref.indexOf(item), 1);
    // console.log("item", item);
    setAddedPurchaseItems(ref);
    logs(
      `Item removed from purchase order having category ${item.inventory_category} and model Number ${item.model_number}`
    );
  };

  const submitPurchaseParts = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      trade_id: tradeNames,
      order_type: PurchaseParts,
      purchase_items: addedPurchaseItems,
    };
    // console.log("data: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/purchase_order/update",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Purchase Part Updated Successfully!");
        navigate(PATH.VIEW_EXISTING);
        logs(
          `Purchase Part submitted against purchase order ID ${purchaseOrderListing?.order_id}`
        );
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const handlePageClickFavourite = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPageFavourite(selectedPage);
  };

  // //////////////////////////////////////

  const { Option } = Select;

  const children = [];
  for (let i = 1; i < 11; i++) {
    children.push(<Option key={i}>{i}</Option>);
  }

  let childrens = [];
  category.map((v, i) => {
    childrens.push(<Option key={v.category_name}>{v.category_name}</Option>);
  });

  const [size, setSize] = React.useState("small");

  function handleChangeInventoryCategory(value, id) {
    setQuantity(value);
    // console.log(value);
    // console.log(`Selected: ${value}`);

    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // logs(
        //   `Quantity changed to ${value} of item having model number ${val.model_number}`
        // );
        // console.log(id + " " + val._id);
        return {
          description: val.description,
          id: val.id,
          inventory_category: value,
          model_number: val.model_number,
          price: val.price,
          suite_no: val.suite_no,
          quantity: val.quantity,
        };
      } else {
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log("val:", arr);
  }

  const addComment = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      comment: { comment: addComments },
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setAddComments("");
        // getPurchaseOrder();
        setCommentLength(commentLength + 1);
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const deleteComment = (deleteArray) => {
    // console.log("deleteArray", deleteArray);
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          // let dataArray = [];
          // dataArray.push(deleteArray);
          data = {
            purchase_order_id: purchaseOrderID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            purchase_order_id: purchaseOrderID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            // getPurchaseOrder();
            setCommentLength(commentLength - 1);
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  function onChangeDescription(value, id) {
    setDescription(value.target.value);
    // console.log(value.target.value);
    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val._id);
        logs(
          `Description changed to "${value.target.value}" of item having description "${val.description}"`
        );
        return {
          description: value.target.value,
          id: val.id,
          inventory_category: val.inventory_category,
          model_number: val.model_number,
          price: val.price,
          suite_no: val.suite_no,
          quantity: val.quantity,
        };
      } else {
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log(arr);
  }

  function onChangeModelNumber(value, id) {
    // console.log(id);
    setModelNumberEdit(value.target.value);
    // console.log(value);
    let tempArray = [...addedPurchaseItems];
    // console.log(tempArray);
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val._id);
        logs(
          `Model Number changed to "${value.target.value}" of item having description "${val.description}"`
        );
        return {
          description: val.description,
          id: val.id,
          inventory_category: val.inventory_category,
          model_number: value.target.value,
          price: val.price,
          suite_no: val.suite_no,
          quantity: val.quantity,
        };
      } else {
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log(arr);
  }

  function handleChangeSuite(value, id) {
    setSuite(value);
    console.log(value);

    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val._id);
        logs(
          `Apt. No. changed to "${value}" of item having description "${val.description}"`
        );
        return {
          description: val.description,
          id: val.id,
          inventory_category: val.inventory_category,
          model_number: val.model_number,
          price: val.price,
          suite_no: value,
          quantity: val.quantity,
        };
      } else {
        // console.log("else");
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log("val:", arr);
  }

  function onChangeNumber(value, id) {
    // console.log("changed", value);
    setPrice(value);

    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val._id);
        logs(
          `Price changed to "$${value}" of item having model number "${val.model_number}"`
        );
        return {
          description: val.description,
          id: val.id,
          inventory_category: val.inventory_category,
          model_number: val.model_number,
          price: value,
          suite_no: val.suite_no,
          quantity: quantity,
        };
      } else {
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log("val:", arr);
  }

  function handleChange(value, id) {
    setQuantity(value);
    // console.log(value);

    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        logs(
          `Quantity changed to "${value}" of item having model number "${val.model_number}"`
        );
        // console.log(id + " " + val._id);
        return {
          description: val.description,
          id: val.id,
          inventory_category: val.inventory_category,
          model_number: val.model_number,
          price: val.price,
          suite_no: val.suite_no,
          quantity: Number(value),
        };
      } else {
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log("val:", arr);
  }

  const units = [<Option key={"Other"}>{"Other"}</Option>];
  unit.map((v, i) => {
    units.push(<Option key={v.unit_no}>{v.unit_no}</Option>);
  });

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const perPageHandler = (e) => {
    // console.log("perpage: ", e.target.value);
    setPerPage(e.target.value);
  };

  const approveListing = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      send_to_trader: approveSendEmailCheckBox,
    };

    // console.log(data);
    // return;

    let res = await adminApiCall(
      "/api/admin/purchase_order/approve_order",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Order has been Approved!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="tabsSection">
          <ul className="nav customTabs">
            <li>
              <a className="nav-link active" data-toggle="tab" href="#purchase">
                Purchase List
              </a>
            </li>
            <li>
              <a className="nav-link" data-toggle="tab" href="#favorite">
                Favourites
              </a>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-11 col-md-5">
            <div className="contentCard small noRadius">
              <div className="filters">
                <div className="row align-items-md-center ">
                  <div className="col-12 col-lg-4 col-sm-12">
                    <div className="search">
                      <div className="searchField">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search"
                          value={searchBar}
                          onChange={searchHandler}
                        />
                        <div className="icon">
                          <Search />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 col-sm-12">
                    <Button
                      className="myBtn shadow reactBoostrapModalBtn"
                      onClick={() => {
                        setModalShow(true);
                      }}
                    >
                      {" "}
                      <i className="fa fa-plus-circle reactBoostrapIcon"></i>
                      Add Item
                    </Button>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <div className="dataTables_length" id="datatable_length">
                      <label>
                        Show entries:
                        <select
                          name="datatable_length"
                          aria-controls="datatable"
                          className="mobile-select mobile-select custom-select custom-select-md form-control"
                          value={perPage}
                          onChange={perPageHandler}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </select>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <AddInventory
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
                category={category}
                categoryNames={categoryNames}
                setCategoryNames={setCategoryNames}
                addtopurchaselist={addToPurchaseList ? true : false}
                getInventoryList={getInventoryList}
                addPurchaseItem={addPurchaseItem}
                suite={suite}
                quantity={quantity}
                setPrice={setPrice}
                virtualID={virtualID}
                setVirtualID={setVirtualID}
              />
              <div className="tab-content">
                <div className="tab-pane fade show active" id="purchase">
                  {loader ? (
                    <div className="text-center">
                      <img src={Loader} alt="" />
                    </div>
                  ) : noRecord ? (
                    <div className="text-center">
                      <img src={NoRecordFound} alt="" />
                    </div>
                  ) : (
                    <div className="mainTable ax newTable table-responsive dataTable scrollable">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="sort" onClick={categorySortt}>
                              Category
                            </th>
                            <th className="sort" onClick={modelNumberSort}>
                              Model Num
                            </th>
                            <th className="sort" onClick={descriptionSort}>
                              Description
                            </th>
                            <th className="sort" onClick={priceSort}>
                              Price
                            </th>
                            <th className="">Add</th>
                          </tr>
                        </thead>
                        <tbody>
                          {inventoryList &&
                            inventoryList.map((v, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <b>{v.category}</b>
                                  </td>
                                  <td>{v.model_number}</td>
                                  <td>{v.description}</td>
                                  <td>
                                    $
                                    {((v.price * 100) / 100).toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 2 }
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      className="circleBtn"
                                      // href="#"
                                      onClick={() =>
                                        addPurchaseItem(
                                          v._id,
                                          v.category,
                                          v.model_number,
                                          v.description,
                                          v.price
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-double-right"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {inventoryList && inventoryList.length > 0 ? (
                    <div className="row align-items-center">
                      <div className="col-sm-12 col-md-5">
                        Showing {currentPage * perPage - perPage + 1} to{" "}
                        {totalRecord > currentPage * perPage
                          ? currentPage * perPage
                          : totalRecord}{" "}
                        of {totalRecord} entries
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers text-right"
                          id="datatable_paginate"
                        >
                          <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            onPageChange={(e) => handlePageClick(e)}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            pageClassName={"paginate_button page-item"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="tab-pane fade" id="favorite">
                  {loaderFavourite ? (
                    <div>
                      <img src={Loader} alt="" />
                    </div>
                  ) : noRecordFavourite ? (
                    <div>
                      <img src={NoRecordFound} alt="" />
                    </div>
                  ) : (
                    <div className="mainTable ax newTable table-responsive dataTable scrollable">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="sort" onClick={categorySorttFav}>
                              Category
                            </th>
                            <th className="sort" onClick={modelNumberSortFav}>
                              Model Num
                            </th>
                            <th className="sort" onClick={descriptionSortFav}>
                              Description
                            </th>
                            <th className="sort" onClick={priceSortFav}>
                              Price
                            </th>
                            <th className="">Add</th>
                          </tr>
                        </thead>
                        <tbody>
                          {favouriteList &&
                            favouriteList.map((v, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <b>{v.category}</b>
                                  </td>
                                  <td>{v.model_number}</td>
                                  <td>{v.descriptiom}</td>
                                  <td>
                                    $
                                    {((v.price * 100) / 100).toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 2 }
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      className="circleBtn "
                                      href="#"
                                      onClick={() =>
                                        addPurchaseItem(
                                          v._id,
                                          v.category,
                                          v.model_number,
                                          v.description,
                                          v.price
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-angle-double-right"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {favouriteList && favouriteList.length > 0 ? (
                    <div className="row align-items-center">
                      <div className="col-sm-12 col-md-5">
                        Showing{" "}
                        {currentPageFavourite * perPageFavourite -
                          perPageFavourite +
                          1}{" "}
                        to{" "}
                        {totalRecordFavourite >
                        currentPageFavourite * perPageFavourite
                          ? currentPageFavourite * perPageFavourite
                          : totalRecordFavourite}{" "}
                        of {totalRecordFavourite} entries
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers text-right"
                          id="datatable_paginate"
                        >
                          <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCountFavourite}
                            // marginPagesDisplayed={2}
                            // pageRangeDisplayed={5}
                            onPageChange={(e) => handlePageClickFavourite(e)}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            pageClassName={"paginate_button page-item"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-11 col-md-7">
            <div className="contentCard small">
              <h3 className="sectionTitle">
                Purchase Order # {purchaseOrderListing?.order_id}
              </h3>
              <hr className="splitCard" />

              <div className="formDesign">
                <div className="row">
                  <div className={`col-12 col-lg-4`}>
                    <div className="form-group">
                      <label>Assign Trade</label>
                      <select
                        className="form-control slct"
                        value={tradeNames}
                        onChange={(e) => handleChangeTrade(e)}
                      >
                        {trade &&
                          trade.map((v, i) => {
                            return (
                              <option key={i} value={v._id}>
                                {v.company.charAt(0).toUpperCase() +
                                  v.company.slice(1)}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mainTable ax newTable table-responsive scrollable">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th style={{ minWidth: "145px" }}>Model Number</th>
                      <th style={{ minWidth: "250px" }}>Description</th>
                      <th>Apt. No.</th>
                      <th>Price</th>
                      <th>Qty</th>
                      <th className="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {addedPurchaseItems.length < 1 ? (
                      <tr></tr>
                    ) : (
                      addedPurchaseItems &&
                      addedPurchaseItems.map((v, i) => {
                        return (
                          <tr key={i} className="getThis">
                            <td>
                              <Select
                                size={size}
                                defaultValue={v.inventory_category}
                                onChange={(e) =>
                                  handleChangeInventoryCategory(e, v.id)
                                }
                                style={{ width: 130 }}
                              >
                                {childrens}
                              </Select>
                            </td>
                            <td>
                              <Input
                                value={v.model_number}
                                onChange={(e) => onChangeModelNumber(e, v.id)}
                              />
                            </td>
                            <td style={{ minWidth: "250px" }}>
                              <TextArea
                                size={size}
                                rows={1}
                                allowClear
                                value={v.description}
                                onChange={(e) => onChangeDescription(e, v.id)}
                              />
                            </td>
                            <td>
                              <Select
                                size={size}
                                defaultValue={v.suite_no}
                                onChange={(e) => {
                                  console.log(e);
                                  handleChangeSuite(e, v.id);
                                }}
                                style={{ width: 90 }}
                              >
                                {units}
                              </Select>
                            </td>
                            <td className="price">
                              <Space>
                                <InputNumber
                                  size="small"
                                  min={0.1}
                                  max={1000000000}
                                  precision={2}
                                  defaultValue={(
                                    (v.price * 100) /
                                    100
                                  ).toLocaleString()}
                                  onChange={(e) => onChangeNumber(e, v.id)}
                                />
                              </Space>
                            </td>
                            <td className="qty">
                              <Space>
                                <InputNumber
                                  size="small"
                                  min={1}
                                  max={1000000000}
                                  defaultValue={v.quantity}
                                  onChange={(e) => handleChange(e, v.id)}
                                />
                              </Space>
                              {/* <Select
                                size={size}
                                defaultValue={v.quantity}
                                onChange={(e) => handleChange(e, v.id)}
                                style={{ width: 60 }}
                              >
                                {children}
                              </Select> */}
                            </td>
                            <td>
                              <Link
                                className="circleBtn danger deleteThis"
                                to="#"
                                onClick={() => deleteSpecificItem(v)}
                              >
                                <Delete2 />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>

                {addedPurchaseItems.length < 1 ? (
                  <div
                    className="mb-3"
                    style={{
                      border: "1px dashed #606071",
                      textAlign: "center",
                      fontSize: "16px",
                      marginRight: "10%",
                      marginLeft: "10%",
                      padding: "25px",
                    }}
                  >
                    Purchase Order is Empty. <br /> Please Add atleast one item
                    from the Purchase List.
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="contentCard commentWrapper mt-3">
                <div className="formDesign">
                  <div className="form-group">
                    <label>
                      Comments <small>(optional)</small>
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={addComments}
                      onChange={(e) => setAddComments(e.target.value)}
                    ></textarea>
                  </div>
                  <button
                    className="myBtn success mb-2"
                    onClick={() => addComment()}
                    disabled={!addComments ? true : false}
                  >
                    Add Comment
                  </button>
                </div>
                <div className="commentLogs">
                  {commentArray &&
                    commentArray.map((v, i) => {
                      return (
                        <div key={i} className="comment">
                          <h2>
                            {capatalizeLetters(
                              v?.created_by_user_info?.first_name +
                                "_" +
                                v?.created_by_user_info?.last_name
                            )}{" "}
                            <span>
                              {getDate(v.created_at)}{" "}
                              {localStorage.getItem("role") === "viewer" ? (
                                userAccessibility(
                                  "purchase_order_management",
                                  "delete"
                                ) === false ? (
                                  ""
                                ) : (
                                  <a
                                    href="#"
                                    className="circleBtn danger deleteThis ml-2"
                                    onClick={() => {
                                      deleteComment([v._id]);
                                    }}
                                  >
                                    <Delete2 />
                                  </a>
                                )
                              ) : localStorage.getItem("role") ===
                                "building_manager" ? (
                                userAccessibility(
                                  "purchase_order_management",
                                  "delete"
                                ) === false ? (
                                  ""
                                ) : (
                                  <a
                                    href="#"
                                    className="circleBtn danger deleteThis ml-2"
                                    onClick={() => {
                                      deleteComment([v._id]);
                                    }}
                                  >
                                    <Delete2 />
                                  </a>
                                )
                              ) : localStorage.getItem("role") ===
                                "property_manager" ? (
                                userAccessibility(
                                  "purchase_order_management",
                                  "delete"
                                ) === false ? (
                                  ""
                                ) : (
                                  <a
                                    href="#"
                                    className="circleBtn danger deleteThis ml-2"
                                    onClick={() => {
                                      deleteComment([v._id]);
                                    }}
                                  >
                                    <Delete2 />
                                  </a>
                                )
                              ) : (
                                <a
                                  href="#"
                                  className="circleBtn danger deleteThis ml-2"
                                  onClick={() => {
                                    deleteComment([v._id]);
                                  }}
                                >
                                  <Delete2 />
                                </a>
                              )}
                            </span>
                          </h2>
                          <p>{v.comment}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="cardFooter">
                {orderStatus === "PENDING" && localStorage.getItem("role") !=
                  "building_manager"  ? (
                  <button
                    className="myBtn mr-1"
                    // type="submit"
                    data-toggle="modal"
                    data-target="#approveOrder"
                    // onClick={() => alert("Under construction")}
                  >
                    Approve
                  </button>
                ) : (
                  ""
                )}

                <button
                  // type="submit"
                  className="myBtn mr-1"
                  onClick={() => submitPurchaseParts()}
                >
                  Update
                </button>
                <Link className="myBtn bdr" to={PATH.VIEW_EXISTING}>
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* approve popup */}
      <div className="modal fade" id="approveOrder">
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content customModal">
            <div className="modal-body">
              <h2 className="modalTitle"> Approve!</h2>
              <div className="formDesign">
                <div className="form-group">
                  <label>
                    You Are About To Approve Purchase Order [{orderID}]
                  </label>
                  <input
                    type="checkbox"
                    className="customCheck mx-3"
                    // id={"purchaseOrder" + i}
                    //               value={v._id}
                    //               checked={
                    //                 deleteItem && deleteItem.includes(v._id)
                    //                   ? true
                    //                   : false
                    //               }

                    value={approveSendEmailCheckBox}
                    onChange={(e) =>
                      setApproveSendEmailCheckBox(e.target.checked)
                    }
                  />
                  <label>Send Email to Contractor</label>
                </div>
                <div className="text-center">
                  <button
                    className="myBtn mr-1"
                    data-dismiss="modal"
                    onClick={() => approveListing()}
                  >
                    Approve
                  </button>
                  <button className="myBtn simple " data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
