import React, { useState, useEffect } from "react";
import MainLayout from "../../../layouts";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  adminApiCall,
  capatalizeLetters,
  getDateOnly,
} from "../../../helpers/ajax_request";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PATH } from "../../../constants/paths";
import moment from "moment";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import ReactPaginate from "react-paginate";

export default function Index() {
  const params = useParams();
  const tradeID = params.id;

  const [tradeData, setTradeData] = useState({});
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [perPage, setPerPage] = useState(10);

  toast.configure();
  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  useEffect(() => {
    getTradeData();
  }, [currentPage]);

  const getTradeData = async () => {
    let data = {
      current_page: currentPage,
      trade_id: tradeID,
    };
    let res = await adminApiCall(
      "/api/admin/trade/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        if (res.data?.purchase_order?.length === 0) {
          setLoader(false);
          setNoRecord(true);
        } else {
          setLoader(false);
          setNoRecord(false);
        }

        // console.log("res", res);
        setTradeData(res.data);
        setPurchaseOrderList(res.data?.purchase_order);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };
  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="row">
          <div className="col-12  col-lg-4 col-xl-3">
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Trader Details</span>
              </div>
              <div className="details">
                <div className="detailContent">
                  <h4>Company Name</h4>
                  <h2 className="heading-controls-com">{tradeData.company}</h2>
                </div>
                <div className="detailContent">
                  <h4>Creation Date</h4>
                  <h2  className="heading-controls-com">{getDateOnly(tradeData?.created_at)}</h2>
                </div>
                {tradeData?.phone_no?.split("").length === 0 ||
                tradeData?.phone_no === null ? (
                  ""
                ) : (
                  <div className="detailContent">
                    <h4>Phone Number</h4>
                    <h2  className="heading-controls-com">
                      {"(" +
                        tradeData?.phone_no?.split("")[0] +
                        tradeData?.phone_no?.split("")[1] +
                        tradeData?.phone_no?.split("")[2] +
                        ") " +
                        tradeData?.phone_no?.split("")[3] +
                        tradeData?.phone_no?.split("")[4] +
                        tradeData?.phone_no?.split("")[5] +
                        " - " +
                        tradeData?.phone_no?.split("")[6] +
                        tradeData?.phone_no?.split("")[7] +
                        tradeData?.phone_no?.split("")[8] +
                        tradeData?.phone_no?.split("")[9]}
                    </h2>
                  </div>
                )}
                {tradeData.email === "" || tradeData.email === null ? (
                  ""
                ) : (
                  <div className="detailContent">
                    <h4>Email</h4>
                    <h2  className="heading-controls-com" >{tradeData.email}</h2>
                  </div>
                )}
                {tradeData.fax?.split("").length === 0 ||
                tradeData.fax === null ? (
                  ""
                ) : (
                  <div className="detailContent">
                    <h4>Fax</h4>
                    <h2  className="heading-controls-com">
                      {"(" +
                        tradeData.fax?.split("")[0] +
                        tradeData.fax?.split("")[1] +
                        tradeData.fax?.split("")[2] +
                        ") " +
                        tradeData.fax?.split("")[3] +
                        tradeData.fax?.split("")[4] +
                        tradeData.fax?.split("")[5] +
                        " - " +
                        tradeData.fax?.split("")[6] +
                        tradeData.fax?.split("")[7] +
                        tradeData.fax?.split("")[8] +
                        tradeData.fax?.split("")[9]}
                    </h2>
                  </div>
                )}

                {tradeData.note_or_description === "" ||
                tradeData.note_or_description === null ? (
                  ""
                ) : (
                  <div className="detailContent">
                    <h4>Description</h4>
                    <h2  className="heading-controls-com">{tradeData.note_or_description}</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-9">
            <div className="contentCard mb-0">
              <div className="titleWrapper">
                <span>Purchase Orders</span>
              </div>
              {loader ? (
                <div className="text-center">
                  <img src={Loader} alt="" />
                </div>
              ) : noRecord ? (
                <div className="text-center">
                  <img src={NoRecordFound} alt="" />
                </div>
              ) : (
                <div className="mainTable table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="sort">Order ID</th>
                        <th className="sort">Type</th>
                        <th className="sort">Assigned Trader</th>
                        <th className="sort">Requested Date</th>
                        <th className="sort">State</th>
                        <th className="sort">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseOrderList &&
                        purchaseOrderList.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td className="">
                                <Link
                                  className="text"
                                  to={
                                    v.order_type === "purchase_parts"
                                      ? `${PATH.PURCHASE_PART_DETAIL}/${v._id}`
                                      : `${PATH.MAINTENANCE_SERVICES_DETAIL}/${v._id}`
                                  }
                                >
                                  <b>{v.order_id} </b>
                                  <span className="orderItems"></span>
                                </Link>
                              </td>
                              <td>
                                {capatalizeLetters(v.order_type.split("_")[0]) +
                                  " " +
                                  capatalizeLetters(v.order_type.split("_")[1])}
                              </td>
                              <td>{v.trader}</td>
                              <td>
                                {moment(v.order_requested_at).format(
                                  "DD MMMM YYYY"
                                )}
                              </td>
                              <td>
                                <span
                                  className={`badge ${
                                    v.order_state === "PENDING"
                                      ? "pending"
                                      : v.order_state === "APPROVED"
                                      ? "pending"
                                      : v.order_state === "CONFIRM"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {v.order_state}
                                </span>
                              </td>
                              <td>
                                {" "}
                                <span
                                  className={`text ${
                                    v.order_status === "COMPLETED"
                                      ? "success"
                                      : v.order_status === "PARTIALLY_COMPLETE"
                                      ? "pending"
                                      : "danger"
                                  }`}
                                >
                                  <span></span>{" "}
                                  {v.order_status === "COMPLETED"
                                    ? "COMPLETED"
                                    : v.order_status === "PARTIALLY_COMPLETE"
                                    ? "PARTIALLY COMPLETED"
                                    : "INCOMPLETE"}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}

              {purchaseOrderList && purchaseOrderList.length > 0 ? (
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    Showing {currentPage * perPage - perPage + 1} to{" "}
                    {totalRecord > currentPage * perPage
                      ? currentPage * perPage
                      : totalRecord}{" "}
                    of {totalRecord} entries
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers text-right"
                      id="datatable_paginate"
                    >
                      <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        onPageChange={(e) => handlePageClick(e)}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        pageClassName={"paginate_button page-item"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="cardFooter">
                <Link className="myBtn bdr" to={PATH.VIEW_TRADES}>
                  Back to Listing
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
