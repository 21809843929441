import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import AddInventory from "../../Inventory/AddInventory/Index";
import Loader from "../../../assets/images/LoaderNew.gif";
import MainLayout from "../../../layouts";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { DatePicker, Input, InputNumber, Radio, Select, Space } from "antd";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Delete2, Search } from "../../../Icons/Icons";
import { PATH, PurchaseParts } from "../../../constants/paths";
import { adminApiCall, userAccessibility } from "../../../helpers/ajax_request";

export default function Index() {
  const { TextArea } = Input;
  const [modalShow, setModalShow] = useState(false);
  const [inventoryList, setInventoryList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [perPage, setPerPage] = useState(50);
  const [comment, setComment] = useState("");

  const [category, setCategory] = useState([]);
  const [categoryNames, setCategoryNames] = useState(category[0]?._id);
  const [addToPurchaseList, setAddToPurchaseList] = useState(true);
  const [dateValue, setDateValue] = useState(moment());
  const [trade, setTrade] = useState([]);
  const [tradeNames, setTradeNames] = useState(trade[0]?._id);

  ////////////////////////////FavouriteList/////////////////////////////
  const [favouriteList, setFavouriteList] = useState([]);
  const [loaderFavourite, setLoaderFavourite] = useState(true);
  const [noRecordFavourite, setNoRecordFavourite] = useState(false);
  const [pageCountFavourite, setPageCountFavourite] = useState(10);
  const [currentPageFavourite, setCurrentPageFavourite] = useState(1);
  const [totalRecordFavourite, setTotalRecordFavourite] = useState(10);
  const [perPageFavourite, setPerPageFavourite] = useState(50);
  const [categorySort, setCategorySort] = useState(false);
  const [modelNumber, setModelNumber] = useState(false);
  const [expenseCode, setExpenseCode] = useState(false);
  const [priceSorting, setPriceSorting] = useState(false);
  const [description, setDescription] = useState(false);
  const [categorySortFavourite, setCategorySortFavourite] = useState(false);
  const [modelNumberFavourite, setModelNumberFavourite] = useState(false);
  const [expenseCodeFavourite, setExpenseCodeFavourite] = useState(false);
  const [priceFavourite, setPriceFavourite] = useState(false);
  const [descriptionFavourite, setDescriptionFavourite] = useState(false);
  const [sortValue, setSortValue] = useState("0");
  const [sortValueFavourite, setSortValueFavourite] = useState("0");

  const [addedPurchaseItems, setAddedPurchaseItems] = useState([]);
  const [modelNumberEdit, setModelNumberEdit] = useState("");
  const [searchBar, setSearchBar] = useState("");
  const [unitNames, setUnitNames] = useState("");
  const [unit, setUnit] = useState([]);

  const [suite, setSuite] = useState(1);
  const [price, setPrice] = useState(0);
  // console.log(price);
  const [quantity, setQuantity] = useState(1);

  const [virtualID, setVirtualID] = useState(0);
  let [typingTimer, setTypingTimer] = useState(0);
  const [submitLoader, setSubmitLoader] = useState(false);


  const dateFormat = "DD/MM/YYYY";

  function onChangeDate(date, dateString) {
    setDateValue(date === null ? "" : date);
  }

  useEffect(() => {
    getInventoryList();
  }, [
    // searchBar,
    currentPage,
    perPage,
    categorySort,
    modelNumber,
    expenseCode,
    description,
    price,
  ]);

  useEffect(() => {
    getTradeList();
  }, []);

  useEffect(() => {
    getCategoryList();
    // getTradeList();
    getUnitList();
    if (localStorage.getItem("role") === "viewer") {
      if (userAccessibility("inventory_management", "delete") === false) {
        // console.log("abc")
      } else {
        getFavouriteList();
      }
    } else if (localStorage.getItem("role") === "building_manager") {
      if (userAccessibility("inventory_management", "delete") === false) {
        // console.log("abc")
      } else {
        getFavouriteList();
      }
    } else if (localStorage.getItem("role") === "property_manager") {
      if (userAccessibility("inventory_management", "delete") === false) {
        // console.log("abc")
      } else {
        getFavouriteList();
      }
    } else {
      getFavouriteList();
    }
  }, [
    addedPurchaseItems,
    currentPage,
    currentPageFavourite,
    categorySortFavourite,
    modelNumberFavourite,
    expenseCodeFavourite,
    descriptionFavourite,
    priceFavourite,
  ]);

  const categorySortt = () => {
    setCategorySort(!categorySort);
    setSortValue("1");
  };

  const modelNumberSort = () => {
    setModelNumber(!modelNumber);
    setSortValue("2");
  };

  const expenseSort = () => {
    setExpenseCode(!expenseCode);
    setSortValue("3");
  };

  const priceSort = () => {
    setPrice(!price);
    setSortValue("4");
  };

  const descriptionSort = () => {
    setDescription(!description);
    setSortValue("5");
  };

  const categorySorttFav = () => {
    setCategorySortFavourite(!categorySortFavourite);
    setSortValueFavourite("1");
  };

  const modelNumberSortFav = () => {
    setModelNumberFavourite(!modelNumberFavourite);
    setSortValueFavourite("2");
  };

  const expenseSortFav = () => {
    setExpenseCodeFavourite(!expenseCodeFavourite);
    setSortValueFavourite("3");
  };

  const priceSortFav = () => {
    setPriceFavourite(!priceFavourite);
    setSortValueFavourite("4");
  };

  const descriptionSortFav = () => {
    setDescriptionFavourite(!descriptionFavourite);
    setSortValueFavourite("5");
  };

  const navigate = useNavigate();

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const searchHandler = (e) => {
    setSearchBar(e.target.value);
  };

  const keyUpSearchHandler = () => {
    //timer identifier
    // console.log("typingTimer ===> ", typingTimer);
    const doneTypingInterval = 500; //time in ms, 5 seconds for example

    clearTimeout(typingTimer);
    typingTimer = setTimeout(getInventoryList, doneTypingInterval);
    // setCurrentPage(1);
  };

  const keyDownSearchHandler = () => {
    clearTimeout(typingTimer);
  };

  const getUnitList = async () => {
    let data = {
      building_id: localStorage.getItem("building_id"),
    };
    let res = await adminApiCall(
      "/api/admin/unit/unit_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res unittttt", res);

        setUnit(res.data);
        // setUnitNames(res.data[0]?.unit_no);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getCategoryList = async () => {
    let data = {
      property_id: "",
    };
    let res = await adminApiCall(
      "/api/admin/inventory_category/inventory_categories_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);

        setCategory(res.data);
        setCategoryNames(res.data[0]?._id);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getTradeList = async () => {
    let data = {
      property_id: "",
    };
    let res = await adminApiCall(
      "/api/admin/trade/trades_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);

        setTrade(res.data);
        setTradeNames(res.data[0]?._id);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const handleChangeCategory = (e) => {
    setTradeNames(e.target.value);
    // console.log(e.target.value);
  };

  const getInventoryList = async () => {
    let data = {
      current_page: currentPage,
      itemsPerPage: perPage,
      is_temp: true,
      search_value: searchBar,
      ...(sortValue === "1"
        ? {
          sort_by_category: categorySort,
        }
        : sortValue === "2"
          ? {
            sort_by_model_no: modelNumber,
          }
          : sortValue === "3"
            ? {
              sort_by_expense_code: expenseCode,
            }
            : sortValue === "4"
              ? {
                sort_by_price: price,
              }
              : sortValue === "5"
                ? {
                  sort_by_description: description,
                }
                : ""),
    };

    let res = await adminApiCall(
      "/api/admin/inventory/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log(res);
        setInventoryList(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const getFavouriteList = async () => {
    let data = {
      current_page: currentPageFavourite,
      itemsPerPage: perPageFavourite,
      ...(sortValueFavourite === "1"
        ? {
          sort_by_category: categorySortFavourite,
        }
        : sortValueFavourite === "2"
          ? {
            sort_by_model_no: modelNumberFavourite,
          }
          : sortValueFavourite === "3"
            ? {
              sort_by_expense_code: expenseCodeFavourite,
            }
            : sortValueFavourite === "4"
              ? {
                sort_by_price: priceFavourite,
              }
              : sortValueFavourite === "5"
                ? {
                  sort_by_description: descriptionFavourite,
                }
                : ""),
    };

    let res = await adminApiCall(
      "/api/admin/inventory/favourite_inventories_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setFavouriteList(res.data);
        setPageCountFavourite(res.total_pages);
        setTotalRecordFavourite(res.total_records);
        setLoaderFavourite(false);
        setNoRecordFavourite(false);
        // console.log("res.data: ", res.data);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoaderFavourite(false);
        setNoRecordFavourite(true);
      } else if (res.status === 300) {
        setLoaderFavourite(false);
        setNoRecordFavourite(true);
      }
    }
  };

  const perPageHandler = (e) => {
    // console.log("perpage: ", e.target.value);
    setPerPage(e.target.value);
  };

  const addPurchaseItem = (
    inventoryID,
    category,
    modelNum,
    desc,
    price,
    quantity,
    suiteNum
  ) => {
    setQuantity(1);
    let obj = {
      id: inventoryID,
      inventory_category: category,
      model_number: modelNum,
      description: desc,
      price: price,
      quantity: Number(children[0].key),
      suite_no: unitNames,
    };
    // console.log("obj:", obj);

    let tempArray = [...addedPurchaseItems];
    let flag = addedPurchaseItems.find((val) =>
      val.id === inventoryID ? true : false
    );
    if (!flag) {
      // console.log("obj pushed in temp");
      tempArray.push(obj);
    }
    // console.log("tempArray: ", tempArray);
    setAddedPurchaseItems(tempArray);
    // console.log("price before: ", price);
    setPrice(price);
  };

  const deleteSpecificItem = (item) => {
    let ref = [...addedPurchaseItems];
    // console.log(ref.indexOf(item));
    ref.splice(ref.indexOf(item), 1);
    // console.log("ref", ref);
    setAddedPurchaseItems(ref);
  };

  const submitPurchaseParts = async () => {
    setSubmitLoader(true)

    let data = {
      building_id: localStorage.getItem("building_id"),
      trade_id: tradeNames,
      order_type: PurchaseParts,
      comments: {
        comment: comment,
      },
      order_requested_at:
        typeof dateValue === "string"
          ? dateValue
          : dateValue?.format("MM/DD/YYYY"),
      purchase_items: addedPurchaseItems,
    };
    // console.log("data: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/purchase_order/create",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      setSubmitLoader(false)

      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Purchase Order Created Successfully!");
        navigate(PATH.VIEW_EXISTING);
      } else if (res.status === 400) {
        notifyError(res.message);
      }

    }
    setSubmitLoader(false)

  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const handlePageClickFavourite = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPageFavourite(selectedPage);
  };

  // //////////////////////////////////////

  function onChangeSuite(value, id) {
    // console.log("changed", value);
    setSuite(value);

    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map(
      (val, i) => {
        if (id === val.id) {
          return {
            description: val.description,
            id: val.id,
            inventory_category: val.inventory_category,
            model_number: val.model_number,
            price: val.price,
            suite_no: value,
            quantity: quantity,
          };
        } else {
          return val;
        }
      }
      // return val;

      // val.id === id ? ((val.suite_no = suite), (val.price = price)) : false
    );
    setAddedPurchaseItems(arr);
    // console.log("vallllllllllllllll: ", arr);
    // console.log("val:", arr);
  }

  const { Option } = Select;

  const children = [];
  for (let i = 1; i < 11; i++) {
    children.push(<Option key={i}>{i}</Option>);
  }
  console.log("children ==> ", children);

  const units = [<Option key={"Other"}>{"Other"}</Option>];
  unit.map((v, i) => {
    units.push(<Option key={v.unit_no}>{v.unit_no}</Option>);
  });
  const [size, setSize] = React.useState("small");

  function handleChange(value, id) {
    setQuantity(value);
    // console.log(value);

    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val.id);
        return {
          description: val.description,
          id: val.id,
          inventory_category: val.inventory_category,
          model_number: val.model_number,
          price: val.price,
          suite_no: val.suite_no,
          quantity: Number(value),
        };
      } else {
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log("val:", arr);
  }

  function handleChangeSuite(value, id) {
    setSuite(value);
    // console.log(value);

    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val.id);
        return {
          description: val.description,
          id: val.id,
          inventory_category: val.inventory_category,
          model_number: val.model_number,
          price: val.price,
          suite_no: value,
          quantity: val.quantity,
        };
      } else {
        // console.log("else");
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log("val:", arr);
  }

  function onChangeNumber(value, id) {
    // console.log("changed", value);
    setPrice(value);

    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val.id);
        return {
          description: val.description,
          id: val.id,
          inventory_category: val.inventory_category,
          model_number: val.model_number,
          price: value,
          suite_no: val.suite_no,
          quantity: val.quantity,
        };
      } else {
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log("val:", arr);
  }

  function handleChangeInventoryCategory(value, id) {
    setQuantity(value);
    // console.log(value);
    // console.log(`Selected: ${value}`);

    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // logs(
        //   `Quantity changed to ${value} of item having model number ${val.model_number}`
        // );
        // console.log(id + " " + val._id);
        return {
          description: val.description,
          id: val.id,
          inventory_category: value,
          model_number: val.model_number,
          price: val.price,
          suite_no: val.suite_no,
          quantity: val.quantity,
        };
      } else {
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log("val:", arr);
  }

  function onChangeModelNumber(value, id) {
    // console.log(id);
    setModelNumberEdit(value.target.value);
    // console.log(value);
    let tempArray = [...addedPurchaseItems];
    // console.log(tempArray);
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val._id);
        // logs(
        //   `Model Number changed to "${value.target.value}" of item having description "${val.description}"`
        // );
        return {
          description: val.description,
          id: val.id,
          inventory_category: val.inventory_category,
          model_number: value.target.value,
          price: val.price,
          suite_no: val.suite_no,
          quantity: val.quantity,
        };
      } else {
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log(arr);
  }

  function onChangeDescription(value, id) {
    setDescription(value.target.value);
    // console.log(value.target.value);
    let tempArray = [...addedPurchaseItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val._id);
        // logs(
        //   `Description changed to "${value.target.value}" of item having description "${val.description}"`
        // );
        return {
          description: value.target.value,
          id: val.id,
          inventory_category: val.inventory_category,
          model_number: val.model_number,
          price: val.price,
          suite_no: val.suite_no,
          quantity: val.quantity,
        };
      } else {
        return val;
      }
    });
    setAddedPurchaseItems(arr);
    // console.log(arr);
  }

  let childrens = [];
  category.map((v, i) => {
    childrens.push(<Option key={v.category_name}>{v.category_name}</Option>);
  });

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="tabsSection">
            <ul className="nav customTabs">
              <li>
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#purchase"
                >
                  Purchase List
                </a>
              </li>
              <li>
                <a className="nav-link" data-toggle="tab" href="#favorite">
                  Favourites
                </a>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-11 col-md-5">
              <div className="contentCard small noRadius">
                <div className="filters">
                  <div className="row align-items-md-center ">
                    <div className="col-12 col-lg-4 col-sm-12">
                      <div className="search">
                        <div className="searchField">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            value={searchBar}
                            onChange={searchHandler}
                            onKeyUp={keyUpSearchHandler}
                            onKeyDown={keyDownSearchHandler}
                          />
                          <div className="icon">
                            <Search />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 col-sm-12">
                      <Button
                        className="myBtn shadow reactBoostrapModalBtn"
                        onClick={() => {
                          setModalShow(true);
                        }}
                      >
                        {" "}
                        <i className="fa fa-plus-circle reactBoostrapIcon"></i>
                        Add Item
                      </Button>
                    </div>
                    <div className="col-sm-12 col-lg-3">
                      <div className="dataTables_length" id="datatable_length">
                        <label>
                          Show entries:
                          <select
                            name="datatable_length"
                            aria-controls="datatable"
                            className="mobile-select mobile-select custom-select custom-select-md form-control"
                            value={perPage}
                            onChange={perPageHandler}
                          >
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                          </select>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <AddInventory
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  setModalShow={setModalShow}
                  category={category}
                  categoryNames={categoryNames}
                  setCategoryNames={setCategoryNames}
                  addtopurchaselist={addToPurchaseList ? true : false}
                  getInventoryList={getInventoryList}
                  addPurchaseItem={addPurchaseItem}
                  suite={suite}
                  quantity={quantity}
                  setPrice={setPrice}
                  virtualID={virtualID}
                  setVirtualID={setVirtualID}
                />
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="purchase">
                    {loader ? (
                      <div className="text-center">
                        <img src={Loader} alt="" />
                      </div>
                    ) : noRecord ? (
                      <div className="text-center">
                        <img src={NoRecordFound} alt="" />
                      </div>
                    ) : (
                      <div className="mainTable ax newTable table-responsive dataTable scrollable">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="sort" onClick={categorySortt}>
                                Category
                              </th>
                              <th className="sort" onClick={modelNumberSort}>
                                Model Num
                              </th>
                              <th className="sort" onClick={descriptionSort}>
                                Description
                              </th>
                              <th className="sort" onClick={priceSort}>
                                Price
                              </th>
                              <th className="">Add</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inventoryList &&
                              inventoryList.map((v, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <b>{v.category}</b>
                                    </td>
                                    <td>{v.model_number}</td>
                                    <td>{v.description}</td>
                                    <td>
                                      $
                                      {((v.price * 100) / 100).toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                    <td>
                                      <a
                                        className="circleBtn"
                                        // href="#"
                                        onClick={() =>
                                          addPurchaseItem(
                                            v._id,
                                            v.category,
                                            v.model_number,
                                            v.description,
                                            v.price
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-angle-double-right"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {inventoryList && inventoryList.length > 0 ? (
                      <div className="row align-items-center">
                        <div className="col-sm-12 col-md-5 col-lg-5">
                          Showing {currentPage * perPage - perPage + 1} to{" "}
                          {totalRecord > currentPage * perPage
                            ? currentPage * perPage
                            : totalRecord}{" "}
                          of {totalRecord} entries
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers text-right"
                            id="datatable_paginate"
                          >
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              onPageChange={(e) => handlePageClick(e)}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              pageClassName={"paginate_button page-item"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="tab-pane fade" id="favorite">
                    {loaderFavourite ? (
                      <div className="text-center">
                        <img src={Loader} alt="" />
                      </div>
                    ) : noRecordFavourite ? (
                      <div className="text-center">
                        <img src={NoRecordFound} alt="" />
                      </div>
                    ) : (
                      <div className="mainTable ax newTable table-responsive dataTable scrollable">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="sort" onClick={categorySorttFav}>
                                Category
                              </th>
                              <th className="sort" onClick={modelNumberSortFav}>
                                Model Num
                              </th>
                              <th className="sort" onClick={descriptionSortFav}>
                                Description
                              </th>
                              <th className="sort" onClick={priceSortFav}>
                                Price
                              </th>
                              <th className="">Add</th>
                            </tr>
                          </thead>
                          <tbody>
                            {favouriteList &&
                              favouriteList.map((v, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <b>{v.category}</b>
                                    </td>
                                    <td>{v.model_number}</td>
                                    <td>{v.descriptiom}</td>
                                    <td>
                                      $
                                      {((v.price * 100) / 100).toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                    <td>
                                      <a
                                        className="circleBtn "
                                        href="#"
                                        onClick={() =>
                                          addPurchaseItem(
                                            v._id,
                                            v.category,
                                            v.model_number,
                                            v.description,
                                            v.price
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-angle-double-right"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {favouriteList && favouriteList.length > 0 ? (
                      <div className="row align-items-center">
                        <div className="col-sm-12 col-md-5">
                          Showing{" "}
                          {currentPageFavourite * perPageFavourite -
                            perPageFavourite +
                            1}{" "}
                          to{" "}
                          {totalRecordFavourite >
                            currentPageFavourite * perPageFavourite
                            ? currentPageFavourite * perPageFavourite
                            : totalRecordFavourite}{" "}
                          of {totalRecordFavourite} entries
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div
                            className="dataTables_paginate paging_simple_numbers text-right"
                            id="datatable_paginate"
                          >
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCountFavourite}
                              // marginPagesDisplayed={2}
                              // pageRangeDisplayed={5}
                              onPageChange={(e) => handlePageClickFavourite(e)}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              pageClassName={"paginate_button page-item"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-11 col-md-7">
              <div className="contentCard small">
                <h3 className="sectionTitle">Purchase Order</h3>
                <hr className="splitCard" />

                <div className="formDesign">
                  <div className="row">
                    <div className={`col-12 col-lg-4`}>
                      <div className="form-group">
                        <label>Assign Trade</label>
                        <select
                          className="form-control"
                          value={tradeNames}
                          onChange={(e) => handleChangeCategory(e)}
                        >
                          {trade &&
                            trade.map((v, i) => {
                              return (
                                <option key={i} value={v._id}>
                                  {v.company.charAt(0).toUpperCase() +
                                    v.company.slice(1)}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3">
                      <div className="form-group">
                        <label>Requested Date</label>

                        <Space direction="vertical">
                          <DatePicker
                            className="form-control"
                            onChange={onChangeDate}
                            defaultValue={dateValue}
                            format={dateFormat}
                          />
                        </Space>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mainTable ax newTable table-responsive scrollable">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ fontSize: "16px", width: "75.590551181px", wordBreak: "break-all" }}>Category</th>
                        <th style={{ fontSize: "16px", width: "94.488188976px", wordBreak: "break-all" }}>Model Number</th>
                        <th style={{ fontSize: "16px", width: "226.77165354px", wordBreak: "break-all" }}>Description</th>
                        <th style={{ fontSize: "16px", width: "56.692913386px", wordBreak: "break-all" }}>Apt</th>
                        <th style={{ fontSize: "16px", width: "56.692913386px", wordBreak: "break-all" }}>Price</th>
                        <th style={{ fontSize: "16px", width: "56.692913386px", wordBreak: "break-all" }}>
                          Qty
                        </th>
                        <th style={{ fontSize: "16px", width: "113.38582677px", wordBreak: "break-all" }}>
                          Qty Rcvd.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {addedPurchaseItems.length < 1 ? (
                        <tr></tr>
                      ) : (
                        addedPurchaseItems &&
                        addedPurchaseItems.map((v, i) => {
                          return (
                            <tr key={i} className="getThis">
                              <td style={{ padding: "10px 6px" }}>
                                {/* <b>{v.inventory_category}</b> */}
                                <Select
                                  size={size}
                                  defaultValue={v.inventory_category}
                                  onChange={(e) =>
                                    handleChangeInventoryCategory(e, v.id)
                                  }
                                  style={{ width: 130 }}
                                >
                                  {childrens}
                                </Select>
                              </td>
                              <td style={{ padding: "10px 20px" }}>
                                {/* {v.model_number} */}
                                <Input
                                  value={v.model_number}
                                  onChange={(e) => onChangeModelNumber(e, v.id)}
                                />
                              </td>
                              <td style={{ minWidth: "280px" }}>
                                {/* {v.description} */}
                                <TextArea
                                  size={size}
                                  rows={1}
                                  allowClear
                                  value={v.description}
                                  onChange={(e) => onChangeDescription(e, v.id)}
                                />
                              </td>
                              <td style={{ padding: "10px 6px" }}>
                                <Select
                                  size={size}
                                  defaultValue={unitNames}
                                  onChange={(e) => handleChangeSuite(e, v.id)}
                                  style={{ width: 90 }}
                                >
                                  {units}
                                </Select>
                              </td>
                              <td
                                className="price"
                                style={{ padding: "10px 6px" }}
                              >
                                <Space>
                                  <InputNumber
                                    size="small"
                                    min={0.1}
                                    precision={2}
                                    max={1000000000}
                                    defaultValue={v.price}
                                    onChange={(e) => onChangeNumber(e, v.id)}
                                  />
                                </Space>
                              </td>
                              <td
                                className="qty"
                                style={{ padding: "10px 6px" }}
                              >
                                <Space>
                                  <InputNumber
                                    size="small"
                                    min={1}
                                    max={1000000000}
                                    defaultValue={quantity}
                                    onChange={(e) => handleChange(e, v.id)}
                                  />
                                </Space>
                                {/* <Select
                                  size={size}
                                  defaultValue={quantity}
                                  onChange={(e) => handleChange(e, v.id)}
                                  style={{ width: 90 }}
                                >
                                  {children}
                                </Select> */}
                              </td>
                              <td style={{ padding: "10px 6px" }}>
                                <Link
                                  className="circleBtn danger deleteThis"
                                  to="#"
                                  onClick={() => deleteSpecificItem(v)}
                                >
                                  <Delete2 />
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  {addedPurchaseItems.length < 1 ? (
                    <div
                      className="mb-3"
                      style={{
                        border: "1px dashed #606071",
                        textAlign: "center",
                        fontSize: "16px",
                        marginRight: "10%",
                        marginLeft: "10%",
                        padding: "25px",
                      }}
                    >
                      Purchase Order is Empty. <br /> Please Add atleast one
                      item from the Purchase List.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="formDesign">
                  <div className="form-group">
                    <label>
                      Comments <small>(optional)</small>
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="cardFooter">
                  <button
                    className="myBtn mr-1"
                    onClick={() => submitPurchaseParts()}
                    disabled={submitLoader}
                    style={{ cursor: submitLoader && "not-allowed" }}
                  >
                    {submitLoader ? "Submitting ..." : "submit"}
                  </button>
                  <Link className="myBtn bdr" to={PATH.VIEW_EXISTING}>
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
