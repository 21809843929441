import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminApiCall, capatalizeLetters } from "../../../helpers/ajax_request";
import moment from "moment";

export default function Index() {
  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const navigate = useNavigate();

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  let validationSchema = yup.object({
    newPassword: yup
      .string()
      .required("Please specify your password.")
      .min(8, "The password should have at minimum length of 8."),
    confirmPassword: yup
      .string()
      .required("Please re-enter password to confirm")
      .oneOf([yup.ref("newPassword"), null], "Password does not match"),
  });

  const onSubmit = async (values) => {
    console.log("values", values);
    let data = {
      current_password: values.oldPassword,
      new_password: values.newPassword,
    };

    // console.log("data: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/change_password",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Password has been Reset!");
        navigate(PATH.BUILDING_LIST);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <section className="pageWrapper">
          <div className="contentCard ">
            <div className="titleWrapper">
              <span>Reset Password</span>
            </div>
            <div className="formDesign">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Enter Old Password</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.oldPassword && formik.touched.oldPassword
                          ? " is-invalid"
                          : "")
                      }
                      type="password"
                      name="oldPassword"
                      value={formik.values.oldPassword}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.oldPassword}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Enter New Password</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.newPassword && formik.touched.newPassword
                          ? " is-invalid"
                          : "")
                      }
                      type="password"
                      name="newPassword"
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.newPassword}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Confirm New Password</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.confirmPassword &&
                        formik.touched.confirmPassword
                          ? " is-invalid"
                          : "")
                      }
                      type="password"
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.confirmPassword}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardFooter">
              <button className="myBtn mr-1" type="submit">
                Update
              </button>
              <Link className="myBtn bdr" to={PATH.HOME}>
                Cancel
              </Link>
            </div>
          </div>
        </section>
      </form>
    </MainLayout>
  );
}
