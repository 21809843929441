import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Delete, Edit } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { adminApiCall, capatalizeLetters } from "../../../helpers/ajax_request";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import { baseDomain } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Index() {
  const [userList, setUserList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(10);
  const [perPage, setPerPage] = React.useState(50);
  const [deleteItem, setDeleteItem] = useState([]);
  const [name, setName] = useState(false);
  const [userName, setUserName] = useState(false);
  const [email, setEmail] = useState(false);
  const [role, setRole] = useState(false);
  const [createdAt, setCreatedAt] = useState(false);
  const [sortValue, setSortValue] = useState("0");
  var moment = require("moment");

  useEffect(() => {
    getUserList();
  }, [currentPage, name, userName, email, role, createdAt]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const getUserList = async () => {
    let data = {
      current_page: currentPage,
      itemsPerPage: perPage,
      ...(sortValue === "1"
        ? {
            sort_by_first_name: name,
          }
        : sortValue === "2"
        ? {
            sort_by_email: email,
          }
        : sortValue === "3"
        ? {
            sort_by_role: role,
          }
        : sortValue === "4"
        ? {
            sort_by_created_at: createdAt,
          }
        : ""),
    };

    let res = await adminApiCall(
      "/api/admin/user/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setUserList(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const handleChange = (e) => {
    const itemPerPage = parseInt(e.target.value);
    setPerPage(itemPerPage);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const handleChangeCheckBox = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    let tempArray = [...deleteItem];
    if (checked) {
      tempArray.push(value);
      // console.log("deleteitem: ", deleteItem);
    } else {
      tempArray.splice(tempArray.indexOf(value), 1);
      // console.log("deleteitem: ", deleteItem);
    }
    setDeleteItem(tempArray);
    // }
  };

  const deleteUser = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          // let dataArray = [];
          // dataArray.push(deleteArray);
          data = {
            user_id: deleteArray,
          };
        } else {
          data = {
            user_id: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/user/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getUserList();
            setDeleteItem([]);
            notifySuccess("User Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  const sortingName = () => {
    setName(!name);
    setSortValue("1");
  };
  const sortingEmail = () => {
    setEmail(!email);
    setSortValue("2");
  };
  const sortingRole = () => {
    setRole(!role);
    setSortValue("3");
  };
  const sortingCreatedAt = () => {
    setCreatedAt(!createdAt);
    setSortValue("4");
  };
  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="contentCard">
            <div className="titleWrapper">
              <span>User List</span>

              <Link className="myBtn shadow ml-auto" to="/addUser">
                <i className="fa fa-plus-circle"></i> Add User
              </Link>
            </div>
            {loader ? (
              <div className="text-center">
                <img src={Loader} alt="" />
              </div>
            ) : noRecord ? (
              <div className="text-center">
                <img src={NoRecordFound} alt="" />
              </div>
            ) : (
              <div className="shadowTable table-responsive dataTable scrollable">
                <table className="table">
                  <thead>
                    <tr>
                      <th width="30"></th>
                      <th className="sort" onClick={() => sortingName()}>
                        Name
                      </th>
                      <th className="sort" onClick={() => sortingEmail()}>
                        Email
                      </th>
                      <th className="sort" onClick={() => sortingRole()}>
                        Role
                      </th>
                      <th>Assigned Building</th>
                      <th className="sort" onClick={() => sortingCreatedAt()}>
                        Date of Creation
                      </th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      /* here the list is being render  */
                      userList.map((v, i) => (
                        <tr key={i}>
                          <td>
                            <input
                              type="checkbox"
                              className="customCheck"
                              id={"userCheck" + i}
                              value={v._id}
                              checked={
                                deleteItem && deleteItem.includes(v._id)
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleChangeCheckBox(e)}
                            />
                          </td>
                          <td>
                            <div className="profile dark">
                              <div className="profileImg">
                                <img
                                  src={`${baseDomain}/${v.profile_image}`}
                                  alt=""
                                />
                              </div>
                              <b>
                                {v.first_name} {v.last_name}
                              </b>
                            </div>
                          </td>
                          <td>{v.email}</td>
                          <td>
                            <b className="text success">
                              <span></span> {capatalizeLetters(v.role)}
                            </b>
                          </td>

                          {v.building.length !== 0 ? (
                            <td>
                              {v.building.map((obj, index) => {
                                return (
                                  <span
                                    key={index}
                                    className={`badge badge-pill`}
                                  >
                                    {obj?.building_name}{" "}
                                  </span>
                                );
                              })}
                            </td>
                          ) : (
                            <td></td>
                          )}

                          <td>{moment(v.created_at).format("DD MMMM YYYY")}</td>
                          <td className="text-right">
                            <Link
                              className="circleBtn"
                              to={`${PATH.EDIT_USER}/${v._id}`}
                            >
                              <Edit />
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )}

            {userList.length > 0 ? (
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-5">
                  Showing {currentPage * perPage - perPage + 1} to{" "}
                  {totalRecord > currentPage * perPage
                    ? currentPage * perPage
                    : totalRecord}{" "}
                  of {totalRecord} entries
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers text-right"
                    id="datatable_paginate"
                  >
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      onPageChange={(e) => handlePageClick(e)}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      pageClassName={"paginate_button page-item"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="cardFooter">
              <button
                className="myBtn danger squre"
                onClick={() => deleteUser(deleteItem)}
                disabled={deleteItem.length === 0 ? true : false}
              >
                <Delete />
              </button>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
