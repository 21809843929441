import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PATH } from "../../constants/paths";
import { Modal, Button } from "react-bootstrap";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminApiCall } from "../../helpers/ajax_request";

export default function WorkOrderCompletion(props) {
  const {
    setmodalshow,
    workorderid,
    marketcomplete,
    requiretrade,
    setrequiretrade,
    markedcompletearray,
    setmarkedcompletearray,
    itemID,
    setItemID,
    getWorkOrder,
  } = props;

  const initialValues = {
    dateVal: moment(),
    description: "",
  };

  // console.log("dateVal: ", initialValues.dateVal);
  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  let validationSchema = yup.object({
    dateVal: yup
      .object()
      // .min(2, "Please enter an address.")
      // .max(100, "Please enter an address.")
      .required("Please select completion date."),
    // description: yup
    //   .string()
    //   .trim()
    //   // .min(2, "Please enter an address.")
    //   // .max(100, "Please enter an address.")
    //   .required("Description is mandatory."),
  });

  const onSubmit = async (values) => {
    // console.log("all okay", values);
    if (formik.values.dateVal === "" || formik.values.dateVal === null) {
      notifyWarning("Please Enter Date");
    } else {
      let data = {
        work_order_id: workorderid,
        item_id: itemID,
        is_complete: marketcomplete,
        require_trade: false,
        item_repair_description: {
          description: formik.values.description,
          completed_at: formik.values.dateVal,
        },
      };
      // console.log("work_order_id: ", workorderid);
      // console.log("item_id: ", marketcomplete);
      // console.log("marketcomplete: ", marketcomplete);
      // console.log("requiretrade: ", requiretrade);
      // console.log(
      //   "formik.values.dateVal: ",
      //   typeof date === "string" ? date : date?.format("MM/DD/YYYY")
      // );
      // console.log("formik.values.description: ", formik.values.description);

      let res = await adminApiCall(
        "/api/admin/work_order/update_items",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("Item Mark Completed!");
          setrequiretrade(false);
          setmodalshow(false);
          // setItemID("");
          getWorkOrder();
          // navigate(PATH.WORK_ORDER);
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  function onChange(date, dateString) {
    // console.log(date, dateString);
    formik.setValues({
      dateVal: date === null ? "" : date,
    });
    // setDate(date === null ? "" : date);
  }

  const cancelBtnModal = () => {
    let tempArray = [...markedcompletearray];
    if (itemID) {
      // console.log("if working");
      tempArray.splice(tempArray.indexOf(itemID), 1);
      // console.log(tempArray);
    } else {
      // console.log("else working");
    }

    setmarkedcompletearray(tempArray);
    setmodalshow(false);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="modalTitle ">Completed?</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontWeight: "bold" }}>Completion Date</p>
          <Space direction="vertical">
            <DatePicker
              className={
                "form-control " +
                (formik.errors.dateVal && formik.touched.dateVal
                  ? " is-invalid"
                  : "")
              }
              style={{ width: "50%" }}
              defaultValue={formik.values.dateVal}
              format={"MM/DD/YYYY"}
              onChange={onChange}
              name="dateVal"
            />
            <div className="invalid-feedback">{formik.errors.dateVal}</div>
          </Space>
          <b>
            <label className="mt-4">
              Description (Specific Repairs or Parts Used){" "}
              <small>(Optional)</small>
            </label>
          </b>
          <br />
          <textarea
            className={
              "form-control "
              // +
              // (formik.errors.description && formik.touched.description
              //   ? " is-invalid"
              //   : "")
            }
            name="description"
            id=""
            cols="30"
            rows="5"
            style={{ width: "100%" }}
            onChange={formik.handleChange}
            value={formik.values.description}
          ></textarea>
          {/* <div className="invalid-feedback">{formik.errors.description}</div> */}
        </Modal.Body>
        <Modal.Footer>
          <div className="formDesign text-center">
            <Button
              className="myBtn shadow mr-1"
              // onClick={() => setmodalshow(false)}
              type="submit"
            >
              Update
            </Button>
            <Button
              className="myBtn shadow "
              onClick={() => cancelBtnModal()}
              type="button"
              // onClick={() => {
              //   setmodalshow(false);
              //   setmarketcomplete(false);
              // }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
