import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { adminApiCall } from "../../../helpers/ajax_request";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeAnimated from "react-select/animated";
import MySelect from "../../../components/SelectDropdown/Index";

const animatedComponents = makeAnimated();

export default function Index() {
  const params = useParams();
  const inventoryID = params.id;
  // console.log(inventoryID);

  const navigate = useNavigate();
  const [inventoryData, setInventoryData] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryNames, setCategoryNames] = useState("");
  const [options, setOptions] = useState([]);
  const [optionSelected, setOptionSelected] = useState({});

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const initialValues = {
    // category: "",
    expenseCode: "",
    modelNumber: "",
    price: "",
    description: "",
  };

  useEffect(() => {
    getExpenseCategory();
    getInventoryData();
    getCategoryList();
  }, []);

  const getCategoryList = async () => {
    let data = {
      property_id: "",
    };
    let res = await adminApiCall(
      "/api/admin/inventory_category/inventory_categories_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setCategory(res.data);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getExpenseCategory = async () => {
    let data = {
      property_id: "23746W",
    };
    let res = await adminApiCall(
      "/api/admin/expense_category/expense_categories_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res unittttt", res);
        let tempArray = [];
        res.data?.map((v, i) => {
          tempArray.push({
            label: v.category_name + " (" + v.expense_id + ")",
            value: v.category_name + "-" + v.expense_id,
          });
        });

        setOptions(tempArray);
        // setUnitNames(res.data[0]?.unit_no);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getInventoryData = async () => {
    let data = {
      inventory_id: inventoryID,
    };
    let res = await adminApiCall(
      "/api/admin/inventory/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setInventoryData(res.data);
        setOptionSelected({
          label:
            res.data?.expense_category + " (" + res.data?.expense_code + ")",
          value: res.data?.expense_category + "-" + res.data?.expense_code,
        });
        formik.setValues({
          expenseCode: res.data.expense_code,
          modelNumber: res.data.model_number,
          price: res.data.price,
          description: res.data.description,
        });
        setCategoryNames(res.data.inventory_category._id);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  let validationSchema = yup.object({
    expenseCode: yup.string().trim().required("Please specify expense code."),
    modelNumber: yup.string().trim().required("Please specify model number."),
    price: yup.string().trim().required("Price is required."),
  });

  const onSubmit = async (values) => {
    let data = {
      inventory_id: inventoryID,
      inventory_category_id: categoryNames,
      expense_code: optionSelected.value?.split("-")[1],
      expense_category: optionSelected.value?.split("-")[0],
      model_number: formik.values.modelNumber,
      price: formik.values.price,
      description: formik.values.description,
    };

    let res = await adminApiCall(
      "/api/admin/inventory/update",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Inventory Updated Successfully!");
        navigate(PATH.INVENTORY_LIST);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleChangeCategory = (e) => {
    setCategoryNames(e.target.value);
  };

  const handleChange = (selected) => {
    console.log(selected);
    setOptionSelected(selected);
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <form className="contentCard" onSubmit={formik.handleSubmit}>
            <div className="titleWrapper">
              <span>Edit Inventory Item</span>
            </div>
            <div className="formDesign">
              <h3 className="sectionTitle">Inventory Details</h3>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Category</label>
                    <select
                      className="form-control"
                      name="category"
                      value={categoryNames}
                      onChange={(e) => handleChangeCategory(e)}
                    >
                      {category &&
                        category.map((v, i) => {
                          return (
                            <option key={i} value={v._id}>
                              {v.category_name.charAt(0).toUpperCase() +
                                v.category_name.slice(1)}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Expense Code</label>
                    {/* <input
                      className={
                        "form-control " +
                        (formik.errors.expenseCode && formik.touched.expenseCode
                          ? " is-invalid"
                          : "")
                      }
                      type="number"
                      min="0"
                      name="expenseCode"
                      value={formik.values.expenseCode}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.expenseCode}
                    </div> */}
                    <MySelect
                      options={options}
                      // isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      components={animatedComponents}
                      onChange={handleChange}
                      allowSelectAll={false}
                      value={optionSelected}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Model Number</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.modelNumber && formik.touched.modelNumber
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="modelNumber"
                      value={formik.values.modelNumber}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.modelNumber}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Price ($)</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.price && formik.touched.price
                          ? " is-invalid"
                          : "")
                      }
                      type="tel"
                      min="0"
                      name="price"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.price}
                    </div>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardFooter">
              <button className="myBtn mr-1" type="submit">
                Update
              </button>
              <Link className="myBtn bdr" to={PATH.INVENTORY_LIST}>
                Cancel
              </Link>
            </div>
          </form>
        </section>
      </>
    </MainLayout>
  );
}
