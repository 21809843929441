import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../assets/images/LoaderNew.gif";
import MainLayout from "../../../layouts";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import moment from "moment";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Delete, Edit, File, Search } from "../../../Icons/Icons";
import { PATH } from "../../../constants/paths";

import {
  adminApiCall,
  userAccessibility,
  capatalizeLetters,
  getDate,
} from "../../../helpers/ajax_request";

export default function Index() {
  const buildingID = localStorage.getItem("building_id");
  const [trafficSheetListing, setTrafficSheetListing] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(10);
  const [perPage, setPerPage] = React.useState(50);
  const [deleteItem, setDeleteItem] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [suiteNoSort, setSuiteNoSort] = useState(false);
  const [actionSort, setActionSort] = useState(false);
  const [rentWithDiscountSort, setRentWithDiscountSort] = useState(false);
  const [standardRentSort, setStandardRentSort] = useState(false);
  const [vacateDateSort, setVacateDateSort] = useState(false);
  const [rentedAsOfSort, setRentedAsOfSort] = useState(false);
  const [statusSort, setStatusSort] = useState(false);
  const [sortValue, setSortValue] = useState("0");

  useEffect(() => {
    listTraffingSheet();
  }, [
    currentPage,
    searchBar,
    suiteNoSort,
    actionSort,
    rentWithDiscountSort,
    standardRentSort,
    vacateDateSort,
    rentedAsOfSort,
    statusSort,
  ]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const listTraffingSheet = async () => {
    let data = {
      current_page: currentPage,
      itemsPerPage: perPage,
      building_id: buildingID,

      search_value: searchBar,

      ...(sortValue === "1"
        ? {
            sort_by_suite_no: suiteNoSort,
          }
        : sortValue === "2"
        ? {
            sort_by_action: actionSort,
          }
        : sortValue === "3"
        ? {
            sort_by_discount_rent: rentWithDiscountSort,
          }
        : sortValue === "4"
        ? {
            sort_by_standard_rent: standardRentSort,
          }
        : sortValue === "5"
        ? {
            sort_by_vacate_date: vacateDateSort,
          }
        : sortValue === "6"
        ? {
            sort_by_rented_as_of: rentedAsOfSort,
          }
        : sortValue === "7"
        ? {
            sort_by_status: statusSort,
          }
        : ""),
    };

    let res = await adminApiCall(
      "/api/admin/traffic_sheet/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setTrafficSheetListing(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const searchHandler = (e) => {
    setSearchBar(e.target.value);
    // console.log(e.target.value);
  };

  const suiteSorting = () => {
    setSuiteNoSort(!suiteNoSort);
    setSortValue("1");
  };

  const actionSorting = () => {
    setActionSort(!actionSort);
    setSortValue("2");
  };

  const rentWithDiscountSorting = () => {
    setRentWithDiscountSort(!rentWithDiscountSort);
    setSortValue("3");
  };

  const standardRentSorting = () => {
    setStandardRentSort(!standardRentSort);
    setSortValue("4");
  };

  const vacateDateSorting = () => {
    setVacateDateSort(!vacateDateSort);
    setSortValue("5");
  };

  const rentedAsOfSorting = () => {
    setRentedAsOfSort(!rentedAsOfSort);
    setSortValue("6");
  };

  const statusSorting = () => {
    setStatusSort(!statusSort);
    setSortValue("7");
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const handleChangeCheckBox = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    let tempArray = [...deleteItem];
    if (checked) {
      tempArray.push(value);
      // console.log("addeditem: ", tempArray);
    } else {
      tempArray.splice(tempArray.indexOf(value), 1);
      // console.log("deleteitem: ", tempArray);
    }
    setDeleteItem(tempArray);
    // }
  };

  const deleteUser = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          // let dataArray = [];
          // dataArray.push(deleteArray);
          data = {
            traffic_sheet_id: deleteArray,
          };
        } else {
          data = {
            traffic_sheet_id: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/traffic_sheet/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            listTraffingSheet();
            setDeleteItem([]);
            notifySuccess("Traffic Sheet Item Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };
  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Traffic Sheet</span>
              <div className="d-flex justify-content-center align-items-center">
                <div className="search mr-2">
                  <div className="searchField">
                    <input
                      className="form-control searchBar"
                      type="text"
                      placeholder="Search"
                      value={searchBar}
                      onChange={searchHandler}
                    />
                    <div className="icon">
                      <Search />
                    </div>
                  </div>
                </div>
                {localStorage.getItem("role") === "viewer" ? (
                  userAccessibility("traffic_sheet_management", "create") ===
                  false ? (
                    ""
                  ) : (
                    <Link
                      className="myBtn shadow ml-auto"
                      to={PATH.CREATE_TRAFFIC_SHEET}
                    >
                      <i className="fa fa-plus-circle"></i> New Traffic Sheet
                      Item
                    </Link>
                  )
                ) : localStorage.getItem("role") === "building_manager" ? (
                  userAccessibility("traffic_sheet_management", "create") ===
                  false ? (
                    ""
                  ) : (
                    <Link
                      className="myBtn shadow ml-auto"
                      to={PATH.CREATE_TRAFFIC_SHEET}
                    >
                      <i className="fa fa-plus-circle"></i> New Traffic Sheet
                      Item
                    </Link>
                  )
                ) : localStorage.getItem("role") === "property_manager" ? (
                  userAccessibility("traffic_sheet_management", "create") ===
                  false ? (
                    ""
                  ) : (
                    <Link
                      className="myBtn shadow ml-auto"
                      to={PATH.CREATE_TRAFFIC_SHEET}
                    >
                      <i className="fa fa-plus-circle"></i> New Traffic Sheet
                      Item
                    </Link>
                  )
                ) : (
                  <Link
                    className="myBtn shadow ml-auto"
                    to={PATH.CREATE_TRAFFIC_SHEET}
                  >
                    <i className="fa fa-plus-circle"></i> New Traffic Sheet Item
                  </Link>
                )}
              </div>
            </div>

            {loader ? (
              <div className="text-center">
                <img src={Loader} alt="" />
              </div>
            ) : noRecord ? (
              <div className="text-center">
                <img src={NoRecordFound} alt="" />
              </div>
            ) : (
              <div className="mainTable table-responsive dataTable scrollable">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {localStorage.getItem("role") === "viewer" ? (
                        userAccessibility(
                          "traffic_sheet_management",
                          "delete"
                        ) === false ? (
                          ""
                        ) : (
                          <th></th>
                        )
                      ) : localStorage.getItem("role") ===
                        "building_manager" ? (
                        userAccessibility(
                          "traffic_sheet_management",
                          "delete"
                        ) === false ? (
                          ""
                        ) : (
                          <th></th>
                        )
                      ) : localStorage.getItem("role") ===
                        "property_manager" ? (
                        userAccessibility(
                          "traffic_sheet_management",
                          "delete"
                        ) === false ? (
                          ""
                        ) : (
                          <th></th>
                        )
                      ) : (
                        <th></th>
                      )}

                      <th className="sort" onClick={() => suiteSorting()}>
                        Apt. No.
                      </th>
                      <th>Bed</th>
                      <th>Bath</th>
                      <th>Sq. Ft.</th>
                      <th className="sort" onClick={() => actionSorting()}>
                        Notice
                      </th>
                      <th
                        className="sort"
                        onClick={() => rentWithDiscountSorting()}
                      >
                        Rent With 2% Discount
                      </th>
                      <th
                        className="sort"
                        onClick={() => standardRentSorting()}
                      >
                        Standard Rent
                      </th>
                      <th className="sort" onClick={() => vacateDateSorting()}>
                        Vacate Date
                      </th>
                      <th className="sort" onClick={() => rentedAsOfSorting()}>
                        Rented as of
                      </th>
                      <th className="sort" onClick={() => statusSorting()}>
                        Status
                      </th>
                      <th>DR</th>
                      <th>OI</th>
                      <th>II</th>
                      <th>LS</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trafficSheetListing &&
                      trafficSheetListing.map((v, i) => {
                        return (
                          <tr key={i}>
                            {localStorage.getItem("role") === "viewer" ? (
                              userAccessibility(
                                "traffic_sheet_management",
                                "delete"
                              ) === false ? (
                                ""
                              ) : (
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck"
                                    id={"trafficSheet" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>
                              )
                            ) : localStorage.getItem("role") ===
                              "building_manager" ? (
                              userAccessibility(
                                "traffic_sheet_management",
                                "delete"
                              ) === false ? (
                                ""
                              ) : (
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck"
                                    id={"trafficSheet" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>
                              )
                            ) : localStorage.getItem("role") ===
                              "property_manager" ? (
                              userAccessibility(
                                "traffic_sheet_management",
                                "delete"
                              ) === false ? (
                                ""
                              ) : (
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck"
                                    id={"trafficSheet" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>
                              )
                            ) : (
                              <td
                                className={`${
                                  v.unit_status === "OCCUPIED" ? "rented" : ""
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  className="customCheck"
                                  id={"trafficSheet" + i}
                                  value={v._id}
                                  checked={
                                    deleteItem && deleteItem.includes(v._id)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => handleChangeCheckBox(e)}
                                />
                              </td>
                            )}

                            <td
                              className={` orderItems ${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                              // style={{ paddingBottom: "32px" }}
                            >
                              <Link
                                // href="#"
                                to={`${PATH.VIEW_ASSIGNED_UNIT}/${v.unit_id}&${v._id}`}
                                className="d-flex justify-content-between"
                                style={{ color: "#2e4765" }}
                              >
                                <b>{v.unit_no} </b>
                                <b>
                                  <span className="magnifyIconTraffic">
                                    <Search />
                                    {v?.comments?.length === 0 ? (
                                      ""
                                    ) : (
                                      <div className="popUpHovers">
                                        {v?.comments?.map((val, ind) => {
                                          return (
                                            <div
                                              key={ind}
                                              className="comment"
                                              style={{
                                                margin: "4px 20px",
                                              }}
                                            >
                                              <h2>
                                                {capatalizeLetters(
                                                  val?.created_by_user_info
                                                    ?.first_name +
                                                    "_" +
                                                    val?.created_by_user_info
                                                      ?.last_name
                                                )}
                                                <span>
                                                  {getDate(val.created_at)}
                                                  {/* {moment(
                                                    new Date(
                                                      v.created_at
                                                    ).toISOString()
                                                  ).format("DD MMM YYYY")} */}
                                                </span>
                                              </h2>

                                              <p>{val.comment}</p>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </span>
                                </b>
                              </Link>
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              {v.bedroom === "BACHELOR" ||
                              v.bedroom === 0 ||
                              v.bedroom === "0"
                                ? "B"
                                : v.bedroom}
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              {v.bathroom.split(" ")[0]}
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              {v.square_footage}
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              {v.action}
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              $
                              {(
                                (v.rent_with_discount * 100) /
                                100
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              $
                              {((v.standard_rent * 100) / 100).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              {v?.vacate_date === null
                                ? ""
                                : moment
                                    .utc(v?.vacate_date)
                                    .format("DD MMMM YYYY")}
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              {v?.rented_as_of === null
                                ? ""
                                : moment
                                    .utc(v?.rented_as_of)
                                    .format("DD MMMM YYYY")}
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              <span
                                className={`text ${
                                  v.unit_status === "VACANT"
                                    ? "success"
                                    : v.unit_status === "OCCUPIED"
                                    ? "occupied"
                                    : "pending"
                                }`}
                              >
                                <span></span>{" "}
                                {v.unit_status === "VACANT"
                                  ? "VACANT"
                                  : v.unit_status === "OCCUPIED"
                                  ? "RENTED"
                                  : v.unit_status === "AVAILABLE"
                                  ? "AVAILABLE"
                                  : ""}
                              </span>
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              <input
                                type="checkbox"
                                className="cursore"
                                // style={{ cursor: "default" }}
                                value={v._id}
                                checked={v.damage_report ? true : false}
                                disabled
                                // onChange={(e) => handleChangeCheckBox(e)}
                              />
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              <input
                                type="checkbox"
                                className="cursore"
                                value={v._id}
                                checked={v.outgoing_inspection ? true : false}
                                disabled
                                // onChange={(e) => handleChangeCheckBox(e)}
                              />
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              <input
                                type="checkbox"
                                className="cursore"
                                value={v._id}
                                checked={v.incoming_inspection ? true : false}
                                disabled
                                // onChange={(e) => handleChangeCheckBox(e)}
                              />
                            </td>
                            <td
                              className={`${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              <input
                                type="checkbox"
                                className="cursore"
                                value={v._id}
                                checked={v.lease_signed ? true : false}
                                disabled
                                // onChange={(e) => handleChangeCheckBox(e)}
                              />
                            </td>
                            <td
                              className={`text-right ${
                                v.unit_status === "OCCUPIED" ? "rented" : ""
                              }`}
                            >
                              <div className="dropleft">
                                <button
                                  type="button"
                                  className="actionEclips dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </button>
                                <div className="dropdown-menu dropDesign">
                                  <Link
                                    to={`${PATH.VIEW_ASSIGNED_UNIT}/${v.unit_id}&${v._id}`}
                                  >
                                    View
                                    <span>
                                      <File />
                                    </span>
                                  </Link>
                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "traffic_sheet_management",
                                      "update"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={`${PATH.EDIT_TRAFFIC_SHEET}/${v._id}`}
                                      >
                                        Edit
                                        <span>
                                          <Edit />
                                        </span>
                                      </Link>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? (
                                    userAccessibility(
                                      "traffic_sheet_management",
                                      "update"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={`${PATH.EDIT_TRAFFIC_SHEET}/${v._id}`}
                                      >
                                        Edit
                                        <span>
                                          <Edit />
                                        </span>
                                      </Link>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "traffic_sheet_management",
                                      "update"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <Link
                                        to={`${PATH.EDIT_TRAFFIC_SHEET}/${v._id}`}
                                      >
                                        Edit
                                        <span>
                                          <Edit />
                                        </span>
                                      </Link>
                                    )
                                  ) : (
                                    <Link
                                      to={`${PATH.EDIT_TRAFFIC_SHEET}/${v._id}`}
                                    >
                                      Edit
                                      <span>
                                        <Edit />
                                      </span>
                                    </Link>
                                  )}
                                  {localStorage.getItem("role") === "viewer"
                                    ? userAccessibility(
                                        "traffic_sheet_management",
                                        "update"
                                      ) === false
                                      ? ""
                                      : v.unit_status === "VACANT"
                                      ? ""
                                      : ""
                                    : localStorage.getItem("role") ===
                                      "building_manager"
                                    ? userAccessibility(
                                        "traffic_sheet_management",
                                        "update"
                                      ) === false
                                      ? ""
                                      : v.unit_status === "VACANT"
                                      ? ""
                                      : ""
                                    : localStorage.getItem("role") ===
                                      "property_manager"
                                    ? userAccessibility(
                                        "traffic_sheet_management",
                                        "update"
                                      ) === false
                                      ? ""
                                      : v.unit_status === "VACANT"
                                      ? ""
                                      : ""
                                    : v.unit_status === "VACANT"
                                    ? ""
                                    : ""}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {trafficSheetListing && trafficSheetListing.length > 0 ? (
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-5">
                  Showing {currentPage * perPage - perPage + 1} to{" "}
                  {totalRecord > currentPage * perPage
                    ? currentPage * perPage
                    : totalRecord}{" "}
                  of {totalRecord} entries
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers text-right"
                    id="datatable_paginate"
                  >
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      // marginPagesDisplayed={2}
                      // pageRangeDisplayed={5}
                      onPageChange={(e) => handlePageClick(e)}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      pageClassName={"paginate_button page-item"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {localStorage.getItem("role") === "viewer" ? (
              userAccessibility("traffic_sheet_management", "delete") ===
              false ? (
                ""
              ) : (
                <div className="cardFooter">
                  <button
                    className="myBtn danger squre"
                    onClick={() => deleteUser(deleteItem)}
                    disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                </div>
              )
            ) : localStorage.getItem("role") === "building_manager" ? (
              userAccessibility("traffic_sheet_management", "delete") ===
              false ? (
                ""
              ) : (
                <div className="cardFooter">
                  <button
                    className="myBtn danger squre"
                    onClick={() => deleteUser(deleteItem)}
                    disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                </div>
              )
            ) : localStorage.getItem("role") === "property_manager" ? (
              userAccessibility("traffic_sheet_management", "delete") ===
              false ? (
                ""
              ) : (
                <div className="cardFooter">
                  <button
                    className="myBtn danger squre"
                    onClick={() => deleteUser(deleteItem)}
                    disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                </div>
              )
            ) : (
              <div className="cardFooter">
                <button
                  className="myBtn danger squre"
                  onClick={() => deleteUser(deleteItem)}
                  disabled={deleteItem.length === 0 ? true : false}
                >
                  <Delete />
                </button>
              </div>
            )}
          </div>
        </section>
      </>
    </MainLayout>
  );
}
