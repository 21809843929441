import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Modal } from "react-bootstrap";
import { adminApiCall } from "../../../helpers/ajax_request";
// import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeAnimated from "react-select/animated";
import MySelect from "../../../components/SelectDropdown/Index";

const animatedComponents = makeAnimated();
export default function Index(props) {
  const {
    show,
    onHide,
    setModalShow,
    getInventoryList,
    category,
    categoryNames,
    setCategoryNames,
    addtopurchaselist,
    addPurchaseItem,
    suite,
    quantity,
    setPrice,
    virtualID,
    setVirtualID,
  } = props;

  const [addToPOCheckBox, setAddToPOCheckBox] = useState(false);
  const [addMoreInventoryFlag, setAddMoreInventoryFlag] = useState(false);
  const [options, setOptions] = useState([]);
  // console.log(options);
  const [optionSelected, setOptionSelected] = useState({
    label: "Other",
    value: "Other-0000",
  });
  // console.log(optionSelected);

  const addToPOCheckBoxHandler = (e) => {
    setAddToPOCheckBox(e.target.checked);
    // console.log(e.target.checked);
  };

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const initialValues = {
    // category: "",
    // expenseCode: "",
    modelNumber: "",
    price: "",
    description: "",
  };

  let validationSchema = yup.object({
    // expenseCode: yup.string().required("Please specify expense code."),
    // modelNumber: yup.string().required("Please specify model number."),
    // price: yup.string().required("Price is required."),
    description: yup.string().required("Description is mandatory."),
  });

  useEffect(() => {
    getExpenseCategory();
  }, []);

  const getExpenseCategory = async () => {
    let data = {
      property_id: "23746W",
    };
    let res = await adminApiCall(
      "/api/admin/expense_category/expense_categories_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res unittttt", res);
        let tempArray = [
          {
            label: "Other",
            value: "Other-0000",
          },
        ];
        res.data?.map((v, i) => {
          tempArray.push({
            label: v.category_name + " (" + v.expense_id + ")",
            value: v.category_name + "-" + v.expense_id,
          });
        });
        // console.log(tempArray);

        setOptions(tempArray);
        // setUnitNames(res.data[0]?.unit_no);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    let data = {
      inventory_category_id: categoryNames,
      expense_code: optionSelected.value?.split("-")[1],
      expense_category: optionSelected.value?.split("-")[0],
      model_number: values.modelNumber,
      price: values.price === "" ? 0 : values.price,
      description: formik.values.description,

      is_temp: addtopurchaselist ? true : false,
      add_to_inventory: addtopurchaselist ? addToPOCheckBox : false,
    };

    // console.log("data sent: ", data);
    // return;
    if (addToPOCheckBox === true) {
      // if (categoryNames !== "Blank") {
      const abc = category.find((item) => item._id === categoryNames);
      // console.log(abc);
      // console.log("findIndex: ", abc.category_name);
      setVirtualID(virtualID + 1);
      // console.log("i: ", virtualID);

      ////////////////////////////////////check it (below func)//////////////////////////////////////
      addPurchaseItem(
        virtualID,
        abc.category_name,
        values.modelNumber,
        formik.values.description,
        values.price === "" ? 0 : values.price,
        quantity,
        suite
      );

      // console.log("1");
      if (addMoreInventoryFlag === false) {
        resetForm();
        // setDescription("");
        setModalShow(false);
      } else {
        // console.log("add more1");
        resetForm();
        // setDescription("");
      }
      setAddToPOCheckBox(false);
      // console.log("findIndex: ", abc.category_name);
      // setVirtualID(virtualID + 1);
      // console.log("i: ", virtualID);
      // console.log("data1: ", data);
      // return;
      let res = await adminApiCall(
        "/api/admin/inventory/create",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          if (addtopurchaselist) {
            notifySuccess("Inventory Added Successfully!");
            getInventoryList();
            if (addMoreInventoryFlag === false) {
              resetForm();
              setModalShow(false);
            } else {
              // console.log("add more3");
              resetForm();
            }
            setAddToPOCheckBox(false);
          } else {
            notifySuccess("Inventory Added Successfully!");
            getInventoryList();
            if (addMoreInventoryFlag === false) {
              resetForm();
              setModalShow(false);
            } else {
              // console.log("add more4");
              resetForm();
            }
            setAddToPOCheckBox(false);
          }
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    } else if (addtopurchaselist === false && addToPOCheckBox === false) {
      // console.log("data2: ", data);
      // return;
      let res = await adminApiCall(
        "/api/admin/inventory/create",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          if (addtopurchaselist) {
            notifySuccess("Inventory Added Successfully!");
            getInventoryList();
            if (addMoreInventoryFlag === false) {
              resetForm();
              setModalShow(false);
            } else {
              // console.log("add more5");
              resetForm();
            }
          } else {
            notifySuccess("Inventory Added Successfully!");
            getInventoryList();
            if (addMoreInventoryFlag === false) {
              resetForm();
              setModalShow(false);
            } else {
              // console.log("add more6");
              resetForm();
            }
          }
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    } else if (addtopurchaselist === true && addToPOCheckBox === false) {
      const abc = category.find((item) => item._id === categoryNames);
      // console.log(abc);
      setVirtualID(virtualID + 1);
      addPurchaseItem(
        virtualID,
        abc.category_name,
        values.modelNumber,
        formik.values.description,
        values.price === "" ? 0 : values.price,
        quantity,
        suite
      );
      // console.log("22");
      if (addMoreInventoryFlag === false) {
        resetForm();
        setModalShow(false);
      } else {
        // console.log("add more7");
        resetForm();
      }
      setAddToPOCheckBox(false);
      // console.log(
      //   virtualID + abc.category_name + values.modelNumber + values.price
      // );
      // console.log("values.price", values.price);
      // console.log("findIndex: ", abc.category_name);
      // console.log("i: ", virtualID);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const modalClose = () => {
    setModalShow(false);
  };

  const handleChangeCategory = (e) => {
    setCategoryNames(e.target.value);
    // console.log(e.target.value);
  };

  const handleMileageRateBlur = (e) => {
    let value = e.target.value < 0 ? 0 : Number(e.target.value).toFixed(2);
    formik.setValues({
      price: value,
    });
  };

  const handleChange = (selected) => {
    // console.log(selected);
    setOptionSelected(selected);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setModalShow(false);
        setAddMoreInventoryFlag(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Inventory Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contentCard ">
            <div className="formDesign">
              <h3 className="sectionTitle">Inventory Details</h3>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Category</label>
                    <select
                      className="form-control"
                      name="category"
                      value={categoryNames}
                      onChange={(e) => handleChangeCategory(e)}
                    >
                      {category &&
                        category.map((v, i) => {
                          return (
                            <option key={i} value={v._id}>
                              {v.category_name.charAt(0).toUpperCase() +
                                v.category_name.slice(1)}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      Expense Category <small>(Optional)</small>
                    </label>
                    {/* <input
                      className={
                        "form-control " +
                        (formik.errors.expenseCode && formik.touched.expenseCode
                          ? " is-invalid"
                          : "")
                      }
                      type="number"
                      min="0"
                      name="expenseCode"
                      value={formik.values.expenseCode}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.expenseCode}
                    </div> */}
                    <MySelect
                      options={options}
                      // isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      components={animatedComponents}
                      onChange={handleChange}
                      allowSelectAll={false}
                      value={optionSelected}
                      // defaultValue={{ label: "Other", value: "other" }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      Model Number <small>(Optional)</small>
                    </label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.modelNumber && formik.touched.modelNumber
                          ? " is-invalid"
                          : "")
                      }
                      type="text"
                      name="modelNumber"
                      value={formik.values.modelNumber}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.modelNumber}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      ($) Price <small>(Optional)</small>
                    </label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.price && formik.touched.price
                          ? " is-invalid"
                          : "")
                      }
                      type="number"
                      name="price"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.price}
                    </div>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className={
                        "form-control " +
                        (formik.errors.description && formik.touched.description
                          ? " is-invalid"
                          : "")
                      }
                      rows="5"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    ></textarea>
                    <div className="invalid-feedback">
                      {formik.errors.description}
                    </div>
                  </div>
                </div>
                {addtopurchaselist ? (
                  <div className="col-12">
                    <input
                      type="checkbox"
                      className="customCheck mr-2"
                      value={addToPOCheckBox}
                      onChange={(e) => addToPOCheckBoxHandler(e)}
                    />
                    <label htmlFor="">Add to Purchase List</label>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="myBtn mr-1"
            type="submit"
            onClick={() => setAddMoreInventoryFlag(true)}
          >
            Create and Add Another
          </button>
          <button
            className="myBtn mr-1"
            type="submit"
            onClick={() => setAddMoreInventoryFlag(false)}
          >
            Add
          </button>
          <button
            className="myBtn bdr"
            onClick={() => {
              setModalShow(false);
              setAddMoreInventoryFlag(false);
            }}
            type="button"
          >
            Cancel
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
