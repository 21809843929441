import * as types from "./login-constants"
import produce from "immer"

const initialState = {
    loading: false,
    user_name: ''
}

export const loginReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case types.LOGIN_REQUESTED:
                draft.loading = true
                break
            case types.LOGIN_SUCCESS:
                draft.loading = false
                draft.user_name = 'Mateen Masood'
                break
            case types.LOGIN_FAILED:
                draft.loading = false
                break
            default:
                return state
        }
    })