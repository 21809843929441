import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { PATH } from "../../../constants/paths";
import { Delete2 } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  adminApiCall,
  capatalizeLetters,
  getDate,
  getDateOnly,
  userAccessibility,
} from "../../../helpers/ajax_request";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import Swal from "sweetalert2";

export default function Index() {
  const params = useParams();
  const workOrderID = params.id;

  const [addComments, setAddComments] = useState("");
  const [workOrderDetails, setWorkOrderDetails] = useState({});
  const [workOrderItems, setWorkOrderItems] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);

  useEffect(() => {
    getWorkOrderDetail();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const getWorkOrderDetail = async () => {
    let data = {
      work_order_id: workOrderID,
    };
    let res = await adminApiCall(
      "/api/admin/work_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      setLoader(false);
      setNoRecord(true);
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setWorkOrderDetails(res.data);
        setWorkOrderItems(res.data?.items);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const addComment = async () => {
    let data = {
      work_order_id: workOrderID,
      comment: { comment: addComments },
    };
    let res = await adminApiCall(
      "/api/admin/work_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setAddComments("");
        getWorkOrderDetail();
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const deleteComment = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            work_order_id: workOrderID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            work_order_id: workOrderID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/work_order/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getWorkOrderDetail();
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Work Order Details</span>
              </div>
              <div className="details">
                <div className="detailContent">
                  <h4>Work Order ID</h4>
                  <h2>{workOrderDetails?.work_order_id}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created Date</h4>
                  <h2>
                    {getDateOnly(workOrderDetails.work_order_creation_date)}
                  </h2>
                </div>
                {workOrderDetails?.suite_no === "" ||
                workOrderDetails?.suite_no === null ? (
                  ""
                ) : (
                  <div className="detailContent">
                    <h4>Apt. No.</h4>
                    <h2>{workOrderDetails?.suite_no}</h2>
                  </div>
                )}

                <div className="detailContent">
                  <h4>Tenant Name</h4>
                  <h2>
                    {workOrderDetails?.tenant_name?.charAt(0).toUpperCase() +
                      workOrderDetails?.tenant_name?.slice(1)}
                  </h2>
                </div>
                {workOrderDetails?.phone_no === null || "" ? (
                  ""
                ) : (
                  <div className="detailContent">
                    <h4>Phone Number</h4>
                    <h2>
                      {/* {" (" +
                        workOrderDetails?.phone_no?.split("")[0] +
                        workOrderDetails?.phone_no?.split("")[1] +
                        workOrderDetails?.phone_no?.split("")[2] +
                        ") " +
                        workOrderDetails?.phone_no?.split("")[3] +
                        workOrderDetails?.phone_no?.split("")[4] +
                        workOrderDetails?.phone_no?.split("")[5] +
                        " - " +
                        workOrderDetails?.phone_no?.split("")[6] +
                        workOrderDetails?.phone_no?.split("")[7] +
                        workOrderDetails?.phone_no?.split("")[8] +
                        workOrderDetails?.phone_no?.split("")[9]} */}

                      {" ("}
                      {workOrderDetails?.phone_no?.split("")[0] !== undefined
                        ? workOrderDetails?.phone_no?.split("")[0]
                        : ""}
                      {workOrderDetails?.phone_no?.split("")[1] !== undefined
                        ? workOrderDetails?.phone_no?.split("")[1]
                        : ""}
                      {workOrderDetails?.phone_no?.split("")[2] !== undefined
                        ? workOrderDetails?.phone_no?.split("")[2]
                        : ""}
                      {") "}
                      {workOrderDetails?.phone_no?.split("")[3] !== undefined
                        ? workOrderDetails?.phone_no?.split("")[3]
                        : ""}
                      {workOrderDetails?.phone_no?.split("")[4] !== undefined
                        ? workOrderDetails?.phone_no?.split("")[4]
                        : ""}
                      {workOrderDetails?.phone_no?.split("")[5] !== undefined
                        ? workOrderDetails?.phone_no?.split("")[5]
                        : ""}
                      {" - "}
                      {workOrderDetails?.phone_no?.split("")[6] !== undefined
                        ? workOrderDetails?.phone_no?.split("")[6]
                        : ""}
                      {workOrderDetails?.phone_no?.split("")[7] !== undefined
                        ? workOrderDetails?.phone_no?.split("")[7]
                        : ""}
                      {workOrderDetails?.phone_no?.split("")[8] !== undefined
                        ? workOrderDetails?.phone_no?.split("")[8]
                        : ""}
                      {workOrderDetails?.phone_no?.split("")[9] !== undefined
                        ? workOrderDetails?.phone_no?.split("")[9]
                        : ""}
                    </h2>
                  </div>
                )}
                <div className="detailContent">
                  <h4>Tenant Signature</h4>
                  <img
                    className="p-4 sigView"
                    src={workOrderDetails?.tenant_signature}
                    alt=""
                  />
                </div>
                <div className="detailContent">
                  <h4>Completion Status</h4>
                  <h2
                    className={`badge ${
                      workOrderDetails?.order_status === "COMPLETED"
                        ? "success"
                        : "pending"
                    }`}
                  >
                    {workOrderDetails?.order_status}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-9">
            <div className="contentCard mb-0">
              <div className="titleWrapper">
                <span>Work Order Items</span>
              </div>
              {loader ? (
                <div className="text-center">
                  <img src={Loader} alt="" />
                </div>
              ) : noRecord ? (
                <div className="text-center">
                  <img src={NoRecordFound} alt="" />
                </div>
              ) : (
                <div className="mainTable table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ maxWidth: "60px" }}>Item #</th>
                        <th style={{ maxWidth: "300px" }}>Specific Items</th>
                        <th style={{ minWidth: "150px" }}>Completed</th>
                        <th style={{ minWidth: "200px" }}>Comments</th>
                        <th style={{ minWidth: "150px", textAlign: "center" }}>
                          Require Trades
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {workOrderItems &&
                        workOrderItems?.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                <b>{v.item}</b>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  className="customRadio"
                                  id={v._id}
                                  checked={v.is_complete ? "checked" : false}
                                  disabled={true}
                                />
                                {v?.item_repair_description[0]?.completed_at ? (
                                  <small className="">
                                    {" "}
                                    (
                                    {getDate(
                                      v?.item_repair_description[0]
                                        ?.completed_at
                                    )}
                                    ){" "}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {" "}
                                <p>
                                  {" "}
                                  {v?.item_repair_description[0]?.description
                                    ? v?.item_repair_description[0]?.description
                                    : ""}
                                </p>{" "}
                              </td>
                              <td
                                style={{ textAlign: "center", paddingLeft: "" }}
                              >
                                <input
                                  type="checkbox"
                                  className="customRadio"
                                  id={v._id}
                                  checked={v.require_trade ? "checked" : false}
                                  disabled={true}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="cardFooter">
                <Link className="myBtn bdr" to={PATH.WORK_ORDER}>
                  Back to Listing
                </Link>
              </div>
            </div>
            <div className="contentCard commentWrapper mt-3">
              <div className="formDesign">
                <div className="form-group">
                  <label>
                    Comments <small>(optional)</small>
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={addComments}
                    onChange={(e) => setAddComments(e.target.value)}
                  ></textarea>
                </div>
                <button
                  className="myBtn success mb-2"
                  onClick={() => addComment()}
                  disabled={!addComments ? true : false}
                >
                  Add Comment
                </button>
              </div>
              <div className="commentLogs">
                {workOrderDetails?.comments?.map((v, i) => {
                  return (
                    <div key={i} className="comment">
                      <h2>
                        {capatalizeLetters(v?.user_name)}
                        <span>
                          {getDate(v.created_at)}{" "}
                          {localStorage.getItem("role") === "viewer" ? (
                            userAccessibility(
                              "work_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : localStorage.getItem("role") ===
                            "building_manager" ? (
                            userAccessibility(
                              "work_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : localStorage.getItem("role") ===
                            "property_manager" ? (
                            userAccessibility(
                              "work_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : (
                            <a
                              href="#"
                              className="circleBtn danger deleteThis ml-2"
                              onClick={() => {
                                deleteComment([v._id]);
                              }}
                            >
                              <Delete2 />
                            </a>
                          )}
                        </span>
                      </h2>
                      <p>{v.comment}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
