import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { PATH } from "../../../constants/paths";
import MainLayout from "../../../layouts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  adminApiCall,
  capatalizeLetters,
  getDate,
  getDateOnly,
} from "../../../helpers/ajax_request";

export default function Index() {
  const params = useParams();
  const maintenanceServiceID = params.id;

  const [maintenanceOrderListing, setMaintenanceOrderListing] = useState({});
  const [maintenanceServiceItems, setMaintenanceServiceItems] = useState([]);
  const [addComments, setAddComments] = useState("");

  useEffect(() => {
    getMaintenanceService();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const getMaintenanceService = async () => {
    let data = {
      purchase_order_id: maintenanceServiceID,
      order_type: "maintenance_service",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setMaintenanceOrderListing(res.data);
        setMaintenanceServiceItems(res.data?.maintenance_service_items);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const addComment = async () => {
    let data = {
      purchase_order_id: maintenanceServiceID,
      comment: { comment: addComments },
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setAddComments("");
        getMaintenanceService();
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Maintenance Service Details</span>
              </div>
              <div className="details">
                <div className="detailContent">
                  <h4>Maintenance Service ID</h4>
                  <h2>{maintenanceOrderListing?.order_id}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created Date</h4>
                  <h2>{getDateOnly(maintenanceOrderListing.created_at)}</h2>
                </div>
                <div className="detailContent">
                  <h4>Type</h4>
                  <h2>
                    {capatalizeLetters(
                      maintenanceOrderListing?.order_type +
                        " " +
                        maintenanceOrderListing?.order_type
                    )}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>Assigned Trade</h4>
                  <h2>{maintenanceOrderListing?.trade_info?.company}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created By</h4>
                  <h2>
                    {capatalizeLetters(
                      maintenanceOrderListing?.order_requested_by_user_info
                        ?.first_name +
                        "_" +
                        maintenanceOrderListing?.order_requested_by_user_info
                          ?.last_name
                    )}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>State</h4>
                  <h2
                    className={`badge ${
                      maintenanceOrderListing?.order_state === "PENDING"
                        ? "pending"
                        : maintenanceOrderListing?.order_state === "APPROVED"
                        ? "success"
                        : maintenanceOrderListing?.order_state === "CONFIRM"
                        ? "success"
                        : "danger"
                    }`}
                  >
                    {maintenanceOrderListing?.order_state}
                  </h2>
                </div>
              </div>
            </div>
            {maintenanceOrderListing?.logs?.length < 1 ? (
              ""
            ) : (
              <div className="contentCard">
                <div className="titleWrapper">
                  <span>Logs</span>
                </div>
                <div className="commentLogs">
                  {maintenanceOrderListing?.logs?.map((v, i) => {
                    return (
                      <div key={i} className="comment">
                        <h2>
                          {capatalizeLetters(
                            v?.created_by_user_info?.first_name +
                              "_" +
                              v?.created_by_user_info?.last_name
                          )}{" "}
                          <span>{getDate(v.created_at)}</span>
                        </h2>
                        <p>{v.log_detail?.log}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="col-12 col-md-8 col-lg-9">
            <div className="contentCard mb-0">
              <div className="titleWrapper">
                <span>Verify Service</span>
              </div>
              <div className="mainTable table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Apt. No.</th>
                      <th>Price</th>
                      <th className="text-center">Completed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {maintenanceServiceItems &&
                      maintenanceServiceItems.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <b>{v.description}</b>
                            </td>
                            <td>{v.suite_no}</td>
                            <td>
                              <b>{v.price ? "$" + v.price : ""}</b>
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                className="customRadio"
                                checked={v.is_complete ? true : false}
                                disabled
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="cardFooter">
                <Link className="myBtn bdr" to={PATH.PO_REPORT}>
                  Back to Listing
                </Link>
              </div>
            </div>
            <div className="contentCard commentWrapper mt-3">
              <div className="formDesign">
                <div className="form-group">
                  <label>
                    Comments <small>(optional)</small>
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={addComments}
                    onChange={(e) => setAddComments(e.target.value)}
                  ></textarea>
                </div>
                <button
                  className="myBtn success mb-2"
                  onClick={() => addComment()}
                  disabled={!addComments ? true : false}
                >
                  Add Comment
                </button>
              </div>
              <div className="commentLogs">
                {maintenanceOrderListing?.comments?.map((v, i) => {
                  return (
                    <div key={i} className="comment">
                      <h2>
                        {capatalizeLetters(
                          v?.created_by_user_info?.first_name +
                            "_" +
                            v?.created_by_user_info?.last_name
                        )}{" "}
                        <span>{getDate(v.created_at)}</span>
                      </h2>
                      <p>{v.comment}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
