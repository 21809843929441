import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Delete, Edit, File, Search } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import {
  adminApiCall,
  userAccessibility,
  capatalizeLetters,
  getDate,
} from "../../../helpers/ajax_request";

export default function Index() {
  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const navigate = useNavigate();

  const params = useParams();
  const ID = params.id;

  const [field, setField] = useState([]);

  const [contentEditorState, setContentEditorState] = useState(
    EditorState.createEmpty()
  );
  const [contentEditorPlainHtml, setContentEditorPlainHtml] = useState("");
  const [recordDetail, setRecordDetail] = useState("");
  const [subject, setSubject] = useState("");
  // const [body, setBody] = useState("");

  useEffect(() => {
    if (!ID) {
      notifyError("Invalid_access_error");
    } else {
      getEmailTemplate();
    }
  }, []);

  const getEmailTemplate = async () => {
    let data = {
      id: ID,
    };

    // console.log("data: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/application_setting/email_template/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log(res);
        setRecordDetail(res?.data?.template_name);
        setSubject(res?.data?.template?.subject);
        setField(res.data?.template?.field);
        setContentEditorPlainHtml(res?.data?.template?.body);
        setContentEditorState(
          res?.data?.template?.body === ("" || null)
            ? EditorState.createEmpty()
            : EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(res?.data?.template?.body)
                )
              )
        );

        // this.setState({
        //     recordDetail: res?.data,
        //     subject: res?.data?.template?.subject,
        //     contentEditorPlainHtml: res?.data?.template?.body,
        //     contentEditorState:
        //       res?.data?.template?.body === ("" || null)
        //         ? EditorState.createEmpty()
        //         : EditorState.createWithContent(
        //             ContentState.createFromBlockArray(
        //               htmlToDraft(res?.data?.template?.body)
        //             )
        //           ),
        //   });
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const onContentEditorStateChange = (contentEditorState) => {
    let editorSourceHTML = draftToHtml(
      convertToRaw(contentEditorState.getCurrentContent())
    );
    // console.log("editorsourceHTML =====> ", editorSourceHTML);
    setContentEditorState(contentEditorState);
    // console.log("contentEditorState =====> ", contentEditorState);
    setContentEditorPlainHtml(editorSourceHTML);
  };

  const submit = async () => {
    let data = {
      id: ID,
      template: {
        subject: subject,
        body: contentEditorPlainHtml,
        field: field,
      },
    };
    // console.log("data sent: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/application_setting/email_template/create_email_template",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again.");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Email Template Updated Successfully!");
        navigate(PATH.APPLICATION_SETTINGS);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Update Email Template</span>
            </div>
            <div className="formDesign">
              <div className="row">
                <div className="col-lg-10">
                  <div className="row align-items-center">
                    <div className="col-lg-3 my-1">
                      <label>Template Title</label>
                    </div>
                    <div className="col-lg-9 my-1">
                      <input
                        className="form-control"
                        type="text"
                        value={recordDetail}
                        disabled
                        onChange={(e) => {
                          setRecordDetail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-lg-3 my-1">
                      <label>Subject</label>
                    </div>
                    <div className="col-lg-9 my-1">
                      <input
                        className="form-control"
                        type="text"
                        value={subject}
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-lg-3 my-1">
                      <label>Body</label>
                    </div>
                    <div className="col-lg-9 my-1">
                      <Editor
                        id={"pageContent"}
                        name={"content"}
                        editorState={contentEditorState}
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onContentEditorStateChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <h6>Placeholders</h6>
                  <div
                    className="cardBody"
                    style={{
                      background: "#e7e2e2",
                      textAlign: "center",
                      border: "1px solid #cec8c8",
                      padding: "5px",
                      minHeight: "100px",
                      maxHeight: "400px",
                      overflow: "auto",
                    }}
                  >
                    {field &&
                      field.map((val, index) => {
                        return (
                          <div key={index}>
                            <code style={{ color: "#354fcf" }}> {val} </code>
                            {index !== field.length - 1 && <hr />}{" "}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="cardFooter">
              <button className="myBtn mr-1" type="submit" onClick={submit}>
                Save
              </button>
              <Link className="myBtn bdr" to={PATH.APPLICATION_SETTINGS}>
                Cancel
              </Link>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
