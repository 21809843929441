import React, { createContext, useState, useEffect } from "react";

const BuildingContexxt = createContext();

export default function BuildingContext({ children }) {
  const [buildingName, setBuildingName] = useState("");

  useEffect(() => {
    console.log("inside context ===> ", buildingName);
  }, [buildingName]);

  return (
    <BuildingContexxt.Provider value={{ buildingName, setBuildingName }}>
      {children}
    </BuildingContexxt.Provider>
  );
}

export { BuildingContexxt };
