import React, { useEffect } from "react";
import Routes from "./routes";
import Gleap from "gleap";
// import 'bootstrap';
// import 'bootstrap/dist/js/bootstrap.bundle';

function App() {
  useEffect(() => {
    Gleap.initialize("NPxpN8miVhzc1KsdiGdZKUNDValqBoYz");
  }, []);
  return <Routes />;
}

export default App;
