import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import { Delete, Edit, Like, Search } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { adminApiCall, userAccessibility } from "../../../helpers/ajax_request";
import Swal from "sweetalert2";
import AddInventory from "../AddInventory/Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Index() {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);

  //////////////////////InventoryList//////////////////////
  const [inventoryList, setInventoryList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [perPage, setPerPage] = useState(50);
  const [deleteItem, setDeleteItem] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [categorySort, setCategorySort] = useState(false);
  const [sortValue, setSortValue] = useState("0");

  const [modelNumber, setModelNumber] = useState(false);
  const [expenseCode, setExpenseCode] = useState(false);

  // console.log("expensecode", expenseCode);
  const [price, setPrice] = useState(false);
  const [description, setDescription] = useState(false);
  const [addToPurchaseList, setAddToPurchaseList] = useState(false);
  const [completeArray, setCompleteArray] = useState([]);

  //////////////////////AddInventory//////////////////////
  const [category, setCategory] = useState([]);
  const [categoryNames, setCategoryNames] = useState(category[0]?._id);

  //////////////////////FavouriteList//////////////////////
  const [favouriteList, setFavouriteList] = useState([]);
  const [loaderFavourite, setLoaderFavourite] = useState(true);
  const [noRecordFavourite, setNoRecordFavourite] = useState(false);
  const [pageCountFavourite, setPageCountFavourite] = useState(10);
  const [currentPageFavourite, setCurrentPageFavourite] = useState(1);
  const [totalRecordFavourite, setTotalRecordFavourite] = useState(10);
  const [perPageFavourite, setPerPageFavourite] = useState(50);
  const [favouriteCheckBox, setfavouriteCheckBox] = useState(false);
  const [checkedFavourite, setCheckedFavourite] = useState(true);
  const [favouritesArray, setFavouritesArray] = useState([]);
  const [categorySortFavourite, setCategorySortFavourite] = useState(false);
  const [modelNumberFavourite, setModelNumberFavourite] = useState(false);
  const [expenseCodeFavourite, setExpenseCodeFavourite] = useState(false);
  const [priceFavourite, setPriceFavourite] = useState(false);
  const [descriptionFavourite, setDescriptionFavourite] = useState(false);
  const [sortValueFavourite, setSortValueFavourite] = useState("0");
  let [typingTimer, setTypingTimer] = useState(0);
  // console.log(typingTimer);

  useEffect(() => {
    getInventoryList();
    getCategoryList();

    if (localStorage.getItem("role") === "viewer") {
      if (userAccessibility("inventory_management", "delete") === false) {
      } else {
        getFavouriteList();
      }
    } else if (localStorage.getItem("role") === "building_manager") {
      if (userAccessibility("inventory_management", "delete") === false) {
      } else {
        getFavouriteList();
      }
    } else if (localStorage.getItem("role") === "property_manager") {
      if (userAccessibility("inventory_management", "delete") === false) {
      } else {
        getFavouriteList();
      }
    } else {
      getFavouriteList();
    }
  }, [
    currentPage,
    // searchBar,
    categorySort,
    modelNumber,
    expenseCode,
    description,
    price,
    categorySortFavourite,
    modelNumberFavourite,
    expenseCodeFavourite,
    descriptionFavourite,
    priceFavourite,
  ]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const searchHandler = (e) => {
    setSearchBar(e.target.value);
  };

  const keyUpSearchHandler = () => {
    const doneTypingInterval = 500; //time in ms, 5 seconds for example

    clearTimeout(typingTimer);
    typingTimer = setTimeout(getInventoryList, doneTypingInterval);
    // setCurrentPage(1);
  };

  const keyDownSearchHandler = () => {
    clearTimeout(typingTimer);
  };

  const getInventoryList = async () => {
    let data = {
      current_page: currentPage,
      itemsPerPage: perPage,
      search_value: searchBar,
      is_temp: false,

      ...(sortValue === "1"
        ? {
            sort_by_category: categorySort,
          }
        : sortValue === "2"
        ? {
            sort_by_model_no: modelNumber,
          }
        : sortValue === "3"
        ? {
            sort_by_expense_code: expenseCode,
          }
        : sortValue === "4"
        ? {
            sort_by_price: price,
          }
        : sortValue === "5"
        ? {
            sort_by_description: description,
          }
        : ""),
    };
    // console.log("1: ", data);

    let res = await adminApiCall(
      "/api/admin/inventory/list",
      "POST",
      JSON.stringify(data)
    );

    // console.log("2: ", data);
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        let completeValue = [];
        res.data?.map((v, i) => {
          completeValue.push(v.is_favourite);
        });
        // let newValue = res?.data?.filter((v) => {
        //   if (v.is_favourite === true || v.is_favourite === false) {
        //     return v;
        //   }
        // });
        // // console.log(newValue);
        setCompleteArray(completeValue);
        setInventoryList(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);

        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const getFavouriteList = async () => {
    let data = {
      current_page: currentPageFavourite,
      itemsPerPage: perPageFavourite,
      // search_value: searchBar,
      ...(sortValueFavourite === "1"
        ? {
            sort_by_category: categorySortFavourite,
          }
        : sortValueFavourite === "2"
        ? {
            sort_by_model_no: modelNumberFavourite,
          }
        : sortValueFavourite === "3"
        ? {
            sort_by_expense_code: expenseCodeFavourite,
          }
        : sortValueFavourite === "4"
        ? {
            sort_by_price: priceFavourite,
          }
        : sortValueFavourite === "5"
        ? {
            sort_by_description: descriptionFavourite,
          }
        : ""),
    };

    let res = await adminApiCall(
      "/api/admin/inventory/favourite_inventories_list",
      "POST",
      JSON.stringify(data)
    );

    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setFavouriteList(res.data);
        setPageCountFavourite(res.total_pages);
        setTotalRecordFavourite(res.total_records);
        setLoaderFavourite(false);
        setNoRecordFavourite(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoaderFavourite(false);
        setNoRecordFavourite(true);
      } else if (res.status === 300) {
        setLoaderFavourite(false);
        setNoRecordFavourite(true);
      }
    }
  };

  const handleChange = (e) => {
    const itemPerPage = parseInt(e.target.value);
    setPerPage(itemPerPage);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const handlePageClickFavourite = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPageFavourite(selectedPage);
  };

  const getCategoryList = async () => {
    let data = {
      property_id: "",
    };
    let res = await adminApiCall(
      "/api/admin/inventory_category/inventory_categories_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        console.log("inventory_categories_select_list", res);

        setCategory(res.data);
        setCategoryNames(res.data[0]?._id);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const handleChangeCheckBox = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    // console.log(e);
    // console.log(checked + value);
    // if (deleteItem) {
    let tempArray = [...deleteItem];
    if (checked) {
      tempArray.push(value);
      // console.log("deleteitem: ", deleteItem);
    } else {
      tempArray.splice(tempArray.indexOf(value), 1);
      // console.log("deleteitem: ", deleteItem);
    }
    setDeleteItem(tempArray);
    // }
  };

  const deleteInventory = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          // let dataArray = [];
          // dataArray.push(deleteArray);
          data = {
            inventory_id: deleteArray,
          };
        } else {
          data = {
            inventory_id: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/inventory/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getInventoryList();
            setDeleteItem([]);
            notifySuccess("Inventory Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  const handleChangeFavourites = async (e, objValue, id, position) => {
    const updatedCheckedState = completeArray.map((item, index) =>
      index === position ? !item : item
    );

    setCompleteArray(updatedCheckedState);
    setfavouriteCheckBox(e.target.checked);

    let data = {
      inventory_id: id,
      is_favourite: e.target.checked,
    };
    let res = await adminApiCall(
      "/api/admin/inventory/favourite_inventory",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess(res.message);
        getFavouriteList();
        getInventoryList();
        favouritesArray.push(objValue);

        favouritesArray.splice();
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const handleChangeRemoveFavourites = async (e, objValue) => {
    let ID = e.target.id.split("-").pop();

    setCheckedFavourite(e.target.checked);
    // console.log(e.target.checked);
    // console.log(e.target.value);

    let data = {
      inventory_id: e.target.value,
      is_favourite: e.target.checked,
    };

    let res = await adminApiCall(
      "/api/admin/inventory/favourite_inventory",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess(res.message);
        // navigate(PATH.USER_LIST);
        getInventoryList();
        getFavouriteList();
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const categorySortt = () => {
    setCategorySort(!categorySort);
    setSortValue("1");
  };

  const modelNumberSort = () => {
    setModelNumber(!modelNumber);
    setSortValue("2");
  };

  const expenseSort = () => {
    setExpenseCode(!expenseCode);
    setSortValue("3");
  };

  const priceSort = () => {
    setPrice(!price);
    setSortValue("4");
  };

  const descriptionSort = () => {
    setDescription(!description);
    setSortValue("5");
  };

  const categorySorttFav = () => {
    setCategorySortFavourite(!categorySortFavourite);
    setSortValueFavourite("1");
  };

  const modelNumberSortFav = () => {
    setModelNumberFavourite(!modelNumberFavourite);
    setSortValueFavourite("2");
  };

  const expenseSortFav = () => {
    setExpenseCodeFavourite(!expenseCodeFavourite);
    setSortValueFavourite("3");
  };

  const priceSortFav = () => {
    setPriceFavourite(!priceFavourite);
    setSortValueFavourite("4");
  };

  const descriptionSortFav = () => {
    setDescriptionFavourite(!descriptionFavourite);
    setSortValueFavourite("5");
  };
  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="tabsSection">
            <ul className="nav customTabs">
              <li>
                <a className="nav-link active" data-toggle="tab" href="#view">
                  View Inventory
                </a>
              </li>
              {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("inventory_management", "delete") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <a className="nav-link" data-toggle="tab" href="#fav">
                      Favourites
                    </a>
                  </li>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("inventory_management", "delete") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <a className="nav-link" data-toggle="tab" href="#fav">
                      Favourites
                    </a>
                  </li>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("inventory_management", "delete") ===
                false ? (
                  ""
                ) : (
                  <li>
                    <a className="nav-link" data-toggle="tab" href="#fav">
                      Favourites
                    </a>
                  </li>
                )
              ) : (
                <li>
                  <a className="nav-link" data-toggle="tab" href="#fav">
                    Favourites
                  </a>
                </li>
              )}
            </ul>
            <div className="d-flex justify-content-center align-items-center">
              <div className="search mr-2">
                <div className="searchField">
                  <input
                    className="form-control searchBar"
                    type="text"
                    placeholder="Search"
                    value={searchBar}
                    onChange={searchHandler}
                    onKeyUp={keyUpSearchHandler}
                    onKeyDown={keyDownSearchHandler}
                  />
                  <div className="icon">
                    <Search />
                  </div>
                </div>
              </div>
              {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("inventory_category_management", "delete") ===
                false ? (
                  ""
                ) : (
                  <Link className="myBtn shadow mr-1" to={PATH.MANAGE_CATEGORY}>
                    <i className="fa fa-plus-circle"></i> Manage Categories
                  </Link>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("inventory_category_management", "delete") ===
                false ? (
                  ""
                ) : (
                  <Link className="myBtn shadow mr-1" to={PATH.MANAGE_CATEGORY}>
                    <i className="fa fa-plus-circle"></i> Manage Categories
                  </Link>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("inventory_category_management", "delete") ===
                false ? (
                  ""
                ) : (
                  <Link className="myBtn shadow mr-1" to={PATH.MANAGE_CATEGORY}>
                    <i className="fa fa-plus-circle"></i> Manage Categories
                  </Link>
                )
              ) : (
                <Link className="myBtn shadow mr-1" to={PATH.MANAGE_CATEGORY}>
                  <i className="fa fa-plus-circle"></i> Manage Categories
                </Link>
              )}

              {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("inventory_management", "create") ===
                false ? (
                  ""
                ) : (
                  <Button
                    className="myBtn shadow reactBoostrapModalBtn"
                    onClick={() => setModalShow(true)}
                  >
                    {" "}
                    <i className="fa fa-plus-circle reactBoostrapIcon"></i>
                    Add Inventory Item
                  </Button>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("inventory_management", "create") ===
                false ? (
                  ""
                ) : (
                  <Button
                    className="myBtn shadow reactBoostrapModalBtn"
                    onClick={() => setModalShow(true)}
                  >
                    {" "}
                    <i className="fa fa-plus-circle reactBoostrapIcon"></i>
                    Add Inventory Item
                  </Button>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("inventory_management", "create") ===
                false ? (
                  ""
                ) : (
                  <Button
                    className="myBtn shadow reactBoostrapModalBtn"
                    onClick={() => setModalShow(true)}
                  >
                    {" "}
                    <i className="fa fa-plus-circle reactBoostrapIcon"></i>
                    Add Inventory Item
                  </Button>
                )
              ) : (
                <Button
                  className="myBtn shadow reactBoostrapModalBtn"
                  onClick={() => setModalShow(true)}
                >
                  {" "}
                  <i className="fa fa-plus-circle reactBoostrapIcon"></i>
                  Add Inventory Item
                </Button>
              )}

              <AddInventory
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
                getInventoryList={getInventoryList}
                category={category}
                categoryNames={categoryNames}
                setCategoryNames={setCategoryNames}
                addtopurchaselist={addToPurchaseList}
              />
            </div>
          </div>

          <div className="tab-content">
            <div className="tab-pane fade show active" id="view">
              <div className="contentCard" style={{ padding: "0px 20px" }}>
                <div className="filters">
                  <div className="row">
                    <div className="col-12 col-md-4 col-lg-3 col-xl-2"></div>
                  </div>
                </div>

                {loader ? (
                  <div className="text-center">
                    <img src={Loader} alt="" />
                  </div>
                ) : noRecord ? (
                  <div className="text-center">
                    <img src={NoRecordFound} alt="" />
                  </div>
                ) : (
                  <div className="inventoryTable table-responsive dataTable scrollable">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          {localStorage.getItem("role") === "viewer" ? (
                            userAccessibility(
                              "inventory_management",
                              "delete"
                            ) === false ? (
                              <th style={{ display: "none" }}></th>
                            ) : (
                              <th style={{ minWidth: "100px" }}></th>
                            )
                          ) : (
                            <th style={{ minWidth: "100px" }}></th>
                          )}

                          <th className="sort" onClick={categorySortt}>
                            Category
                          </th>
                          <th className="sort" onClick={modelNumberSort}>
                            Model Number
                          </th>
                          <th className="sort" onClick={expenseSort}>
                            Expense Code
                          </th>
                          <th
                            className="sort"
                            style={{ minWidth: "330px" }}
                            onClick={descriptionSort}
                          >
                            Description
                          </th>
                          <th className="sort" onClick={priceSort}>
                            Price
                          </th>

                          {localStorage.getItem("role") === "viewer" ? (
                            userAccessibility(
                              "inventory_management",
                              "update"
                            ) === false ? (
                              <th style={{ display: "none" }}></th>
                            ) : (
                              <th className="text-right">Action</th>
                            )
                          ) : localStorage.getItem("role") ===
                            "building_manager" ? (
                            userAccessibility(
                              "inventory_management",
                              "update"
                            ) === false ? (
                              <th style={{ display: "none" }}></th>
                            ) : (
                              <th className="text-right">Action</th>
                            )
                          ) : localStorage.getItem("role") ===
                            "property_manager" ? (
                            userAccessibility(
                              "inventory_management",
                              "update"
                            ) === false ? (
                              <th style={{ display: "none" }}></th>
                            ) : (
                              <th className="text-right">Action</th>
                            )
                          ) : (
                            <th className="text-right">Action</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {inventoryList &&
                          inventoryList.map((v, i) => {
                            return (
                              <tr
                                key={i}
                                // style={{ fontSize: "12px", padding: "4px 5px" }}
                              >
                                {localStorage.getItem("role") === "viewer" ? (
                                  userAccessibility(
                                    "inventory_management",
                                    "delete"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="customCheck mr-3"
                                        id={"inventoryCheck" + i}
                                        value={v._id}
                                        checked={
                                          deleteItem &&
                                          deleteItem.includes(v._id)
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleChangeCheckBox(e)
                                        }
                                      />
                                      <label className="toggleLike">
                                        <input
                                          type="checkbox"
                                          id={"favouriteCheck" + "-" + i}
                                          value={v._id}
                                          checked={
                                            completeArray[i] ? "checked" : false
                                          }
                                          onChange={(e) =>
                                            handleChangeFavourites(
                                              e,
                                              v,
                                              v._id,
                                              i
                                            )
                                          }
                                        />
                                        <Like />
                                      </label>
                                    </td>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "building_manager" ? (
                                  userAccessibility(
                                    "inventory_management",
                                    "delete"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="customCheck mr-3"
                                        id={"inventoryCheck" + i}
                                        value={v._id}
                                        checked={
                                          deleteItem &&
                                          deleteItem.includes(v._id)
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleChangeCheckBox(e)
                                        }
                                      />
                                      <label className="toggleLike">
                                        <input
                                          type="checkbox"
                                          id={"favouriteCheck" + "-" + i}
                                          value={v._id}
                                          checked={
                                            completeArray[i] ? "checked" : false
                                          }
                                          onChange={(e) =>
                                            handleChangeFavourites(
                                              e,
                                              v,
                                              v._id,
                                              i
                                            )
                                          }
                                        />
                                        <Like />
                                      </label>
                                    </td>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "property_manager" ? (
                                  userAccessibility(
                                    "inventory_management",
                                    "delete"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="customCheck mr-3"
                                        id={"inventoryCheck" + i}
                                        value={v._id}
                                        checked={
                                          deleteItem &&
                                          deleteItem.includes(v._id)
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleChangeCheckBox(e)
                                        }
                                      />
                                      <label className="toggleLike">
                                        <input
                                          type="checkbox"
                                          id={"favouriteCheck" + "-" + i}
                                          value={v._id}
                                          checked={
                                            completeArray[i] ? "checked" : false
                                          }
                                          onChange={(e) =>
                                            handleChangeFavourites(
                                              e,
                                              v,
                                              v._id,
                                              i
                                            )
                                          }
                                        />
                                        <Like />
                                      </label>
                                    </td>
                                  )
                                ) : (
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="customCheck mr-3"
                                      id={"inventoryCheck" + i}
                                      value={v._id}
                                      checked={
                                        deleteItem && deleteItem.includes(v._id)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => handleChangeCheckBox(e)}
                                    />
                                    <label className="toggleLike">
                                      <input
                                        type="checkbox"
                                        id={"favouriteCheck" + "-" + i}
                                        value={v._id}
                                        checked={
                                          completeArray[i] ? "checked" : false
                                        }
                                        onChange={(e) =>
                                          handleChangeFavourites(e, v, v._id, i)
                                        }
                                      />
                                      <Like />
                                    </label>
                                  </td>
                                )}
                                <td>
                                  <b>{v.category}</b>
                                </td>
                                <td>{v.model_number}</td>
                                <td>{v.expense_code}</td>
                                <td>{v.description}</td>
                                <td>
                                  $
                                  {((v.price * 100) / 100).toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 2 }
                                  )}
                                </td>

                                {localStorage.getItem("role") === "viewer" ? (
                                  userAccessibility(
                                    "inventory_management",
                                    "update"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td className="text-right">
                                      <div className="dropleft">
                                        <button
                                          type="button"
                                          className="actionEclips dropdown-toggle"
                                          data-toggle="dropdown"
                                        >
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </button>
                                        <div className="dropdown-menu dropDesign">
                                          <Link
                                            to={`${PATH.EDIT_INVENTORY}/${v._id}`}
                                          >
                                            Edit
                                            <span>
                                              <Edit />
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "building_manager" ? (
                                  userAccessibility(
                                    "inventory_management",
                                    "update"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td className="text-right">
                                      <div className="dropleft">
                                        <button
                                          type="button"
                                          className="actionEclips dropdown-toggle"
                                          data-toggle="dropdown"
                                        >
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </button>
                                        <div className="dropdown-menu dropDesign">
                                          <Link
                                            to={`${PATH.EDIT_INVENTORY}/${v._id}`}
                                          >
                                            Edit
                                            <span>
                                              <Edit />
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "property_manager" ? (
                                  userAccessibility(
                                    "inventory_management",
                                    "update"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td className="text-right">
                                      <div className="dropleft">
                                        <button
                                          type="button"
                                          className="actionEclips dropdown-toggle"
                                          data-toggle="dropdown"
                                        >
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </button>
                                        <div className="dropdown-menu dropDesign">
                                          <Link
                                            to={`${PATH.EDIT_INVENTORY}/${v._id}`}
                                          >
                                            Edit
                                            <span>
                                              <Edit />
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  )
                                ) : (
                                  <td className="text-right">
                                    <div className="dropleft">
                                      <button
                                        type="button"
                                        className="actionEclips dropdown-toggle"
                                        data-toggle="dropdown"
                                      >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                      </button>
                                      <div className="dropdown-menu dropDesign">
                                        <Link
                                          to={`${PATH.EDIT_INVENTORY}/${v._id}`}
                                        >
                                          Edit
                                          <span>
                                            <Edit />
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}

                {inventoryList.length > 0 ? (
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-5">
                      Showing {currentPage * perPage - perPage + 1} to{" "}
                      {totalRecord > currentPage * perPage
                        ? currentPage * perPage
                        : totalRecord}{" "}
                      of {totalRecord} entries
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers text-right"
                        id="datatable_paginate"
                      >
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          onPageChange={(e) => handlePageClick(e)}
                          containerClassName={"paginationInventory"}
                          subContainerClassName={"pages pagination"}
                          pageClassName={"paginate_button page-item"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {localStorage.getItem("role") === "viewer" ? (
                  userAccessibility("inventory_management", "delete") ===
                  false ? (
                    ""
                  ) : (
                    <div className="inventoryCardFooter">
                      <button
                        className="myBtn danger squre"
                        onClick={() => deleteInventory(deleteItem)}
                      >
                        <Delete />
                      </button>
                    </div>
                  )
                ) : localStorage.getItem("role") === "building_manager" ? (
                  userAccessibility("inventory_management", "delete") ===
                  false ? (
                    ""
                  ) : (
                    <div className="inventoryCardFooter">
                      <button
                        className="myBtn danger squre"
                        onClick={() => deleteInventory(deleteItem)}
                      >
                        <Delete />
                      </button>
                    </div>
                  )
                ) : localStorage.getItem("role") === "property_manager" ? (
                  userAccessibility("inventory_management", "delete") ===
                  false ? (
                    ""
                  ) : (
                    <div className="inventoryCardFooter">
                      <button
                        className="myBtn danger squre"
                        onClick={() => deleteInventory(deleteItem)}
                      >
                        <Delete />
                      </button>
                    </div>
                  )
                ) : (
                  <div className="inventoryCardFooter">
                    <button
                      className="myBtn danger squre"
                      onClick={() => deleteInventory(deleteItem)}
                      disabled={deleteItem.length === 0 ? true : false}
                    >
                      <Delete />
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="tab-pane fade " id="fav">
              <div className="contentCard">
                {loaderFavourite ? (
                  <div className="text-center">
                    <img src={Loader} alt="" />
                  </div>
                ) : noRecordFavourite ? (
                  <div className="text-center">
                    <img src={NoRecordFound} alt="" />
                  </div>
                ) : (
                  <div className="inventoryTable table-responsive dataTable scrollable">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th></th>
                          <th className="sort" onClick={categorySorttFav}>
                            Category
                          </th>
                          <th className="sort" onClick={modelNumberSortFav}>
                            Model Num
                          </th>
                          <th className="sort" onClick={expenseSortFav}>
                            Expense Code
                          </th>
                          <th
                            className="sort"
                            style={{ minWidth: "330px" }}
                            onClick={descriptionSortFav}
                          >
                            Description
                          </th>
                          <th className="sort" onClick={priceSortFav}>
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {favouriteList &&
                          favouriteList.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <label className="toggleLike">
                                    <input
                                      type="checkbox"
                                      id={"checkedFavourite" + "-" + i}
                                      value={v._id}
                                      checked
                                      onChange={(e) =>
                                        handleChangeRemoveFavourites(e, v)
                                      }
                                    />
                                    <Like />
                                  </label>
                                </td>
                                <td>
                                  <b>{v.category}</b>
                                </td>
                                <td>{v.model_number}</td>
                                <td>{v.expense_code}</td>
                                <td>{v.description}</td>
                                <td>
                                  $
                                  {((v.price * 100) / 100).toLocaleString(
                                    undefined,
                                    { minimumFractionDigits: 2 }
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}

                {favouriteList && favouriteList.length > 0 ? (
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-5">
                      Showing{" "}
                      {currentPageFavourite * perPageFavourite -
                        perPageFavourite +
                        1}{" "}
                      to{" "}
                      {totalRecordFavourite >
                      currentPageFavourite * perPageFavourite
                        ? currentPageFavourite * perPageFavourite
                        : totalRecordFavourite}{" "}
                      of {totalRecordFavourite} entries
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers text-right"
                        id="datatable_paginate"
                      >
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCountFavourite}
                          // marginPagesDisplayed={2}
                          // pageRangeDisplayed={5}
                          onPageChange={(e) => handlePageClickFavourite(e)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          pageClassName={"paginate_button page-item"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
