import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import {
  adminApiCall,
  userAccessibility,
  capatalizeLetters,
  getDate,
} from "../../../helpers/ajax_request";

export default function Index() {
  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const params = useParams();
  const ID = params.id;

  const navigate = useNavigate();

  const [emailTemplate, setEmailTemplate] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    getEmailTemplate();
  }, []);

  useEffect(() => {
    if (!ID) {
      notifyError("Invalid_access_error");
    } else {
      getEmailTemplate();
    }
  }, []);

  const getEmailTemplate = async () => {
    let data = {
      id: ID,
    };

    // console.log("data: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/application_setting/email_template/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log(res);
        setEmailTemplate({
          title: res?.data?.template_name,
          description: res?.data?.description,
        });
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let ref = {
      ...emailTemplate,
      [name]: value,
    };
    // console.table(ref);
    setEmailTemplate(ref);
  };

  const submit = async () => {
    let data = {
      id: ID,
      template_name: emailTemplate.title,
      description: emailTemplate.description,
    };
    // console.log("data sent: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/application_setting/email_template/update",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again.");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Email Template Updated Successfully!");
        navigate(PATH.APPLICATION_SETTINGS);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Edit Email Template</span>
            </div>
            <div className="formDesign">
              <div className="row ">
                <div className="col-lg-10">
                  <div className="row align-items-center">
                    <div className="col-lg-3 my-1">
                      <label>Title</label>
                    </div>
                    <div className="col-lg-9 my-1">
                      <input
                        className="form-control"
                        type="text"
                        name="title"
                        value={emailTemplate.title}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-lg-3 my-1">
                      <label>Description</label>
                    </div>
                    <div className="col-lg-9 my-1">
                      <input
                        className="form-control"
                        type="text"
                        name="description"
                        value={emailTemplate.description}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cardFooter">
              <button className="myBtn mr-1" type="submit" onClick={submit}>
                Save
              </button>
              <Link className="myBtn bdr" to={PATH.APPLICATION_SETTINGS}>
                Cancel
              </Link>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
