import React from "react";
import { Link } from "react-router-dom";
import MobileLogo from "../../assets/images/mobileNavLogo.png";
import ProfileImg from "../../assets/images/profileImg.png";
import { PATH } from "../../constants/paths";
import {
  Buildings,
  Inventory,
  Reports,
  Settings,
  Trade,
  Users,
} from "../../Icons/Icons";

export default function Index() {
  return (
    <>
      <div className="bottomNav toggleThis" data-attr="mobileNav">
        <div className="bgClose closeNav"></div>
        <div className="navContainer">
          <div className="navHeader">
            <div className="logo d-none d-sm-block">
              <img src={MobileLogo} alt="" />
            </div>
            <div className="d-block d-sm-none">
              <div className="profile">
                <div className="profileImg">
                  <img src={ProfileImg} alt="" />
                </div>
                <span>George Lucas</span>
              </div>
            </div>
            <div className="closeNav closeThis">
              <svg viewBox="0 0 23.095 30.794">
                <use xlinkHref="#close"></use>
              </svg>
            </div>
          </div>
          <ul>
            <li>
              <Link className="navItem toggleNav" to={"/buildingList"}>
                <i>
                  <Buildings />
                </i>
                <span>Buildings</span>
              </Link>
            </li>
            <li>
              <a className="navItem toggleNav" data-toggle="inventory">
                <i>
                  <Inventory />
                </i>
                <span>Inventory</span>
              </a>
            </li>
            <li>
              <a className="navItem toggleNav" data-toggle="trade">
                <i>
                  <Trade />
                </i>
                <span>Trades</span>
              </a>
            </li>
            <li>
              <a className="navItem toggleNav" data-toggle="reports">
                <i>
                  <Reports />
                </i>
                <span>Reports</span>
              </a>
            </li>
            <li>
              <Link className="navItem" to="/userList">
                <i>
                  <Users />
                </i>
                <span>Users</span>
              </Link>
            </li>
            <li>
              <a className="navItem toggleNav" data-toggle="settings">
                <i>
                  <Settings />
                </i>
                <span>Settings</span>
              </a>
            </li>
          </ul>
          <div className="innerMenuContainer toggleThis" data-attr="inventory">
            <div className="navHeader">
              <h2>Inventory</h2>
              <div className="closeNav closeThis" data-close="inventory">
                <svg viewBox="0 0 23.095 30.794">
                  <use xlinkHref="#close"></use>
                </svg>
              </div>
            </div>
            <ul>
              <li>
                <Link to={PATH.INVENTORY_LIST}>View Inventory</Link>
              </li>
              <li>
                <Link to={PATH.ADD_INVENTORY}>Add Inventory</Link>
              </li>
            </ul>
          </div>
          <div className="innerMenuContainer toggleThis" data-attr="trade">
            <div className="navHeader">
              <h2>Trades</h2>
              <div className="closeNav closeThis" data-close="trade">
                <svg viewBox="0 0 23.095 30.794">
                  <use xlinkHref="#close"></use>
                </svg>
              </div>
            </div>
            <ul>
              <li>
                <Link to="/viewTrades">View Trades</Link>
              </li>
              <li>
                <Link to="/addTrade">Add Trades</Link>
              </li>
            </ul>
          </div>
          <div className="innerMenuContainer toggleThis" data-attr="reports">
            <div className="navHeader">
              <h2>Reports</h2>
              <div className="closeNav closeThis" data-close="reports">
                <svg viewBox="0 0 23.095 30.794">
                  <use xlinkHref="#close"></use>
                </svg>
              </div>
            </div>
            <ul>
              <li>
                <Link to="/poReport">PO Report</Link>
              </li>
              <li>
                <Link to="/vacancyReport">Vacancy Report</Link>
              </li>
            </ul>
          </div>
          <div className="innerMenuContainer toggleThis" data-attr="settings">
            <div className="navHeader">
              <h2>Reports</h2>
              <div className="closeNav closeThis" data-close="settings">
                <svg viewBox="0 0 23.095 30.794">
                  <use xlinkHref="#close"></use>
                </svg>
              </div>
            </div>
            <ul>
              <li>
                <Link to="/accountSettings">Account Settings</Link>
              </li>
              <li>
                <Link to="/applicationSettings">Application Settings</Link>
              </li>
              <li>
                <Link to="/">Logout</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
