import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Delete, Edit, File } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { adminApiCall, getDateOnly } from "../../../helpers/ajax_request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as yup from "yup";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";

export default function Index() {
  const params = useParams();
  const unitID = params.id;
  const navigate = useNavigate();
  const [unitData, setUnitData] = useState([]);
  const [typeSelected, setTypeSelected] = useState("");
  const [bathroomSelected, setBathroomSelected] = useState("");

  const [type, setType] = useState([
    { label: "Bachelor", value: "BACHELOR" },
    { label: "1 Bedroom", value: "1 BEDROOM" },
    { label: "2 Bedroom", value: "2 BEDROOM" },
    { label: "3 Bedroom", value: "3 BEDROOM" },
    { label: "4 Bedroom", value: "4 BEDROOM" },
  ]);

  const [bathroom, setBathroom] = useState([
    { label: "1 Bathroom", value: "1 BATHROOM" },
    { label: "1.5 Bathroom", value: "1.5 BATHROOM" },
    { label: "2 Bathroom", value: "2 BATHROOM" },
  ]);

  const handleType = (e) => {
    // console.log(e.target.value);
    setTypeSelected(e.target.value);
  };

  const handleBathroom = (e) => {
    // console.log(e.target.value);
    setBathroomSelected(e.target.value);
  };

  const initialValues = {
    unitName: "",
    height: "",
    note: "",
  };

  useEffect(() => {
    getUnitData();
  }, []);

  const getUnitData = async () => {
    let data = {
      unit_id: unitID,
    };
    let res = await adminApiCall(
      "/api/admin/unit/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setUnitData(res.data);
        formik.setValues({
          unitName: res.data?.unit_no,
          height: res.data?.square_footage,
          note: res.data?.note_or_description,
        });
        setTypeSelected(res.data?.type);
        setBathroomSelected(res.data?.bathroom);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  let validationSchema = yup.object({
    unitName: yup.string().trim().required("Unit name is mandatory."),
    // height: yup.string().trim().required("Height is mandatory."),
  });

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const onSubmit = async (values) => {
    // console.log("all okay", values);
    let data = {
      unit_id: unitID,
      unit_no: formik.values.unitName,
      type: typeSelected,
      bathroom: bathroomSelected,
      ...(formik.values.height && {
        square_footage: formik.values.height,
      }),
      note: formik.values.note,
      // ...(formik.values.note && {
      //   note: formik.values.note,
      // }),
    };

    // console.log("data: ", data);
    let res = await adminApiCall(
      "/api/admin/unit/update",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Unit Updated Successfully!");
        // getUnitList();
        navigate(PATH.ADD_UNIT);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  return (
    <MainLayout>
      <section className="pageWrapper">
        <form className="contentCard" onSubmit={formik.handleSubmit}>
          <div className="titleWrapper">
            <span>Update Apt</span>
          </div>
          <div className="formDesign">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3">
                <div className="form-group">
                  <label>Building ID</label>
                  <input
                    className="form-control"
                    type="text"
                    value={localStorage.getItem("building_namee")}
                    disabled
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="form-group">
                  <label>Number Of Apts.</label>
                  <input
                    className="form-control"
                    type="number"
                    value={localStorage.getItem("no_of_suites")}
                    disabled
                  />
                  <span className="text">
                    Available No. Of Apts. (
                    {localStorage.getItem("available_suites")})
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="form-group">
                  <label>Apt. Name</label>
                  <input
                    className={
                      "form-control " +
                      (formik.errors.unitName && formik.touched.unitName
                        ? " is-invalid"
                        : "")
                    }
                    type="text"
                    placeholder="A-12"
                    name="unitName"
                    value={formik.values.unitName}
                    onChange={formik.handleChange}
                  />
                  <div className="invalid-feedback">
                    {formik.errors.unitName}
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <hr className="splitCard" />

                <h3 className="sectionTitle">Apt. Types</h3>
                <span className="text">Please Select Type of Apt.</span>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="form-group">
                  <label>Type</label>
                  <select
                    className="form-control"
                    value={typeSelected}
                    onChange={(e) => handleType(e)}
                  >
                    {type &&
                      type.map((v, i) => {
                        return (
                          <option key={i} value={v.value}>
                            {v.label}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="form-group">
                  <label>Bathroom</label>
                  <select
                    className="form-control"
                    value={bathroomSelected}
                    onChange={(e) => handleBathroom(e)}
                  >
                    {bathroom &&
                      bathroom.map((v, i) => {
                        return (
                          <option key={i} value={v.value}>
                            {v.label}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <div className="form-group">
                  <label>Square Footage</label>
                  <input
                    className={
                      "form-control " +
                      (formik.errors.height && formik.touched.height
                        ? " is-invalid"
                        : "")
                    }
                    type="text"
                    name="height"
                    value={formik.values.height}
                    onChange={formik.handleChange}
                  />
                  <div className="invalid-feedback">{formik.errors.height}</div>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                <div className="form-group">
                  <label>Unit Specific Notes</label>
                  <textarea
                    className={
                      "form-control "
                      // +
                      // (formik.errors.description && formik.touched.description
                      //   ? " is-invalid"
                      //   : "")
                    }
                    name="note"
                    id=""
                    cols="30"
                    rows="3"
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    value={formik.values.note}
                  ></textarea>
                </div>
              </div>
            </div>
            <button className="myBtn mr-1" type="submit">
              Update
            </button>
            <Link className="myBtn bdr" to={PATH.VIEW_TRAFFIC_SHEET}>
              Cancel
            </Link>
          </div>
        </form>
      </section>
    </MainLayout>
  );
}
