import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminApiCall, capatalizeLetters } from "../../../helpers/ajax_request";
import moment from "moment";

export default function Index() {
  const params = useParams();
  const trafficID = params.id;
  const navigate = useNavigate();
  const buildingID = localStorage.getItem("building_id");

  useEffect(() => {
    getTrafficUnit();
    // unitList();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const initialValues = {
    // standardRent: "",
    rentWithDiscount: "",
    comments: "",
  };

  const [unitNameList, setUnitNameList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [vacateDate, setVacateDate] = useState("");
  const [rentedAsOf, setRentedAsOf] = useState("");
  const [pickedUpDate, setPickedUpDate] = useState("");
  const [standardRent, setStandardRent] = useState(0);

  const [unitName, setUnitName] = useState("");

  const [checkboxes, setCheckboxes] = useState({
    damageReport: false,
    outgoingInspection: false,
    incomingInspection: false,
    leaseSigned: false,
  });

  const [action, setAction] = useState([
    { label: "Monthly", value: "MONTHLY" },
    { label: "Short", value: "SHORT" },
    { label: "Eviction", value: "EVICTION" },
  ]);

  const [status, setStatus] = useState([
    { label: "Vacant", value: "VACANT" },
    { label: "Occupied", value: "OCCUPIED" },
  ]);

  const selectActionHandler = (e) => {
    // console.log(e.target.value);
    setSelectedAction(e.target.value);
  };

  const selectUnitName = (e) => {
    // console.log(e.target.value);
    setSelectedUnit(e.target.value);
  };

  const getTrafficUnit = async () => {
    let data = {
      traffic_sheet_id: trafficID,
    };
    let res = await adminApiCall(
      "/api/admin/traffic_sheet/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);

        formik.setValues({
          rentWithDiscount: res.data?.rent_with_discount,
          comments:
            res.data?.comments.length === 0
              ? ""
              : res.data?.comments[0].comment,
        });
        let action = capatalizeLetters(res.data?.action.toLowerCase());
        setSelectedAction(res.data?.action);
        // console.log(res.data?.action);
        // setVacateDate(moment(res.data?.vacate_date));
        setRentedAsOf(
          res.data?.rented_as_of === null || res.data?.unit_status === "VACANT"
            ? ""
            : moment(res.data?.rented_as_of)
        );
        // setPickedUpDate(moment(res.data?.pm_picked_up_date));
        // setSelectedStatus(res.data?.unit_status);
        setStandardRent(res.data.standard_rent);
        setUnitName(res.data?.unit_info?.unit_no);
        let obj = { ...checkboxes };
        obj.damageReport = res.data?.damage_report;
        obj.incomingInspection = res.data?.incoming_inspection;
        obj.outgoingInspection = res.data?.outgoing_inspection;
        obj.leaseSigned = res.data?.lease_signed;
        setCheckboxes(obj);
      } else if (res.status === 400) {
        notifyError(res.message);
      } else if (res.status === 300) {
        notifyError(res.message);
      }
    }
  };

  const handleStatus = (e) => {
    // console.log(e.target.value);
    setSelectedStatus(e.target.value);
  };

  const handleCheckBoxes = (e) => {
    let checked = e.target.checked;
    let name = e.target.name;
    // console.log(e);
    let newCheckbox = {
      ...checkboxes,
      [name]: checked,
    };
    // console.table(newCheckbox);
    setCheckboxes(newCheckbox);
  };

  let validationSchema = yup.object({
    // standardRent: yup.string().required("Please enter Standard Rent."),
    rentWithDiscount: yup.string().required("Please enter Rent with Discount."),
  });

  const onSubmit = async (values, { resetForm }) => {
    // console.log("all okay", values);
    let newAction = capatalizeLetters(selectedAction.toLowerCase());
    newAction = newAction.split(" ")[0];
    // let newStatus = capatalizeLetters(selectedStatus.toLowerCase());
    // newStatus = newStatus.split(" ")[0];

    if (vacateDate === "") {
      notifyError("Please Enter Vacate Date");
    } else {
      let data = {
        traffic_sheet_id: trafficID,
        action: newAction,
        standard_rent: Number(standardRent.toFixed(2)),
        rent_with_discount: Number(formik.values.rentWithDiscount),
        vacate_date: vacateDate === "" ? "" : vacateDate.format("MM/DD/YYYY"),
        // rented_as_of: rentedAsOf === "" ? "" : rentedAsOf.format("MM/DD/YYYY"),
        // ...(rentedAsOf && {
        //   rented_as_of: "",
        // }),
        //   pm_picked_up_date: pickedUpDate.format("MM/DD/YYYY"),
        // unit_status: newStatus,
        damage_report: checkboxes.damageReport,
        outgoing_inspection: checkboxes.outgoingInspection,
        //   incoming_inspection: checkboxes.incomingInspection,
        //   lease_signed: checkboxes.leaseSigned,
        comments: {
          comment: formik.values.comments,
        },
      };
      // console.log("data sent: ", data);
      // return;
      let res = await adminApiCall(
        "/api/admin/traffic_sheet/schedule_vacancy",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("Schedule Vacancy Created Successfully!");
          navigate(PATH.VIEW_TRAFFIC_SHEET);
          resetForm();
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  function onChangeVacateDate(date, dateString) {
    // console.log(date, dateString);
    setVacateDate(date === null ? "" : date);
  }

  function onChangeRentedAsOf(date, dateString) {
    // console.log(date, dateString);
    setRentedAsOf(date === null ? "" : date);
  }

  function onChangePickedUpDate(date, dateString) {
    // console.log(date, dateString);
    setPickedUpDate(date === null ? "" : date);
  }

  const customFormik = (e) => {
    // console.log(e.currentTarget.value);
    let value = e.currentTarget.value;
    let name = e.currentTarget.name;

    let obj = {
      ...initialValues,
      [name]: value,
    };
    // console.log("obj: ", obj);
    let withoutDiscount = e.target.value / 0.98;
    setStandardRent(withoutDiscount);
    formik.setValues(obj);
  };

  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <section className="pageWrapper">
          <div className="contentCard ">
            <div className="titleWrapper">
              <span>Schedule Vacancy</span>
            </div>
            <div className="formDesign">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Suite (Unit)</label>
                    <input
                      className={"form-control"}
                      type="text"
                      name="unitName"
                      value={unitName}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Action</label>
                    <select
                      className="form-control"
                      value={selectedAction}
                      onChange={(e) => selectActionHandler(e)}
                      // disabled
                    >
                      {action &&
                        action.map((v, i) => {
                          return (
                            <option key={i} value={v.value}>
                              {v.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Rent With 2% Discount ($)</label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.rentWithDiscount &&
                        formik.touched.rentWithDiscount
                          ? " is-invalid"
                          : "")
                      }
                      type="number"
                      name="rentWithDiscount"
                      value={formik.values.rentWithDiscount}
                      onChange={customFormik}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.rentWithDiscount}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Legal Rent ($)</label>
                    <input
                      // className={
                      //   "form-control " +
                      //   (formik.errors.standardRent &&
                      //   formik.touched.standardRent
                      //     ? " is-invalid"
                      //     : "")
                      // }
                      className="form-control"
                      type="number"
                      name="standardRent"
                      value={standardRent.toFixed(2)}
                      disabled
                    />
                    {/* <div className="invalid-feedback">
                      {formik.errors.standardRent}
                    </div> */}
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Vacate Date</label>
                    <Space direction="vertical">
                      <DatePicker
                        className="form-control"
                        name="vacateDate"
                        value={vacateDate}
                        onChange={onChangeVacateDate}
                      />
                    </Space>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Rented As Of</label>
                    <Space direction="vertical">
                      <DatePicker
                        className="form-control"
                        value={rentedAsOf}
                        onChange={onChangeRentedAsOf}
                        disabled
                      />
                    </Space>
                  </div>
                </div>
                {/* <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>PM Picked Up Date</label>
                    <Space direction="vertical">
                      <DatePicker
                        className="form-control"
                        value={pickedUpDate}
                        onChange={onChangePickedUpDate}
                      />
                    </Space>
                  </div>
                </div> */}
                {/* <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={selectedStatus}
                      onChange={(e) => handleStatus(e)}
                    >
                      {status &&
                        status.map((v, i) => {
                          return (
                            <option key={i} value={v.value}>
                              {v.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Damage Report</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="damageReport"
                          name="damageReport"
                          value={checkboxes.damageReport}
                          onChange={(e) => handleCheckBoxes(e)}
                          checked={checkboxes.damageReport ? "checked" : false}
                        />
                        <label htmlFor="damageReport">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Outgoing Inspection</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="outgoing"
                          name="outgoingInspection"
                          value={checkboxes.outgoingInspection}
                          onChange={(e) => handleCheckBoxes(e)}
                          checked={
                            checkboxes.outgoingInspection ? "checked" : false
                          }
                        />
                        <label htmlFor="outgoing">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Incoming Inspection</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="incoming"
                          name="incomingInspection"
                          value={checkboxes.incomingInspection}
                          onChange={(e) => handleCheckBoxes(e)}
                          checked={
                            checkboxes.incomingInspection ? "checked" : false
                          }
                        />
                        <label htmlFor="incoming">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Lease Signed</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="lease"
                          name="leaseSigned"
                          value={checkboxes.leaseSigned}
                          onChange={(e) => handleCheckBoxes(e)}
                          checked={checkboxes.leaseSigned ? "checked" : false}
                        />
                        <label htmlFor="lease">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="col-12 col-md-6 col-lg-8 col-xl-9">
                  <div className="form-group">
                    <label>Comments</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="comments"
                      value={formik.values.comments}
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardFooter">
              <button
                className="myBtn mr-1"
                type="submit"
                // to={PATH.VIEW_TRAFFIC_SHEET}
              >
                Add
              </button>
              <Link className="myBtn bdr" to={PATH.VIEW_TRAFFIC_SHEET}>
                Cancel
              </Link>
            </div>
          </div>
        </section>
      </form>
    </MainLayout>
  );
}
