import React from 'react';
import { useReactToPrint } from 'react-to-print';
import Application from "./index";


const Printable = () => {
  const printRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <div>
      <Application ref={printRef} handlePrint={handlePrint}/>
      {/* <button onClick={handlePrint}>Print</button> */}
    </div>
  );
};

export default Printable;
