import { loginApi } from "../apis/index";
import * as actions from "./login-actions";

export const login = (payload) => async (dispatch) => {
  dispatch(actions.loginRequested());
  return loginApi(payload)
    .then((res) => {
      // console.log("ressss: ", res);
      // console.log("ressss permission: ", res.data.user_detail.permissions);
      // console.log("access_token: ", res.data.access_token);
      let fullName =
        res.data.user_detail.adminData.first_name +
        " " +
        res.data.user_detail.adminData.last_name;
      localStorage.setItem("userName", fullName);
      localStorage.setItem("userID", res.data.user_detail?.adminData?._id);
      localStorage.setItem(
        "profileImg",
        res.data.user_detail.adminData.profile_image
      );
      localStorage.setItem("adminToken", res.data.access_token);
      localStorage.setItem("role", res.data.user_detail.adminData.role);
      localStorage.setItem(
        "permissions",
        JSON.stringify(res.data.user_detail.permissions)
      );
      return dispatch(actions.loginSuccess(res));
    })
    .catch((err) => Promise.reject(dispatch(actions.loginFailed(err))));
};
