import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import {
  adminApiCall,
  userAccessibility,
  capatalizeLetters,
  getDate,
} from "../../../helpers/ajax_request";

export default function Index() {
  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const navigate = useNavigate();

  const [apiTemplate, setApiTemplate] = useState({
    sender: "",
    key: "",
  });

  useEffect(() => {
    getEmailTemplate();
  }, []);

  const getEmailTemplate = async () => {
    let data = {
      api_settings: {
        api_type: "postmark",
        sender_signature: "jbirman@candoapartments.com",
        api_key: "5e377a84-0ca2-4ada-bc04-469fccca53e5",
      },
    };

    // console.log("data: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/application_setting/api_setting/get_api",
      "GET",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log(res);
        setApiTemplate({
          sender: res.data?.postmark[0]?.sender_signature,
          key: res.data?.postmark[0]?.api_key,
        });
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let ref = {
      ...apiTemplate,
      [name]: value,
    };
    // console.table(ref);
    setApiTemplate(ref);
  };

  const submit = async () => {
    let data = {
      api_settings: {
        api_type: "postmark",
        sender_signature: apiTemplate.sender,
        api_key: apiTemplate.key,
      },
    };
    // console.log("data sent: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/application_setting/api_setting/create",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again.");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("API Settings Updated Successfully!");
        navigate(PATH.APPLICATION_SETTINGS);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Postmark Api Settings</span>
            </div>
            <div className="formDesign">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row align-items-center">
                    <div className="col-lg-3 my-1">
                      <label>Sender Signature</label>
                    </div>
                    <div className="col-lg-9 my-1">
                      <input
                        className="form-control"
                        type="text"
                        name="sender"
                        value={apiTemplate.sender}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-lg-3 my-1">
                      <label>Api Key</label>
                    </div>
                    <div className="col-lg-9 my-1">
                      <input
                        className="form-control"
                        type="text"
                        name="key"
                        value={apiTemplate.key}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cardFooter">
              <button className="myBtn mr-1" type="submit" onClick={submit}>
                Save
              </button>
              <Link className="myBtn bdr" to={PATH.HOME}>
                Cancel
              </Link>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
