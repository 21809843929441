import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

export default function SpecificSearch(props) {
  const {
    show,
    onHide,
    modalChange,
    searchScope,
    options,
    selectBuildingHandler,
    POReport,
  } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modalHeader justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">
            Select Buildings to be Searched
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="cardDec">
            <div className="card-body">
              <ul
                className="dropDownContentCard text-left"
                style={{ listStyleType: "none" }}
              >
                {options &&
                  options.map((v, i) => {
                    return (
                      <li key={i}>
                        {" "}
                        <label>
                          <input
                            type="checkbox"
                            className="customCheck"
                            id={v._id}
                            onChange={selectBuildingHandler}
                            checked={
                              POReport.buildingID &&
                              POReport.buildingID.includes(v._id)
                                ? true
                                : false
                            }
                            style={{
                              position: "relative",
                              bottom: "1px",
                              right: "5px",
                            }}
                          />
                          {v.building_name}
                        </label>{" "}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className="myBtn bdr"
            onClick={() => modalChange(false)}
            to={"#"}
          >
            Close
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}
