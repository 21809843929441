import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import MainLayout from "../../../layouts";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Input, InputNumber, Select, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Delete2 } from "../../../Icons/Icons";
import { MaintenanceParts, PATH } from "../../../constants/paths";
import { adminApiCall } from "../../../helpers/ajax_request";

export default function Index() {
  const { TextArea } = Input;
  const [dateValue, setDateValue] = useState(moment());
  const [trade, setTrade] = useState([]);
  const [tradeNames, setTradeNames] = useState(trade[0]?._id);
  const [unit, setUnit] = useState([]);
  const [unitNames, setUnitNames] = useState("Other");

  const [maintenance, setMaintenance] = useState({
    suite: "",
    price: "",
    description: "",
    comment: "",
  });
  // console.log(maintenance.price);
  const [maintenaceServiceItems, setMaintenaceServiceItems] = useState([]);
  const [uniqueID, setUniqueID] = useState(0);

  const [description, setDescription] = useState("");
  const [suite, setSuite] = useState("");
  const [price, setPrice] = useState(1);
  const [submitLoader, setSubmitLoader] = useState(false);

  const maintenanceItemHandler = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    let tempObj = {
      ...maintenance,
      [name]: value,
    };
    setMaintenance(tempObj);
  };

  const navigate = useNavigate();

  useEffect(() => {
    getTradeList();
    getUnitList();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const dateFormat = "DD/MM/YYYY";

  function onChange(date, dateString) {
    // console.log(date, dateString);
    setDateValue(date === null ? "" : date);
  }

  const handleChangeCategory = (e) => {
    setTradeNames(e.target.value);
    // console.log(e.target.value);
  };

  const getTradeList = async () => {
    let data = {
      property_id: "",
    };
    let res = await adminApiCall(
      "/api/admin/trade/trades_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);

        setTrade(res.data);
        setTradeNames(res.data[0]?._id);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const handleChangeUnit = (e) => {
    setUnitNames(e.target.value);
    // console.log(e.target.value);
  };

  const getUnitList = async () => {
    let data = {
      building_id: localStorage.getItem("building_id"),
    };
    let res = await adminApiCall(
      "/api/admin/unit/unit_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res unittttt", res);

        setUnit(res.data);
        // setUnitNames(res.data[0]?.unit_no);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const addMaintenanceItem = () => {
    if (!maintenance.description) {
      notifyError("Please add description inorder to add item to list");
    } else {
      // notifySuccess("good");
      let maintenanceServiceObj = {
        description: maintenance.description,
        price: maintenance.price,
        suite_no: unitNames,
        id: uniqueID,
      };
      let tempArray = [...maintenaceServiceItems];
      tempArray.push(maintenanceServiceObj);
      // console.log(tempArray);
      setMaintenaceServiceItems(tempArray);
      setUniqueID(uniqueID + 1);
      setMaintenance({
        suite: "",
        price: "",
        description: "",
      });
    }
  };

  const deleteSpecificItem = (item) => {
    let ref = [...maintenaceServiceItems];
    // console.log(ref.indexOf(item));
    ref.splice(ref.indexOf(item), 1);
    // console.log("ref", ref);
    setMaintenaceServiceItems(ref);
  };

  const submit = async () => {
    setSubmitLoader(true);
    let data = {
      building_id: localStorage.getItem("building_id"),
      trade_id: tradeNames,
      // description: "",
      comments: {
        comment: maintenance.comment,
      },
      order_type: MaintenanceParts,
      order_requested_at:
        typeof dateValue === "string"
          ? dateValue
          : dateValue?.format("MM/DD/YYYY"),
      maintenance_service_items: maintenaceServiceItems,
    };
    // console.log("data: ", data);
    // return;
    if (maintenaceServiceItems.length < 1) {
      notifyError("Please add items for maintenance to proceed");

    } else {
      // console.log("else");
      let res = await adminApiCall(
        "/api/admin/purchase_order/create",
        "POST",
        JSON.stringify(data)
      ).catch(err => console.log(err))
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        setSubmitLoader(false)
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("Maintenance Service Created Successfully!");
          navigate(PATH.VIEW_EXISTING);
        } else {
          notifyError(res.message);
        }
      }
    }
    setSubmitLoader(false)

  };

  const handleMileageRateBlur = (e) => {
    let value = e.target.value < 0 ? 0 : Number(e.target.value).toFixed(2);
    let name = e.target.name;
    let tempObj = {
      ...maintenance,
      [name]: value,
    };

    setMaintenance(tempObj);
  };

  // ///////////////////////////////////////////////////////////////////////////////////

  const [size, setSize] = React.useState("small");

  const { Option } = Select;

  const children = [<Option key="Other">Other</Option>];
  unit.map((v, i) => {
    children.push(<Option key={v.unit_no}>{v.unit_no}</Option>);
  });

  function onChangeDescription(value, id) {
    setDescription(value);
    // console.log(value);
    let tempArray = [...maintenaceServiceItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val._id);
        // logs(
        //   `Description changed to ${value} of item having description ${val.description}`
        // );
        return {
          id: val.id,
          description: value.target.value,
          suite_no: val.suite_no,
          price: val.price,
        };
      } else {
        return val;
      }
    });
    setMaintenaceServiceItems(arr);
    // console.log(arr);
  }

  function handleChange(value, id) {
    setSuite(value);
    // console.log(value);
    let tempArray = [...maintenaceServiceItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // console.log(id + " " + val._id);
        // logs(
        //   `Suite Number changed to ${value} of item having description ${val.description}`
        // );
        return {
          id: val.id,
          description: val.description,
          suite_no: value,
          price: val.price,
        };
      } else {
        return val;
      }
    });
    setMaintenaceServiceItems(arr);
    // console.log(arr);
  }

  function onChangeNumber(value, id) {
    // console.log("value", value);
    // console.log("id: ", id);
    setPrice(value);
    let tempArray = [...maintenaceServiceItems];
    let arr = tempArray.map((val, i) => {
      if (id === val.id) {
        // logs(
        //   `Price changed to ${value} of item having description ${val.description}`
        // );
        // console.log(id + " " + val._id);
        // console.log(val);
        return {
          id: val.id,
          description: val.description,
          suite_no: val.suite_no,
          price: value,
        };
      } else {
        return val;
      }
    });
    setMaintenaceServiceItems(arr);
    // console.log(arr);
  }

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="contentCard small">
                <div className="titleWrapper">
                  <span>Maintenance Service</span>
                </div>
                <div className="formDesign">
                  <div className="row">
                    <div className={`col-12 col-lg-6`}>
                      <div className="form-group">
                        <label>Assign Trade</label>
                        <select
                          className="form-control"
                          value={tradeNames}
                          onChange={(e) => handleChangeCategory(e)}
                        >
                          {trade &&
                            trade.map((v, i) => {
                              return (
                                <option key={i} value={v._id}>
                                  {v.company.charAt(0).toUpperCase() +
                                    v.company.slice(1)}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="form-group">
                        <label>Requested Date</label>

                        <Space direction="vertical">
                          <DatePicker
                            className="form-control"
                            onChange={onChange}
                            defaultValue={dateValue}
                            format={dateFormat}
                          />
                        </Space>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="formDesign">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Apt. No.</label>
                        <select
                          className="form-control"
                          defaultValue={unitNames}
                          onChange={(e) => handleChangeUnit(e)}
                        >
                          <option value="">Other</option>
                          {unit &&
                            unit.map((v, i) => {
                              return (
                                <option key={i} value={v.unit_no}>
                                  {v.unit_no}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>
                          Price <small>(Optional)</small>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="price"
                          value={maintenance.price}
                          onChange={(e) => maintenanceItemHandler(e)}
                          onBlur={(e) => handleMileageRateBlur(e)}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          name="description"
                          value={maintenance.description}
                          onChange={(e) => maintenanceItemHandler(e)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cardFooter">
                  <button className="myBtn" onClick={addMaintenanceItem}>
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="contentCard small">
                <h3 className="sectionTitle">Maintenance Order</h3>
                <hr className="splitCard" />

                <div className="mainTable ax newTable table-responsive scrollable">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ minWidth: "350px" }}>Description</th>
                        <th>Apt. No.</th>
                        <th>Price</th>
                        <th className="text-right">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {maintenaceServiceItems.length < 1 ? (
                        <tr></tr>
                      ) : (
                        maintenaceServiceItems &&
                        maintenaceServiceItems.map((v, i) => {
                          return (
                            <tr key={i}>
                              <td style={{ minWidth: "350px" }}>
                                <TextArea
                                  size={size}
                                  rows={1}
                                  placeholder="Please add Description"
                                  allowClear
                                  value={v.description}
                                  onChange={(e) => onChangeDescription(e, v.id)}
                                />
                              </td>
                              <td>
                                <Select
                                  size={size}
                                  defaultValue={v.suite_no}
                                  onChange={(e) => handleChange(e, v.id)}
                                  style={{ width: 90 }}
                                >
                                  {children}
                                </Select>
                              </td>
                              <td>
                                <Space>
                                  <InputNumber
                                    size="small"
                                    min={0.1}
                                    max={1000000000}
                                    precision={2}
                                    defaultValue={(
                                      (v.price * 100) /
                                      100
                                    ).toLocaleString()}
                                    onChange={(e) => onChangeNumber(e, v.id)}
                                  />
                                </Space>
                              </td>
                              <td className="text-right">
                                <a
                                  className="circleBtn danger deleteThis"
                                  href="#"
                                  onClick={() => deleteSpecificItem(v)}
                                >
                                  <Delete2 />
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>

                  {maintenaceServiceItems.length < 1 ? (
                    <div
                      className="mb-3"
                      style={{
                        border: "1px dashed #606071",
                        textAlign: "center",
                        fontSize: "16px",
                        marginRight: "10%",
                        marginLeft: "10%",
                        padding: "25px",
                      }}
                    >
                      Maintenance Order is Empty. <br /> Please Add atleast one
                      item from the Maintenance Service.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="formDesign">
                  <div className="form-group">
                    <label>
                      Comments <small>(Optional)</small>
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      name="comment"
                      value={maintenance.comment}
                      onChange={(e) => maintenanceItemHandler(e)}
                    ></textarea>
                  </div>
                </div>

                <div className="cardFooter">
                  <button
                    className="myBtn mr-1"
                    type="submit"
                    onClick={() => submit()}
                    disabled={submitLoader}
                    style={{cursor:submitLoader && "not-allowed"}}
                  >
                    {submitLoader ? "Submitting ..." : "submit"}
                  </button>
                  <Link className="myBtn bdr" to={PATH.VIEW_EXISTING}>
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- all icons here --> */}
        <svg
          className="icon-definitions"
          style={{ display: "none" }}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            {/* <!-- sidebar icons --> */}
            <symbol id="purchaseOrder" viewBox="0 0 28 28">
              <g transform="translate(-35.362 -511.523)">
                <path
                  d="M8.579,5.566V27.613a.961.961,0,0,1-.959.959h0a.961.961,0,0,1-.959-.959V7.757h-1.1A2.189,2.189,0,0,0,3.375,9.948V29.667a2.189,2.189,0,0,0,2.191,2.191H29.743a2.117,2.117,0,0,0,2.116-2.116V5.566a2.189,2.189,0,0,0-2.191-2.191l-19.035.137A1.963,1.963,0,0,0,8.579,5.566ZM13.1,7.757h5.751a.961.961,0,0,1,.959.959h0a.961.961,0,0,1-.959.959H13.1a.961.961,0,0,1-.959-.959h0A.961.961,0,0,1,13.1,7.757Zm0,10.955H23.231a.961.961,0,0,1,.959.959h0a.961.961,0,0,1-.959.959H13.1a.961.961,0,0,1-.959-.959h0A.961.961,0,0,1,13.1,18.712Zm13.42,7.395H13.1a.961.961,0,0,1-.959-.959h0a.961.961,0,0,1,.959-.959h13.42a.961.961,0,0,1,.959.959h0A.961.961,0,0,1,26.518,26.107Zm0-10.955H13.1a.961.961,0,0,1-.959-.959h0a.961.961,0,0,1,.959-.959h13.42a.961.961,0,0,1,.959.959h0A.961.961,0,0,1,26.518,15.152Z"
                  transform="translate(31.987 508.148)"
                  fill="currentColor"
                />
              </g>
            </symbol>
            <symbol id="workConfirmation" viewBox="0 0 29.25 29.25">
              <path
                d="M12,15.221,9.956,17.269l6.581,6.581L31.163,9.225,29.115,7.178l-12.578,12.5ZM29.7,18A11.65,11.65,0,1,1,21.218,6.739l2.267-2.267A13.6,13.6,0,0,0,18,3.375,14.625,14.625,0,1,0,32.625,18Z"
                transform="translate(-3.375 -3.375)"
                fill="currentColor"
              />
            </symbol>
            <symbol id="workOrder" viewBox="0 0 23 31">
              <g transform="translate(-0.474 0.402)">
                <g
                  transform="translate(0.474 3.598)"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <rect width="23" height="27" rx="4" stroke="none" />
                  <rect x="1" y="1" width="21" height="25" rx="3" fill="none" />
                </g>
                <line
                  x2="8.477"
                  transform="translate(3.498 20.201)"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <line
                  x2="8.477"
                  transform="translate(3.498 14.955)"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <line
                  x2="13.32"
                  transform="translate(5.516 6.525)"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                />
                <path
                  d="M2.023,8.932.087,7a.3.3,0,0,1,0-.421l.421-.421a.3.3,0,0,1,.421,0l1.3,1.3L5.027,4.664a.3.3,0,0,1,.421,0l.421.421a.3.3,0,0,1,0,.421L2.444,8.932A.3.3,0,0,1,2.023,8.932Z"
                  transform="translate(14.247 7.55)"
                  fill="currentColor"
                />
                <path
                  d="M2.023,8.932.087,7a.3.3,0,0,1,0-.421l.421-.421a.3.3,0,0,1,.421,0l1.3,1.3L5.027,4.664a.3.3,0,0,1,.421,0l.421.421a.3.3,0,0,1,0,.421L2.444,8.932A.3.3,0,0,1,2.023,8.932Z"
                  transform="translate(14.247 12.598)"
                  fill="currentColor"
                />
                <g
                  transform="translate(8.474 -0.402)"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="6"
                >
                  <ellipse cx="3.5" cy="4" rx="3.5" ry="4" stroke="none" />
                  <ellipse cx="3.5" cy="4" rx="0.5" ry="1" fill="none" />
                </g>
              </g>
            </symbol>
            <symbol id="trafficSheet" viewBox="0 0 26.899 28.068">
              <path
                d="M16.152,277.36a.585.585,0,0,0-.637.126l-1.925,1.926-1.925-1.926a.585.585,0,0,0-1,.414v11.7a.585.585,0,0,0,.585.585h4.678a.585.585,0,0,0,.585-.585V277.9A.585.585,0,0,0,16.152,277.36Z"
                transform="translate(-10.666 -262.112)"
                fill="currentColor"
              />
              <path
                d="M142,192.163a.585.585,0,0,0-.827,0l-2.339,2.339a.585.585,0,0,0-.172.414v14.034a.585.585,0,0,0,.585.585h4.678a.585.585,0,0,0,.585-.585V194.916a.585.585,0,0,0-.171-.414Z"
                transform="translate(-131.649 -181.467)"
                fill="currentColor"
              />
              <path
                d="M270,149.5a.585.585,0,0,0-.827,0l-2.339,2.339a.585.585,0,0,0-.172.414v16.373a.585.585,0,0,0,.585.585h4.678a.585.585,0,0,0,.585-.585V152.249a.584.584,0,0,0-.171-.414Z"
                transform="translate(-252.632 -141.139)"
                fill="currentColor"
              />
              <path
                d="M400.343,108.007l-1.17-1.17a.585.585,0,0,0-.414-.171h-1.17a.585.585,0,0,0-.414.171l-2.339,2.339a.585.585,0,0,0-.171.414V128.3a.585.585,0,0,0,.585.585h4.678a.585.585,0,0,0,.585-.585V108.421A.585.585,0,0,0,400.343,108.007Z"
                transform="translate(-373.615 -100.819)"
                fill="currentColor"
              />
              <path
                d="M37.114,0H33.606a.585.585,0,0,0-.414,1l1.341,1.34L30.1,6.775,28.172,4.849a.585.585,0,0,0-.827,0L23.08,9.114,21.155,7.188a.585.585,0,0,0-.827,0l-6.6,6.6L11.8,11.866a.585.585,0,0,0-.827.827l2.339,2.339a.585.585,0,0,0,.827,0l6.6-6.6,1.925,1.925a.585.585,0,0,0,.827,0L27.758,6.09l1.925,1.925a.585.585,0,0,0,.827,0l4.85-4.849,1.34,1.34a.585.585,0,0,0,.414.172.578.578,0,0,0,.223-.044.585.585,0,0,0,.361-.54V.585A.585.585,0,0,0,37.114,0Z"
                transform="translate(-10.8 0)"
                fill="currentColor"
              />
            </symbol>
            {/* <!-- logout icon --> */}
            <symbol id="logout" viewBox="0 0 22.549 19.73">
              <path
                d="M8.456,0V2.819H19.73V16.912H8.456V19.73H22.549V0ZM5.637,5.637,0,9.865l5.637,4.228V11.274H16.912V8.456H5.637Z"
                fill="currentColor"
              />
            </symbol>
            {/* <!-- top bar icon --> */}
            <symbol id="home" viewBox="0 0 19.246 19.245">
              <rect
                width="6.415"
                height="7.217"
                rx="1"
                transform="translate(6.339 12.029)"
                fill="currentColor"
              />
              <path
                d="M15.99,9.384,6.75,18.55v7.685c0,1.368.481,2.228,1.56,2.228h2.72a.846.846,0,0,0,.8-.883V20.727a.845.845,0,0,1,.8-.883h7.484a.845.845,0,0,1,.8.883v6.849a.931.931,0,0,0,.234.626.767.767,0,0,0,.568.26h2.751c1.056,0,1.526-.948,1.526-2.228V18.544l-9.238-9.16A.568.568,0,0,0,15.99,9.384Z"
                transform="translate(-6.75 -9.235)"
                fill="currentColor"
              />
            </symbol>
            <symbol id="buildings" viewBox="0 0 26.837 30.671">
              <g>
                <path
                  d="M26.118,28.754h-1.2V1.438A1.438,1.438,0,0,0,23.483,0H3.355A1.438,1.438,0,0,0,1.917,1.438V28.754H.719A.719.719,0,0,0,0,29.473v1.2H26.837v-1.2A.719.719,0,0,0,26.118,28.754ZM7.668,4.553a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719v2.4a.719.719,0,0,1-.719.719h-2.4a.719.719,0,0,1-.719-.719Zm0,5.751a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719v2.4a.719.719,0,0,1-.719.719h-2.4a.719.719,0,0,1-.719-.719Zm3.115,8.866h-2.4a.719.719,0,0,1-.719-.719v-2.4a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719v2.4A.719.719,0,0,1,10.783,19.17Zm4.553,9.585H11.5V23.722A.719.719,0,0,1,12.221,23h2.4a.719.719,0,0,1,.719.719Zm3.834-10.3a.719.719,0,0,1-.719.719h-2.4a.719.719,0,0,1-.719-.719v-2.4a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719Zm0-5.751a.719.719,0,0,1-.719.719h-2.4a.719.719,0,0,1-.719-.719V10.3a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719Zm0-5.751a.719.719,0,0,1-.719.719h-2.4a.719.719,0,0,1-.719-.719v-2.4a.719.719,0,0,1,.719-.719h2.4a.719.719,0,0,1,.719.719Z"
                  fill="currentColor"
                />
              </g>
            </symbol>
            <symbol id="inventory" viewBox="0 0 23.095 30.794">
              <g transform="translate(-38 -288.214)">
                <path
                  d="M20.209,3.849H15.4a3.849,3.849,0,1,0-7.7,0H2.887A2.888,2.888,0,0,0,0,6.736V27.907a2.888,2.888,0,0,0,2.887,2.887H20.209A2.888,2.888,0,0,0,23.1,27.907V6.736A2.888,2.888,0,0,0,20.209,3.849ZM5.774,25.5a1.443,1.443,0,1,1,1.443-1.443A1.44,1.44,0,0,1,5.774,25.5Zm0-5.774a1.443,1.443,0,1,1,1.443-1.443A1.44,1.44,0,0,1,5.774,19.727Zm0-5.774A1.443,1.443,0,1,1,7.217,12.51,1.44,1.44,0,0,1,5.774,13.954ZM11.548,2.406A1.443,1.443,0,1,1,10.1,3.849,1.44,1.44,0,0,1,11.548,2.406Zm7.7,22.133a.483.483,0,0,1-.481.481H10.1a.483.483,0,0,1-.481-.481v-.962A.483.483,0,0,1,10.1,23.1h8.661a.483.483,0,0,1,.481.481Zm0-5.774a.483.483,0,0,1-.481.481H10.1a.483.483,0,0,1-.481-.481V17.8a.483.483,0,0,1,.481-.481h8.661a.483.483,0,0,1,.481.481Zm0-5.774a.483.483,0,0,1-.481.481H10.1a.483.483,0,0,1-.481-.481v-.962a.483.483,0,0,1,.481-.481h8.661a.483.483,0,0,1,.481.481Z"
                  transform="translate(38 288.214)"
                  fill="currentColor"
                />
              </g>
            </symbol>
            <symbol id="trade" viewBox="0 0 32.478 28.941">
              <g transform="translate(-36.001 -366.162)">
                <g transform="translate(36.001 366.162)">
                  <path
                    d="M7.219,14.47a1.551,1.551,0,0,1-1.1-.465L.459,8.351a1.584,1.584,0,0,1,0-2.231L6.116.465A1.574,1.574,0,0,1,8.807,1.58V3.087h16.8a.79.79,0,0,1,.789.79v6.718a.79.79,0,0,1-.789.789H22.961a7.515,7.515,0,0,0-13.185,0H8.807v1.507A1.569,1.569,0,0,1,7.219,14.47Z"
                    transform="translate(0)"
                    fill="currentColor"
                  />
                  <path
                    d="M19.179,14.471a1.57,1.57,0,0,1-1.59-1.581V11.383H.787A.788.788,0,0,1,0,10.6V3.876a.788.788,0,0,1,.787-.787H3.228a7.516,7.516,0,0,0,14.127,0h.234V2.314A7.567,7.567,0,0,0,17.8.783,1.6,1.6,0,0,1,19.179,0a1.552,1.552,0,0,1,1.1.465L25.937,6.12a1.577,1.577,0,0,1,0,2.231l-5.655,5.657A1.558,1.558,0,0,1,19.179,14.471Z"
                    transform="translate(6.078 14.47)"
                    fill="currentColor"
                  />
                  <path
                    d="M16.092,9.994a2.5,2.5,0,0,1-.654,1.732,2.823,2.823,0,0,1-1.7.9v1.151a.2.2,0,0,1-.21.21H12.64a.213.213,0,0,1-.21-.21V12.624a4.144,4.144,0,0,1-.838-.2,4.037,4.037,0,0,1-1.154-.608,3.748,3.748,0,0,1-.306-.247q-.082-.079-.115-.118a.2.2,0,0,1-.013-.27l.677-.888a.2.2,0,0,1,.151-.079.163.163,0,0,1,.158.059l.013.013a3.433,3.433,0,0,0,1.6.822,2.3,2.3,0,0,0,.487.053,1.594,1.594,0,0,0,.937-.283.921.921,0,0,0,.4-.8.667.667,0,0,0-.1-.348,1.232,1.232,0,0,0-.22-.276,1.8,1.8,0,0,0-.385-.247q-.263-.135-.434-.21t-.526-.214l-.4-.164q-.148-.059-.4-.174t-.411-.2q-.154-.089-.371-.233a2.315,2.315,0,0,1-.352-.279,4.1,4.1,0,0,1-.286-.322,1.608,1.608,0,0,1-.233-.381,2.637,2.637,0,0,1-.138-.437,2.282,2.282,0,0,1-.056-.513,2.248,2.248,0,0,1,.644-1.591A3.008,3.008,0,0,1,12.43,3.6V2.414a.213.213,0,0,1,.21-.21h.888a.2.2,0,0,1,.21.21V3.571a3.657,3.657,0,0,1,.726.151,4.212,4.212,0,0,1,.572.22,3.168,3.168,0,0,1,.417.247q.2.138.256.191t.1.092a.19.19,0,0,1,.033.25l-.533.96a.179.179,0,0,1-.151.105.21.21,0,0,1-.178-.046q-.02-.02-.1-.079t-.256-.174a3.392,3.392,0,0,0-.385-.21,2.936,2.936,0,0,0-.49-.171,2.193,2.193,0,0,0-.562-.076,1.709,1.709,0,0,0-1.019.283.884.884,0,0,0-.339,1.045.74.74,0,0,0,.194.273,2.9,2.9,0,0,0,.26.217,2.316,2.316,0,0,0,.368.2q.247.115.4.178t.46.181q.348.131.533.207t.5.23a3.971,3.971,0,0,1,.5.279,4.2,4.2,0,0,1,.408.329,1.7,1.7,0,0,1,.348.417,2.383,2.383,0,0,1,.207.5,2.169,2.169,0,0,1,.085.618Z"
                    transform="translate(3.4 6.013)"
                    fill="currentColor"
                  />
                </g>
              </g>
            </symbol>
            <symbol id="reports" viewBox="0 0 22.33 29.315">
              <g transform="translate(0)">
                <path
                  d="M2.577,29.315H19.753a2.579,2.579,0,0,0,2.577-2.577V8.588H16.318a2.579,2.579,0,0,1-2.577-2.577V0H2.577A2.579,2.579,0,0,0,0,2.577V26.739A2.579,2.579,0,0,0,2.577,29.315ZM6.012,12.081H16.318a.859.859,0,0,1,0,1.718H6.012a.859.859,0,1,1,0-1.718Zm0,3.435H16.318a.859.859,0,1,1,0,1.718H6.012a.859.859,0,1,1,0-1.718Zm0,3.435H16.318a.859.859,0,0,1,0,1.718H6.012a.859.859,0,1,1,0-1.718Zm0,3.435h6.871a.859.859,0,1,1,0,1.718H6.012a.859.859,0,1,1,0-1.718Z"
                  transform="translate(0)"
                  fill="currentColor"
                />
                <path
                  d="M.859,6.368H6.368L0,0V5.509A.859.859,0,0,0,.859,6.368Z"
                  transform="translate(15.459 0.503)"
                  fill="currentColor"
                />
              </g>
            </symbol>
            {/* <!-- right nav --> */}
            <symbol id="users" viewBox="0 0 28.538 29.339">
              <g transform="translate(-34.766 -843.472)">
                <g transform="translate(34.766 843.472)">
                  <path
                    d="M13.531,15.223A7.611,7.611,0,1,0,5.92,7.611,7.613,7.613,0,0,0,13.531,15.223ZM20.3,16.914H17.384a9.2,9.2,0,0,1-7.706,0H6.766A6.765,6.765,0,0,0,0,23.68v.846a2.538,2.538,0,0,0,2.537,2.537H24.525a2.538,2.538,0,0,0,2.537-2.537V23.68A6.765,6.765,0,0,0,20.3,16.914Z"
                    fill="currentColor"
                  />
                  <path
                    d="M15.15,10.515a4.719,4.719,0,0,0,0-1.341l1.444-1.129a.345.345,0,0,0,.082-.438L15.307,5.238a.344.344,0,0,0-.417-.151l-1.7.684A5,5,0,0,0,12.029,5.1l-.26-1.814A.334.334,0,0,0,11.434,3H8.7a.334.334,0,0,0-.335.287L8.1,5.1a5.258,5.258,0,0,0-1.157.671l-1.7-.684a.334.334,0,0,0-.417.151L3.453,7.606a.337.337,0,0,0,.082.438L4.98,9.173a5.428,5.428,0,0,0-.048.671,5.428,5.428,0,0,0,.048.671L3.536,11.644a.345.345,0,0,0-.082.438L4.822,14.45a.344.344,0,0,0,.417.151l1.7-.684a5,5,0,0,0,1.157.671l.26,1.814a.334.334,0,0,0,.335.287h2.738a.334.334,0,0,0,.335-.287l.26-1.814a5.258,5.258,0,0,0,1.157-.671l1.7.684a.334.334,0,0,0,.417-.151l1.369-2.368a.345.345,0,0,0-.082-.438L15.15,10.515Zm-5.085,1.725a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,10.065,12.239Z"
                    transform="translate(3.218 17.218)"
                    fill="currentColor"
                  />
                </g>
              </g>
            </symbol>
            <symbol id="settings" viewBox="0 0 28.538 29.339">
              <g transform="translate(16.088 -955.896)">
                <path
                  d="M28.578,19.107a11.431,11.431,0,0,0,.1-1.438,11.431,11.431,0,0,0-.1-1.438l3.1-2.42a.74.74,0,0,0,.176-.939L28.915,7.8a.737.737,0,0,0-.895-.323L24.368,8.941A10.718,10.718,0,0,0,21.889,7.5l-.557-3.887A.715.715,0,0,0,20.612,3H14.744a.715.715,0,0,0-.719.616L13.468,7.5a11.27,11.27,0,0,0-2.479,1.438L7.336,7.474a.716.716,0,0,0-.895.323L3.508,12.873a.723.723,0,0,0,.176.939l3.1,2.42a11.634,11.634,0,0,0-.1,1.438,11.634,11.634,0,0,0,.1,1.438l-3.1,2.42a.74.74,0,0,0-.176.939l2.934,5.076a.737.737,0,0,0,.895.323L10.989,26.4a10.717,10.717,0,0,0,2.479,1.438l.557,3.887a.715.715,0,0,0,.719.616h5.868a.715.715,0,0,0,.719-.616l.557-3.887A11.27,11.27,0,0,0,24.368,26.4l3.653,1.467a.716.716,0,0,0,.895-.323l2.934-5.076a.74.74,0,0,0-.176-.939l-3.1-2.42Zm-10.9,3.7a5.134,5.134,0,1,1,5.134-5.134A5.14,5.14,0,0,1,17.678,22.8Z"
                  transform="translate(-19.494 952.896)"
                  fill="currentColor"
                />
              </g>
            </symbol>
            <symbol id="notification" viewBox="0 0 20.148 25.191">
              <g transform="translate(-1672.228 -15.404)">
                <path
                  d="M17.639,31.673c1.958,0,3.028-1.385,3.028-3.337H14.6C14.6,30.288,15.674,31.673,17.639,31.673Z"
                  transform="translate(1664.647 8.923)"
                  fill="currentColor"
                />
                <path
                  d="M26.647,22.586c-.97-1.278-2.877-2.027-2.877-7.751,0-5.874-2.594-8.235-5.012-8.8-.227-.057-.39-.132-.39-.371V5.479a1.536,1.536,0,1,0-3.073,0v.183c0,.233-.164.315-.39.371-2.424.573-5.012,2.928-5.012,8.8,0,5.723-1.908,6.466-2.877,7.751a1.25,1.25,0,0,0,1,2H25.652A1.25,1.25,0,0,0,26.647,22.586Z"
                  transform="translate(1665.467 11.474)"
                  fill="currentColor"
                />
              </g>
            </symbol>
            {/* <!-- search icon --> */}
            <symbol id="search" viewBox="0 0  24.46 24.46">
              <g transform="translate(1 1)">
                <path
                  d="M24.1,14.3a9.8,9.8,0,1,1-9.8-9.8A9.8,9.8,0,0,1,24.1,14.3Z"
                  transform="translate(-4.5 -4.5)"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <path
                  d="M30.3,30.3l-5.328-5.328"
                  transform="translate(-8.257 -8.257)"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </symbol>
            {/* <!-- delete icon --> */}
            <symbol id="delete" viewBox="0 0  24.728 19.783">
              <path
                d="M19.073,20.837h4.946V23.31H19.073Zm0-9.891h8.655v2.473H19.073Zm0,4.946h7.419v2.473H19.073ZM4.236,23.31a2.48,2.48,0,0,0,2.473,2.473h7.419A2.48,2.48,0,0,0,16.6,23.31V10.946H4.236Zm13.6-16.073H14.128L12.891,6H7.946L6.709,7.236H3V9.709H17.837Z"
                transform="translate(-3 -6)"
                fill="currentColor"
              />
            </symbol>
            <symbol id="delete2" viewBox="0 0  13.646 17.544">
              <path
                d="M8.475,20.095a1.955,1.955,0,0,0,1.949,1.949h7.8a1.955,1.955,0,0,0,1.949-1.949V8.4H8.475ZM21.146,5.475H17.734L16.76,4.5H11.886l-.975.975H7.5V7.424H21.146Z"
                transform="translate(-7.5 -4.5)"
                fill="currentColor"
              />
            </symbol>
            {/* <!-- like icon --> */}
            <symbol id="like" viewBox="0 0  18.881 17.54">
              <path
                d="M13.832,22.209l-1.26-1.131C8.1,17.057,5.141,14.4,5.141,11.157a4.7,4.7,0,0,1,4.78-4.73A5.238,5.238,0,0,1,13.832,8.22a5.238,5.238,0,0,1,3.911-1.793,4.7,4.7,0,0,1,4.78,4.73c0,3.247-2.955,5.9-7.43,9.921l-1.26,1.131Z"
                transform="translate(-4.391 -5.677)"
                fill="currentColor"
                stroke="inherit"
                strokeWidth="1.5"
              />
            </symbol>
            {/* <!-- edit icon --> */}
            <symbol id="edit" viewBox="0 0  13.635 14.678">
              <g transform="translate(0.5 0.625)">
                <path
                  d="M18,30h7.077"
                  transform="translate(-12.653 -16.448)"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  d="M14.462,4.777A1.565,1.565,0,0,1,16.676,6.99L7.452,16.214,4.5,16.952,5.238,14Z"
                  transform="translate(-4.5 -4.318)"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </symbol>
          </defs>
        </svg>
      </>
    </MainLayout>
  );
}
