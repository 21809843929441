import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { PATH } from "../../../constants/paths";
import { Edit, Delete2 } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import { ToastContainer, toast } from "react-toastify";
import { baseDomain } from "../../../config";
import "react-toastify/dist/ReactToastify.css";
import {
  adminApiCall,
  capatalizeLetters,
  userAccessibility,
  getDate,
  getDateOnly,
} from "../../../helpers/ajax_request";
import Swal from "sweetalert2";
import { BuildingContexxt } from "../../../contextAPI/BuildingContext";

export default function Index() {
  const params = useParams();
  const purchaseOrderID = params.id;
  // console.log(purchaseOrderID);
  const navigate = useNavigate();

  const { buildingName, setBuildingName } = useContext(BuildingContexxt); // coming from context api
  console.log(buildingName);

  const [purchaseOrderListing, setPurchaseOrderListing] = useState({});
  const [purchaseItemsList, setpurchaseItemsList] = useState([]);
  const [addComments, setAddComments] = useState("");

  const [approveSendEmailCheckBox, setApproveSendEmailCheckBox] =
    useState(false);
  const [orderID, setOrderID] = useState("");

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  const [findBuilding, setFindBuilding] = useState("");
  // console.log("findBuilding ==> ", findBuilding);

  useEffect(() => {
    setBuildingOnNavbar();
  }, [findBuilding]);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const getPurchaseOrder = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      order_type: "purchase_parts",
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setPurchaseOrderListing(res.data);
        setpurchaseItemsList(res.data?.purchase_items);
        setFindBuilding(res?.data?.building_id);
        setOrderID(res.data?.order_id);
      } else if (res.status === 400) {
        notifyError(res.message);
      } else if (res.status === 300) {
        // notifyError(res.message);
        navigate("*");
      }
    }
  };

  const setBuildingOnNavbar = async () => {
    let data = {
      building_id: findBuilding,
    };
    let res = await adminApiCall(
      "/api/admin/building/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res SET BUILDING => ", res);
        localStorage.setItem("building_name", res?.data?.street);
        localStorage.setItem("building_ID", res?.data?.building_id);
        localStorage.setItem("building_id", res?.data?._id);
        // console.log("v._id --===> ", v._id);
        localStorage.setItem("available_suites", res?.data?.available_suite);
        localStorage.setItem("no_of_suites", res?.data?.no_of_suite);
        localStorage.setItem("building_namee", res?.data?.building_name);
      } else if (res.status === 400) {
        notifyError(res.message);
      } else if (res.status === 300) {
        // notifyError(res.message);
        navigate("*");
      }
    }
  };

  const addComment = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      comment: { comment: addComments },
    };
    let res = await adminApiCall(
      "/api/admin/purchase_order/add_comment",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setAddComments("");
        getPurchaseOrder();
        notifySuccess("Comment Has Been Added!");
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const deleteComment = (deleteArray) => {
    // console.log("deleteArray", deleteArray);
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            purchase_order_id: purchaseOrderID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            purchase_order_id: purchaseOrderID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/purchase_order/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getPurchaseOrder();
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  const approveListing = async () => {
    let data = {
      purchase_order_id: purchaseOrderID,
      send_to_trader: approveSendEmailCheckBox,
    };

    // console.log(data);
    // return;

    let res = await adminApiCall(
      "/api/admin/purchase_order/approve_order",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Order has been Approved!");
        navigate(PATH.VIEW_EXISTING);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };
  return (
    <MainLayout>
      <section className="pageWrapper">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Purchase Order Details</span>
              </div>
              <div className="details">
                <div className="detailContent">
                  <h4>Purchase Order ID</h4>
                  <h2>{purchaseOrderListing?.order_id}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created Date</h4>
                  <h2>{getDateOnly(purchaseOrderListing?.created_at)}</h2>
                </div>
                <div className="detailContent">
                  <h4>Type</h4>
                  <h2>
                    {capatalizeLetters(
                      purchaseOrderListing?.order_type +
                        " " +
                        purchaseOrderListing?.order_type
                    )}
                  </h2>
                </div>
                <div className="detailContent">
                  <h4>Assigned Trade</h4>
                  <h2>{purchaseOrderListing?.trade_info?.company}</h2>
                </div>
                <div className="detailContent">
                  <h4>Created By</h4>
                  <h2>
                    {capatalizeLetters(
                      purchaseOrderListing?.order_requested_by_user_info
                        ?.first_name +
                        "_" +
                        purchaseOrderListing?.order_requested_by_user_info
                          ?.last_name
                    )}
                  </h2>
                </div>
                {/* {purchaseOrderListing?.invoice_number ? (
                  <div className="detailContent">
                    <h4>Invoice Number</h4>
                    <h2>{purchaseOrderListing?.invoice_number}</h2>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="detailContent">
                  <h4>State</h4>
                  <h2
                    className={`badge ${
                      purchaseOrderListing?.order_state === "PENDING"
                        ? "grey"
                        : purchaseOrderListing?.order_state === "APPROVED"
                        ? "success"
                        : purchaseOrderListing?.order_state === "CONFIRM"
                        ? "success"
                        : "danger"
                    }`}
                  >
                    {purchaseOrderListing?.order_state}
                  </h2>
                </div>
              </div>
            </div>
            <div className="contentCard">
              <div className="titleWrapper">
                <span>Logs</span>
              </div>
              <div className="commentLogs">
                {purchaseOrderListing?.logs?.map((v, i) => {
                  return (
                    <div key={i} className="comment">
                      <h2>
                        {capatalizeLetters(
                          v?.created_by_user_info?.first_name +
                            "_" +
                            v?.created_by_user_info?.last_name
                        )}{" "}
                        <span>{getDate(v.created_at)}</span>
                      </h2>
                      <p>{v.log_detail?.log}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 col-lg-9">
            <div className="contentCard mb-0">
              <div className="titleWrapper">
                <span>Verify Order</span>
              </div>
              <div className="mainTable table-responsive scrollable">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Model Number</th>
                      <th>Description</th>
                      <th>Apt. No.</th>
                      <th>Price</th>
                      <th>Qty.</th>
                      <th>Qty. Received</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseItemsList &&
                      purchaseItemsList.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <b>{v.inventory_category}</b>
                            </td>
                            <td>{v.model_number}</td>
                            <td>{v.description}</td>
                            <td>{v.suite_no}</td>
                            <td>
                              <b>
                                $
                                {((v.price * 100) / 100).toLocaleString(
                                  undefined,
                                  { minimumFractionDigits: 2 }
                                )}
                              </b>
                            </td>
                            <td>{v.quantity}</td>
                            <td>
                              <span
                                className={`text ${
                                  v.quantity_received === v.quantity
                                    ? "success"
                                    : "danger"
                                }`}
                              >
                                <span className="mr-1"></span>
                                <small
                                  style={{
                                    fontSize: "14px",
                                    // paddingLeft:
                                    //   "3px",
                                  }}
                                >
                                  {v?.quantity_received}
                                </small>
                                {v?.received_date === null ? (
                                  ""
                                ) : (
                                  <small
                                    className="ml-1"
                                    style={{
                                      color: "#606071",
                                    }}
                                  >
                                    ({getDateOnly(v?.received_date)}){" "}
                                  </small>
                                )}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <hr className="splitCard" />
              <div className="formDesign">
                <Link className="myBtn bdr" to={PATH.VIEW_EXISTING}>
                  Back To Listing
                </Link>
                {purchaseOrderListing?.order_state === "PENDING"&& localStorage.getItem("role") !=
                  "building_manager"  ? (
                  <Link
                    className="myBtn ml-1"
                    to={"#"}
                    data-toggle="modal"
                    data-target="#approveOrder"
                  >
                    Approve
                  </Link>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="formDesign">
                
              </div> */}
            </div>
            {purchaseOrderListing?.documents?.length === 0 ? (
              ""
            ) : (
              <div className="contentCard commentWrapper mt-3">
                <div className="titleWrapper">
                  <span>Documents</span>
                </div>
                <div className="row documentDisplayWrapper">
                  {purchaseOrderListing?.documents?.map((v, i) => {
                    return (
                      <>
                        {v.mime_type === "image" ? (
                          <>
                            <a
                              className="imageDisplay col-lg-3 col-md-3 col-sm-12 mt-2 mb-2"
                              href={`${baseDomain + v.url}`}
                              download={v.original_name}
                              target="_blank"
                              key={i}
                            >
                              <div className="imageView">{v.original_name}</div>
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              className="documentDisplay col-lg-3 col-md-3 col-sm-12 mt-2 mb-2"
                              href={`${baseDomain + v.url}`}
                              download={v.original_name}
                              target="_blank"
                              key={i}
                            >
                              <div className="fileView">{v.original_name}</div>
                            </a>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="contentCard commentWrapper mt-3">
              <div className="formDesign">
                <div className="form-group">
                  <label>
                    Comments <small>(optional)</small>
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={addComments}
                    onChange={(e) => setAddComments(e.target.value)}
                  ></textarea>
                </div>
                <button
                  className="myBtn success mb-2"
                  onClick={() => addComment()}
                  disabled={!addComments ? true : false}
                >
                  Add Comment
                </button>
              </div>
              <div className="commentLogs">
                {purchaseOrderListing?.comments?.map((v, i) => {
                  return (
                    <div key={i} className="comment">
                      <h2>
                        {capatalizeLetters(
                          v?.created_by_user_info?.first_name +
                            "_" +
                            v?.created_by_user_info?.last_name
                        )}{" "}
                        <span>
                          {getDate(v.created_at)}{" "}
                          {localStorage.getItem("role") === "viewer" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : localStorage.getItem("role") ===
                            "building_manager" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : localStorage.getItem("role") ===
                            "property_manager" ? (
                            userAccessibility(
                              "purchase_order_management",
                              "delete"
                            ) === false ? (
                              ""
                            ) : (
                              <a
                                href="#"
                                className="circleBtn danger deleteThis ml-2"
                                onClick={() => {
                                  deleteComment([v._id]);
                                }}
                              >
                                <Delete2 />
                              </a>
                            )
                          ) : (
                            <a
                              href="#"
                              className="circleBtn danger deleteThis ml-2"
                              onClick={() => {
                                deleteComment([v._id]);
                              }}
                            >
                              <Delete2 />
                            </a>
                          )}
                        </span>
                      </h2>
                      <p>{v.comment}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="approveOrder">
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content customModal">
            <div className="modal-body">
              <h2 className="modalTitle"> Approve!</h2>
              <div className="formDesign">
                <div className="form-group">
                  <label>
                    You Are About To Approve Purchase Order [{orderID}]
                  </label>
                  <input
                    type="checkbox"
                    className="customCheck mx-3"
                    // id={"purchaseOrder" + i}
                    //               value={v._id}
                    //               checked={
                    //                 deleteItem && deleteItem.includes(v._id)
                    //                   ? true
                    //                   : false
                    //               }

                    value={approveSendEmailCheckBox}
                    onChange={(e) =>
                      setApproveSendEmailCheckBox(e.target.checked)
                    }
                  />
                  <label>Send Email to Contractor</label>
                </div>
                <div className="text-center">
                  <button
                    className="myBtn mr-1"
                    data-dismiss="modal"
                    onClick={() => approveListing()}
                  >
                    Approve
                  </button>
                  <button className="myBtn simple " data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
