import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { Modal } from "react-bootstrap";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import Multiselect from "multiselect-react-dropdown";
import { adminApiCall } from "../../../helpers/ajax_request";
import { useFormik } from "formik";
import * as yup from "yup";
import { baseDomain } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomPhone from "../../../components/CustomPhone/Index";

export default function Index() {
  const params = useParams();
  const userList = params.id;
  // console.log("params", params);

  const navigate = useNavigate();

  const [userData, setUserData] = useState([]);
  const [profilePic, setProfilePic] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [options, setOptions] = useState([]);
  const [buildingIDs, setBuildingIDs] = useState([]);
  const [selectValidationError, setSelectValidationError] = useState("");

  useEffect(() => {
    getUserData();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const initialValues = {
    fName: "",
    lName: "",
    email: "",
    phone: "",
    // role: "",
    password: "",
    cPassword: "",
    description: "",
  };

  const selectBuildingAPI = async (role) => {
    let data = {
      user_role: role,
    };
    let res = await adminApiCall(
      "/api/admin/user/building_select_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res select building", res);
        let tempArray = [...options];
        for (let i = 0; i < res.data.length; i++) {
          tempArray.push({
            name: res.data[i].building_name,
            id: res.data[i]._id,
            building_id: res.data[i].building_id,
          });
        }
        setOptions(tempArray);
        // console.log("temparray: ", tempArray);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const getUserData = async () => {
    let data = {
      user_id: userList,
    };
    let res = await adminApiCall(
      "/api/admin/user/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setUserData(res.data);
        formik.setValues({
          fName: res.data[0].first_name,
          lName: res.data[0].last_name,
          email: res.data[0].email,
          phone: res.data[0].phone_no,
          description: res.data[0].description ? res.data[0].description : "",
          password: res.data[0].password_without_hashed,
          cPassword: res.data[0].password_without_hashed,
        });
        setRoleType(res.data[0].role);
        setProfilePic(res.data[0]?.profile_image);
        setSelectedBuilding(
          res.data[0].building.map((building) => {
            return { id: building._id, name: building.building_name };
          })
        );
        setBuildingIDs(
          res.data[0].building.map((building) => {
            return building._id;
          })
        );
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
    selectBuildingAPI(res.data[0].role);
  };

  let validationSchema = yup.object({
    fName: yup
      .string("Enter first name.")
      .trim()
      .min(2, "Please enter a valid name.")
      .max(50, "Please enter a valid name.")
      .required("Please specify first name."),
    lName: yup
      .string("Enter last name.")
      .trim()
      .min(2, "Please enter a valid name.")
      .max(50, "Please enter a valid name.")
      .required("Please specify last name."),
    email: yup
      .string("Enter email.")
      .trim()
      .email("Please enter a valid email address.")
      .required("Email is required."),
    // phone: yup
    //   .string()
    //   .trim()
    //   .min(10, "Please enter 10 digit number.")
    //   .max(10, "16 digit number exceeded.")
    //   .required("Please specify Phone No."),
    // role: yup
    //   .string("Please select Role")
    //   // .trim()
    //   // .min(2, "Please enter a valid name.")
    //   // .max(50, "Please enter a valid name.")
    //   .required("Please specify a role."),
    password: yup
      .string()
      .required("Please specify your password.")
      .min(8, "The password should have at minimum length of 8."),
    cPassword: yup
      .string()
      .required("Please re-enter password to confirm")
      .oneOf([yup.ref("password"), null], "Password does not match"),
    // description: yup
    //   .string("Enter Description.")
    //   .trim()
    //   //   .min(2, "Please enter a valid name")
    //   //   .max(50, "Please enter a valid name")
    //   .required("Please provide description for this User."),
  });

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const handleChangeRole = (e) => {
    setRoleType(e.target.value);
    // console.log(e.target.value);
  };

  function MyVerticallyCenteredModal(props) {
    const profilePicAPI = {
      name: "profile_image",
      data: {
        user_id: userList,
      },
      listType: "picture",
      accept: "image/*", //file explorer opens and all format images will show by default. if .png is accepted only then only png format images will show.
      beforeUpload: (file) => {
        // console.log(file.type);
        let type = file?.type?.split("/")[0];
        // console.log(type);
        if (type === "image") {
          return true;
        } else {
          notifyError("This format cannot be uploaded");
          return false;
        }
      },
      maxCount: 1,
      action: `${baseDomain}/api/admin/update_profile_photo`,
      headers: {
        authorization: localStorage.getItem("adminToken"),
      },
      onChange(info) {
        if (info?.file?.status !== "uploading") {
          // console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          notifySuccess(`Profile photo uploaded successfully`);
          setModalShow(false);
          setProfilePic(info.file.response?.data?.profile_image);
        } else if (info.file.status === "error") {
          notifyError(`Profile photo upload failed.`);
        }
      },
    };

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="modalTitle">Upload Profile Picture</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mt-3 mb-5">
            <Upload {...profilePicAPI}>
              <Button icon={<UploadOutlined />} type="file">
                Click to Upload
              </Button>
            </Upload>
          </div>

          <div className="formDesign text-center">
            <button
              className="myBtn shadow "
              style={{ width: "100px" }}
              onClick={() => handleCloseModal()}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <div className="formDesign text-center">
            <Link
              className="myBtn shadow "
              style={{ minWidth: "176px" }}
              to={PATH.MAINTENANCE_SERVICES}
            >
              Maintenance Service
            </Link>
            <br />
            <br />
            <Link
              className="myBtn shadow "
              style={{ minWidth: "176px" }}
              to={PATH.PURCHASE_PARTS}
            >
              Purchase Parts
            </Link>
          </div>
        </Modal.Footer> */}
      </Modal>
    );
  }

  const onSubmit = async (values) => {
    // console.log("values", values);
    if (buildingIDs.length === 0) {
      // console.log("buildingIDs", buildingIDs);
      notifyError("Please Assign Building before proceeding");
    } else {
      setSelectValidationError("");
      // console.log("all okay", values);
      // console.log("all okay", "buildingidddd: ", buildingIDs);
      let data = {
        user_id: userList,
        first_name: values.fName,
        last_name: values.lName,
        phone_no: values.phone,
        email: values.email,
        password: values.password,
        role: roleType,
        description: values.description,
        building_ids: buildingIDs,
      };

      // console.log("data: ", data);
      // return;
      let res = await adminApiCall(
        "/api/admin/user/update",
        "POST",
        JSON.stringify(data)
      );
      if (!res) {
        notifyError("Something went wrong.. Please Try Again");
        return false;
      }
      if (res.hasOwnProperty("status")) {
        if (res.status === 200) {
          notifySuccess("User Updated Successfully!");
          navigate(PATH.USER_LIST);
          // history.push("/user-list-truck");
          // navigate(PATH.USER_LIST);
        } else if (res.status === 400) {
          notifyError(res.message);
        }
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const onSelect = (selectedList, selectedItem) => {
    // console.log("selectedList: ", selectedList);
    // console.log("selectedItem", selectedItem);
    // console.log("buildingIDssss: ", buildingIDs);
    let selectOptionsArray = [];
    selectedList.map((v, i) => {
      selectOptionsArray.push(v.id);
      // console.log("selectOptionsArray: ", selectOptionsArray);
      setBuildingIDs(selectOptionsArray);
    });
  };

  const onRemove = (removedItem) => {
    // console.log("buildingIDs: ", buildingIDs);
    let tempArray = [];
    removedItem.map((v, i) => {
      tempArray.push(v.id);
      // console.log("tempArray: ", tempArray);
    });
    setBuildingIDs(tempArray);
  };

  const handlePhoneNumberChange = (phone) => {
    let output = "";
    if (phone.length === 11) {
      let cc = phone.substr(0, 1);
      let area = phone.substr(1, 3);
      let pre = phone.substr(4, 3);
      let tel = phone.substr(7, 4);

      output = `+${cc} (${area}) ${pre} ${tel}`;
      // console.log("if", phone);
      formik.setFieldValue("phone", phone);
    } else {
      if (phone.length < 11) {
        // console.log("length zero");
      } else {
        // console.log("not zero");
      }
      // console.log("else", phone);
      formik.setFieldValue("phone", phone);
    }
  };
  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="profileSide">
                <div className="profileImg">
                  <img src={`${baseDomain}/${profilePic}`} alt="" />
                </div>

                <button
                  variant="primary"
                  className="myBtn bdr"
                  onClick={() => setModalShow(true)}
                >
                  CHANGE
                </button>

                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />

                <p>{formik.values.description}</p>
              </div>
            </div>
            <div className="col-12 col-md-9">
              <div className="contentCard">
                <div className="titleWrapper">
                  <span>Account Settings</span>
                </div>

                <div className="formDesign">
                  <form className="row" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.fName && formik.touched.fName
                              ? " is-invalid"
                              : "")
                          }
                          type="text"
                          name="fName"
                          value={formik.values.fName}
                          onChange={formik.handleChange}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.fName}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.lName && formik.touched.lName
                              ? " is-invalid"
                              : "")
                          }
                          type="text"
                          // onChange={handleInputs}
                          // name="l_name"
                          // value={userInputs.l_name}
                          name="lName"
                          value={formik.values.lName}
                          onChange={formik.handleChange}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.lName}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.email && formik.touched.email
                              ? " is-invalid"
                              : "")
                          }
                          type="email"
                          // onChange={handleInputs}
                          // name="email"
                          // required
                          // value={userInputs.email}
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.email}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <CustomPhone
                          className={
                            "form-control noDrpDown " +
                            (formik.errors.phone && formik.touched.phone
                              ? "is-invalid"
                              : "")
                          }
                          value={formik.values.phone}
                          onChange={(phone) => {
                            formik.handleChange(phone);
                            handlePhoneNumberChange(phone);
                          }}
                          onlyCountries={["ca"]}
                          name="phone"
                        />
                        <div className="invalid-feedback">
                          {formik.errors.phone}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Role</label>
                        <select
                          className={
                            "form-control " +
                            (formik.errors.role && formik.touched.role
                              ? " is-invalid"
                              : "")
                          }
                          name="role"
                          // onChange={handleInputs}
                          // required
                          // value={userInputs.role}
                          value={roleType}
                          onChange={(e) => handleChangeRole(e)}
                        >
                          <option value="property_manager">
                            Property Manager
                          </option>
                          <option value="building_manager">
                            Building Manager
                          </option>
                          <option value="viewer">Viewer</option>
                        </select>
                        <div className="invalid-feedback">
                          {formik.errors.role}
                        </div>
                      </div>
                      {formik.values.role}
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.password && formik.touched.password
                              ? " is-invalid"
                              : "")
                          }
                          type="password"
                          name="password"
                          // onChange={handleInputs}
                          // required
                          // value={userInputs.password}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.password}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          className={
                            "form-control " +
                            (formik.errors.cPassword && formik.touched.cPassword
                              ? " is-invalid"
                              : "")
                          }
                          type="password"
                          name="cPassword"
                          // onChange={handleInputs}
                          // required
                          // value={userInputs.confirmPassword}
                          value={formik.values.cPassword}
                          onChange={formik.handleChange}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.cPassword}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <label>Assign Buildings</label>
                      <div className="form-group">
                        <Multiselect
                          options={options} // Options to display in the dropdown
                          name="options"
                          onSelect={(list, item) => onSelect(list, item)}
                          onRemove={(list, item) => onRemove(list, item)}
                          selectedValues={selectedBuilding}
                          showCheckbox={true} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                          placeholder="Search Building"
                          // loading={true}
                          // className="multiselectWrapper "
                          className={
                            buildingIDs.length === 0
                              ? " is-invalid multiselectWrapper"
                              : "multiselectWrapper"
                          }
                          style={{
                            chips: {
                              background: "var(--primary)",
                            },
                          }}
                        />
                        {buildingIDs.length === 0 ? (
                          <div className="invalid-feedback">
                            {selectValidationError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-12 ">
                      <div className="form-group">
                        <label>Details</label>
                        <textarea
                          className={
                            "form-control " +
                            (formik.errors.cPassword && formik.touched.cPassword
                              ? " is-invalid"
                              : "")
                          }
                          rows="5"
                          // name="details"
                          // onChange={handleInputs}
                          // value={userInputs.details}
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                        ></textarea>
                        <div className="invalid-feedback">
                          {formik.errors.description}
                        </div>
                      </div>
                    </div>
                    <div className="cardFooter ml-0">
                      <button className="myBtn mr-1" type="submit">
                        Update
                      </button>

                      <Link className="myBtn bdr" to={PATH.USER_LIST}>
                        Cancel
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- modal starts here --> */}
        <div className="modal fade" id="profileModal">
          <div className="modal-dialog modal-xl">
            <div className="modal-content customModal">
              <div className="modal-header">
                <div className="titleWrapper">
                  <span>Account Settings</span>
                </div>
              </div>
              <div className="modal-body">
                <div className="formDesign">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Role</label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea className="form-control" rows="5"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <a className="myBtn shadow ml-auto" type="submit" href="#">
                  Update Profile
                </a>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </MainLayout>
  );
}
