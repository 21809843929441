import { configureStore } from '@reduxjs/toolkit';
import {loginReducer} from "../pages/authentication/login/login-reducer";


export const store = configureStore({
  reducer: {
    login: loginReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});
