import $ from "jquery";
import {
  baseDomain,
  adminApiDomain,
  adminLoginURL,
  sessionExpireStatus,
} from "../config";
import Swal from "sweetalert2";

/**
 * @param url
 * @param method
 * @param data
 * @param authToken

 * @returns URL response
 */
function userApiCall(url, method, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          "Content-Type": "application/json",
        },
        method: method || "GET",
        data,
        success: function (data) {
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

function userApiCallApplication(url, method, data, authToken) {
  return new Promise(function (resolve, reject) {
    try {
      var headers = {
        "Content-Type": "application/json",
      };

      if (authToken) {
        headers["Authorization"] = authToken;
      }

      $.ajax({
        url: baseDomain + url,
        headers: headers,
        method: method || "GET",
        data: data,
        success: function (data) {
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

function userApiCallApplicationFile(url, method, data, authToken) {
  return new Promise(function (resolve, reject) {
    try {
      var headers = {
        "Content-Type": "application/json",
      };

      if (authToken) {
        headers["Authorization"] = authToken;
      }

      $.ajax({
        url: baseDomain + url,
        headers: headers,
        method: method || "GET",
        data: data,
        // processData: false, // To prevent jQuery from processing the FormData
        // contentType: false, // To prevent jQuery from setting the Content-Type header
        success: function (data) {
          resolve(data);
        },
        error: function (xhr, status, error) {
          console.error("Error occurred during request:", error);
          reject(error);
        },
      });
    } catch (e) {
      console.error("Error occurred during request:", e);
      reject(e);
    }
  });
}




function adminApiCall(url, method, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("adminToken"),
        },
        method: method || "GET",
        data,
        success: function (data) {
          if (data.status && data.status == sessionExpireStatus) {
            Swal.fire({
              title: "Error!",
              text: data.message,
              icon: "error",
            }).then(function () {
              localStorage.clear();
              window.location.href = "/";
            });
          }
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}
// delete Api
function deleteApplication(url, method, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("adminToken"),
        },
        method: method || "GET",
        data,
        success: function (data) {
          if (data.status && data.status == sessionExpireStatus) {
            Swal.fire({
              title: "Error!",
              text: data.message,
              icon: "error",
            }).then(function () {
              localStorage.clear();
              window.location.href = "/";
            });
          }
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}


// Update of Application Api call
function updateApplication(url, method, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("adminToken"),
        },
        method: method || "GET",
        data,
        success: function (data) {
          if (data.status && data.status == sessionExpireStatus) {
            Swal.fire({
              title: "Error!",
              text: data.message,
              icon: "error",
            }).then(function () {
              localStorage.clear();
              window.location.href = "/";
            });
          }
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}
// Lsiting of Application Api call
function listingApplication(url, method, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("adminToken"),
        },
        method: method || "GET",
        data,
        success: function (data) {
          if (data.status && data.status == sessionExpireStatus) {
            Swal.fire({
              title: "Error!",
              text: data.message,
              icon: "error",
            }).then(function () {
              localStorage.clear();
              window.location.href = "/";
            });
          }
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}
// get Api
function getApplication(url, method, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("adminToken"),
        },
        method: method || "GET",
        data,
        success: function (data) {
          if (data.status && data.status == sessionExpireStatus) {
            Swal.fire({
              title: "Error!",
              text: data.message,
              icon: "error",
            }).then(function () {
              localStorage.clear();
              window.location.href = "/";
            });
          }
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}
function adminLogin(url, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        data,
        success: function (data) {
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

function forgotPassword(url, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        data,
        success: function (data) {
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

function resetPassword(url, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        data,
        success: function (data) {
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

function adminApiCallForFormData(url, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          Authorization: localStorage.getItem("adminToken"),
          Accept: "application/json",
        },
        method: "POST",
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        data,
        success: function (data) {
          data = JSON.parse(data);
          resolve(data);
          if (data.status && data.status == sessionExpireStatus) {
            Swal.fire({
              title: "Error!",
              text: data.message,
              icon: "error",
            }).then(function () {
              localStorage.clear();
              window.location.href = "/";
            });
          }
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

function adminRegister(url, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          Authorization: localStorage.getItem("adminToken"),
          "Content-Type": "application/json",
        },
        method: "POST",
        data,
        success: function (data) {
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}

// create Application
function createApplcation(url, data) {
  return new Promise(function (resolve, reject) {
    try {
      $.ajax({
        url: baseDomain + url,
        headers: {
          Authorization: localStorage.getItem("adminToken"),
          "Content-Type": "application/json",
        },
        method: "POST",
        data,
        success: function (data) {
          resolve(data);
        },
        error: function (err) {
          console.log(err);
          reject(err);
        },
      });
    } catch (e) {
      reject(e);
    }
  });
}


function capatalizeLetters(word) {
  let firstWord = word.split("_")[0];
  let secondWord = word.split("_")[1];
  let newWord =
    firstWord.charAt(0).toUpperCase() +
    firstWord.slice(1) +
    " " +
    (secondWord?.charAt(0).toUpperCase() + secondWord?.slice(1)
      ? secondWord?.charAt(0).toUpperCase() + secondWord?.slice(1)
      : "");

  if (word === "viewer") {
    return newWord.split(" ")[0];
  } else {
    return newWord.split(" ")[0] + " " + newWord.split(" ")[1];
  }

  // return newWord;
}

function getDate(dateSent) {
  // let date = dateSent.split("T")[0];
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  // let newDate = new Date(dateSent);
  let newDate = new Date(
    new Date(dateSent).toLocaleString("en-US", {
      timeZone: "America/New_York",
    })
  );
  let thisTime = new Date();
  // console.log(
  //   "newDate:",
  //   newDate.getDate() +
  //     "/" +
  //     newDate.getMonth() +
  //     "/" +
  //     newDate.getFullYear() +
  //     newDate.getTime()
  // );
  let hours = newDate.getHours();
  let mins = newDate.getMinutes();
  // console.log(mins);
  mins = mins < 10 ? "0" + mins : mins;
  let ampm = newDate.getHours() >= 12 ? "PM" : "AM";
  // console.log(newDate.getHours(), mins);
  // console.log(newDate);
  return (
    months[newDate.getMonth()] +
    " " +
    newDate.getDate() +
    " " +
    newDate.getFullYear() +
    " - " +
    newDate.getHours() +
    ":" +
    mins +
    " " +
    ampm
  );
}

function getDateOnly(dateSent) {
  // let date = dateSent;
  // console.log(date);
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let newDate = new Date(dateSent).toUTCString();
  // console.log(newDate);
  // console.log(
  //   "newDate:",
  //   newDate.getDate() +
  //     "/" +
  //     newDate.getMonth() +
  //     "/" +
  //     newDate.getFullYear() +
  //     newDate.getTime()
  // );
  // let hours = newDate.getHours();
  // let mins = newDate.getMinutes();
  // mins = mins < 10 ? "0" + mins : mins;
  // let ampm = newDate.getHours() >= 12 ? "PM" : "AM";

  return newDate.slice(5, 17);
  // new Date(newDate).getDate() +
  // " " +
  // months[new Date(newDate).getMonth()] +
  // " " +
  // new Date(newDate).getFullYear()
  // newDate.getDate() +
  // " " +
  // months[newDate.getMonth()] +
  // " " +
  // newDate.getFullYear()
}

function userAccessibility(resource, mode) {
  let permission = JSON.parse(localStorage.getItem("permissions"));
  // let resourceArray = permission.indexOf(permission.find(resource));
  let permissionStatus;
  let resourceArray = permission.map((v, i) => {
    if (v.resource === resource) {
      // console.log("permission of particular resource: ", v.permissions);
      // console.log("permission of particular resource: ", v.resource);
      let resoursePermissions = v?.permissions?.map((per, index) => {
        if (per.action === mode) {
          // console.log("permission mode: ", per.action, per.permission_status);
          permissionStatus = per.permission_status;
        }
      });
    }
  });
  // console.log(permissionStatus);

  return permissionStatus;
}

export {
  userApiCall,
  adminApiCall,
  forgotPassword,
  resetPassword,
  adminLogin,
  adminApiCallForFormData,
  adminRegister,
  capatalizeLetters,
  getDate,
  getDateOnly,
  userAccessibility,
  createApplcation,
  listingApplication,
  getApplication,
  updateApplication,
  deleteApplication,
  userApiCallApplication,
  userApiCallApplicationFile
  
};
