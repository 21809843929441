import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Edit, Like, Search, File, Delete } from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { adminApiCall, userAccessibility } from "../../../helpers/ajax_request";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import AddTrades from "../AddTrades/Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Index() {
  const [modalShow, setModalShow] = useState(false);
  const [tradeList, setTradeList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [perPage, setPerPage] = useState(50);
  const [deleteItem, setDeleteItem] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [name, setName] = useState(false);
  const [company, setCompany] = useState(false);
  const [fax, setFax] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState(false);
  const [completeArray, setCompleteArray] = useState([]);
  const [sortValue, setSortValue] = useState("0");

  const [favouriteList, setFavouriteList] = useState([]);
  const [loaderFavourite, setLoaderFavourite] = useState(true);
  const [noRecordFavourite, setNoRecordFavourite] = useState(false);
  const [pageCountFavourite, setPageCountFavourite] = useState(10);
  const [currentPageFavourite, setCurrentPageFavourite] = useState(1);
  const [totalRecordFavourite, setTotalRecordFavourite] = useState(10);
  const [perPageFavourite, setPerPageFavourite] = useState(50);
  const [favouriteCheckBox, setfavouriteCheckBox] = useState(false);
  const [checkedFavourite, setCheckedFavourite] = useState(true);
  const [nameFavourite, setNameFavourite] = useState(false);
  const [companyFavourite, setCompanyFavourite] = useState(false);
  const [faxFavourite, setFaxFavourite] = useState(false);
  const [emailFavourite, setEmailFavourite] = useState(false);
  const [phoneFavourite, setPhoneFavourite] = useState(false);
  const [purchaseOrderFavourite, setPurchaseOrderFavourite] = useState(false);
  const [showState, setShowState] = useState(true);
  const [showAllState, setShowAllState] = useState(false);
  const [showAllHiddenState, setShowAllHiddenState] = useState(false);
  const [valueSortFavourite, setValueSortFavourite] = useState("0");

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const searchHandler = (e) => {
    setSearchBar(e.target.value);
    // console.log(e.target.value);
  };

  useEffect(() => {
    if (
      showState === true &&
      showAllState === false &&
      showAllHiddenState === false
    ) {
      // console.log("showtrade");
      getTradeList(false, false);
    } else if (
      showState === false &&
      showAllState === true &&
      showAllHiddenState === false
    ) {
      // console.log("showalltrade");
      getTradeList(true, false);
    } else if (
      showState === false &&
      showAllState === false &&
      showAllHiddenState === true
    ) {
      // console.log("showallhiddentrade");
      getTradeList(false, true);
    }
    if (localStorage.getItem("role") === "viewer") {
      if (userAccessibility("trade_management", "delete") === false) {
        // console.log("abc")
      } else {
        getFavouriteList();
      }
    } else if (localStorage.getItem("role") === "building_manager") {
      if (userAccessibility("trade_management", "delete") === false) {
        // console.log("abc")
      } else {
        getFavouriteList();
      }
    } else if (localStorage.getItem("role") === "property_manager") {
      if (userAccessibility("trade_management", "delete") === false) {
        // console.log("abc")
      } else {
        getFavouriteList();
      }
    } else {
      getFavouriteList();
    }
  }, [
    searchBar,
    name,
    company,
    fax,
    email,
    phone,
    purchaseOrder,
    nameFavourite,
    companyFavourite,
    faxFavourite,
    emailFavourite,
    phoneFavourite,
    purchaseOrderFavourite,
    currentPage,
    currentPageFavourite,
    showState,
    showAllState,
    showAllHiddenState,
  ]);

  const getTradeList = async (showAll, ShowAllHidden) => {
    let data = {
      current_page: currentPage,
      itemsPerPage: perPage,
      search_value: searchBar,
      is_show_all: showAll,
      is_show_all_hidden: ShowAllHidden,

      ...(sortValue === "1"
        ? {
            sort_by_name: name,
          }
        : sortValue === "2"
        ? {
            sort_by_company: company,
          }
        : sortValue === "3"
        ? {
            sort_by_fax: fax,
          }
        : sortValue === "4"
        ? {
            sort_by_email: email,
          }
        : sortValue === "5"
        ? {
            sort_by_phone: phone,
          }
        : sortValue === "6"
        ? {
            sort_by_purchase_order: purchaseOrder,
          }
        : ""),
    };
    // console.log("data: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/trade/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setTradeList(res.data);
        let completeValue = [];
        res.data?.map((v, i) => {
          completeValue.push(v.is_favourite);
        });
        // console.log("completeValue: ", completeValue);
        setCompleteArray(completeValue);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
        // console.log("tradeList: ", tradeList);
        setTradeList([]);
      }
    }
  };

  const handleChange = (e) => {
    const itemPerPage = parseInt(e.target.value);
    setPerPage(itemPerPage);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const handleChangeCheckBox = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    // console.log(e);
    // console.log(checked + value);
    // if (deleteItem) {
    let tempArray = [...deleteItem];
    if (checked) {
      tempArray.push(value);
      // console.log("deleteitem: ", deleteItem);
    } else {
      tempArray.splice(tempArray.indexOf(value), 1);
      // console.log("deleteitem: ", deleteItem);
    }
    setDeleteItem(tempArray);
    // }
  };
  const deleteTrade = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            trade_id: deleteArray,
          };
        } else {
          data = {
            trade_id: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/trade/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong! Please Try Again!");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getTradeList();
            setDeleteItem([]);
            // setHideTradesArray([]);
            notifySuccess("Trade Has Been Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  // ///////////////////////////////
  const hideTrade = async (hideArray) => {
    let data = {
      trade_id: hideArray,
      is_hidden: true,
    };
    let res = await adminApiCall(
      "/api/admin/trade/change_status",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        getTradeList();
        setDeleteItem([]);
        // setHideTradesArray([]);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const unHideTrade = async (hideArray) => {
    let data = {
      trade_id: hideArray,
      is_hidden: false,
    };
    let res = await adminApiCall(
      "/api/admin/trade/change_status",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        getTradeList();
        setDeleteItem([]);
        // setHideTradesArray([]);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const showAllTrades = async () => {
    let data = {
      current_page: currentPage,
      // itemsPerPage: 10,
      is_show_all: true,
      // is_show_all_hidden: false,

      // sort_by_name: false,
      // sort_by_company: false,
      // sort_by_fax: false,
      // sort_by_email: false,
      // sort_by_purchase_order: false,
    };
    // console.log("currentpageshowalltrades: ", currentPage);
    let res = await adminApiCall(
      "/api/admin/trade/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setTradeList(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const showAllHidden = async () => {
    let data = {
      current_page: currentPage,
      // itemsPerPage: 10,
      is_show_all_hidden: true,

      // sort_by_name: false,
      // sort_by_company: false,
      // sort_by_fax: false,
      // sort_by_email: false,
      // sort_by_purchase_order: false,
    };
    // console.log("currentpageshowallhidden: ", currentPage);
    let res = await adminApiCall(
      "/api/admin/trade/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setTradeList(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };
  // ///////////////////////////////

  const getFavouriteList = async () => {
    let data = {
      current_page: currentPageFavourite,
      itemsPerPage: perPageFavourite,
      search_value: searchBar,
      is_show_all: false,
      is_show_all_hidden: false,
      ...(valueSortFavourite === "1"
        ? {
            sort_by_name: nameFavourite,
          }
        : valueSortFavourite === "2"
        ? {
            sort_by_company: companyFavourite,
          }
        : valueSortFavourite === "3"
        ? {
            sort_by_fax: faxFavourite,
          }
        : valueSortFavourite === "4"
        ? {
            sort_by_email: emailFavourite,
          }
        : valueSortFavourite === "5"
        ? {
            sort_by_phone: phoneFavourite,
          }
        : valueSortFavourite === "6"
        ? {
            sort_by_purchase_order: purchaseOrderFavourite,
          }
        : ""),
    };

    let res = await adminApiCall(
      "/api/admin/trade/favourite_trade_list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setFavouriteList(res.data);
        setPageCountFavourite(res.total_pages);
        setTotalRecordFavourite(res.total_records);
        setLoaderFavourite(false);
        setNoRecordFavourite(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoaderFavourite(false);
        setNoRecordFavourite(true);
      } else if (res.status === 300) {
        setLoaderFavourite(false);
        setNoRecordFavourite(true);
      }
    }
  };

  const handleChangeFavourite = (e) => {
    const itemPerPage = parseInt(e.target.value);
    setPerPageFavourite(itemPerPage);
  };

  const handlePageClickFavourite = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPageFavourite(selectedPage);
  };

  const handleChangeFavourites = async (e, objValue, id, position) => {
    const updatedCheckedState = completeArray.map((item, index) =>
      index === position ? !item : item
    );

    setCompleteArray(updatedCheckedState);
    setfavouriteCheckBox(e.target.checked);

    let data = {
      trade_id: id,
      is_favourite: e.target.checked,
    };

    let res = await adminApiCall(
      "/api/admin/trade/favourite_trade",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess(res.message);
        getFavouriteList();
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const handleChangeRemoveFavourites = async (e, objValue) => {
    let ID = e.target.id.split("-").pop();

    // setCheckedFavourite(e.target.checked);
    setCheckedFavourite(e.target.checked);
    // console.log(e.target.checked);
    // console.log(e.target.value);

    let data = {
      trade_id: e.target.value,
      is_favourite: e.target.checked,
    };

    let res = await adminApiCall(
      "/api/admin/trade/favourite_trade",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something Went Wrong! Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess(res.message);
        getFavouriteList();
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const nameSort = () => {
    setName(!name);
    setSortValue("1");
  };

  const companySort = () => {
    setCompany(!company);
    setSortValue("2");
  };

  const faxSort = () => {
    setFax(!fax);
    setSortValue("3");
  };

  const emailSort = () => {
    setEmail(!email);
    setSortValue("4");
  };

  const phoneSort = () => {
    setPhone(!phone);
    setSortValue("5");
  };

  const purchaseOrderSort = () => {
    setPurchaseOrder(!purchaseOrder);
    setSortValue("6");
  };

  const nameSortFav = () => {
    setNameFavourite(!nameFavourite);
    setValueSortFavourite("1");
  };

  const companySortFav = () => {
    setCompanyFavourite(!companyFavourite);
    setValueSortFavourite("2");
  };

  const faxSortFav = () => {
    setFaxFavourite(!faxFavourite);
    setValueSortFavourite("3");
  };

  const emailSortFav = () => {
    setEmailFavourite(!emailFavourite);
    setValueSortFavourite("4");
  };

  const phoneFavSort = () => {
    setPhoneFavourite(!phoneFavourite);
    setValueSortFavourite("5");
  };

  const purchaseOrderSortFav = () => {
    setPurchaseOrderFavourite(!purchaseOrderFavourite);
    setValueSortFavourite("6");
  };

  const modalChange = (val) => {
    setModalShow(val);
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="tabsSection">
            <ul className="nav customTabs">
              <li>
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#view"
                  onClick={() => {
                    setShowState(true);
                    setShowAllHiddenState(false);
                    setShowAllState(false);

                    getTradeList(false, false);
                  }}
                >
                  View Trades
                </a>
              </li>
              {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("trade_management", "delete") === false ? (
                  ""
                ) : (
                  <li>
                    <a className="nav-link" data-toggle="tab" href="#fav">
                      Favourites
                    </a>
                  </li>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("trade_management", "delete") === false ? (
                  ""
                ) : (
                  <li>
                    <a className="nav-link" data-toggle="tab" href="#fav">
                      Favourites
                    </a>
                  </li>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("trade_management", "delete") === false ? (
                  ""
                ) : (
                  <li>
                    <a className="nav-link" data-toggle="tab" href="#fav">
                      Favourites
                    </a>
                  </li>
                )
              ) : (
                <li>
                  <a className="nav-link" data-toggle="tab" href="#fav">
                    Favourites
                  </a>
                </li>
              )}
            </ul>

            <div className="d-flex justify-content-center align-items-center">
              <div className="search mr-2">
                <div className="searchField">
                  <input
                    className="form-control searchBar"
                    type="text"
                    placeholder="Search"
                    value={searchBar}
                    onChange={searchHandler}
                  />
                  <div className="icon">
                    <Search />
                  </div>
                </div>
              </div>
              {localStorage.getItem("role") === "viewer" ? (
                userAccessibility("trade_management", "create") === false ? (
                  ""
                ) : (
                  <Button
                    className="myBtn shadow reactBoostrapModalBtn"
                    onClick={() => setModalShow(true)}
                  >
                    {" "}
                    <i className="fa fa-plus-circle reactBoostrapIcon"></i>
                    Add Trade
                  </Button>
                )
              ) : localStorage.getItem("role") === "building_manager" ? (
                userAccessibility("trade_management", "create") === false ? (
                  ""
                ) : (
                  <Button
                    className="myBtn shadow reactBoostrapModalBtn"
                    onClick={() => setModalShow(true)}
                  >
                    {" "}
                    <i className="fa fa-plus-circle reactBoostrapIcon"></i>
                    Add Trade
                  </Button>
                )
              ) : localStorage.getItem("role") === "property_manager" ? (
                userAccessibility("trade_management", "create") === false ? (
                  ""
                ) : (
                  <Button
                    className="myBtn shadow reactBoostrapModalBtn"
                    onClick={() => setModalShow(true)}
                  >
                    {" "}
                    <i className="fa fa-plus-circle reactBoostrapIcon"></i>
                    Add Trade
                  </Button>
                )
              ) : (
                <Button
                  className="myBtn shadow reactBoostrapModalBtn"
                  onClick={() => setModalShow(true)}
                >
                  {" "}
                  <i className="fa fa-plus-circle reactBoostrapIcon"></i>
                  Add Trade
                </Button>
              )}
            </div>
            <AddTrades
              show={modalShow}
              onHide={() => setModalShow(false)}
              getTradeList={() => getTradeList()}
              modalChange={modalChange}
            />
          </div>

          <div className="tab-content">
            <div className="tab-pane fade show active" id="view">
              <div className="contentCard">
                <div className="filters"></div>

                {loader ? (
                  <div className="text-center">
                    <img src={Loader} alt="" />
                  </div>
                ) : noRecord ? (
                  <div className="text-center">
                    <img src={NoRecordFound} alt="" />
                  </div>
                ) : (
                  <div className="mainTable table-responsive dataTable scrollable">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          {localStorage.getItem("role") === "viewer" ? (
                            userAccessibility("trade_management", "delete") ===
                            false ? (
                              <th style={{ display: "none" }}></th>
                            ) : (
                              <th style={{ minWidth: "100px" }}></th>
                            )
                          ) : (
                            <th style={{ minWidth: "100px" }}></th>
                          )}
                          <th className="sort" onClick={nameSort}>
                            Name
                          </th>
                          <th className="sort" onClick={companySort}>
                            Company
                          </th>
                          <th className="sort" onClick={faxSort}>
                            Fax
                          </th>
                          <th className="sort" onClick={emailSort}>
                            Email
                          </th>
                          <th className="sort" onClick={phoneSort}>
                            Phone
                          </th>
                          <th className="sort" onClick={purchaseOrderSort}>
                            Purchase Orders
                          </th>
                          {localStorage.getItem("role") === "viewer" ? (
                            userAccessibility("trade_management", "update") ===
                            false ? (
                              <th style={{ display: "none" }}></th>
                            ) : (
                              <th className="text-right">Action</th>
                            )
                          ) : localStorage.getItem("role") ===
                            "building_manager" ? (
                            userAccessibility("trade_management", "update") ===
                            false ? (
                              <th style={{ display: "none" }}></th>
                            ) : (
                              <th className="text-right">Action</th>
                            )
                          ) : localStorage.getItem("role") ===
                            "property_manager" ? (
                            userAccessibility("trade_management", "update") ===
                            false ? (
                              <th style={{ display: "none" }}></th>
                            ) : (
                              <th className="text-right">Action</th>
                            )
                          ) : (
                            <th className="text-right">Action</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {tradeList &&
                          tradeList.map((v, i) => {
                            return (
                              <tr key={i}>
                                {localStorage.getItem("role") === "viewer" ? (
                                  userAccessibility(
                                    "trade_management",
                                    "delete"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="customCheck mr-3"
                                        id={"inventoryCheck" + i}
                                        value={v._id}
                                        checked={
                                          deleteItem &&
                                          deleteItem.includes(v._id)
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleChangeCheckBox(e)
                                        }
                                      />
                                      <label className="toggleLike">
                                        <input
                                          type="checkbox"
                                          id={"favouriteCheck" + "-" + i}
                                          value={v._id}
                                          checked={
                                            completeArray[i] ? "checked" : false
                                          }
                                          onChange={(e) =>
                                            handleChangeFavourites(
                                              e,
                                              v,
                                              v._id,
                                              i
                                            )
                                          }
                                        />
                                        <Like />
                                      </label>
                                    </td>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "building_manager" ? (
                                  userAccessibility(
                                    "trade_management",
                                    "delete"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="customCheck mr-3"
                                        id={"inventoryCheck" + i}
                                        value={v._id}
                                        checked={
                                          deleteItem &&
                                          deleteItem.includes(v._id)
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleChangeCheckBox(e)
                                        }
                                      />
                                      <label className="toggleLike">
                                        <input
                                          type="checkbox"
                                          id={"favouriteCheck" + "-" + i}
                                          value={v._id}
                                          checked={
                                            completeArray[i] ? "checked" : false
                                          }
                                          onChange={(e) =>
                                            handleChangeFavourites(
                                              e,
                                              v,
                                              v._id,
                                              i
                                            )
                                          }
                                        />
                                        <Like />
                                      </label>
                                    </td>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "property_manager" ? (
                                  userAccessibility(
                                    "trade_management",
                                    "delete"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="customCheck mr-3"
                                        id={"inventoryCheck" + i}
                                        value={v._id}
                                        checked={
                                          deleteItem &&
                                          deleteItem.includes(v._id)
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleChangeCheckBox(e)
                                        }
                                      />
                                      <label className="toggleLike">
                                        <input
                                          type="checkbox"
                                          id={"favouriteCheck" + "-" + i}
                                          value={v._id}
                                          checked={
                                            completeArray[i] ? "checked" : false
                                          }
                                          onChange={(e) =>
                                            handleChangeFavourites(
                                              e,
                                              v,
                                              v._id,
                                              i
                                            )
                                          }
                                        />
                                        <Like />
                                      </label>
                                    </td>
                                  )
                                ) : (
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="customCheck mr-3"
                                      id={"inventoryCheck" + i}
                                      value={v._id}
                                      checked={
                                        deleteItem && deleteItem.includes(v._id)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => handleChangeCheckBox(e)}
                                    />
                                    <label className="toggleLike">
                                      <input
                                        type="checkbox"
                                        id={"favouriteCheck" + "-" + i}
                                        value={v._id}
                                        checked={
                                          completeArray[i] ? "checked" : false
                                        }
                                        onChange={(e) =>
                                          handleChangeFavourites(e, v, v._id, i)
                                        }
                                      />
                                      <Like />
                                    </label>
                                  </td>
                                )}
                                <td>
                                  <b>{v.name}</b>
                                </td>
                                <td>{v.company}</td>
                                <td>
                                  {/* {"(" +
                                    v.fax?.split("")[0] +
                                    v.fax?.split("")[1] +
                                    v.fax?.split("")[2] +
                                    ") " +
                                    v.fax?.split("")[3] +
                                    v.fax?.split("")[4] +
                                    v.fax?.split("")[5] +
                                    " - " +
                                    v.fax?.split("")[6] +
                                    v.fax?.split("")[7] +
                                    v.fax?.split("")[8] +
                                    v.fax?.split("")[9]}
                                  {" ("} */}
                                  {v?.fax?.split("")[0] !== undefined
                                    ? " ("
                                    : ""}
                                  {v?.fax?.split("")[0] !== undefined
                                    ? v?.fax?.split("")[0]
                                    : ""}
                                  {v?.fax?.split("")[1] !== undefined
                                    ? v?.fax?.split("")[1]
                                    : ""}
                                  {v?.fax?.split("")[2] !== undefined
                                    ? v?.fax?.split("")[2]
                                    : ""}
                                  {v?.fax?.split("")[0] !== undefined
                                    ? ") "
                                    : ""}
                                  {v?.fax?.split("")[3] !== undefined
                                    ? v?.fax?.split("")[3]
                                    : ""}
                                  {v?.fax?.split("")[4] !== undefined
                                    ? v?.fax?.split("")[4]
                                    : ""}
                                  {v?.fax?.split("")[5] !== undefined
                                    ? v?.fax?.split("")[5]
                                    : ""}
                                  {v?.fax?.split("")[0] !== undefined
                                    ? " - "
                                    : ""}
                                  {v?.fax?.split("")[6] !== undefined
                                    ? v?.fax?.split("")[6]
                                    : ""}
                                  {v?.fax?.split("")[7] !== undefined
                                    ? v?.fax?.split("")[7]
                                    : ""}
                                  {v?.fax?.split("")[8] !== undefined
                                    ? v?.fax?.split("")[8]
                                    : ""}
                                  {v?.fax?.split("")[9] !== undefined
                                    ? v?.fax?.split("")[9]
                                    : ""}
                                </td>
                                <td>{v.email}</td>
                                <td>
                                  {/* {"(" +
                                    v.phone_no?.split("")[0] +
                                    v.phone_no?.split("")[1] +
                                    v.phone_no?.split("")[2] +
                                    ") " +
                                    v.phone_no?.split("")[3] +
                                    v.phone_no?.split("")[4] +
                                    v.phone_no?.split("")[5] +
                                    " - " +
                                    v.phone_no?.split("")[6] +
                                    v.phone_no?.split("")[7] +
                                    v.phone_no?.split("")[8] +
                                    v.phone_no?.split("")[9]} */}
                                  {v.phone_no?.split("")[0] !== undefined
                                    ? " ("
                                    : ""}
                                  {v.phone_no?.split("")[0] !== undefined
                                    ? v.phone_no?.split("")[0]
                                    : ""}
                                  {v.phone_no?.split("")[1] !== undefined
                                    ? v.phone_no?.split("")[1]
                                    : ""}
                                  {v.phone_no?.split("")[2] !== undefined
                                    ? v.phone_no?.split("")[2]
                                    : ""}
                                  {v.phone_no?.split("")[0] !== undefined
                                    ? ") "
                                    : ""}
                                  {v.phone_no?.split("")[3] !== undefined
                                    ? v.phone_no?.split("")[3]
                                    : ""}
                                  {v.phone_no?.split("")[4] !== undefined
                                    ? v.phone_no?.split("")[4]
                                    : ""}
                                  {v.phone_no?.split("")[5] !== undefined
                                    ? v.phone_no?.split("")[5]
                                    : ""}
                                  {v.phone_no?.split("")[0] !== undefined
                                    ? " - "
                                    : ""}
                                  {v.phone_no?.split("")[6] !== undefined
                                    ? v.phone_no?.split("")[6]
                                    : ""}
                                  {v.phone_no?.split("")[7] !== undefined
                                    ? v.phone_no?.split("")[7]
                                    : ""}
                                  {v.phone_no?.split("")[8] !== undefined
                                    ? v.phone_no?.split("")[8]
                                    : ""}
                                  {v.phone_no?.split("")[9] !== undefined
                                    ? v.phone_no?.split("")[9]
                                    : ""}
                                </td>
                                <td>{v.purchase_order}</td>
                                {localStorage.getItem("role") === "viewer" ? (
                                  userAccessibility(
                                    "inventory_management",
                                    "update"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td className="text-right">
                                      <div className="dropleft">
                                        <button
                                          type="button"
                                          className="actionEclips dropdown-toggle"
                                          data-toggle="dropdown"
                                        >
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </button>
                                        <div className="dropdown-menu dropDesign">
                                          <Link
                                            to={`${PATH.EDIT_TRADE}/${v._id}`}
                                          >
                                            Edit
                                            <span>
                                              <Edit />
                                            </span>
                                          </Link>
                                          <Link
                                            to={`${PATH.VIEW_COMMENTS}/${v._id}`}
                                          >
                                            View
                                            <span>
                                              <File />
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "building_manager" ? (
                                  userAccessibility(
                                    "inventory_management",
                                    "update"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td className="text-right">
                                      <div className="dropleft">
                                        <button
                                          type="button"
                                          className="actionEclips dropdown-toggle"
                                          data-toggle="dropdown"
                                        >
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </button>
                                        <div className="dropdown-menu dropDesign">
                                          <Link
                                            to={`${PATH.EDIT_TRADE}/${v._id}`}
                                          >
                                            Edit
                                            <span>
                                              <Edit />
                                            </span>
                                          </Link>
                                          <Link
                                            to={`${PATH.VIEW_COMMENTS}/${v._id}`}
                                          >
                                            View
                                            <span>
                                              <File />
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  )
                                ) : localStorage.getItem("role") ===
                                  "property_manager" ? (
                                  userAccessibility(
                                    "inventory_management",
                                    "update"
                                  ) === false ? (
                                    <td style={{ display: "none" }}></td>
                                  ) : (
                                    <td className="text-right">
                                      <div className="dropleft">
                                        <button
                                          type="button"
                                          className="actionEclips dropdown-toggle"
                                          data-toggle="dropdown"
                                        >
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </button>
                                        <div className="dropdown-menu dropDesign">
                                          <Link
                                            to={`${PATH.EDIT_TRADE}/${v._id}`}
                                          >
                                            Edit
                                            <span>
                                              <Edit />
                                            </span>
                                          </Link>
                                          <Link
                                            to={`${PATH.VIEW_COMMENTS}/${v._id}`}
                                          >
                                            View
                                            <span>
                                              <File />
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  )
                                ) : (
                                  <td className="text-right">
                                    <div className="dropleft">
                                      <button
                                        type="button"
                                        className="actionEclips dropdown-toggle"
                                        data-toggle="dropdown"
                                      >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                      </button>
                                      <div className="dropdown-menu dropDesign">
                                        <Link
                                          to={`${PATH.EDIT_TRADE}/${v._id}`}
                                        >
                                          Edit
                                          <span>
                                            <Edit />
                                          </span>
                                        </Link>
                                        <Link
                                          to={`${PATH.VIEW_COMMENTS}/${v._id}`}
                                        >
                                          View
                                          <span>
                                            <File />
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}

                {tradeList && tradeList.length > 0 ? (
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-5">
                      Showing {currentPage * perPage - perPage + 1} to{" "}
                      {totalRecord > currentPage * perPage
                        ? currentPage * perPage
                        : totalRecord}{" "}
                      of {totalRecord} entries
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers text-right"
                        id="datatable_paginate"
                      >
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          onPageChange={(e) => handlePageClick(e)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          pageClassName={"paginate_button page-item"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {localStorage.getItem("role") === "viewer" ? (
                  userAccessibility("inventory_management", "delete") ===
                  false ? (
                    ""
                  ) : (
                    <div className="cardFooter">
                      <button
                        className="myBtn danger squre mr-1"
                        onClick={() => deleteTrade(deleteItem)}
                        disabled={deleteItem.length === 0 ? true : false}
                      >
                        <Delete />
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => hideTrade(deleteItem)}
                      >
                        Hide Trade
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => unHideTrade(deleteItem)}
                      >
                        Unhide Trade
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => {
                          setShowState(false);
                          setShowAllHiddenState(false);
                          setShowAllState(true);
                          getTradeList(true, false);
                        }} //show all trades
                      >
                        Show All Trades
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => {
                          setShowState(false);
                          setShowAllHiddenState(true);
                          setShowAllState(false);
                          getTradeList(false, true);
                        }} //show all hidden trades
                      >
                        Show All Hidden
                      </button>
                    </div>
                  )
                ) : localStorage.getItem("role") === "building_manager" ? (
                  userAccessibility("inventory_management", "delete") ===
                  false ? (
                    ""
                  ) : (
                    <div className="cardFooter">
                      <button
                        className="myBtn danger squre mr-1"
                        onClick={() => deleteTrade(deleteItem)}
                        disabled={deleteItem.length === 0 ? true : false}
                      >
                        <Delete />
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => hideTrade(deleteItem)}
                      >
                        Hide Trade
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => unHideTrade(deleteItem)}
                      >
                        Unhide Trade
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => {
                          setShowState(false);
                          setShowAllHiddenState(false);
                          setShowAllState(true);
                          getTradeList(true, false);
                        }}
                      >
                        Show All Trades
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => {
                          setShowState(false);
                          setShowAllHiddenState(true);
                          setShowAllState(false);
                          getTradeList(false, true);
                        }}
                      >
                        Show All Hidden
                      </button>
                    </div>
                  )
                ) : localStorage.getItem("role") === "property_manager" ? (
                  userAccessibility("inventory_management", "delete") ===
                  false ? (
                    ""
                  ) : (
                    <div className="cardFooter">
                      <button
                        className="myBtn danger squre mr-1"
                        onClick={() => deleteTrade(deleteItem)}
                        disabled={deleteItem.length === 0 ? true : false}
                      >
                        <Delete />
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => hideTrade(deleteItem)}
                      >
                        Hide Trade
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => unHideTrade(deleteItem)}
                      >
                        Unhide Trade
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => {
                          setShowState(false);
                          setShowAllHiddenState(false);
                          setShowAllState(true);
                          getTradeList(true, false);
                        }}
                      >
                        Show All Trades
                      </button>
                      <button
                        className="myBtn simple mr-1"
                        onClick={() => {
                          setShowState(false);
                          setShowAllHiddenState(true);
                          setShowAllState(false);
                          getTradeList(false, true);
                        }}
                      >
                        Show All Hidden
                      </button>
                    </div>
                  )
                ) : (
                  <div className="cardFooter">
                    <button
                      className="myBtn danger squre mr-1"
                      onClick={() => deleteTrade(deleteItem)}
                      disabled={deleteItem.length === 0 ? true : false}
                    >
                      <Delete />
                    </button>
                    <button
                      className="myBtn simple mr-1"
                      onClick={() => hideTrade(deleteItem)}
                    >
                      Hide Trade
                    </button>
                    <button
                      className="myBtn simple mr-1"
                      onClick={() => unHideTrade(deleteItem)}
                    >
                      Unhide Trade
                    </button>
                    <button
                      className="myBtn simple mr-1"
                      onClick={() => {
                        setShowState(false);
                        setShowAllHiddenState(false);
                        setShowAllState(true);
                        getTradeList(true, false);
                      }}
                    >
                      Show All Trades
                    </button>
                    <button
                      className="myBtn simple mr-1"
                      onClick={() => {
                        setShowState(false);
                        setShowAllHiddenState(true);
                        setShowAllState(false);
                        getTradeList(false, true);
                      }}
                    >
                      Show All Hidden
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="tab-pane fade " id="fav">
              <div className="contentCard">
                {loaderFavourite ? (
                  <div className="text-center">
                    <img src={Loader} alt="" />
                  </div>
                ) : noRecordFavourite ? (
                  <div className="text-center">
                    <img src={NoRecordFound} alt="" />
                  </div>
                ) : (
                  <div className="mainTable table-responsive dataTable scrollable">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="text-right"></th>
                          <th className="sort" onClick={nameSortFav}>
                            Name
                          </th>
                          <th className="sort" onClick={companySortFav}>
                            Company
                          </th>
                          <th className="sort" onClick={faxSortFav}>
                            Fax
                          </th>
                          <th className="sort" onClick={emailSortFav}>
                            Email
                          </th>
                          <th className="sort" onClick={phoneFavSort}>
                            Phone
                          </th>
                          <th className="sort" onClick={purchaseOrderSortFav}>
                            Purchase Orders
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {favouriteList &&
                          favouriteList.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <label className="toggleLike">
                                    <input
                                      type="checkbox"
                                      id={"checkedFavourite" + "-" + i}
                                      value={v._id}
                                      checked
                                      // disabled
                                      onChange={(e) =>
                                        handleChangeRemoveFavourites(e, v)
                                      }
                                    />
                                    <Like />
                                  </label>
                                </td>
                                <td>
                                  <b>{v.name}</b>
                                </td>
                                <td>{v.company}</td>
                                <td>
                                  {v.fax?.split("")[0] !== undefined
                                    ? " ("
                                    : ""}
                                  {v.fax?.split("")[0] !== undefined
                                    ? v.fax?.split("")[0]
                                    : ""}
                                  {v.fax?.split("")[1] !== undefined
                                    ? v.fax?.split("")[1]
                                    : ""}
                                  {v.fax?.split("")[2] !== undefined
                                    ? v.fax?.split("")[2]
                                    : ""}
                                  {v.fax?.split("")[0] !== undefined
                                    ? ") "
                                    : ""}
                                  {v.fax?.split("")[3] !== undefined
                                    ? v.fax?.split("")[3]
                                    : ""}
                                  {v.fax?.split("")[4] !== undefined
                                    ? v.fax?.split("")[4]
                                    : ""}
                                  {v.fax?.split("")[5] !== undefined
                                    ? v.fax?.split("")[5]
                                    : ""}
                                  {v.fax?.split("")[0] !== undefined
                                    ? " - "
                                    : ""}
                                  {v.fax?.split("")[6] !== undefined
                                    ? v.fax?.split("")[6]
                                    : ""}
                                  {v.fax?.split("")[7] !== undefined
                                    ? v.fax?.split("")[7]
                                    : ""}
                                  {v.fax?.split("")[8] !== undefined
                                    ? v.fax?.split("")[8]
                                    : ""}
                                  {v.fax?.split("")[9] !== undefined
                                    ? v.fax?.split("")[9]
                                    : ""}
                                </td>
                                <td>{v.email}</td>
                                <td>
                                  {v.phone_no?.split("")[0] !== undefined
                                    ? " ("
                                    : ""}
                                  {v.phone_no?.split("")[0] !== undefined
                                    ? v.phone_no?.split("")[0]
                                    : ""}
                                  {v.phone_no?.split("")[1] !== undefined
                                    ? v.phone_no?.split("")[1]
                                    : ""}
                                  {v.phone_no?.split("")[2] !== undefined
                                    ? v.phone_no?.split("")[2]
                                    : ""}
                                  {v.phone_no?.split("")[0] !== undefined
                                    ? ") "
                                    : ""}
                                  {v.phone_no?.split("")[3] !== undefined
                                    ? v.phone_no?.split("")[3]
                                    : ""}
                                  {v.phone_no?.split("")[4] !== undefined
                                    ? v.phone_no?.split("")[4]
                                    : ""}
                                  {v.phone_no?.split("")[5] !== undefined
                                    ? v.phone_no?.split("")[5]
                                    : ""}
                                  {v.phone_no?.split("")[0] !== undefined
                                    ? " - "
                                    : ""}
                                  {v.phone_no?.split("")[6] !== undefined
                                    ? v.phone_no?.split("")[6]
                                    : ""}
                                  {v.phone_no?.split("")[7] !== undefined
                                    ? v.phone_no?.split("")[7]
                                    : ""}
                                  {v.phone_no?.split("")[8] !== undefined
                                    ? v.phone_no?.split("")[8]
                                    : ""}
                                  {v.phone_no?.split("")[9] !== undefined
                                    ? v.phone_no?.split("")[9]
                                    : ""}
                                </td>
                                <td>{v.purchase_order}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}

                {favouriteList && favouriteList.length > 0 ? (
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-5">
                      Showing{" "}
                      {currentPageFavourite * perPageFavourite -
                        perPageFavourite +
                        1}{" "}
                      to{" "}
                      {totalRecordFavourite >
                      currentPageFavourite * perPageFavourite
                        ? currentPageFavourite * perPageFavourite
                        : totalRecordFavourite}{" "}
                      of {totalRecordFavourite} entries
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers text-right"
                        id="datatable_paginate"
                      >
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCountFavourite}
                          // marginPagesDisplayed={2}
                          // pageRangeDisplayed={5}
                          onPageChange={(e) => handlePageClickFavourite(e)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          pageClassName={"paginate_button page-item"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="commentsWrapper"></section>
      </>
    </MainLayout>
  );
}
