import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { PATH } from "../../constants/paths";
import {
  PurchaseOrder,
  TrafficSheet,
  WorkOrder,
  Settings,
} from "../../Icons/Icons";
import MainLayout from "../../layouts";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDate } from "../../helpers/ajax_request";

const Home = () => {
  const navigate = useNavigate();
  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });
  return (
    <MainLayout>
      <section className="pageWrapper cardControl">
        <div className="contentCard" style={{ minHeight: "850px" }}>
          <div className="titleWrapper">
            <span>Welcome Back!</span>
          </div>
          <div className="w-80 me-auto ms-0">
            <div
              className="row justify-content-start"
              style={{ marginTop: "80px" }}
            >
              <div className="col-lg-auto mt-3 col-md-6 col-sm-12">
                <Card style={{ width: "265px" }} className="homeCard mx-auto ">
                  <Card.Header className="cardHeaderHome">
                    <PurchaseOrder />
                  </Card.Header>
                  <hr />
                  <Card.Body>
                    <Card.Title className="cardTitleHome">
                      Purchase Order
                    </Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                    <Link
                      className="row justify-content-between footerWrapper"
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.VIEW_EXISTING
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.VIEW_EXISTING);
                        }
                      }}
                    >
                      <div className="col-lg-6 col-md-6">
                        <div className="listView">View List</div>
                      </div>
                      <div className="col-lg-5 col-md-4 rightArrow text-right">
                        <FontAwesomeIcon icon={faArrowRight} className="svgg" />
                      </div>
                    </Link>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-lg-auto mt-3 col-md-6 col-sm-12">
                <Card style={{ width: "265px" }} className="homeCard mx-auto">
                  <Card.Header className="cardHeaderWorkOrderHome">
                    <WorkOrder />
                  </Card.Header>
                  <hr />
                  <Card.Body>
                    <Card.Title className="cardTitleHome">
                      {/* Work Order */}
                      Application
                    </Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                    <Link
                      className="row justify-content-between footerWrapper"
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.APPLICATION_LISTING
                          
                          // PATH.WORK_ORDER
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.APPLICATION_LISTING);
                        }
                      }}
                    >
                      <div className="col-lg-6 col-md-6">
                        <div className="listView">View List</div>
                      </div>
                      <div className="col-lg-5 col-md-4 rightArrow text-right">
                        <FontAwesomeIcon icon={faArrowRight} className="svgg" />
                      </div>
                    </Link>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-lg-auto mt-3 col-md-6 col-sm-12">
                <Card style={{ width: "265px" }} className="homeCard mx-auto">
                  <Card.Header className="cardHeaderHome">
                    <TrafficSheet />
                  </Card.Header>
                  <hr />
                  <Card.Body>
                    <Card.Title className="cardTitleHome">
                      Traffic Sheet
                    </Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                    <Link
                      className="row justify-content-between footerWrapper"
                      to={`${
                        localStorage.getItem("building_id") === null
                          ? "#"
                          : PATH.VIEW_TRAFFIC_SHEET
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.VIEW_TRAFFIC_SHEET);
                        }
                      }}
                    >
                      <div className="col-lg-6 col-md-6">
                        <div className="listView">View List</div>
                      </div>
                      <div className="col-lg-5 col-md-4 rightArrow text-right">
                        <FontAwesomeIcon icon={faArrowRight} className="svgg" />
                      </div>
                    </Link>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-lg-auto mt-3 col-md-6 col-sm-12">
                <Card style={{ width: "265px" }} className="homeCard mx-auto">
                  <Card.Header className="cardHeaderHome">
                    <Settings />
                  </Card.Header>
                  <hr />
                  <Card.Body>
                    <Card.Title className="cardTitleHome">Settings</Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </Card.Text>
                    <Link
                      className="row justify-content-between footerWrapper"
                      to={`${
                        localStorage.getItem("building_id") === null ? "#" : "#"
                        // : PATH.ACCOUNT_SETTINGS
                      }`}
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate("#");
                        }
                      }}
                    >
                      <div className="col-lg-6 col-md-6">
                        <div className="listView">View List</div>
                      </div>
                      <div className="col-lg-5 col-md-4 rightArrow text-right">
                        <FontAwesomeIcon icon={faArrowRight} className="svgg" />
                      </div>
                    </Link>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Home;
