import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  adminApiCall,
  capatalizeLetters,
  getDate,
  userAccessibility,
} from "../../../helpers/ajax_request";
import moment from "moment";
import Swal from "sweetalert2";
import { Delete2 } from "../../../Icons/Icons";
import { getViewerDetails } from "react-documents";

export default function Index() {
  const params = useParams();
  const trafficID = params.id;
  const navigate = useNavigate();
  const buildingID = localStorage.getItem("building_id");
  // console.log(params);
  const TS_ID = params._id;
console.log("trafficID",trafficID)
console.log("TS_ID",TS_ID)



  useEffect(() => {
    getTrafficUnit();
    // unitList();
  }, []);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const initialValues = {
    // standardRent: "",
    rentWithDiscount: "",
    comments: "",
  };
  const [unitStatus, setUnitStatus] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [vacateDate, setVacateDate] = useState(moment.utc());
  const [rentedAsOf, setRentedAsOf] = useState(moment.utc());
  const [pickedUpDate, setPickedUpDate] = useState(moment.utc());
  const [standardRent, setStandardRent] = useState("");
  const [unitName, setUnitName] = useState("");
  const [viewComments, setViewComments] = useState();

  const [unitData, setUnitData] = useState({
    type: "",
    bathroom: "",
    square_footage: "",
  });

  const [checkboxes, setCheckboxes] = useState({
    damageReport: false,
    outgoingInspection: false,
    incomingInspection: false,
    leaseSigned: false,
  });

  const [action, setAction] = useState([
    { label: "Monthly", value: "MONTHLY" },
    { label: "Short", value: "SHORT" },
    { label: "Eviction", value: "EVICTION" },
  ]);

  const [status, setStatus] = useState([
    { label: "Vacant", value: "VACANT" },
    { label: "Occupied", value: "OCCUPIED" },
  ]);

  const selectActionHandler = (e) => {
    // console.log(e.target.value);
    setSelectedAction(e.target.value);
  };

  const selectUnitName = (e) => {
    // console.log(e.target.value);
    setSelectedUnit(e.target.value);
  };

  const getTrafficUnit = async () => {
    let data = {
      traffic_sheet_id: trafficID,
    };
    let res = await adminApiCall(
      "/api/admin/traffic_sheet/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        // console.log("res", res);
        setUnitData(res.data?.unit_info);

        formik.setValues({
          rentWithDiscount: res.data?.rent_with_discount,
          comments:
            res.data?.comments.length === 0
              ? ""
              : res.data?.comments[0].comment,
        });
        setStandardRent(res.data.standard_rent);
        setUnitStatus(res.data?.unit_status);
        let action = capatalizeLetters(res.data?.action.toLowerCase());
        setSelectedAction(res.data?.action);
        // console.log(res.data?.action);
        setViewComments(res?.data?.comments);
        setVacateDate(
          // res.data?.unit_status === "OCCUPIED"
          //   ? ""
          //   :
          moment.utc(res.data?.vacate_date)
        );

        setRentedAsOf(
          res.data?.rented_as_of === null || res.data?.unit_status === "VACANT"
            ? ""
            : moment.utc(res.data?.rented_as_of)
        );
        setPickedUpDate(moment.utc(res.data?.pm_picked_up_date));

        // setRentWithDiscount(res.data?.rent_with_discount);
        setUnitName(res.data?.unit_info?.unit_no);
        let obj = { ...checkboxes };
        obj.damageReport = res.data?.damage_report;
        obj.incomingInspection = res.data?.incoming_inspection;
        obj.outgoingInspection = res.data?.outgoing_inspection;
        obj.leaseSigned = res.data?.lease_signed;
        setCheckboxes(obj);
      } else if (res.status === 400) {
        notifyError(res.message);
      } else if (res.status === 300) {
        notifyError(res.message);
      }
    }
  };

  const handleStatus = (e) => {
    // console.log(e.target.value);
    setSelectedStatus(e.target.value);
  };

  const handleCheckBoxes = (e) => {
    let checked = e.target.checked;
    let name = e.target.name;
    // console.log(e);
    let newCheckbox = {
      ...checkboxes,
      [name]: checked,
    };
    // console.table(newCheckbox);
    setCheckboxes(newCheckbox);
  };

  let validationSchema = yup.object({
    // standardRent: yup.string().required("Please enter Standard Rent."),
    rentWithDiscount: yup.string().required("Please enter Rent with Discount."),
  });

  const onSubmit = async (values) => {
    // console.log("all okay", values);
    let newAction = capatalizeLetters(selectedAction.toLowerCase());
    newAction = newAction.split(" ")[0];
    // let newStatus = capatalizeLetters(selectedStatus.toLowerCase());
    // newStatus = newStatus.split(" ")[0];

    let data = {
      traffic_sheet_id: trafficID,
      action: newAction,
      standard_rent: Number(standardRent.toFixed(2)),
      rent_with_discount: Number(formik.values.rentWithDiscount),
      ...(vacateDate && {
        vacate_date:
          vacateDate === "" ? vacateDate : vacateDate.format("MM/DD/YYYY"),
      }),
      // ...(rentedAsOf && {
      //   rented_as_of:
      //     rentedAsOf === "" ? rentedAsOf : rentedAsOf.format("MM/DD/YYYY"),
      // }),
      rented_as_of: rentedAsOf === "" ? "" : rentedAsOf.format("MM/DD/YYYY"), // Include with empty string if empty

      // pm_picked_up_date:
      //   pickedUpDate === "" ? "" : pickedUpDate.format("MM/DD/YYYY"),
      // unit_status: newStatus,
      damage_report: checkboxes.damageReport,
      outgoing_inspection: checkboxes.outgoingInspection,
      incoming_inspection: checkboxes.incomingInspection,
      lease_signed: checkboxes.leaseSigned,
      comments: {
        comment: formik.values.comments,
      },
    };
    // console.log("data sent: ", data);
    // return;
    let res = await adminApiCall(
      "/api/admin/traffic_sheet/update",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        notifySuccess("Traffic Sheet Updated Successfully!");
        navigate(PATH.VIEW_TRAFFIC_SHEET);
        formik.setValues({});
        formik.setErrors({});
      } else if (res.status === 400) {
        notifyError(res.message);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  function onChangeVacateDate(date, dateString) {
    console.log(date, dateString);
    date = moment.utc(date);
    setVacateDate(dateString === "" ? "" : moment.utc(date));
  }

  function onChangeRentedAsOf(date, dateString) {
    // console.log(date, dateString);
    date = moment.utc(date);
    setRentedAsOf(dateString === "" ? "" : moment.utc(date));
  }

  function onChangePickedUpDate(date, dateString) {
    // console.log(date, dateString);
    date = moment.utc(date);
    setPickedUpDate(dateString === "" ? "" : moment.utc(date));
  }

  const customFormik = (e) => {
    // console.log(e.currentTarget.value);
    let value = e.currentTarget.value;
    let name = e.currentTarget.name;
    // console.log("obj: ", obj);
    let withoutDiscount = e.target.value / 0.98;
    setStandardRent(withoutDiscount);
    formik.setFieldValue("rentWithDiscount", value);
  };

  const deleteComment = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            traffic_sheet_id: trafficID,
            comment_ids: deleteArray,
          };
        } else {
          data = {
            traffic_sheet_id: trafficID,
            comment_ids: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/traffic_sheet/delete_comment",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            getTrafficUnit();
            notifySuccess("Comment Deleted Successfully!");
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };
  return (
    <MainLayout>
      <form onSubmit={formik.handleSubmit}>
        <section className="pageWrapper">
          <div className="contentCard ">
            <div className="titleWrapper">
              <span>Edit Traffic Sheet</span>
            </div>
            <div className="formDesign">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Apt. No.</label>
                    <input
                      className={"form-control"}
                      type="text"
                      name="unitName"
                      value={unitName}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Notice</label>
                    <select
                      className="form-control"
                      value={selectedAction}
                      onChange={(e) => selectActionHandler(e)}
                    >
                      {action &&
                        action.map((v, i) => {
                          return (
                            <option key={i} value={v.value}>
                              {v.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>
                      Rent With 2% Discount <small>($)</small>
                    </label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.rentWithDiscount &&
                        formik.touched.rentWithDiscount
                          ? " is-invalid"
                          : "")
                      }
                      type="number"
                      name="rentWithDiscount"
                      value={formik.values.rentWithDiscount}
                      onChange={customFormik}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.rentWithDiscount}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>
                      Legal Rent <small>($)</small>
                    </label>
                    <input
                      className={
                        "form-control " +
                        (formik.errors.standardRent &&
                        formik.touched.standardRent
                          ? " is-invalid"
                          : "")
                      }
                      type="number"
                      name="standardRent"
                      value={(formik.values.rentWithDiscount / 0.98).toFixed(2)}
                      disabled
                    />
                    <div className="invalid-feedback">
                      {formik.errors.standardRent}
                    </div>
                  </div>
                </div>
                {unitData.type === "" &&
                unitData.type === "" &&
                unitData.square_footage === "" ? (
                  ""
                ) : (
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="form-group">
                      <label>
                        <small>
                          (
                          {unitData?.type +
                            " BEDROOM" +
                            ", " +
                            unitData?.bathroom}
                          {unitData?.square_footage === null ? "" : ", "}
                          {unitData?.square_footage === null
                            ? ""
                            : unitData?.square_footage}
                          {unitData?.square_footage === null ? "" : " SQ. FT."})
                        </small>
                      </label>
                    </div>
                  </div>
                )}
                {/* {unitStatus === "OCCUPIED" ? (
                  ""
                ) : ( */}
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Vacate Date</label>
                    <Space direction="vertical">
                      <DatePicker
                        className="form-control"
                        name="vacateDate"
                        value={vacateDate}
                        onChange={onChangeVacateDate}
                      />
                    </Space>
                  </div>
                </div>
                {/* )} */}
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label>Rented As Of</label>
                    <Space direction="vertical">
                      <DatePicker
                        className="form-control"
                        value={rentedAsOf}
                        onChange={onChangeRentedAsOf}
                      />
                    </Space>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Damage Report</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="damageReport"
                          name="damageReport"
                          value={checkboxes.damageReport}
                          onChange={(e) => handleCheckBoxes(e)}
                          checked={checkboxes.damageReport ? "checked" : false}
                        />
                        <label htmlFor="damageReport">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Outgoing Inspection</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="outgoing"
                          name="outgoingInspection"
                          value={checkboxes.outgoingInspection}
                          onChange={(e) => handleCheckBoxes(e)}
                          checked={
                            checkboxes.outgoingInspection ? "checked" : false
                          }
                        />
                        <label htmlFor="outgoing">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Incoming Inspection</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="incoming"
                          name="incomingInspection"
                          value={checkboxes.incomingInspection}
                          onChange={(e) => handleCheckBoxes(e)}
                          checked={
                            checkboxes.incomingInspection ? "checked" : false
                          }
                        />
                        <label htmlFor="incoming">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group d-flex align-items-center justify-content-between">
                      <label>Lease Signed</label>
                      <div className="toggleBtn">
                        <input
                          type="checkbox"
                          id="lease"
                          name="leaseSigned"
                          value={checkboxes.leaseSigned}
                          onChange={(e) => handleCheckBoxes(e)}
                          checked={checkboxes.leaseSigned ? "checked" : false}
                        />
                        <label htmlFor="lease">
                          <span className="on">ON</span>
                          <span className="off">OFF</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-8 col-xl-9">
                  <div className="form-group">
                    <label>Comments</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="comments"
                      value={formik.values.comments}
                      onChange={formik.handleChange}
                    ></textarea>

                    {/* new commments */}
                    <div className="contentCard commentWrapper mt-3">
                      <div className="commentLogs">
                        {viewComments?.map((v, i) => {
                          return (
                            <div key={i} className="comment">
                              <h2>
                                {capatalizeLetters(
                                  v?.created_by_user_info?.first_name +
                                    "_" +
                                    v?.created_by_user_info?.last_name
                                )}{" "}
                                <span>
                                  {getDate(v.created_at)}{" "}
                                  {localStorage.getItem("role") === "viewer" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "building_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : localStorage.getItem("role") ===
                                    "property_manager" ? (
                                    userAccessibility(
                                      "purchase_order_management",
                                      "delete"
                                    ) === false ? (
                                      ""
                                    ) : (
                                      <a
                                        href="#"
                                        className="circleBtn danger deleteThis ml-2"
                                        onClick={() => {
                                          deleteComment([v._id]);
                                        }}
                                      >
                                        <Delete2 />
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      href="#"
                                      className="circleBtn danger deleteThis ml-2"
                                      onClick={() => {
                                        deleteComment([v._id]);
                                      }}
                                    >
                                      <Delete2 />
                                    </a>
                                  )}
                                </span>
                              </h2>
                              <p>{v.comment}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* new commments */}
                  </div>
                </div>
              </div>
            </div>

            <div className="cardFooter">
              <button className="myBtn mr-1" type="submit">
                Update
              </button>
              <Link className="myBtn bdr" to={PATH.VIEW_TRAFFIC_SHEET}>
                Cancel
              </Link>
            </div>
          </div>
        </section>
      </form>
    </MainLayout>
  );
}
