import React, { useState, useEffect, useRef } from "react";
import {
  Delete,
  Delete2,
  File,
  Print,
  WorkConfirmation,
} from "../../../Icons/Icons";
import MainLayout from "../../../layouts";
import { PATH } from "../../../constants/paths";
import { Link, useNavigate } from "react-router-dom";
import { Search } from "../../../Icons/Icons";
import NoRecordFound from "../../../assets/images/noRecordFound.png";
import Loader from "../../../assets/images/LoaderNew.gif";
import ReactPaginate from "react-paginate";
import Logosm from "../../../assets/images/logo.png";
import { useReactToPrint } from "react-to-print";
import {
  adminApiCall,
  capatalizeLetters,
  userAccessibility,
  getDate,
  getDateOnly,
} from "../../../helpers/ajax_request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Swal from "sweetalert2";

export default function Index() {
  let buildingName = localStorage.getItem("building_namee");
  const [workOrderList, setworkOrderList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [noRecord, setNoRecord] = useState(false);
  const [pageCount, setPageCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecord, setTotalRecord] = React.useState(10);
  const [perPage, setPerPage] = React.useState(50);
  const [deleteItem, setDeleteItem] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [createdAt, setCreatedAt] = useState(false);
  const [suiteNo, setSuiteNo] = useState(false);
  const [tenantName, setTenantName] = useState(false);
  const [building, setBuilding] = useState(false);
  const [status, setStatus] = useState(false);
  const [sortValue, setSortValue] = useState("0");
  const [showLogo, setShowLogo] = useState(false);

  const [workOrderDetails, setWorkOrderDetails] = useState({});
  const [workOrderItems, setWorkOrderItems] = useState([]);

  const navigate = useNavigate();
  const componentRef = useRef(null);
  const listingRef = useRef(null);

  toast.configure();

  const notifySuccess = (msg) =>
    toast.success(msg, {
      autoClose: 3000,
    });

  const notifyWarning = (msg) =>
    toast.warning(msg, {
      autoClose: 3000,
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      autoClose: 3000,
    });

  const searchHandler = (e) => {
    setSearchBar(e.target.value);
  };

  useEffect(() => {
    listWorkOrder();
  }, [
    currentPage,
    searchBar,
    createdAt,
    suiteNo,
    tenantName,
    building,
    status,
  ]);

  const listWorkOrder = async () => {
    let data = {
      current_page: currentPage,
      itemsPerPage: perPage,
      search_value: searchBar,
      building_id: localStorage.getItem("building_id"),
      ...(sortValue === "1"
        ? {
            sort_by_created_at: createdAt,
          }
        : sortValue === "2"
        ? {
            sort_by_suite_no: suiteNo,
          }
        : sortValue === "3"
        ? {
            sort_by_tenant_name: tenantName,
          }
        : sortValue === "4"
        ? {
            sort_by_building: building,
          }
        : sortValue === "5"
        ? {
            sort_by_status: status,
          }
        : ""),
    };

    let res = await adminApiCall(
      "/api/admin/work_order/list",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (localStorage.getItem("building_id") === null) {
        notifyError("Please Select a Building First");
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 200) {
        setworkOrderList(res.data);
        setPageCount(res.total_pages);
        setTotalRecord(res.total_records);
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      } else if (res.status === 300) {
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  const handleChange = (e) => {
    const itemPerPage = parseInt(e.target.value);
    setPerPage(itemPerPage);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  const deleteWorkOrder = (deleteArray) => {
    Swal.fire({
      title: "Are you sure!",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No, cancel please!",
      confirmButtonText: "Yes! Delete This",
      confirmButtonColor: "#d97228",
      closeOnConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data;
        if (typeof deleteArray === "number") {
          data = {
            work_order_id: deleteArray,
          };
        } else {
          data = {
            work_order_id: deleteArray,
          };
        }
        let res = await adminApiCall(
          "/api/admin/work_order/delete",
          "POST",
          JSON.stringify(data)
        );
        if (!res) {
          notifyError("Something went wrong.. Please Try Again");
          return false;
        }
        if (res.hasOwnProperty("status")) {
          if (res.status === 200) {
            notifySuccess("Work Order Deleted Successfully!");
            listWorkOrder();
            setDeleteItem([]);
          } else if (res.status === 400) {
            notifyError(res.message);
          }
        }
      }
    });
  };

  const handleChangeCheckBox = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    let tempArray = [...deleteItem];
    if (checked) {
      tempArray.push(value);
    } else {
      tempArray.splice(tempArray.indexOf(value), 1);
    }
    setDeleteItem(tempArray);
    // }
  };

  const createdAtSort = () => {
    setCreatedAt(!createdAt);
    setSortValue("1");
  };

  const suiteNoSort = () => {
    setSuiteNo(!suiteNo);
    setSortValue("2");
  };

  const tenantNameSort = () => {
    setTenantName(!tenantName);
    setSortValue("3");
  };

  const buildingSort = () => {
    setBuilding(!building);
    setSortValue("4");
  };

  const statusSort = () => {
    setStatus(!status);
    setSortValue("5");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleListingPage = useReactToPrint({
    content: () => listingRef.current,
    onAfterPrint: () => {
      setShowLogo(false);
    },
  });

  const getWorkOrder = async (ID) => {
    let data = {
      work_order_id: ID,
    };
    let res = await adminApiCall(
      "/api/admin/work_order/get",
      "POST",
      JSON.stringify(data)
    );
    if (!res) {
      notifyError("Something went wrong.. Please Try Again");
      setLoader(false);
      setNoRecord(true);
      return false;
    }
    if (res.hasOwnProperty("status")) {
      if (res.status === 200) {
        setWorkOrderDetails(res.data);
        setWorkOrderItems(res.data?.items);
        handlePrint();
        setLoader(false);
        setNoRecord(false);
      } else if (res.status === 400) {
        notifyError(res.message);
        setLoader(false);
        setNoRecord(true);
      }
    }
  };

  return (
    <MainLayout>
      <>
        <section className="pageWrapper">
          <div className="contentCard">
            <div className="titleWrapper">
              <span>Work Orders</span>

              <div className="d-flex justify-content-center align-items-center">
                <div className="search mr-2">
                  <div className="searchField">
                    <input
                      className="form-control searchBar"
                      type="text"
                      placeholder="Search"
                      value={searchBar}
                      onChange={searchHandler}
                    />
                    <div className="icon">
                      <Search />
                    </div>
                  </div>
                </div>
                {localStorage.getItem("role") === "viewer" ? (
                  userAccessibility("work_order_management", "create") ===
                  false ? (
                    ""
                  ) : (
                    <div>
                      <button
                        className="myBtn shadow ml-auto"
                        onClick={() => {
                          if (localStorage.getItem("building_id") === null) {
                            notifyError("Please Select a Building");
                          } else {
                            navigate(PATH.WORK_ORDER_CREATION);
                          }
                        }}
                      >
                        <i className="fa fa-plus-circle"></i> Add Work Order
                      </button>
                    </div>
                  )
                ) : localStorage.getItem("role") === "building_manager" ? (
                  userAccessibility("work_order_management", "create") ===
                  false ? (
                    ""
                  ) : (
                    <div>
                      <button
                        className="myBtn shadow ml-auto"
                        onClick={() => {
                          if (localStorage.getItem("building_id") === null) {
                            notifyError("Please Select a Building");
                          } else {
                            navigate(PATH.WORK_ORDER_CREATION);
                          }
                        }}
                      >
                        <i className="fa fa-plus-circle"></i> Add Work Order
                      </button>
                    </div>
                  )
                ) : localStorage.getItem("role") === "property_manager" ? (
                  userAccessibility("work_order_management", "create") ===
                  false ? (
                    ""
                  ) : (
                    <div>
                      <button
                        className="myBtn shadow ml-auto"
                        onClick={() => {
                          if (localStorage.getItem("building_id") === null) {
                            notifyError("Please Select a Building");
                          } else {
                            navigate(PATH.WORK_ORDER_CREATION);
                          }
                        }}
                      >
                        <i className="fa fa-plus-circle"></i> Add Work Order
                      </button>
                    </div>
                  )
                ) : (
                  <div>
                    <button
                      className="myBtn shadow ml-auto"
                      onClick={() => {
                        if (localStorage.getItem("building_id") === null) {
                          notifyError("Please Select a Building");
                        } else {
                          navigate(PATH.WORK_ORDER_CREATION);
                        }
                      }}
                    >
                      <i className="fa fa-plus-circle"></i> Add Work Order
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="row align-items-md-end"></div>
            {loader ? (
              <div className="text-center">
                <img src={Loader} alt="" />
              </div>
            ) : noRecord ? (
              <div className="text-center">
                <img src={NoRecordFound} alt="" />
              </div>
            ) : (
              <div
                ref={listingRef}
                className="mainTable table-responsive dataTable scrollable abbb"
              >
                {showLogo ? (
                  <div
                    className="logoWrapper"
                    style={{
                      backgroundColor: "white",
                      marginBottom: "15px",
                    }}
                  >
                    <Link className="logo" to={PATH.BUILDING_LIST}>
                      <img className="logo" src={Logosm} alt="" />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {localStorage.getItem("role") === "viewer" ? (
                        userAccessibility("work_order_management", "delete") ===
                        false ? (
                          <th style={{ display: "none" }}></th>
                        ) : (
                          <th></th>
                        )
                      ) : localStorage.getItem("role") ===
                        "building_manager" ? (
                        userAccessibility("work_order_management", "delete") ===
                        false ? (
                          <th style={{ display: "none" }}></th>
                        ) : (
                          <th></th>
                        )
                      ) : localStorage.getItem("role") ===
                        "property_manager" ? (
                        userAccessibility("work_order_management", "delete") ===
                        false ? (
                          <th style={{ display: "none" }}></th>
                        ) : (
                          <th></th>
                        )
                      ) : (
                        <th></th>
                      )}
                      <th className="sort" onClick={createdAtSort}>
                        Date
                      </th>
                      <th className="sort" onClick={tenantNameSort}>
                        Tenant Name
                      </th>
                      <th className="sort" onClick={buildingSort}>
                        Building Name
                      </th>
                      <th className="sort" onClick={suiteNoSort}>
                        Tenant Apt. No.
                      </th>
                      <th className="sort" onClick={statusSort}>
                        Status
                      </th>
                      {localStorage.getItem("role") === "viewer" ? (
                        userAccessibility("work_order_management", "create") ===
                        false ? (
                          <th style={{ display: "none" }}></th>
                        ) : (
                          <th className="text-right">Action</th>
                        )
                      ) : localStorage.getItem("role") ===
                        "building_manager" ? (
                        userAccessibility("work_order_management", "create") ===
                        false ? (
                          <th style={{ display: "none" }}></th>
                        ) : (
                          <th className="text-right">Action</th>
                        )
                      ) : localStorage.getItem("role") ===
                        "property_manager" ? (
                        userAccessibility("work_order_management", "create") ===
                        false ? (
                          <th style={{ display: "none" }}></th>
                        ) : (
                          <th className="text-right">Action</th>
                        )
                      ) : (
                        <th className="text-right">Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {workOrderList &&
                      workOrderList.map((v, i) => {
                        return (
                          <tr key={i}>
                            {localStorage.getItem("role") === "viewer" ? (
                              userAccessibility(
                                "work_order_management",
                                "delete"
                              ) === false ? (
                                <td style={{ display: "none" }}></td>
                              ) : (
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck"
                                    id={"workOrderCheck" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>
                              )
                            ) : localStorage.getItem("role") ===
                              "building_manager" ? (
                              userAccessibility(
                                "work_order_management",
                                "delete"
                              ) === false ? (
                                <td style={{ display: "none" }}></td>
                              ) : (
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck"
                                    id={"workOrderCheck" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>
                              )
                            ) : localStorage.getItem("role") ===
                              "property_manager" ? (
                              userAccessibility(
                                "work_order_management",
                                "delete"
                              ) === false ? (
                                <td style={{ display: "none" }}></td>
                              ) : (
                                <td>
                                  <input
                                    type="checkbox"
                                    className="customCheck"
                                    id={"workOrderCheck" + i}
                                    value={v._id}
                                    checked={
                                      deleteItem && deleteItem.includes(v._id)
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => handleChangeCheckBox(e)}
                                  />
                                </td>
                              )
                            ) : (
                              <td>
                                <input
                                  type="checkbox"
                                  className="customCheck"
                                  id={"workOrderCheck" + i}
                                  value={v._id}
                                  checked={
                                    deleteItem && deleteItem.includes(v._id)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => handleChangeCheckBox(e)}
                                />
                              </td>
                            )}

                            <td
                              style={{
                                cursor:
                                  localStorage.getItem("role") === "viewer"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : localStorage.getItem("role") ===
                                      "building_manager"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : localStorage.getItem("role") ===
                                      "property_manager"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (localStorage.getItem("role") === "viewer") {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "update"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else if (
                                  localStorage.getItem("role") ===
                                  "building_manager"
                                ) {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "create"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else if (
                                  localStorage.getItem("role") ===
                                  "property_manager"
                                ) {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "create"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else {
                                  navigate(
                                    `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                  );
                                }
                              }}
                            >
                              <b>
                                {moment
                                  .utc(v?.created_at)
                                  .format("DD MMMM YYYY")}
                              </b>
                            </td>
                            <td
                              style={{
                                cursor:
                                  localStorage.getItem("role") === "viewer"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : localStorage.getItem("role") ===
                                      "building_manager"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : localStorage.getItem("role") ===
                                      "property_manager"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (localStorage.getItem("role") === "viewer") {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "update"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else if (
                                  localStorage.getItem("role") ===
                                  "building_manager"
                                ) {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "create"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else if (
                                  localStorage.getItem("role") ===
                                  "property_manager"
                                ) {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "create"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else {
                                  navigate(
                                    `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                  );
                                }
                              }}
                            >
                              <b>{capatalizeLetters(v?.tenant_name)}</b>
                            </td>
                            <td
                              style={{
                                cursor:
                                  localStorage.getItem("role") === "viewer"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : localStorage.getItem("role") ===
                                      "building_manager"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : localStorage.getItem("role") ===
                                      "property_manager"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (localStorage.getItem("role") === "viewer") {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "update"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else if (
                                  localStorage.getItem("role") ===
                                  "building_manager"
                                ) {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "create"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else if (
                                  localStorage.getItem("role") ===
                                  "property_manager"
                                ) {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "create"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else {
                                  navigate(
                                    `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                  );
                                }
                              }}
                            >
                              {localStorage.getItem("building_namee")}
                            </td>
                            <td
                              style={{
                                cursor:
                                  localStorage.getItem("role") === "viewer"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : localStorage.getItem("role") ===
                                      "building_manager"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : localStorage.getItem("role") ===
                                      "property_manager"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (localStorage.getItem("role") === "viewer") {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "update"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else if (
                                  localStorage.getItem("role") ===
                                  "building_manager"
                                ) {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "create"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else if (
                                  localStorage.getItem("role") ===
                                  "property_manager"
                                ) {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "create"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else {
                                  navigate(
                                    `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                  );
                                }
                              }}
                            >
                              {v?.suite_no}
                            </td>
                            <td
                              style={{
                                cursor:
                                  localStorage.getItem("role") === "viewer"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : localStorage.getItem("role") ===
                                      "building_manager"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : localStorage.getItem("role") ===
                                      "property_manager"
                                    ? userAccessibility(
                                        "work_order_management",
                                        "create"
                                      ) === false
                                      ? "none"
                                      : "pointer"
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (localStorage.getItem("role") === "viewer") {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "update"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else if (
                                  localStorage.getItem("role") ===
                                  "building_manager"
                                ) {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "create"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else if (
                                  localStorage.getItem("role") ===
                                  "property_manager"
                                ) {
                                  if (
                                    userAccessibility(
                                      "work_order_management",
                                      "create"
                                    ) === false
                                  ) {
                                    // console.log("abc")
                                  } else {
                                    navigate(
                                      `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                    );
                                  }
                                } else {
                                  navigate(
                                    `${PATH.WORK_ORDER_COMPLETION}/${v._id}`
                                  );
                                }
                              }}
                            >
                              <span
                                className={`${
                                  v?.order_status === "PENDING"
                                    ? "text pending"
                                    : "text success"
                                }`}
                              >
                                <span></span> {v?.order_status}
                              </span>
                            </td>
                            {localStorage.getItem("role") === "viewer" ? (
                              userAccessibility(
                                "work_order_management",
                                "create"
                              ) === false ? (
                                <td style={{ display: "none" }}></td>
                              ) : (
                                <td className="text-right">
                                  <div className="dropleft">
                                    <button
                                      type="button"
                                      className="actionEclips dropdown-toggle"
                                      data-toggle="dropdown"
                                    >
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </button>
                                    <div className="dropdown-menu dropDesign">
                                      <Link
                                        to={`${PATH.VIEW_WORK_ORDER}/${v._id}`}
                                      >
                                        View
                                        <span>
                                          <File />
                                        </span>
                                      </Link>
                                      <Link
                                        to={`${PATH.WORK_ORDER_COMPLETION}/${v._id}`}
                                      >
                                        Work Confirmation
                                        <span>
                                          <WorkConfirmation />
                                        </span>
                                      </Link>
                                      <Link
                                        to={"#"}
                                        onClick={() => {
                                          getWorkOrder(v._id, "printJS-form");
                                        }}
                                      >
                                        Print{" "}
                                        <span>
                                          <Print />
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              )
                            ) : localStorage.getItem("role") ===
                              "building_manager" ? (
                              userAccessibility(
                                "work_order_management",
                                "create"
                              ) === false ? (
                                <td style={{ display: "none" }}></td>
                              ) : (
                                <td className="text-right">
                                  <div className="dropleft">
                                    <button
                                      type="button"
                                      className="actionEclips dropdown-toggle"
                                      data-toggle="dropdown"
                                    >
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </button>
                                    <div className="dropdown-menu dropDesign">
                                      <Link
                                        to={`${PATH.VIEW_WORK_ORDER}/${v._id}`}
                                      >
                                        View
                                        <span>
                                          <File />
                                        </span>
                                      </Link>
                                      <Link
                                        to={`${PATH.WORK_ORDER_COMPLETION}/${v._id}`}
                                      >
                                        Work Confirmation
                                        <span>
                                          <WorkConfirmation />
                                        </span>
                                      </Link>
                                      <Link
                                        to={"#"}
                                        onClick={() => {
                                          getWorkOrder(v._id, "printJS-form");
                                        }}
                                      >
                                        Print{" "}
                                        <span>
                                          <Print />
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              )
                            ) : localStorage.getItem("role") ===
                              "property_manager" ? (
                              userAccessibility(
                                "work_order_management",
                                "create"
                              ) === false ? (
                                <td style={{ display: "none" }}></td>
                              ) : (
                                <td className="text-right">
                                  <div className="dropleft">
                                    <button
                                      type="button"
                                      className="actionEclips dropdown-toggle"
                                      data-toggle="dropdown"
                                    >
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </button>
                                    <div className="dropdown-menu dropDesign">
                                      <Link
                                        to={`${PATH.VIEW_WORK_ORDER}/${v._id}`}
                                      >
                                        View
                                        <span>
                                          <File />
                                        </span>
                                      </Link>
                                      <Link
                                        to={`${PATH.WORK_ORDER_COMPLETION}/${v._id}`}
                                      >
                                        Work Confirmation
                                        <span>
                                          <WorkConfirmation />
                                        </span>
                                      </Link>
                                      <Link
                                        to={"#"}
                                        onClick={() => {
                                          getWorkOrder(v._id, "printJS-form");
                                        }}
                                      >
                                        Print{" "}
                                        <span>
                                          <Print />
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              )
                            ) : (
                              <td className="text-right">
                                <div className="dropleft">
                                  <button
                                    type="button"
                                    className="actionEclips dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </button>
                                  <div className="dropdown-menu dropDesign">
                                    <Link
                                      to={`${PATH.VIEW_WORK_ORDER}/${v._id}`}
                                    >
                                      View
                                      <span>
                                        <File />
                                      </span>
                                    </Link>
                                    <Link
                                      to={`${PATH.WORK_ORDER_COMPLETION}/${v._id}`}
                                    >
                                      Work Confirmation
                                      <span>
                                        <WorkConfirmation />
                                      </span>
                                    </Link>
                                    <div style={{ display: "none" }}></div>
                                    <Link
                                      to={"#"}
                                      onClick={() => {
                                        getWorkOrder(v._id, "printJS-form");
                                      }}
                                    >
                                      Print{" "}
                                      <span>
                                        <Print />
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {workOrderList && workOrderList.length > 0 ? (
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-5">
                  Showing {currentPage * perPage - perPage + 1} to{" "}
                  {totalRecord > currentPage * perPage
                    ? currentPage * perPage
                    : totalRecord}{" "}
                  of {totalRecord} entries
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers text-right"
                    id="datatable_paginate"
                  >
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      onPageChange={(e) => handlePageClick(e)}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      pageClassName={"paginate_button page-item"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {localStorage.getItem("role") === "viewer" ? (
              userAccessibility("work_order_management", "delete") === false ? (
                ""
              ) : (
                <div className="cardFooter">
                  <button
                    className="myBtn danger squre deleteThis"
                    onClick={() => deleteWorkOrder(deleteItem)}
                    disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                  <button
                    className="myBtn bdr mb-3"
                    type="button"
                    onClick={() => {
                      setShowLogo(true);
                      setTimeout(() => {
                        handleListingPage();
                      }, 500);
                    }}
                  >
                    <Print />
                    Print
                  </button>
                </div>
              )
            ) : localStorage.getItem("role") === "building_manager" ? (
              userAccessibility("work_order_management", "delete") === false ? (
                ""
              ) : (
                <div className="cardFooter">
                  <button
                    className="myBtn danger squre deleteThis"
                    onClick={() => deleteWorkOrder(deleteItem)}
                    disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                  <button
                    className="myBtn bdr ml-2"
                    type="button"
                    onClick={() => {
                      setShowLogo(true);
                      setTimeout(() => {
                        handleListingPage();
                      }, 500);
                    }}
                  >
                    <Print />
                    Print
                  </button>
                </div>
              )
            ) : localStorage.getItem("role") === "property_manager" ? (
              userAccessibility("work_order_management", "delete") === false ? (
                ""
              ) : (
                <div className="cardFooter">
                  <button
                    className="myBtn danger squre deleteThis"
                    onClick={() => deleteWorkOrder(deleteItem)}
                    disabled={deleteItem.length === 0 ? true : false}
                  >
                    <Delete />
                  </button>
                  <button
                    className="myBtn bdr ml-2"
                    type="button"
                    onClick={() => {
                      setShowLogo(true);
                      setTimeout(() => {
                        handleListingPage();
                      }, 500);
                    }}
                  >
                    <Print />
                    Print
                  </button>
                </div>
              )
            ) : (
              <div className="cardFooter">
                <button
                  className="myBtn danger squre deleteThis"
                  onClick={() => deleteWorkOrder(deleteItem)}
                  disabled={deleteItem.length === 0 ? true : false}
                >
                  <Delete />
                </button>
                <button
                  className="myBtn bdr ml-2"
                  type="button"
                  onClick={() => {
                    setShowLogo(true);
                    setTimeout(() => {
                      handleListingPage();
                    }, 500);
                  }}
                >
                  <Print />
                  Print
                </button>
              </div>
            )}
          </div>
        </section>

        <section style={{ display: "none" }} className="pageWrapper">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <div className="contentCard">
                <div className="titleWrapper">
                  <span>Work Order Details</span>
                </div>
                <div className="details">
                  <div className="detailContent">
                    <h4>Work Order ID</h4>
                    <h2>{workOrderDetails?.work_order_id}</h2>
                  </div>
                  <div className="detailContent">
                    <h4>Created Date</h4>
                    <h2>
                      {getDateOnly(workOrderDetails?.work_order_creation_date)}
                    </h2>
                  </div>
                  <div className="detailContent">
                    <h4>Apt. No.</h4>
                    <h2>{workOrderDetails?.suite_no}</h2>
                  </div>
                  <div className="detailContent">
                    <h4>Tenant Name</h4>
                    <h2>
                      {capatalizeLetters(
                        workOrderDetails?.tenant_name +
                          "_" +
                          workOrderDetails?.tenant_name
                      )}
                    </h2>
                  </div>
                  <div className="detailContent">
                    <h4>Phone Number</h4>
                    <h2>{workOrderDetails?.phone_no}</h2>
                  </div>
                  <div className="detailContent">
                    <h4>Tenant Signature</h4>
                    <img
                      className="p-4"
                      src={workOrderDetails?.tenant_signature}
                      alt=""
                    />
                  </div>
                  <div className="detailContent">
                    <h4>Completion Status</h4>
                    <h2
                      className={`badge ${
                        workOrderDetails?.order_status === "COMPLETED"
                          ? "success"
                          : "pending"
                      }`}
                    >
                      {workOrderDetails?.order_status}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-9">
              <div className="contentCard mb-0">
                <div>
                  <div className="titleWrapper">
                    <span>Work Order Items</span>
                  </div>
                  {loader ? (
                    <div className="text-center">
                      <img src={Loader} alt="" />
                    </div>
                  ) : noRecord ? (
                    <div className="text-center">
                      <img src={NoRecordFound} alt="" />
                    </div>
                  ) : (
                    <div ref={componentRef}>
                      {showLogo ? (
                        <div
                          className="logoWrapper"
                          style={{
                            backgroundColor: "#38393c",
                            marginBottom: "15px",
                          }}
                        >
                          <Link className="logo" to={PATH.BUILDING_LIST}>
                            <img className="logo" src={Logosm} alt="" />
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className="logoWrapper"
                        style={{ backgroundColor: "white" }}
                      >
                        <Link className="logo" to={PATH.BUILDING_LIST}>
                          <img className="logo" src={Logosm} alt="" />
                        </Link>
                      </div>
                      <div className="contentCard">
                        <div className="titleWrapper">
                          <span>Work Confirmation</span>
                        </div>
                        <div className="details">
                          <div className="detailContent">
                            <h4>Work Order ID</h4>
                            <h2>{workOrderDetails?.work_order_id}</h2>
                          </div>
                          <div className="detailContent">
                            <h4>Created Date</h4>
                            <h2>
                              {getDateOnly(
                                workOrderDetails.work_order_creation_date
                              )}
                            </h2>
                          </div>
                          <div className="detailContent">
                            <h4>Apt. No.</h4>
                            <h2>{workOrderDetails?.suite_no}</h2>
                          </div>
                          <div className="detailContent">
                            <h4>Building Name</h4>
                            <h2>{buildingName}</h2>
                          </div>
                          <div className="detailContent">
                            <h4>Tenant Name</h4>
                            <h2>
                              {workOrderDetails?.tenant_name
                                ?.charAt(0)
                                .toUpperCase() +
                                workOrderDetails?.tenant_name?.slice(1)}
                            </h2>
                          </div>
                          <div className="detailContent">
                            <h4>Phone Number</h4>
                            <h2>
                              {" ("}
                              {workOrderDetails?.phone_no?.split("")[0] !==
                              undefined
                                ? workOrderDetails?.phone_no?.split("")[0]
                                : ""}
                              {workOrderDetails?.phone_no?.split("")[1] !==
                              undefined
                                ? workOrderDetails?.phone_no?.split("")[1]
                                : ""}
                              {workOrderDetails?.phone_no?.split("")[2] !==
                              undefined
                                ? workOrderDetails?.phone_no?.split("")[2]
                                : ""}
                              {") "}
                              {workOrderDetails?.phone_no?.split("")[3] !==
                              undefined
                                ? workOrderDetails?.phone_no?.split("")[3]
                                : ""}
                              {workOrderDetails?.phone_no?.split("")[4] !==
                              undefined
                                ? workOrderDetails?.phone_no?.split("")[4]
                                : ""}
                              {workOrderDetails?.phone_no?.split("")[5] !==
                              undefined
                                ? workOrderDetails?.phone_no?.split("")[5]
                                : ""}
                              {" - "}
                              {workOrderDetails?.phone_no?.split("")[6] !==
                              undefined
                                ? workOrderDetails?.phone_no?.split("")[6]
                                : ""}
                              {workOrderDetails?.phone_no?.split("")[7] !==
                              undefined
                                ? workOrderDetails?.phone_no?.split("")[7]
                                : ""}
                              {workOrderDetails?.phone_no?.split("")[8] !==
                              undefined
                                ? workOrderDetails?.phone_no?.split("")[8]
                                : ""}
                              {workOrderDetails?.phone_no?.split("")[9] !==
                              undefined
                                ? workOrderDetails?.phone_no?.split("")[9]
                                : ""}
                            </h2>
                          </div>
                          <div className="detailContent">
                            <h4>Tenant Signature</h4>
                            <img
                              className="p-4"
                              src={workOrderDetails?.tenant_signature}
                              alt=""
                            />
                          </div>
                          <div className="detailContent">
                            <h4>Completion Status</h4>
                            <h2
                              className={`badge ${
                                workOrderDetails?.order_status === "COMPLETED"
                                  ? "success"
                                  : "pending"
                              }`}
                            >
                              {workOrderDetails?.order_status}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="mainTable table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th style={{ maxWidth: "60px" }}>Item #</th>
                              <th style={{ maxWidth: "300px" }}>
                                Specific Items
                              </th>
                              <th style={{ minWidth: "150px" }}>Completed</th>
                              <th style={{ minWidth: "200px" }}>Comments</th>
                              <th
                                style={{
                                  minWidth: "150px",
                                  textAlign: "center",
                                }}
                              >
                                Require Trades
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {workOrderItems &&
                              workOrderItems?.map((v, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                      <b>{v.item}</b>
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="customRadio"
                                        id={v._id}
                                        //   value={marketComplete}
                                        checked={
                                          v.is_complete ? "checked" : false
                                        }
                                        //   onClick={() => {
                                        //     setItemID(v._id);
                                        //   }}
                                        //   onChange={(e) =>
                                        //     markCompletedOnChange(e, v._id)
                                        //   }
                                        disabled={true}
                                      />
                                      {v?.item_repair_description[0]
                                        ?.completed_at ? (
                                        <small className="">
                                          {" "}
                                          (
                                          {getDate(
                                            v?.item_repair_description[0]
                                              ?.completed_at
                                          )}
                                          ){" "}
                                        </small>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      <p>
                                        {" "}
                                        {v?.item_repair_description[0]
                                          ?.description
                                          ? v?.item_repair_description[0]
                                              ?.description
                                          : ""}
                                      </p>{" "}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingLeft: "",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        className="customRadio"
                                        //   value={requireTrade}
                                        id={v._id}
                                        checked={
                                          v.require_trade ? "checked" : false
                                        }
                                        //   onChange={(e) =>
                                        //     requireTradeOnChange(e, v._id)
                                        //   }
                                        disabled={true}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      {workOrderDetails?.comments?.length < 1 ? (
                        ""
                      ) : (
                        <div className="contentCard commentWrapper mt-3">
                          <div className="formDesign">
                            <div className="form-group">
                              <label>Comments</label>
                            </div>
                          </div>
                          <div className="commentLogs">
                            {workOrderDetails?.comments?.map((v, i) => {
                              return (
                                <div key={i} className="comment">
                                  <h2>
                                    {capatalizeLetters(v?.user_name)}
                                    <span>
                                      {getDate(v.created_at)}{" "}
                                      {localStorage.getItem("role") ===
                                      "viewer" ? (
                                        userAccessibility(
                                          "work_order_management",
                                          "delete"
                                        ) === false ? (
                                          ""
                                        ) : (
                                          <a
                                            href="#"
                                            className="circleBtn danger deleteThis ml-2"
                                            // onClick={() => {
                                            //   deleteComment([v._id]);
                                            // }}
                                          >
                                            <Delete2 />
                                          </a>
                                        )
                                      ) : localStorage.getItem("role") ===
                                        "building_manager" ? (
                                        userAccessibility(
                                          "work_order_management",
                                          "delete"
                                        ) === false ? (
                                          ""
                                        ) : (
                                          <a
                                            href="#"
                                            className="circleBtn danger deleteThis ml-2"
                                            // onClick={() => {
                                            //   deleteComment([v._id]);
                                            // }}
                                          >
                                            <Delete2 />
                                          </a>
                                        )
                                      ) : localStorage.getItem("role") ===
                                        "property_manager" ? (
                                        userAccessibility(
                                          "work_order_management",
                                          "delete"
                                        ) === false ? (
                                          ""
                                        ) : (
                                          <a
                                            href="#"
                                            className="circleBtn danger deleteThis ml-2"
                                            // onClick={() => {
                                            //   deleteComment([v._id]);
                                            // }}
                                          >
                                            <Delete2 />
                                          </a>
                                        )
                                      ) : (
                                        <a
                                          href="#"
                                          className="circleBtn danger deleteThis ml-2"
                                          // onClick={() => {
                                          //   deleteComment([v._id]);
                                          // }}
                                        >
                                          <Delete2 />
                                        </a>
                                      )}
                                    </span>
                                  </h2>
                                  <p>{v.comment}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </MainLayout>
  );
}
