import React, { ReactNode } from "react";
import Sidebar from "../components/SideBarNav/Index";
import Header from "../components/TopBarNav/Index";
import BottomMobileNav from "../components/MobileNav/Index";

function MainLayout(props) {
  const { children } = props;
  return (
    <>
      <Sidebar />
      <Header />
      <BottomMobileNav />

      {children}
    </>
  );
}

export default MainLayout;
