import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { baseDomain } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
export default function Verifyotp() {
  const [otp, setOtp] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [check, setCheck] = useState(true);
  const [idParams, setIdParams] = useState(false);

  const navigate = useNavigate();
  const customInputStyle = {
    width: "50px",
    height: "50px",
    margin: "0 10px",
    fontSize: "24px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    textAlign: "center",
  };
  const customContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const url = new URL(window.location.href);
  const token = url.searchParams.get("token");
  // useEffect(() => {
  //   console.log("token validate", token);
  //   if (token) {
  //     fetch(`${baseDomain}/api/user/send_otp`, {
  //       method: "get",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `${token}`, // Add the token to the Authorization header
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log("data in ger request", data);
  //         if (
  //           data?.message == "Something went wrong while authenticating session"
  //         ) {
  //           toast.error("Your Link has been Expired Kindly send it again");
  //           setCheck(false);
  //         } else {
  //           setIsValid(true);
  //           toast.success("OTP is Sent Succesfully Please Check Your Email");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Token validation error:", error);
  //       });
  //   }
  // }, []);

  useEffect(() => {
    console.log("token validate", token);
    if (token) {
      fetch(`${baseDomain}/api/user/send_otp`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`, // Add the token to the Authorization header
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data in ger request", data);
          if (
            data?.message ===
            "Something went wrong while authenticating session"
          ) {
            toast.error("Your Link has expired. Kindly send it again.");
            setCheck(false); // Update the 'check' state to false if the link has expired
          } else {
            setIsValid(true);
            toast.success("OTP is Sent Successfully. Please Check Your Email");
          }
        })
        .catch((error) => {
          console.error("Token validation error:", error);
          toast.error("Error occurred while sending OTP.");
        });
    }
  }, []);

  const ResendEmail = async () => {
    try {
      if (token) {
        const response = await fetch(`${baseDomain}/api/public/resend_email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`, // Add the token to the Authorization header
          },
          body: JSON.stringify({
            application_id: idParams, // Include the application_id in the request body
          }),
        });

        const data = await response.json();
        if (data.success === false) {
          toast.error(data?.message);
        }
        if (
          data?.message === "Something went wrong while authenticating session"
        ) {
          toast.error("Something went wrong while authenticating session");
        }
        if (data.success === true) {
          console.log("data ==> otp", data);
          toast.success(data?.message);
          // navigate("/")
        }
      } else {
        toast.error("There is no token available");
      }
    } catch (error) {
      console.error("Token validation error:", error);
      toast.error("Token validation error occurred");
    }
  };

  const handleVerify = async () => {
    if (!otp) {
      toast.error("Please enter the OTP");
      return;
    }
    try {
      const response = await fetch(`${baseDomain}/api/user/verify_otp`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`, // Add the token to the Authorization header
        },
        body: JSON.stringify({
          otp: otp,
        }),
      });

      const data = await response.json();
      console.log("data==>", data);
      localStorage.setItem("Application_token", data?.token);
      if (
        data?.message == "Something went wrong while authenticating session"
      ) {
        toast.error("Your Link has been Expired Kindly send it again");
        setCheck(false);
      } else if (data?.message == "Wrong Otp provided") {
        toast.error(data?.message);
        setOtp("");
      } else {
        toast.success("OTP is Verified");
        navigate("/user_application");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("An error occurred while verifying OTP.");
    }
  };
  // let idParam;
  useEffect(() => {
    // Get the current URL
    const currentURL = window.location.href;

    // Create a URL object with the current URL
    const url = new URL(currentURL);

    // Get the "id" parameter from the URL
    let idParam = url.searchParams.get("id");
    setIdParams(idParam);
    // Do something with the extracted ID
    console.log("ID:", idParam);

    // Now you can use the "idParam" value in your React component as needed
    // For example, you can send it to an API or use it to render content.
  }, []);

  // const handleVerify = async () => {
  //   if (!otp) {
  //     toast.error("Please enter the OTP");
  //     return;
  //   }
  //   try {
  //     const response = await fetch(`${baseDomain}/api/user/verify_otp`, {
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `${token}`, // Add the token to the Authorization header
  //       },
  //       body: JSON.stringify({
  //         otp: otp,
  //       }),
  //     });

  //     const data = await response.json();
  //     console.log("data==>", data);
  //     localStorage.setItem("Application_token", data?.token);
  //     if (
  //       data?.message === "Something went wrong while authenticating session"
  //     ) {
  //       toast.error("Your Link has expired. Kindly send it again");
  //       setCheck(false); // Update the 'check' state to false if the link has expired
  //     } else if (data?.success == true) {
  //       setIsValid(data.isValid);
  //       toast.success("OTP is Verified");
  //       navigate("/user_application");
  //     }
  //   } catch (error) {
  //     console.error("Error verifying OTP:", error);
  //     toast.error("An error occurred while verifying OTP.");
  //   }
  // };

  // useEffect(() => {
  //   const applicationToken = localStorage.getItem("Application_token");
  //   if (applicationToken) {
  //     // setIsOTPVerified(true);
  //     console.log(applicationToken)
  //   } else if(applicationToken == undefined) {
  //     navigate("/user_application/verify_otp");
  //   }
  //   return () => {
  //     // Remove the token from localStorage when the component is unmounted
  //     localStorage.removeItem("Application_token");
  //   };
  // }, []);
  // window.onbeforeunload = () => {
  //   // Clear the token from local storage when the user navigates away
  //   localStorage.removeItem("Application_token");
  // };
  return (
    <section
      className="pageWrapper cardControl"
      style={{ marginLeft: "0 !important" }}
    >
      {check == true ? (
        <div
          className="contentCard"
          style={{
            minHeight: "550px",
          }}
        >
          <h5 style={{ textAlign: "center", color: "#d97228" }}>
            Please enter your verification PIN here:
          </h5>
          <div className="titleWrapper">
            <span>Verification</span>
          </div>
          <div style={customContainerStyle}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              inputType="password"
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={customInputStyle}
            />
          </div>
          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div
              className="button-effects "
              style={{
                padding: "1rem 2rem 1rem 2rem",
                border: "1px solid #d97228",
                cursor: "pointer",
                width: "7rem",
                marginTop: "3rem",
                borderRadius: "10PX",
              }}
              onClick={handleVerify}
            >
              Verify
            </div>
            <div
              className="button-effects ml-3"
              style={{
                padding: "1rem 2rem 1rem 2rem",
                border: "1px solid #d97228",
                cursor: "pointer",
                width: "7rem",
                marginTop: "3rem",
                borderRadius: "10PX",
              }}
              onClick={() => {
                setOtp("");
              }}
            >
              Clear
            </div>
            <div
              className="button-effects ml-3"
              style={{
                padding: "1rem 2rem 1rem 2rem",
                border: "1px solid #d97228",
                cursor: "pointer",
                width: "7rem",
                marginTop: "3rem",
                borderRadius: "10PX",
              }}
              onClick={ResendEmail}
            >
              Resend
            </div>
          </div>
        </div>
      ) : (
        <div
          className="contentCard"
          style={{
            minHeight: "550px",
          }}
        >
          <h5 style={{ textAlign: "center", color: "#d97228" }}>
            Your link has been expired kindly send it again
          </h5>
          <div className="titleWrapper">
            <span>Request New Link</span>
          </div>

          <div className="d-flex" style={{ justifyContent: "center" }}>
            {/* <div
          className="button-effects "
          style={{
            padding: "1rem 2rem 1rem 2rem",
            border: "1px solid #d97228",
            cursor: "pointer",
            width: "7rem",
            marginTop: "3rem",
            borderRadius: "10PX",
          }}
          onClick={handleVerify}
        >
          Verify
        </div> */}
            {/* <div
          className="button-effects ml-3"
          style={{
            padding: "1rem 2rem 1rem 2rem",
            border: "1px solid #d97228",
            cursor: "pointer",
            width: "7rem",
            marginTop: "3rem",
            borderRadius: "10PX",
          }}
          onClick={() => {
            setOtp("");
          }}
        >
          Clear
        </div> */}
            <div
              className="button-effects ml-3"
              style={{
                padding: "1rem 2rem 1rem 2rem",
                border: "1px solid #d97228",
                cursor: "pointer",
                width: "7rem",
                marginTop: "3rem",
                borderRadius: "10PX",
              }}
              onClick={ResendEmail}
            >
              Resend
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
